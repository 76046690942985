import { Component, OnInit, OnDestroy, AfterViewInit, ViewChildren } from '@angular/core';
import { PassionProjects } from 'src/app/model/PassionProject';
import { Subscription, Observable } from 'rxjs';
import { PassionProjectService } from 'src/app/services/passion-project.service';
import { SharedPassionProjectService } from 'src/app/services/shared-passion-project.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Teams, EditTeamRequest } from 'src/app/model/passion-project-teams';
import { NgbModal, NgbModalConfig, NgbModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, map, finalize } from 'rxjs/operators';
import { NotificationService } from 'src/app/services/notification.service';
import { PassionProjectTeamService } from '../../services/shared-services/passion-project-team.service';
import { UserRole } from 'src/app/shared/user-role';
import { SocketService } from 'src/app/services/web/socket.service';
import { CommonService } from 'src/app/services/common.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-project-view',
  templateUrl: './project-view.component.html',
  styleUrls: ['./project-view.component.scss']
})
export class ProjectViewComponent implements OnInit, OnDestroy, AfterViewInit {

  disabled = false;

  showProjres: boolean = false;

  public loader = false;  // change by samir for production release
  public passionProject!: any;
  public sharedPassionProjectSubscription: Subscription;

  public teamList: Teams[];
  public sharedTeamListSubscription: Subscription;

  public projectTeams: Teams[];

  public searchTeamModalRef = null;
  public createTeamModalRef = null;
  public searchedTeam: Teams;
  public userId: string;

  public UserRole = new UserRole();
  projectResData: any;
  youtubeLinkFlag: boolean;
  passion_project_mentor_list = [];
  @ViewChildren('scrollMe') myScrollContainer;
  processLoader = false;

  passionProjectId: string = '';
  role: string = '';
  defaultThumbnailPic: string = environment.imageBasePath + environment.userDefaultProfilePic;

  constructor(
    private socket_service: SocketService,
    private activate_route: ActivatedRoute,
    private router: Router,
    private ngModal: NgbModal,
    private ns: NotificationService,
    private _passionProjectService: PassionProjectService,
    private _sharedPassionProjectService: SharedPassionProjectService,
    private _sharedPassionProjectTeamService: PassionProjectTeamService,
    private modalReview: NgbModal,
    config: NgbModalConfig, private modalService: NgbModal,
    private commonService: CommonService
  ) {
    config.backdrop = 'static';
  }

  ngOnInit() {
    this.role = this.UserRole.userRole;
    this.userId = localStorage.getItem('user_id');

    this.activate_route.queryParams.subscribe(params => {
      this.passionProjectId = params.id;
      this.getPassionProject();
    });

    if (this.socket_service.sockets.disconnected) {
      this.socket_service.connection();
    }
  }

  ngAfterViewInit() {
    //  this.scrollToBottom();
  }

  scrolldelay;
  scrollToBottom(): void {
    if (this.myScrollContainer) {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    }
  }


  getPassionProject() {

    //GET PROJECT FROM NEW API
    if (this.passionProjectId) {
      this.loader = true;
      this._passionProjectService
        .getPassionProject(this.passionProjectId)
        .subscribe(
          res => {
            this.loader = false
            this.passionProject = res.projects;

            // for(let i = 0; i < this.passionProject.project_resources.length; i++){
            //   let splitTile = this.passionProject.project_resources[i].title.split('.');
            //   let fileExtenion = splitTile.pop();

            //  console.log(fileExtenion);

            // }

            //CHECK PUBLISH STATUS
            if (this.role == 'student' && !this.passionProject.publish) {
              this.router.navigate(['/passion-projects']);
              return false;
            }

            this._sharedPassionProjectService.changePassionProject(this.passionProject);
            this.updateTagColors();
            this.getTeams();
          },
          err => {
            this.loader = false
            this.ns.showNotification('error', err.message);
          }
        );
    }

    //GET PROJECT FROM SHARED SERVICE
    if (!this.passionProjectId) {
      this.sharedPassionProjectSubscription = this._sharedPassionProjectService
        .passionProject
        .subscribe(
          res => {
            if (Object.keys(res).length < 1) return this.redirect('/passion-projects');
            this.passionProject = res as PassionProjects;
            this.updateTagColors();
            this.getTeams();
          }
        );
    }
  }

  //TAGS COLORS
  updateTagColors() {
    if (this.passionProject.project_tags) {
      for (let i = 0; i < this.passionProject.project_tags.length; i++) {
        this.passionProject.project_tags[i].bgColor = this.commonService.randomBgColor();
      }
    }
    //console.log(this.passionProject);
  }

  is_chat_room() {
    if (this.passionProject.mentor_chat['room_status']) {
      return true;
    }
    return false;
  }

  getTeams() {

    //FOR NEW DESIGN
    this.getTeamsById();
    
    //GET USERS CHAT
    if (this.is_mentor_present()) {
      this.get_passion_project_proposal();
    };
  }

  getTeamsById() {
    this.processLoader = true;
    this._passionProjectService
      .getTeamsById(this.passionProject._id)
      .pipe(finalize(() => this.processLoader = false))
      .subscribe(
        res => {
          this.projectTeams = res.teams;
        },
        err => this.ns.showNotification('error', err.message)
      );
  }

  openSearchTeamModal(content) {
    this.searchTeamModalRef = this.ngModal.open(content);
  }

  openCreateTeamModal(content) {
    this.createTeamModalRef = this.ngModal.open(content);
  }

  closeModalCreateTeam(val) {
    if (val == 'closeModal') {
      this.createTeamModalRef.close();
      this.searchTeamModalRef.close();
      this.getTeams();
    }
  }

  addTeam() {
    this.searchTeamModalRef.close();
    if (this.searchedTeam) {
      if (typeof this.searchedTeam == 'string') return;


      this.loader = true;

      const projectIds: Array<string> = [this.passionProject._id];
      this.searchedTeam.projects.forEach(val => projectIds.push(val._id));

      // const teamMembers: Array<string> = [this.passionProject._id];
      const teamMembers: Array<string> = [];
      this.searchedTeam.members.forEach(val => teamMembers.push(val._id));

      const formData: EditTeamRequest = {
        team_id: this.searchedTeam._id,
        project_id: projectIds,
        team_name: this.searchedTeam.name,
        team_logo: this.searchedTeam.logo,
        team_members: teamMembers
      };
      this._passionProjectService
        .editTeam(formData)
        .pipe(finalize(() => this.loader = false))
        .subscribe(
          editTeam => {
            this.ns.showNotification('success', editTeam.message);
            this.getPassionProject();
            this.searchedTeam = null;
          },
          err => this.ns.showNotification('error', err.message)
        );
    }

  }

  editTeam(team: Teams) {
    this._sharedPassionProjectTeamService.changeteam(team);
    this.redirect('/passion-projects/teams/edit');
  }

  viewTeam(team: Teams) {
    this._sharedPassionProjectTeamService.changeteam(team);
    this.redirect('/passion-projects/team', team);
  }

  deleteSearchedTeam() {
    this.searchedTeam = null;
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      map(term => term === '' ? []
        : this.teamList.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )

  formatter = (x: { name: string }) => x.name;

  redirect(path: string, team: any = '') {
    if (this.searchTeamModalRef) { this.searchTeamModalRef.close(); }
    if(team) {
      this.router.navigate([path], { queryParams: { project_id: this.passionProjectId, team_id: team._id} });
    }
    else {
      this.router.navigate([path], { queryParams: { project_id: this.passionProjectId} });
    }
  }

  redirectToEdit(path: string) {
    if (this.searchTeamModalRef) this.searchTeamModalRef.close();
    this.router.navigate([path], {queryParams: {id: this.passionProjectId}});
  }

  ngOnDestroy() {
    this.socket_service.leave_room(this.room_id, localStorage.getItem('user_id'));

    if (this.sharedPassionProjectSubscription) this.sharedPassionProjectSubscription.unsubscribe();
    if (this.sharedTeamListSubscription) this.sharedTeamListSubscription.unsubscribe();
  }

  openVideo(videoResources) {
    this.modalService.open(videoResources, { size: 'md' });
  }


  openProjRes(projectResource) {
    this.projectResData = projectResource;
    if (projectResource.url) {
      let urlRegex = /^(https?:\/\/[^/]+)/;
      if (projectResource.url.match(urlRegex)) {
        if (projectResource.url.match(urlRegex)[0] == 'https://www.youtube.com') {
          this.youtubeLinkFlag = true;
          this.showProjres = true;
        } else {
          this.youtubeLinkFlag = false;
          this.showProjres = false;
          window.open(projectResource.url, "blank")

        };
      }
    } else {
      this.showProjres = true;
    }
  }

  closeProjRes() {
    this.showProjres = false;
    this.youtubeLinkFlag = false;
  }

  savePlayer(player) {
    player.target.playVideo();
  }

  created_passion_project(project) {
    return this.UserRole.create_permission(project)
  }

  is_chat_true = false;
  show_group_name
  open_chat(mentor, pc, type) {
    this.is_chat_true = true;
    this.show_group_name = type;
    this.group_details = pc;
    if (type == 'team') {
      this.view_room_group(pc.room_id, pc, type)
    } else {
      this.avatar_url = pc.avatar_url;
      this.chat_user_name = pc.name;
      this.group_details = pc;
      this.view_room(pc.room_id);
    }
  }

  open_chat_mentor(mentor, type) {
    if (!this.is_chat_room()) {
      this.init_mentor_chat();
    } else {
      this.open_chat(mentor, this.passionProject.mentor_chat['chat_room'], type)
    }
  }

  // close chat model
  close_chat(group_details) {
    this.is_chat_true = !this.is_chat_true;
    this.leave_group(group_details)
  }

  room_id = '';
  my_message = [];
  avatar_url = [];
  chat_user_name = '';
  avatar_chat_color = []
  username = '';
  group_details: any[] = [];
  group_lists: any[] = [];
  view_room(room_id) {
    this.my_message = [];
    if (this.room_id != null || this.room_id != '') {
      this.socket_service.leave_room(this.room_id, localStorage.getItem('user_id'));
    }

    this.room_id = room_id;

    this.socket_service.view_room(room_id).subscribe(view_room => {
      this.my_message = view_room.messages;
      this.socket_service.join_room(this.userId, room_id);
      this.group_details['unread_message_count'] = 0;
      let list = document.getElementById('message');
      let length = list.childNodes.length;

      if (list != null && list.hasChildNodes()) {
        for (let i = 0; i <= length; i++) {
          list.removeChild(list.childNodes[0]);
        }
      }
    },
      (err) => {
        this.ns.showNotification('error', err.message)
      })
  }

  all_date = [];
  get_Date() {
    this.all_date = [];
    let last_date = new Date(this.my_message[this.my_message.length - 1].created_at).toDateString();
    for (let i = 0; i < this.my_message.length - 2; i++) {
      if (new Date(this.my_message[i].created_at).toDateString() != last_date) {
        this.all_date[i] = (new Date(this.my_message[i].created_at).toDateString());
        last_date = new Date(this.my_message[i].created_at).toDateString();
      }
    }
  }

  getName(pc) {
    if (pc.last_name) {
      return pc.name[0].toUpperCase() + pc.last_name[0].toUpperCase();
    }
    else {
      if (pc.name) {
        return pc.name[0].toUpperCase();
      }
      return 'U';
    }
  }

  get_time(date) {
    return this.ns.formatAMPM(new Date(date))
  }
  
  message_view = '';
  send_message() {
    if (this.message_view == '') {
      this.ns.showNotification('info', 'Enter Some message for your friend');
      return;
    }

    if (!this.room_id) {
      this.ns.showNotification('info', 'Please join with the person first..');
      return;
    }

    this.socket_service.send_message(this.userId, this.room_id, this.message_view, null);
    this.message_view = '';
  }

  view_room_group(room_id, group_details, type) {
    this.avatar_url = group_details.avatar_url;
    this.chat_user_name = group_details.name;
    this.group_details = group_details;
    this.view_room(room_id)
  }

  is_team_object(team) {
    if (Object.keys(team.group_chat).length > 0) {
      if (team.group_chat.user_status == "deactivate") {
        return false;
      }
      return true;
    }
    return false;
  }

  exit_group(group_team) {
    if (confirm('Are you sure to exit this group ?')) {
      this.socket_service.exit_group(group_team.room_id).subscribe(exit_group => {
        this.ns.showNotification('success', exit_group.message);
        this.is_chat_true = false;
        this.getTeams();
      },
        (err) => {
          this.ns.showNotification('error', err.message);
        })
    }
    return;
  }

  delete_group(group_team) {
    if (confirm('Are you sure to delete this group ?')) {
      this.socket_service.delete_group(group_team.room_id).subscribe(delete_group => {
        this.ns.showNotification('success', 'Successfully deleted the group');
        this.is_chat_true = false;
        this.getTeams();
      },
        (err) => {
          this.ns.showNotification('error', err.message);
        })
    }
    return;
  }

  leave_group(group_team) {
    this.socket_service.leave_room(group_team.room_id, localStorage.getItem('user_id'));
  }


  weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];


  is_day(date) {
    let day = new Date(date);
    return this.weekday[day.getUTCDay()];
  }

  is_mentor_present() {
    let mentor_list = this.passionProject.project_mentors.find(list => list['id'] == this.userId);
    return mentor_list;
  }

  scrollTop_height = 200
  get_height(height) {
    if (height) {

      let messgae_height = this.my_message.length;
      // let unread_mesage = this.get_count(this.group_details);
      // if (unread_mesage > 0) {
      //   this.scrollTop_height = height - messgae_height;
      // } else {
      this.scrollTop_height = height;
      // }

      return this.scrollTop_height;
    }
    return this.scrollTop_height
  }

  init_mentor_chat() {
    let project_name = this.passionProject.project_title;
    let project_Des = this.passionProject.project_overview.description;
    let project_id = this.passionProject._id;
    let admin_list = [];
    for (let i = 0; i < this.passionProject.project_mentors.length; i++) {
      admin_list.push(this.passionProject.project_mentors[i]['id']);
    }
    this.socket_service.initialize_chat(project_name, admin_list, project_Des, project_id).subscribe(init_chat => {
      this.open_chat('', init_chat.chat_room, 'single')
      this.passionProject.mentor_chat['room_status'] = true;
    }, (err) => {
      console.log(err);
    })
  }

  chat_with(mentor_info) {
    if (this.is_chat_room()) {
      return 'chat with mentor';
    }
    return 'Initialze Chat';
  }

  get_passion_project_proposal() {
    this.socket_service.get_passion_project_proposal(this.passionProject._id).subscribe(proposal_list => {
      if (proposal_list && proposal_list.rooms_data) {
        this.passion_project_mentor_list = proposal_list.rooms_data;
      }
    },
      (err) => {
        console.log(err);
      })
  }

  get_user_chat(user_chat) {
    this.open_chat('', user_chat, 'single');
  }

  get_username(username) {
    let split_value = username.split('-');
    if (split_value.length > 1 && (this.UserRole.matchRole('student') && !this.is_mentor_present())) {
      return split_value[1]
    }
    return username;
  }

  get_length_message() {
    let list = document.getElementById('message');
    let length = list.childNodes.length;
    return length
  }

  get_message() {
    if (this.socket_service.receive_message_view.length > 0) {
      this.my_message.push(this.socket_service.receive_message_view[0]);
      this.socket_service.receive_message_view = [];
    }
    return this.my_message;
  }

  image_url;
  openImgModal(content, img_info) {
    this.image_url = img_info;
    this.modalReview.open(content, { windowClass: 'prevImgModal', centered: true });
  }

  openInNewWindow(viewSubmissions) {
    window.open(viewSubmissions.url);
  }

  download_file() {
    //
  }

  //FOR NEW DESIGN
  onGoBack() {
    this.router.navigate(['/passion-projects']);
  }

  onGetTeamStatus() {
    this.getTeams();
  }
}
