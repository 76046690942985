<div class="banner">
    <div class="container text-center">
        <h1>{{save_organization}}</h1>
        <button class="btn btn-primary" routerLink="/search_organisation"><i class="fas fa-arrow-circle-left mr-2"></i>
        </button>
    </div>
</div>
<div class="pageBody">
    <div class="container">
        <div class="modal-body" [ngClass]="{submitting : loader}">
            <div class="spinner-border text-warning formLoader" role="status" *ngIf="loader">
                <span class="sr-only">Loading...</span>
            </div>
            <div class="rounded embed-responsive-16by9">
                <form class="mw620 floatedInputForm" [ngClass]="{submitting : loader}"
                    [formGroup]="add_organization_form" (ngSubmit)="add_organization()">
                    <dl>
                        <dd>
                            <select class="custom-select"
                                [ngClass]="{ 'is-invalid': submit_org && organization_form.org_type.errors }"
                                formControlName="org_type">
                                <option selected value="" selected>Select Organization type</option>
                                <option value="School" *ngFor="let org of org_type" [value]="org._id">{{org.name}}
                                </option>
                            </select>
                            <label class="flLabel">Organization Type</label>
                            <div *ngIf="submit_org && organization_form.org_type.errors" class="invalid-feedback">
                                <div *ngIf="submit_org && organization_form.org_type.errors.required">Organization Type is required
                                </div>
                            </div>
                        </dd>
                    </dl>
                    <dl>
                        <dd>
                            <input type="text" class="form-control" placeholder=""
                                [ngClass]="{ 'is-invalid':  submit_org && organization_form.organization_name.errors }"
                                formControlName="organization_name">
                            <label class="flLabel">Organisation Name</label>
                            <div *ngIf="submit_org && organization_form.organization_name.errors" class="invalid-feedback">
                                <div *ngIf="submit_org && organization_form.organization_name.errors.required">Organization Name is
                                    required</div>
                            </div>
                        </dd>
                    </dl>
                    <div class="text-right">
                        <button type="button" class="btn btn-primary mr-2" (click)="reset()">Reset</button>
                        <button type="submit" class="btn btn-primary">Save</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>