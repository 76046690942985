<div class="banner wDtls">
  <div class="container text-center">
    <h1 class="text-truncate">Attendance</h1>
    <h2 class="sourceName text-truncate">{{courseTitle}}</h2>
    <p class="mb-2">Session: <span class="badge badge-secondary">{{sessionTitle}}</span></p>

    <button class="btn btn-danger" (click)="redirect()" ngbTooltip="Go Back">
      <i class="fas fa-arrow-left"></i>
    </button>
  </div>
</div>

<div class="pageBody" [ngClass]="{submitting : loader}">
  <div class="container-fluid">
    <div *ngIf="loader">
      <div class="spinner-border text-warning formLoader" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <p class="text-center mb-1"><small>{{fileName}}</small></p>
    <div class="uploadBtnRow">
      <div class="d-inline-block">
        <div class="upBtn">
          <input type="file" accept=".csv" (change)="onuploadFile($event)">
          <button class="custUBtn"><i class="ico mr-2"></i>Upload Sheet</button>
        </div>
        <div class="progress" style="height: 8px;" *ngIf="progress > 0">
          <div class="progress-bar progress-bar-striped bg-success" role="progressbar" [style.width.%]="progress">
          </div>
        </div>
        <p><small>(Upload only <strong>.csv</strong> file)</small></p>
      </div>

      <div class="d-inline-block gn">
        <button class="btn btn-secondary mx-2" [ngClass] = "showGenerate?'active':'inactive'" (click)="onFuzzyMatch('nonlive_attendance')"><i class="fas fa-cog mr-2"></i>Generate</button>
      </div>
    </div>

    <form name="markAttendForm" enctype="multipart/form-data" [formGroup]="markAttendanceForm"
      (ngSubmit)="markAttendance()" *ngIf="subscribersInfo.length>0 && showFuzzymatchedSubscriber">
      <div class="boxesArea">
        <div class="row" formArrayName="subscribers_info">

          <div class="col-12 col-sm-6 col-lg-4 col-xl-3 py-2"
            *ngFor="let subInfo of markAttendanceForm.controls.subscribers_info['controls']; let i=index"
            [formGroupName]="i">
            <div class="markAttBx" [ngClass]="subInfo.controls.attende.value?'attended':'unattended'">
              <label class="checkMarkBox">
                <input type="checkbox" formControlName="attende">
                <span class="ico"></span>
              </label>
              <div class="d-flex align-items-center pRow">
                <figure class="profImg">
                  <img [src]="subInfo.controls.profile_pic.value" alt="">
                </figure>
                <div class="desc">
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="name" [title]="subInfo.controls.username.value"><strong>{{subInfo.controls.username.value}}</strong></div>
                    <div class="org">
                      <span class="badge badge-secondary ml-2" [title]="subInfo.controls.organisation.value">{{subInfo.controls.organisation.value}}</span>
                    </div>
                  </div>
                  <ng-select placeholder="Select" [addTag]="true" formControlName="participant">
                    <ng-option *ngFor="let zoomParticipant of zoom_participants" [value]="zoomParticipant">
                      {{zoomParticipant }}</ng-option>
                  </ng-select>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="pt-3 text-center">
        <button class="btn btn-danger">Save Changes</button>
      </div>
    </form>

    <!-- After Save -->
    <div class="boxesArea" *ngIf = "sessionParticipants.length > 0 && showParticipant">
      <div class="row">
        <div class="col-12 col-sm-6 col-lg-4 col-xl-3 py-2"
          *ngFor="let sessionParticipant of sessionParticipants; let i=index">
          <div class="markAttBx" [ngClass]="sessionParticipant.attended?'attended':'unattended'">
            <div class="d-flex align-items-center pRow">
              <figure class="profImg">
                <img [src]="sessionParticipant.profile_pic" alt="">
              </figure>
              <div class="desc">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="name"><strong>{{sessionParticipant.username}}</strong></div>
                  <div class="org">
                    <span class="badge badge-secondary ml-2" [title]="sessionParticipant.organisation">{{sessionParticipant.organisation}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
</div>