<div class="banner">
    <div class="container text-center">
        <h1>Report</h1>
        <p>Have you found any Bug / Error / Non-working Functionality?</p>
    </div>
</div>

<div class="pageBody">
    <div class="container">
        <form class="mw480 floatedInputForm" [formGroup]="reportForm" [ngClass]="{submitting : loader}">
            <div class="spinner-border text-warning formLoader" role="status" *ngIf="loader">
                <span class="sr-only">Loading...</span>
            </div>
            <dl>
                <dd>
                    <select class="custom-select" formControlName="applicationType">
                        <option value="">Select</option>
                        <option value="mobile application">Mobile Application</option>
                        <option value="web application">Web Application</option>
                    </select>
                    <label class="flLabel">Application Type</label>
                </dd>
            </dl>
            <dl>
                <dd>
                    <select class="custom-select" formControlName="type">
                        <option value="">Select</option>
                        <option value="bug">Bug</option>
                        <option value="Suggesstion">Suggesstion</option>
                        <option value="Query">Query</option>
                    </select>
                    <label class="flLabel">Type</label>
                </dd>
            </dl>

            <dl>
                <dd>
                    <textarea rows="4" cols="12" class="form-control" formControlName="description"
                        placeholder=" "></textarea>
                    <label class="flLabel">Your report</label>
                </dd>
            </dl>
            <dl>
                <dd>
                    <div class="MLSWrraper">
                        <div class="MLSWrraper_add">
                            <i class="fas fa-cloud-upload-alt"></i>
                            <p-fileUpload name="myfile[]" #fileUpload maxFileSize="1000000000" cancelLabel="Clear"
                                customUpload="true" (uploadHandler)="myUploader($event,fileUpload)">
                            </p-fileUpload>
                        </div>
                    </div>
                </dd>
            </dl>
        </form>
    </div>
</div>