import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NotificationService } from 'src/app/services/notification.service';
import { QuestionBankService } from 'src/app/services/question-bank.service';
import { finalize } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
//import * as jsPDF from 'jspdf';
import * as fileSaver from 'file-saver';
import { AssessmentShareService } from 'src/app/services/assessment-share.service';

@Component({
  selector: 'app-view-assessment',
  templateUrl: './view-assessment.component.html',
  styleUrls: ['./view-assessment.component.scss']
})
export class ViewAssessmentComponent implements OnInit {
  @ViewChild('htmlData', { static: false }) htmlData: ElementRef;

  public viewAssmntTable: boolean = false;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings: any = {};
  responseData: any = {};
  loader: boolean = false;
  assessmentId;
  assessmentName = '';
  tableWidth = '';
  assessmentNameB;
  role: string = '';

  constructor(private _questionBankService: QuestionBankService,
    private _notificationService: NotificationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _assessmentShareService: AssessmentShareService) {
    this.activatedRoute.params.subscribe(params => {
      // console.log(params);
      this.assessmentId = params.id;
    })
  }

  ngOnInit() {

    this.role = localStorage.getItem('role');

    this.assessmentName = this._assessmentShareService.getAssessment();
    if (!this.assessmentName) {
      this.assessmentName = localStorage.getItem('assessment_name');
    }
    this.dropdownList = [
      'Type',
      'Option',
      'Answer',
      'Marks',
      'Complexity',
      'Tags'
    ];
    this.selectedItems = [
      // { item_id: 3, item_text: 'Tags' },
      // { item_id: 4, item_text: 'Complexity' }
    ];
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: false
    };

    this.getAssessmentDetails();
  }

  onItemSelect(item: any) {
    this.getAssessmentDetails();
  }
  onSelectAll(items: any) {
    this.selectedItems = items;
    this.getAssessmentDetails();
  }
  onItemDeSelect(item: any) {
    this.getAssessmentDetails();
  }
  onDeSelectAll(items: any) {
    this.selectedItems = items;
    this.getAssessmentDetails();
  }
  viewTable() {
    // this.viewAssmntTable = !this.viewAssmntTable;
  }

  isEmpty(obj) {
    return Object.keys(obj).length;
  }

  getcolspanVal(obj) {
    return Object.keys(obj).length;
  }
  courseList:any = [];
  getAssessmentDetails() {

    this.loader = true;
    this.responseData = {};
    let viewAssessmentObj: any = {};
    viewAssessmentObj.dropdownlist = this.selectedItems;
    viewAssessmentObj.assessment_id = this.assessmentId;

    this._questionBankService.view_assessment(viewAssessmentObj).pipe(
      finalize(() => {
        this.loader = false;
      })).subscribe(
        (response) => {
          this.responseData = response;
          this.courseList = response.courses;
          this.assessmentNameB = response.assessment_name;
          let tbWidth = 0;
          for (let key in response.questions[0]) {

            if (key == 'question_html') tbWidth += 450;

            if (key == 'type') tbWidth += 150;

            if (key == 'option') tbWidth += 200;

            if (key == 'answer') tbWidth += 250;

            if (key == 'marks') tbWidth += 100;

            if (key == 'complexity') tbWidth += 150;

            if (key == 'tags') tbWidth += 100;

          }
          tbWidth = tbWidth + 60;

          if (tbWidth < 1110)
            this.tableWidth = '100%';
          else
            this.tableWidth = tbWidth + 'px';


        },
        (error) => {
          this.loader = false;
          // console.log(error)
          this._notificationService.showNotification("error", "Unable to view assessment please try again later");
        })
  }

/* 
  public openPDF(): void {
    let DATA = this.htmlData.nativeElement;
    let doc = new jsPDF('p', 'pt', [1297, 1210]);

    let handleElement = {
      '#editor': function (element, renderer) {
        return true;
      }
    };

    let margin = {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0
    };
    doc.fromHTML(DATA.innerHTML, 15, 15, {
      'width': 100, // max width of content on PDF
      'elementHandlers': handleElement
    },
      function (bla) { doc.output('dataurlnewwindow'); },
      margin);
  }

  public downloadPDF(): void {
    let that = this;
    let DATA = this.htmlData.nativeElement;
    let doc = new jsPDF('p', 'pt', [1297, 1210]);

    let handleElement = {
      '#editor': function (element, renderer) {
        return true;
      }
    };

    doc.fromHTML(DATA.innerHTML, 15, 15, {
      'width': 400,
      'elementHandlers': handleElement
    },
      function (bla) {
        doc.save(that.assessmentName + '.pdf');
      });


  } */

  generatePdf() {
    this.loader = true;
    let assessmentObj: any = {};
    assessmentObj.dropdownlist = this.selectedItems;
    assessmentObj.assessment_id = this.assessmentId;

    this._questionBankService.generateAssessmentPdf(assessmentObj).pipe(
      finalize(() => {
        this.loader = false;
      })).subscribe(
        (data) => {
          let blob: any = new Blob([data.body], { type: 'application/pdf' });
          let d = new Date();

          let fileName = this.assessmentName + '.pdf';
          fileSaver.saveAs(blob, fileName);
        },
        (error) => {
          this.loader = false;
          this._notificationService.showNotification("error", "Unable to generate pdf");
        })
  }


  go_to_list() {
    this.router.navigate(['/retrieve-assessment']);
  }

  isBlcokTrue: boolean = false;
  itemm = 0;

  nextItem() {
    this.itemm++;
  }
  prevItem() {
    if (this.itemm > 0 || this.itemm != 0) {
      this.itemm--;
    }
    else if (this.itemm === 0) {
      this.sumaryResult = true;
    }
    // console.log(this.itemm);
  }


  goSummary: boolean = false;
  goQustn(v: any, iQst) {
    this.itemm = v.target.id;
    this.isBlcokTrue = false;
    this.sumaryResult = false;
    // this.newArrayItem = [];

    if (this.itemm == iQst) {
      this.goSummary = true;
    }
  }
  goToSummary() {
    this.goSummary = false;
    this.isBlcokTrue = true;
    this.sumaryResult = true;
  }

  sumaryResult: boolean = true;
  reviewOneByOne() {
    this.itemm = 0;
    this.sumaryResult = false;
    this.isBlcokTrue = false;
  }
}
