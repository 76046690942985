import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QuestionShareService {

  private data = new BehaviorSubject<object>({});

  questionData = this.data.asObservable();

  constructor() { }

  setEditQues(passionProject) {
    this.data.next(passionProject);
  }


  // questionData;

  // setEditQues(data){

  //   this.questionData = data
  // }

  // getEditQues() {
  //   return this.questionData;
  // }
}