<div class="banner">
    <div class="container text-center">
        <h1>My Profile</h1>
        <!-- <p>lorem ipsum dolor sit amet</p> -->
    </div>
</div>

  
<div class="pageBody">
    <div class="container">
        <form class="mw480 floatedInputForm" [formGroup]="myProfile" (ngSubmit)="saveProfile()" [ngClass]="{submitting : loader}"> 
                <div class="spinner-border text-warning formLoader" role="status" *ngIf="loader">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <dl>
                        <dd [ngClass]="!onEdit ? 'disableProfileFIeld' : null">
                            <input type="text" class="form-control" formControlName="name" >
                            <label class="flLabel">First Name :</label>                    
                        </dd>
                    </dl>
                    <dl>
                        <dd [ngClass]="!onEdit ? 'disableProfileFIeld' : null">
                            <input type="text" class="form-control" formControlName="last_name">
                            <label class="flLabel">Last Name :</label>                    
                        </dd>
                    </dl>
            <dl>
                <dd [ngClass]="!onEdit ? 'disableProfileFIeld' : null">
                    <input type="text" class="form-control" formControlName="username" >
                    <label class="flLabel">User Name :</label>                    
                </dd>
            </dl>
            <dl>
                <dd [ngClass]="!onEdit ? 'disableProfileFIeld' : null">
                    <input type="email" class="form-control" formControlName="email" readonly>
                    <label class="flLabel">Email Address :</label>                    
                </dd>
            </dl>
            <dl>
                <dd [ngClass]="!onEdit ? 'disableProfileFIeld' : null">
                    <input type="password" class="form-control" formControlName="password" hidden>
                    <label class="flLabel" hidden>Password :</label>
                </dd>
            </dl>

            <dl>
                    <dd Class="disableProfileFIeld">
                        <input type="text" class="form-control" formControlName="institute" readonly>
                        <label class="flLabel">Institute Name :</label>                    
                    </dd>
                </dl>
                <dl>
                        <dd [ngClass]="!onEdit ? 'disableProfileFIeld' : null">
                            <input type="text" class="form-control"  (input)="stripText(myProfile.controls.mobile)" formControlName="mobile" maxlength="10">
                            <label class="flLabel">Mobile Number :</label>                    
                        </dd>
                    </dl>
            <div class="text-right">
                <button type="button" class="btn btn-primary mr-3" (click)="onClickEdit()" ><i class="fas fa-edit mr-2"></i>Edit Profile</button>
                <button type="submit" class="btn btn-secondary">Save Profile<i class="fas fa-check ml-2"></i></button>
            </div>
        </form>
    </div>
</div>


