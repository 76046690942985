<div class="cwBoxOuter projView" [ngClass]="{submitting : loader || zipDownloading}">

  <div class="spinner-border text-warning formLoader mb-2 mt-4" role="status" *ngIf="loader || zipDownloading">
    <span class="sr-only">Loading...</span>
  </div>

  <!-- UPLOADED WORK -->
  <div class="learning-course-items-wrap learning-course-resources-wrap" *ngIf="uploadedWorkFiles.length > 0">
    <div class="text-right mt-4">
      <h6 class="primary-text avenirBlack mb-0 mt-2 float-left">Uploaded Work</h6>
      <button type="button" class="btn btn-grey-primary btn-icon-text pt-2 pb-2 mb-2" (click)="getZipFile()"
        [disabled]="zipDownloading == true || uploadedWorkFiles.length == 0">
        <i class="fa fa-download" aria-hidden="true"></i><strong class="ml-2">Download All</strong>
      </button>
    </div>

    <div class="learning-course-item" *ngFor="let submissions of uploadedWorkFiles; let i=index">
      <div class="learning-course-link">
        <div class="learning-course-elem-left">
          <p class="learning-course-link-title">
            <span class="title-icon"><i class="fas fa-folder"></i></span>
            <strong ngbTooltip="{{submissions.timestamp}}" placement="top">{{submissions.filename}}</strong>
          </p>
        </div>
        <div class="learning-course-elem-right">
          <ul class="list-icon-wrap">
            <li>
              <a class="learning-course-download" (click)="openUploadedWork(submissions.file_path)">
                <i class="fas fa-download"></i>
              </a>
            </li>
            <li *ngIf="isFileDeleteAllowed"
              (click)="deleteUploadedWork(courseId,courseworks.schedule_id,courseworks._id,submissions.file_id,showCourseWork.submission_report,reportIndex, teamName || '')">
              <a><i class="fas fa-trash-alt"></i></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-md-12" *ngIf="!loader && uploadedWorkFiles.length == 0">
      <div class="alert alert-info text-center">
        <p>There are no submissions.</p>
      </div>
    </div>
  </div>

</div>
