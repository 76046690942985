<div class="new_UIstyle">
    <div class="pageBodyWrap">
        <!-- banner section -->
        <div class="banner banner-gradient banner-course-assement" [ngClass]="showStepTwoForm?'d-block':'d-none'">
            <div class="container">
                <div class="banner-content">
                    <div class="banner-content-content">
                        <h2 class="white-text avenirBlack"><!-- Machine Learning A-Z™: <br /> -->
                            {{courseworkTitle}}</h2>

                        <!-- <div class="tags-badges mb-4" *ngIf="editCourseWork">
                            <span class="white-text">Status: </span> 
                            <span class="badge badge-light badge-primary">{{editCourseWork.publish ? 'Published' : 'Draft'}}</span>
                        </div> -->

                        <div class="tags-badges mb-4" *ngIf="courseList.length > 0">
                            <span class="white-text">Course<span *ngIf="courseList.length > 1">s</span>: </span> 
                            <span *ngFor="let course of courseList" class="badge badge-light badge-primary">{{course.course}}</span>
                        </div>

                        <p class="white-text" *ngIf="editCourseWork">Status: 
                            <strong class="ms-2 primary-text">{{editCourseWork.publish ? 'Published' : 'Draft'}}</strong>
                        </p>
                    </div>                    
                </div>
            </div>
        </div>

        <div class="section plan-course-wrap courseWorkView">
            <div class="container">
                <form class="floatedInputForm pt-0 mb-0" [ngClass]="{submitting : loader}">
                    <!-- <div class="spinner-border text-warning formLoader" role="status" *ngIf="loader">
                        <span class="sr-only">Loading...</span>
                    </div> -->

                    <div class="row create-coursework-wrap">
                        <div class="col-md-8 m-auto">
                            <div class="add-course-step add-course-step-title"
                                [ngClass]="showStepOneForm ? 'd-block' : 'd-none' ">
                                <div class="course-title text-center mb-4 teach_data">
                                    <h2>What is the title of the coursework?</h2>
                                    <p>It's ok if can't think of a good title right now, You can always change it later</p>
                                </div>
                                <div class="form-filed mb-4">
                                    <input type="text" name="courseworkTitle" [(ngModel)]="courseworkTitle" #cwTitle=ngModel class="form-control form-control-style-2 form-control-lg" [ngClass]="{ 'is-invalid': isNextBtnClicked && !cwTitle.valid && cwTitle.touched }" [value]="courseworkTitle | capitalization" (change)="onTitleChange()" maxlength="200" placeholder="" required="" />
                                    <div *ngIf="isNextBtnClicked && !cwTitle.valid && cwTitle.touched">
                                        <div *ngIf="cwTitle.errors['required']" class="alert alert-danger mb-0 text-center">Title is required
                                        </div>
                                    </div>
                                </div>
                                <div class="btn-groups justify-content-center">
                                    <button type="button" class="btn btn-grey-dark btn-md btn-w-120"
                                        (click)="onDiscardClicked()">Discard</button>
                                    <button type="button" class="btn btn-primary btn-md btn-w-120"
                                        (click)="formSubmitStepOne(cwTitle)">Next</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

                <form class="floatedInputForm pt-0 mb-0" [ngClass]="{submitting : loader || processLoader}" [formGroup]="addCourseWork" (ngSubmit)="formSubmit(true)">
                    
                    <div class="spinner-border text-warning formLoader" role="status" *ngIf="loader">
                        <span class="sr-only">Loading...</span>
                    </div>

                    <div [ngClass]="showStepTwoForm?'d-block':'d-none'" class="dis_non">
                        <div class="course-card-item-wrap mb-2">
                            <div class="course-card-item course-card-addNew paln_you">

                                <div class="topic-elem-block">

                                    <div class="form-group mb-3">
                                        <label class="avenirBlack">Coursework Topic</label>
                                        <input type="text" class="form-control form-control-style-2 form-control-lg"
                                            [value]="addCourseWork.get('course_work_title').value | capitalization"
                                            [ngClass]="{ 'is-invalid': submitCoursework && addCourseWork.controls.course_work_title.errors }"
                                            placeholder="Enter title" formControlName="course_work_title" (blur)="keyChange($event)">

                                        <div *ngIf="submitCoursework && addCourseWork.controls.course_work_title.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="addCourseWork.controls.course_work_title.errors.required"> Title is required</div>
                                        </div>
                                    </div>

                                    <div class="form-group mb-3">
                                        <label class="avenirBlack">Coursework Category</label>
                                        <select class="form-control form-control-style-2 form-control-lg form-control-caret"
                                            [ngClass]="{ 'is-invalid': submitCoursework && addCourseWork.controls.course_category_id.errors }"
                                            placeholder=" " formControlName="course_category_id">
                                            <option [selected]="true" value="" disabled="">Choose category</option>
                                            <option [value]="option._id" *ngFor="let option of allCategory">{{option.Course_Category}}</option>
                                        </select>
                                        <div *ngIf="submitCoursework && addCourseWork.controls.course_category_id.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="addCourseWork.controls.course_category_id.errors.required"> Category is required
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group mb-3">
                                        <label class="avenirBlack">Coursework Description</label>
                                        <div class="position-relative">
                                            <textarea rows="6" cols="8" class="form-control form-control-style-2 form-control-lg"
                                                [ngClass]="{ 'is-invalid': submitCoursework && addCourseWork.controls.course_work_description.errors }"
                                                placeholder="Enter a learning Objective" formControlName="course_work_description" id="textCourseWork"></textarea>

                                            <div *ngIf="submitCoursework && addCourseWork.controls.course_work_description.errors"
                                                class="invalid-feedback">
                                                <div
                                                    *ngIf="addCourseWork.controls.course_work_description.errors.required">
                                                    Descrption is required</div>
                                            </div>
                                        </div>
                                    </div>

                                </div>


                                <div class="course-card-body">
                                    <div ngbNav #addTopicsResource="ngbNav" [(activeId)]="ResousreActive"
                                        class="nav-tabs nav-tabs-custom border-bottom-0">
                                        <div [ngbNavItem]="1">
                                            <!-- <a ngbNavLink>Coursework Resources</a> -->
                                            <ng-template ngbNavContent>
                                                <label class="avenirBlack">Coursework Resources</label>
                                                <div class="dragDropWrap" (dragover)="onDragOver($event)" (drop)="onDropSuccess($event)" (dragleave)="onDragLeave($event)" [ngClass]="{'fileover': fileOver}">
                                                    <div class="dragDrop dragDropOr">
                                                        <figure class="dragDropIcon"><i
                                                                class="fas fa-cloud-upload-alt"></i>
                                                        </figure>
                                                        <div class="dragDropContent">
                                                            <p><strong class="blue-text d-block">Drag
                                                                    &
                                                                    Drop your file here
                                                                </strong>
                                                                Supports image, video, audio,
                                                                pdf &
                                                                text files</p>
                                                        </div>
                                                        <span class="divideOr"></span>
                                                    </div>

                                                    <div class="dragDropUpload btn-groups">
                                                        <label
                                                        class="custom-upload-btn btn btn-blue btn-shadow btn-icon-text" [for]="'upCourseImageresarr0'">
                                                            <input type="file" #multiFileField class="file-upload-input" 
                                                            [id]="'upCourseImageresarr0'" accept="image/*,video/*,audio/*,doc/*,application/*,text/*" multiple (change)="onSelectMultiResource($event)" />
                                                            <span class=""><i class="fas fa-download"></i>
                                                            Browse Files</span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="mt-4 uploadedItemWrap">
                                                    <div class="uploadedItem" *ngFor="let multiFile of multiFilesData; let idx = index" [ngClass]="{noRemoveBtn: idx == 0}">
                                                        <div class="uploadedItemTop" *ngIf="multiFile.progressBar > 0">
                                                            <div class="uploadedItemDetail">
                                                                <p class="mb-1"><strong
                                                                        class="blue-text">{{multiFile.name}}</strong>
                                                                </p>
                                                                <p class="uploadedItemStatus">
                                                                    <small>{{multiFile.progressBar}}%
                                                                        Complete</small>
                                                                    <!-- <small>{{multiFile.progressTime}}
                                                                        seconds remaining</small> -->
                                                                </p>
                                                            </div>
                                                            <div class="uploadedItemAction">
                                                                <!-- <a href="#" class="uploadedActionLink"><i
                                                                        class="fas fa-pause-circle"></i></a> -->
                                                                <!-- <a href="javascript:void(0)" class="uploadedActionLink"><i
                                                                        class="far fa-times-circle"></i></a> -->
                                                            </div>
                                                        </div>
                                                        <div class="progress uploadedProgress" *ngIf="multiFile.progressBar > 0">
                                                            <div class="progress-bar uploadedProgressBlue"
                                                                role="progressbar" [style.width.%]="multiFile.progressBar" aria-valuenow="multiFile.progressBar"
                                                                aria-valuemin="0" aria-valuemax="100"></div>
                                                        </div>
                                                    </div>

                                                    <div class="uploadedItem" *ngFor="let uploadedFile of uploadedFilesData; let uploadedFileIndex=index">
                                                        <div class="uploadedItemTop" *ngIf="!uploadedFile.resource_deleted">
                                                            <div class="uploadedItemDetail">
                                                                <p class="mb-1">
                                                                    <a href="{{uploadedFile.resource_url}}" target="_blank"><strong class="blue-text">{{uploadedFile.resource_title}}</strong></a>
                                                                </p>                    
                                                            </div>
                                                            <div class="uploadedItemAction">
                                                                <a href="javascript:void(0)" (click)="deleteUploadedResource(uploadedFileIndex)" class="uploadedActionLink">
                                                                <i class="far fa-times-circle"></i>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </div>
                                    </div>

                                    <div [ngbNavOutlet]="addTopicsResource"></div>
                                </div>


                                <!-- <div class="instructorLoopHldr mb-3 resource_name" formArrayName="course_work_resources"
                                    style="min-height: 20px;">
                                    <div class="sectBoxNorms mb-4 resource_tea"
                                        *ngFor="let courseRes of addCourseWork.controls.course_work_resources.controls; let courseWorkResourcesIndex=index"
                                        [formGroupName]="courseWorkResourcesIndex"
                                        [ngClass]="{noRemoveBtn: courseWorkResourcesIndex == 0}">
                                        <div class="whiteBx mb-0">
                                            <button type="button" class="btn bxClose resource_tea2"><i
                                                    class="fas fa-times-circle"
                                                    (click)="removeSubmission(courseWorkResourcesIndex,'course_work_resources', 'manual')"></i></button>

                                            <div class="bxTitle minHe8">
                                                <span class="tl">Resource</span>
                                                <input type="text" class="secTtleInput resource_tea3"
                                                    formControlName="resource_title">
                                                <div class="invalid-feedback">
                                                    <div>Resource title is required</div>
                                                </div>
                                                <ng-container>
                                                    <div class="p-3 mb-4">
                                                        <div class="row mb-3">
                                                            <label class="col-12 col-md-3 pb-2 pb-md-0 m-0 pt0">
                                                                Enter URL: <br><small>or Add
                                                                    File:</small>
                                                            </label>
                                                            <div class="col-12 col-md-9">
                                                                <div class="progress form-group" style="height: 8px;"
                                                                    *ngIf="progressBar > 0 && (uploaded_index == courseWorkResourcesIndex)">
                                                                    <div class="progress-bar progress-bar-striped bg-success"
                                                                        role="progressbar"
                                                                        [style.width.%]="progressBar">
                                                                    </div>
                                                                </div>
                                                                <div class="inputNBrowse">
                                                                    <mat-form-field class="example-full-width">
                                                                        <input type="text" class="form-control"
                                                                            (focus)="setInd(courseWorkResourcesIndex)"
                                                                            matInput
                                                                            [id]="'resource'+courseWorkResourcesIndex"
                                                                            aria-label="Resource"
                                                                            [matAutocomplete]="auto" #inputField
                                                                            [value]="set_resource_val(courseRes.controls.resource_id.value, courseWorkResourcesIndex)"
                                                                            [ngClass]="{ 'is-invalid': submitCoursework && courseRes.controls.resource_url.errors }"
                                                                            (change)="saveType(courseWorkResourcesIndex)">
                                                                        <mat-autocomplete #auto="matAutocomplete">
                                                                            <mat-option *ngFor="let option of results"
                                                                                [value]="option.title"
                                                                                (onSelectionChange)="searchData(option,courseWorkResourcesIndex)">
                                                                                {{ option.title }}
                                                                                <span *ngIf="option.id == ''">(Add
                                                                                    URL)</span>
                                                                            </mat-option>
                                                                        </mat-autocomplete>
                                                                    </mat-form-field>

                                                                    <div class="custom-file fltdBrowse">
                                                                    <input type="file" class="custom-file-input"
                                                                        [id]="'upCourseImageresarr'+courseWorkResourcesIndex"
                                                                        (change)="uploadResourse($event, courseWorkResourcesIndex, 'course_work_resources', courseRes.value.resource_title, 'file')">
                                                                    <label class="custom-file-label"
                                                                        [for]="'upCourseImageresarr'+courseWorkResourcesIndex"></label>
                                                                </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div> 

                                    </div>
                                    <div class="text-right">
                                        <button type="button" (click)="addcourseWorkResource()"
                                            class="resourceAddBtn btn btn-primary btn-sm">
                                            <i class="fas fa-plus-circle mr-2"></i>Add Resource
                                        </button>
                                    </div>
                                </div> -->

                                <div class="topic-elem-block mb-4">
                                    <label class="avenirBlack">Coursework Tags</label>
                                    <div class="form-group mb-3">

                                        <tag-input [formControlName]="'tag'" class="form-control form-control-style-2 form-control-lg" [addOnBlur]="true" [clearOnBlur]="true"
                                            [secondaryPlaceholder]="'Enter tags'" [displayBy]="'tag'"
                                            [identifyBy]="'_id'"
                                            [ngClass]="{ 'is-invalid': submitCoursework && addCourseWork.controls.tag.errors }">
                                            <tag-input-dropdown [displayBy]="'tag'" [identifyBy]="'_id'" [autocompleteObservable]="onInputTag">
                                            </tag-input-dropdown>
                                        </tag-input>

                                        <div *ngIf="submitCoursework && addCourseWork.controls.tag.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="addCourseWork.controls.tag.errors.required"> Tag is required
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <hr class="dashed-hr" />
                                <div class="mb-3 mt-4"><label class="avenirBlack">Submission Schedule</label></div>
                                <div formArrayName="submission_requirement"
                                    *ngFor="let opt of submissionOptions; let submissionId = index;">
                                <div class="modal_cont float-right">
                                    <button type="button" class="close-btn lg-close" *ngIf="submissionId != 0"
                                        (click)="removeSubmission(submissionId, 'submission_requirement', 'manual')"><i class="fa fa-trash" aria-hidden="true"></i></button>    
                                </div>
                                <div class="plan-course-content-body cour_pl mb-4">

                                    <div [formGroupName]="submissionId">
                                        <div class="topic-elem-block mb-2">
                                            <div class="form-group mb-3">
                                                <label class="avenirBlack">Submission Name</label>
                                                <input type="text" class="form-control form-control-style-2 form-control-lg" [ngClass]="{ 'is-invalid': submitCoursework && submissionOptions[submissionId].controls.submission_report
                                                .errors }" formControlName="submission_report"
                                                [value]="submissionOptions[submissionId].get('submission_report').value | capitalization" placeholder="Enter title">
                                                <div *ngIf="submitCoursework && submissionOptions[submissionId].controls.submission_report.errors"
                                                class="invalid-feedback">
                                                <div
                                                    *ngIf="submissionOptions[submissionId].controls.submission_report.errors.required">
                                                    Submission Name is required
                                                </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="pe-2 ps-2">
                                            <div class="row">
                                                <div class="col-md-6 mb-2 mb-md-0">
                                                    <div class="form-group mb-3">
                                                        <label class="avenirBlack">Submission Marks (Overall Percentage: {{totalPercentage}} %)</label>
                                                        <input type="text"
                                                            (input)="stripText(opt.controls['total_number_of_percentage'], 'Percentage')"
                                                            [ngClass]="{ 'is-invalid': submitCoursework && submissionOptions[submissionId].controls.total_number_of_percentage.errors }"
                                                            class="form-control form-control-style-2 form-control-lg"
                                                            formControlName="total_number_of_percentage" placeholder="Enter marks">
                                                        <div *ngIf="submitCoursework && submissionOptions[submissionId].controls.total_number_of_percentage.errors"
                                                            class="invalid-feedback">
                                                            <div
                                                                *ngIf="submissionOptions[submissionId].controls.total_number_of_percentage.errors.required">
                                                                Marks Percentage is
                                                                required</div>
                                                            <div
                                                                *ngIf="submissionOptions[submissionId].controls.total_number_of_percentage.errors.min">
                                                                Marks can't be zero</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group mb-3">
                                                        <label class="avenirBlack">Days (Coursework Timeline: {{TotalDays}} {{TotalDays > 1 ? 'Days' : 'Day'}})</label>
                                                        <input type="text"
                                                            (input)="stripText(opt.controls['days_of_completion'], 'days')"
                                                            [ngClass]="{ 'is-invalid': submitCoursework && submissionOptions[submissionId].controls.days_of_completion.errors }"
                                                            class="form-control form-control-style-2 form-control-lg"
                                                            formControlName="days_of_completion" placeholder="Enter days">
                                                        <div *ngIf="submitCoursework && submissionOptions[submissionId].controls.days_of_completion.errors"
                                                            class="invalid-feedback">
                                                            <div
                                                                *ngIf="submissionOptions[submissionId].controls.days_of_completion.errors.required">
                                                                Day is
                                                                required</div>
                                                            <div
                                                                *ngIf="submissionOptions[submissionId].controls.days_of_completion.errors.min">
                                                                Day can't be zero</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <label class="avenirBlack">Rubric</label>
                                            <!-- <h6 class="avenirBlack mb-2">Rubric</h6> -->
                                            <textarea rows="3" cols="6"
                                                [value]="submissionOptions[submissionId].get('rubric').value | capitalization"
                                                class="form-control form-control-style-2 form-control-lg" formControlName="rubric"
                                                [ngClass]="{ 'is-invalid': submitCoursework && submissionOptions[submissionId].controls.rubric.errors }" placeholder="Enter rubric">
                                            </textarea>
                                            <div *ngIf="submitCoursework && submissionOptions[submissionId].controls.rubric.errors"
                                                class="invalid-feedback">
                                                <div
                                                    *ngIf="submissionOptions[submissionId].controls.rubric.errors.required">
                                                    Rubric is
                                                    required</div>
                                            </div>
                                        </div>

                                        <div class="d-inline-block pl-2 py-1 col-md-12 radio_but">
                                            <div class="text-left">
                                                <div class="autograde-flex1">
                                                <label class="avenirBlack mr-4">This submission is presentation</label>
                                                <label class="switch">
                                                    <div class="custom-control custom-checkbox mb-2">
                                                        <input type="checkbox" class="custom-control-input"
                                                            [id]="'onlinePresentationChk'+(submissionId)"
                                                            formControlName="online_presentation">

                                                        <span class="slider round"></span>
                                                        <label class="slider round" class="custom-control-label d-none"
                                                            [for]="'onlinePresentationChk'+(submissionId)">
                                                        </label>
                                                    </div>
                                                </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="d-inline-block pl-2 py-1 col-md-12 radio_but">
                                            <div class="text-left">
                                                <div class="autograde-flex1">
                                                    <label class="avenirBlack">Autograde this submission</label>
                                                    <div class="custom-control custom-checkbox mb-2 ml-3">
                                                        <label class="switch">
                                                            <input type="checkbox" class="custom-control-input"
                                                                [id]="'autograde'+(submissionId)"
                                                                (click)="check_auto_grad($event, submissionId)"
                                                                [attr.readonly]="is_disabled(submissionId)"
                                                                formControlName="autograding"
                                                                [value]="submissionId">
                                                            <span class="slider round"></span>
                                                            <label class="custom-control-label d-none"
                                                                [for]="'autograde'+(submissionId)"></label>
                                                        </label>
                                                    </div>                                             
                                                </div>
                                            </div>
                                        </div>

                                        <div class="modal_cont pt-0" *ngIf="submissionOptions[submissionId].controls.autograding.value">
                                            <hr class="dashed-hr" />

                                            <div class="modal-body1">
                                                <div class="resourseInputOut">
                                                    <form [formGroup]="allTestCases[submissionId]">
                                                        <div class="resourseTestItem form-group"
                                                        formArrayName="text_case">
                                                        <div *ngFor="let textcase of allTestCases[submissionId].controls.text_case['controls']; let test_index = index;"
                                                        [formGroupName]="test_index">
                                                        <label><b>Test case {{test_index+1}}</b></label>
                                                        <div class="resourseTestItemCont">
                                                        <label><b>test case Inputs</b></label>
                                                        <div class="testCaseInputWrapper" formArrayName="_inputarr">  
                                                        <div *ngFor="let input_arr of textcase.controls._inputarr['controls']; let input_index = index;"
                                                        [formGroupName]="input_index">
                                                        <button class="close-btn lg-close" type="button"
                                                        *ngIf="test_index != '0'"
                                                        (click)="delete_test_case('text_case', test_index, '', submissionId)"><i
                                                        class="fa fa-trash"
                                                        aria-hidden="true"></i></button>
                                                        <div class="form-group">
                                                            <!-- <label>Input</label> -->
                                                            <!-- <div class="resourseTestInputCont"> -->
                                                                <div class="form-group">
                                                                    <!-- <label *ngIf="input_index == 0"><b>test case Inputs</b></label> -->
                                                                    <div class="resourseTestInputBlock">
                                                                        <input type="text"
                                                                        formControlName="input"
                                                                        class="form-control form-control-style-2 form-control-lg" placeholder="Enter input here" [ngClass]="{ 'is-invalid': submitCoursework && input_arr.controls.input.errors }" />
                                                                        <button
                                                                        class="close-btn sm-close bt-delete"
                                                                        type="button"
                                                                        *ngIf="input_index != '0'"
                                                                        (click)="delete_test_case('_inputarr', test_index, input_index, submissionId)"><i
                                                                        class="fa fa-trash"
                                                                        aria-hidden="true"></i></button>
                                                                        <div *ngIf="submitCoursework && input_arr.controls.input.errors"
                                                                            class="invalid-feedback">
                                                                            <div *ngIf="input_arr.controls.input.errors.required">
                                                                            Input is required    
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <!-- </div> -->
                                                            </div>
                                                        </div>
                                                        <div class="text-left">
                                                            <a class="textBtn primary-text avenirBlack"
                                                            (click)="add_inputs(test_index, submissionId)">+ Add
                                                        Input</a>
                                                        </div>
                                                        </div>

                                                        <div class="testCaseOutput">
                                                        <div class="form-group">
                                                            <label><b>test case Output</b></label>
                                                            <input type="text" formControlName="output"
                                                            class="form-control form-control-style-2 form-control-lg" placeholder="Enter output here" [ngClass]="{ 'is-invalid': submitCoursework && textcase.controls.output.errors }" />
                                                            <div *ngIf="submitCoursework && textcase.controls.output.errors"
                                                                class="invalid-feedback">
                                                                <div *ngIf="textcase.controls.output.errors.required">
                                                                Output is required    
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </div>

                                                        </div>                                                              
                                                        </div>

                                                        <div class="form-group text-left">
                                                            <a href="javascript:void(0)" class="textBtn primary-text avenirBlack" (click)="add_test_case(submissionId)">+ Add New Test
                                                        Case</a>
                                                        </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>

                                            <hr class="dashed-hr" />
                                        </div>

                                        <!-- <div class="modal_footer1 btn_group text-right">
                                            <button class="btn btn-primary" (click)="save_test_cases()"
                                                type="button">Add Submission</button>
                                        </div> -->

                                    </div>
                                </div>
                                </div>

                                <div class="textBtn-groups mt-4">
                                    <a class="textBtn primary-text avenirBlack" (click)="addsubmission()">+ Add New Submission</a>
                                </div>
                                <hr>

                                <div class="d-inline-block pl-0 py-1 col-md-12 radio_but">
                                    <div class="text-left">
                                        <div class="autograde-flex1">
                                            <label class="avenirBlack mr-4">Is Group Project?</label>
                                                <label class="switch mr-4">
                                                <div class="custom-control custom-checkbox mb-2">
                                                    <input type="checkbox" class="custom-control-input"
                                                        [id]="'isgroupChk'"
                                                        formControlName="is_group">
                                                    <span class="slider round"></span>
                                                    <label class="slider round" class="custom-control-label d-none"
                                                        [for]="'isgroupChk'">
                                                    </label>
                                                </div>
                                            </label>
                                            <label *ngIf="addCourseWork.value.is_group" class="avenirBlack mr-4">Group Size</label>
                                            <div *ngIf="addCourseWork.value.is_group">    
                                                <input type="text" class="form-control form-control-style-2 form-control-lg"
                                                (input)="stripText(addCourseWork.controls.group_size, 'group_size')" placeholder=" "
                                                formControlName="group_size" [ngClass]="{ 'is-invalid': submitCoursework && addCourseWork.controls.group_size.errors }">
                                                <div *ngIf="submitCoursework && addCourseWork.controls.group_size.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="addCourseWork.controls.group_size.errors">
                                                        Group Size is required
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="text-right">
                                    <div class="spinner-border text-warning formLoader d-inline-block mr-4 inline-loader" role="status" *ngIf="processLoader">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <button class="btn btn-primary mr-2" type="button" (click)="onDiscardClicked()" [disabled]="processLoader">Discard</button>
                                    <button *ngIf="course_work_id" class="btn btn-primary mr-2" type="button" (click)="onResetClicked()" [disabled]="processLoader">Reset</button>
                                    <button type="button" class="btn btn-primary" [disabled]="save_draft || processLoader"
                                        (click)="formSubmit(false)">Save as Draft</button>
                                    <button type="submit" class="btn btn-primary ml-2" [disabled]="processLoader">Publish</button>
                                </div>

                            </div>
                        </div>
                    </div>

                </form>
            </div>
        </div>
    </div>
</div>


<!-- <ng-template #content let-modal>
    <div class="modal_cont">
        <div class="modal-header modal_cont_Header">
            <h3>Add Test Cases</h3>
            <button class="close" type="button"><span aria-hidden="true" (click)="back()">×</span></button>
        </div>
        <div class="modal-body">
            <div class="resourseInputOut">
                <form [formGroup]="testCases">
                    <div class="resourseTestItem form-group" formArrayName="text_case">
                        <div *ngFor="let textcase of testCases.controls.text_case['controls']; let test_index = index;"
                            [formGroupName]="test_index">
                            <label>Test Case - {{test_index+1}}</label>
                            <div class="resourseTestItemCont" formArrayName="_inputarr">
                                <div *ngFor="let input_arr of textcase.controls._inputarr['controls']; let input_index = index;"
                                    [formGroupName]="input_index">
                                    <button class="close-btn lg-close" type="button" *ngIf="test_index != '0'"
                                        (click)="delete_test_case('text_case', test_index, '')">X</button>
                                    <div class="form-group">

                                        <div class="form-group">
                                            <label>Input - {{input_index+1}}</label>
                                            <div class="resourseTestInputBlock">
                                                <input type="text" formControlName="input" class="form-control" />
                                                <button class="close-btn sm-close" type="button"
                                                    *ngIf="input_index != '0'"
                                                    (click)="delete_test_case('_inputarr', test_index, input_index)">X</button>
                                            </div>
                                        </div>
 
                                    </div>
                                </div>
                                <div class="text-right">
                                    <button class="btn btn-primary sm-btn" (click)="add_inputs(test_index)">+ Add
                                        Input</button>
                                </div>
                            </div>

                            <div class="form-group">
                                <label>Output</label>
                                <input type="text" formControlName="output" class="form-control" />
                            </div>

                        </div>

                        <div class="form-group text-right">
                            <button class="btn btn-primary" type="button" (click)="add_test_case()">+ Add New Test
                                Case</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <hr class="dashed-hr" />
        <div class="modal_footer btn_group text-right">
            <button class="btn btn-default" (click)="back()" type="button">Cancel</button>
            <button class="btn btn-primary" (click)="save_test_cases()" type="button">Save</button>
        </div>
    </div>
</ng-template> -->

<!-- old code start -->