export const environment = {
  production: true,
  APIEndpoint: 'https://prod.api.edu-collab.com',
  socket_EndPoint :'https://prod.chatapp.edu-collab.com',
  imageBasePath:'assets/images/',
  userDefaultProfilePic: 'avatar-default.jpg',
  userDefaultGroupPic: 'team-avtars.png',
  groupProfilePicSize: 20,
  client__id:'370977595915-mivuo942fsdra3v2deq6qi5o4ojlns32.apps.googleusercontent.com',
};
