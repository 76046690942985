<div class="banner">
    <div class="container text-center">
        <h1>Add User</h1>
    </div>
</div>

<div class="pageBody">
    <div class="container">
        <form class="mw480 floatedInputForm" [formGroup]="registrationForm" [ngClass]="{submitting : loader}"
            (ngSubmit)="onRegistration(registrationForm.value)" id="registration">
            <div class="spinner-border text-warning formLoader" role="status" *ngIf="loader">
                <span class="sr-only">Loading...</span>
            </div>
            <dl>
                <dd>
                    <select class="custom-select form-control"
                        [ngClass]="{ 'is-invalid': submitRegistration && userRegistration.role.errors }"
                        formControlName="role" [value]="selectedRole" required>
                        <option selected value="">Select Role</option>
                        <option [value]="role.name" *ngFor="let role of roles">{{role.displayName}}</option>
                    </select>
                    <label class="flLabel">Role *</label>
                    <div *ngIf="submitRegistration && userRegistration.role.errors" class="invalid-feedback">
                        <div *ngIf="userRegistration.role.errors.required"> Role is required</div>
                    </div>

                </dd>
            </dl>

            <dl>
                <dd>
                    <input type="text" class="form-control"
                        [ngClass]="{ 'is-invalid': submitRegistration && userRegistration.first_name.errors }"
                        placeholder=" " formControlName="first_name" required>
                    <label class="flLabel">First Name *</label>
                    <div *ngIf="submitRegistration && userRegistration.first_name.errors" class="invalid-feedback">
                        <div *ngIf="userRegistration.first_name.errors.required"> First Name is required</div>
                        <div *ngIf="userRegistration.first_name.errors.pattern">Please add correct First Name</div>
                    </div>
                </dd>
            </dl>

            <dl>
                <dd>
                    <input type="text" class="form-control"
                        placeholder="" formControlName="last_name">
                    <label class="flLabel">Last Name</label>
                </dd>
            </dl>
            <dl>
                <dd>
                    <input type="text" class="form-control" placeholder=" " formControlName="username">
                    <label class="flLabel">User Name</label>
                </dd>
            </dl>
            <dl>
                <dd>
                    <input type="text" class="form-control"
                        [ngClass]="{ 'is-invalid': submitRegistration && userRegistration.email.errors }"
                        placeholder=" " formControlName="email" required>
                    <label class="flLabel">Email *</label>
                    <div *ngIf="submitRegistration && userRegistration.email.errors" class="invalid-feedback">
                        <div *ngIf="userRegistration.email.errors.required"> Email is required</div>
                        <div *ngIf="userRegistration.email.errors.email"> Email is not correct</div>
                    </div>
                </dd>
            </dl>
            <dl>
                <dd>
                    <select class="custom-select" formControlName="organisation"
                        [ngClass]="{ 'is-invalid': submitRegistration && userRegistration.organisation.errors }">
                        <option selected value="">Select Organisation</option>
                        <option *ngFor="let org of organisation" [value]="org.slug">{{org.name}}</option>
                    </select>
                    <label class="flLabel">Organisation Name</label>
                    <div *ngIf="submitRegistration && userRegistration.organisation.errors" class="invalid-feedback">
                        <div *ngIf="userRegistration.organisation.errors.required"> Institute is required</div>
                    </div>
                </dd>
            </dl>
            <dl>
                <dd>
                    <select class="custom-select" formControlName="org_type"
                        [ngClass]="{ 'is-invalid': submitRegistration && userRegistration.org_type.errors }"
                        (change)="selectType($event)">
                        <option selected value="">Select option</option>
                        <option *ngFor="let organization of organization_type" [value]="organization.slug">
                            {{organization.name}}
                        </option>
                    </select>
                    <label class="flLabel">Organisation Type *</label>
                    <div *ngIf="submitRegistration && userRegistration.org_type.errors" class="invalid-feedback">
                        <div *ngIf="userRegistration.org_type.errors.required"> Type is required</div>
                    </div>
                </dd>
            </dl>

            <dl>
                <dd>
                    <select class="custom-select" formControlName="grade"
                        [ngClass]="{ 'is-invalid': submitRegistration && userRegistration.grade.errors }">
                        <option selected value="">Select option</option>
                        <option *ngFor="let grade of grades" [value]="grade.value">
                            {{grade.name}}
                        </option>
                    </select>
                    <label class="flLabel">Grade *</label>
                    <div *ngIf="submitRegistration && userRegistration.grade.errors" class="invalid-feedback">
                        <div *ngIf="userRegistration.grade.errors.required"> Grade is required</div>
                    </div>
                </dd>
            </dl>
            <dl>
                <dd>
                    <input type="password" class="form-control" placeholder=" " formControlName="password">
                    <label class="flLabel">Password</label>
                </dd>
            </dl>
            <dl>
                <dd>
                    <input type="password" class="form-control" placeholder=" " formControlName="userConfirmPassword">
                    <label class="flLabel">Confirm Password</label>
                </dd>
            </dl>

            <p class="btnHldr text-right">
                <button type="submit" class="btn btn-primary">Add User<i class="fas ml-2"></i></button>
            </p>
            <!-- <hr>
            <p class="text-center"><a routerLink="/login">Have you already registered? Click here to log in.</a></p> -->
        </form>
    </div>
</div>