import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'dateFormatFullmonth'
})
export class DateFormatFullmonthPipe implements PipeTransform {

  constructor(private datePipe: DatePipe) {}	

  transform(value: any, ...args: any[]): any {
  	
  	let result = value;

  	if(value) {
  		result = new Date(value.year, value.month - 1, value.day);
  		result = this.datePipe.transform(result, 'MMMM d, y');
  	}
    
    return result;
  }

}
