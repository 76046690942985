import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Resource } from 'src/app/model/resource';
import { CourseService } from 'src/app/services/course.service';
import { NotificationService } from 'src/app/services/notification.service';
import { QuestionBankService } from 'src/app/services/question-bank.service';
import { QuestionService } from 'src/app/services/question.service';
import { ResourceService } from 'src/app/services/resources/resource.service';
import { SharedResourceService } from 'src/app/services/resources/shared-resource.service';
import { UploadFilesService } from 'src/app/services/upload-files.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-add-resources',
  templateUrl: './add-resources.component.html',
  styleUrls: ['./add-resources.component.scss']
})
export class AddResourcesComponent implements OnInit, OnDestroy {
  public sharedResourceSubscription: Subscription;
  public resource: Resource;
  type = 'file';
  resource_id = '';
  page_name = 'Add Resource';
  submissionTags: any = [];
  submitResource = false;
  choose_file = 'Choose file...';
  constructor(private fb: FormBuilder,
    private resource_service: ResourceService,
    private router: Router,
    private route: ActivatedRoute,
    private ns: NotificationService,
    private _questionBankService: QuestionBankService,
    private courseService: CourseService,
    private modalService: NgbModal,
    private shared_resource: SharedResourceService,
    private UFS: UploadFilesService,
    private questionService:QuestionService) { }
  public addResourceFrom: FormGroup;
  ngOnInit() {
    this.getAllTags();
    this.validateForm();
    this.resource_id = this.route.snapshot.queryParamMap.get('resource_id');
    if (this.resource_id) {
      this.get_resource();
      this.page_name = 'Edit Resource';
    }
  }

  validateForm(): void {
    this.addResourceFrom = this.fb.group({
      resource_id: ['', Validators.required],
      resource_title: ['', Validators.required],
      description: ['', Validators.required],
      tags: ['', Validators.required],
      file: [''],
    });
  }

  get_resource() {
    this.sharedResourceSubscription = this.shared_resource
      .getResouce
      .subscribe(
        res => {
          if (Object.keys(res).length < 1) return this.router.navigate(['/resource-bank']);
          this.resource = res as Resource;
          this.update_form();
          if (this.resource) {
              this.resourceCondition(this.resource.url, this.uploadedMedia)
            }
        }
      );

  }

  update_form() {
    this.type = 'text';
    const tags = [];
    this.resource.tags.forEach(val => tags.push(val));
    this.addResourceFrom.patchValue({
      resource_title: this.resource.title,
      resource_id: this.resource._id,
      description: this.resource.description,
      file: this.resource.url,
      tags: tags,
    });


  }

  getAllTags() {
    this._questionBankService.fetchTags().pipe(finalize(() => {
    })).subscribe(data => {
      this.submissionTags = data.message;
    });
  }

  progressBar = 0
  upload_resource(event, controlName, resourceName, type) {
    let file;
    if (event.target.files) {
      file = event.target.files[0];
    }

    this.choose_file = file.name;

    this.ns.upload_resource('global-resources', file).pipe(
      finalize(() => {
      })).subscribe(
        (response: HttpEvent<any>) => {
          switch (response.type) {
            case HttpEventType.Sent:
              break;
            case HttpEventType.ResponseHeader:
              break;
            case HttpEventType.UploadProgress:
              this.progressBar = Math.round(response.loaded / response.total * 100);
              break;
            case HttpEventType.Response:
              this.addResourceFrom.controls.resource_id.setValue(response.body.resource_info[0].resource_id);
              this.ns.showNotification('success', 'file uploaded successfully');
              setTimeout(() => {
                this.progressBar = 0;
              }, 1500);
          }

        },
        (err) => {
          this.progressBar = 0;
          this.ns.showNotification('info', 'Please add again not able to save your file');
        })
  }


  formSubmit(action) {
    if (this.resource_id) {
      this.edit_resource()
    } else {
      this.add_resource()
    }
  }

  add_resource() {
    this.submitResource = true;
    if (this.progressBar != 0) {
      this.ns.showNotification('info', "Your file is not uploaded yet.");
      return;
    }

    if (!this.addResourceFrom.value.resource_id) {
      this.ns.showNotification('info', "please upload the file");
      return;
    }

    if (this.addResourceFrom.invalid) {
      this.ns.showNotification('info', "Please correct the form");
      return;
    }
    this.resource_service.add_resource(this.addResourceFrom.value).subscribe(get_info => {
      this.ns.showNotification('success', 'Resource added successfully');
      this.addResourceFrom.reset();
      this.submitResource = false;
      this.choose_file = 'Choose file...';
      this.router.navigate(['/resource-bank']);

    },
      (err) => {
        this.ns.showNotification('error', err.message);
        this.submitResource = false;
      })
  }

  edit_resource() {
    this.submitResource = true;
    if (this.addResourceFrom.invalid) {
      this.ns.showNotification('info', "Please correct the form");
      return;
    }
  
    this.resource_service.edit_resource(this.addResourceFrom.value, this.resource.instance_id).subscribe(get_info => {
      this.ns.showNotification('success', get_info.message);
      this.submitResource = false;
      this.choose_file = 'Choose file...';
      this.router.navigate(['/resource-bank']);
    },
      (err) => {
        this.ns.showNotification('error', err.message);
        this.submitResource = false;
      })
  }


  // ADD NEW CODE FOR RE-DESIGN  

  resourceCondition(getArrayItem, setArrayItem) {
    
      let filePath = getArrayItem;
      let fileNameSplitA = getArrayItem.split('/');
      let fileNameSplitB = fileNameSplitA[4].split('__');
      //let getFileName = fileNameSplitB[0];
      let extension = fileNameSplitB.pop().split('.');
      let getFileName = fileNameSplitB[0] + '.' + extension[1].split('?');
      let getFileNameA = getFileName.split(',')
      setArrayItem.push({
        FileName: getFileNameA[0],
        FileProgessSize: 100,
        FileProgress: 100,
        path: filePath
      });
    
  }

  
  ngOnDestroy() {
    if (this.sharedResourceSubscription) this.sharedResourceSubscription.unsubscribe();
  }


  // ADD NEW CODE FOR RE-DESIGN 

  selectFilelenghth;
  moduleName;
  browseFileLen = 0;
  selectedArray;
  fileSizeLimit: number;
  fileSize: number = 0;
  mediaFileSize: number = 0;
  uploadedMedia: Array<any> = [];
  uploadArray = [];
  onFileBrowse(event: Event) {
    const target = event.target as HTMLInputElement;
    let browseFile = target.files;
    this.browseFileLen = browseFile.length;
    this.uploadArray = [];
    this.processFiles(target.files);
  }
  processFiles(files) {
    let maxLimitItem = 1;
    this.fileSizeLimit = 20000000;
    this.selectedArray = this.uploadedMedia.length;
    let moreAllowItem = maxLimitItem - this.selectedArray;
    let fileLength = files.length;

    if (fileLength < moreAllowItem || fileLength == moreAllowItem) {
      for (let i = 0; i < files.length; i++) {
        let file = files[i];
        if (this.validateFile(file.name)) {
          this.ns.showNotification("info", 'Selected file format is not supported.');
          this.browseFileLen--;
        } else {
          let hasItem = this.uploadedMedia.find(s => s.FileName === file.name);
          if (!hasItem) {
            this.selectFilelenghth = this.selectedArray + this.browseFileLen;
            var reader = new FileReader();
            reader.readAsDataURL(file); // read file as data url
            reader.onload = (event: any) => {
              // called once readAsDataURL is completed

              let fileType = file.type;
              this.moduleName = 'global-resources';

              this.uploadedMedia.push({
                FileName: file.name,
                FSB: file.size,
                FileSize:
                  this.UFS.getFileSize(file.size) +
                  ' ' +
                  this.UFS.getFileSizeUnit(file.size),
                FileType: file.type,
                FileUrl: event.target.result,
                FileProgessSize: 0,
                FileProgress: 0,
                Id: '',
                path: '',
                abc:false
              });
              this.startProgress(file, this.uploadedMedia.length - 1, this.moduleName);
            }

          } else {
            this.ns.showNotification("info", 'File already added.');
            this.browseFileLen--;
            this.restUploadFile();
          }
        }
      }
      if (this.uploadedMedia.length > 0) {
        for (let m = 0; m < this.uploadedMedia.length; m++) {
          this.mediaFileSize += parseInt(this.uploadedMedia[m].FSB);
        }
      } else if (this.uploadedMedia.length < 1 || this.uploadedMedia.length == undefined) {
        this.mediaFileSize = 0;
      }
      let newSize = this.mediaFileSize + this.fileSize;
    } else {
      this.ns.showNotification("info", 'Only 1 file allowed.');
      this.browseFileLen = 0;
    }
  }
  savevisible: boolean = true;
  async startProgress(file, index, module_name) {
    let filteredFile = this.uploadedMedia
      .filter((u, index) => index === index)
      .pop();

    if (filteredFile != null) {
      let fileSize = this.UFS.getFileSize(file.size);
      let fileSizeInWords = this.UFS.getFileSizeUnit(file.size);
      if (this.UFS.isApiSetup) {
        let formData = new FormData();
        formData.append('resource', file);

        this.UFS
          .uploadMedia(formData, module_name)
          .subscribe(
            (res: any) => {
              if (res.resource_info == undefined) {
                this.savevisible = false;
                
              } else {
                this.savevisible = true;
                this.uploadArray.push(res.resource_info)
              }

              this.uploadedMedia[index].abc = this.savevisible;

              if (res.status === 'progress') {
                let completedPercentage = parseFloat(res.message);

                filteredFile.FileProgessSize = `${(
                  (fileSize * completedPercentage) /
                  100
                ).toFixed(2)} ${fileSizeInWords}`;
                filteredFile.FileProgress = completedPercentage;

                let pro = this.uploadedMedia.find(s => s.FileProgress != 100)

              } else if (res.status === 'completed') {
                filteredFile.Id = res.resource_id;
                filteredFile.path = res.path;

                filteredFile.FileProgessSize = fileSize + ' ' + fileSizeInWords;
                filteredFile.FileProgress = 100;
              }

              if (res.resource_info) {
                filteredFile.Id = res.resource_info[0].resource_id;
                filteredFile.path = res.resource_info[0].path;
              this.addResourceFrom.controls.resource_id.setValue(res.resource_info[0].resource_id);

                this.restUploadFile();
              }
            },
            (error: any) => {
              console.log(error);
            }
          );
      } else {
        for (
          var f = 0;
          f < fileSize + fileSize * 0.0001;
          f += fileSize * 0.01
        ) {
          filteredFile.FileProgessSize = f.toFixed(2) + ' ' + fileSizeInWords;
          var percentUploaded = Math.round((f / fileSize) * 100);
          filteredFile.FileProgress = percentUploaded;
          await this.fakeWaiter(Math.floor(Math.random() * 35) + 1);
        }
      }
    }
    
  }

  fakeWaiter(ms: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  validateFile(name: String) {
    var ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() == 'exe') {
      return true;
    }
    else {
      return false;
    }
  }
 

  restUploadFile() {
    let tsnString = (document.getElementById("fileU1") as HTMLTextAreaElement).value = ''; 
   // this.fileUploadClear1.nativeElement.value = "";
  }

  questionModel = null;
  openModelDeleteRes(deleteRes) {
    this.questionModel = this.modalService.open(deleteRes, { size: "sm", centered: true });
  }

  closeResPopup() {
    this.questionModel.close();
    // this.questionId = null;
  }


  deleteResouce(item, idx: number) {
    let sp = item.path;
    //let fileTitleSplit = sp.split('/');
    //let fileTitle = fileTitleSplit[1];
      this.uploadedMedia = this.uploadedMedia.filter((u, index) => index !== idx);
      this.selectFilelenghth = this.uploadedMedia.length;
      this.questionService.deleteResource(sp).subscribe(res => {
        this.ns.showNotification("success", res.message);
        this.questionModel.close();
        this.addResourceFrom.controls.resource_id.setValue('');
        this.restUploadFile();
        if(this.browseFileLen > 0){
          this.browseFileLen--;
        }
        if(this.uploadArray.length > 0){
          this.uploadArray.length--;
        }
      })
      if (this.selectFilelenghth == 0 || this.selectFilelenghth < 1) {
        this.restUploadFile();
      } 
  }
  
  //FOR BUILD FIX
  loader: boolean = false;
}
