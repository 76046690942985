import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbRatingConfig } from '@ng-bootstrap/ng-bootstrap';
import { allCategory, CourseInfo } from 'src/app/model/course.model';
import { CourseService } from 'src/app/services/course.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ShareService } from 'src/app/services/share.service';

@Component({
  selector: 'app-deactivated-courses',
  templateUrl: './deactivated-courses.component.html',
  styleUrls: ['./deactivated-courses.component.scss']
})
export class DeactivatedCoursesComponent implements OnInit {

  @Input() allCategory: allCategory[] = [];
  @Input() categoryVisDetails: CourseInfo[] = [];
  @Input() total_pages: number;
  @Input() current_page: number;
  @Input() number_of_page;
  @Output() send_index_number_deactivate = new EventEmitter();

  currentRate: number;
  role = '';
  loader = false;
  get permissions() {
    return JSON.parse(localStorage.getItem("permissions"));
  }

  constructor(
    private courseService: CourseService,
    private router: Router,
    private ns: NotificationService,
    rateConfig: NgbRatingConfig,
    private shareService: ShareService) {
    rateConfig.readonly = true;
    this.role = localStorage.getItem('role');
  }

  ngOnInit() {
    this.send_index_number_deactivate.emit('1+deactivate');
  }

  sendCourseDetails(id, course) {
    this.router.navigate(['/courses/single-course'], { queryParams: { course_id: id, course: course } });
  }

  redirect(link) {
    this.router.navigate([link]);
  }

  findIndexOf(slug, pmenusSlug) {
    let indexOfValue;
    indexOfValue = this.shareService.getIndexArr(slug, pmenusSlug)
    return indexOfValue;
  }

  Send_index(number) {
    this.send_index_number_deactivate.emit(number + '+deactivate');
  }

  check_previous(type) {
    if (type == 'Previous') {
      return this.current_page == this.number_of_page[0] ? true : false;
    }
  }

  check_next(type) {
    if (type == 'Next') {
      return this.current_page == this.total_pages ? false : true;
    }
  }

  get_number(number_of_page) {
    if (number_of_page != undefined) {
      return number_of_page
    }
    return 1;
  }

  activate_course(course_id) {
    if (confirm('Are you sure you want to reactivate this course again ?')) {
      this.courseService.activate_course(course_id).subscribe(activate_course => {
        this.ns.showNotification('success', activate_course.message);
        this.send_index_number_deactivate.emit(this.current_page + '+deactivate');
      },
        (err) => {
          this.ns.showNotification('error', err.message);
        })
    }
  }
}
