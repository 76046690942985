import { Component, OnInit, Input, Output, SimpleChanges, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from '../../services/notification.service';
import { CourseService } from '../../services/course.service';
import { Renderer2 } from '@angular/core';

@Component({
  selector: 'app-course-work-marking-remark',
  templateUrl: './course-work-marking-remark.component.html',
  styleUrls: ['./course-work-marking-remark.component.scss']
})
export class CourseWorkMarkingRemarkComponent implements OnInit {

  @Input() reportIndex: any;
  @Input() userId: any;
  @Input() teamName: any;
  @Input() isSuperAdmin: any;
  @Input() isTeachingAssistant: any;
  @Input() isPublishRight: any;
  @Input() showCourseWork: any;
  @Input() courseworks: any;
  @Input() courseId: any;
  @Input() teachingAssistant: any;
  @Input() submissionData: any;
  @Output() sendReviewSavedStatus = new EventEmitter();

  processLoader: boolean = false;
  role: string = '';
  loggedInUserId: string = '';

  viewSubmissionData: any[] = [];
  reviewForm!: FormGroup;
  showReport: boolean = false;
  showReviewForm: boolean = false;
  submissionReportDetail: any = {};
  selfReviewReport: any = '';
  peerReviewReport: any = '';
  remarkReport: any = '';
  gradeReport: any = '';
  showSelfReviewEditBtn: boolean = false;
  showPeerReviewEditBtn: boolean = false;
  submissionPublishStatus: boolean = false;
  editReviewType: string = '';

  //GRADE REVIEW
  findedIndex: number = 0;
  showGradeForm: boolean = false;
  showGradeReviewEditBtn: boolean = false;
  editBtnClicked: boolean = false;
  editBtnRef: string = '';

  isSubmissionDataLoading: boolean = false;
  gradeFormAction: string = '';

  constructor(private fb: FormBuilder, private notifyService: NotificationService, private courseService: CourseService, private renderer: Renderer2) { }

  ngOnInit() {
    this.role = localStorage.getItem('role');
    this.loggedInUserId = localStorage.getItem('user_id');
    this.createReviewForm();

  }

  createReviewForm() {
    this.reviewForm = this.fb.group({
      review: ['', [Validators.required, Validators.maxLength(2000)]],
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.submissionData.firstChange) {
      this.viewSubmissionData = changes.submissionData.currentValue.viewSubmissionData;
      if (this.viewSubmissionData.length > 0) {
        //console.log(this.viewSubmissionData);
        this.manageSubmissionReportData();
        //console.log(this.submissionReportDetail);
        if (Object.keys(this.submissionReportDetail).length > 0) {
          this.manageSubmissionReport();
        }
      }
    }
    this.isSubmissionDataLoading = false;
  }

  manageSubmissionReportData() {
    this.submissionPublishStatus = this.submissionData.submissionPublish;
    this.submissionReportDetail = this.viewSubmissionData[this.findedIndex].submissions[this.showCourseWork.submission_report];
    this.checkPremission();
  }

  checkPremission(){
    let isCheckTruue = (!this.viewSubmissionData[0].is_reassigned == true && !this.viewSubmissionData[0].marked_status == false) || (this.viewSubmissionData[0].is_reassigned == false && this.viewSubmissionData[0].marked_status == false)
    return isCheckTruue;
  }

  //SHOW HID REPORT DISAPLY AND REVIEW FORM
  manageSubmissionReport() {
    this.showReviewForm = false;

    if (this.courseworks.is_group == false) {
      if (this.submissionReportDetail.self_review.content) {
        this.selfReviewReport = this.submissionReportDetail.self_review.content;
      }
      this.checkReviewEditAllowed('self_review');

      if (this.submissionReportDetail.peer_review.content) {
        this.peerReviewReport = this.submissionReportDetail.peer_review.content;
      }
      this.checkReviewEditAllowed('peer_review');
    }

    if (this.submissionReportDetail.grade.content) {
      this.gradeReport = this.submissionReportDetail.grade.content;
      this.checkReviewEditAllowed('grade');
    }

    if (this.submissionReportDetail.remark.content) {
      this.remarkReport = this.submissionReportDetail.remark.content;
      this.checkReviewEditAllowed('remark');
    }

    //SHOW HIDE REPORT
    if (this.submissionReportDetail.files.length > 0) {
      this.showReport = true;
    }
    else {
      this.showReport = false;
    }

    //SHOW HIDE REVIEW FORM
    this.showReviewForm = false;

    //SHOW HIDE GRADE FORM
    this.showHideGradeForm();
  }

  showHideGradeForm() {
    if (this.submissionReportDetail.files.length > 0) {
      if (this.showGradeReviewEditBtn == false) {
        if(this.isTeachingAssistant == true && this.submissionPublishStatus == true) {
          this.showGradeForm = false;
        }
        else {
          this.showGradeForm = true;
        }
      }
      else {
        this.showGradeForm = false;
      }
    }
    else {
      this.showGradeForm = false;
    }
  }

  checkReviewEditAllowed(reviewType) {
    if (reviewType == 'self_review') {
      if (this.isSuperAdmin == true) {
        this.showSelfReviewEditBtn = true;
      }
      else {
        this.showSelfReviewEditBtn = false;
      }
    }
    else if(reviewType == 'peer_review') {
      if (this.isSuperAdmin == true) {
        this.showPeerReviewEditBtn = true;
      }
      else {
        this.showPeerReviewEditBtn = false;
      }
    }
    else if (reviewType == 'remark' || reviewType == 'grade') {
      if (this.gradeReport || this.remarkReport) {
        if(this.isTeachingAssistant == true && this.submissionPublishStatus == true) {
          this.showGradeReviewEditBtn = false;
        }
        else {
          this.showGradeReviewEditBtn = true;
        }
      }
      else {
        this.showGradeReviewEditBtn = false;
      }
    }
  }

  onEditReview(editReviewType) {
    this.editReviewType = editReviewType;

    if(this.editReviewType == 'self_review' || this.editReviewType == 'peer_review') {
      this.showHideGradeForm();
    }
    else {
      this.showGradeForm = false;
    }

    this.showReviewForm = false;

    if (this.editReviewType == 'self_review') {
      this.showReviewForm = true;
      this.reviewForm.get('review').setValue(this.selfReviewReport);
      setTimeout(() => { this.renderer.selectRootElement('#reviewInputBox').focus(); }, 100);
    }
    else if (this.editReviewType == 'peer_review') {
      this.showReviewForm = true;
      this.reviewForm.get('review').setValue(this.peerReviewReport);
      setTimeout(() => { this.renderer.selectRootElement('#reviewInputBox').focus(); }, 100);
    }
    else if (this.editReviewType == 'grade' || this.editReviewType == 'remark') {
      this.showGradeForm = true;
      this.editBtnClicked = !this.editBtnClicked;
      this.editBtnRef = this.editReviewType;
    }
  }

  onSaveReview() {
    //console.log(this.reviewForm);
    if (!this.validateForm()) {
      return;
    }
    const review = this.reviewForm.value.review;
    this.saveReview(review, this.editReviewType);
  }

  saveReview(review, reviewType) {
    const submissionIndex = this.findedIndex;
    const submissionInfo = this.viewSubmissionData[submissionIndex];
    const submissionReportUserId = this.viewSubmissionData[submissionIndex].user_id;
    const submissionReportName = this.showCourseWork.submission_report;
    let submissionReportData = this.viewSubmissionData[submissionIndex].submissions[submissionReportName];

    if (submissionReportData.files.length == 0) {
      this.notifyService.showNotification('info', 'User has not submitted any file for this submission.');
      return;
    }

    if (reviewType == 'peer_review') {
      submissionReportData.peer_review = { content: review }
    }

    if (reviewType == 'self_review') {
      submissionReportData.self_review = { content: review }
    }

    let grade = '0';
    let feedback = '';
    let peerReview = '';
    let selfReview = '';

    let teamName = '';
    if (this.courseworks.is_group) {
      teamName = submissionInfo.name;
    }

    if (reviewType == 'peer_review') {
      peerReview = submissionReportData.peer_review.content ? submissionReportData.peer_review.content : '';
    }

    if (reviewType == 'self_review') {
      selfReview = submissionReportData.self_review.content ? submissionReportData.self_review.content : '';
    }

    let body = {
      user_id: submissionReportUserId,
      team_name: teamName,
      course_work_id: this.courseworks._id,
      schedule_id: this.courseworks.schedule_id,
      course_id: this.courseId,
      grade: grade,
      feedback: feedback,
      submission_name: submissionReportName,
      submission_index: '' + submissionReportData.index,
      peer_review: peerReview,
      self_review: selfReview
    }

    this.processLoader = true;
    this.courseService.add_remark_grade_course_work(body).subscribe(view_remark => {
      this.isSubmissionDataLoading = true;
      this.processLoader = false;
      this.onDiscardReview();
      this.sendReviewSavedStatus.emit({ status: true });
      this.notifyService.showNotification('success', "Review saved successfully.");
    },
      (err) => {
        this.processLoader = false;
        this.notifyService.showNotification('error', err.message);
      });
  }

  validateForm() {
    this.reviewForm.get('review').setValue(this.reviewForm.get('review').value.trim());
    if (this.reviewForm.controls['review'].invalid) {
      if (this.reviewForm.get('review').hasError('required')) {
        this.notifyService.showNotification("error", "Review can not be empty.");
        return false;
      }
      if (this.reviewForm.get('review').hasError('maxlength')) {
        this.notifyService.showNotification("error", "Review may not be greater than 2000 characters.");
        return false;
      }
      return false;
    }

    return true;
  }

  getGradeReviewSavedStatus(data) {
    if(data.action == 'save') {
      this.isSubmissionDataLoading = true;
      this.sendReviewSavedStatus.emit({ status: true });
    }
    this.showGradeForm = false;
    this.editBtnRef = '';
  }

  onDiscardReview() {
    this.editReviewType = '';
    this.showReviewForm = false;
    this.reviewForm.reset();
    this.reviewForm.get('review').setValue('');
  }

}
