import { Component, OnInit } from '@angular/core';

import { NgbDropdownConfig, NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { UserService } from 'src/app/services/user.service';
import { UserStatus } from 'src/app/model/user.model';
import { NotificationService } from 'src/app/services/notification.service';
import { HeaderService } from 'src/app/services/header.service';

@Component({
  selector: 'app-personal-info',
  templateUrl: './personal-info.component.html',
  styleUrls: ['./personal-info.component.scss'],
  providers: [NgbDropdownConfig, NgbModalConfig, NgbModal]
})
export class PersonalInfoComponent implements OnInit {
  active = 'pinfo';
  incompetence: boolean;
  modalRef: any;
  imgData: any = {};
  selectedUserTypeId: any;
  profileloader: boolean;
  
  constructor(config: NgbDropdownConfig,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private notifciationService: NotificationService,
    private headerService: HeaderService,
    configMod: NgbModalConfig, private modalService: NgbModal) {
    config.autoClose = true;
    configMod.backdrop = 'static';
    configMod.keyboard = false;
  }

  userLevels = [];
  selectedStatBorder: string = 'Beginner';

  loader = false;
  editProfile: any;
  public myProfile: FormGroup;

  ChangeStatBorder(val) { 
    this.selectedUserTypeId = val._id;
    this.selectedStatBorder = val.user_type;
  }


  ngOnInit() {
    this.validateProfileForm();
    this.getUserLevels();
    this.getProfileDetails();
    
  }

  getUserLevels(){
    this.loader = true;
    this.userService.userLevels().pipe(
      finalize(() => {
        this.loader = false;
      })).subscribe((res) => {
        this.userLevels = res.data;
      })    
  }

  getProfileDetails(){
    this.profileloader = true;
    this.userService.getProfileData().pipe(
      finalize(() => {
        this.profileloader = false;
      })).subscribe((res) => {
        this.editProfile = res;
        if(!this.editProfile.avatar_path) {
          this.imgData.path = "";
          this.editProfile.avatar_path = "";
        }
        else {
          this.imgData.path = this.editProfile.avatar_path;
        }
        this.imgData.url = this.editProfile.avatar_url;
        this.headerService.setLogo(this.imgData.url);
        localStorage.setItem('avatarURL', this.imgData.url);
        this.selectedStatBorder = this.editProfile.user_type == ""?'Beginner':this.editProfile.user_type;
        this.setValueInEditProfile();
      })
      
  }

  validateProfileForm(){
    
      this.myProfile = this.formBuilder.group({
        username: ['', Validators.required],
        first_name: ['', Validators.required],
        last_name: [''],
        email: ['',[ Validators.email, Validators.required]],
        about: [''],
        role: ['',Validators.required],
        institute: ['', Validators.required],
        mobile: ['', [Validators.min(10)]],
        facebook: [''],
        linkedin: [''],
        avatar_url: ['', null],
      });
    
  }

  
  stripText(control) {
    control.setValue(control.value.replace(/[^0-9]/g, ''));
  }

  setValueInEditProfile(){
    this.myProfile.controls['first_name'].setValue(this.editProfile.first_name);
    this.myProfile.controls['last_name'].setValue(this.editProfile.last_name);
    this.myProfile.controls['username'].setValue(this.editProfile.username);
    this.myProfile.controls['role'].setValue(this.editProfile.role);
    this.myProfile.controls['email'].setValue(this.editProfile.Email);
    this.myProfile.controls['about'].setValue(this.editProfile.about);
    this.myProfile.controls['institute'].setValue(this.editProfile.Institute);
    this.myProfile.controls['mobile'].setValue(this.editProfile.mobile);
    this.myProfile.controls['facebook'].setValue(this.editProfile.facebook_url);
    this.myProfile.controls['linkedin'].setValue(this.editProfile.linkedin_url);
    this.myProfile.controls['avatar_url'].setValue(this.editProfile.avatar_path);
    this.myProfile.controls['role'].setValue(this.editProfile.role);

  }

  saveProfile(){
    let fbpattern = /^(https?:\/\/)?(www\.)?facebook.com\/[a-zA-Z0-9(\.\?)?]/
    if (this.myProfile.value.facebook != '' && !fbpattern.test(this.myProfile.value.facebook)) {
      this.notifciationService.showNotification('info', 'Please add correct url for facebook profile');
      return false;
    }
    let linkedinPattern = /((https?:\/\/)?((www|\w\w)\.)?linkedin\.com\/)((([\w]{2,3})?)|([^\/]+\/(([\w|\d-&#?=])+\/?){1,}))$/gm
    if (this.myProfile.value.linkedin != '' && !linkedinPattern.test(this.myProfile.value.linkedin)) {
      this.notifciationService.showNotification('info', 'Please add correct url for linkedin profile');
      return false;
    }

    if(this.myProfile.invalid){
      this.notifciationService.showNotification("info", "Please fill all the mandate fields");
      return;
    }
    this.myProfile.value.user_type = this.selectedStatBorder;
    this.loader = true;
    this.userService.userProfile(this.myProfile.value).pipe(
      finalize(() => {
        this.loader = false;
      })).subscribe((Response) => {
        this.notifciationService.showNotification("success", "Saved successfully");
      },
      (error) => {
        let msg = '';
        msg = error.message?error.message:"Please try again later there is something wrong";
        this.notifciationService.showNotification("error", msg);
    }
      )
  }

  getAvatarPath(data){
    this.imgData = {};
    this.imgData = JSON.parse(data);
    this.myProfile.controls['avatar_url'].setValue(this.imgData.path);
    this.headerService.setLogo(this.imgData.url);
    localStorage.setItem('avatarURL', this.imgData.url);
    this.modalRef.close();
     
  }
  openAvLibrary(avatarLibrary) {
    this.modalRef = this.modalService.open(avatarLibrary);
  }

}
