import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { NotificationService } from 'src/app/services/notification.service';
import { QuestionBankService } from 'src/app/services/question-bank.service';
import { ResourceService } from 'src/app/services/resources/resource.service';
import { SharedResourceService } from 'src/app/services/resources/shared-resource.service';

@Component({
  selector: 'app-import-question',
  templateUrl: './import-question.component.html',
  styleUrls: ['./import-question.component.scss']
})
export class ImportQuestionComponent implements OnInit {

  qusTypes: any = [];
  notPresent: boolean = false;
  csvQues: any[];
  coursesData: string;
  courses:string
  ques_type: string;
  fileToUpload: File = null;
  fileName = 'No File Selected';
  loader = false;
  importForm: boolean
  importQuesList: boolean
  importedQues: any = [];
  isQusAvailable: boolean = false;
  qusList: any[];
  multiFilesData: any[] = [];

  constructor(private resource_service: ResourceService,
    private ns: NotificationService,
    private router: Router,
    private shared_resouce: SharedResourceService,
    private _questionBankService: QuestionBankService,
    private _notificationService: NotificationService) { }
  @ViewChild('fileInput', { static: false }) fileInput: any;
  @ViewChild("fileU1", { static: false }) input: ElementRef;




  ngOnInit() {
    this.getAllQusTypes();
    this.importForm = true;

    window.addEventListener('resize', (event: UIEvent) => {
      const w = event.target as Window; 
      console.log(w.innerWidth) // works!
    });
    // let tableWrap = document.querySelector('.questionBankUploadTable .table');
    // let tableWidth = tableWrap.innerWidth;
    // console.log(tableWidth);
    
  }

  getImportedQus() {
    this._questionBankService.fetchImportedQus().pipe(finalize(() => {
    })).subscribe(data => {
      this.importedQues = data.questions;
    });
  }

  onImportedQuesChange(eve) {

    if (eve) {
      this.isQusAvailable = true;
      this.notPresent = false;
      this.qusList = this.importedQues;
    } else {
      this.isQusAvailable = false;
      // this.notPresent = true;
    }
  }


  getAllQusTypes() {
    this._questionBankService.fetchQusType().pipe(finalize(() => {
    })).subscribe(data => {
      this.qusTypes = data.ques_type;
    });
  }

  selectQtype(ques_type, index, type) {
    this.ques_type = ques_type
  }

  handleFileInput(files) {
    this.fileToUpload = files.target.files[0];
    this.fileName = this.fileToUpload.name;
  }

 

  get_question_type() {
    if (this.ques_type) {
      let question_type = this.qusTypes.find(type => type.value == this.ques_type);
      return question_type.name;
    }
    return '';
  }
  download_file() {
    let url = '';

    if (this.ques_type == 'text') {
      url = '../assets/import/Question_Text.csv';
    } else if (this.ques_type == 'bool') {
      url = '../assets/import/Question_binary.csv';
    } else if (this.ques_type == 'singleanswermcq') {
      url = '../assets/import/Question_SingleMCQ.csv';
    } else if (this.ques_type == 'fib') {
      url = '../assets/import/Question_FIB.csv';
    } else if (this.ques_type == 'multianswermcq') {
      url = '../assets/import/Question_MCQ.csv';
    } else {
      url = '../assets/import/Question_Text.csv';
    }

    window.open(url, '_blank');
  }

  invalid;
  save_questions() {
    this.invalid = this.csvQues.findIndex(csv => csv.category == undefined);
    if (this.invalid > -1) {
      this._notificationService.showNotification('info', 'Please add the category in every question or make as same for all');
      return;
    }
    this._questionBankService.save_ques(this.csvQues).subscribe(data => {
      this.csvQues = [];
      this._notificationService.showNotification('success', 'Your questions added successfully');
      this.notPresent = false;
      this.fileName = 'Choose file';
      this.fileToUpload = null;
      this.router.navigate(['/question-bank']);

    })
  }

  //  FOR UPLOAD US

  totSelFiles: number = 0;
  processedFiles: number = 0;
  uploadMultiResource(ques_type) {
    this.multiFilesData = [];
    this.uploadSingleResource(0, this.fileToUpload, ques_type);

  }

  uploadSingleResource(idx, file, ques_type) {
    if (file) {
      const fileName = file.name;
      let progressBar = 0;
      let progressTime = 0;
       if(ques_type == undefined){
        this._notificationService.showNotification('info', 'Please Select Question Category');
        return
       }
      

      // this.loader = true;
      if(file.type == 'text/csv'){
      let startDate = new Date;
      this._questionBankService.uploadTextQus(this.fileToUpload, ques_type).pipe(
        finalize(() => {
          // this.loader = false;
        })).subscribe(
          (response: HttpEvent<any>) => {
            switch (response.type) {
              case HttpEventType.Sent:
                break;
              case HttpEventType.ResponseHeader:
                break;
              case HttpEventType.UploadProgress:
                progressBar = Math.round(response.loaded / response.total * 100);

                //TIME CALCULATION  
                let currDate = new Date;
                let timeElapsed: number = currDate.getTime() - startDate.getTime();
                let uploadSpeed = response.loaded / (timeElapsed / 1000);
                let seconds = (file.size - response.loaded) / uploadSpeed;
                progressTime = Math.round(seconds);

                this.multiFilesData[idx] = { name: fileName, progressBar: progressBar, progressTime: progressTime, uploadStatus: false };
                break;
              case HttpEventType.Response:
                this.csvQues = response.body.records;
                this._notificationService.showNotification("success", response.body.message.toUpperCase());
                this.importQuesList = true;
                this.notPresent = true
                this.importForm = false;
                this.loader = false;
                this.fileToUpload = null;
                this.ques_type = undefined;
                this.restUploadFile();               
                setTimeout(() => {
                  
                  progressBar = 0;
                  progressTime = 0;
                  this.multiFilesData[idx] = { name: fileName, progressBar: progressBar, progressTime: progressTime, uploadStatus: true };
                  //  FOR RESET FILE BROWSE
                  this.fileToUpload = null;
                  this.multiFilesData = []
                  this.fileName = 'No File Selected';
                }, 1500);
            }
          },
          (err) => {
            progressBar = 0;
            progressTime = 0;
            this.fileToUpload = null;
            this.fileName = 'No File Selected';
            this.restUploadFile();
            this.multiFilesData[idx] = { name: fileName, progressBar: progressBar, progressTime: progressTime, uploadStatus: false };
            this._notificationService.showNotification('info', err.message);
          });
          this.getAllCourses();
        }else{
          this._notificationService.showNotification('info', 'Only Csv file are allowed');
          
        }
    }
  }

  validateOption() {
    if ((this.ques_type == 'singleanswermcq') || (this.ques_type == 'multianswermcq') || (this.ques_type == 'bool')) {
      return true
    }
    return false
  }

  get_answer(answer) {
    let ans = answer.split('~');
    return JSON.stringify(ans);
  }

 
  checked = false;
  coursesDataCat: any[]
  sameForAll(event) {
    if (!this.coursesData) {
      event.target.checked = !event.target.checked;
      this._notificationService.showNotification('info', 'Please Select the category from the category list');
      return;
    }
    this.checked = false;
    if (event.target.checked) {
      this.checked = true;
      this.csvQues.filter(csvques => {
        csvques.category = this.coursesData
      })
    } else {
      return;
    }
  }

  getAllCourses() {
    this.loader = true;
    this._questionBankService.categoryAPI().pipe(finalize(() => {
      this.loader = false;
    })).subscribe(data => {
      this.courses = data;
    });
  }

  selectCourseCat(value, csvQues) {
    this.coursesData = value;
    csvQues.category = value;
    if(this.checked) {
      this.checked = false;
    }
    //  if(this.coursesData != )
  }

  goBack(){
    if(this.notPresent){
      this.importForm = true
      this.notPresent = false
    }else{
      this.fileToUpload = null;
      this.multiFilesData = []
      this.fileName = 'No File Selected';
      this.restUploadFile();
      this.router.navigate(['/question-bank']);
    }

  }

  restUploadFile() {
    let tsnString = (document.getElementById("fileU1") as HTMLTextAreaElement).value = '';

  }

}

