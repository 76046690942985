<div class="cwBoxOuter projView mt-4" [ngClass]="{submitting : processLoader || isSubmissionDataLoading}">

	<!-- REVIEW TO PEER FORM -->
	<div *ngIf="showGradeReviewForm">
		<div class="form-group">
			<div>
				<form [formGroup]="gradeReviewForm" autocomplete="off">
					<div class="row" [hidden]="editBtnRef && editBtnRef == 'remark'">
						<div *ngIf="editBtnRef && editBtnRef == 'grade'" class="col-md-1 primary-text grade-display-heading">
							<span class="cw-grade-heading">Grade :</span>
						</div>
						<div class="" [ngClass]="{'col-md-10': editBtnRef && editBtnRef == 'grade', 'col-md-6': !editBtnRef}">
							<label *ngIf="!editBtnRef" class="avenirBlack"><strong>Grade / marks</strong></label>
							<input *ngIf="!editBtnRef || editBtnRef == 'grade'" class="form-control form-control-lg reviews-input" formControlName="grade"
								id="gradeInputBox" placeholder="Give grade to student" type="text"
								(input)="stripText(gradeReviewForm.get('grade'))">
						</div>
					</div>
					<div class="mt-4">
						<label *ngIf="!editBtnRef" class="avenirBlack"><strong>Remarks</strong></label>
						<textarea *ngIf="!editBtnRef || editBtnRef == 'remark'" type="text" formControlName="review" id="remarkInputBox"
							class=" form-control reviews-input" cols="10" rows="6"
							[value]="(gradeReviewForm.get('review').value || '') | capitalization"
							placeholder="Enter remarks here"></textarea>
					</div>
					<div class="text-center mt-4">
						<div class="spinner-border text-warning formLoader d-inline-block mr-4 inline-loader"
							*ngIf="processLoader" role="status">
							<span class="sr-only">Loading...</span>
						</div>
						<button *ngIf="editBtnRef" type="button" class="btn btn-primary btn-w-120 mr-2"
							(click)="onDiscardReview(editBtnRef,'discard')" [disabled]="processLoader">
							Discard
						</button>
						<button type="button" class="btn btn-primary btn-w-120" (click)="onSaveReview()"
							[disabled]="processLoader">
							Save
						</button>
					</div>
				</form>
			</div>
		</div>
	</div>

</div>
