import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CapitalizationPipe } from '../pipe/capitalization.pipe';



@NgModule({
  declarations: [
    CapitalizationPipe
  ],
  exports:[
    CapitalizationPipe
  ],
  imports: [
    CommonModule
  ]
})
export class CommonAppModuleModule { }
