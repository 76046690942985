import { Component, OnInit } from '@angular/core';
import { BugReport } from '../services/bugReport.service';
import { finalize } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NotificationService } from '../services/notification.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {

  selectedFiles: FileList;
  fileName: string;
  reportForm: FormGroup;
  loader = false;
  constructor(private bugReport: BugReport, private router: Router, private fb: FormBuilder, private ns: NotificationService) { }

  detectFiles(event) {
    this.selectedFiles = event.target.files;
    this.fileName = this.selectedFiles[0].name;
    console.log(this.fileName);
  }


  ngOnInit() {
    this.validateForm();
  }

  validateForm() {
    this.reportForm = this.fb.group({
      applicationType: ['', Validators.required],
      description: [''],
      type: ['', Validators.required]

    })
  }

  myUploader(event, fileUpload): void {
    
    if (this.reportForm.invalid ) {
      this.ns.showNotification("info", "Please fill the form");
      return;
    }

    if (event.files.length == 0  || this.reportForm.controls['description'].value.length < 0) {
      this.ns.showNotification("info", "No file found");
      return;
    }
    
    var fileToUpload = event.files[0];

    const body = {
      file: fileToUpload,
      applicationType: this.reportForm.get('applicationType').value,
      description: this.reportForm.get('description').value,
      type: this.reportForm.get('type').value,
    };

    if (confirm("Are you sure you want to upload these uses?")) {
      this.loader = true;
      this.bugReport.bugReportApi(body).subscribe(
        (bugResponse) => {
         // this.router.navigate(['/report-list']);
          fileUpload.clear();
          this.reportForm.reset();
          this.loader = false;
          this.ns.showNotification("success",bugResponse.message)
        },
        (error) => {
          this.loader = false;
      });
    }
  }
}
