import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';

import { finalize } from 'rxjs/operators';

import { UserService } from '../services/user.service';
import { NotificationService } from '../services/notification.service';
import { Router } from '@angular/router';
import { BulkUploadService } from '../services/bulk-upload.service';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {
  registrationForm: FormGroup;
  submitRegistration = false;
  loader = false;
  public selectedRole: string;
  roles: any;
  grades = [];
  organisation = [];
  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private ns: NotificationService,
    private router: Router,
    private bulkUpload: BulkUploadService
  ) { }

  ngOnInit() {
    this.validateRegistrationForm();
    this.getRoles();
    this.getOrganizationType();
    this.getOrganisation();
  }

  getOrganisation() {
    this.ns.getOrganisation().subscribe(orgnosation_list => {
      this.organisation = orgnosation_list.data;
    })
  }

  organization_type = [];
  getOrganizationType() {
    this.bulkUpload.getOrganizationType().subscribe(organozation => {
      this.organization_type = organozation;
    })
  }

  validateRegistrationForm(): void {
    this.registrationForm = this.formBuilder.group({
      username: ['', [Validators.maxLength(50)]],
      first_name: ['', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(100),
        Validators.pattern('^[a-zA-Z]*$')]],
      last_name: [''],
      email: ['', [Validators.required,
      Validators.email,
      Validators.maxLength(280),
      Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]],
      role: ['', Validators.required],
      organisation: ['', [Validators.required, Validators.maxLength(300)]],
      org_type: ['', Validators.required],
      grade: ['', Validators.required],
      password: ['', [Validators.maxLength(30)]],
      userConfirmPassword: ['']
    },
      {
        validator: this.userService.MustMatch('password', 'userConfirmPassword')
      });
  }

  getRoles() {
    this.userService
      .getRoles()
      .subscribe(
        (data) => {
          this.roles = data.roles_info;
        }
      );

  }

  selectType(event) {
    const type = event.target.value;
    this.grades = [];
    let n = 6;
    type == 'school' ? n = 12 : n = 6;
    for (let i = 0; i < n; i++) {
      this.grades.push({
        name: i + 1,
        value: i + 1
      });
    }
  }

  get userRegistration() { return this.registrationForm.controls; }

  onRegistration(value): void {
    this.submitRegistration = true;
    if (this.registrationForm.invalid) {
      this.ns.showNotification('info', 'Please enter correct info');
      return;
    }
    if (this.registrationForm.value.last_name != '') {
      let lastname = this.registrationForm.value.last_name;
      if (lastname.length < 3) {
        this.ns.showNotification('info', 'Last name length should be greater than or equal to 3')
        return;
      } else if (lastname.length > 100) {
        this.ns.showNotification('info', 'Last name length should be leass than or equal to 100')
        return;
      } else if (!lastname.match('^[a-zA-Z]*$')) {
        this.ns.showNotification('info', 'Last name only allow character')
        return;
      }
    }

    if (this.registrationForm.value.username != '') {
      let username = this.registrationForm.value.username;
      if (username.length < 3) {
        this.ns.showNotification('info', 'Username length should be greater than or equal to 3')
        return;
      } else if (username.length > 50) {
        this.ns.showNotification('info', 'Username length should be leass than or equal to 50')
        return;
      } else if (!username.match('^[a-zA-Z0-9]*$')) {
        this.ns.showNotification('info', 'Username only allow alpha numeric')
        return;
      }
    }

    this.loader = true;
    this.userService
      .signUp(value)
      .pipe(
        finalize(() => {
          this.loader = false;
          this.submitRegistration = false;
        }))
      .subscribe(
        (response) => {
          this.ns.showNotification('success', 'You are successfully registered please login');
          this.registrationForm.reset();
          this.registrationForm.controls.username.setValue('');
        },
        (error) => this.ns.showNotification('error', error.message)
      );
  }

}