import { Component, OnInit, OnDestroy, Renderer2, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { finalize } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ShareService } from 'src/app/services/share.service';
// import { topRatedCourse } from '../model/course.model';
import { CourseService } from '../services/course.service';
import { NgbRatingConfig } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from '../services/notification.service';
import { MonitoringShareService } from '../services/monitoring-share.service';
import { tr } from 'date-fns/locale';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  currentRate: number;
  courseId = null;
  routerUrl;
  courseName = null;
  popupModel = null;
  demoUrl = "https://youtu.be/_WbvXCvkWlo"
  discoverCourse: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    autoWidth:false,
    navSpeed: 700,
    navText: ['<i class="fas fa-angle-left"></i>', '<i class="fas fa-angle-right"></i>'],
    nav: true,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },

  }

  subscribeCourse: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['<i class="fas fa-angle-left"></i>', '<i class="fas fa-angle-right"></i>'],
    nav: true,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },

  }
  category_id: any;
  // course_category_id: any;
  first_name: string

  //FOR HOMEPAGE API SEPERATION
  loaderRecentSessions: boolean = false;
  loaderRecommendedCourses: boolean = false;
  loaderSubscribedCourses: boolean = false;
  loaderTopCategories: boolean = false;
  loaderTopCourses: boolean = false;

  constructor(private renderer: Renderer2,
    private modalReview: NgbModal,
    private router: Router,
    private route: Router,
    private courseService: CourseService,
    private ns: NotificationService,
    private authService: AuthService,
    private shareService: ShareService,
    private activatedRoute: ActivatedRoute,
    rateConfig: NgbRatingConfig,
    private modalService: NgbModal,
    private _monitoringShareService: MonitoringShareService) {
    rateConfig.readonly = true;
    this.renderer.addClass(document.body, 'ecHome');

  }

  openReviewModal(content) {
    this.modalReview.open(content, { windowClass: 'reviewModal', centered: true });
  }

  ngOnInit() {
    let sp = this.router.url.split('?')
    this.routerUrl = sp[0];
    this.courseId = this.activatedRoute.snapshot.queryParamMap.get('course_id'); // due to conflict replace _id with course_id
    this.courseName = this.activatedRoute.snapshot.queryParamMap.get('course');
    this.first_name = localStorage.getItem('first_name');
    if(this.router.url == '/'){
      localStorage.removeItem('courseName');
    }
    this.getAllHomeCourses();
    // this.getAllCourses();
    this.topRateCourses();
    this.discoverCo()
    if(this.routerUrl !== '/published-courses' ){
      this.draftCourse()
    } 
    if(this.routerUrl !== '/draft-courses' ){
      this.publishedCourse();
    }
    if(this.role == 'student'){
      if(this.routerUrl == '/published-courses' || this.routerUrl == '/draft-courses'){
        this.route.navigate(['/']);
      }
    }
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'ecHome');
  }
  get role() {
    return localStorage.getItem('role');
  }

  get permissions() {
    return JSON.parse(localStorage.getItem("permissions"));
  }

  get islogin() {
    return this.authService.isLogin;
  }

  loader = false;
  allCategory = [];
  getAllCourses() {
    this.loader = true;
    if (this.role == 'student') {
      this.courseService.categoryAPI().subscribe(data => {
        this.allCategory = data;
        this.loader = false;
      });
    }
  }

  categoryVisDetails = [];
  susbcribe_courses = [];
  recommended_course = [];
  teach_on_edu = [];
  onChangeCategory(value, page, status, component_name?: string) {
    if (this.authService.isLogin) {
      this.loader = true;
      this.courseService.categoryVisCourses(value, page, status, component_name).subscribe(courseDetails => {
        this.categoryVisDetails = [];
        this.susbcribe_courses = [];
        this.recommended_course = []
        this.categoryVisDetails = courseDetails.courses_info.data;
        this.getReletedCourses(this.categoryVisDetails);
        this.loader = false;
      })
    }
  }

  picks_where_left = [];
  getReletedCourses(course_list) {
    this.ns.getReletedCourses(course_list);
    this.susbcribe_courses = this.ns.susbcribe_courses;
    this.recommended_course = this.ns.other_course;
    this.teach_on_edu = this.ns.teach_on_edu;
    if (this.susbcribe_courses.length == 0) {
      this.picks_where_left = this.teach_on_edu;
    } else {
      this.picks_where_left = this.susbcribe_courses;
    }
  }

  navigate_to(url, course_id) {
    this.ns.navigate_wirh_id(url, course_id)
  }


  topRatedCourseData: any;
  topRateCourses() {
    this.loader = true;
    if (this.role == 'student') {
      this.courseService.topRatedCourse().subscribe((topRateData) => {
        this.topRatedCourseData = topRateData.data;
        this.loader = false;
      }, error => {
        // console.log(error);
      })
    }
  }


  // kabir code  
  allCourses = [];
  viewed_sessions = [];
  susbcribe_course = [];
  recommended_courses = [];
  top_categories = [];
  top_courses = [];
  getAllHomeCourses() {
    if (this.role == 'student') {
      //FOR HOMEPAGE API SEPERATION
      this.getRecentSessions();
      this.getRecommendedCourses();
      this.getTopCourses();
      this.getTopCategories();
      this.getSubscribedCourses();
    }
  }

  getRecentSessions() {
    this.loaderRecentSessions = true;
    this.courseService.recentlyViewSessions().subscribe(res => {
      this.viewed_sessions = res.recently_viewed_sessions;
      console.log(this.viewed_sessions.length)
      this.loaderRecentSessions = false;
    }, err => {
      this.loaderRecentSessions = false;
      console.log(err);
    });
  }

  getRecommendedCourses() {
    this.loaderRecommendedCourses = true;
    this.courseService.recommendedCourses().subscribe(res => {
      this.recommended_courses = res.recommended_courses;
      this.loaderRecommendedCourses = false;
    }, err => {
      this.loaderRecommendedCourses = false;
      console.log(err);
    });
  }

  getTopCourses() {
    this.loaderTopCourses = true;
    this.courseService.topCourses().subscribe(res => {
      this.top_courses = res.top_courses;
      this.loaderTopCourses = false;
    }, err => {
      this.loaderTopCourses = false;
      console.log(err);
    });
  }

  getTopCategories() {
    this.loaderTopCategories = true;
    this.courseService.topCategories().subscribe(res => {
      this.top_categories = res.top_categories;
      this.loaderTopCategories = false;
    }, err => {
      this.loaderTopCategories = false;
      console.log(err);
    });
  }

  getSubscribedCourses() {
    this.loaderSubscribedCourses = true;
    this.courseService.subscribedCourses().subscribe(res => {
      this.susbcribe_course = res.subscribed_courses;
      this.loaderSubscribedCourses = false;
    }, err => {
      this.loaderSubscribedCourses = false;
      console.log(err);
    });
  }

  onTopChangeCategory(value, page = null, status = null) {
    this.loader = true;
    this.category_id = value
    this.courseService.categoryVisCourses(value, page, status).subscribe(courseDetails => {
      this.categoryVisDetails = courseDetails.courses_info;
      this.loader = false;
    })
    this.router.navigate(['/category-list'], { queryParams: { course_category_id: this.category_id, } });
  }

  letsPickUp(viewed_sessions) {
    this.route.navigate(['courses/video-with-notes'], { queryParams: { course_id: viewed_sessions.course_id, session_id: viewed_sessions.session_id, course_category: viewed_sessions.courseCategoryTaxonomy, course_category_id: viewed_sessions.courseCategoryId,topicId:viewed_sessions.topic_id, sessionkey: viewed_sessions.session_type } })
  }


  get isPublishLen() {
    return this.publishCourse.length;
  }


  publishKey:string = 'publish';
  unPublishKey:string = 'unpublish';
  IsLengthDraft;
  IsLengthPublish;
  totalLengthU: any;
  totalRecordeP: any;
  pageP: number = 1;
  cShowDraft;
  cShowPublish;
  publishCoursePublish;
  publishCourseDraft;
  publishStatus;
  publishCourse = [];
  apiRes;
  uNpublishCourse;
  draftCourse(){
    this.publishCourseDraft = [];
    this.loader = true;
    if (this.role == 'teacher' || this.role == 'super_admin') {
    this.courseService.addCourseCate(this.pageP,this.unPublishKey).subscribe(res => {
      this.cShowDraft = true;
      this.apiRes = res.courses_info;
      this.uNpublishCourse = this.apiRes.data;
      this.loader = false;
      this.totalLengthU = this.apiRes.total_courses;
      console.log('UnPublixh', this.totalLengthU)
      if (this.uNpublishCourse.length > 0) {
        this.IsLengthDraft = true;
      }
      this.publishCourseDraft = this.uNpublishCourse;
      if(this.routerUrl !== '/draft-courses'){
        this.publishCourseDraft = this.publishCourseDraft.slice(-2);
      }
    });
   }
  }

  publishedCourse(){
    this.publishCoursePublish = [];
    this.loader = true;
    if (this.role == 'teacher' || this.role == 'super_admin') {
    this.courseService.addCourseCate(this.pageP,this.publishKey).subscribe(res => {
      this.cShowPublish = true;
      this.apiRes = res.courses_info;
      this.publishCourse = this.apiRes.data;
      this.loader = false;
      this.totalRecordeP = this.apiRes.total_courses;
      if (this.publishCourse.length > 0) {
        this.IsLengthPublish = true;
      }
      this.publishCoursePublish = this.publishCourse;
      if(this.routerUrl !== '/published-courses'){
        this.publishCoursePublish = this.publishCoursePublish.slice(-10);
      }
    });
   }
  }

  currentPageNo(pageNo) {
    this.pageP = pageNo;
    if(pageNo){
      window.scrollTo(0,500);
      if (this.routerUrl == '/draft-courses') {
        this.draftCourse();
      } else if(this.routerUrl == '/published-courses'){
        this.publishedCourse();
      }
    }
  }

  viewAllDraft() {
    this.route.navigate(['/draft-courses']);
  }
  viewAllPublish() {
    this.route.navigate(['/published-courses']);
  }

  sendEditDetails(id, course) {
    this.router.navigate(['/courses/add'], { queryParams: { courseId: id, course: course } });
  }

  sendCourseDetails(id, course) {
    this.router.navigate(['/courses/single-course'], { queryParams: { course_id: id, course: course } });
  }


  findIndexOf(slug, pmenusSlug) {
    let indexOfValue;
    indexOfValue = this.shareService.getIndexArr(slug, pmenusSlug)
    return indexOfValue;
  }



  // viewStudentMonitoring() {
  //   let role: string = 'student';
  //   this.loader = true;
  //   this.courseService.getSubscribeStudent(this.courseId, role).pipe(
  //     finalize(() => {
  //       this.loader = false;
  //     })).subscribe(data => {
  //       if (!data.hasOwnProperty('users_info')) {
  //         this.ns.showNotification("info", data.message);
  //         return;
  //       }
  //       this._monitoringShareService.setCourseName(this.courseName);
  //       localStorage.setItem('courseName', this.courseName);
  //       this.router.navigate(['/courses/student-monitoring'], { queryParams: { course_id: this.courseId } });
  //     })
  // }

  disCoverItems: any = [];
  discoverCo() {
    this.loader = true;
    if (!this.authService.isLogin) {
      this.courseService.discoverCourses().subscribe((res) => {
        this.disCoverItems = res.data;
        this.loader = false;
      }, (error) => {
        console.log(error);
      })
    }
  }

  viewStudentMonitoring(course_id,subject:string = '') {
    let role: string = 'student';
    this.loader = true;
    this.courseName = subject;
    this.courseService.getSubscribeStudent(course_id, role).subscribe(data => {
        if (!data.hasOwnProperty('users_info')) {
          this.ns.showNotification("info", data.message);
          this.loader = false;
          return;
        }
        this.loader = false;
        
        this._monitoringShareService.setCourseName(this.courseName);
        // localStorage.setItem('courseName', this.courseName);
        this.router.navigate(['/courses/student-monitoring'], { queryParams: { course_id: course_id } });
      })
  }

  is_true
  makeCourseUniversalModel(makeCourseUniversal,event){
    this.is_true = event.target.checked
    this.popupModel = this.modalService.open(makeCourseUniversal, { size: "sm", centered: true });
  }

   
   makeCourseAvailableToall(courseId){
      this.loader = true;
        this.courseService.makeCourseUniversal(courseId,this.is_true).subscribe(req => {
          this.ns.showNotification("success", req.message);
          this.popupModel.close();
          this.publishedCourse();
          this.loader = false;
        },
          (err) => {
            this.ns.showNotification("error", err.message);
          })
    
  }

  closeCourseMakeUniversalPopup() {
    this.popupModel.close();
    this.publishedCourse();
  }

  deactiveCourseModel(deativeCourse){
    this.popupModel = this.modalService.open(deativeCourse, { size: "sm", centered: true });
  }

  closeCoursePopup() {
    this.popupModel.close();
    // this.questionId = null;
  }

  deactivedCourse(courseId) {
    this.loader = true;

      this.courseService.deactivateCourse(courseId).subscribe(req => {
        this.ns.showNotification("success", req.Status);
        // this.getpublishCourses();
        this.publishedCourse();
        this.loader = false;
        this.popupModel.close();

      },
        (err) => {
          this.ns.showNotification("error", err.status);
        })
    
  }

  delCourse(courseId) {
    this.loader = true;
    if (confirm("Are you sure you want to delete this course?")) {
      this.courseService.deactivateCourse(courseId).subscribe(req => {
        this.ns.showNotification("success", req.Status);
        // this.getpublishCourses();
        this.publishedCourse();
        this.loader = false;
      },
        (err) => {
          this.ns.showNotification("error", err.status);
        })
    }
  }
}