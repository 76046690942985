import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { CourseService } from 'src/app/services/course.service';

@Component({
  selector: 'app-resource-detail',
  templateUrl: './resource-detail.component.html',
  styleUrls: ['./resource-detail.component.scss']
})
export class ResourceDetailComponent implements OnInit {

  resourceId;
  instanceId;
  resDetails:any = [];

  loader:boolean
  constructor(
    private activatedRoute: ActivatedRoute,
    private courseService:CourseService,
    private router: Router,

  ) { }

  ngOnInit() {
    this.resourceId = this.activatedRoute.snapshot.queryParamMap.get('resourceId');
    this.instanceId = this.activatedRoute.snapshot.queryParamMap.get('instanceId');
    console.log(this.instanceId);
    this.resourceDetails()

  }

  
resourceDetails(){
  this.loader = true
  this.courseService.viewResource(this.resourceId,this.instanceId).pipe(finalize(() => this.loader=false)).subscribe(viewRes => {
      this.resDetails = viewRes.response;
      this.loader = false
    })
  
}

goBack() {
  this.router.navigate(['/resource-bank']);
}

//FOR BUILD FIX
ansResourceImg: string = '';

}

