import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CourseWorkUtilityService {

  constructor() { }

  //GET COURSEWORK DEADLINE
  getExpireDate(dateValue, updateDays) {
    let expireDate;
    let updatedate;
    let day = dateValue.day;
    let month = dateValue.month;
    let year = dateValue.year;
    let date = year + '-' + month + '-' + day;
    updatedate = new Date(Date.parse(date));
    updatedate.setDate(updatedate.getDate() + Number(updateDays));
    expireDate = { day: updatedate.getDate(), month: updatedate.getMonth() + 1, year: updatedate.getFullYear() };

    return expireDate;
  }

  //CHECK COURSEWORK HAS STARTED
  isCourseWorkStarted(dateValue, time) {
    let updatedate;
    let courseWorkTime;
    courseWorkTime = time.split(':')
    let day = dateValue.day;
    let month = dateValue.month;
    let year = dateValue.year;
    let date = year + '-' + month + '-' + day;
    updatedate = new Date(Date.parse(date));
    updatedate.setHours(Number(courseWorkTime[0]), Number(courseWorkTime[1]));
    let curretdate = new Date();
    return curretdate.getTime() >= updatedate.getTime();
  }

  isApprovedTeamMember(team: any) {
    if(team.length > 0) {
      return team[0].members.find(t => {
        if(t.username == localStorage.getItem('currentUser')) {
          return t.approval_status;
        }
      });
    }
    return false;
  }

  //CHECK COURSEWORK HAS EXPIRED
  isCourseWorkExpired(dateValue, time) {
    let updatedate;
    let courseWorkTime;
    courseWorkTime = time.split(':')
    let day = dateValue.day;
    let month = dateValue.month;
    let year = dateValue.year;
    let date = year + '-' + month + '-' + day;
    updatedate = new Date(Date.parse(date));
    updatedate.setHours(Number(courseWorkTime[0]), Number(courseWorkTime[1]));
    let curretdate = new Date();
    return curretdate.getTime() >= updatedate.getTime();
  }
}
