import { Directive, ElementRef, OnInit, EventEmitter, Output } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';

@Directive({
  selector: '[appSearchField]'
})
export class SearchFieldDirective implements OnInit {

  @Output() sendSearchData: EventEmitter<any> = new EventEmitter<any>();
  searchText: string = '';

  constructor(private searchInputElm: ElementRef) { }

  ngOnInit() {
  	this.searchInit();
  }

  searchInit() {
  	fromEvent(this.searchInputElm.nativeElement, 'input')
  	  .pipe(
  	    debounceTime(400),
  	    distinctUntilChanged(),
  	    tap((event) => {
  	      this.searchText = '';
  	      if (this.searchInputElm.nativeElement.value.length > 0) {
  	        this.searchText = this.searchInputElm.nativeElement.value;
  	      }
  	      this.sendSearchData.emit({searchText: this.searchText});
  	    })
  	  )
  	  .subscribe();
  }

}
