
<div class="new_UIstyle">
    <!-- Banner Section Start -->
    <section class="banner banner-smBanner" style="background-image: url('assets/images/banner/privacy-policy.png');">
       <div class="container">
           <h1 class="white-text">TERMS AND CONDITIONS</h1>
       </div>
   </section>
   <!-- Banner Section End -->
   
   <section class="section  terms-wrap">
       <div class="container">
           <p>At Educollab login screen- User must accept the terms and conditions ("Terms") by accessing Educollab.</p>
   
           <p>By agreeing to our terms and conditions, the users agree to the following terms and conditions</p>
           
           <h5 class="avenirBlack">User Account: </h5>

           <ul class="albha-list">
            <li>You are in charge of protecting the privacy of your login information and account details.</li>
            <li>Neither you nor anyone else using your account information is allowed unauthorized access to the website.</li>
           </ul>

           <h5 class="avenirBlack">Acceptable Use:</h5>

           <ul class="albha-list">
            <li>When using Educollab, you must abide by all relevant laws and rules.</li>
            <li>You are not permitted to use the website in any way that is illegal, harmful, or unauthorized.</li>
            <li>You shall refrain from any actions that could obstruct or otherwise affect how the website operates.</li>
           </ul>

           <h5 class="avenirBlack">Intellectual Property: </h5>

           <ul class="albha-list">
            <li>Educollab and the content on platform is protected by copyrights and trademarks that belong to Tatras Data or its’ licensees.</li>
            <li>Without prior written approval, you are not allowed to copy, share, alter, or transform any of the website's material into a different form.</li>
           </ul>


           <h5 class="avenirBlack">Data protection: </h5>

           <ul class="albha-list">
            <li>We gather and handle personal data in compliance with our Privacy Policy.</li>
            <li>You give your consent for your personal information to be collected, stored, and processed as set out in our Privacy Policy by using the LMS website.</li>
           </ul>

           <h5 class="avenirBlack">Integration with Third-Party Tools or Services: </h5>

           <ul class="albha-list">
            <li>Educollab may integrate with third-party tools or services.</li>
            <li>The accessibility, functionality, or dependability of any third-party services or tools connected with our platform are not our responsibility.</li>
            <li>The terms and conditions of any third-party services or tools that you use are governed by them.</li>   
           </ul>

           <h5 class="avenirBlack">Limitation of Liability: </h5>
           <p>While we make every effort to offer a reliable and secure platform, we make no guarantees or assurances regarding its performance, accuracy, or availability.</p>

           <ul class="albha-list">
              <li>As a result of your use of the platform, we are not responsible for any direct, indirect, incidental, consequential, or punitive damages.</li>   
           </ul>

           <h5 class="avenirBlack">Termination:</h5>

           <ul class="albha-list">
              <li>We reserve the right to terminate or suspend your access to Educollab, without prior notice or liability, for any reason.</li>   
             <li>Upon termination, your right to use the platform will cease.</li>
            </ul>

           <h5 class="avenirBlack">Modifications:</h5>

           <ul class="albha-list">
             <li>We reserve the right to modify or update these Terms at any time without prior notice.</li>   
             <li>By continuing to use the LMS website after any modifications, you agree to be bound by the revised Terms.</li>
            </ul>

           <h5 class="avenirBlack">Governing Law:</h5>

           <ul class="albha-list">
              <li>These Terms shall be governed by and construed in accordance with the laws of [Jurisdiction].</li>   
             <li>Any disputes arising from these Terms shall be subject to the exclusive jurisdiction of the courts of [Jurisdiction].</li>
            </ul>

            <p  class="mt-3"><b>Please read these Terms carefully and contact us if you have any questions or concerns.</b></p>

         </div>
   </section>
   </div>

   