import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-live-button',
  templateUrl: './live-button.component.html',
  styleUrls: ['./live-button.component.scss']
})
export class LiveButtonComponent implements OnInit {

  @Input() liveSessionStatus = ''; 
  
  constructor() { }

  ngOnInit() {
    // console.log('statuts',this.liveSessionStatus)
  }

}
