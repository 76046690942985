<div class="new_UIstyle">
    <button *ngIf="is_team_object(teamData)" class="btn btn-primary btn-sm ms-auto d-table"
        (click)="open_chat(teamData.group_chat.room_id, teamData.group_chat, 'team')">
        <i class="fas fa-comment-dots"></i> {{actionLabel}}
    </button>

    <div class="chatboxModal mosaic_chat_wrap chatboxModal-fixed" #scrollMe id="chatboxModal" *ngIf="is_chat_true">
        <!-- Chat Header -->
        <div class="chatboxModal-header bg-yellow">
            <div class="chatboxModal-header-detail">
                <div class="Instructor-profile">
                    <figure class="Instructor-pro-fig chat-user-icon" *ngIf="avatar_url"><img class="image_class" [src]="avatar_url" alt="get_username(chat_user_name)" /></figure>
                    <default-name-icon *ngIf="!avatar_url" [name]="get_username(chat_user_name)" [figureClass]="'chat-user-icon'">
                    </default-name-icon>
                </div>
                <div class="chatboxModal-header-proDetal">
                    <p class="contDetail"><strong class="d-block">{{get_username(chat_user_name)}}</strong>
                        <!-- <small>Active Now</small> -->
                    </p>
                </div>
            </div>

            <div class="chatboxModal-header-action">
                <!-- <a type="button" class="chat-cta-btn chat-cta-expand"><i class="fas fa-expand-alt"></i></a> -->
                <div ngbDropdown class="optDDBox">
                    <i ngbDropdownToggle class="fas fa-ellipsis-v"></i>
                    <div ngbDropdownMenu>
                        <button ngbDropdownItem (click)="exit_group(group_details)">Exit Group</button>
                        <!-- <button ngbDropdownItem class="delete_group"
                                    (click)="delete_group(group_details)">Delete Group</button> -->
                    </div>
                </div>
                <a type="button" class="chat-cta-btn chat-cta-close" (click)="close_chat(group_details)"><i
                        class="fas fa-times"></i></a>
            </div>
        </div>
        <!-- Chat Body -->
        <div class="chatboxModal-body" #scrollMe [scrollTop]="get_height(scrollMe.scrollHeight)">
            <div *ngFor="let msg of get_message()">
                <div class="chatboxModal-msg-elem msg-left" *ngIf="msg.user_id == userId">
                    <div class="Instructor-profile">
                        <figure class="Instructor-pro-fig chat-user-icon" *ngIf="msg.profile_pic">
                            <img [src]="msg.profile_pic" [alt]="msg.sender_name">
                        </figure>
                        <default-name-icon *ngIf="!msg.profile_pic" [name]="msg.sender_name" [figureClass]="'chat-user-icon'">
                        </default-name-icon>
                    </div>
                    <div class="chatboxModal-msg-cont">
                        <small>{{msg.username}}</small>
                        <p class="msg-block" *ngIf="msg.message_type == 'text'">{{msg.message}}</p>
                        <div class="chatMsgHarFigBlk" *ngIf="msg.message_type == 'image'">
                            <figure class="chatMsgHarFig" *ngFor='let img of msg.urls'>
                                <img [src]="img.url" height="150" width="100px" class="chatMsgHarImg"
                                    (click)="openImgModal(view_image, img)">
                            </figure>
                        </div>
                        <div class="chatMsgHarFigBlk" *ngIf="msg.message_type == 'file'">
                            <button type="button" *ngFor="let img of msg.urls; let i=index"
                                class="btn btn-lg btn-outline-primary mr-2 mb-1" [title]="img.file_name"
                                (click)="openInNewWindow(img)">
                                <i class="far fa-file-alt"></i>
                            </button>

                        </div>
                        <small>{{get_time(msg.created_at)}}</small>
                        <small *ngIf="msg.is_read"><i class='fas fa-check-double'></i></small>
                        <small *ngIf="!msg.is_read"><i class='fas fa-check'></i></small>
                    </div>
                </div>

                <div class="chatboxModal-msg-elem msg-right" *ngIf="msg.user_id != userId">
                    <div class="Instructor-profile">
                        <figure class="Instructor-pro-fig chat-user-icon" *ngIf="msg.profile_pic">
                            <img [src]="msg.profile_pic" [alt]="msg.sender_name">
                        </figure>
                        <default-name-icon *ngIf="!msg.profile_pic" [name]="msg.sender_name" [figureClass]="'chat-user-icon'">
                        </default-name-icon>
                    </div>
                    <div class="chatboxModal-msg-cont">
                        <small>{{msg.username}}</small>
                        <p class="msg-block" *ngIf="msg.message_type == 'text'">{{msg.message}}</p>
                        <div class="chatMsgHarFigBlk" *ngIf="msg.message_type == 'image'">
                            <figure class="chatMsgHarFig" *ngFor='let img of msg.urls'>
                                <img [src]="img.url" height="150" width="100px" class="chatMsgHarImg"
                                    (click)="openImgModal(view_image, img)">
                            </figure>
                        </div>
                        <div class="chatMsgHarFigBlk" *ngIf="msg.message_type == 'file'">
                            <button type="button" *ngFor="let img of msg.urls; let i=index"
                                class="btn btn-lg btn-outline-primary mr-2 mb-1" [title]="img.file_name"
                                (click)="openInNewWindow(img)">
                                <i class="far fa-file-alt"></i>
                            </button>

                        </div>
                        <small>{{get_time(msg.created_at)}}</small>
                        <small *ngIf="msg.is_read"><i class='fas fa-check-double'></i></small>
                        <small *ngIf="!msg.is_read"><i class='fas fa-check'></i></small>
                    </div>
                </div>

                <span class="d-block text-center msg-date-divide">{{is_day(msg.created_at)}}</span>

            </div>
            <span class="d-block text-center msg-date-divide" *ngIf="get_length_message() > 0">Today</span>
            <div id="message">

            </div>
        </div>
        <!-- Chat Footer -->
        <div class="chatboxModal-footer">
            <form action="" #messageform="ngForm">
                <div class="msg-submit-field d-flex align-items-center">
                    <input type="text" name="message_view" class="form-control" (keydown.enter)="send_message()"
                        [(ngModel)]="message_view" placeholder="Type a Message" />
                    <div class="btn-group btn-group-right">
                        <a type="button" (click)="send_message()" class="more-ctcBtn msgsubmit-btn"><i
                                class="fas fa-paper-plane"></i></a>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <ng-template #view_image let-modal>
        <div class="modal-header border-0 p-0">
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body modalprevImgBody">
            <div>
                <div class="modalprevAntionBar">
                    <button class="modalprevImgBtn download-img" (click)="download_file()"><i
                            class="fas fa-cloud-download-alt"></i></button>
                </div>
                <div class="modalprevFig">
                    <img [src]="image_url.url" alt="cover-image-sample.jpg" />
                </div>
            </div>
            
            <!-- <img  [src]="img.url" height="150" width="100px" class="chatMsgHarImg" /> -->
        </div>
    </ng-template>
</div>
