import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'splitComma'
  })
  export class splitComma implements PipeTransform {
    transform(val:string):string {
       let time = val.split(',');
       return time[0];
    }
  }