import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  constructor() { }

  isAllowedPageView(allowedRoles, role) {
  	if(allowedRoles.indexOf(role) == -1) {
  	  return false;
  	}
  	return true;
  }

  isCourseSubscribed(subscriptiionStatus) {
	if(subscriptiionStatus == true) {
		return true;
	}
	return false;
  }

  isAllowedSharedPageView(publishRight, role, teachAssis = []) {
    if(role == 'super_admin') {
      return true;
    }
    else if(publishRight == true) {
      return true;
    }
    else if(this.isAssistant(teachAssis)) {
      return true;
    }
    else {
      return false;
    }
  }

  isAssistant(teachAssis = []) {
    let teachAssisIndex = teachAssis.findIndex(e => e._id === localStorage.getItem('user_id'));
    if(teachAssisIndex == -1) {
		  return false;
    }
   return true;
  }
}
