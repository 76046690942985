<section class="section discover-course-wrap">
  <div class="container">
    <div class="title discover-course-title text-center">
      <h3>Discover Best Projects</h3>
    </div>
    <div class="discover-items-wrap" [ngClass]="{submitting : loader}">
      <div class="loadingData">
        <div class="spinner-border text-warning formLoader" role="status" *ngIf="loader">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div class="discover-items-slider course-item-slider">
        <owl-carousel-o [options]="discoverCourse">
          <ng-template carouselSlide *ngFor="let project of discoverProjectList">
            <div class="discover-course-item">
              <div class="course-item-block shineEffect">
                <figure class="course-item-fig">
                  <img *ngIf="project.project_overview_image" [src]="project.project_overview_image" [alt]="project.project_title" />
                  <img *ngIf="!project.project_overview_image && project.project_image" [src]="project.project_image" [alt]="project.project_title" />
                  <img *ngIf="!project.project_overview_image && !project.project_image" [src]="defaultThumbnailPic" [alt]="project.project_title" />
                </figure>
                <div class="course-item-content">
                  <p class="course-title">
                    <a (click)="changeSharedProject(project._id)">
                      <strong title="{{ project.project_title | titlecase }}">
                        {{ project.project_title | titlecase}}
                      </strong>
                    </a>
                  </p>
                  <p class=""><small>By {{project.created_by}}</small></p>
                </div>
              </div>
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
    </div>
  </div>
</section>
