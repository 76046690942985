import { Component, OnInit, Input, Output, SimpleChanges, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CourseService } from '../../services/course.service';
import { NotificationService } from '../../services/notification.service';
import { Renderer2 } from '@angular/core';

@Component({
  selector: 'app-course-work-marking-remark-grade',
  templateUrl: './course-work-marking-remark-grade.component.html',
  styleUrls: ['./course-work-marking-remark-grade.component.scss']
})
export class CourseWorkMarkingRemarkGradeComponent implements OnInit {

  @Input() editBtnClicked: any;
  @Input() editBtnRef: any;
  @Input() isSuperAdmin: any;
  @Input() isTeachingAssistant: any;
  @Input() isPublishRight: any;
  @Input() courseworks: any;
  @Input() submissionReportDetail: any;
  @Input() findedIndex: number;
  @Input() viewSubmissionData: any[];
  @Input() submissionData: any;
  @Input() showCourseWork: any;
  @Input() courseId: any;
  @Output() sendGradeReviewSavedStatus = new EventEmitter();

  processLoader: boolean = false;
  role: string = '';
  loggedInUserId: string = '';

  gradeReviewForm!: FormGroup;
  showGradeReviewForm: boolean = false;
  submissionPublishStatus: boolean = false;
  editReviewType: string = '';

  submissionReportUserId: string = '';

  isSubmissionDataLoading: boolean = false;

  constructor(private fb: FormBuilder,
    private courseService: CourseService,
    private notifyService: NotificationService,
    private renderer: Renderer2) { }

  ngOnInit() {
  }

  createGradeReviewForm() {
    this.gradeReviewForm = this.fb.group({
      grade: ['', [Validators.required]],
      review: ['', [Validators.maxLength(2000)]]
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    //console.log(this.viewSubmissionData);
    this.role = localStorage.getItem('role');
    this.loggedInUserId = localStorage.getItem('user_id');
    this.createGradeReviewForm();
    this.manageSubmissionReport();
    this.isSubmissionDataLoading = false;
  }

  manageSubmissionReport() {
    this.showGradeReviewForm = true;
    this.submissionPublishStatus = this.submissionData.submissionPublish;
    this.onEditReview('grade');
  }

  onEditReview(editReviewType) {
    this.editReviewType = editReviewType;
    if (this.editReviewType == 'grade') {
      let gradeMarks = '';
      if (this.submissionReportDetail.grade.content) {
        gradeMarks = this.submissionReportDetail.grade.content;
      }
      let remark = '';
      if (this.submissionReportDetail.remark.content) {
        remark = this.submissionReportDetail.remark.content;
      }
      this.gradeReviewForm.get('grade').setValue(gradeMarks);
      this.gradeReviewForm.get('review').setValue(remark);
      setTimeout(() => {
        if (this.editBtnRef) {
          this.renderer.selectRootElement('#' + this.editBtnRef + 'InputBox').focus();
        }
      }, 100);
    }
  }

  onSaveReview() {
    //console.log(this.gradeReviewForm);
    if (!this.validateForm()) {
      return;
    }
    const gradeMarks = this.gradeReviewForm.value.grade;
    const review = this.gradeReviewForm.value.review;
    this.saveReview(gradeMarks, review, 'grade');
  }

  saveReview(gradeMarks, review, reviewType) {

    const submissionInfo = this.viewSubmissionData[this.findedIndex];
    const submissionReportUserId = this.viewSubmissionData[this.findedIndex].user_id;
    const submissionReportName = this.showCourseWork.submission_report;
    let submissionReportData = this.viewSubmissionData[this.findedIndex].submissions[submissionReportName];

    if (submissionReportData.files.length == 0) {
      this.notifyService.showNotification('info', 'User has not submitted any file for this submission.');
      return;
    }

    if (gradeMarks > 100 && reviewType == 'grade') {
      this.notifyService.showNotification('info', 'You can not give more than 100 marks, Please assign it again.');
      return;
    }

    if (this.isTeachingAssistant == true && this.submissionPublishStatus == true) {
      this.notifyService.showNotification('info', 'Submission is published. No permission to edit it now.');
      return;
    }

    if (reviewType == 'grade') {
      submissionReportData.grade = { content: gradeMarks };
      submissionReportData.remark = { content: review };
    }

    let grade = '';
    let feedback = '';
    let peerReview = '';
    let selfReview = '';

    let teamName = '';
    if (this.courseworks.is_group) {
      teamName = submissionInfo.name;
    }

    if (reviewType == 'grade') {
      grade = submissionReportData.grade.content ? submissionReportData.grade.content : '';
      feedback = submissionReportData.remark.content ? submissionReportData.remark.content : '';
    }

    let body = {
      user_id: submissionReportUserId,
      team_name: teamName,
      course_work_id: this.courseworks._id,
      schedule_id: this.courseworks.schedule_id,
      course_id: this.courseId,
      grade: grade,
      feedback: feedback,
      submission_name: submissionReportName,
      submission_index: '' + submissionReportData.index,
      peer_review: peerReview,
      self_review: selfReview
    }

    this.processLoader = true;
    this.courseService.add_remark_grade_course_work(body).subscribe(view_remark => {
      this.isSubmissionDataLoading = true;
      this.processLoader = false;
      this.onDiscardReview(reviewType, 'save');
      this.notifyService.showNotification('success', "Review saved successfully.");
    },
      (err) => {
        this.processLoader = false;
        this.notifyService.showNotification('error', err.message);
      });
  }

  validateForm() {
    if (this.gradeReviewForm.get('review').value) {
      this.gradeReviewForm.get('review').setValue(this.gradeReviewForm.get('review').value.trim());
    }

    if (this.gradeReviewForm.controls['grade'].invalid) {
      if (this.gradeReviewForm.get('grade').hasError('required')) {
        this.notifyService.showNotification("error", "Grade can not be empty.");
        return false;
      }
      return false;
    }

    if (this.gradeReviewForm.controls['review'].invalid) {
      if (this.gradeReviewForm.get('review').hasError('maxlength')) {
        this.notifyService.showNotification("error", "Remarks may not be greater than 2000 characters.");
        return false;
      }
      return false;
    }

    return true;
  }

  stripText(control) {
    control.setValue(control.value.replace(/[^0-9]/g, ''));
  }

  onDiscardReview(reviewType, action = '') {
    this.editReviewType = '';
    this.showGradeReviewForm = false;
    this.gradeReviewForm.reset();
    this.gradeReviewForm.get('grade').setValue('');
    this.gradeReviewForm.get('review').setValue('');

    //SEND STATUS TO PARENT COMPONENT
    this.sendGradeReviewSavedStatus.emit({ status: true, action: action });
  }

}
