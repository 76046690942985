import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SocketService } from 'src/app/services/web/socket.service';
import { NotificationService } from 'src/app/services/notification.service';
import { NgbModal, NgbModalConfig, NgbModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-team-chat',
  templateUrl: './team-chat.component.html',
  styleUrls: ['./team-chat.component.scss']
})
export class TeamChatComponent implements OnInit {

  @Input() actionLabel: string = '';
  @Input() roomId: string = '';
  @Input() teamData: any = {};
  @Input() chatType: string = '';
  @Output() teamStatus = new EventEmitter<any>();

  role: string = '';
  userId: string = '';

  //FOR CHAT BOX
  is_chat_true: boolean = false;
  show_group_name: string = '';

  message_view: string = '';

  room_id: string = '';
  my_message: any[] = [];
  avatar_url: any[] = [];
  chat_user_name: string = '';
  avatar_chat_color: any[] = [];
  username: string = '';
  group_details: any[] = [];
  group_lists: any[] = [];

  image_url: any = '';

  scrollTop_height: any = 200;

  weekday: any[] = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  constructor(private modalReview: NgbModal, private notificationService: NotificationService, private socket_service: SocketService) { }

  ngOnInit() {
    this.role = localStorage.getItem('role');
    this.userId = localStorage.getItem('user_id');
  }

  open_chat(mentor, pc, type) {
    this.is_chat_true = true;
    this.show_group_name = type;
    this.group_details = pc;
    if (type === 'team') {
      this.view_room_group(pc.room_id, pc, type)
    } else {
      this.avatar_url = pc.avatar_url;
      this.chat_user_name = pc.name;
      this.group_details = pc;
      this.view_room(pc.room_id);
    }
  }

  close_chat(group_details) {
    this.is_chat_true = !this.is_chat_true;
    this.leave_group(group_details)
  }

  get_time(date) {
    return this.notificationService.formatAMPM(new Date(date))
  }

  send_message() {
    if (this.message_view === '') {
      this.notificationService.showNotification('info', 'Enter Some message for your friend');
      return;
    }

    if (!this.room_id) {
      this.notificationService.showNotification('info', 'Please join with the person first..');
      return;
    }

    this.socket_service.send_message(this.userId, this.room_id, this.message_view, null);
    this.message_view = '';
  }

  view_room_group(room_id, group_details, type) {
    this.avatar_url = group_details.avatar_url;
    this.chat_user_name = group_details.name;
    this.group_details = group_details;
    this.view_room(room_id)
  }

  is_team_object(team) {
    if (Object.keys(team.group_chat).length > 0) {
      if (team.group_chat.user_status === "deactivate") {
        return false;
      }
      return true;
    }
    return false;
  }

  view_room(room_id) {
    this.my_message = [];
    if (this.room_id != null || this.room_id != '') {
      this.socket_service.leave_room(this.room_id, localStorage.getItem('user_id'));
    }

    this.room_id = room_id;

    this.socket_service.view_room(room_id).subscribe(view_room => {
      this.my_message = view_room.messages;
      this.socket_service.join_room(this.userId, room_id);
      this.group_details['unread_message_count'] = 0;
      let list = document.getElementById('message');
      let length = list.childNodes.length;

      if (list != null && list.hasChildNodes()) {
        for (let i = 0; i <= length; i++) {
          list.removeChild(list.childNodes[0]);
        }
      }
    },
      (err) => {
        this.notificationService.showNotification('error', err.message)
      })
  }

  get_username(username) {
    let split_value = username.split('-');
    if (split_value.length > 1 && (this.role === 'student')) {
      return split_value[1]
    }
    return username;
  }

  get_length_message() {
    let list = document.getElementById('message');
    let length = list.childNodes.length;
    return length
  }

  get_message() {
    if (this.socket_service.receive_message_view.length > 0) {
      this.my_message.push(this.socket_service.receive_message_view[0]);
      this.socket_service.receive_message_view = [];
    }
    return this.my_message;
  }

  openImgModal(content, img_info) {
    this.image_url = img_info;
    this.modalReview.open(content, { windowClass: 'prevImgModal', centered: true });
  }

  openInNewWindow(viewSubmissions) {
    window.open(viewSubmissions.url);
  }

  exit_group(group_team) {
    if (confirm('Are you sure to exit this group ?')) {
      this.socket_service.exit_group(group_team.room_id).subscribe(exit_group => {
        this.notificationService.showNotification('success', exit_group.message);
        this.is_chat_true = false;
        this.teamStatus.emit({ status: true });
      },
        (err) => {
          this.notificationService.showNotification('error', err.message);
        })
    }
    return;
  }

  get_height(height) {
    if (height) {
      let messgae_height = this.my_message.length;
      this.scrollTop_height = height;
      return this.scrollTop_height;
    }
    return this.scrollTop_height
  }

  leave_group(group_team) {
    this.socket_service.leave_room(group_team.room_id, localStorage.getItem('user_id'));
  }

  is_day(date) {
    let day = new Date(date);
    return this.weekday[day.getUTCDay()];
  }

  download_file() {
    //
  }

}

