import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
  PassionProjectsResponse,
  PassionProjectTeamResponse,
  PassionProjectsTeamInfoResponse,
  AddProjectImage,
  AddProjectRequest,
  EditProjectRequest,
  AddTeamRequest,
  TeamMembers
} from '../model/PassionProject';
import { TeamsResponse, EditTeamRequest } from '../model/passion-project-teams';

@Injectable({
  providedIn: 'root'
})
export class PassionProjectService {
  private apiEndPoint = environment.APIEndpoint;

  constructor(
    private http: HttpClient,
  ) { }

  passionProjects(): Observable<PassionProjectsResponse> {
    return this.http.get<PassionProjectsResponse>(this.apiEndPoint + '/passion_projects');
  }

  passionProjectTeams(id: string): Observable<PassionProjectTeamResponse> {
    return this.http.get<PassionProjectTeamResponse>(this.apiEndPoint + '/passion_projects/teams?project_id=' + id);
  }

  passionProjectTeamInfo(id: string, teamId: string): Observable<PassionProjectsTeamInfoResponse> {
    const path = '/passion_projects/report?project_id=' + id + '&team_id=' + teamId;
    return this.http.get<PassionProjectsTeamInfoResponse>(this.apiEndPoint + path);
  }

  projectTags(): Observable<any> {
    const path = '/get_tags';
    return this.http.get<any>(this.apiEndPoint + path);
  }

  addPassionProject(data: AddProjectRequest) {
    const path = this.apiEndPoint + '/passion_projects';
    return this.http.post<any>(path, data);
  }

  editPassionProject(data: EditProjectRequest) {
    const path = this.apiEndPoint + '/passion_projects';
    return this.http.patch<any>(path, data);
  }

  getTeams(): Observable<TeamsResponse> {
    const path = '/passion_projects/team';
    return this.http.get<TeamsResponse>(this.apiEndPoint + path);
  }

  getTeamsById(id: string, teamId = ''): Observable<TeamsResponse> {
    let path = '/passion_projects/team?project_id=' + id;
    if(teamId) {
      path = path + '&team_id='+teamId;
    }
    return this.http.get<TeamsResponse>(this.apiEndPoint + path);
  }

  addTeam(data: AddTeamRequest, groupActionName = '', teamId = '') {
    const path = this.apiEndPoint + '/passion_projects/team';
    if(groupActionName == 'Edit') {
      data['team_id'] = teamId;
      return this.http.patch<any>(path, data);
    }
    else {
      return this.http.post<any>(path, data);
    }    
  }

  editTeam(data: EditTeamRequest) {
    const path = this.apiEndPoint + '/passion_projects/team';
    return this.http.patch<any>(path, data);
  }

  uploadImage(file): Observable<AddProjectImage> {
    const reqHeader = new HttpHeaders({ });
    const formData = new FormData();
    formData.append('resource', file);

    const path = this.apiEndPoint + '/upload-resource?module_name=passion_project';

    return this.http.post<AddProjectImage>(path, formData, {headers: reqHeader});
  }

  searchTeamMember(name: string, roles): Observable<TeamMembers[]> {
    const role = roles
    const path = this.apiEndPoint + '/users?role=' + role + '&query=' + name;
    return this.http.get<TeamMembers[]>(path);
  }

  deletePassionProject(id) {
    let params = new HttpParams();
    params = params.append('project_id', id);
    const path = this.apiEndPoint + '/passion_projects';
    return this.http.delete<any>(path, {params});
  }

  //PASSION PROJECTS NEW APIS
  passionProjectsList(listType, sortBy, page, selectedTab = 'all'): Observable<any> {
    let params = new HttpParams();
    params = params.append('page_name', listType);
    params = params.append('tab', selectedTab);
    params = params.append('sort_by', sortBy);
    params = params.append('page', page);
    return this.http.get<any>(this.apiEndPoint + '/passion_projects/new', {params});
  }

  getPassionProject(projectId): Observable<any> {
    let params = new HttpParams();
    params = params.append('project_id', projectId);
    return this.http.get<any>(this.apiEndPoint + '/passion_projects/new', {params});
  }

  discoverProjectsList(): Observable<any> {
    let params = new HttpParams();
    return this.http.get<any>(this.apiEndPoint + '/discover_our_project', {params});
  }

  searchPassionProjectsList(searchText): Observable<any> {
    let params = new HttpParams();
    params = params.append('search_text', searchText);
    params = params.append('content_type', 'mosaic');
    return this.http.get<any>(this.apiEndPoint + '/global_search', {params});
  }

  addNewPassionProject(data: any) {
    const path = this.apiEndPoint + '/passion_projects/new';
    return this.http.post<any>(path, data);
  }

  updatePassionProject(data: any) {
    const path = this.apiEndPoint + '/passion_projects/new';
    return this.http.patch<any>(path, data);
  }

  fetchSearchTags(searchText): Observable<any> {
    let params = new HttpParams();
    params = params.append('search_text', searchText);

    return this.http.get<any>(this.apiEndPoint + '/tags/search', { params }).pipe(
      catchError(this.handleError)
    );
  }

  handleError(error) {

    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(error);
  }
}
