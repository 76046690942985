<!-- New Code -->
<div class="new_UIstyle">
    <div class="pageBodyWrap">
        <!-- banner section -->
        <div class="banner banner-gradient banner-C-assessment"
            style="background-image:url('../../../assets/images/banner/banner-question.png');">
            <div class="container">
                <div class="banner-content">
                    <div class="banner-content-content">
                        <h2 class="white-text avenirBlack">{{componentName}}</h2>
                        <!-- <p class="white-text">Group Assignment: <strong class="ms-2 primary-text">Student - Led</strong></p> -->
                        <!-- <div class="tags-badges">
                         <span class="badge badge-light badge-primary">Draft</span>
                     </div> -->
                    </div>
                    <div class="banner-content-action btn-groups">
                        <button class="btn btn-primary" (click)="goBack()">
                            Back</button>
                    </div>
                </div>
            </div>
        </div>

        <section class="section addQuestionWrap">
            <div class="container">
                <div class="add-QuestionOption">
                    <form class="typo-16" [ngClass]="{submitting : loader}" [formGroup]="addQuestionForm" id="addQus"
                        (ngSubmit)="formSubmit()">
                        <div class="spinner-border text-warning formLoader" role="status" *ngIf="loader">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="row">
                            <div class="col-md-6 mb-3 mb-md-4">
                                <label class="avenirBlack mb-2">Category of course</label>
                                <select class="form-control form-control-lg form-control-style-2 form-control-caret"
                                    formControlName="course"
                                    [ngClass]="{ 'is-invalid': submitAddQus && addQus.course.errors }">
                                    <option selected value="">Select option</option>
                                    <option *ngFor="let course of courses" [value]="course._id">
                                        {{course.Course_Category}}
                                    </option>
                                </select>

                                <div *ngIf="submitAddQus && addQus.course.errors" class="invalid-feedback">
                                    <div *ngIf="addQus.course.errors.required"> Please select any course</div>
                                </div>
                            </div>
                            <div class="col-md-6 mb-3 mb-md-4">
                                <label class="avenirBlack mb-2" >Question Type</label>

                                <select class="form-control form-control-lg form-control-style-2 form-control-caret"
                                    formControlName="type"
                                    [ngClass]="{ 'is-invalid': submitAddQus && addQus.type.errors, 'disabled': routerUrl == '/edit-question'  }"
                                    (change)="selectQtype($event.target.value,0,'addques')" >
                                    <option selected value="">Select option</option>
                                    <option *ngFor="let qt of qusTypes" [value]="qt._id">
                                        {{qt.name}}
                                    </option>
                                </select>

                                <div *ngIf="submitAddQus && addQus.type.errors" class="invalid-feedback">
                                    <div *ngIf="addQus.type.errors.required"> Please select question type</div>
                                </div>
                            </div>

                            <!-- <div class="col-md-12 mb-3 mb-md-4">
                                <label class="avenirBlack mb-2">Question</label>
                                <textarea class="form-control form-control-textarea form-control-style-2"></textarea>
                            </div> -->


                         


                            <!-- <dl>
                                <dd class="position-relative"> -->
                                    <!-- <div *ngIf="!insertBlankFlag"> -->
                                    <div class="col-md-12 mb-3 mb-md-4">
                                        <label class="avenirBlack mb-2">Question</label>
                                        <textarea class="form-control"  formControlName="question"
                                            id="textQus"
                                            [ngClass]="{ 'is-invalid': submitAddQus && addQus.question.errors }">
                                        </textarea>
                                    </div>

                                    <div class="col-md-12 mb-3 mb-md-4">
                                    <div *ngIf="multiSubQusFlag">
                                        <div class="secWrapHe8">
                                            <div class="secTreeLayer" formArrayName="sub_qus"
                                                *ngFor="let opt of subQusControls; let i = index;">
                                                <button type="button" class="btn bxClose" (click)="removeSubQus(i)"><i
                                                        class="fas fa-times-circle"></i></button>
                                                <h2 class="bxTitle">
                                                    <span class="tl">Q {{i+1}}</span>
                                                    <!-- <button type="button" class="btn btn-link p-0 {{btnStat}}" (click)="minMax()">
                                                        <i class="fas fa-plus"></i>
                                                    </button> -->
                                                </h2>
                                                <dl class="p-3 secTInner">
                                                    <dd [formGroupName]="i">
                                                        <div class="form-group">
                                                            <select class="custom-select" formControlName="type"
                                                                [ngClass]="{ 'is-invalid': submitAddQus && addSubQus(i).type.errors }"
                                                                (change)="selectSubQtype($event.target.value,0,'addSubques',i)">
                                                                <option selected value="">Select option</option>
                                                                <option *ngFor="let qt of qusTypesWithoutComprehension"
                                                                    [value]="qt._id">
                                                                    {{qt.name}}
                                                                </option>
                                                            </select>
                                                            <label class="flLabel">Question Type</label>
                                                            <div *ngIf="submitAddQus && addSubQus(i).type.errors"
                                                                class="invalid-feedback">
                                                                <div *ngIf="addSubQus(i).type.errors.required"> Please
                                                                    select sub
                                                                    question
                                                                    type</div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group">
                                                            <textarea class="form-control" 
                                                                formControlName="question"
                                                                [ngClass]="{ 'is-invalid': submitAddQus && addSubQus(i).question.errors }"
                                                                id="textSubQus{{i}}">
                                                            </textarea>
                                                            <div *ngIf="submitAddQus && addSubQus(i).question.errors"
                                                                class="invalid-feedback">
                                                                <div *ngIf="addSubQus(i).question.errors.required">
                                                                    Question is required
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <dl formArrayName="meta_data"
                                                            *ngFor="let optData of subQusMetaDataControls(opt); let ii = index;">
                                                            <dd [formGroupName]="ii">
                                                                <div class="form-group">
                                                                    <div
                                                                        *ngIf="optData['controls']['flag_type'].value == 'singleanswermcq'">
                                                                        <!-- <button *ngIf="multiSubQusOptions.length>4"
                                                                            (click)="removeSubQusOption(ii)" type="button" class="close"
                                                                            aria-label="Close">
                                                                            <span aria-hidden="true">&times;</span>
                                                                        </button> -->
                                                                        <div class="row align-items-center optFldsLoop">
                                                                            <div class="col-2">
                                                                                <div
                                                                                    class="custom-control custom-radio">
                                                                                    <input class="custom-control-input"
                                                                                        [value]="true" type="radio"
                                                                                        formControlName="answer"
                                                                                        id="{{i}}optData{{ii}}"
                                                                                        (change)="handleSubQusChange(i,ii)" />
                                                                                    <label class="custom-control-label"
                                                                                        [for]="i+'optData'+ii">Option
                                                                                        {{ii+1}}</label>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-10">
                                                                                <textarea class="form-control"                                                                                    
                                                                                    formControlName="option"
                                                                                    id="singleanswermcq{{i}}{{ii}}"
                                                                                    [ngClass]="{ 'is-invalid': submitAddQus && addSubQusOption(i,ii).option.errors }">
                                                                                    </textarea>
                                                                                <div *ngIf="submitAddQus && addSubQusOption(i,ii).option.errors"
                                                                                    class="invalid-feedback">
                                                                                    <div
                                                                                        *ngIf="addSubQusOption(i,ii).option.errors.required">
                                                                                        Option is required</div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        *ngIf="optData['controls']['flag_type'].value == 'multianswermcq'">
                                                                        <!-- <button *ngIf="multiSubQusOptions.length>4"
                                                                            (click)="removeSubQusOption(ii)" type="button" class="close"
                                                                            aria-label="Close">
                                                                            <span aria-hidden="true">&times;</span>
                                                                        </button> -->
                                                                        <div class="row align-items-center optFldsLoop">
                                                                            <div class="col-2">
                                                                                <div
                                                                                    class="custom-control custom-checkbox">
                                                                                    <input type="checkbox"
                                                                                        class="custom-control-input"
                                                                                        formControlName="answer"                                                                                        
                                                                                        id="{{i}}optData{{ii}}" />
                                                                                    <label class="custom-control-label"
                                                                                        [for]="i+'optData'+ii">Option
                                                                                        {{ii+1}}</label>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-10">
                                                                                <textarea class="form-control"                                                                                    
                                                                                    [ngClass]="{ 'is-invalid': submitAddQus && addSubQusOption(i,ii).option.errors }"
                                                                                    formControlName="option"
                                                                                    id="multianswermcq{{i}}{{ii}}">{{ii}}
                                                                                    </textarea>
                                                                                <div *ngIf="submitAddQus && addSubQusOption(i,ii).option.errors"
                                                                                    class="invalid-feedback">
                                                                                    <div
                                                                                        *ngIf="addSubQusOption(i,ii).option.errors.required">
                                                                                        Option is required</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        *ngIf="optData['controls']['flag_type'].value == 'bool'">
                                                                        <div class="row align-items-center optFldsLoop">
                                                                            <div class="col-2">
                                                                                <div
                                                                                    class="custom-control custom-radio">
                                                                                    <input class="custom-control-input"
                                                                                        [value]="true" type="radio"
                                                                                        formControlName="answer"
                                                                                        (change)="handleSubQusChange(i,ii)"
                                                                                        id="{{i}}optData{{ii}}" />
                                                                                    <label class="custom-control-label"
                                                                                        [for]="i+'optData'+ii">Option
                                                                                        {{ii+1}}</label>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-10">
                                                                                <textarea class="form-control"
                                                                                    formControlName="option"
                                                                                    id="bool{{i}}{{ii}}"
                                                                                    [ngClass]="{ 'is-invalid': submitAddQus && addSubQusOption(i,ii).option.errors }">
                                                                                    </textarea>
                                                                                <div *ngIf="submitAddQus && addSubQusOption(i,ii).option.errors"
                                                                                    class="invalid-feedback">
                                                                                    <div
                                                                                        *ngIf="addSubQusOption(i,ii).option.errors.required">
                                                                                        Option is required</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </dd>
                                                        </dl>
                                                        <div *ngIf="opt['controls']['answer_counter'].value == 0"
                                                            class="text-danger mb-3">
                                                            Choose atleast one answer
                                                        </div>
                                                    </dd>
                                                </dl>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <label class="flLabel">Question</label> -->

                                    <div class="text-right mt-3" *ngIf="showComprehension">
                                        <button type="button" class="btn btn-sm btn-primary" 
                                            (click)="addCompQus()"><i class="fas fa-plus-circle mr-2"></i>Add
                                            SubQuestion</button>
                                    </div>

                                    <div *ngIf="submitAddQus" class="">
                                        <!-- <div *ngIf="addQus.question.errors.required"> Question is required</div> -->
                                        <div *ngIf="qFlag" class="text-danger mb-3">
                                            Question is required
                                        </div>
                                    </div>

                                <!-- </dd>
                            </dl> -->


                            <div *ngIf="modelAnswerForTextQus" class="mb-4">
                                <dl formArrayName="meta_data" *ngFor="let opt of optionControls; let i = index;">
                                    <dd [formGroupName]="i">
                                        <div class="form-group">
                                                <label class="avenirBlack mb-2">Model Answer</label>
                                                <div class="mb-1">
                                                    <textarea class="form-control"
                                                        formControlName="answer" [id]="i">
                                                    </textarea>
                                               </div>
 
                                        </div>
                                    </dd>
                                    <div *ngIf="submitAddQus && modelAnsErrFlag" class="text-danger mb-3">
                                        Model answer is required
                                    </div>

                                </dl>
                            </div>

                            <div *ngIf="showSingleAnswerMcqFlag || showMultiAnswerMcqFlag" class="mb-4 optFldsBx">
                                <div *ngIf="showSingleAnswerMcqFlag" class="secWrapHe8">
                                    <dl class="mcqOption" formArrayName="meta_data"
                                        *ngFor="let opt of optionControls; let i = index;">
                                        <dd [formGroupName]="i">
                                            <div class="form-group">
                                                <button title="Remove Option" *ngIf="multiOptions.length>4"
                                                    (click)="removeOption(i)" type="button" class="close"
                                                    aria-label="Close"><i class="fas fa-times-circle"></i></button>
                                                <div class="row align-items-center optFldsLoop">
                                                    <div class="col-2">
                                                        <div class="custom-control custom-radio">
                                                            <input class="custom-control-input" name="answer"
                                                                [value]="true" type="radio" formControlName="answer"
                                                                id="opt{{i}}" (change)="handleChange(i)" />
                                                            <label class="custom-control-label" [for]="'opt'+i">Option
                                                                {{i+1}}</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-10">
                                                        <textarea class="form-control"
                                                            formControlName="option" [id]="optionControls[i].value.editorId">
                                                        </textarea>
                                                    </div>
                                                </div>

                                            </div>
                                        </dd>
                                    </dl>
                                </div>
                                <div *ngIf="showMultiAnswerMcqFlag" class="secWrapHe8">
                                    <dl class="mcqOption" formArrayName="meta_data"
                                        *ngFor="let opt of optionControls; let i = index;">
                                        <dd [formGroupName]="i">
                                            <!-- <button type="button" class="btn btn-danger btn-sm" (click)="removeOption(i)">-</button> -->
                                            <div class="form-group">
                                                <button title="Remove Option" *ngIf="multiOptions.length>4"
                                                    (click)="removeOption(i)" type="button" class="close"
                                                    aria-label="Close"><i class="fas fa-times-circle"></i></button>
                                                <div class="row align-items-center optFldsLoop">
                                                    <div class="col-2">
                                                        <div class="custom-control custom-checkbox">
                                                            <input type="checkbox" class="custom-control-input"
                                                                formControlName="answer"  id="opt{{i}}" />
                                                            <label class="custom-control-label" [for]="'opt'+i">Option {{i+1}}</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-10">
                                                        <textarea class="form-control" formControlName="option" 
                                                        [id]="optionControls[i].value.editorId">{{i}}</textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </dd>
                                    </dl>
                                </div>

                                <div *ngIf="optionFlag" class="text-danger mb-3">
                                    Option is required
                                </div>
                                <div *ngIf="answerFlag" class="text-danger mb-3">
                                    Choose atleast one answer
                                </div>
                                <div class="text-right">
                                    <button type="button" class="btn btn-sm btn-primary" (click)="addOptions()"><i
                                            class="fas fa-plus-circle mr-2"></i>Add More Option</button>
                                </div>

                            </div>
                            <div *ngIf="showBoolFlag" class="mb-4 optFldsBx">
                                <dl formArrayName="meta_data" *ngFor="let opt of optionControls; let i = index;">
                                    <dd [formGroupName]="i">
                                        <div class="form-group">
                                            <div class="row align-items-center optFldsLoop">
                                                <div class="col-2">
                                                    <div class="custom-control custom-radio">
                                                        <input class="custom-control-input" name="answer" [value]="true"
                                                            type="radio" formControlName="answer"
                                                            (change)="handleChange(i)" id="opt{{i}}" />
                                                        <label class="custom-control-label" [for]="'opt'+i">Option
                                                            {{i+1}}</label>
                                                    </div>
                                                </div>
                                                <div class="col-10">
                                                    <textarea class="form-control"
                                                        formControlName="option" [id]="i">
                                                    </textarea>
                                                </div>
                                            </div>

                                        </div>
                                    </dd>
                                </dl>
                                <div *ngIf="optionFlag" class="text-danger mb-3">
                                    Option is required
                                </div>
                                <div *ngIf="answerFlag" class="text-danger mb-3">
                                    Choose atleast one answer
                                </div>
                            </div>
                            </div>

                            <div class="col-md-12 mb-3 mb-md-4">
                                <label class="avenirBlack mb-2">Tags</label>
                                <!-- <input class="form-control form-control-lg form-control-style-2" type="text" /> -->

                                <tag-input class="form-control form-control-lg form-control-style-2" [formControlName]="'tags'" [addOnBlur]="true" [clearOnBlur]="true"
                                    [secondaryPlaceholder]="'Enter a tag'" [displayBy]="'tag'" [identifyBy]="'_id'"
                                    [ngClass]="{ 'is-invalid': submitAddQus && addQus.tags.errors }">
                                    <tag-input-dropdown [autocompleteItems]="qusTags" [displayBy]="'tag'"
                                        [identifyBy]="'_id'">
                                    </tag-input-dropdown>
                                </tag-input>
                                <div *ngIf="submitAddQus && addQus.tags.errors" class="invalid-feedback">
                                    <div *ngIf="addQus.tags.errors.required"> Tag is required</div>
                                </div>
                            </div>

                            <div class="col-md-12 mb-3 mb-md-4">
                                <label class="avenirBlack mb-2">Marks</label>
                                <!-- <input class="form-control form-control-lg form-control-style-2" type="text" /> -->

                                <input type='number' class="form-control form-control-lg form-control-style-2"  formControlName="marks"
                                    [ngClass]="{ 'is-invalid': submitAddQus && addQus.marks.errors }">

                                <div *ngIf="submitAddQus && addQus.marks.errors" class="invalid-feedback">
                                    <div *ngIf="addQus.marks.errors.required">marks is required</div>
                                </div>

                            
                            </div>

                            <div class="col-md-4 mb-3 mb-md-4">
                                <label class="avenirBlack mb-2">Duration ( Mins )</label>
                                <div class="row ">
                                    <div class="col-6">
                                        <input type='text' maxlength="3"
                                            (input)="stripText(addQuestionForm.get('duration'))"
                                            class="form-control form-control-lg form-control-style-2" 
                                            formControlName="duration"
                                            [ngClass]="{ 'is-invalid': submitAddQus && addQus.duration.errors }">
                                        <div *ngIf="submitAddQus && addQus.duration.errors" class="invalid-feedback">
                                            <div *ngIf="addQus.duration.errors.required"> Duration is required</div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <!-- <select
                                            class="form-control form-control-lg form-control-style-2"
                                            formControlName="durationUnit">
                                            <option *ngFor="let unit of units" [value]="unit._id" selected="Minutes">
                                                {{unit.name}}
                                            </option>
                                        </select> -->
                                        <input class="form-control form-control-lg form-control-style-2" placeholder="Minutes" readonly/>
                                    </div>
                                </div>
                            </div>


                            <div *ngIf="isShown" class="custCFBoxOuter">
                                <div class="custCFBox text-center">
                                    <p>{{confirmMsg}}</p>
                                    <div>
                                        <button class="btn btn-sm btn-primary mx-2" type="button" (click)=handleYes()>Yes</button>
                                        <button class="btn btn-sm btn-primary mx-2" (click)="handleNo()" type="button">No,
                                            Thanks!</button>
                                    </div>
                                </div>
                            </div>

                            
                            <div class="col-md-6 mb-3 mb-md-4 offset-md-2">
                                <label class="avenirBlack mb-2">Complexity</label>
                                <!-- <select class="form-control form-control-lg form-control-style-2 form-control-caret">
                                    <option selected>Select complexity level</option>
                                    <option selected>Option 2</option>
                                </select> -->

                                <select class="form-control form-control-lg form-control-style-2 form-control-caret"
                                    formControlName="complexity"
                                    [ngClass]="{ 'is-invalid': submitAddQus && addQus.complexity.errors }">
                                    <option selected value="">Select option</option>
                                    <option *ngFor="let cmplxty of complexities" [value]="cmplxty._id">
                                        {{cmplxty.name}}
                                    </option>
                                </select>

                                <div *ngIf="submitAddQus && addQus.complexity.errors" class="invalid-feedback">
                                    <div *ngIf="addQus.complexity.errors.required"> Complexity is required</div>
                                </div>
                            </div>

                            <div class="col-md-12">
                                <hr />

                                <div class="btn-groups justify-content-end">
                                    <button class="btn btn-primary-border btn-w-100" (click)="goBack()">Cancel</button>
                                    <button *ngIf="!ques_id"class="btn btn-primary btn-w-100">Save</button>
                                    <button *ngIf="ques_id" type="submit" class="btn btn-primary">Update</button>
                                </div>

                             
                            </div>

                        </div>
                    </form>
                </div>

              
            </div>
        </section>
    </div>
</div>