import { Component, OnInit, Renderer2 } from '@angular/core';
import { NavigationStart, Router, Event as NavigationEvent, ActivatedRoute } from '@angular/router';
import { io } from 'socket.io-client';
import { environment } from 'src/environments/environment';
import { AuthService } from './services/auth.service';
import { CourseService } from './services/course.service';
import { HeaderService } from './services/header.service';
import { ShareService } from './services/share.service';
import { SocketService } from './services/web/socket.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [ShareService]
})
export class AppComponent implements OnInit {
  title = 'educollab';
  showHead: boolean = true;
  showfoot: boolean = true;
  constructor(
    private router: Router,
    private headerService: HeaderService,
    private socket_service: SocketService,
    private renderer: Renderer2,
    private route: ActivatedRoute,
    private auth: AuthService,
    private sharedService: ShareService,
    private courseService: CourseService
  ) {
  }
  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
          window.scrollTo(0,0);
        if (!!event.url && event.url.match(/^\/#/)) {
          this.router.navigate([event.url.replace('/#!', event.url.substring(3))]);
        }
      }
    });

    this.router.events
      .subscribe(
        (event: NavigationEvent) => {
          if (event instanceof NavigationStart) {
            let splictVal = event.url.split('/');
            let splictPop = splictVal.pop().split('?')
            let FinalVal = splictPop[0]
            if (event.url === '/login' || event.url != '/' && !this.auth.isLogin || event.url === '/forgot-password' || event.url === '/reset-password') {
              this.showHead = false;
              this.showfoot = false;
              const body = document.getElementsByTagName('body')[0];
              body.classList.add('pt-0');
            }
            else {
              this.showHead = true;
              this.showfoot = true;
              const body = document.getElementsByTagName('body')[0];
              body.classList.remove('pt-0');
            }

            // if(FinalVal == 'feedback-assessement' || FinalVal == 'submit-assessement'){
            //   document.querySelector('body').addEventListener("contextmenu", (e) => {
            //     e.preventDefault();
            //   });
            // }
          }
        });



    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    document.body.appendChild(tag);

    if (localStorage.getItem('avatarURL')) {
      this.headerService.setLogo(localStorage.getItem('avatarURL'));
    }
    if (localStorage.getItem('user_id')) {
      this.connection();
      this.get_notification();
      this.get_message_count();
    }
  }


  connection() {
    this.socket_service.connection();
  }


  get_notification() {
    this.socket_service.get_notification();
  }
  ngAfterViewInit() {
    let loader = this.renderer.selectRootElement('#loader');
    this.renderer.setStyle(loader, 'display', 'none');
  }
  get_message_count() {
    this.socket_service.get_count();
  }
}
