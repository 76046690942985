import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { CourseService } from 'src/app/services/course.service';
import { NotificationService } from 'src/app/services/notification.service';
import { environment } from 'src/environments/environment';
import { HttpEventType, HttpEvent } from '@angular/common/http';
import { UploadFilesService } from 'src/app/services/upload-files.service';
import { QuestionService } from 'src/app/services/question.service';
import { Observable } from 'rxjs/internal/Observable';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

declare var tinymce: any;

@Component({
  selector: 'app-note-taking',
  templateUrl: './note-taking.component.html',
  styleUrls: ['./note-taking.component.scss']
})

export class NoteTakingComponent implements OnInit {
  apiEndpoint;
  saving_notes = false;
  insertBlankFlag: boolean = false;
  toolbarWithoutBlankButton = 'undo redo | formatselect | bold italic backcolor | \
  alignleft aligncenter alignright alignjustify | \
  bullist numlist outdent indent | removeformat | \
  link image | preview media help';
  fileArr: any = [];
  @Input() noteTake;
  @Input() session_detail_data: any;
  @Input() topic_id: any;
  @Input() player:any
  @ViewChild("fileU1", { static: false }) input: ElementRef;

  course_id = '';
  session_id;
  NotesAndSummry: FormGroup;
  NotEditing = true;
  notes_is: boolean = true;
  input_is: boolean = true;
  type = '';
  summaryName = 'Add Summary'
  NoteAndSummry = [];
  note_id = '';
  timest: string
  hours: any
  second: any
  minutes: any

  selectFileSize: number = 0;
  totalFilesSize: number = 0;
  // selectFilelenghth: any;
  uploadedFiles: any = [];
  selectedPic: any = [];
  selectedVideo: any = [];
  selectedDoc: any = [];
  // fileName: any;
  itemSum: number = 0;
  // moduleName: string;
  uploadItem: boolean = false;
  noFileSelected: boolean = true;

  resources: FormArray;
  saveNotesAndSummryForm = false;
  @Output() note_summary_updated = new EventEmitter();
  loader: boolean;
  upload_index: any;
  progressBar: number;
  fileUploadClear1: any;
  fileInfos: Observable<any>;
  uploadedMedia: Array<any> = [];
  uploadArray = [];

  constructor(
    private ns: NotificationService,
    private course_service: CourseService,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private active_route: ActivatedRoute,
    private activatedRoute: ActivatedRoute,
    private questionService: QuestionService,
    private UFS: UploadFilesService) {
    this.apiEndpoint = environment.APIEndpoint;


  }
  ngOnInit() {
    this.validate_form();
    this.course_id = this.activatedRoute.snapshot.queryParamMap.get('course_id');
    this.session_id = this.activatedRoute.snapshot.queryParamMap.get('session_id');
  }



  selectFilelenghth;
  moduleName;
  browseFileLen = 0;
  selectedArray;
  fileSizeLimit: number;
  fileSize: number = 0;
  mediaFileSize: number = 0;
  onFileBrowse(event: Event) {
    const target = event.target as HTMLInputElement;
    let browseFile = target.files;
    this.browseFileLen = browseFile.length;
    this.uploadArray = [];
    this.processFiles(target.files);
  }
  processFiles(files) {
    let maxLimitItem = 10;
    this.fileSizeLimit = 20000000;
    this.selectedArray = this.uploadedMedia.length;
    let moreAllowItem = maxLimitItem - this.selectedArray;
    let fileLength = files.length;
 
   if (fileLength < moreAllowItem || fileLength == moreAllowItem) {
      for (let i = 0; i < files.length; i++) {
        let file = files[i];
        if (this.validateFile(file.name)) {
          this.ns.showNotification("info", 'Selected file format is not supported.');
          this.browseFileLen--;
        } else {
          let hasItem = this.uploadedMedia.find(s => s.FileName === file.name);
          if (!hasItem) {
            this.selectFilelenghth = this.selectedArray + this.browseFileLen;
            var reader = new FileReader();
            reader.readAsDataURL(file); // read file as data url
            reader.onload = (event: any) => {
              // called once readAsDataURL is completed

              let fileType = file.type;
              if (fileType.includes('image')) {
                this.moduleName = 'question-image';
              } else if (fileType.includes('video')) {
                this.moduleName = 'question-video';
              } else if (fileType.includes('audio')) {
                this.moduleName = 'question-file';
              } else if (fileType.includes('text') || fileType.includes('') || fileType.includes('application')) {
                this.moduleName = 'question-file';
              }

              this.uploadedMedia.push({
                FileName: file.name,
                FSB: file.size,
                FileSize:
                  this.UFS.getFileSize(file.size) +
                  ' ' +
                  this.UFS.getFileSizeUnit(file.size),
                FileType: file.type,
                FileUrl: event.target.result,
                FileProgessSize: 0,
                FileProgress: 0,
                Id: '',
                path: '',
                abc:false
              });

              this.startProgress(file, this.uploadedMedia.length - 1, this.moduleName);
            }

          } else {
            this.ns.showNotification("info", 'File already added.');
            this.browseFileLen--;
            // this.restUploadFile();
          }
        }
      }
      if (this.uploadedMedia.length > 0) {
        for (let m = 0; m < this.uploadedMedia.length; m++) {
          this.mediaFileSize += parseInt(this.uploadedMedia[m].FSB);
        }
      } else if (this.uploadedMedia.length < 1 || this.uploadedMedia.length == undefined) {
        this.mediaFileSize = 0;
      }
      let newSize = this.mediaFileSize + this.fileSize;
      // console.log('media Ken', this.uploadedMedia.length)
    } else {
      this.ns.showNotification("info", 'Only 10 files allowed.');
      this.browseFileLen = 0;
    }
  }
  savevisible: boolean = true;
  async startProgress(file, index, module_name) {
    let filteredFile = this.uploadedMedia
      .filter((u, index) => index === index)
      .pop();

    if (filteredFile != null) {
      let fileSize = this.UFS.getFileSize(file.size);
      let fileSizeInWords = this.UFS.getFileSizeUnit(file.size);
      if (this.UFS.isApiSetup) {
        let formData = new FormData();
        formData.append('resource', file);

        this.UFS
          .uploadMedia(formData, module_name)
          .subscribe(
            (res: any) => {
              if (res.resource_info == undefined) {
                this.savevisible = false;
                
              } else {
                this.savevisible = true;
                this.uploadArray.push(res.resource_info)
              }
              if (res.status === 'progress') {
                let completedPercentage = parseFloat(res.message);
                filteredFile.FileProgessSize = `${(
                  (fileSize * completedPercentage) /
                  100
                ).toFixed(2)} ${fileSizeInWords}`;
                filteredFile.FileProgress = completedPercentage;

                let pro = this.uploadedMedia.find(s => s.FileProgress != 100)

              } else if (res.status === 'completed') { 
                filteredFile.Id = res.resource_id;
                filteredFile.path = res.path;

                filteredFile.FileProgessSize = fileSize + ' ' + fileSizeInWords;
                filteredFile.FileProgress = 100;
              }

              if (res.resource_info) {
                filteredFile.Id = res.resource_info[0].resource_id;
                filteredFile.path = res.resource_info[0].path;
                this.restUploadFile();
              }
            },
            (error: any) => {
              console.log('file upload error');
              console.log(error);
            }
          );
      } else {
        for (
          var f = 0;
          f < fileSize + fileSize * 0.0001;
          f += fileSize * 0.01
        ) {
          filteredFile.FileProgessSize = f.toFixed(2) + ' ' + fileSizeInWords;
          var percentUploaded = Math.round((f / fileSize) * 100);
          filteredFile.FileProgress = percentUploaded;
          await this.fakeWaiter(Math.floor(Math.random() * 35) + 1);
        }
      }
    }

  }

  fakeWaiter(ms: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  validateFile(name: String) {
    var ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() == 'exe') {
      return true;
    }
    else {
      return false;
    }
  }

  questionModel = null;
  openModelDeleteRes(deleteRes) {
    this.questionModel = this.modalService.open(deleteRes, { size: "sm", centered: true });
  }

  closeResPopup() {
    this.questionModel.close();
    // this.questionId = null;
  }

  deleteResouce(item, idx: number) {
    let sp = item.path;
    let fileTitleSplit = sp.split('/');
    let fileTitle = fileTitleSplit[1];
      this.uploadedMedia = this.uploadedMedia.filter((u, index) => index !== idx);

      this.selectFilelenghth = this.uploadedMedia.length;
      this.questionService.deleteResource(sp).subscribe(res => {
        this.ns.showNotification("success", res.message);
        this.questionModel.close()
        this.restUploadFile();
        if(this.browseFileLen > 0){
          this.browseFileLen--;
        }
        
        if(this.uploadArray.length > 0){
          this.uploadArray.length--;
        }
      })
      if (this.selectFilelenghth == 0 || this.selectFilelenghth < 1) {
        this.restUploadFile();
      }
      if (this.saveNotesAndSummryForm) {
        this.selectedDoc = [];
        this.selectedPic = [];
        this.selectedVideo = [];
      }
  }


  restUploadFile() {
    let tsnString = (document.getElementById("fileU1") as HTMLTextAreaElement).value = '';

  }




  editTimestamp:any
  addNotes(NotEditing, type, NoteAndSummryEditDetail) {
    this.edit_note = true;
    this.pauseVideo();

    if(this.player){
      this.hours = String(Math.floor(this.ytTime / 3600)).padStart(2, '0');
      this.minutes = String(Math.floor(this.ytTime / 60) - (this.hours * 60)).padStart(2, '0');
      this.second = String(Math.floor(this.ytTime % 60)).padStart(2, '0');
      this.timest = [this.hours + ":" + this.minutes + ":" + this.second].toString()
     }
     
    if(this.videoElement){
     this.hours = String(Math.floor(this.videoElement.currentTime / 3600)).padStart(2, '0');
     this.minutes = String(Math.floor(this.videoElement.currentTime / 60) - (this.hours * 60)).padStart(2, '0');
     this.second = String(Math.floor(this.videoElement.currentTime % 60)).padStart(2, '0');
    }
     this.editTimestamp= NoteAndSummryEditDetail.timestamp
    if (tinymce.get()) {
      tinymce.remove('textarea');
    }
    this.callMceEditor('addNoteandSummary');
    this.NotEditing = !NotEditing;
    this.type = type;
    // this.NoteAndSummry = NoteAndSummry;

    

    if (NoteAndSummryEditDetail) {
      this.note_id = NoteAndSummryEditDetail._id
      this.NotesAndSummry.controls.title.setValue(NoteAndSummryEditDetail.title);
      this.NotesAndSummry.controls.content.setValue(NoteAndSummryEditDetail.content);
      
      if (NoteAndSummryEditDetail.image_path_key.length > 0) {
        this.resourceCondition(NoteAndSummryEditDetail.image_path_key, this.uploadedMedia)
      }
      if (NoteAndSummryEditDetail.video_path_key.length > 0) {
        this.resourceCondition(NoteAndSummryEditDetail.video_path_key, this.uploadedMedia)
      }
  
      if (NoteAndSummryEditDetail.resources_key.length > 0) {
        this.resourceCondition(NoteAndSummryEditDetail.resources_key, this.uploadedMedia)
      }
  
      this.selectFilelenghth = this.uploadedMedia.length;

      
      setTimeout(() => {
        tinymce.get("addNoteandSummary").setContent(NoteAndSummryEditDetail.content);
      }, 300)
    } else {
      this.NoteAndSummry = [];
      this.uploadedMedia= []
      this.NotesAndSummry.reset();

    }
   

  }

  validate_form() {
    this.NotesAndSummry = this.fb.group({
      title: [''],
      content: ['', Validators.required],
      timestamp: [''],
      video_path: [''],
      image_path: [''],
      resource_path: ['']
    })
  }

  get_value() {
    return this.NotesAndSummry.value.title == null ? '' : this.NotesAndSummry.value.title;
  }



  saveNotesAndSummry(type) {
    // this.input_is =true
     //this.notes_is = true
    this.saveNotesAndSummryForm = true;
    //this.edit_note = true;
    if(this.NotesAndSummry.valid){
      this.edit_note = false;
    }
    
    let description = this.ns.titleCase(tinymce.get("addNoteandSummary").getContent());
    description = this.ns.paraString(description);
    this.NotesAndSummry.controls.content.setValue(description);
    // if (this.NotesAndSummry.value.title) {
    //   this.ns.showNotification('info', 'Title is mandatory. Please add title');
    //   return;
    // } else if (this.NotesAndSummry.value.title == null && type == 'note') {
    //   this.ns.showNotification('info', 'Title is mandatory. Please add title');
    //   return;
    // } else if (this.NotesAndSummry.value.image_path == null &&this.NotesAndSummry.value.resource_path && type == 'note') {
    //   this.ns.showNotification('info', 'image and resource is mandatory. Please add both');
    //   return;
    // }
    
    if (this.NotesAndSummry.value.title == null && type == 'note') {
      console.log(this.NotesAndSummry.valid)
      this.ns.showNotification('info', 'Title is mandatory. Please add title');
      this.selectedDoc = [];
      this.selectedPic = [];
      this.selectedVideo = [];
      this.edit_note=true
      return;
    }

    if (description.length < 1) {
      this.ns.showNotification('info', 'Content is mandatory. Please add content');
      this.selectedDoc = [];
      this.selectedPic = [];
      this.selectedVideo = [];
      this.edit_note=true
      return;
    }

    if (this.note_id) {
      this.editNotesAndSummary(type)
    } else {
      this.saveNotesAndSummary(type);
    }
  }


  editNotesAndSummary(type) {
    this.input_is = true
    this.notes_is = true
    this.saving_notes = true;
    // this.timest = [this.hours + ":" + this.minutes + ":" + this.second].toString()
    this.NotesAndSummry.value.timestamp = this.editTimestamp;
    let folderPath;
    let uploadedMediaFiles;
    let uploadedMediaFilespath;
    for (let s = 0; s < this.uploadedMedia.length; s++) {
      uploadedMediaFiles = this.uploadedMedia[s];
      uploadedMediaFilespath = uploadedMediaFiles.path.split('/');
      folderPath = uploadedMediaFilespath[0];
        if (folderPath == 'question_bank_files') {
          this.selectedDoc.push(uploadedMediaFiles.path);
        }
        if (folderPath == 'question-images') {
          this.selectedPic.push(uploadedMediaFiles.path);
        }
        if (folderPath == 'question-videos') {
          this.selectedVideo.push(uploadedMediaFiles.path);
        }
      
    
      
    }

    this.NotesAndSummry.value.video_path = this.selectedVideo;
    this.NotesAndSummry.value.image_path = this.selectedPic
    this.NotesAndSummry.value.resource_path = this.selectedDoc;
    if (!this.selectedPic) {
      this.selectedPic = [];
    }
    if (!this.selectedVideo) {
      this.selectedVideo = [];
    }
    if (!this.selectedDoc) {
      this.selectedDoc = [];
    }
    this.course_service.edit_notes(this.session_id, this.topic_id, this.course_id, this.note_id, type, this.NotesAndSummry.value)
      .pipe(finalize(() => this.saving_notes = false))
      .subscribe(edit_notes => {
        this.ns.showNotification('success', edit_notes.message);
        tinymce.remove('textarea');
        this.uploadedMedia= [];
        this.NotEditing = !this.NotEditing;
        this.note_id = '';
        this.edit_note=false
        this.note_summary_updated.emit('update_note');
        this.NotesAndSummry.reset();
        this.restUploadFile();
        this.selectedDoc = [];
        this.selectedPic = [];
        this.selectedVideo = [];

      },
        (err) => {
          this.ns.showNotification('error', err.message);
          console.log(err.message)
          this.edit_note=true
        }
      )
  }



  // re-design code from here 
  resourceInfo = [];
  saveNotesAndSummary(type) {
    this.input_is = true
    this.notes_is = true
    this.saving_notes = true;
  
    this.timest = [this.hours + ":" + this.minutes + ":" + this.second].toString()
    
    // this.timest = [Math.floor(this.videoElement.currentTime / 3600)+ ":"+ Math.floor(this.videoElement.currentTime / 60)+":"+ Math.floor(this.videoElement.currentTime % 60)].toString()
   
    let folderPath;
    let uploadedMediaFiles;
    let uploadedMediaFilespath;
    for (let s = 0; s < this.uploadedMedia.length; s++) {
      uploadedMediaFiles = this.uploadedMedia[s];
      uploadedMediaFilespath = uploadedMediaFiles.path.split('/');
      folderPath = uploadedMediaFilespath[0];

      if (folderPath == 'question_bank_files') {
        this.selectedDoc.push(uploadedMediaFiles.path);
      }
      if (folderPath == 'question-images') {
        this.selectedPic.push(uploadedMediaFiles.path);
      }
      if (folderPath == 'question-videos') {
        this.selectedVideo.push(uploadedMediaFiles.path);
      }
      //  console.log('module name',moduleName) 
    }

    this.NotesAndSummry.value.timestamp = this.timest;
    this.NotesAndSummry.value.video_path = this.selectedVideo;
    this.NotesAndSummry.value.image_path = this.selectedPic;
    this.NotesAndSummry.value.resource_path = this.selectedDoc

    if (!this.selectedPic) {
      this.selectedPic = [];
    }
    if (!this.selectedVideo) {
      this.selectedVideo = [];
    }
    if (!this.selectedDoc) {
      this.selectedDoc = [];
    }
    this.course_service.add_note(this.session_id, type, this.course_id, this.topic_id, this.NotesAndSummry.value)
      .pipe(finalize(() => this.saving_notes = false)).subscribe(notes => {
        this.ns.showNotification('success', notes.message);
        tinymce.remove('textarea');
        this.NotEditing = !this.NotEditing;
        this.saving_notes = true;
        this.note_id = '';
        this.note_summary_updated.emit('update_note');
        this.NotesAndSummry.reset();
        this.edit_note=false
        this.uploadedMedia= []
        this.restUploadFile()
        this.selectedDoc = [];
        this.selectedPic = [];
        this.selectedVideo = [];
      },
        (err) => {
          this.ns.showNotification('error', err.message);
          console.log(err.message);
          this.edit_note=true
        })
  }


  deleteNotesandSummary(type, note) {
    let note_id = note._id
    if (confirm('are you sure to delete this note')) {
      this.course_service.delete_notes(this.session_id, type, note_id)
        .pipe(finalize(() => this.saving_notes = false)).subscribe(notes => {
          this.ns.showNotification('success', notes.message);
          this.note_summary_updated.emit('update_note');
          this.note_id = '';
        },
          (err) => {
            this.ns.showNotification('error', err.message);
          })
    }
  }



  callMceEditor(edId) {
    let that = this;
    setTimeout(() => {
      tinymce.baseURL = 'assets';
      tinymce.init({
        selector: 'textarea#' + edId,
        height: 300,
        // base_url: '/tinymce',
        suffix: '.min',
        themes: "assets/themes/silver",
        // icons: "assets/icons/",
        // content_css: '//www.tiny.cloud/css/codepen.min.css',

        // plugins: [
        //   'advlist autolink lists link image imagetools charmap print preview anchor',
        //   'searchreplace visualblocks code fullscreen',
        //   'insertdatetime media table paste code help wordcount'
        // ],
        toolbar: that.toolbarWithoutBlankButton,
        image_title: true,
        automatic_uploads: true,
        image_description: false,
        file_picker_types: 'file image media',
        // external_plugins: { tiny_mce_wiris: "https://www.wiris.net/demo/plugins/tiny_mce/plugin.js" }, 
        // toolbar1: "tiny_mce_wiris_formulaEditor, tiny_mce_wiris_formulaEditorChemistry",
        file_picker_callback: function (cb, value, meta) {

          let input = document.createElement('input');
          input.setAttribute('type', 'file');
          input.setAttribute('accept', 'image/*');

          // Note: In modern browsers input[type="file"] is functional without 
          // even adding it to the DOM, but that might not be the case in some older
          // or quirky browsers like IE, so you might want to add it to the DOM
          // just in case, and visually hide it. And do not forget do remove it
          // once you do not need it anymore.

          input.onchange = function () {

            // var file = $event.path[0].files[0];
            let file = input.files[0];

            let reader: any = new FileReader();

            reader.onload = function () {

              // Note: Now we need to register the blob in TinyMCEs image blob
              // registry. In the next release this part hopefully won't be
              // necessary, as we are looking to handle it internally.
              let id = 'blobid' + (new Date()).getTime();
              let blobCache = tinymce.activeEditor.editorUpload.blobCache;
              let base64 = reader.result.split(',')[1];
              let blobInfo = blobCache.create(id, file, base64);

              blobCache.add(blobInfo);

              // call the callback and populate the Title field with the file name

              cb(reader.result, { title: file.name });
            };
            reader.readAsDataURL(file);
          };

          input.click();
        },
        images_upload_handler: function (blobInfo, success, failure, progress) {
          let xhr, formData;

          xhr = new XMLHttpRequest();

          xhr.withCredentials = false;
          xhr.open('POST', that.apiEndpoint + '/upload_course_resource');
          xhr.setRequestHeader('session-token', localStorage.getItem('token')); // manually set header

          xhr.upload.onprogress = function (e) {
            progress(e.loaded / e.total * 100);
          };

          xhr.onerror = function () {
            failure("Image upload failed due to a XHR Transport error. Code: " + xhr.status);
          };

          xhr.onload = function () {
            let json;

            if (xhr.status != 200) {
              failure('HTTP Error: ' + xhr.status);
              return;
            }
            json = JSON.parse(xhr.responseText);

            if (!json || typeof json.resource_id != 'string') {
              failure('Invalid JSON: ' + xhr.responseText);
              return;
            }

            success(json.s3_location);
            let fileObj: any = {};
            fileObj._id = json.resource_id,
              fileObj.type = "file"
            that.fileArr.push(fileObj);
          };

          formData = new FormData();
          formData.append('resource_content', blobInfo.blob(), blobInfo.filename());


          xhr.send(formData);
        },
        setup: function (editor) {
          // if (edId == 'fibtype') {
          if (that.insertBlankFlag) {
            editor.ui.registry.addButton('customBlankButton', {
              text: 'Add Blank',
            });
          }
        }
      });

    }, 100)
  }

  createResources() {
    return this.fb.group({
      file_title: ['', Validators.required],
      file_id: [''],
      file_path: [''],
      file_name: ['']
    });
  }

  addResource() {
    this.resources = <FormArray>this.NotesAndSummry.get('resources');
    this.resources.push(this.createResources());
  }

  removeResource(index: number, type?: string) {
    if (type == 'manual') {
      if (confirm('Are you sure want to delete')) {
        this.remove_item(index);
      } else {
        return
      }
    } else {
      this.remove_item(index);
    }
  }

  remove_item(index) {
    let control;
    control = <FormArray>this.NotesAndSummry.controls['resources'];
    control.removeAt(index);
  }

  file: any
  fileName: any



  downloadResource(data) {
    window.open(data.s3_path);
  }

  //public player;
  currentTime: string;
  videoElement: any;
  ytTime:number
  pauseVideo() {
    let time = 0;
    if (this.player) {
      this.player.target.pauseVideo();
      time = this.player.target.getCurrentTime();
      //time = time - 10;
      this.currentTime = time.toString().split('.')[0];
      this.ytTime = time
    }
    else {
      this.videoElement = document.getElementById('videoByS3');
      if (this.videoElement) {
        this.videoElement.pause();
      }
    }
   
  }

  popupModel=null
  deleteNotesModel(deleteNotes){
    this.popupModel = this.modalService.open(deleteNotes, { size: "sm", centered: true });
  }

  closeNotesPopup(){
    this.popupModel.close();
  }


  deleteNo(val){
    this.course_service.delete_notes(this.session_id, 'note', val)
        .pipe(finalize(() => this.saving_notes = false)).subscribe(notes => {
          this.ns.showNotification('success', notes.message);
          this.note_summary_updated.emit('update_note');
          this.note_id = '';
          this.popupModel.close();
        },
          (err) => {
            this.ns.showNotification('error', err.message);
          })
  }


  resourceCondition(getArrayItem, setArrayItem) {
    for (let i = 0; i < getArrayItem.length; i++) {
      let filePath = getArrayItem[i];
      let fileNameSplitA = getArrayItem[i].split('/');
      let fileNameSplitB = fileNameSplitA[1].split('__');
      //let getFileName = fileNameSplitB[0];
      let extension = fileNameSplitB.pop().split('.');
      let getFileName = fileNameSplitB[0] + '.' + extension[1];
      setArrayItem.push({
        FileName: getFileName,
        FileProgessSize: 100,
        FileProgress: 100,
        path: filePath
      });
    }
  }


  edit_note: boolean = false
  cancel() {
    this.note_id = ''
    this.edit_note = false
    this.NotesAndSummry.reset();
    this.uploadedMedia = [];
    this.restUploadFile();
    this.selectedDoc = [];
        this.selectedPic = [];
        this.selectedVideo = [];
  }

  

}
