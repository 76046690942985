<div class="banner" *ngIf="course_work_info">
    <div class="container text-center">
        <h1 class="text-truncate">{{title}}</h1>
        <button class="btn btn-primary" routerLink="/search-course-work"><i class="fas fa-arrow-circle-left mr-2"></i>
        </button>
    </div>
</div>
<div class="pageBody courseworkInfo" *ngIf="course_work_info">
    <div class="container" [ngClass]="{submitting : loader}">
        <div class="spinner-border text-warning formLoader" role="status" *ngIf="loader">
            <span class="sr-only">Loading...</span>
        </div>
        <section class="contentBlocks text-center ss">
            <div class="icoLinkBx thinBx d-lg-inline-block m-2">
                <span class="ico"><i class="far fa-calendar-alt"></i></span>
                <span>{{course_work_info.date | dateFormatPipe}} - {{course_work_info.time}}</span>
            </div>
            <span class="toStyle m-2">to</span>
            <div class="icoLinkBx thinBx d-lg-inline-block m-2">
                <span class="ico"><i class="far fa-calendar-alt"></i></span>
                <span>{{getExpireDate(course_work_info.date, totalDays) | dateFormatPipe}} -
                    {{course_work_info.time}}</span>
            </div>
        </section>

        <div class="row no-gutters ss">
            <div class="col-12 col-lg-9 py-3 py-lg-0">
                <section class="contentBlocks pr-lg-4">
                    <h3><span>Description</span></h3>
                    <div class="text-justify" id="new" [innerHTML]="course_work_info.course_work_description">
                    </div>
                </section>
            </div>
            <div class="col-12 col-lg-3 py-3 py-lg-0 lightBg"
                *ngIf="course_work_info.course_work_resources?.length > 0">
                <h3><span>Resources</span></h3>
                <section class="contentBlocks" *ngFor="let resource of course_work_info.course_work_resources">
                    <div class="px">
                        <div class="mb-1 pt-2">
                            <button type="button" class="btn btn-lg btn-outline-primary mr-2 mb-1" title="Resource file"
                                (click)="openResource(resource.resource_url)">
                                <i class="far fa-file-alt"></i>&nbsp;
                                <span>{{resource.resource_title}}</span>
                            </button>
                        </div>
                    </div>
                </section>
            </div>
        </div>

        <section class="contentBlocks" *ngIf="course_work_info.submission_requirement?.length > 0">
            <h3 class="mbL"><span>Submission Requirements</span></h3>
            <div class="cmnBx"
                *ngFor="let requirement of course_work_info.submission_requirement; let requirementIndex = index">
                <!-- Each Submission Box (Loop) -->
                <div class="hdrBar">
                    <span class="ico"><i class="txtNo">{{requirementIndex+1}}</i></span>
                    <div class="d-md-flex justify-content-between align-items-center">
                        <span class="title">
                            {{requirement.submission_report}} ({{requirement.total_number_of_percentage}}%)
                        </span>
                        <label class="rtInfoTxt">
                            <small>Deadline:</small>&nbsp; <i
                                class="fas fa-calendar-alt mr-2"></i>{{getExpireDate(course_work_info.date,requirement.days_of_completion) | dateFormatPipe}}
                        </label>
                    </div>
                </div>
                <p class="text-justify">{{requirement.rubric}}</p>
                <!-- <div>
                    <button type="button" class="btn btn-lg btnAdd mr-2 mb-1"
                        title="Upload File">
                        <i class="fas fa-cloud-upload-alt mr-2"></i><small>Upload</small>
                        <input type="file">
                    </button>
                </div> -->
            </div>
        </section>

    </div>
</div>


<!-- // Popup for Session Video // -->
<ng-template #resourceVideo let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Resource Video</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="rounded embed-responsive embed-responsive-16by9">
            <iframe src="https://www.youtube.com/embed/r-uOLxNrNk8" frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>
        </div>
    </div>
</ng-template>