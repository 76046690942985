import { Pipe, PipeTransform } from '@angular/core';
import { CourseWorkUtilityService } from '../../services/course-work-utility.service';

@Pipe({
  name: 'expireDate'
})
export class ExpireDatePipe implements PipeTransform {

  constructor(private cwUtilityService: CourseWorkUtilityService) {
         
  }	

  transform(value: any, ...args: any[]): any {

    const courseworkTimeline = args[0] || 0;

    return this.cwUtilityService.getExpireDate(value, courseworkTimeline);
  }

}
