import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor() { }

  //FOR RANDOM BACKGROUND COLORS
  randomBgColor() {
    let x = Math.floor(Math.random() * 256);
    let y = Math.floor(Math.random() * 256);
    let z = Math.floor(Math.random() * 256);
    let bgColor = "rgb(" + x + "," + y + "," + z + ")";
   
    return bgColor;
  }

  //FOR EDITOR DISPLAY
  callMceEditor(edId, classInstance, tinymce) {

    let that = classInstance;
    setTimeout(() => {
      tinymce.baseURL = 'assets';
      tinymce.init({
        selector: 'textarea#' + edId,
        draggable_modal: true,
        height: 300,
        // base_url: '/tinymce',
        suffix: '.min',
        themes: "assets/themes/silver",
        // icons: "assets/icons/",
        // content_css: '//www.tiny.cloud/css/codepen.min.css',

        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount'
        ],
        toolbar: that.toolbarWithoutBlankButton,
        image_title: true,
        automatic_uploads: true,
        image_description: false,
        file_picker_types: 'file image media',
        // external_plugins: { tiny_mce_wiris: "https://www.wiris.net/demo/plugins/tiny_mce/plugin.js" }, 
        // toolbar1: "tiny_mce_wiris_formulaEditor, tiny_mce_wiris_formulaEditorChemistry",
        file_picker_callback: function (cb, value, meta) {

          let input = document.createElement('input');
          input.setAttribute('type', 'file');
          input.setAttribute('accept', 'image/*');

          // Note: In modern browsers input[type="file"] is functional without 
          // even adding it to the DOM, but that might not be the case in some older
          // or quirky browsers like IE, so you might want to add it to the DOM
          // just in case, and visually hide it. And do not forget do remove it
          // once you do not need it anymore.

          input.onchange = function () {

            // var file = $event.path[0].files[0];
            let file = input.files[0];

            let reader: any = new FileReader();

            reader.onload = function () {

              // Note: Now we need to register the blob in TinyMCEs image blob
              // registry. In the next release this part hopefully won't be
              // necessary, as we are looking to handle it internally.
              let id = 'blobid' + (new Date()).getTime();
              let blobCache = tinymce.activeEditor.editorUpload.blobCache;
              let base64 = reader.result.split(',')[1];
              let blobInfo = blobCache.create(id, file, base64);

              blobCache.add(blobInfo);

              // call the callback and populate the Title field with the file name

              cb(reader.result, { title: file.name });
            };
            reader.readAsDataURL(file);
          };

          input.click();
        },
        images_upload_handler: function (blobInfo, success, failure, progress) {
          let xhr, formData;

          xhr = new XMLHttpRequest();

          xhr.withCredentials = false;
          xhr.open('POST', that.apiEndpoint + '/upload_course_resource');
          xhr.setRequestHeader('session-token', localStorage.getItem('token')); // manually set header

          xhr.upload.onprogress = function (e) {
            progress(e.loaded / e.total * 100);
          };

          xhr.onerror = function () {
            failure("Image upload failed due to a XHR Transport error. Code: " + xhr.status);
          };

          xhr.onload = function () {
            let json;

            if (xhr.status != 200) {
              failure('HTTP Error: ' + xhr.status);
              return;
            }
            json = JSON.parse(xhr.responseText);

            if (!json || typeof json.resource_id != 'string') {
              failure('Invalid JSON: ' + xhr.responseText);
              return;
            }

            success(json.s3_location);
            let fileObj: any = {};
            fileObj._id = json.resource_id,
              fileObj.type = "file"
            that.fileArr.push(fileObj);
          };

          formData = new FormData();
          formData.append('resource_content', blobInfo.blob(), blobInfo.filename());


          xhr.send(formData);
        },
        setup: function (editor) {
          // if (edId == 'fibtype') {
          if (that.insertBlankFlag) {
            editor.ui.registry.addButton('customBlankButton', {
              text: 'Add Blank',
            });
          }
        }
      });

    }, 100)
  }

}

