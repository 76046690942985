<div class="new_UIstyle">
    <!-- banner section -->
        <div class="banner banner-gradient banner-course-assement">
            <div class="container">
                <div class="banner-content">
                    <div class="banner-content-content">
                        <h2 class="white-text avenirBlack">Zoom Integration</h2>
                    </div>
                    <!-- <div class="banner-content-action btn-groups">
                        <button class="btn btn-primary btn-w-120" (click)="go_to_list()">Back to Assessment</button>
                    </div> -->
                </div>
            </div>
        </div>

<div class="pageBody subPage zoomIntegrationInner mb-4">
    <div class="container">
        <div class="row">
          <div class="col-12">
            <!-- <h3 class="avenirBlack mb-3">Apps</h3> -->
            <p class="text-muted">As a <strong><em>educollab</em></strong> memeber you can add your zoom account and make meetings with us we will follow our privacy and policy.
              We will also provide recording if your account will be paid othervise you have to add them in related Place. We can't provide recording for free account.</p>
              <form class="pt-0 mb-3 mt-5" id="zoom_form" [ngClass]="{submitting : loader}"
              [formGroup]="zoom_integration">
              <div class="spinner-border text-warning formLoader" role="status" *ngIf="loader">
                <span class="sr-only">Loading...</span>
              </div>
            <ngb-accordion class="customSetAcc" #apps="ngbAccordion"  formArrayName="zoom_array">
              <ngb-panel *ngFor="let zoom_value of zoom_integration.controls.zoom_array['controls']; let zoom_index=index"
              [formGroupName]="zoom_index">


      
                <ng-template ngbPanelHeader let-opened="opened" >
                  <div class="d-flex align-items-center justify-content-between p-2 ngbPanelCustomBtn">
                    <div class="d-flex align-items-center">
                      <div class="zoomImage"><img src="../../../assets/images/apps-icon/zoom-app.png"></div>
                      <div class="px-3">
                        <h6 class="avenirBook mb-1">AutoZoomRecordings</h6>
                        <p class="text-muted m-0"><small>Date {{dateAddedApp | date:'longDate'}}</small></p>
                      </div>
                    </div>
                    <button type="button" (click)="removeZoomForm('zoom_array', zoom_index, 'delete')" class="removeBtn" ngbTooltip="Remove App" container="body">
                      <i class="fas fa-times-circle"></i>
                    </button>
                  </div>
                  <button ngbPanelToggle class="btn btn-outline-primary">{{ opened ? 'Hide' : 'View' }} Details</button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <p class="text-muted">Below credential allows you to generate a token that is utilized by Zoom OAuth, providing you
                      access to Zoom APIs.</p>
                      <div class="row">
                          <div class="col-lg-6 mb-3">
                            <label class="d-block text-normal">APP Email</label>
                            <input type="email" [readOnly]="zoom_value.controls.type.value == 'edited'"
                            [ngClass]="{ 'is-invalid':zoom_form_submit && zoom_value.controls.app_email.errors}"
                            formControlName="app_email" class="form-control form-control-md form-control-style-2" 
                            placeholder="abc@gmail.com"  [class.disabled]="zoom_value.controls.type.value == 'edited'" />
                            <div *ngIf=" zoom_form_submit && zoom_value.controls.app_email.errors" class="invalid-feedback">
                              <div *ngIf="zoom_value.controls.app_email.errors.required"> app email is required</div>
                          </div>
                          </div>
                          <div class="col-lg-6 mb-3">
                            <label class="d-block text-normal">Account Id</label>
                            <input type="text" formControlName="api_key" 
                            [ngClass]="{ 'is-invalid':zoom_form_submit && zoom_value.controls.api_key.errors}"
                            [readOnly]="zoom_value.controls.type.value == 'edited'" class="form-control form-control-md form-control-style-2" 
                            placeholder="AbcXyzMnopQAE@!"  [class.disabled]="zoom_value.controls.type.value == 'edited'" />
                            <div *ngIf=" zoom_form_submit && zoom_value.controls.api_key.errors" class="invalid-feedback">
                              <div *ngIf="zoom_value.controls.api_key.errors.required"> app account_id is required</div>
                          </div>
                          </div>
    
                          <div class="col-lg-6 mb-3">
                            <label class="d-block text-normal">Client Id</label>
                            <input type="text" formControlName="client_id" 
                            [ngClass]="{ 'is-invalid':zoom_form_submit && zoom_value.controls.client_id.errors}"
                            [readOnly]="zoom_value.controls.type.value == 'edited'" class="form-control form-control-md form-control-style-2" 
                            placeholder="AbcXyzMnopQAE@!" 
                            [class.disabled]="zoom_value.controls.type.value == 'edited'" />
                            <div *ngIf=" zoom_form_submit && zoom_value.controls.client_id.errors" class="invalid-feedback">
                              <div *ngIf="zoom_value.controls.client_id.errors.required"> app client_id is required</div>
                          </div>
                          </div>
    
                          <div class="col-lg-6 mb-3">
                            <label class="d-block text-normal">Secret Key</label>
                            <input type="password" formControlName="secret_key" 
                            [ngClass]="{ 'is-invalid':zoom_form_submit && zoom_value.controls.secret_key.errors}"
                            [readOnly]="zoom_value.controls.type.value == 'edited'" class="form-control form-control-md form-control-style-2"
                            placeholder="AbcXyzMnopQAEAbcXyzMnopQAE"  [class.disabled]="zoom_value.controls.type.value == 'edited'" />
                            <div *ngIf=" zoom_form_submit && zoom_value.controls.secret_key.errors" class="invalid-feedback">
                              <div *ngIf="zoom_value.controls.secret_key.errors.required"> app secret_key is required</div>
                          </div>
                          </div>
    
                          <div class="col-12 mb-3">
                            <p class="mb-0"><label>Permission</label></p>
                            <div class="custom-control custom-checkbox custom-control-inline">
                              <input type="checkbox" class="custom-control-input" formControlName="co_authors" id="co_authors{{zoom_index}}">
                              <label class="custom-control-label" for="co_authors{{zoom_index}}">Co Authors</label>
                            </div>
                            <div class="custom-control custom-checkbox custom-control-inline">
                              <input type="checkbox" class="custom-control-input" formControlName="public_to_org" id="public_to_org{{zoom_index}}">
                              <label class="custom-control-label" for="public_to_org{{zoom_index}}">Public to Organization</label>
                            </div>
                            <div class="custom-control custom-checkbox custom-control-inline">
                              <input type="checkbox" class="custom-control-input" formControlName="collaborators" id="collaborators{{zoom_index}}">
                              <label class="custom-control-label" for="collaborators{{zoom_index}}">Collaborators</label>
                            </div>
                          </div>
                      </div>

                    <div class="text-right">
                      <button class="btn btn-sm btn-primary btn-w-120" type="button" (click)="submit_zoom(zoom_index)">Save</button>
                    </div> 
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
          </form>
          <div class="text-right">
            <button class="btn btn-primary" type="button" (click)="add_zoom_form()"><i class="fas fa-plus mr-2"></i>Add Another Zoom</button>
          </div> 
      
          </div>
        </div>
    </div>
  </div>

  </div>