import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-schedule-calendar',
  templateUrl: './schedule-calendar.component.html',
  styleUrls: ['./schedule-calendar.component.scss']
})
export class ScheduleCalendarComponent implements OnInit {

  groupProcessing: boolean = false;

  constructor(private modalService: NgbModal) { }

  ngOnInit() {
  }
  addEventModel;
  open(content) {
    this.addEventModel = this.modalService.open(content, { windowClass: 'newEvent', centered: true, size: "sm", backdrop: 'static', keyboard: false });
  }
  closeModel() {
    this.addEventModel.close();
  }
  closeTooltipVar: boolean = false;
  closeTooltip() {
    this.closeTooltipVar = !this.closeTooltipVar;
  }
} 
