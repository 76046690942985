import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-found',
  templateUrl: './no-found.component.html',
  styleUrls: ['./no-found.component.scss']
})
export class NoFoundComponent implements OnInit {
  @Input() errorMsg: any;
  constructor() { }

  ngOnInit() {
    this.errorMsg = 'Oops! there is nothing related to your search, try searching something else.'
  }

}
