<div class="" [ngClass]="{submitting : processLoader || isSubmissionDataLoading}">
    <div>
        <div *ngIf="showReport" class="report-wrapper">
            <div *ngIf="submissionPublishStatus" class="grade">
                <hr>
                <p class="primary-text mb-4 mt-4"><span class="cw-grade-heading">Grade :</span>
                    <span class="cw-grade ml-2">{{gradeReport}}</span>
                </p>
            </div>

            <div class="report-review-wrapper">
                <div>
                    <hr>
                    <p class="primary-text mb-2 mt-4"><span class="reviews-heading">Reviews:</span></p>
                </div>

                <div *ngIf="submissionPublishStatus" class="teaching-assistant">
                    <p class="primary-text mb-2 mt-4"><span class="reviews-sub-heading">Final Review</span></p>
                    <p class="reviews-content">
                    	{{remarkReport | capitalization}}
                    </p>
                </div>

                <div *ngIf="courseworks.peer_review == true" class="peer">
                    <p class="primary-text mb-2 mt-4"><span class="reviews-sub-heading">Peer Review</span></p>
                    <p class="reviews-content">
                    	{{peerReviewReport | capitalization}}
                    </p>
                </div>

                <div *ngIf="courseworks.self_review == true" class="self">
                    <p class="primary-text mb-2 mt-4">
                        <span class="reviews-sub-heading">Self Review</span>
                        <a *ngIf="showSelfReviewEditBtn && (!showReviewForm && !editReviewType)" class="float-right" ngbTooltip="Edit" (click)="onEditReview('self_review')"><i class="fas fa-pencil-alt"></i></a>
                    </p>
                    <p *ngIf="!showReviewForm && !editReviewType" class="reviews-content">
                    	{{selfReviewReport | capitalization}}
                    </p>
                    <!-- EDIT REVIEW FORM |START -->
                    <div *ngIf="showReviewForm && editReviewType == 'self_review'">
                        <div class="form-group">
                            <div>
                                <form [formGroup]="reviewForm" autocomplete="off">
                                    <textarea type="text" formControlName="review" id="selfReviewEditInputBox" class="mt-2 form-control reviews-textarea" cols="10" rows="6" [value]="reviewForm.get('review').value | capitalization"></textarea>
                                    <div class="text-center mt-4">
                                        <div class="spinner-border text-warning formLoader d-inline-block mr-4 inline-loader" *ngIf="processLoader" role="status" >
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                        <button type="button" *ngIf="editReviewType" class="btn btn-primary btn-w-120 mr-2" (click)="onDiscardReview()" [disabled]="processLoader">
                                            Discard
                                        </button>
                                        <button type="button" class="btn btn-primary btn-w-120" (click)="onSaveReview()" [disabled]="processLoader">
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <!-- EDIT REVIEW FORM |END -->
                </div>
            </div>
        </div>

        <div *ngIf="showReviewForm && !editReviewType">
            <div *ngIf="!showReport">
                <hr>
                <p class="primary-text mb-2 mt-4"><span class="reviews-heading">Reviews:</span></p>
            </div>
            <p class="primary-text mb-2 mt-4"><span class="reviews-sub-heading">Give yourself a Review</span></p>
            <div class="form-group">
                <div>
                	<form [formGroup]="reviewForm" autocomplete="off">
    	                <textarea type="text" formControlName="review" id="selfReviewInputBox" class="mt-2 form-control reviews-textarea" cols="10" rows="6" [value]="reviewForm.get('review').value | capitalization"></textarea>
    	                <div class="text-center mt-4">
                            <div class="spinner-border text-warning formLoader d-inline-block mr-4 inline-loader" *ngIf="processLoader" role="status" >
                                <span class="sr-only">Loading...</span>
                            </div>
    	                    <button type="button" class="btn btn-primary btn-w-120" (click)="onSaveReview()" [disabled]="processLoader">
    	                    	Submit
    	                	</button>
    	                </div>
                	</form>
                </div>
            </div>
        </div>

        <div *ngIf="isPeerAssigned">
            <app-course-work-test-review-peer 
            [peerSubmissionReportData]="peerSubmissionReportData"
            [courseworks]="courseworks"
            [viewSubmissionData]="viewSubmissionData"
            [findedIndex]="findedIndex"
            [findedPeerIndex]="findedPeerIndex"
            [submissionData]="submissionData"
            [showCourseWork]="showCourseWork"
            [courseId]="courseId"
            (sendPeerReviewSavedStatus)="getPeerReviewSavedStatus($event)">
            </app-course-work-test-review-peer>
        </div>

    </div>
</div>
