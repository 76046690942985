<div class="new_UIstyle">
    <div class="pageBodyWrap questionBankMain">
        <!-- Banner Section Start -->
        <section class="banner banner-inner banner-ask-qstion text-center"
            style="background-image: url('assets/images/banner/question-bank.jpg');">
            <div class="container">
                <div class="banner-content">
                    <h3 class="white-text amitaBold mb-2">{{question_type}}</h3>
                </div>
            </div>
        </section>

        <section class="section askQuestionWrap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-10 m-auto">
                        <div class="askQuestionform">
                            <form action="" class="typo-16"
                                [formGroup]="askQuestionForm" (ngSubmit)="questionFormSubmit()">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="mt-2 form-group">
                                            <label>Category</label>
                                            <select
                                                class="form-control form-control-style-2 form-control-lg form-control-caret"
                                                formControlName="subject"
                                                [ngClass]="{ 'is-invalid': submitAskQustion && askQuestionForm.controls.subject.errors , 'disabled': routerUrl == '/edit-ask-question' }">
                                                <!-- [disabled]="routerUrl == '/edit-ask-question'" -->
                                                <option [selected]="chooseCate == true" value="">{{chooseCategory}}
                                                </option>
                                                <option *ngFor="let quCat of quesCat" [value]="quCat._id">
                                                    {{quCat.Course_Category}}
                                                </option>
                                            </select>
                                            <div *ngIf="submitAskQustion && askQuestionForm.controls.subject.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="askQuestionForm.controls.subject.errors.required">Category
                                                    is
                                                    required
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="mt-2 form-group">
                                            <label>Coins</label>
                                            <input type="number"
                                                class="form-control form-control-style-2 form-control-lg"
                                                formControlName="coins" placeholder="Coin Amount"
                                                [ngClass]="{ 'is-invalid': submitAskQustion && askQuestionForm.controls.coins.errors , 'disabled': routerUrl == '/edit-ask-question'  }"/>
                                                 <!-- [disabled]="routerUrl == '/edit-ask-question'" -->
                                            <div *ngIf="submitAskQustion && askQuestionForm.controls.coins.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="askQuestionForm.controls.coins.errors.required">Coins is
                                                    required
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="mt-2 form-group">
                                            <label>Question tags</label>
                                            <tag-input [formControlName]="'tags'"
                                                class="form-control form-control-style-2 form-control-lg"
                                                [addOnBlur]="true" [clearOnBlur]="true"
                                                [secondaryPlaceholder]="'Enter tags seperated by comma'"
                                                [displayBy]="'tag'"
                                                [ngClass]="{ 'is-invalid': submitAskQustion && askQuestionForm.controls.tags.errors }"
                                                [identifyBy]="'_id'" >
                                                <tag-input-dropdown [autocompleteObservable]="onInputTag" [displayBy]="'tag'"
                                                    [identifyBy]="'_id'">
                                                </tag-input-dropdown>
                                            </tag-input>
                                            <div *ngIf="submitAskQustion && askQuestionForm.controls.tags.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="askQuestionForm.controls.tags.errors.required">Tags are
                                                    required
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-12">
                                        <div class="mt-2 mt-md-3 form-group">
                                            <label>Question ?</label>
                                            <textarea class="form-control form-control-style-2 form-control-textarea"
                                                placeholder="Write your Question here." formControlName="yourQuestion"
                                                [ngClass]="{ 'is-invalid': submitAskQustion && askQuestionForm.controls.yourQuestion.errors }"></textarea>

                                            <div *ngIf="submitAskQustion && askQuestionForm.controls.yourQuestion.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="askQuestionForm.controls.yourQuestion.errors.required">
                                                    Question is
                                                    required
                                                </div>
                                            </div>
                                            <!-- [value]="askQuestionForm.yourQuestion | capitalization" -->
                                        </div>
                                    </div>
                                    <div class="col-md-12 mt-4">
                                        <div class="dragDropWrap" [ngClass]="{submitting : !(uploadArray.length == browseFileLen)}">
                                            <div class="spinner-border text-warning outer_loader_over" role="status"  *ngIf="!(uploadArray.length == browseFileLen)">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                            <div>
                                                <div class="dragDrop dragDropOr  d-none d-md-flex">
                                                    <figure class="dragDropIcon"><i class="fas fa-upload"></i></figure>
                                                    <div class="dragDropContent">
                                                        <input type="file" title=" "  class="file-upload-input "
                                                            accept="image/*,video/*,audio/*,doc/*,application/*,text/*"
                                                            (change)="onFileBrowse($event)" multiple #fileU1 />
                                                        <p><strong class="blue-text d-block">Drag & Drop your file here
                                                            </strong>Supports image, video, audio, pdf & text files
                                                        </p>

                                                        <div class="uploadedFiles">
                                                            <p *ngIf="uploadedMedia.length > 0">
                                                                <small *ngIf="uploadedMedia.length == 1"><b>{{selectFilelenghth}} file selected</b></small>
                                                                <small *ngIf="uploadedMedia.length > 1"><b>{{selectFilelenghth}}  files selected</b></small>
                                                            </p>
                                                            <p *ngIf="uploadedMedia.length == 0"><small><b>No File
                                                                        Selected</b></small></p>
                                                        </div>
                                                    </div>
                                                    <span class="divideOr"></span>
                                                </div>

                                                <div class="dragDropUpload btn-groups">
                                                    <button type="button" (click)="fileU1.click()"
                                                        class="custom-upload-btn btn btn-blue btn-shadow btn-icon-text">
                                                        <span class=""><i class="fas fa-download"></i> Browse
                                                            Files</span>
                                                    </button>
                                                </div>

                                            </div>

                                        </div>
                                        <div class="col-md-12 mt-4 uploadedItemWrap">
                                            <div class="uploadedItem">
                                                <div class=" mb-3" *ngFor="let media of uploadedMedia; let i = index">
                                                    <div class="uploadedItemTop">
                                                        <div class="uploadedItemDetail">
                                                            <p class="mb-1"><strong class="blue-text"> {{ media.FileName
                                                                    }}</strong></p>
                                                            <p class="uploadedItemStatus">
                                                                <small>{{media.FileProgress}}%
                                                                    Complete</small>
                                                                <small class="dark-green-text"
                                                                    *ngIf="media.FileProgress === 100">Uploaded
                                                                    Successful</small>
                                                            </p>
                                                        </div>
                                                        <div class="uploadedItemAction">
                                                        <a href="javascript:void(0);"
                                                        *ngIf="media.FileProgress === 100 && (uploadArray.length == browseFileLen)" 
                                                        (click)="openModelDeleteRes(delRes)" class="uploadedActionLink"><i
                                                                class="far fa-times-circle"></i></a>

                                                                    <!-- new ui delete question -->
     <ng-template #delRes let-c="close" let-d="dismiss">
        <div class="new_UIstyle">
            <div class="modal-header justify-content-between align-items-center">
                <h5 class="avenirBook modal-title text-danger mb-0">Delete Resourse</h5>
                <button type="button" class="close ml-0" aria-label="Close"
                    (click)="closeResPopup()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>Are you sure you want to delete Resource <strong class="text-danger">?</strong></p>
            </div>

            <div class="modal-footer">
                <div class="text-left">
                    <button type="submit" class="btn btn-primary btn-sm"
                        (click)="closeResPopup()">NO</button>
                </div>
                <div class="text-right">
                    <button type="submit" class="btn btn-primary btn-sm"
                    (click)="deleteResouce(media,i)">YES</button>
                </div>
            </div>
        </div>
    </ng-template>
                                                    </div>
                                                    </div>
                                                    <div class="progress uploadedProgress">
                                                        <div class="progress-bar uploadedProgressBlue"
                                                            role="progressbar"
                                                            [ngStyle]="{ 'width.%': media.FileProgress }"></div>
                                                    </div>
                                                    <!-- <p>
                                                        <small>{{ media.FileProgessSize }} of {{ media.FileSize
                                                            }}</small>
                                                    </p> -->
                                                </div>
                                            </div>
                                        </div>
                                        <div class="btn-groups mt-4 justify-content-end">
                                            <button type="button" class="btn btn-grey btn-sm"
                                                (click)="discardaskForm()" [disabled]="!(uploadArray.length == browseFileLen)">Discard</button>
                                            <button class="btn btn-primary btn-sm" [disabled]="!(uploadArray.length == browseFileLen)">Save</button>
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>






<!-- <div class="modal-header justify-content-between align-items-center d-none">
    <h4 class="modal-title">Ask A Question</h4> -->

<!-- <button type="button" class="close ml-0" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button> -->
<!-- </div>
<div class="modal-body d-none" [ngClass]="{submitting : loader}">
    <div class="spinner-border text-warning formLoader" role="status" *ngIf="loader">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="rounded embed-responsive-16by9">
        <form class="mw620 floatedInputForm" [ngClass]="{submitting : loader}" [formGroup]="askQuestionForm"
            (ngSubmit)="questionFormSubmit()">
            <dl>
                <dd>
                    <input type="text" class="form-control" placeholder=" " formControlName="subject" readonly>
                    <label class="flLabel">Category</label>
                </dd>
            </dl>
            <dl>
                <dd> 
                    <input type="text" class="form-control" placeholder=" " formControlName="coins">
                    <label class="flLabel">Coins</label>

                </dd>
            </dl>
            <dl>
                <dd>
                    <input type="text" class="form-control" placeholder=" "
                    [value]="askQuestionForm.get('yourQuestion').value | capitalization"
                    formControlName="yourQuestion">
                    <label class="flLabel">Your Question</label>

                </dd>
            </dl>

            <dl>
                <dd>
                    <div class="custom-file">
                        <input type="file" class="custom-file-input" id="choosePic" accept="image/*"
                            (change)="onPicSelected($event)">
                        <label class="custom-file-label" for="choosePic">{{picName}}</label>
                    </div>
                    <label class="flLabel">Choose Picture</label>
                </dd>
            </dl>

            <dl>
                <dd>
                    <div class="custom-file">
                        <input type="file" class="custom-file-input" id="chooseVideo" accept="video/*"
                            (change)="onVideoSelected($event)">
                        <label class="custom-file-label" for="chooseVideo">{{videoName}}</label>
                    </div>
                    <label class="flLabel">Choose Video</label>

                </dd>
            </dl>

            <div class="text-right">
                <button type="submit" class="btn btn-primary">Submit</button>
            </div>
        </form>
    </div>
</div> -->