
<div class="new_UIstyle">
    <div class="pageBodyWrap assesssment-wrap">
        <!-- Banner Section Start -->
        <section class="banner banner-inner assesssment-banner text-center"
            style="background-image: url('assets/images/banner/assessment-01.png');" *ngIf="getRole != 'student'">
            <div class="container">
                <div class="banner-content">
                    <h1 class="white-text mb-2">Assessment</h1>
                    <p class="avenirRoman white-text">Assessment - Create, Edit and Delete Assessment here</p>
    
                    <form class="assessment-banner-form">
                        <div class="input-with-icon input-with-icon-right">
                            <input type="text" class="form-control form-control-lg" name="search" (input)="searchAss($event)" id="search"
                                    placeholder="Search For Assessment" autofocus />
                            <button class="input-with-icon-btn"><i class="fa fa-search"></i></button>
                        </div>
                    </form>
                    <!-- <button class="btn btn-primary mt-2 mt-md-4" routerLink="/add-assessment-title">+ Create new
                        Assessment</button> -->
                        <button class="btn btn-primary mt-2 mt-md-4" routerLink="/create-assessment">+ Create New
                            Assessment</button>
                </div>
            </div>
        </section>
    
        <section class="section assessment-content-wrap" *ngIf="getRole != 'student'">
            <div class="container">
                <div [ngClass]="{submitting : loader}">
                    <div class="text-center" *ngIf="loader">
                        <div class="spinner-border text-warning formLoader" role="status" >
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
    
                    <div class="row justify-content-end mb-3" *ngIf="!isAssAvailable && !loader">
                        <div class="col-6 col-md-2">
                            <form class="sort-elenm">
                                <label><small>Sort by:</small></label>
                                <select class="form-control form-control-caret form-select" (change)="onSelectAssessment($event.target.value)">
                                    <option [value]="atype.value" *ngFor="let atype of assessmentTypes">{{atype.name}}</option>
                                </select>
                            </form>
                        </div>
                    </div>
    
                    <div class="assessment-content-list"  *ngIf="assessments.length > 0">
                        
                        <div class="questionBankItem assessment-content-item bg-grey" *ngFor="let assessment of assessments;let i=index;">
                            <div class="questionItemLeft">
                                <h6 class="avenirBook mb-3"><a href="javascript:void(0);"  (click)="gotoViewAssessment(assessment)">{{assessment.assessment_name |
                                        capitalization}}</a></h6>
    
                                <div class="assessment-content-options-list">
                                    <ul class="assessment-content-options-ul options-ul-style-label ul-list-100">
                                        <li>
                                            <span class="options-ul-label">Status:</span> 
                                            <div class="options-ul-content">
                                                <strong class="primary-text {{assessment.status | titlecase}}">{{assessment.status |
                                                    titlecase}}</strong>
                                            </div>
                                        </li>
                                        <li>
                                            <span class="options-ul-label">Last Edited: </span>
                                            <div class="options-ul-content">
                                                <strong class="primary-text">{{assessment.created_date |
                                                    date:'longDate':'UTC +0' }}</strong>
                                            </div>
                                         </li>                                                
                                        <li *ngIf="assessment?.courses.length > 0">
                                            <span class="options-ul-label"> Courses: </span>
                                            <div class="options-ul-content">
                                                <strong *ngFor="let a_course of assessment?.courses.slice(0, 2)" class="primary-text">
                                                    {{a_course.course | titlecase}}
                                                    <span class="colma">,</span> 
                                                </strong>
                                                <ng-container *ngIf="assessment?.courses.length > 2"><b [matTooltip]="courseListN[i]?.slice(2).toString()" 
                                                    matTooltipPosition="above"> + {{assessment?.courses.length - 2}} More</b>
                                                </ng-container>
                                          </div>  
                                        </li>
                                    </ul>
                                </div>
    
                                <div class="tags-badges">
                                    <span *ngFor="let a_tag of assessment?.tags" class="badge badge-green-lt">{{a_tag.tag |
                                        titlecase}}</span>
                                </div>
    
                            </div>
                            <div class="questionItemRight icons-group">
                                <a class="questionItemLink ItemEditLink" (click)="editassessment(assessment)"><i
                                        class="fas fa-pencil-alt"></i></a>
                                        <!-- (click)="deleteAssMent(assessment._id)" -->
                                <a class="questionItemLink ItemDeleteLink" (click)="openModelDeleteAss(delAss)"><i class="fas fa-trash-alt"></i></a>

                                <ng-template #delAss let-c="close" let-d="dismiss">
                                    <div class="new_UIstyle">
                                        <div class="modal-header justify-content-between align-items-center">
                                            <h5 class="avenirBook modal-title text-danger mb-0">Delete Assessment</h5>
                                            <button type="button" class="close ml-0" aria-label="Close" (click)="closeAssPopup()">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div class="modal-body">
                                            <p>Are you sure you want to delete Assessment <strong class="text-danger">?</strong></p>
                                        </div>
                                
                                        <div class="modal-footer">
                                            <div class="text-left">
                                                <button type="submit" class="btn btn-primary btn-sm" (click)="closeAssPopup()">NO</button>
                                            </div>
                                            <div class="text-right">
                                                <button type="submit" class="btn btn-primary btn-sm" (click)="deleteAssMent(assessment._id)">YES</button>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>

                            </div>
                            <a  class="questionItemLink ItemAnchorLink" (click)="gotoViewAssessment(assessment)"><i
                                    class="fa fa-angle-right"></i></a>
                        </div>
                    </div>
    
                    <div class="text-center mb-3" *ngIf="!loader && assessments.length == 0">
                        <strong>No Data Found</strong>
                    </div>
                      <!-- pagination -->
                    <div class="pagination-wrap mt-3 mt-md-4" *ngIf="numPages>1 && assessments.length > 0">
                        <a class="pagination-link pagination-prev-btn" [ngClass]="currentPage==1?'disabled':'enabled'">
                            <span (click)="first()"><i class="fas fa-angle-left"></i></span>
                        </a>
                        <a class="pagination-link" *ngFor="let page of pages;let i = index" [ngClass]="(currentPage)==page?'active':''">
                            <span (click)="onPage(page)"
                                >{{page}}</span>
                        </a>
                        <a  class="pagination-link pagination-prev-btn" [ngClass]="(currentPage==numPages)?'disabled':'enabled'">
                            <span (click)="next()"><i class="fas fa-angle-right"></i></span>
                        </a>
                    </div>
                </div>
    
            </div>
        </section>
    </div>
</div>

