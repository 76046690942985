import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileUploadModule } from 'primeng/fileupload';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TagInputModule } from 'ngx-chips';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { CommonAppModuleModule } from '../common-app-module/common-app-module.module';
import { AddQuestionComponent } from './add-question/add-question.component';
import { ImportQuestionComponent } from './import-question/import-question.component';
import { QuestionDetailComponent } from './question-detail/question-detail.component';
import { TinymceEditorComponent } from './tinymce-editor/tinymce-editor.component';
import { QuestionBankComponent } from './question-bank/question-bank.component';
import { SharedModule } from '../shared/modules/shared/shared.module';


const routes: Routes = [
    {
        path: '',
        children: [
            {
                path: '', 
                component:QuestionBankComponent 
            },
            {
                path: 'add-question',
                component: AddQuestionComponent
            },
            {
                path: 'import-question',
                component: ImportQuestionComponent
            },
            {
                path: 'question-detail',
                component: QuestionDetailComponent
            },

            // {
            //     path: '**',
            //     redirectTo: '/question-bank',
            //     pathMatch: 'full'
            // },
          
        ]
    }
];

@NgModule({
    imports: [
        
        CommonModule,
        SharedModule,
        RouterModule.forChild(routes),
        FormsModule,
        ReactiveFormsModule,
        FileUploadModule,
        NgSelectModule,
        NgbModule,
        TagInputModule,
        Ng2SearchPipeModule,
        CommonAppModuleModule,
        
       
        
        
    ],
    declarations: [
        AddQuestionComponent,
        ImportQuestionComponent,
        QuestionDetailComponent,
        // TinymceEditorComponent,
        QuestionBankComponent
    ],
    exports: [
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        FileUploadModule,
        NgbModule,
        NgSelectModule,
        Ng2SearchPipeModule,
        AddQuestionComponent,
        ImportQuestionComponent,
        QuestionDetailComponent,
      
    ],
})
export class QuestionBankModule { }
