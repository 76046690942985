import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { NotificationService } from './notification.service';

@Injectable({
    providedIn: 'root'
  })
  export class ShareService {
    searchedText = new Subject<any>();
    countNum = new BehaviorSubject(0);
    getCountItem = this.countNum.asObservable();
    
    runSocketStatus = new Subject<boolean>();
    IsSocketTrue = this.runSocketStatus.asObservable();


    constructor(private ns: NotificationService) {
    
    }
  getIndexArr(arr, value){
    let indexOfValue;
    indexOfValue = arr.filter(this.getValue).indexOf(value);
    
    return indexOfValue;
  }

  getValue(slug) {
    return slug;
  }

  
  getSearchText() {
    return this.searchedText;
  }


  setCountNumber(val) { 
    //console.log(val);
    localStorage.setItem('unread_msg', val);
    this.countNum.next(val);  
    //console.log(this.countNum);
    
  }  
  setUserStatus(status,isNotify:boolean = false){
     this.ns.userActiveStatus(status).subscribe((res)=>{
      this.runSocketStatus.next(true);
      
      if(isNotify == true){
        this.ns.showNotification('success', res.message)   
      }
    })   
  }
}
