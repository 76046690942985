<div class="new_UIstyle">
  <!-- <div class="topBarSupport">
    <p>Edu-collab is best supported in Google Chrome and Firefox Latest Version</p>
  </div> -->
  <header class="mainHeader header-shadow" Id="mainHeader" #headerSection>
    <nav class="navbar navbar-expand-xl">
      <div class="container">
        <div class="nav-elem headerLeft navbar-site-logo">
          <a class="navbar-brand" [routerLink]="['/']"><img src="../../assets/images/logo.png"
              alt="logo.png" /></a>
          <button class="navbar-toggler " (click)="mainMenuClickEvent()" type="button" data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
            aria-label="Toggle navigation">
            <span class="navbarTogglerIcon"></span>
            <span class="navbarTogglerIcon"></span>
            <span class="navbarTogglerIcon"></span>
          </button>
        </div>


        <div class="nav-elem headerCenter collapse navbar-collapse" id="navbarSupportedContent"
          [ngClass]="sidebarMainMenu ? 'sidemenu-active' : 'sidemenu-deActive'">
          <button class="sidebar-close-btn d-block d-xl-none" (click)="mainMenuClickEvent()"><i
              class="fas fa-times"></i></button>
          <div class="nav-elem-mnu-list" *ngIf="loginFLagValue">
            <ul class="navbar-nav me-2">
              <li ngbDropdown class="dropdown nav-item" *ngIf="role == 'student'">
                <a class="category-link nav-link" ngbDropdownToggle id="navbarDropdown" role="button"
                  data-bs-toggle="dropdown" aria-expanded="false">Categories</a>
                <div ngbDropdownMenu class="dropdown-menu  dropdown-menu-regular dropdown-menu-start mt-xl-4 mt-0">
                  <ul class="dropdown-nav" >
                    <li ngbDropdown class="dropdown" [ngClass]="catItem.subChild.length == 0 ? '' : 'dropdown'"
                      *ngFor="let catItem of sotedCategory">
                      
                      <a ngbDropdownToggle data-bs-toggle="dropdown" class="dropdown_item"
                        [ngClass]="catItem.subChild.length == 0 ? 'dropdown-toggle-none dropdown-item' : 'dropdown-toggle'" 
                        (click)="onTopChangeCategory(catItem.cat_id)">{{catItem.parentName }}</a>
                      <div ngbDropdownMenu class="dropdown-menu dropdown-menu-regular dropdown-menu-start"
                        *ngIf="catItem.subChild.length > 0">
                        <ul>
                          <li ngbDropdown class="dropdown" *ngFor="let catSubItem of catItem.subChild"
                            [ngClass]="catSubItem.grandChild.length == 0 ? '' : ''">
                            
                            <a  ngbDropdownToggle data-bs-toggle="dropdown"  class="dropdown_item"
                              [ngClass]="catSubItem.grandChild.length == 0 ? 'dropdown-toggle-none dropdown-item' : 'dropdown-toggle'" 
                              (click)="onTopChangeCategory(catSubItem.cat_id)">{{catSubItem.subChildName }}</a>
                            <div ngbDropdownMenu class="dropdown-menu dropdown-menu-regular dropdown-menu-start"
                               *ngIf="catSubItem.grandChild.length > 0">
                              
                              <ul>
                                <li *ngFor="let catGrandItem of catSubItem.grandChild">                                  
                                  <a class="dropdown-item" (click)="onTopChangeCategory(catGrandItem.cat_id)">{{ catGrandItem.grandChildName }}</a>
                                </li>
                              </ul>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="nav-item">
                <div class="category-search-block">
                  <form class="input-with-icon input-with-icon-right category-search-form">
                    <input type="search" (input)="onSearchTextChanged($event)" [(ngModel)]="search_text"
                      name="search_text" autocomplete="off" placeholder="What are you looking for ?"
                      class="form-control form-control-md" #searchInput />
                    <button class="input-with-icon-btn category-search-btn"><i class="fas fa-search"></i></button>
                  </form>
                </div>
              </li>
            </ul>
            <ul class="navbar-nav">

              <li ngbDropdown class="nav-item" *ngIf="role != 'student'">
                <a class="nav-link dropdown-toggle" ngbDropdownToggle *ngIf="role == 'teacher'">My Courses</a>
                <a class="nav-link dropdown-toggle" ngbDropdownToggle *ngIf="role != 'student' && role != 'teacher'">Courses</a>
                <div class="dropdown-menu" ngbDropdownMenu>
                  <a class="dropdown-item" routerLink="/draft-courses" routerLinkActive="active">Draft Courses</a>
                  <a class="dropdown-item" routerLink="/published-courses" routerLinkActive="active">Published Courses</a>
                  <a class="dropdown-item" routerLink="/courses/ec-course-board" routerLinkActive="active" 
                  *ngIf="role != 'student' && role != 'teacher'">De-activated Courses</a>
                  <!-- <a *ngIf="!isProduction" class="dropdown-item" routerLink="/courses/add" routerLinkActive="active">Add Course</a> -->
                </div>
              </li>
              <li class="nav-item">
                <a class="nav-link" (click)="navigate_to('/passion-projects')">The Mosaic</a>
              </li>

              <li ngbDropdown class="nav-item dropdown" routerLinkActive="active"
                *ngIf="findIndexOf(permissions, 'user_management') > -1">
                <a class="nav-link dropdown-toggle" id="questDropdown" ngbDropdownToggle>
                  Management
                </a>
                <div class="dropdown-menu" ngbDropdownMenu aria-labelledby="questDropdown">
                  <a class="dropdown-item" routerLink="/manage-user" routerLinkActive="active">Manage Users</a>
                  <a class="dropdown-item" routerLink="/manage-role" routerLinkActive="active">Manage Roles</a>
                  <a class="dropdown-item" routerLink="/manage-categories" routerLinkActive="active">Manage
                    Categories</a>
                </div>
              </li>

              <li ngbDropdown class="nav-item dropdown" routerLinkActive="active"
                *ngIf="findIndexOf(permissions, 'question_bank') > -1">
                <a class="nav-link dropdown-toggle" id="questDropdown" ngbDropdownToggle>
                  Resources
                </a>
                <div class="dropdown-menu" ngbDropdownMenu aria-labelledby="questDropdown">
                  <a class="dropdown-item" routerLink="/question-bank" routerLinkActive="active">Question Bank</a>
                  <a class="dropdown-item" routerLink="/retrieve-assessment" routerLinkActive="active">Assessment</a>
                  <a class="dropdown-item" routerLink="/courses/search-coursework">Course Work</a>
                  <a class="dropdown-item" routerLink="/resource-bank">Resource Bank</a>
                </div>
              </li>

              <li class="nav-item"  *ngIf="findIndexOf(permissions, 'student_questions') > -1">
                <a class="nav-link"  routerLink="/Student-Questions" routerLinkActive="active">Student Questions</a>
              </li>

              

              <li ngbDropdown class="nav-item dropdown student-dropdown" *ngIf="role == 'student'">
                <a class="nav-link" routerLinkActive="active" ngbDropdownToggle id="navbarDropdown" role="button"
                  data-bs-toggle="dropdown" aria-expanded="false">Student Corner</a>
                <div ngbDropdownMenu class="dropdown-menu dropdown-menu-student dropdown-menu-end"
                  aria-labelledby="navbarDropdown">
                  <a class="dropdown-item" (click)="navigate_to('/all-questions')">All Questions</a>
                  <a class="dropdown-item" (click)="navigate_to('/recommended-questions')">Recommended Questions</a>
                  <a class="dropdown-item" (click)="navigate_to('/My-Questions')">My Questions</a>
                  <a class="dropdown-item" (click)="navigate_to('/my-answers')">My Answers</a>
               </div>
           </li>
           <li ngbDropdown class="nav-item dropdown" routerLinkActive="active" >
            <a class="nav-link dropdown-toggle" id="moreDropDown" ngbDropdownToggle>More</a>
            <div class="dropdown-menu dropdown-menu-more dropdown-menu-end" ngbDropdownMenu>
              <!-- <a class="dropdown-item" routerLink="/addUser" routerLinkActive="active" *ngIf="role == 'super_admin'">Add User</a>
              <a class="dropdown-item" routerLink="/bulk-upload" routerLinkActive="active" *ngIf="role == 'super_admin'">Bulk Upload</a> -->
              <a class="dropdown-item" routerLink="/report" routerLinkActive="active">Report</a>
              <a class="dropdown-item" routerLink="/report-list" routerLinkActive="active" *ngIf="role == 'super_admin'">Report-List</a>
              <a class="dropdown-item" routerLink="/search_organisation" routerLinkActive="active" *ngIf="role == 'super_admin'">Organization</a>
            </div>
            </li>
            
          </ul>
        </div>
      </div>
      <div class="backlog-sidebar" [ngClass]="sidebarMainMenu ? 'backlog-active' : 'backlog-deActive'"></div>
      <div class="nav-elem headerRight">
        <a *ngIf="!loginFLagValue" routerLink="/login" routerLinkActive="active"
          class="btn btn-primary btn-w-120 login-btn">Login</a>
        <div class="header-icon-list" *ngIf="loginFLagValue">
          <ul>
            <li *ngIf="role == 'student'"><a class="header-icon-link" [routerLink]="['/schedules']"><i class="fas fa-calendar-week"></i></a></li>
            <li><a class="header-icon-link" routerLink="/guftagu" (click)="resetMessageCount()"><i class="far fa-comment-dots"></i> 
              <!-- <span class="badge badge-round-vh-center badge-primary" *ngIf="countNumber>0">{{countNumber>0?countNumber:0}}</span> -->
            </a></li>
            <!-- <li ngbDropdown class="dropdown notification-dropdown toggle-none">
              <a href="javascript:void(0);" (click)="getLastestNotify();" class="header-icon-link" ngbDropdownToggle id="navbarDropdown" role="button"
                data-bs-toggle="dropdown" aria-expanded="false"><i class="fas fa-bell"></i> <span
                  class="badge badge-round-vh-center badge-primary" *ngIf="notifyCount > 0">{{notifyCount > 99 ? '99+':notifyCount }}</span></a>
              <div ngbDropdownMenu class="dropdown-menu dropdown-menu-notification dropdown-menu-end"
                aria-labelledby="navbarDropdown">
                <div class="dropdown-title d-flex align-items-center justify-content-between">
                  <h6 class="primary-text mb-0 avenirBook">Notifications</h6>
                  <a [routerLink]="['/notifications']" class="blue-text dropdown-item no_style_dropdown_item"  *ngIf="currentUrl !== '/notifications'">See all</a>
                </div>
                <hr />
                <div class="drop-items-wrap notification-items-wrap ">
                  <div class="text-center" *ngIf="listLoader">
                    <div class="spinner-border text-warning formLoader" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <div class="mb-1" *ngFor="let notification of notificationList; index as i;">
                  <div class="drop-item-list notification-items-list notification-items-unread" [ngClass]="notification.read_status == false ? 'notification-items-unread':''" >

                    <ng-container  *ngIf="notification.type == 'chat_request'">
                      <div class="drop-item-list-link notification-items-list-link">
                        <div class="drop-item-list-left">
                          <figure class="drop-item-list-figure">
                            <img *ngIf="notification.image !== ''" [src]="notification.image" alt="Avtar Image" />
                            <img *ngIf="notification.image == '' " [src]="defaultAvtar" alt="Avtar Image" />
                          </figure> 
                          <div class="drop-item-list-content">
                            <p>You received a friend request from  <a href="javascript:void(0);" class="dropdown-item" (click)="redirectTo(notification)"><b>{{notification.content.sender_name}}</b></a></p>
                            <p><small>{{notification.created_at | date:'short'}}</small></p>
                          </div>
                        </div>
                      </div>
                    </ng-container>

                    <ng-container  *ngIf="notification.type == 'chat_invite'">
                      <div class="drop-item-list-link notification-items-list-link">
                        <div class="drop-item-list-left">
                          <figure class="drop-item-list-figure">
                            <img *ngIf="notification.image !== ''" [src]="notification.image" alt="Avtar Image" />
                            <img *ngIf="notification.image == '' " [src]="defaultAvtar" alt="Avtar Image" />
                          </figure> 
                          <div class="drop-item-list-content">
                            <p>{{notification.receiver_name}} <span *ngIf="notification.subtype == 'reject'">rejected</span> <span *ngIf="notification.subtype == 'accept'">accepted</span> your request.</p>
                            <p><small>{{notification.created_at | date:'short'}}</small></p>
                          </div>
                        </div>
                      </div>
                    </ng-container>

                      <ng-container  *ngIf="notification.type == 'TA_assignment'">
                        <div class="drop-item-list-link notification-items-list-link">
                          <div class="drop-item-list-left">
                            <figure class="drop-item-list-figure">
                              <img *ngIf="notification.image !== ''" [src]="notification.image" alt="Avtar Image" />
                              <img *ngIf="notification.image == '' "[src]="defaultAvtar" alt="Avtar Image" />
                            </figure>
                            <div class="drop-item-list-content">
                              <p>You received TA assignment notification from <a href="javascript:void(0);" class="dropdown-item" (click)="redirectTo(notification)"><strong>{{notification.content.subject}}</strong></a> course.</p>
                              <p><small>{{notification.created_at | date:'short'}}</small></p>
                            </div>
                          </div>
                        </div>
                      </ng-container>

                      <ng-container  *ngIf="notification.type == 'topic'">
                        <div class="drop-item-list-link notification-items-list-link">
                          <div class="drop-item-list-left">
                            <figure class="drop-item-list-figure">
                              <img *ngIf="notification.image !== ''" [src]="notification.image" alt="Avtar Image" />
                              <img *ngIf="notification.image == '' "[src]="defaultAvtar" alt="Avtar Image" />
                            </figure>
                            <div class="drop-item-list-content">
                              <p>You received topic notification for <a href="javascript:void(0);" class="dropdown-item" (click)="redirectTo(notification)"><strong>{{notification.content.topic}}</strong></a> from course  <a href="javascript:void(0);" class="dropdown-item" (click)="redirectTo(notification)"><strong>{{notification.content.course_name}}</strong></a>.</p>
                              <p><small>{{notification.created_at | date:'short'}}</small></p>
                            </div>
                          </div>
                        </div>
                      </ng-container>

                      <ng-container  *ngIf="notification.type == 'question'">
                        <div class="drop-item-list-link notification-items-list-link">
                          <div class="drop-item-list-left">
                            <figure class="drop-item-list-figure">
                              <img *ngIf="notification.image !== ''" [src]="notification.image" alt="Avtar Image" />
                              <img *ngIf="notification.image == '' "[src]="defaultAvtar" alt="Avtar Image" />
                            </figure>
                            <div class="drop-item-list-content">
                              <p>You received question notification for <a href="javascript:void(0);" class="dropdown-item" (click)="redirectTo(notification)"><strong>{{notification.content.question}}</strong></a>.</p>
                              <p><small>{{notification.created_at | date:'short'}}</small></p>
                            </div>
                          </div>
                        </div>
                      </ng-container>

                      <ng-container  *ngIf="notification.type == 'course_coursework'">
                        <div class="drop-item-list-link notification-items-list-link">
                          <div class="drop-item-list-left">
                            <figure class="drop-item-list-figure">
                              <img *ngIf="notification.image !== ''" [src]="notification.image" alt="Avtar Image" />
                              <img *ngIf="notification.image == '' "[src]="defaultAvtar" alt="Avtar Image" />
                            </figure>
                            <div class="drop-item-list-content">
                              <p>You received coursework notification for  <a href="javascript:void(0);" class="dropdown-item" (click)="redirectTo(notification)"><strong>{{notification.content.course_name}}</strong></a>  course.</p>
                              <p><small>{{notification.created_at | date:'short'}}</small></p>
                            </div>
                          </div>
                        </div>
                      </ng-container>

                      <ng-container  *ngIf="notification.type == 'coursework'">
                        <div class="drop-item-list-link notification-items-list-link">
                          <div class="drop-item-list-left">
                            <figure class="drop-item-list-figure">
                              <img *ngIf="notification.image !== ''" [src]="notification.image" alt="Avtar Image" />
                              <img *ngIf="notification.image == '' "[src]="defaultAvtar" alt="Avtar Image" />
                            </figure> 
                            <div class="drop-item-list-content">
                              <p>You have only one day left for submission for <strong>{{notification.content.title}}</strong> in <strong>{{notification.content.course_name}}</strong>  course.</p>
                              <p><small>{{notification.created_at | date:'short'}}</small></p>
                            </div>
                          </div>
                        </div>
                      </ng-container>


                      <ng-container  *ngIf="notification.type == 'coursework_invite'">
                        <div class="drop-item-list-link notification-items-list-link">
                          <div class="drop-item-list-left">
                            <figure class="drop-item-list-figure">
                              <img *ngIf="notification.image !== ''" [src]="notification.image" alt="Avtar Image" />
                              <img *ngIf="notification.image == '' "[src]="defaultAvtar" alt="Avtar Image" />
                            </figure>
                            <div class="drop-item-list-content">
                              <p>You received coursework invite notification for <a href="javascript:void(0);" class="dropdown-item" (click)="redirectTo(notification)"><strong>{{notification.content.team_name}}</strong></a> team  from <a href="javascript:void(0);" class="dropdown-item" (click)="redirectTo(notification)"><strong>{{notification.content.course_name}} </strong></a> course.</p>
                              <p><small>{{notification.created_at | date:'short'}}</small></p>
                            </div>
                          </div>
                        </div>
                      </ng-container>

                      <ng-container  *ngIf="notification.type == 'course_assessment'">
                        <div class="drop-item-list-link notification-items-list-link">
                          <div class="drop-item-list-left">
                            <figure class="drop-item-list-figure">
                              <img *ngIf="notification.image !== ''" [src]="notification.image" alt="Avtar Image" />
                              <img *ngIf="notification.image == '' "[src]="defaultAvtar" alt="Avtar Image" />
                            </figure>
                            <div class="drop-item-list-content">
                              <p>You received assessment notification for  <a href="javascript:void(0);" class="dropdown-item" (click)="redirectTo(notification)"><strong>{{notification.content.course_name}}</strong></a>  course.</p>
                              <p><small>{{notification.created_at | date:'short'}}</small></p>
                            </div>
                          </div>
                        </div>
                      </ng-container>

                      <ng-container  *ngIf="notification.type == 'assessment'">
                        <div class="drop-item-list-link notification-items-list-link">
                          <div class="drop-item-list-left">
                            <figure class="drop-item-list-figure">
                              <img *ngIf="notification.image !== ''" [src]="notification.image" alt="Avtar Image" />
                              <img *ngIf="notification.image == '' "[src]="defaultAvtar" alt="Avtar Image" />
                            </figure>
                            <div class="drop-item-list-content">
                              <p>You received assessment notification for  <a href="javascript:void(0);" class="dropdown-item" (click)="redirectTo(notification)"><strong>{{notification.content.title}}</strong></a>  from <a href="javascript:void(0);" class="dropdown-item" (click)="redirectTo(notification)"><strong>{{notification.content.course_name}}</strong></a>  course.</p>
                              <p><small>{{notification.created_at | date:'short'}}</small></p>
                            </div>
                          </div>
                        </div>
                      </ng-container>


                      <ng-container  *ngIf="notification.type == 'announcement'">
                        <div class="drop-item-list-link notification-items-list-link">
                          <div class="drop-item-list-left">
                            <figure class="drop-item-list-figure">
                              <img *ngIf="notification.image !== ''" [src]="notification.image" alt="Avtar Image" />
                              <img *ngIf="notification.image == '' "[src]="defaultAvtar" alt="Avtar Image" />
                            </figure>
                            <div class="drop-item-list-content">
                              <p><a href="javascript:void(0);" class="dropdown-item" (click)="redirectTo(notification)"><strong>{{notification.content.announcement}}</strong></a> announcement has been published in <a href="javascript:void(0);" class="dropdown-item" (click)="redirectTo(notification)"><strong>{{notification.content.course_name}}</strong></a>  course.</p>
                              <p><small>{{notification.created_at | date:'short'}}</small></p>
                            </div>
                          </div>
                        </div>
                      </ng-container>


                      <ng-container  *ngIf="notification.type == 'mosaic_team'">
                        <div class="drop-item-list-link notification-items-list-link">
                          <div class="drop-item-list-left">
                            <figure class="drop-item-list-figure">
                              <img *ngIf="notification.image !== ''" [src]="notification.image" alt="Avtar Image" />
                              <img *ngIf="notification.image == '' "[src]="defaultAvtar" alt="Avtar Image" />
                            </figure>
                            <div class="drop-item-list-content">
                              <p> You received notification from mosaic team <a href="javascript:void(0);" class="dropdown-item" (click)="redirectTo(notification)"><strong>{{notification.content.team_name}}</strong></a>.</p>
                              <p><small>{{notification.created_at | date:'short'}}</small></p>
                            </div>
                          </div>
                        </div>
                      </ng-container>


                      <ng-container  *ngIf="notification.type == 'changes_by_admin'">
                        <div class="drop-item-list-link notification-items-list-link">
                          <div class="drop-item-list-left">
                            <figure class="drop-item-list-figure">
                              <img *ngIf="notification.image !== ''" [src]="notification.image" alt="Avtar Image" />
                              <img *ngIf="notification.image == '' "[src]="defaultAvtar" alt="Avtar Image" />
                            </figure>
                            <div class="drop-item-list-content">
                              <p>You received notification for some changes has be done by admin.</p>
                              <p><small>{{notification.created_at | date:'short'}}</small></p>
                            </div>
                          </div>
                        </div>
                      </ng-container>

                      <ng-container  *ngIf="notification.type == 'calendar_reminders_or_tasks'">
                        <div class="drop-item-list-link notification-items-list-link">
                          <div class="drop-item-list-left">
                            <figure class="drop-item-list-figure">
                              <img *ngIf="notification.image !== ''" [src]="notification.image" alt="Avtar Image" />
                              <img *ngIf="notification.image == '' "[src]="defaultAvtar" alt="Avtar Image" />
                            </figure>
                            <div class="drop-item-list-content">
                              <p>You received notification from calendar.</p>
                              <p><small>{{notification.created_at | date:'short'}}</small></p>
                            </div>
                          </div>
                        </div>
                      </ng-container>

                     


                  </div>
                </div>
                
                  <div class="text-center" *ngIf="notificationList.length === 0">
                    <p><b>There is no notification available</b></p>
                  </div>

                </div>
              </div>
            </li> -->
            <li ngbDropdown class="dropdown account-profile-blk" >
              <a class="profile-fig header-profile-btn bg-primary" ngbDropdownToggle id="navbarDropdown" role="button"
                data-bs-toggle="dropdown" aria-expanded="false" (click)="setCoinEvent()">
                <img  *ngIf="userLogo.length > 0"  class="header-profile-img" [src]="userLogo" alt="" />
                <figcaption class="d-flex align-items-center justify-content-center h-100" 
                *ngIf="userLogo.length == 0">
                <h5 class="m-0 avenirBook primary-text">{{userFirstName[0] | uppercase}}</h5></figcaption>
              </a>
              <!-- <a class="profile-fig header-profile-btn" ngbDropdownToggle id="navbarDropdown" role="button"
                data-bs-toggle="dropdown" aria-expanded="false" *ngIf="userLogo.length == 0">
                <span class="text-center align-self-center">{{userName[0] | uppercase}}</span>
              </a>  -->

              <div ngbDropdownMenu class="dropdown-menu dropdown-menu-user-profile dropdown-menu-end">
                <div class="drop-items-wrap user-profile-items-wrap user_right">
                  <div class="drop-item-list user-profile-items-list">
                    <div class="w-100 drop-item-list-left align-items-center">
                      <figure  class="drop-item-list-figure bg-primary">
                        <img *ngIf="userLogo.length > 0" [src]="userLogo" alt="" />
                        <figcaption class="d-flex align-items-center justify-content-center h-100" 
                        *ngIf="userLogo.length == 0">
                        <h4 class="m-0 avenirBook primary-text">{{userFirstName[0] | uppercase}}</h4></figcaption>
                      </figure>
                      <div class="drop-item-list-content">
                        <h6 class="primary-text mb-1 avenirBlack">{{userFirstName}} {{userLastName}}</h6>
                        <p><small>{{Email_ID}}</small></p>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <ul class="dropdown-nav">
                    <li><a class="dropdown-item" routerLink="/courses">My Learning</a></li>
                    <li><a class="dropdown-item" routerLink="/home">Dashboard</a></li>
                    <li *ngIf="findIndexOf(permissions, 'show_coins') > -1">
                      <div class="coins-wrap"><strong class="black-text">Edu Coins</strong> <span class="coins-count"><strong
                            class="primary-text">{{allCoins}} </strong><img
                            src="../../assets/images/apps-icon/coins.png" alt="coins.png" /></span></div>
                    </li>
                  </ul>
                  <hr />
                  <ul class="dropdown-nav">
                    <!-- <li><a routerLink="/notify/">Notifications</a></li> -->
                    <li><a class="dropdown-item" (click)="navigate_to('/guftagu')">Guftagu</a></li>
                  </ul>
                  <hr />
                  <ul class="dropdown-nav">
                    <li><a class="dropdown-item" style="cursor: pointer;" routerLink="/public-profile">Public Profile</a></li>
                    <li><a class="dropdown-item" style="cursor: pointer;" routerLink="/zoom-integration">Apps</a></li>
                    <!-- <li><a routerLink="/my-account">Edit Profile</a></li>
                    <li><a routerLink="/my-account">Account Settings</a></li> -->
                  </ul>
                  <hr />
                  <!-- <ul class="dropdown-nav">
                    <li><a routerLink="/my-account">Privacy Settings</a></li>
                    <li><a routerLink="/my-account">Notification Settings</a></li>
                    <li>
                      <div class="lng-blk d-flex align-items-center justify-content-between primary-text">
                        <a >Language</a> 
                        <strong class="lang">English <i class="fas fa-globe"></i></strong></div>
                    </li>
                    <li><a routerLink="/">Help</a></li>
                  </ul>
                  <hr /> -->
                  <ul class="dropdown-nav">
                    <li><a class="dropdown-item" (click)="signout()">Logout</a></li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
</div>
</nav>
</header>
</div>

