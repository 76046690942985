import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { CourseService } from 'src/app/services/course.service';
import { NotificationService } from 'src/app/services/notification.service';
import { finalize } from 'rxjs/operators';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators, AbstractControl, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-attendance-marking',
  templateUrl: './attendance-marking.component.html',
  styleUrls: ['./attendance-marking.component.scss']
})
export class AttendanceMarkingComponent implements OnInit {
  courseId: any;
  sessionId: any;
  courseTitle: any;
  sessionTitle: any;
  progress: number = 0;
  uploadRes: any = {};
  public markAttendanceForm: FormGroup;
  subscribersInfo: any = [];
  mark_subscribers: any;
  zoom_participants: any;
  fileName: any;
  markAttendanceSubscription: Subscription;
  participantsSubscription: Subscription;
  loader: boolean;
  sessionParticipants: any = [];
  showParticipant: boolean = false;
  showGenerate: boolean;
  showFuzzymatchedSubscriber: boolean = false;

  constructor(private courseService: CourseService,
    private _notificationService: NotificationService,
    private fb: FormBuilder,
    private renderer: Renderer2,
    private location: Location,
    private activatedRoute: ActivatedRoute) {
    this.renderer.addClass(document.body, 'attendanceMark');
  }


  ngOnInit() {
    this.courseId = this.activatedRoute.snapshot.queryParamMap.get('courseId');
    this.sessionId = this.activatedRoute.snapshot.queryParamMap.get('sessionId');
    this.courseTitle = this.activatedRoute.snapshot.queryParamMap.get('course_title');
    this.sessionTitle = this.activatedRoute.snapshot.queryParamMap.get('session_title');
    this.viewAttendance();
  }

  /**
   * @description : upload .csv type file 
   */
  onuploadFile(event) {
    let file;
    this.fileName = '';
    if (event.target.files) {
      file = event.target.files[0];
      this.fileName = file.name;
    }

    let module_name: string = 'attendance';
    if (file) {
      this._notificationService.upload_resource(module_name, file).pipe(
        finalize(() => {
        })).subscribe(
          (response: HttpEvent<any>) => {
            switch (response.type) {
              case HttpEventType.Sent:
                break;
              case HttpEventType.ResponseHeader:
                break;
              case HttpEventType.UploadProgress:
                this.progress = Math.round(response.loaded / response.total * 100);
                break;
              case HttpEventType.Response:
                this.uploadRes = response.body.resource_info[0];
                this.showGenerate = true;
                this._notificationService.showNotification('success', response.body.message);
                setTimeout(() => {
                  this.progress = 0;
                }, 1500);
            }

          },
          (err) => {
            this.progress = 0;
            this._notificationService.showNotification('info', 'Please add again not able to save your file');
          })
    }
  }

  /**
   * @description: do fuzzy match with uploaded .csv file
   */
  onFuzzyMatch(type) {
    this.showParticipant = false;
    
    this.courseService.matchAttendance(type,this.courseId, this.sessionId, this.uploadRes.resource_id?this.uploadRes.resource_id:'', this.uploadRes.path?this.uploadRes.path:'').pipe(
      finalize(() => {

      })).subscribe(
        (res) => {
          this.initializeForm();
          this.subscribersInfo = res.response.subscribers_info;
          this.zoom_participants = res.response.zoom_participants;
          this.showFuzzymatchedSubscriber = true;
          this.populateForm();
          this.showGenerate = false;
          this.fileName = '';
        },
        (err) => {
          this.fileName = '';
          this.showGenerate = false;
          this._notificationService.showNotification('error', err.message);
        })
  }

  initializeForm(): void {
    this.markAttendanceForm = this.fb.group({
      subscribers_info: this.fb.array([]),
    });
  }
  markSubscribers() {
    return this.fb.group({
      _id: [''],
      username: [''],
      organisation: [''],
      profile_pic: [''],
      attende: [false],
      participant: ['']
    });
  }

  populateForm() {
    const mark_subs = [];
    this.subscribersInfo.forEach((val: any, i) => {
      this.mark_subscribers = <FormArray>this.markAttendanceForm.get('subscribers_info');
      this.mark_subscribers.push(this.markSubscribers());
      mark_subs.push({
        _id: val._id,
        username: val.username,
        organisation: val.organisation,
        profile_pic: val.profile_pic,
        attende: (val.matched_user && val.matched_score > 70) ? true : false,
        participant: this.getMatchedParticipant(val.matched_user)
      })
    })
    this.markAttendanceForm.patchValue({
      subscribers_info: mark_subs
    });
  }

  /**
   * @description: submit form
   */
  markAttendance() {
    const attendeParticipants = this.markAttendanceForm.value.subscribers_info.filter(ele => ele.attende == true).map(elem => (
      {
        _id: elem._id,
        matched_user: elem.participant
      }
    ));

    let saveObj: any = {};
    saveObj.course_id = this.courseId;
    saveObj.session_id = this.sessionId;
    saveObj.participants = attendeParticipants;
    this.markAttendanceSubscription = this.courseService.markSessionAttendance(saveObj).pipe(
      finalize(() => {

      })).subscribe(
        (res) => {
          this._notificationService.showNotification('success', res.message);
          this.showFuzzymatchedSubscriber = false;
          this.viewAttendance();
        },
        (err) => {
          this._notificationService.showNotification('error', err.message);
        })

  }

  getMatchedParticipant(user) {
    let matcheduser = this.zoom_participants.filter(ele => ele == user);
    if (matcheduser) {
      return matcheduser[0]
    } else {
      return 'None'
    }
  }

  /**
   * @description: List of zoom participants 
   */
  viewAttendance() {
    this.loader = true;
    this.showParticipant = false;
    this.participantsSubscription = this.courseService.viewSessionAttendance(this.courseId, this.sessionId).pipe(
      finalize(() => {
        this.loader = false;
      })).subscribe(
        (res) => {
          if (res.response.attendance.length == 0 && res.response.live_attendance) {
            this.onFuzzyMatch('live_attendance');
            return;
          }
          this.sessionParticipants = res.response.attendance;
          this.showParticipant = true;
        },
        (err) => {
          this.loader = false;
          this._notificationService.showNotification('error', err.message);
        })
  }

  redirect(): void {
    this.location.back();
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'attendanceMark');
    if (this.markAttendanceSubscription) this.markAttendanceSubscription.unsubscribe();
    if (this.participantsSubscription) this.participantsSubscription.unsubscribe();
  }

}
