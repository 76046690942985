<div class="pageBody subPage">
  <div class="calendarTemp pt-4 pt-xl-0">
      <div class="d-xl-flex justify-content-between text-center">
        <div class="pb-4 pb-xl-0">
          <div class="btn-group">
            <div
              class="btn btn-primary"
              mwlCalendarPreviousView
              [view]="view"
              [(viewDate)]="viewDate"
              (viewDateChange)="closeOpenMonthViewDay($event)"
            >
              Previous
            </div>
            <div
              class="btn btn-outline-secondary"
              mwlCalendarToday
              [(viewDate)]="viewDate"
            >
              Today
            </div>
            <div
              class="btn btn-primary"
              mwlCalendarNextView
              [view]="view"
              [(viewDate)]="viewDate"
              (viewDateChange)="closeOpenMonthViewDay($event)"
            >
              Next
            </div>
          </div>
        </div>
        <div class="pb-4 pb-xl-0">
          <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
        </div>
        <div class="pb-0">
          <div class="btn-group">
            <div
              class="btn btn-primary"
              (click)="setView(CalendarView.Month)"
              [class.active]="view === CalendarView.Month"
            >
              Month
            </div>
            <div
              class="btn btn-primary"
              (click)="setView(CalendarView.Week)"
              [class.active]="view === CalendarView.Week"
            >
              Week
            </div>
            <div
              class="btn btn-primary"
              (click)="setView(CalendarView.Day)"
              [class.active]="view === CalendarView.Day"
            >
              Day
            </div>
          </div>
        </div>
      </div>
      
      <div class="pt-4" [ngSwitch]="view">
        <mwl-calendar-month-view
          *ngSwitchCase="CalendarView.Month"
          [viewDate]="viewDate"
          [events]="events"
          [refresh]="refresh"
          [activeDayIsOpen]="activeDayIsOpen"
          (dayClicked)="dayClicked($event.day)"
          (eventClicked)="handleEvent('Clicked', $event.event)"
          (eventTimesChanged)="eventTimesChanged($event)"
        >
        </mwl-calendar-month-view>
        <mwl-calendar-week-view
          *ngSwitchCase="CalendarView.Week"
          [viewDate]="viewDate"
          [events]="events"
          [refresh]="refresh"
          (eventClicked)="handleEvent('Clicked', $event.event)"
          (eventTimesChanged)="eventTimesChanged($event)"
        >
        </mwl-calendar-week-view>
        <mwl-calendar-day-view
          *ngSwitchCase="CalendarView.Day"
          [viewDate]="viewDate"
          [events]="events"
          [refresh]="refresh"
          (eventClicked)="handleEvent('Clicked', $event.event)"
          (eventTimesChanged)="eventTimesChanged($event)"
        >
        </mwl-calendar-day-view>
      </div>  
      
    </div>
  </div>

  <ng-template #modalContent let-close="close">
    <div class="modal-header">
      <h5 class="modal-title st">{{eventContent.title}}</h5>
      <button type="button" class="close" (click)="close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body eventDtls">
      <div class="row mb-3">
        <div class="col-2 col-sm-1"><i class="fas fa-calendar-alt"></i></div>
        <div class="col-10 col-sm-11">
          <!-- {{eventContent.start| date:'medium'}} &nbsp;-&nbsp; {{eventContent.end| date:'medium'}} -->
          <span class="inDate">{{eventContent.start| date:'mediumDate'}}</span>
          <span class="inDate">{{eventContent.start| date:'shortTime'}}</span>
          <span class="dateTo">to</span>
          <span class="inDate">{{eventContent.end| date:'shortTime'}}</span>
          <span class="inDate">{{eventContent.end| date:'mediumDate'}}</span>
          <div class="tz">(GMT+05:30) India Standard Time - Kolkata</div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-2 col-sm-1"><i class="fas fa-bell"></i></div>
        <div class="col-10 col-sm-11">
          <!-- {{eventContent.duration == 'all_day'?'All Day':eventContent.duration + ' min'}} -->
          {{eventDuration}}
        </div>
      </div>
      <!-- <div class="row mb-3">
        <div class="col-2 col-sm-1"><i class="fas fa-map-marker-alt"></i></div>
        <div class="col-10 col-sm-11">
          New Delhi, India
        </div>
      </div> -->
      <div class="row mb-3">
        <div class="col-2 col-sm-1"><i class="fas fa-info-circle"></i></div>
        <div class="col-10 col-sm-11">
          <span [innerHTML] = "eventContent.description"></span>
        </div>
      </div>
    </div>
    <!-- <div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary" (click)="close()">OK</button>
    </div> -->
  </ng-template>