import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Teams } from 'src/app/model/passion-project-teams';

@Injectable({
  providedIn: 'root'
})
export class PassionProjectTeamService {
  private teamSource = new BehaviorSubject<object>({});

  team  = this.teamSource.asObservable();

  constructor() { }

  changeteam(team: Teams) {
    this.teamSource.next(team);
  }
}
