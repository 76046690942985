<div class="new_UIstyle">
        <!-- Banner Section Start -->
    <section class="banner banner-inner banner-answer" style="background-image: url('assets/images/banner/question-banner-02.png');">
        <div class="container">
             <div class="banner-content">
                <!-- <h4 *ngIf="myContentVideos"></h4> -->
                 <h2  class="white-text avenirBlack">{{questionName}}</h2>
                 <div class="tags-badges" *ngIf="tags.length > 0">
                     <span class="badge badge-blue" *ngFor="let tag of tags;">{{tag.tag}}</span>
                 </div>
                 <div class="tags-badges mt-2" *ngIf="categories.length > 0">
                     <span class="badge badge-green-lt" *ngFor="let cateies of categories;">{{cateies}}</span>
                 </div>
             </div>
        </div>
    </section> 
        <!-- Banner Section End -->

    <section class="section anwser-view-wrap">
        <div class="container">
            <div class="title text-start">
                <h5 class="avenirBook primary-text mb-0">Question Resources</h5>
                <hr />
            </div> 
           
            <div class="alert alert-info text-center" *ngIf="selectedVideokey.length == 0 && selectedPickey.length == 0 && selectedDockey.length == 0 && !loader">
                <p>Resources are not available yet.</p>
            </div>
            <div class="text-center" [ngClass]="{submitting : loader}">
                <div class="spinner-border text-warning formLoader" role="status" *ngIf="loader">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <div class="question-answer-items question-resourceWrap">
                <div class="answerVideoBlock">
                    
                    <div class="answerVideoListItem" *ngIf="selectedVideokey.length > 0">
                        <h6 class="primary-text avenirBlack">Videos</h6>
                        <div class="row">
                            <div class="col-lg-4 col-md-6 mb-3" *ngFor="let resourceVideo of selectedVideokey; let i = index;">
                                <div class="answerVideoBox">
                                    <div class="ratio ratio-16x9">
                                        <video width="540" height="310" controls>
                                            <source [src]="resourceVideos[i]" type="video/mp4">
                                        </video>
                                    </div>  
                                </div>
                            </div>
                        </div>
                    </div> 
 
                    <div class="answerVideoListItem" *ngIf="selectedPickey.length > 0">
                        <hr />
                        <h6 class="primary-text avenirBlack">Images</h6>
                        <div class="row ">
                            <div class="col-lg-4 col-md-6  mb-3" *ngFor="let resourceImg of selectedPickey; let i = index;">
                                <div class="answerVideoBox">
                                    <div class="ratio ratio-16x9">
                                        <img [src]="resourceImages[i]" [alt]="resourceImg" />
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>

                
                <div class="learning-course-items-wrap learning-course-resources-wrap" *ngIf="selectedDockey.length > 0">
                    <hr />
                    <h6 class="primary-text avenirBlack">Files</h6>
                    <div class="learning-course-item" *ngFor="let docRes of selectedDockey; let i = index;">
                        <div class="learning-course-link">
                            <div class="learning-course-elem-left">
                            <p class="learning-course-link-title">
                                <span class="title-icon"><i class="fas fa-folder"></i></span><strong>{{docRes | splitTitle}}</strong></p>
                            </div>
                            <div class="learning-course-elem-right">
                                <ul class="list-icon-wrap">
                                    <li>
                                        <a class="learning-course-download" [href]="resourceDocs[i]" target="_blank" [download]="resourceDocs[i]">
                                            <i class="fas fa-download"></i>
                                        </a>
                                    </li>
                                </ul>                                                   
                            </div>
                    </div>
                    </div>
                </div>
            </div>
            <hr />
            <div class="title d-flex align-items-center justify-content-between">
                <div class="title-left-cont text-start">
                    <h5 class="avenirBook primary-text mb-0">Answer<span *ngIf="routerUrl != '/answer-details'">s</span> 
                        <span clas="ansDetailClass" *ngIf="routerUrl == '/answer-details'"> Detail</span></h5>
                    <p *ngIf="!(answerslength>1) && routerUrl != '/answer-details'">There is {{answerslength}} Answer</p>
                    <p *ngIf="answerslength>1 && routerUrl != '/answer-details'">There are {{answerslength}} Answers</p>
                </div>
                <div class="btn-groups ansDetailAction">
                    <div class="ansDetailActionItem d-flex align-items-center justify-content-end" *ngIf="routerUrl == '/answer-details'">
                        <div class="likeDislike ansDetailActionItem" [ngClass]="userId == ansDetailUserID ? 'noTouched' : ''">
                            <ul class="likeDisList">
                                <li [ngClass]="isLiked == true ? 'active': ''">
                                    <a class="likeAction" href="javascript:void(0);" (click)="setLikeDislike('liked')" >
                                      <i class="fa fa-thumbs-up" aria-hidden="true"></i></a> 
                                      <span class="likeDisCount ml-2">{{like_count}}</span></li>
                                <li [ngClass]="isDisLiked == true ? 'active':' '">
                                    <a  class="likeAction" href="javascript:void(0);" (click)="setLikeDislike('disliked')" >
                                        <i class="fa fa-thumbs-down" aria-hidden="true"></i></a> 
                                        <span class="likeDisCount ml-2">{{dislike_count}}</span></li>
                            </ul>
                        </div>
                        <button class="btn btn-primary ansDetailActionItem" 
                        *ngIf="approval_status != 'approved' && userId != ans_user_id && question_status == 'open' && pageInfo=='MYQUES'"
                        (click)="approveAnswer()">Approve Answer</button>
                        <span class="dark-green-text ansDetailActionItem" 
                        *ngIf="approval_status == 'approved'"><i class="fas fa-check-double"></i><b> Answer Approved</b></span>
                        <!-- *ngIf="answer.approval_status.toLowerCase() != 'approved' && answer.user_id!=userId && question_status == 'open' && pageInfo=='MYQUES'"
                        (click)="approveAnswer(answer._id, answer.question_id,answer.approval_status, answerVideoIndex)" -->
                    </div>
                    <button type="button" class="btn btn-primary ansDetailActionItem"
                    *ngIf="question_status == 'open' && pageInfo == 'MYQUES' && role == 'student' && routerUrl == '/question-details'"
                    (click)="closeQuestion()">Close Question 
                    <i class="fas fa-times ml-2"></i>
                    </button>
                    <button type="button" class="btn btn-primary" 
                    (click)="giveYourAnswer()"
                    *ngIf="userId != question_user_id && pageInfo !='MYQUES' && routerUrl == '/question-details' && question_status != 'closed'">Write Your Answer</button>
                </div>
            </div> 
            <hr class="mt-3"/> 
            
            <div class="questionBankLists mb-3" *ngIf="routerUrl == '/question-details'">
                <div class="text-center" [ngClass]="{submitting : loader}">
                    <div class="spinner-border text-warning formLoader" role="status" *ngIf="loader">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <div class="questionBankItem bg-grey" *ngFor="let ans of answersList | paginate: { itemsPerPage: 10, currentPage: pageP, totalItems:totalAnsLen };">
                    <div class="questionItemLeft">
                        <div class="mb-2 questionItemLeftEllipse" *ngIf="ans.answer_html != ''">
                            <a [innerHTML]="ans.answer_html | slice:0:150"
                            (click)="getAnswerDetailRoute(ans._id)"></a>...
                        </div>
                        <h6 class="avenirBook mb-2" *ngIf="ans.answer_html == ''">
                            <a (click)="getAnswerDetailRoute(ans._id)"><b>This is a recorded answer</b></a>
                        </h6>
                    </div>
                    <div class="questionItemRight icons-group"> 
                        <a class="questionItemLink ItemEditLink"
                        (click)="EditQuestion(ans._id)"
                        *ngIf="ans.user_id == userId && ans.approval_status != 'approved'"
                         ngbTooltip="Edit">
                            <i class="fas fa-pencil-alt"></i></a>

                        <a class="questionItemLink ItemDeleteLink" 
                        *ngIf="ans.user_id == userId && ans.approval_status != 'approved'" 
                        (click)=openModelDeleteAns(deleteAns) ngbTooltip="Delete">
                        <i class="fas fa-trash-alt"></i></a>

                           <!-- new ui delete Answer -->
     <ng-template #deleteAns let-c="close" let-d="dismiss">
        <div class="new_UIstyle">
            <div class="modal-header justify-content-between align-items-center">
                <h5 class="avenirBook modal-title text-danger mb-0">Delete Answer</h5>
                <button type="button" class="close ml-0" aria-label="Close"
                    (click)="closeQuesPopup()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>Are you sure you want to delete Answer <strong class="text-danger">?</strong></p>
            </div>

            <div class="modal-footer">
                <div class="text-left">
                    <button type="submit" class="btn btn-primary btn-sm"
                        (click)="closeQuesPopup()">NO</button>
                </div>
                <div class="text-right">
                    <button type="submit" class="btn btn-primary btn-sm"
                    (click)=deleteToAnswer(ans._id)>YES</button>
                </div>
            </div>
        </div>
    </ng-template>
                    </div>
                    <div class="questionItemFooter">
                        <div class="questionItemFooterLeft">
                            <p class="primary-text list-space"><strong>{{ans.created_at | date:'short'}}</strong></p>
                        </div>
                        <div class="questionItemFooterRight">
                            <p class="primary-text" *ngIf="ans.approval_status == 'approved'"><strong>Answer Approved</strong></p>
                        </div>
                    </div>
                    <a  class="questionItemLink ItemAnchorLink" (click)="getAnswerDetailRoute(ans._id)"><i class="fa fa-angle-right"></i></a>
                </div>

                <div class="alert alert-info text-center" *ngIf="answersList.length == 0">
                    <p>Answer is not available yet</p> 
                </div>
                 <pagination-controls  class="text-center my-pagination"  *ngIf="answersList.length > 0" previousLabel="<"
                  nextLabel=">" maxSize="10" (pageChange)="pageP = $event"></pagination-controls>
            </div> 

            <div class="answerDetailWrap mb-3" *ngIf="routerUrl == '/answer-details'">
                <!-- question-resourceWrap -->
                <div class="question-answer-items answer-resourceWrap">
                    <div class="answerContentBlock mb-4" [innerHTML]="answerHtml"></div>
                    <div class="answerVideoBlock"> 
                        <div class="answerVideoListItem" *ngIf="ansResourceVideosKeyAns.length > 0">
                            <h6 class="primary-text avenirBlack">Videos</h6>
                            <div class="row">
                                <div class="col-lg-4 col-md-6 mb-3" *ngFor="let ansResourceVideo of ansResourceVideosKeyAns; let i = index;">
                                    <div class="answerVideoBox">
                                        <div class="ratio ratio-16x9"> 
                                            <video width="540" height="310" controls>
                                                <source [src]="ansResourceVideosAns[i]" type="video/mp4">
                                            </video>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
     
                        <div class="answerVideoListItem" *ngIf="resourceImagesKeyAns.length > 0">
                            <hr />
                            <h6 class="primary-text avenirBlack">Images</h6>
                            <div class="row ">
                                <div class="col-lg-4 col-md-6  mb-3" *ngFor="let ansResourceImg of resourceImagesKeyAns; let i = index;">
                                    <div class="answerVideoBox"> 
                                        <div class="ratio ratio-16x9">
                                            <img [src]="resourceImagesAns[i]" [alt]="ansResourceImg | splitTitle" />
                                        </div>
                                    </div>
                                </div> 
                            </div> 
                        </div>
                    </div>
    
                    
                    <div class="learning-course-items-wrap learning-course-resources-wrap" *ngIf="resourceDocsKeyAns.length > 0">
                        <hr />
                        <h6 class="primary-text avenirBlack">Files</h6>
                        <div class="learning-course-item" *ngFor="let ansDocRes of resourceDocsKeyAns; let i = index;">
                            <div class="learning-course-link">
                                <div class="learning-course-elem-left">
                                <p class="learning-course-link-title">
                                    <span class="title-icon"><i class="fas fa-folder"></i></span><strong>{{ansDocRes | splitTitle}}</strong></p>
                                </div>
                                <div class="learning-course-elem-right">
                                    <ul class="list-icon-wrap">
                                        <li>
                                            <a class="learning-course-download" [href]="resourceDocsAns[i]" target="_blank" [download]="resourceDocsAns[i]">
                                                <i class="fas fa-download"></i>
                                            </a>
                                        </li>
                                    </ul>                                                   
                                </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="comment_Detail_wrap" *ngIf="routerUrl == '/answer-details'">
                <hr />
                <div class="title d-flex align-items-center justify-content-between">
                    <div class="title-left-cont text-start">
                        <h5 class="avenirBook primary-text mb-0">Comment</h5>
                    </div>
                   <!-- comment sorting -->
                    <div class="btn-groups ansDetailAction" *ngIf="FeedbacksList.length > 0">   
                        <button type="button" class="btn btn-sm" [ngClass]="topFilter ? 'btn-primary':'btn-grey'" (click)="sortComment('top')">Top</button>
                        <button type="button" class="btn btn-sm " [ngClass]="newFilter? 'btn-primary':'btn-grey'" (click)="sortComment('Newest')">Newest</button>
                    </div>
                </div> 
                <hr class="mt-3 mb-5"/>  
                <div class="commentElemBlock">
                    <div class="writeCommentWrap border-0">
                        <div class="commentleftElem">
                            <figure class="commentDp mb-5" *ngIf="avatarURL">
                                <img [src]="avatarURL" alt="avtar" /> 
                            </figure>
                            <figure class="commentDp mb-5 commentDp-title" *ngIf="!avatarURL">
                                {{currentUsername | slice:0:1 | titlecase}}
                            </figure>
                        </div>
                        <div class="commentRightElem relative" >
                            <div class="text-center loadingScreen" [ngClass]="{submitting : loader}" *ngIf="loader">
                                <div class="spinner-border text-warning formLoader" role="status" *ngIf="loader">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                            <form id="commentVideo" #feedBack="ngForm"
                                (ngSubmit)="sendAnswerFeedback(feedBack)">


                                <textarea type="text" class="form-control form-control-textarea w-100"
                                    name="feedBack" ngModel placeholder="Add a comment... " id="textanswer"></textarea>


                                <div class="commentFooter btn-groups justify-content-end">
                                <!-- <button type="button" class="btn btn-sm btn-primary mr-3"
                                    (click)="resetFeedBack(feedBack)">Cancel</button> -->
                                <button type="submit" class="btn btn-sm btn-primary">Post</button>
                            </div>
                            </form>
                        </div>
                    </div>


                    <div class="commentListElemWrap">
                        <!-- Comment Row start -->
                        <div class="commentElemrow" *ngFor="let comment of FeedbacksList  | paginate: { itemsPerPage: 10, currentPage: pageComment, totalItems:totalcomments };">
                            <div class="commentleftElem commentUserDp">
                                <figure class="commentDp commentDp-sm" *ngIf="comment.profile_pic != ''">
                                    <img [src]="comment.profile_pic" />
                                </figure>
                                <figure class="commentDp commentDp-sm commentDp-title" *ngIf="comment.profile_pic == ''">
                                    {{comment.name | slice:0:1}}
                                </figure>
                            </div>
                            <div class="commentRightElem commentUserDetail">
                                <p>
                                    <small class="d-flex align-item-center commentUserhead">
                                        <strong class="commentName avenirBlack primary-text">{{comment.name |
                                            titlecase}}</strong>
                                        <span class="commentTime "> &nbsp; &#x2022; &nbsp;
                                            {{comment.Timestamp | dateTimeAgo}}</span>
                                    </small>
                                    <span class="textComment" [innerHTML]="comment.Feedback"></span>


                                    <span class="commentUserFoot d-flex">
                                        <span class="mr-3 me-md-5 commentActionElem likeComment">
                                            <a class="commentActionhand"
                                            [ngClass]="comment.Liked == true ? 'active': 'inActive'"
                                                (click)="ansCommentLikeDis(comment._id, 'liked',comment.Liked)"><i
                                                    class="fas fa-thumbs-up mr-2"></i>
                                                </a>
                                                <b class="conunts color-grey">{{comment.like_count}}</b>
                                            
                                        </span>
                                        <span class="commentActionElem disLikeComment">
                                            <a class="commentActionhand mt-1"
                                                [ngClass]="comment.Disliked == true ? 'active': 'inActive'"
                                                (click)="ansCommentLikeDis(comment._id, 'disliked',comment.Disliked)"><i
                                                    class="fas fa-thumbs-down mr-2"></i>
                                                </a>
                                                <b class="conunts color-grey">{{comment.dislike_count}}</b>
                                        </span>
                                    </span>
                                </p>
                            </div>
                        </div>

                        <pagination-controls  class="text-center my-pagination"  *ngIf="FeedbacksList.length > 0" previousLabel="<"
                            nextLabel=">" maxSize="10" (pageChange)="pageComment = $event"></pagination-controls>
                    </div>

                </div>
            </div>
        </div> 
    </section>
</div>
