<div class="banner">
    <div class="container text-center">
        <h1>{{ContentTitle}}</h1>
    </div>
</div>

<div class="pageBody" [ngClass]="{submitting : loader}">
    <div class="spinner-border text-warning formLoader" role="status" *ngIf="loader">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="container">

        <div class="clearfix filter mb-4" *ngIf="ContentTitle == 'Student Answer'">
            <div class="float-left">
                <select class="custom-select" [(ngModel)]="selectedValue" (change)="onChangeSubject($event.target.value)">
                    <option  value="select">Select Category</option>
                    <option *ngFor="let sub of subjectOfQuestion" [value]="sub._id">
                        {{sub.Course_Category}}
                    </option>
                </select>
            </div>
        </div>

        <div class="card-deck courses" *ngIf="ContentTitle != 'Student Answer'">
            <div class="card" *ngFor="let myAns of myAnswer">
                <div class="card-body">
                    <h5 title="{{myAns.question}}" class="text-truncate mb-3">{{myAns.question}}</h5>
                    <div class="embed-responsive embed-responsive-16by9 mb-3" *ngIf="myAns.file_path != ''">
                        <video width="540" height="310" controls>
                            <source [src]="myAns.file_path" type="video/mp4">
                        </video>
                    </div>

                    <div *ngIf="myAns.file_path == ''" class="text_answer_fixed">
                        <p>{{myAns.answer_text}}</p>
                     </div>

                    <div class="d-flex justify-content-between align-items-center" *ngIf="myAns.file_path != ''">
                        <div><button type="button" class="btn btn-sm btn-primary"
                                (click)="open(content, myAns.file_path, myAns.Feedback, myAns.Liked, myAns.Rating, myAns.question,myAns._id)">See
                                Answer<i class="fas fa-video ml-2"></i></button></div>
                        <div><span class="badge">{{myAns.Subject}}</span></div>
                    </div>
                    <span>{{myAns.Timestamp}}</span>
                </div>
            </div>

        </div>



        <div class="card-deckHldr" *ngIf="ContentTitle == 'Student Answer'">
            <div class="stdAnsList" *ngFor="let myAns of studentAnswer">
                <h5 class="ansQst mb-3">
                    <i class="fas fa-question-circle mr-2"></i>{{myAns.Question}}
                </h5>
                <div class="card-deck courses">
                    <div class="card" *ngFor="let answer of myAns.answer; let i=index">
                        <div class="card-body">
                            <div class="embed-responsive embed-responsive-16by9 mb-3">
                                <video width="540" height="310" controls>
                                    <source [src]="answer.file_path" type="video/mp4">
                                </video>
                            </div>
                            <div class="d-flex justify-content-between align-items-center">
                                <div><button type="button" class="btn btn-sm btn-primary"
                                        (click)="open(content, answer.file_path, answer.Feedback, answer.Liked, answer.Rating, answer.question,answer._id)">See
                                        Answer<i class="fas fa-video ml-2"></i></button></div>
                                <div><span class="badge">{{answer.Subject}}</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="myquestions?.length === 0">
                No Answer added by the student yet
            </div>
            <div *ngIf="selectedValue == 'select'">
                Select the category and if answer will be preset then it will show here.
            </div>
        </div>

    </div>


    <!-- Video PopUp -->
    <ng-template #content let-c="close" let-d="dismiss">
        <div class="modal-header justify-content-between align-items-center">
            <h4 class="modal-title">{{videoTitle}}</h4>
            <button type="button" class="close ml-0" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="embed-responsive rounded embed-responsive-16by9 mb-1">
                <video width="540" height="310" controls>
                    <source [src]="path" type="video/mp4">
                </video>
            </div>
            <div class="d-flex justify-content-between align-items-center">

                <div *ngIf="comments">
                    <h5 class="mb-0" *ngIf="comments.length == 1">{{comments.length}} Comment</h5>
                    <h5 class="mb-0" *ngIf="comments.length > 1">{{comments.length}} Comments</h5>
                </div>
                <div class="actionBox">
                    <!-- <a href="#" ngbTooltip="Like" [ngClass]="{txtRed: isLiked,  txtGray: !isLiked}"><i class="fas fa-thumbs-up"></i></a>
                <a href="#" ngbTooltip="Dislike" [ngClass]="{txtRed: isDisLiked,  txtGray: !isDisLiked}"><i class="fas fa-thumbs-down"></i></a> -->
                </div>
            </div>
            <div class="comntBoxPanel">
                <div class="media yourCmnt">
                    <div class="align-self-start mr-3 profIco">
                        <span class="tagIco">{{currentUsername[0] | uppercase}}</span>
                    </div>

                    <div class="media-body">

                        <form class="floatedInputForm" id="commentVideo" #answerFeedback="ngForm"
                            (ngSubmit)="sendAnswerFeedback(answerFeedback)">
                            <dl>
                                <dd>
                                    <input type="text" class="form-control" name="feedbackAnswer" ngModel
                                        placeholder=" " (focus)="showCmntsBtn()">
                                    <label class="flLabel">Enter Your comment</label>
                                </dd>
                            </dl>
                            <div class="text-right" *ngIf="showBtns">
                                <button type="button" class="btn btn-sm btn-default mr-3" (click)= "cancelFeedBack(answerFeedback)">Cancel</button>
                                <button type="submit" class="btn btn-sm btn-primary">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="media align-items-stretch" *ngFor="let comment of comments">
                    <div class="align-self-start mr-3 profIco" *ngIf="comment.username"><span
                            class="tagIco">{{comment.username[0] | uppercase}}</span></div>
                    <div class="media-body">
                        <h6 class="mt-0" *ngIf="comment.username">{{comment.username}}<small>
                                {{comment.Timestamp  | splitComma}}</small> </h6>
                        <p>{{comment.Feedback}}</p>
                    </div>
                </div>

            </div>
        </div>
    </ng-template>