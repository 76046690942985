import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CourseService } from 'src/app/services/course.service';

@Component({
  selector: 'app-user-submissions',
  templateUrl: './user-submissions.component.html',
  styleUrls: ['./user-submissions.component.scss']
})
export class UserSubmissionsComponent implements OnInit {
  loader: boolean;
  courseId: any;
  course_session_id: any;
  topic_id: any;
  user_id: any;
  publishRight: any;
  _id: any;
  schedule_id: any;

  course_category: any;
  course_category_id: any;
  sessionkey: any;
  courseName: any;
  course__Id: any;
  assessmentId: any;
  assessment_name: any;
  schedule__id: any;
  start__time: any;
  topicId: any;
  session_id: any;
  user__id: any;
  assessment_result_id: any;
  role: string;
  routerUrl:string;
  isTA:string = 'false';
  constructor(
    private activatedRoute: ActivatedRoute,
    private route: Router,
    private courseService: CourseService
  ) { }

  ngOnInit() {
    let sp = this.route.url.split('?')
    this.routerUrl = sp[0];
    this.role = localStorage.getItem('role');
    this.courseName = this.activatedRoute.snapshot.queryParamMap.get('course');
    this.course__Id = this.activatedRoute.snapshot.queryParamMap.get('course_id');
    this.course_category = this.activatedRoute.snapshot.queryParamMap.get('course_category');
    this.course_category_id = this.activatedRoute.snapshot.queryParamMap.get('course_category_id');
    this.assessmentId = this.activatedRoute.snapshot.queryParamMap.get('assessment_id');
    this.assessment_name = this.activatedRoute.snapshot.queryParamMap.get('assessment_name');
    this.schedule__id = this.activatedRoute.snapshot.queryParamMap.get('schedule_id');
    this.start__time = this.activatedRoute.snapshot.queryParamMap.get('start_time');
    this.topicId = this.activatedRoute.snapshot.queryParamMap.get('topicId');
    this.session_id = this.activatedRoute.snapshot.queryParamMap.get('session_id');
    this.user__id = this.activatedRoute.snapshot.queryParamMap.get('user__id');
    this.assessment_result_id = this.activatedRoute.snapshot.queryParamMap.get('assessment_result_id');
    this.sessionkey = this.activatedRoute.snapshot.queryParamMap.get('sessionkey');
    this.isTA = this.activatedRoute.snapshot.queryParamMap.get('isTA');


    // if(this.role !== 'student'){
    //    this.route.navigate(['/']);
    // }

    if (this.session_id == (undefined || null)) {
      this.session_id = '';
    }
    if (this.topicId == (undefined || null)) {
      this.topicId = '';
    }
    if (this.user__id == (undefined || null)) {
      this.user__id = '';
    }
    if (this.user_id == (undefined || null)) {
      this.user_id = '';
    }
    if (this.course_session_id == null) {
      this.course_session_id = '';
    }
    this.getSubmission();
  }

  go_to_url() {
    let url = '/courses/single-course'
    let body;
    if (this.session_id) {
      body = {
        course_id: this.course__Id,
        session_id: this.session_id,
        course_category: this.course_category,
        course_category_id: this.course_category_id,
        topicId:this.topicId,
        sessionkey: this.sessionkey,
        isTA:this.isTA
      }
      url = '/courses/video-with-notes'
    } else {
      body = {
        course_id: this.course__Id,
        isTA:this.isTA
      }
    }
    this.route.navigate([url], { queryParams: body })
  }

  go_to_list() {
    let url;
    let body;
       url = '/courses/assessment-marking';
       body = {
        course_id: this.course__Id,
        assessment_id: this.assessmentId,
        assessment_name: this.assessment_name,
        assessment_result_id: this.assessmentId,
        submitted_time: this.start__time,
        schedule_id: this.schedule_id,
        session_id: this.session_id,
        course_category: this.course_category,
        course_category_id: this.course_category_id,
        topicId:this.topicId,
        user__id:this.user__id,
        sessionkey: this.sessionkey,
        isTA: this.isTA
      }
      this.route.navigate([url], { queryParams: body })
    } 
    negative_marking_status:boolean = false;
  submissionResult = [];
  getSubmission(){
    this.loader = true;
    this.courseService.getUserSubmissions(this.assessmentId, this.course__Id, this.user__id).subscribe((res) =>{      
      this.negative_marking_status = res.negative_marking_status;
      this.submissionResult = res.response;
      this.loader = false;
    })
  }

  getFeedBack(submissionData){
    this.route.navigate(['/courses/feedback-assessement'] , { 
      queryParams: {
        course_id:this.course__Id, 
        course_category:this.course_category,
        course_category_id:this.course_category_id,
        assessment_id:this.assessmentId, 
        assessment_name:this.assessment_name, 
        assessment_result_id:submissionData.assessment_result_id,
        user__id:submissionData.user_id, 
        topicId: this.topicId, 
        session_id: this.session_id,
        sessionkey:this.sessionkey ,
        isTA:this.isTA
      } 
    })
  }

}
