
<!-- New Code -->
<div class="pageBodyWrap new_UIstyle">
    <!-- banner section -->
 <div class="banner banner-gradient banner-monitoring">
     <div class="container">
          <div class="banner-content">
              <div class="banner-content-content">
                  <h2 class="white-text avenirBlack">Machine Learning A-Z™:  <br /> Monitoring report</h2>
                    <!-- <p class="white-text">Group Assignment: <strong class="ms-2 primary-text">Student - Led</strong></p> -->
              </div>
              <div class="banner-content-action btn-groups">
                   <button class="btn btn-primary"> Cancel</button>
              </div>
          </div>
     </div>
  </div>
  

  <section class="section monitoring-report-wrap">
      <div class="container">
        <ul ngbNav #monitoringNav="ngbNav" class="nav-tabs nav-tabs-custom monitoring-report-nav">
            <li ngbNavItem>
              <a ngbNavLink>Session</a>
              <ng-template ngbNavContent>
                <div class="row mt-5">
                    <div class="col-12 col-md-7">
                        <div class="searchBarWrap">
                            <div class="category-search-block">
                                <form class="input-with-icon input-with-icon-right category-search-form">
                                  <input type="search" name="search_text" autocomplete="off" 
                                    placeholder="Search..."
                                    class="form-control form-control-lg" />
                                  <button class="input-with-icon-btn category-search-btn"><i class="fas fa-search"></i></button>
                                </form>
                              </div>
                        </div>

                        <div class="sc-tabs-content-block mR-tabs-session-content">
                            <ngb-accordion #mRsessionAcc="ngbAccordion" [closeOthers]="true"  activeIds="active" class="accordionCustom">
                                <ngb-panel id="active">
                                  <ng-template ngbPanelHeader>
                                      <button ngbPanelToggle class="d-block panelHeaderButton">
                                        <span class="acc-headerTitle h6 avenirBlack d-block" >Introduction</span>
                                        <span class="acc-headerDes p d-block">10 Sessions</span>  
                                        <span class="acc-headerAngel"><i class="fas fa-angle-down"></i></span>     
                                      </button>
                                  </ng-template>
                                  <ng-template ngbPanelContent>  
                                      <div class="panelContentWrap">

                                        <div
                                        class="learning-course-items-wrap monitoring-sessionWrap">
                                        <!-- learning-course-liveSession-wrap -->
                                        <div class="learning-course-item">
                                            <div class="learning-course-link align-items-center">
                                                <div class="learning-course-elem-left">
                                                    <div
                                                        class="title-icon-content  d-inline-block">
                                                            <ng-container>
                                                                <p class="learning-course-link-title avenirBook session-link mb-1">
                                                                    <strong>Title Here... </strong>
                                                                </p>
                                                                <span class="session-link d-flex align-items-center justify-content-start" >
                                                                    <img class="session-play-img mr-1 d-inline-block" src="../../../assets/images/apps-icon/video-icon.png" />
                                                                     <small>40 Min</small>
                                                                </span>
                                                            </ng-container>
                                                           
                                                    </div>
                                                </div>
                                                <div class="learning-course-elem-right text-right">
                                                    <ng-container>
                                                        <button type="button" class="btn btn-primary btn-sm">Select</button>
                                                    </ng-container>
                                            </div>
                                            </div>
                                        </div>


                                        <!-- session item start -->

                                        <div class="learning-course-item">
                                            <div class="learning-course-link align-items-center">
                                                <div class="learning-course-elem-left">
                                                    <div
                                                        class="title-icon-content  d-inline-block">
                                                            <ng-container>
                                                                <p class="learning-course-link-title avenirBook session-link mb-1">
                                                                    <strong>Title Here... </strong>
                                                                </p>
                                                                <span class="session-link d-flex align-items-center justify-content-start" >
                                                                    <img class="session-play-img mr-1 d-inline-block" src="../../../assets/images/apps-icon/video-icon.png" />
                                                                     <small>40 Min</small>
                                                                </span>
                                                            </ng-container>
                                                           
                                                    </div>
                                                </div>
                                                <div class="learning-course-elem-right text-right">
                                                    <ng-container>
                                                        <button type="button" class="btn btn-primary btn-sm">Select</button>
                                                    </ng-container>
                                            </div>
                                            </div>
                                        </div>


                                    </div>
                                       </div>
                                  </ng-template> 
                                </ngb-panel>
                                  
                              </ngb-accordion>
                              
                        </div>
                    </div>
                    <div class="col-12 col-md-5 mt-md-0 mt-2 border-md-left">
                       <div class="selectedSession-wrap">
                         <h6 class="primary-text avenirBlack">Resources</h6>

                         <div class="selectedSession-list">

                            <div
                            class="learning-course-items-wrap monitoring-sessionSelected">
                            <!-- learning-course-liveSession-wrap -->
                            <div class="learning-course-item">
                                <div class="learning-course-link align-items-center">
                                    <div class="learning-course-elem-left">
                                        <div
                                            class="title-icon-content  d-inline-block">
                                                <ng-container>
                                                    <p class="learning-course-link-title avenirBook session-link mb-1">
                                                        <strong>Title Here... </strong>
                                                    </p>
                                                    <span class="session-link d-flex align-items-center justify-content-start" >
                                                        <img class="session-play-img mr-1 d-inline-block" src="../../../assets/images/apps-icon/video-icon.png" />
                                                         <small>40 Min</small>
                                                    </span>
                                                </ng-container>
                                               
                                        </div>
                                    </div>
                                    <!-- <div class="learning-course-elem-right text-right">
                                        <ng-container>
                                            <button type="button" class="btn btn-primary btn-sm">Select</button>
                                        </ng-container>
                                </div> -->
                                </div>
                            </div>

                            <div class="learning-course-item">
                                <div class="learning-course-link align-items-center">
                                    <div class="learning-course-elem-left">
                                        <div
                                            class="title-icon-content  d-inline-block">
                                                <ng-container>
                                                    <p class="learning-course-link-title avenirBook session-link mb-1">
                                                        <strong>Title Here... </strong>
                                                    </p>
                                                    <span class="session-link d-flex align-items-center justify-content-start" >
                                                        <img class="session-play-img mr-1 d-inline-block" src="../../../assets/images/apps-icon/video-icon.png" />
                                                         <small>40 Min</small>
                                                    </span>
                                                </ng-container>
                                               
                                        </div>
                                    </div>
                                    <!-- <div class="learning-course-elem-right text-right">
                                        <ng-container>
                                            <button type="button" class="btn btn-primary btn-sm">Select</button>
                                        </ng-container>
                                </div> -->
                                </div>
                            </div>

                            <div class="learning-course-item">
                                <div class="learning-course-link align-items-center">
                                    <div class="learning-course-elem-left">
                                        <div
                                            class="title-icon-content  d-inline-block">
                                                <ng-container>
                                                    <p class="learning-course-link-title avenirBook session-link mb-1">
                                                        <strong>Title Here... </strong>
                                                    </p>
                                                    <span class="session-link d-flex align-items-center justify-content-start" >
                                                        <img class="session-play-img mr-1 d-inline-block" src="../../../assets/images/apps-icon/video-icon.png" />
                                                         <small>40 Min</small>
                                                    </span>
                                                </ng-container>
                                               
                                        </div>
                                    </div>
                                    <!-- <div class="learning-course-elem-right text-right">
                                        <ng-container>
                                            <button type="button" class="btn btn-primary btn-sm">Select</button>
                                        </ng-container>
                                </div> -->
                                </div>
                            </div>

                            <div class="learning-course-item">
                                <div class="learning-course-link align-items-center">
                                    <div class="learning-course-elem-left">
                                        <div
                                            class="title-icon-content  d-inline-block">
                                                <ng-container>
                                                    <p class="learning-course-link-title avenirBook session-link mb-1">
                                                        <strong>Title Here... </strong>
                                                    </p>
                                                    <span class="session-link d-flex align-items-center justify-content-start" >
                                                        <img class="session-play-img mr-1 d-inline-block" src="../../../assets/images/apps-icon/video-icon.png" />
                                                         <small>40 Min</small>
                                                    </span>
                                                </ng-container>
                                               
                                        </div>
                                    </div>
                                    <!-- <div class="learning-course-elem-right text-right">
                                        <ng-container>
                                            <button type="button" class="btn btn-primary btn-sm">Select</button>
                                        </ng-container>
                                </div> -->
                                </div>
                            </div>
                            

                            </div>
                         </div>
                         <div class="btn-groups justify-content-end">
                             <button class="btn btn-sm btn-primary btn-w-100">Cancel</button>
                             <button class="btn btn-sm btn-primary btn-w-100">Next</button>
                         </div>
                       </div>
                    </div>
                </div>

              </ng-template>
            </li>
            <li ngbNavItem>
              <a ngbNavLink>Topic</a>
              <ng-template ngbNavContent>
                <div class="sc-tabs-content-block mR-tabs-Topic-content">
                    <div class="learning-course-items-wrap mR-tabs-topic-course-items">
                        <div class="learning-course-item">
                           <div class="learning-course-link bg-grey align-items-center">
                               <div class="learning-course-elem-left">
                                <p class="learning-course-link-title h6 avenirBlack"><strong>Introduction</strong></p>
                                <div class="mt-2 mt-md-2 assessment-time-slot">
                                    <p><small class="d-inline-block">8 Sessions</small></p>
                                </div>
                               </div>
                               <div class="learning-course-elem-right">
                                   <p><a class="btn btn-primary btn-w-150 btn-sm">Select</a></p>
                               </div>
                          </div>
                       </div>

                       <div class="learning-course-item">
                        <div class="learning-course-link bg-grey align-items-center">
                            <div class="learning-course-elem-left">
                             <p class="learning-course-link-title h6 avenirBlack"><strong>Introduction to Machine Learning</strong></p>
                             <div class="mt-2 mt-md-2 assessment-time-slot">
                                 <p><small class="d-inline-block">11 Sessions</small></p>
                             </div>
                            </div>
                            <div class="learning-course-elem-right">
                                <p><a class="btn btn-primary btn-w-150 btn-sm">Select</a></p>
                            </div>
                       </div>
                    </div>

                    <div class="learning-course-item">
                        <div class="learning-course-link bg-grey align-items-center">
                            <div class="learning-course-elem-left">
                             <p class="learning-course-link-title h6 avenirBlack"><strong>Introduction</strong></p>
                             <div class="mt-2 mt-md-2 assessment-time-slot">
                                 <p><small class="d-inline-block">10 Sessions</small></p>
                             </div>
                            </div>
                            <div class="learning-course-elem-right">
                                <p><a class="btn btn-primary btn-w-150 btn-sm">Select</a></p>
                            </div>
                       </div>
                    </div>

                    <div class="learning-course-item">
                     <div class="learning-course-link bg-grey align-items-center">
                         <div class="learning-course-elem-left">
                          <p class="learning-course-link-title h6 avenirBlack"><strong>Introduction to Machine Learning</strong></p>
                          <div class="mt-2 mt-md-2 assessment-time-slot">
                              <p><small class="d-inline-block">8 Sessions</small></p>
                          </div>
                         </div>
                         <div class="learning-course-elem-right">
                             <p><a class="btn btn-primary btn-w-150 btn-sm">Select</a></p>
                         </div>
                    </div>
                 </div>

                    </div>
                </div>
              </ng-template>
            </li>
          </ul>
          <div [ngbNavOutlet]="monitoringNav"></div>
      </div>
  </section>

  <section class="section genrateMonitoringReprot">
     <div class="container">
        <ngb-accordion #mRsessionAcc="ngbAccordion" activeIds="active" class="accordionCustom">
            <ngb-panel id="active" >
              <ng-template ngbPanelHeader >
                  <div  class="d-block panelHeaderButton">
                    <span class="acc-headerTitle h6 avenirBlack d-block" >Supervised Learning</span>
                    <!-- <span class="acc-headerDes p d-block">10 Sessions</span>   -->
                    <!-- <span class="acc-headerAngel"><i class="fas fa-angle-down"></i></span>      -->
                  </div>
              </ng-template>
              <ng-template ngbPanelContent>  
                  <div class="panelContentWrap p-3">
                    <div class="row justify-content-center">
                        <div class="col-md-12 asslist form">
                            <div class="form-group mb-2 mb-md-2">
                                <div class="assessment-item">
                                    <div class="assessment-item-checkBox">
                                        <input class="form-check-input" type="checkbox" >
                                    </div>
                                    <div class="assessment-item-label">
                                        <div class="col-12 h-100">
                                            <div class="row align-items-center h-100">
                                                <div class="col-xl-12 col-md-12">
                                                    <p>Attendance</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
    
                            <div class="form-group mb-2 mb-md-2">
                                <div class="assessment-item">
                                    <div class="assessment-item-checkBox">
                                        <input class="form-check-input" type="checkbox" >
                                    </div>
                                    <div class="assessment-item-label">
                                        <div class="col-12 h-100">
                                            <div class="row align-items-center h-100">
                                                <div class="col-xl-12 col-md-12">
                                                    <p>Notes</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
    
                            <div class="form-group mb-2 mb-md-2">
                                <div class="assessment-item">
                                    <div class="assessment-item-checkBox">
                                        <input  class="form-check-input" type="checkbox" >
                                    </div>
                                    <div class="assessment-item-label">
                                        <div class="col-12 h-100">
                                            <div class="row align-items-center h-100">
                                                <div class="col-xl-6 col-md-12 mb-2 mb-xl-0">
                                                    <p>Coursework</p>
                                                </div>
                                                <div class="col-xl-2 col-md-4 mb-2 mb-md-0">
                                                    <div class="form-group form-check">
                                                        <input type="checkbox" class="form-check-input" id="topiclevel">
                                                        <label class="form-check-label" for="topiclevel">Topic Level</label>
                                                      </div>
                                                </div>
                                                <div class="col-xl-2 col-md-4 mb-2 mb-md-0">
                                                    <div class="form-group form-check">
                                                        <input type="checkbox" class="form-check-input" id="courselevel">
                                                        <label class="form-check-label" for="courselevel">Course Level</label>
                                                      </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
        
                            <div class="form-group mb-2 mb-md-2">
                                <div class="assessment-item">
                                    <div class="assessment-item-checkBox">
                                        <input class="form-check-input" type="checkbox">
                                    </div>
                                    <div class="assessment-item-label">
                                        <div class="col-12 h-100">
                                            <div class="row align-items-center h-100">
                                                <div class="col-xl-6 col-md-12 mb-2 mb-xl-0">
                                                    <p>Assessments</p>
                                                </div>
                                                <div class="col-xl-2 col-md-4 mb-2 mb-lg-0">
                                                    <div class="form-group form-check">
                                                        <input type="checkbox" class="form-check-input" id="AssTopiclevel">
                                                        <label class="form-check-label" for="AssTopiclevel">Topic Level</label>
                                                      </div>
                                                </div>
                                                <div class="col-xl-2 col-md-4 mb-2 mb-lg-0">
                                                    <div class="form-group form-check">
                                                        <input type="checkbox" class="form-check-input" id="assTopiclevel">
                                                        <label class="form-check-label" for="assTopiclevel">Session Level</label>
                                                      </div>
                                                </div>
                                                <div class="col-xl-2 col-md-4 mb-2 mb-lg-0">
                                                    <div class="form-group form-check">
                                                        <input type="checkbox" class="form-check-input" id="assCourselevel">
                                                        <label class="form-check-label" for="assCourselevel">Course Level</label>
                                                      </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="btn-groups justify-content-end mt-5">
                                <button type="button" class="btn btn-sm btn-primary">Generate Report</button>
                            </div>
                        </div>
                    </div>
                   </div>
              </ng-template> 
            </ngb-panel>
              
          </ngb-accordion>
     </div>
  </section>

  <section class="section monitoring-report-table">
      <div class="container">
        <div class="manage-table-nav mb-3">
            <div class="manage-table-nav-left">
                <ul class="manage-table-nav-list">
                    <li><button class="btn-transparent-caret manage-table-nav-btn table-filter-btn"><img src="../../assets/images/apps-icon/filter-icon.png" alt="filter-icon.png" /></button></li>
                    <li>
                        <div ngbDropdown class="dropdown dropdown-Width-260 sessions-dropdown" container="body">
                            <button class="btn-transparent-caret" ngbDropdownToggle>Sessions</button>
                            <div ngbDropdownMenu class="dropdown-menu-dropdown dropdown-menu-lg">
                                <form>
                                   <div class="form-check mb-2">
                                       <input class="form-check-input" type="checkbox" id="Organization1" value="option1">
                                       <label class="form-check-label" for="Organization1">Evaluating Models and Model Selection</label>
                                     </div>
                                     <div class="form-check mb-2">
                                       <input class="form-check-input" type="checkbox" id="Organization2" value="option2">
                                       <label class="form-check-label" for="Organization2">Introduction to machine learning 1</label>
                                     </div>

                                     <div class="form-check mb-2">
                                       <input class="form-check-input" type="checkbox" id="Organization3" value="option3">
                                       <label class="form-check-label" for="Organization3">Machine Learning 2</label>
                                     </div>

                                     <div class="form-check mb-2">
                                       <input class="form-check-input" type="checkbox" id="Organization4" value="option4">
                                       <label class="form-check-label" for="Organization4">Models</label>
                                     </div>

                                     <div class="form-check mb-2">
                                       <input class="form-check-input" type="checkbox" id="Organization5" value="option5">
                                       <label class="form-check-label" for="Organization5">Nodes</label>
                                     </div>
                                </form>
                            </div>
                        </div>
                   </li>

                   <li>
                       <div ngbDropdown class="dropdown dropdown-Width-260 courseWork-dropdown" container="body">
                           <button class="btn-transparent-caret" ngbDropdownToggle>Coursework</button>
                           <div ngbDropdownMenu class="dropdown-menu-dropdown dropdown-menu-lg">
                               <form>
                                <label class="form-label avenirBlack mb-1">Topic</label>
                                  <div class="form-check mb-2">
                                      <input class="form-check-input" type="checkbox" id="OrganizationU1" value="option1">
                                      <label class="form-check-label" for="OrganizationU1">Punjab University</label>
                                    </div>
                                    <div class="form-check mb-2">
                                      <input class="form-check-input" type="checkbox" id="OrganizationU2" value="option2">
                                      <label class="form-check-label" for="OrganizationU2">Tatras University</label>
                                    </div>

                                    <div class="form-check mb-2">
                                      <input class="form-check-input" type="checkbox" id="OrganizationU3" value="option3">
                                      <label class="form-check-label" for="OrganizationU3">IEC</label>
                                    </div>

                                    <div class="form-check mb-2">
                                      <input class="form-check-input" type="checkbox" id="OrganizationU4" value="option4">
                                      <label class="form-check-label" for="OrganizationU4">Thapar University</label>
                                    </div>

                                    <div class="form-check mb-2">
                                      <input class="form-check-input" type="checkbox" id="OrganizationU5" value="option5">
                                      <label class="form-check-label" for="OrganizationU5">Punjabi University, Patiala</label>
                                    </div>

                                    <div class="form-check mb-2">
                                      <input class="form-check-input" type="checkbox" id="OrganizationU6" value="option6">
                                      <label class="form-check-label" for="OrganizationU6">Sabudh Insitiute</label>
                                    </div>
                                    <label class="form-label avenirBlack mb-1">Course</label>
                                    <div class="form-check mb-2">
                                        <input class="form-check-input" type="checkbox" id="course1" value="option1">
                                        <label class="form-check-label" for="course1">Punjab University</label>
                                      </div>
                                      <div class="form-check mb-2">
                                        <input class="form-check-input" type="checkbox" id="course2" value="option2">
                                        <label class="form-check-label" for="course2">Tatras University</label>
                                      </div>
  
                                      <div class="form-check mb-2">
                                        <input class="form-check-input" type="checkbox" id="course3" value="option3">
                                        <label class="form-check-label" for="course3">IEC</label>
                                      </div>
  
                                      <div class="form-check mb-2">
                                        <input class="form-check-input" type="checkbox" id="course4" value="option4">
                                        <label class="form-check-label" for="course4">Thapar University</label>
                                      </div>
  
                                      <div class="form-check mb-2">
                                        <input class="form-check-input" type="checkbox" id="course5" value="option5">
                                        <label class="form-check-label" for="course5">Punjabi University, Patiala</label>
                                      </div>
  
                                      <div class="form-check mb-2">
                                        <input class="form-check-input" type="checkbox" id="course6" value="option6">
                                        <label class="form-check-label" for="course6">Sabudh Insitiute</label>
                                      </div>
                               </form>
                           </div>
                       </div>
                  </li>

                  <li>
                   <div ngbDropdown class="dropdown dropdown-Width-120 assessment-dropdown" container="body">
                       <button class="btn-transparent-caret" ngbDropdownToggle>Assessment</button>
                       <div ngbDropdownMenu class="dropdown-menu-dropdown dropdown-menu-lg">
                           <form>

                              <div class="form-check mb-2">
                                  <input class="form-check-input" type="checkbox" id="OrganizationT1" value="option1">
                                  <label class="form-check-label" for="OrganizationT1">Punjab University</label>
                                </div>
                                <div class="form-check mb-2">
                                  <input class="form-check-input" type="checkbox" id="OrganizationT2" value="option2">
                                  <label class="form-check-label" for="OrganizationT2">Tatras University</label>
                                </div>

                                <div class="form-check mb-2">
                                  <input class="form-check-input" type="checkbox" id="OrganizationT3" value="option3">
                                  <label class="form-check-label" for="OrganizationT3">IEC</label>
                                </div>

                                <div class="form-check mb-2">
                                  <input class="form-check-input" type="checkbox" id="OrganizationT4" value="option4">
                                  <label class="form-check-label" for="OrganizationT4">Thapar University</label>
                                </div>

                                <div class="form-check mb-2">
                                  <input class="form-check-input" type="checkbox" id="OrganizationT5" value="option5">
                                  <label class="form-check-label" for="OrganizationT5">Punjabi University, Patiala</label>
                                </div>

                                <div class="form-check mb-2">
                                  <input class="form-check-input" type="checkbox" id="OrganizationT6" value="option6">
                                  <label class="form-check-label" for="OrganizationT6">Sabudh Insitiute</label>
                                </div>
                           </form>
                       </div>
                   </div>
              </li>
                </ul>
            </div>
            <div class="manage-table-nav-right btn-groups">
                <!-- <button class="btn btn-sm btn-primary" (click)="openaddEditUserModal(addEditUser)">+ Add user</button>
                <button class="btn btn-sm btn-primary" (click)="openaddEditUserModal(bulkUpload)">+ Bulk Upload</button> -->
                <button class="btn-transparent btn-transparent-primary manage-tableExport-btn"><img class="export-icon" src="../../assets/images/apps-icon/export.png" alt="export.png" />Export</button>
               </div>
        </div> 
          <div class="monitoring-table-main">
              <table class="table monitoring-table-fixed">
                  <tr>
                      <td class="monitoring-td-left monitoring-td-fixed">
                          <table class="table" width="100%" >
                            <thead>
                                <tr>
                                    <th>&nbsp;</th>
                                    <th>&nbsp;</th>
                                 </tr>
                                 <tr>
                                    <th>&nbsp;</th>
                                    <th>&nbsp;</th>
                                 </tr>
                                <tr>
                                   <th>Name</th>
                                   <th>Attendance</th>
                                </tr>
                           </thead>
                           <tbody>
                              <tr>
                                  <td>Ananya Sharma</td>
                                  <td class="greenText">10/10</td>
                              </tr>
                              <tr>
                                 <td>Kirti Sharma </td>
                                 <td class="redText">4/10</td>
                             </tr>
                             <tr>
                                 <td>Shivendra Sinha</td>
                                 <td class="greenText">8/10</td>
                             </tr>
                             <tr>
                                 <td>Sanjay</td>
                                 <td class="greenText">9/10</td>
                             </tr>
                             <tr>
                                 <td>Sudhanshu</td>
                                 <td class="greenText">6/10</td>
                             </tr>
                           </tbody>
                          </table>
                      </td>
                      <td class="monitoring-td-right monitoring-td-scroll">
                        <table class="table monitoring-td-scroll-table">
                            <tr>
                                <td class="monitoring-td-scroll-inn monitoring-td-scroll-1">
                                      <table class="table">
                                        <thead>
                                            <tr>
                                                <th colspan="2" class="border-bottom h1-title text-center"><strong>Coursework</strong></th>
                                            </tr>
                                            <tr>
                                                <th class="h2-title text-center border-right">Topic</th>
                                                <th class="h2-title text-center">Course</th>
                                            </tr>
                                            <tr>
                                                <th class="p-0">
                                                    <table>
                                                        <tr>
                                                            <th class="h3-title text-center">CWT 1</th>
                                                            <th class="h3-title text-center">CWT 2</th>
                                                        </tr>
                                                    </table>
                                                </th>
                                                <th class="p-0">
                                                    <table class="table">
                                                        <tr>
                                                            <th class="h3-title text-center">CWT 2</th>
                                                            <th>CWT 2</th>
                                                        </tr>
                                                    </table>
                                                </th>
                                            </tr>
                                        </thead>
                                         <tbody>
                                             <tr>
                                                 <td>
                                                    <table class="table">
                                                        <tbody>
                                                            <tr>
                                                                <td class="greenText">1</td>
                                                                <td class="greenText">1</td>
                                                            </tr>
                                                            <tr>
                                                                <td class="greenText">1</td>
                                                                <td class="greenText">1</td>
                                                            </tr>
                                                            <tr>
                                                                <td class="greenText">1</td>
                                                                <td class="redText">1</td>
                                                            </tr>
                                                            <tr>
                                                                <td class="greenText">1</td>
                                                                <td class="redText">1</td>
                                                            </tr>
                                                            <tr>
                                                                <td class="greenText">1</td>
                                                                <td class="redText">1</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                 </td>
                                                 <td>
                                                    <table class="table">
                                                        <tbody>
                                                            <tr>
                                                                <td class="greenText">1</td>
                                                                <td class="greenText">1</td>
                                                            </tr>
                                                            <tr>
                                                                <td class="redText">1</td>
                                                                <td class="greenText">1</td>
                                                            </tr>
                                                            <tr>
                                                                <td class="redText">1</td>
                                                                <td class="greenText">1</td>
                                                            </tr>
                                                            <tr>
                                                                <td class="greenText">1</td>
                                                                <td class="greenText">1</td>
                                                            </tr>
                                                            <tr>
                                                                <td class="greenText">1</td>
                                                                <td class="greenText">1</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                 </td>
                                             </tr>
                                         </tbody>
                                      </table>
                                </td>
                                <td class="monitoring-td-scroll-inn monitoring-td-scroll-2">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th colspan="3" class="border-bottom h1-title text-center"><strong>Assessement</strong></th>
                                            </tr>
                                            <tr>
                                                <th class="h2-title text-center border-right">Session</th>
                                                <th class="h2-title text-center border-right">Topic</th>
                                                <th class="h2-title text-center">Course</th>
                                            </tr>
                                            <tr>
                                                <th class="p-0">
                                                    <table>
                                                        <tr>
                                                            <th class="h3-title text-center">CWT 1</th>
                                                            <th class="h3-title text-center">CWT 2</th>
                                                        </tr>
                                                    </table>
                                                </th>
                                                <th class="p-0">
                                                    <table class="table">
                                                        <tr>
                                                            <th class="h3-title text-center">CWT 2</th>
                                                            <th>CWT 2</th>
                                                        </tr>
                                                    </table>
                                                </th>
                                                <th class="p-0">
                                                    <table class="table">
                                                        <tr>
                                                            <th class="h3-title text-center">CWT 2</th>
                                                            <th>CWT 2</th>
                                                        </tr>
                                                    </table>
                                                </th>
                                            </tr>
                                        </thead>
                                         <tbody>
                                             <tr>
                                                 <td>
                                                    <table class="table">
                                                        <tbody>
                                                            <tr>
                                                                <td class="greenText">1</td>
                                                                <td class="greenText">1</td>
                                                            </tr>
                                                            <tr>
                                                                <td class="redText">1</td>
                                                                <td class="greenText">1</td>
                                                            </tr>
                                                            <tr>
                                                                <td class="greenText">1</td>
                                                                <td class="redText">1</td>
                                                            </tr>
                                                            <tr>
                                                                <td class="greenText">1</td>
                                                                <td class="greenText">1</td>
                                                            </tr>
                                                            <tr>
                                                                <td class="redText">1</td>
                                                                <td class="greenText">1</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                 </td>
                                                 <td>
                                                    <table class="table">
                                                        <tbody>
                                                            <tr>
                                                                <td class="greenText">1</td>
                                                                <td class="greenText">1</td>
                                                            </tr>
                                                            <tr>
                                                                <td class="redText">1</td>
                                                                <td class="greenText">1</td>
                                                            </tr>
                                                            <tr>
                                                                <td class="redText">1</td>
                                                                <td class="greenText">1</td>
                                                            </tr>
                                                            <tr>
                                                                <td class="greenText">1</td>
                                                                <td class="greenText">1</td>
                                                            </tr>
                                                            <tr>
                                                                <td class="redText">1</td>
                                                                <td class="greenText">1</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                 </td>
                                                 <td>
                                                    <table class="table">
                                                        <tbody>
                                                            <tr>
                                                                <td class="greenText">1</td>
                                                                <td class="greenText">1</td>
                                                            </tr>
                                                            <tr>
                                                                <td class="redText">1</td>
                                                                <td class="greenText">1</td>
                                                            </tr>
                                                            <tr>
                                                                <td class="redText">1</td>
                                                                <td class="greenText">1</td>
                                                            </tr>
                                                            <tr>
                                                                <td class="greenText">1</td>
                                                                <td class="greenText">1</td>
                                                            </tr>
                                                            <tr>
                                                                <td class="greenText">1</td>
                                                                <td class="greenText">1</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                 </td>
                                             </tr>
                                         </tbody>
                                      </table>
                                </td>
                                
                            </tr>
                        
                        </table>
                      </td>
                  </tr>
                </table>
          </div>
      </div>
  </section>
  </div>
