<!-- MODAL CREATE TEAMS |START-->
<div #createGroup class="new_UIstyle" [ngClass]="{submitting : groupProcessing}">
    <div class="create_mod">
        <div class="modal-body modalGroupBody crea_mod modal-body-border">
            <div class="spinner-border text-warning formLoader" role="status" *ngIf="groupProcessing">
                <span class="sr-only">Loading...</span>
            </div>
            <div class="guftagu-addUserGroup-body-wrap pt-0">
                <div class="row g-0 gy-3 gy-md-0">
                    <div class="col-lg-6 mt-3  mt-lg-0 order-2 order-lg-1 pt-3">
                        <div class="guftagu-addUser-search mb-4">
                            <form class="input-with-icon input-with-icon-left search-form">
                                <input type="search" name="search" placeholder="Search user"
                                    class="form-control form-control-style-2 form-control-lg create-group-control" autocomplete="off" appSearchField (sendSearchData)="onStartSearch($event)"/>
                                <button class="input-with-icon-btn search-btn"><i
                                        class="fas fa-search"></i></button>
                            </form>
                        </div>

                        <div class="guftagu-addUserList mt-2">
                            <div class="guftagu-item-list guftagu-item-list-addChatUser"
                                *ngFor="let subscriberStudent of subscribeStudentList">
                                <div *ngIf="subscriberStudent._id != loggedInUserId" class="guftagu-item-link">
                                    <div class="row g-0 gy-3 gy-md-0 align-items-center">
                                        <div class="col-12 col-md-8 guftagu-item-link-left">
                                            <default-name-icon *ngIf="!subscriberStudent.profile_pic" [name]="subscriberStudent.name" [figureClass]="''"></default-name-icon>
                                            <figure *ngIf="subscriberStudent.profile_pic" class="guftagu-item-fig">
                                                <img [src]="subscriberStudent.profile_pic" alt="dp-2.jpg" /></figure>
                                            <div class="ml-2">
                                                <p class="guftagu-item-desc-title avenirBlack mb-0">
                                                    {{subscriberStudent.name}}</p>
                                                <p class="guftagu-item-desc-title mb-0">
                                                    {{subscriberStudent.username}}</p>
                                            </div>
                                        </div>

                                        <div class="col-12 col-md-4 guftagu-item-link-right text-end">
                                            <button class="btn btn-sm btn-blue"
                                                (click)="addMemberToGroup(subscriberStudent)" name="grouplist"
                                                [disabled]="subscriberStudent?.isAdded || subscriberStudent.team_status == true">{{subscriberStudent?.isAdded
                                                ? 'Selected' : 'Select'}}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="alert alert-info text-center p-2"
                                *ngIf="!groupProcessing && subscribeStudentList.length == 0 && searchText.length > 0">
                                <p>User Not Found.</p>
                            </div>
                            <div class="alert alert-info text-center p-2"
                                *ngIf="!groupProcessing && subscribeStudentList.length == 0 && searchText.length == 0">
                                <p>Search users to add into mentors list.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 ps-0 ps-lg-3 order-1 order-lg-2">
                        <form *ngIf="editTeamData" [formGroup]="addTeamByStudnet">
                            <div class="guftaguCreateGroup">

                                <div class="mt-4 memberListWrap">
                                    <p class="mb-0">Selected Members</p>
                                    <hr class="mt-0" />
                                    <div class="mt-2 addGroupMenmerList">
                                        <div class="guftagu-item-list guftagu-item-list-addChatUser"
                                            *ngFor="let member of addedMembers;">
                                            <div class="guftagu-item-link">
                                                <div class="row g-0 align-items-center">
                                                    <div class="col-9 guftagu-item-link-left">
                                                        <default-name-icon *ngIf="!member.profile_pic" [name]="member.name" [figureClass]="''"></default-name-icon>
                                                        <figure *ngIf="member.profile_pic" class="guftagu-item-fig">
                                                            <img src="{{member.profile_pic}}" alt="dp-2.jpg" />
                                                        </figure>
                                                        <div class="guftagu-item-desc">
                                                            <p class="guftagu-item-desc-title avenirBlack mb-0">
                                                                {{member.name}}
                                                            </p>
                                                            <p class="guftagu-item-desc-title mb-0">
                                                                {{member.username}}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="col-3 guftagu-item-link-right text-right">
                                                        <button *ngIf="groupActionName == 'Create' && (member._id != loggedInUserId)"
                                                            class="btn-transparent primary-text"
                                                            (click)="removeMemberFromGroup(member)"><i
                                                                class="fa fa-times"></i>
                                                        </button>
                                                        <button *ngIf="groupActionName == 'Edit' && (member._id != loggedInUserId)"
                                                            class="btn-transparent primary-text"
                                                            (click)="removeMemberFromGroup(member)"><i
                                                                class="fa fa-times"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <hr /> -->
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- MODAL CREATE TEAMS |END-->
