import { Component, OnInit } from '@angular/core';
import { PassionProjectService } from 'src/app/services/passion-project.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { ReportService } from 'src/app/services/passion-project/report.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-team-view',
  templateUrl: './team-view.component.html',
  styleUrls: ['./team-view.component.scss']
})
export class TeamViewComponent implements OnInit {

  defaultThumbnailPic: string = environment.imageBasePath + environment.userDefaultProfilePic;
  defaultGroupThumbnailPic: string = environment.imageBasePath + environment.userDefaultGroupPic;

  role: string = '';
  userId: string = '';
  loader: boolean = false;
  reportLoader: boolean = false;
  passionProjectId: string = '';
  teamId: string = '';
  teamData: any = null;
  passionProject: any = {};

  projectReports: any[] = [];
  isReportAddAllowed: boolean = false;
  isReportViewAllowed: boolean = true;

  constructor(private passionProjectService: PassionProjectService, private notificationService: NotificationService, private activateRoute: ActivatedRoute, private route: Router, private commonService: CommonService, private reportService: ReportService) { }

  ngOnInit() {

    this.role = localStorage.getItem('role');
    this.userId = localStorage.getItem('user_id');

    this.activateRoute.queryParams.subscribe(params => {
      this.passionProjectId = params.project_id;
      this.teamId = params.team_id;
      this.getTeamData();
      this.getReports();
    });
  }

  getTeamData() {
    this.loader = true;
    this.passionProjectService.getTeamsById(this.passionProjectId, this.teamId)
      .subscribe(res => {
        this.loader = false;
        this.teamData = res.teams[0];
        this.passionProject = this.teamData.projects[0];
                
        this.updateTagColors();
        //CHECK PERMISSIONS
        const findedIndex = this.teamData.members.findIndex(team => team._id === this.userId);
        
        if (findedIndex > -1 || this.passionProject.edit_rights === true) {
          this.isReportAddAllowed = true;
        }
        else {
          this.isReportAddAllowed = false;
        }
      }, err => {
        this.loader = false;
        this.notificationService.showNotification('error', err.message);
      });
  }

  getReports() {
    this.reportLoader = true;
    this.reportService.getReportByTeamId(this.passionProjectId, this.teamId).subscribe(res => {
      this.reportLoader = false;
      if(res.reports) {
        this.projectReports = res.reports;
        this.updateReportTagColors();
      }
    }, err => {
      this.reportLoader = false;
      this.notificationService.showNotification('error', err.message);
    });
  }

  updateTagColors() {
    if (this.passionProject.project_tags) {
      for (let i = 0; i < this.passionProject.project_tags.length; i++) {
        this.passionProject.project_tags[i].bgColor = this.commonService.randomBgColor();
      }
    }
  }

  updateReportTagColors() {
    if (this.projectReports.length) {
      for (let i = 0; i < this.projectReports[0].tags.length; i++) {
        this.projectReports[0].tags[i].bgColor = this.commonService.randomBgColor();
      }
    }
  }

  onAddProjectReport() {
    this.route.navigate(['passion-projects/team/add-report'], { queryParams: { project_id: this.passionProjectId, team_id: this.teamData._id } });
  }

  onEditProjectReport() {
    // this.route.navigate(['passion-projects/team/add-report']);
    this.route.navigate(['passion-projects/team/add-report'], { queryParams: { project_id: this.passionProjectId, team_id: this.teamData._id, report_id: this.projectReports[0]._id } });
  }

  onGoBack() {
    this.route.navigate(['/passion-projects/view'], { queryParams: { id: this.passionProjectId } });
  }

  onGetTeamStatus() {
    this.getTeamData();
  }

}
