
<div class="new_UIstyle">
 <!-- Banner Section Start -->
 <section class="banner banner-smBanner" style="background-image: url('assets/images/banner/privacy-policy.png');">
    <div class="container">
        <h1 class="white-text">Privacy Policy</h1>
    </div>
</section>
<!-- Banner Section End -->

<section class="section  privacy-policy-wrap">
    <div class="container">
        <p>Welcome to our E-learning platform "Educollab". We are committed to protecting your privacy and ensuring a safe and secure learning environment. This Privacy Policy and Terms of use governs the collection, use, and disclosure of personal information and outlines the terms and conditions of using our website. By accessing or using our website, you agree to the terms and practices described in this Policy. Please read this document carefully before using our services.
        </p>

        <h5 class="avenirBlack">Collection and Use of Personal Information</h5>

        <p>You understand and agree, as a user of our website, that our website team exclusively controls the registration process. During the registration process, we will gather personal data from you, including your first and last name, email address, and other pertinent information. This information is required to grant you access to our learning materials and services as well as to administer and manage your account.</p>

        <p>We also use cookies to provide a better experience to our users. Cookies are small data files that are placed on your device when you visit our website. We use cookies to maintain your session and to record basic information that is necessary for tracking your activity on our e-learning platform, such as the pages you visit, the courses you view/consume and your interaction with other services offered. We do not use cookies to collect any personally identifiable information.</p>

        <h5 class="avenirBlack">Disclosure of Personal Information</h5>
        <p>We do not sell, rent, or disclose your personal information to third parties, except as necessary to provide our services to you or as required by law. We may disclose your personal information to our service providers who assist us in operating our e-learning platform and providing our services. These service providers are contractually bound to protect the confidentiality and security of your personal information and are prohibited from using your personal information for any other purpose.</p>

        <h5 class="avenirBlack">Security of Personal Information</h5>
        <p>We take reasonable steps to protect the confidentiality and security of your personal information, including using industry-standard encryption and access controls to protect your information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the internet or method of electronic storage is 100% secure, and we cannot guarantee the absolute security of your personal information.</p>

        <h5 class="avenirBlack">Your Rights and Choices</h5>
        <p>You have certain rights and choices regarding your personal information. You may access, correct, or delete your personal information by logging into your account or contacting us at <a href="mailto:educollab@tatrasdata.com"><u>educollab@tatrasdata.com</u></a>. You may also opt-out of receiving promotional emails from us by following the instructions in the email. Please note that we may still send you administrative emails regarding your account or our services.</p>

        <h5 class="avenirBlack">Changes to this Privacy Policy</h5>
        <p>We may update this Privacy Policy from time to time by posting a new version on our e-learning platform. We encourage you to review this Privacy Policy periodically for any changes. If we make any material changes, we will notify you by email or by posting a notice on our the platform itself.</p>

        <h5 class="avenirBlack">Contact Us</h5>
        <p>If you have any questions or concerns about this Privacy Policy, please contact us at <a href="mailto:educollab@tatrasdata.com">
            <u>educollab@tatrasdata.com</u></a></p>
      </div>
</section>
</div>


