import { Component, OnInit, OnDestroy } from '@angular/core';
import { PassionProjectService } from '../../services/passion-project.service';
import { NotificationService } from '../../services/notification.service';
import { PassionProjects } from '../../model/PassionProject';
import { Router } from '@angular/router';
import { identity, Subscription } from 'rxjs';
import { SharedPassionProjectService } from '../../services/shared-passion-project.service';
import { UserRole } from '../../shared/user-role';
import { finalize } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-projects-list',
  templateUrl: './projects-list.component.html',
  styleUrls: ['./projects-list.component.scss']
})
export class ProjectsListComponent implements OnInit, OnDestroy {

  loader: boolean = false;
  passionProjectList!: PassionProjects[];
  passionProjectListSubscription!: Subscription;

  showSchBx: boolean = false;
  searchText: string = '';
  userRole: UserRole = new UserRole();
  deletePassionProjectSubscription: any;
  filterProjects: PassionProjects[];
  activateTab = 'all';
  activateTab1 = '';

  defaultThumbnailPic: string = environment.imageBasePath + environment.userDefaultProfilePic;
  sidebarStatus: boolean = false;
  role: string = '';
  listType: string = 'all';
  sortingList: any[] = [{ id: 1, name: 'Newest', value: 'newest' }, { id: 2, name: 'Oldest', value: 'oldest' }];
  sortBy: string = 'newest';

  //FOR PAGINATION
  maxPageNoDisplay: number = 10;
  totalDatalength: any = 0;
  pageSize: number = 9;
  page: any = 1;
  isSearchProject: boolean = false;
  is_true:boolean = true

  constructor(
    private _passionProjectService: PassionProjectService,
    private _sharedPassionProjectService: SharedPassionProjectService,
    private ns: NotificationService,
    private router: Router,
    private modalService:NgbModal
  ) { }

  ngOnInit() {
    this.role = this.userRole.userRole;
    if (this.role == 'student' || this.role == 'teacher') {
      this.listType = (this.role == 'student' || this.role == 'teacher') ? 'published' : 'all';
    } else if(this.role == 'super_admin'){
      this.listType = 'published';
    }
    this.getPassionPorjects();
  }

  getPassionPorjects() {
    this.loader = true;    
    this.passionProjectListSubscription = this._passionProjectService
      .passionProjectsList(this.listType, this.sortBy, this.page, this.activateTab)
      .pipe(finalize(() => this.loader = false))
      .subscribe(
        res => {
          this.passionProjectList = res.projects;
          this.filterProjects = this.passionProjectList;
          this.totalDatalength = res.total_pages * this.pageSize;
        },
        err => this.ns.showNotification('error', err.message)
      );
  }

  hasPermission(project) {
    return this.userRole.create_permission(project);
  }

  changeSharedProject(id: string) {
    //this.getData(id);
    this.redirect('/passion-projects/view', id);
  }

  goToEdit(id: string) {
    //this.getData(id);
    this.redirect('/passion-projects/add/', id);
  }

  getData(id) {
    const passionProject = this.passionProjectList.filter(project => project._id === id);
    if (passionProject.length > 0)
      this._sharedPassionProjectService.changePassionProject(passionProject[0]);
  }

  redirect(path: string, id: string) {
    if (id) this.router.navigate([path], { queryParams: { id } });
    else this.router.navigate([path]);
  }

  openSchBx() {
    this.showSchBx = !this.showSchBx;
  }

  questionModel = null;
  openModelDeletePro(deletePro) {
    this.questionModel = this.modalService.open(deletePro, { size: "sm", centered: true });
  }

  closeProPopup() {
    this.questionModel.close();
    this.getPassionPorjects();
  }

  

  deleteProject(id: string) {
      this.loader = true;
      this.deletePassionProjectSubscription = this._passionProjectService
        .deletePassionProject(id)
        .pipe(finalize(() => this.loader = false))
        .subscribe(
          res => {
            // this.ns.showNotification('success', res.message);
            this.ns.showNotification('success', 'Project deactivated successfully');
            this.page = 1;
            this.questionModel.close();
            this.getPassionPorjects();
          },
          err => this.ns.showNotification('error', err.message)
        );
  }

  onViewAllProject(selectedTab) {
    console.log(selectedTab);
    
    this.filterProjects = [];
    this.activateTab = selectedTab;    
    this.page = 1;
    this.getPassionPorjects();
  }

  onViewAllProject_admin(listType) {    
    this.activateTab = 'all'
    this.filterProjects = [];
    this.listType = listType;    
    this.page = 1;
    this.getPassionPorjects();
  }

  // FILTER SIDEBAR
  openFilterSidebar() {
    this.sidebarStatus = true;
  }

  getFilterStatus(data) {
    if (data.status) {
      this.sidebarStatus = data.sidebarStatus;
    }
  }

  // FOR PAGINATION
  onPageChanged(page: string) {
    this.page = parseInt(page, 10) || 1;
    this.getPassionPorjects();
  }

  // FOR SORT DATA
  onSortData(event) {
    this.sortBy = event.target.value;
    this.page = 1;
    this.getPassionPorjects();
  }

  // FOR SEARCH PROJECT
  onStartSearch(data) {
    this.page = 1;
    if(this.role == 'student' || this.role == 'teacher'){
      this.listType = (this.role == 'student' || this.role == 'teacher') ? 'published' : 'all';
    }
    this.sortBy = 'newest';
    this.searchText = data.searchText;
    if (this.searchText) {
      this.isSearchProject = true;
      this.getSearchPassionPorjects();
    }
    else {
      this.isSearchProject = false;
      this.getPassionPorjects();
    }
  }

  getSearchPassionPorjects() {
    this.loader = true;
    this.passionProjectListSubscription = this._passionProjectService
      .searchPassionProjectsList(this.searchText)
      .subscribe(
        res => {
          this.loader = false
          this.passionProjectList = res.response
          this.filterProjects = this.passionProjectList;
          this.totalDatalength = res.response.length;
        },
        err => {
          this.loader = false
          this.ns.showNotification('error', err.message);
          console.log(err);
        }
      );
  }

  ngOnDestroy() {
    this.passionProjectListSubscription.unsubscribe();
    if (this.deletePassionProjectSubscription) this.deletePassionProjectSubscription.unsubscribe();
  }
}
