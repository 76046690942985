import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ManageFileUploadService {

  addForm!: any;
  actionRef: string = '';
  editProject: any = {};
  submitProject: boolean = false;

  //TO REMOVE RESOURCES
  allUploadedFiles: any[] = [];
  allDeletedFiles: any[] = [];

  constructor() { }
}
