<div class="new_UIstyle">
<!-- New Code -->
<div class="pageBodyWrap">
    <div>
        <!-- banner section -->
        <div class="banner banner-gradient banner-calendar">
            <div class="container">
                <div class="banner-content">
                    <div class="banner-content-content">
                        <h2 class="white-text avenirBlack">{{courseworks[0]?.title}}</h2>
                        <div class="tags-badges mb-4" *ngIf="courseworks[0]?.tag.length > 0">
                           <span class="white-text">Tags: </span> 
                            <span *ngFor="let item of courseworks[0]?.tag" class="badge badge-light badge-primary" [ngStyle]="{'background-color': item.bgColor ? item.bgColor : 'white', 'color': true ? '#FFFFFF': '#FFFFFF'}">{{item.tag}}</span>
                        </div>
                        <div class="tags-badges mb-4" *ngIf="courseList.length > 0">
                            <span class="white-text">Course<span *ngIf="courseList.length > 1">s</span>: </span> 
                            <span *ngFor="let course of courseList" class="badge badge-light badge-primary">{{course.course}}</span>
                        </div>
                        <p class="white-text">Status: 
                            <strong class="ms-2 primary-text" *ngIf="courseworks.length && courseworks[0].publish == true">{{'Published'}}</strong>
                            <strong class="ms-2 primary-text" *ngIf="courseworks.length && courseworks[0].publish == false">{{'Unpublished'}}</strong>
                        </p>
                        <p class="white-text">Coursework Timeline: <strong class="ms-2 primary-text">
                            {{courseworks[0]?.course_work_timeline}} {{courseworks[0]?.course_work_timeline > 1 ? 'days' : 'day'}}
                        </strong></p>
                    </div>
                    <div class="banner-content-action btn-groups">
                        <button class="btn btn-primary" (click)="editSelectedCourseWork(courseId)">
                            <i class="fas fa-pencil-alt" aria-hidden="true"></i> Edit</button>
                        <button class="btn btn-primary" (click)="onGoBack(courseId)">
                            <i class="fas fa-arrow-left"></i> Back</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="section course-work-detail-wrap courseWorkView">
            <div class="container" [ngClass]="{submitting : loader}">

                <div class="spinner-border text-warning formLoader" role="status" *ngIf="loader">
                    <span class="sr-only">Loading...</span>
                </div>

                <div>
                    <div class="course-work-detail-elem course-work-info course-work-detail-elem1"
                      >
                        <p class="lead descr_data1" *ngIf="courseworks[0]?.course_work_description" [innerHTML]="courseworks[0]?.course_work_description"></p>
                    </div>
                </div>

            <hr class="mb-0"/>

            <!-- My RESOURCES |START -->
            <div class="learning-course-items-wrap learning-course-resources-wrap mt-4" *ngIf="courseworks && courseworks[0]?.course_work_resources.length > 0">
                <h5 class="primary-text avenirBlack">Resources</h5>
                <div class="learning-course-item" *ngFor="let resource of courseworks[0]?.course_work_resources">
                    <div class="learning-course-link">
                        <div class="learning-course-elem-left">
                            <p class="learning-course-link-title">
                                <span class="title-icon">
                                    <i class="fas fa-folder"></i></span>
                                <strong>{{resource.resource_title}}</strong>
                            </p>
                        </div>
                        <div class="learning-course-elem-right">
                            <ul class="list-icon-wrap">
                                <li>
                                    <a href="javascript:void(0);" class="learning-course-download" (click)="openResource(resource.resource_url)">
                                        <i class="fas fa-download"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <hr *ngIf="courseworks && courseworks[0]?.course_work_resources.length > 0">
            <!-- My RESOURCES |END -->

            <!-- My submission start -->
            <div class="course-work-detail-elem course-work-submission-wrap">               

                <h5 class="avenirBlack primary-text">Submissions</h5>

                <ngb-accordion #submissions="ngbAccordion" [closeOthers]="true" activeIds="active"
                    class="accordionCustom">
                    <ngb-panel *ngFor="let course_works of courseworks_submission; let i = index">

                        <ng-template ngbPanelHeader>
                            <div class="d-flex panelHeaderButton panelHeaderActionBtn">
                                <div class="panelHeaderElem panelHeaderLeft">
                                    <button ngbPanelToggle class="ngbPanelToggleBtn" id="ngbPanelToggle1">
                                        <span class="acc-headerTitle h6 avenirBlack d-block mb-0">
                                            <!-- {{course_works | json}} -->
                                            {{course_works.submission_report}}
                                        </span>
                                        <div class="learning-course-elem-right">
                                            <p class=" mb-0">Deadline: <strong class="primary-text">
                                                    {{course_works.days_of_completion}} {{course_works.days_of_completion > 1 ? 'days' : 'day'}}
                                                </strong></p>
                                        </div>
                                        <span class="acc-headerAngel"><i class="fas fa-angle-down"></i></span>
                                        <p class="mt-4"><span>Marks: </span><span class="marks_course">
                                            <strong>{{course_works.total_number_of_percentage}}%</strong></span>
                                        </p>
                                    </button>
                                </div>
                            </div>
                        </ng-template>


                        <ng-template ngbPanelContent>
                            <div class="panelContentWrap">
                                <div class="panelHeaderElem rubric_des">
                                    {{course_works.rubric}}
                                </div>                                
                            </div>
                        </ng-template>
                    </ngb-panel>

                </ngb-accordion>
            </div>
            <!-- My submission end -->

        </div>
       </div>

    </div>

</div>
</div>
