<div class="banner">
    <div class="container text-center">
        <h1>Guftagu</h1>
    </div>
</div>
<div class="pageBody subPage" [ngClass]="{submitting : loader}">
    <div class="spinner-border text-warning formLoader" role="status" *ngIf="loader">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="row">
        <div class="col-12 col-sm-4">
            <div class="userListPanel">
                <div class="schFrndsBar">
                    <i class="fas fa-search"></i>
                    <input type="text" (input)="Search_user($event.target.value)" [placeholder]="'Search '+ Friends">
                </div>
                <div class="frndsListWrap">
                    <div class="filterBar">
                        <button type="button" [ngClass]="{'active':chat_type == 'single'}"
                            (click)="chat_info_type('single')">Chats</button>
                        <button type="button" [ngClass]="{'active':chat_type == 'group'}"
                            (click)="chat_info_type('group')">Groups</button>
                    </div>

                    <!-- <button type="button" 
                            (click)="refresh()"><i class="fas fa-refresh"></i></button> -->
                    <div *ngIf="chat_type == 'single'">
                        <div class="listCell" *ngFor="let pc of user_list;">
                            <div class="prBoxHldr">
                                <div class="prBoxWrap d-flex justify-content-between align-items-center">
                                    <div class="prBox" *ngIf="pc.avatar_url">
                                        <img [src]="pc.avatar_url" alt="">
                                    </div>


                                    <div class="prBox" [style.background-color]="pc.color" style="color:white"
                                        *ngIf="pc.avatar_url == ''">
                                        <label class="m-0">{{getName(pc)}}</label>
                                    </div>
                                    <div class="txtPart">
                                        <div class="user_chat_detail">
                                            <p><label class="userLbl text-truncate">{{show_name(pc) |
                                                    titlecase}}</label>
                                                <span class="badge badge-secondary  mr-2">{{pc.username}}</span>
                                                <span class="badge badge-secondary">{{pc.organisation_name}}</span>
                                            </p>
                                            <div class="rap_date_count">
                                                {{room_id != pc.room_id}}
                                                <span class="badge badge_color"
                                                    *ngIf="room_id != pc.room_id">{{get_count(pc)}}</span>
                                            </div>
                                        </div>
                                        <div>
                                            <button class="mr-2" type="button" (click)="get_connected(pc)"
                                                *ngIf="pc.request_status == 'not sent'">Send Request
                                            </button>
                                            <button class="mr-2" type="button"
                                                (click)="view_room(pc.room_id, pc, 'single')" *ngIf="pc.room_status">
                                                Chat with me</button>
                                            <div class="d-inline-block" *ngIf="accep_decline(pc.request_status)">
                                                <button class="mr-2" type="button"
                                                    (click)="receive_request(pc,'accept')">Accept
                                                </button>
                                                <button class="mr-2" type="button"
                                                    (click)="receive_request(pc, 'decline')">
                                                    Decline</button>
                                            </div>
                                            <span class="badge badge-secondary" *ngIf="pc.request_status == 'pending'">
                                                Request Sent</span>

                                            <div class="d-inline-block" *ngIf="add_in_group">
                                                <button class="mr-2" type="button" *ngIf="get_from_list(pc) == -1"
                                                    (click)="Add_in_group(pc,'add')">Add In Group
                                                </button>
                                                <span class="badge badge-secondary" *ngIf="get_from_list(pc) > -1">
                                                    Added </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="chat_type == 'group'">
                        <div class="listCell" *ngFor="let group_name of group_lists; let group_index = index">

                            <div class="prBoxHldr">
                                <div class="prBoxWrap d-flex justify-content-between align-items-center">
                                    <div class="prBox">
                                        <img [src]="get_img_url(group_name)" alt="">
                                    </div>
                                    <div class="txtPart">
                                        <div class="user_chat_detail">
                                            <p><label class="userLbl text-truncate justify_group">{{group_name.name |
                                                    titlecase}}
                                                    <div class="rap_date_count">
                                                        <span class="badge badge_color"
                                                            *ngIf="room_id != group_name.room_id">{{get_count(group_name)}}</span>
                                                    </div>
                                                </label>
                                            </p>
                                        </div>


                                        <p *ngIf="get_msg_length(group_name)">
                                            <span class="badge badge-secondary">last message :
                                                {{group_name.messages[0].message | slice:0:50}}</span>
                                            <small *ngIf="get_msg_length(group_name)" class="d-block mt-1">
                                                {{date(group_name.messages[0].created_at, 'date')}}</small>
                                        </p>
                                        <div>

                                            <button type="button"
                                                (click)="view_room_group(group_name.room_id, group_name)">
                                                Chat with me</button>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
                <div class="text-center pt-3">
                    <button type="button" *ngIf="add_in_group == true;" class="btn btn-danger rounded-pill createGr"
                        (click)="back()">Back
                    </button>
                    <button type="button" *ngIf="add_in_group == false;" class="btn btn-danger rounded-pill createGr"
                        (click)="createGroup()">Create
                        New Group</button>
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-8 order-sm-1">

            <!-- chat screen and shwoing added member in the group-->
            <div *ngIf="chatConvScreen1">
                <div class="groupChatBar d-flex align-items-center justify-content-between" *ngIf="show_group_name">
                    <div class="d-flex align-items-center">
                        <div class="prBox">
                            <img *ngIf="group_details.group_photo.avatar_url == ''"
                                src="assets/images/svg/group-icon-circle.svg" alt="">

                            <img *ngIf="group_details.group_photo.avatar_url"
                                [src]="group_details.group_photo.avatar_url" alt="">
                        </div>
                        <div class="flex-fill pl-3">
                            <h2 class="text-truncate">{{group_details.name}}</h2>
                            <!-- <div class="gcNames text-truncate" style="max-width:100%">
                                <span *ngFor="let memeber of group_details.room_members">
                                    &nbsp; {{memeber.username}}</span>
                            </div> -->
                        </div>
                    </div>
                    <div *ngIf="group_details.room_status != 'deactivate'">
                        <div ngbDropdown class="optDDBox">
                            <i ngbDropdownToggle class="fas fa-ellipsis-v"></i>
                            <div ngbDropdownMenu>
                                <button ngbDropdownItem (click)="openGInfo()">Group Info</button>
                                <button ngbDropdownItem (click)="exit_group(group_details)">Exit Group</button>
                                <button ngbDropdownItem class="delete_group"
                                    (click)="delete_group(group_details)">Delete Group</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="groupChatBar d-flex align-items-center justify-content-between" *ngIf="username">
                    <div class="d-flex align-items-center">
                        <div class="prBox" *ngIf="avatar_url">
                            <img [src]="avatar_url" alt="">
                        </div>
                        <div class="prBox" [style.background-color]="avatar_color" style="color:white"
                            *ngIf="avatar_url == ''">
                            <label class="m-0">{{my_name}}</label>
                        </div>
                        <div class="pl-3">
                            <h2>{{username}}</h2>
                        </div>
                    </div>
                </div>

                <div class="msgWritingPanel" style="height: 100%;">
                    <div class="prConvWrap" #scrollMe [scrollTop]="get_height(scrollMe.scrollHeight, group_details)">
                        <div class="convBox selectedUser" #itemMsg *ngFor="let mes of get_message(); let i = index">
                            <!-- <label class="tsBar" *ngIf="i ==count"><span>Unread Message</span></label> -->
                            <label class="tsBar" *ngIf="all_date[i]"><span>{{all_date[i]}}</span></label>
                            <div class="txtHldr" [id]="'chat_msg'+ i" *ngIf="user_id != mes.user_id">
                                <div class="chatConvViewBx">
                                    <b>{{mes.sender_name}}</b>
                                    <div class="chatMsgHar">
                                        <div class="chatMsgHarMsg" *ngIf="mes.message_type == 'text'">
                                            <p class="mb-0">{{mes.message}}</p>
                                        </div>
                                        <div class="chatMsgHarFigBlk" *ngIf="mes.message_type == 'image'">
                                            <figure class="chatMsgHarFig" *ngFor='let img of mes.urls'>
                                                <img [src]="img.url" height="150" width="100px" class="chatMsgHarImg"
                                                    (click)="openImgModal(view_image, img)">
                                            </figure>
                                        </div>
                                        <div class="chatMsgHarFigBlk" *ngIf="mes.message_type == 'file'">
                                            <div class="chatMsgHarFig" *ngFor="let img of mes.urls; let i=index">
                                                <span class="chatMsgHarDoc" [title]="img.file_name"
                                                    (click)="openInNewWindow(img)">
                                                    <i class="{{get_path(img)}}"></i>
                                                </span>
                                            </div>

                                        </div>
                                        <div class="chatFooterBlock">
                                            <div class="chatFooterLeft text-right">
                                                <p><span> {{date(mes.created_at, 'time')}}</span></p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="convBox mySelf" *ngIf="user_id == mes.user_id">
                                <div class="txtHldr">
                                    <div class="chatConvViewBx">
                                        <b>You</b>
                                        <div class="chatMsgHar" style="word-wrap: break-word;">
                                            <div class="chatMsgHarMsg" *ngIf="mes.message_type == 'text'">
                                                <p class="mb-0">{{mes.message}}</p>
                                            </div>
                                            <div class="chatMsgHarFigBlk" *ngIf="mes.message_type == 'image'">
                                                <figure class="chatMsgHarFig" *ngFor='let img of mes.urls'>
                                                    <img [title]="img.file_name" [src]="img.url" height="150"
                                                        width="100px" class="chatMsgHarImg"
                                                        (click)="openImgModal(view_image, img)">
                                                </figure>
                                            </div>
                                            <div class="chatMsgHarFigBlk" *ngIf="mes.message_type == 'file'">
                                                <div class="chatMsgHarFig" *ngFor="let img of mes.urls; let i=index">
                                                    <span class="chatMsgHarDoc" [title]="img.file_name"
                                                        (click)="openInNewWindow(img)">
                                                        <i class="{{get_path(img)}}"></i>
                                                    </span>
                                                </div>

                                            </div>
                                            <div class="chatFooterBlock">
                                                <div class="chatFooterLeft">
                                                    <p><span> {{date(mes.created_at, 'time')}}</span></p>
                                                </div>
                                                <div class="chatFooterRight">
                                                    <span *ngIf="mes.is_read"><i class='fas fa-check-double'></i></span>
                                                    <span *ngIf="!mes.is_read"><i class='fas fa-check'></i></span>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="message"></div>
                    </div>

                    <div class="editorWrap" *ngIf="room_id && group_details.room_status != 'deactivate'">
                        <form action="" #message="ngForm">
                            <button type="button" (click)="attachToggleShow()" placement="top"
                                ngbTooltip="Add Attachment" class="btn btn-primary btn-rounded btn-attachment"><i
                                    class="fas fa-link"></i></button>
                            <div class="attached-block" *ngIf="isShown">
                                <div class="custom-file">
                                    <input type="file" multiple accept="image/png,image/jpeg,image/bmp,image/gif"
                                        (change)="onFileChange('chat-files',$event, view_image, 'image')"
                                        class="custom-file-input" id="customFileLangHTML">
                                    <label class="custom-file-label" for="customFileLangHTML">
                                        <i class="fas fa-image"></i>
                                        <small>Photos</small>
                                    </label>
                                </div>
                                <div class="custom-file">
                                    <input type="file" multiple accept=".doc,.docx,application/msword,.csv,.txt,.pdf"
                                        (change)="onFileChange('chat-files',$event, view_image, 'doc')"
                                        class="custom-file-input" id="customFileLangHTMLDoc">
                                    <label class="custom-file-label" for="customFileLangHTMLDoc">
                                        <i class="far fa-file-alt"></i>
                                        <small>Documents</small>
                                    </label>
                                </div>
                                <!-- <div class="custom-file">
                                    <input type="file" class="custom-file-input" id="customFileLangHTML">
                                    <label class="custom-file-label" for="customFileLangHTML">
                                        <i class="fas fa-headphones-alt"></i>
                                        <small>Audio</small>
                                    </label>
                                </div> -->
                            </div>
                            <div *ngIf="group_details.room_status != 'deactivate'">
                                <input type="text" class="form-control" placeholder="Enter your text" id="message"
                                [(ngModel)]="message_view" name="message_view" (keydown.enter)="send_message()">
                            <button type="button" (click)="send_message()" class="btn btn-primary  btn-rounded btnTxt"
                                ngbTooltip="Send"><i class="far fa-paper-plane"></i></button>
                            </div>
                            
                        </form>
                    </div>
                </div>
            </div>

            <!-- end chat screen and shwoing added member in the group-->


            <!-- added user in group will show here  after next you will add group name and icon and description of the group-->

            <div class="gchatOthersPanel" *ngIf="gchatScreen3">
                <div class="groupChatBar d-flex align-items-center justify-content-between">
                    <div class="d-flex align-items-center">
                        <div class="prBox">
                            <img src="assets/images/svg/group-icon-circle.svg" alt="">
                        </div>
                        <div class="pl-3">
                            <h2 class="mb-0">Add users in your group</h2>
                        </div>
                    </div>
                    <!-- <div>
                        <div ngbDropdown class="optDDBox">
                            <i ngbDropdownToggle class="fas fa-ellipsis-v"></i>
                            <div ngbDropdownMenu>
                                <button ngbDropdownItem (click)="openGInfo()">Group Info</button>
                                <button ngbDropdownItem>Exit Group</button>
                                <button ngbDropdownItem>Delete Group</button>
                            </div>
                        </div>
                    </div> -->
                </div>
                <div class="inrLayer">
                    <div class="mebersList scrn3">
                        <div class="mListLoop d-flex" *ngFor="let member of add_user_group; let member_index = index">
                            <div class="d-flex align-items-center">
                                <div class="prBox" *ngIf="member.avatar_url">
                                    <img [src]="member.avatar_url" alt="">
                                </div>
                                <div class="prBox" [style.background-color]="member.color" style="color:white"
                                    *ngIf="member.avatar_url == ''">
                                    <label>{{getName(member)}}</label>
                                </div>
                                <div class="m-0 flex-fill">
                                    {{member.username}}<br>
                                    <span class="badge badge-secondary">
                                        {{member.name}} {{member.last_name}} </span><br>
                                    <span class="badge badge-secondary">
                                        {{member.organisation_name}} </span>
                                </div>
                            </div>
                            <div>
                                <span class="removeMember" ngbTooltip="Remove"
                                    (click)="remove_from_group(member,member_index)">&times;</span>
                            </div>
                        </div>

                    </div>
                    <div class="text-right" *ngIf="add_user_group?.length > 0">
                        <button class="btn btnNxt rounded-pill" (click)="next2Screen4()">
                            Next<i class="fas fa-chevron-right ml-2"></i>
                        </button>
                    </div>
                </div>
            </div>

            <!-- end user in group will show here  after next you will add group name and icon and description of the group-->


            <!-- after next you will add group name and icon and description of the group-->

            <div class="gchatOthersPanel" *ngIf="gchatScreen4">
                <div class="inrLayer">
                    <span class="closeBtn" (click)="closeGroupScreen()">&times;</span>
                    <figure class="addAvatar">
                        <div class="input--file">
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                    <circle cx="12" cy="12" r="3.2" />
                                    <path
                                        d="M9 2l-1.83 2h-3.17c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-12c0-1.1-.9-2-2-2h-3.17l-1.83-2h-6zm3 15c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
                                    <path d="M0 0h24v24h-24z" fill="none" />
                                </svg>
                            </span>
                            <input name="Select File" style=" padding-top: 76%;
                            padding-left: 20%;" accept="image/png, image/jpeg"
                                (change)="add_group_icon($event, 'new_group')" type="file" />
                        </div>
                        <img class="imageOutput" *ngIf="group_icon_url" [src]="group_icon_url">
                        <figcaption class="addGP d-flex">Add Group Photo</figcaption>
                    </figure>
                    <div class="grpName">
                        <input class="inputFld" [(ngModel)]="group_name" type="text" placeholder="Add Group name">
                    </div>
                    <h3>Added Members</h3>
                    <div class="mebersList">

                        <div class="mListLoop d-flex" *ngFor="let member of add_user_group; let member_index = index">
                            <div class="d-flex align-items-center">
                                <div class="prBox" *ngIf="member.avatar_url">
                                    <img [src]="member.avatar_url" alt="">
                                </div>
                                <div class="prBox" [style.background-color]="member.color" style="color:white"
                                    *ngIf="member.avatar_url == ''">
                                    <label class="m-0">{{getName(member)}}</label>
                                </div>
                                <div class="flex-fill">
                                    <label class="m-0">{{member.username}}</label><br>
                                    <span class="badge badge-secondary">
                                        {{member.name}} {{member.last_name}} </span><br>
                                    <span class="badge badge-secondary">
                                        {{member.organisation_name}} </span>
                                </div>
                            </div>
                        </div>

                        <div class="mListLoop d-flex">
                            <div>
                                <div class="prBox">
                                    <img *ngIf="admin_url" [src]="admin_url" alt="">
                                </div>
                                <div class="prBox" *ngIf="admin_url == ''">
                                    <label class="m-0">{{role[0] | titlecase}}</label>
                                </div>
                            </div>
                            <div class="flex-fill">
                                <label class="m-0">You</label><br>
                                <span class="badge badge-secondary">Admin</span>
                            </div>
                        </div>
                    </div>
                    <div class="text-right">
                        <button type="button" (click)="create_group()" class="btn btnNxt rounded-pill">
                            Create Group<i class="fas fa-chevron-right ml-2"></i>
                        </button>
                    </div>
                </div>
            </div>


            <!--end next you will add group name and icon and description of the group-->


            <!-- start of Showing group info for the user-->

            <div class="gchatOthersPanel" *ngIf="gchatScreen2">
                <div class="inrLayer">
                    <span class="closeBtn" (click)="closeGroupScreen()">&times;</span>
                    <figure class="addAvatar" *ngIf="!update_group">
                        <div class="input--file" *ngIf="is_admin(group_details)">
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                    <circle cx="12" cy="12" r="3.2" />
                                    <path
                                        d="M9 2l-1.83 2h-3.17c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-12c0-1.1-.9-2-2-2h-3.17l-1.83-2h-6zm3 15c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
                                    <path d="M0 0h24v24h-24z" fill="none" />
                                </svg>
                            </span>
                            <input name="Select File" style=" padding-top: 76%;
                            padding-left: 20%;" (change)="add_group_icon($event, 'description')" type="file"
                                accept="image/png, image/jpeg" />
                        </div>
                        <img class="imageOutput" *ngIf="group_details.group_photo.avatar_url"
                            [src]="group_details.group_photo.avatar_url">
                        <figcaption class="addGP d-flex">Add Group Photo</figcaption>
                    </figure>
                    <div *ngIf="!update_group">
                        <div class="grpName">
                            <input class="inputFld" type="text" [readOnly]="!is_admin(group_details)"
                                [(ngModel)]="info_group_name" (change)="update_group_name(group_details, null)"
                                [value]="group_details.name" placeholder="Add Group name">
                        </div>
                        <div class="grpName">
                            <input class="inputFld" [readOnly]="!is_admin(group_details)" type="text"
                                [(ngModel)]="info_group_description" (change)="update_group_name(group_details, null)"
                                [value]="group_details.group_description.description" placeholder="Description">
                        </div>
                        <h3>Group Members</h3>
                    </div>
                    <div class="mebersList" *ngIf="!update_group">
                        <div class="mListLoop d-flex"
                            *ngFor="let member of group_details.room_members; let member_index = index">
                            <div class="d-flex align-items-center" *ngIf="member.status != 'deactivate'">
                                <div class="prBox" *ngIf="member.avatar_url">
                                    <img [src]="member.avatar_url" alt="">
                                </div>
                                <div class="prBox" [style.background-color]="member.color" style="color:white"
                                    *ngIf="member.avatar_url == ''">
                                    <img src="assets/images/common-avatars/avatar-(14).jpg" alt="">
                                </div>
                                <div class="flex-fill">
                                    <label class="m-0"> {{member.name}} {{member.last_name}}</label><br>
                                    <span class="badge badge-secondary">
                                        {{member.username}} </span><br>
                                    <span class="badge badge-secondary">
                                        {{member.organisation_name}} </span>
                                    <span *ngIf="member.is_admin" class="badge badge-secondary">
                                        Admin </span>
                                </div>
                            </div>
                            <div>
                                <span
                                    *ngIf="is_admin(group_details) && member.is_admin == false && member.status != 'deactivate'"
                                    class="removeMember" ngbTooltip="Remove"
                                    (click)="delete_member(group_details,member)">&times;</span>
                            </div>
                        </div>
                    </div>


                    <div class="mebersList scrn3" *ngIf="update_group">
                        <h3 style="margin-top:3%">Add Group Member</h3>
                        <div class="mListLoop d-flex"
                            *ngFor="let member of update_group_member; let member_index = index">
                            <div class="d-flex align-items-center">
                                <div class="prBox" *ngIf="member.avatar_url">
                                    <img [src]="member.avatar_url" alt="">
                                </div>
                                <div class="prBox" [style.background-color]="member.color" style="color:white"
                                    *ngIf="member.avatar_url == ''">
                                    <label>{{getName(member)}}</label>
                                </div>
                                <div class="m-0 flex-fill">
                                    {{member.username}}<br>
                                    <span class="badge badge-secondary">
                                        {{member.name}} {{member.last_name}} </span><br>
                                    <span class="badge badge-secondary">
                                        {{member.organisation_name}} </span>
                                </div>
                            </div>
                            <div>
                                <span class="removeMember" ngbTooltip="Remove"
                                    (click)="remove_from_group(member,member_index)">&times;</span>
                            </div>
                        </div>
                    </div>

                    <!-- for next sprint work-->
                    <div class="text-center" *ngIf="is_admin(group_details)">
                        <button type="button" *ngIf="!update_group" class="btn btn-danger rounded-pill createGr"
                            (click)="addMember(group_details)">
                            <i class="fas fa-plus mr-2"></i>Add New Member
                        </button>

                        <button type="button" *ngIf="update_group" class="btn btn-danger rounded-pill createGr"
                            (click)="update_member(group_details)">
                            <i class="fas fa-save mr-2"></i>Update Member
                        </button>

                        <!-- <button type="button" class="btn btn rounded-pill createGr" (click)="save_group_info(group_details)">
                            <i class="fas fa-plus mr-2"></i>Update Group
                        </button> -->
                    </div>
                </div>
            </div>

            <!--end of Showing group info for the user-->
        </div>
    </div>

</div>



<ng-template #view_image let-modal>
    <div class="modal-header border-0 p-0">
        <button type="button" class="close" aria-label="Close" (click)="closeimage_scree()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body modalprevImgBody">
        <div class="modalprevImgCondition" *ngIf="!send_files">
            <div class="modalprevAntionBar">
                <button (click)="download_image(image_url.url, image_url.file_name)" class="modalprevImgBtn download-img"><i
                            class="fas fa-cloud-download-alt"></i></button>
            </div>
            <div class="modalprevFig">
                <img [src]="image_url.url" alt="cover-image-sample.jpg" />
            </div>
        </div>
        <div class="gchatOthersPanel uploadAttachMentBlock" *ngIf="send_files">
            <div class="inrLayer">
                <div class="chatConvViewBx">
                    <div class="chatMsgHar chatMsgHarUploadAttach">
                        <div class="chatMsgHarFigBlk">
                            <div class="chatMsgHarCondition" *ngIf="file_type != 'doc'">
                                <figure class="chatMsgHarFig" *ngFor='let url of files'>
                                    <img [src]="url" height="150" width="100px" class="chatMsgHarImg">
                                    <div class="chatMsgHarFigOver" *ngIf="progress > 0">
                                        <div class="progress">
                                            <div class="progress-bar progress-bar-striped bg-success" role="progressbar"
                                                [style.width.%]="progress">
                                            </div>
                                        </div>
                                    </div>
                                </figure>
                            </div>
                            <div class="chatMsgHarCondition" *ngIf="file_type == 'doc'">
                                <div class="chatMsgHarFig" *ngFor='let url of files; let ind = index'>
                                    <span class="chatMsgHarDoc" [title]="all_image_event[ind].name">
                                        <i class="{{get_type(all_image_event[ind])}}"></i>
                                    </span>
                                    <div class="chatMsgHarFigOver" *ngIf="progress > 0">
                                        <div class="progress">
                                            <div class="progress-bar progress-bar-striped bg-success" role="progressbar"
                                                [style.width.%]="progress">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="chatFooterBlock mt-4">
                            <button class="closeBtn btn btn-primary" (click)="closeimage_scree()">Cancel</button>
                            <button type="button" (click)="upload_all_images()" class="btn btn-primary"
                                ngbTooltip="">Send</button>
                        </div>

                    </div>
                </div>

                <!-- <button type="button" (click)="send_images('chat-files')"
                    class="btn btn-primary  btn-rounded btnTxt" ngbTooltip="Send"><i
                        class="far fa-paper-plane"></i></button> -->



            </div>
        </div>
        <!-- <img  [src]="img.url" height="150" width="100px" class="chatMsgHarImg" /> -->
    </div>
</ng-template>