<div class="new_UIstyle">
    <div class="pageBodyWrap">
        <!-- banner section -->
        <div class="banner banner-gradient banner-C-assessment">
            <div class="container">
                <div class="banner-content">
                    <div class="banner-content-content">
                        <h2 class="white-text avenirBlack">Calendar </h2>
                    </div>
                    <div class="banner-content-action form">
                        <select class="form-control calander-type form-control-caret">
                            <option value="Schedule" selected>Schedule</option>
                            <option value="Day">Day</option>
                            <option value="Week">Week</option>
                            <option value="Month">Month</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>

        <section class="schedule-calendar-wrap">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-3 col-md-4 border-right">
                        <div class="schedule-calendar-left">
                            <div class="schedule-calendar-box schedule-calendar-box-calander">
                                <ngb-datepicker class="calender-custom" #dp outsideDays="hidden" navigation="none">
                                </ngb-datepicker>

                                <button type="button" class="btn btn-primary btn-md" (click)="open(createNewEvent)">+
                                    Create</button>
                            </div>

                            <div class="schedule-calendar-box border-top schedule-calendar-box-tasks">
                                <h5 class="avenirBlack mb-3">My Calendars</h5>

                                <div class="checkbox-list form">
                                    <div class="form-check mb-3">
                                        <input class="form-check-input double-check blue-700" type="checkbox"
                                            name="flexRadioDefault" id="check01" checked>
                                        <label class="form-check-label" for="check01">
                                            Task
                                        </label>
                                    </div>
                                    <div class="form-check mb-3">
                                        <input class="form-check-input double-check green-700" type="checkbox"
                                            name="flexRadioDefault" id="check02" checked>
                                        <label class="form-check-label" for="check02">
                                            Reminders
                                        </label>
                                    </div>
                                    <div class="form-check mb-3">
                                        <input class="form-check-input double-check violet-700" type="checkbox"
                                            name="flexRadioDefault" id="check03" checked>
                                        <label class="form-check-label" for="check03">
                                            Sessions
                                        </label>
                                    </div>
                                    <div class="form-check mb-3">
                                        <input class="form-check-input double-check yellow-700" type="checkbox"
                                            name="flexRadioDefault" id="check04" checked>
                                        <label class="form-check-label" for="check04">
                                            Live Sessions
                                        </label>
                                    </div>
                                    <div class="form-check mb-3">
                                        <input class="form-check-input double-check pink-900" type="checkbox"
                                            name="flexRadioDefault" id="check05" checked>
                                        <label class="form-check-label" for="check05">
                                            Assessments
                                        </label>
                                    </div>
                                    <div class="form-check mb-3">
                                        <input class="form-check-input double-check brown-200" type="checkbox"
                                            name="flexRadioDefault" id="check06" checked>
                                        <label class="form-check-label" for="check06">
                                            Coursework
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-9 col-md-8">
                        <div class="schedule-calendar-right">
                            <div
                                class="schedule-calendar-right-title-header d-flex align-items-center justify-content-between border-bottom">
                                <div class="schedule-calendar-header-left">
                                    <h5 class="avenirBlack mb-0">August 2021</h5>
                                </div>
                                <div class="schedule-calendar-header-right">
                                    <button class="arrow-button arrow-button-left"><i
                                            class="fa fa-angle-left"></i></button>
                                    <button class="arrow-button arrow-label">Today</button>
                                    <button class="arrow-button arrow-button-right"><i
                                            class="fa fa-angle-right"></i></button>
                                </div>
                            </div>

                            <div class="schedule-date-lists border-bottom">

                                <!-- Repeat main loop -->
                                <div class="list-row border-bottom">
                                    <div class="row mb-3 mr-0">
                                        <div class="col-lg-3 col-md-4 mb-3 mb-md-0">
                                            <div class="date-box">
                                                <span class="date-number">11</span>
                                                <p class="date-name"><strong>AUG, WED</strong></p>
                                            </div>
                                        </div>
                                        <div class="col-lg-9 col-md-8">
                                            <!-- Repeat time event loop -->
                                            <div class="row mb-4 mb-md-3">
                                                <div class="col-lg-4 col-md-12 mb-2 mb-lg-0">
                                                    <div class="event-timeline-box">
                                                        <p>
                                                            <span class="event-timeline-dot"></span>
                                                            <strong class="event-timeline-time">10:00 - 10:30
                                                                A.M</strong>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="col-lg-8 col-md-12">
                                                    <div class="event-des-box">
                                                        <p class="event-des-title mb-1"><strong>Des Title</strong></p>
                                                        <p class="event-des-des">Lorem ipsum dolor sit amet, consectetur
                                                            adipiscing elit, sed do ut labore et dolore magna aliqua.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row mb-4 mb-md-3">
                                                <div class="col-lg-4 col-md-12 mb-2 mb-lg-0">
                                                    <div class="event-timeline-box">
                                                        <p>
                                                            <span class="event-timeline-dot"></span>
                                                            <strong class="event-timeline-time">10:00 - 10:30
                                                                A.M</strong>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="col-lg-8 col-md-12">
                                                    <div class="event-des-box">
                                                        <p class="event-des-title mb-1"><strong>Des Title</strong></p>
                                                        <p class="event-des-des">Lorem ipsum dolor sit amet, consectetur
                                                            adipiscing elit, sed do ut labore et dolore magna aliqua.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row mb-4 mb-md-3">
                                                <div class="col-lg-4 col-md-12 mb-2 mb-lg-0">
                                                    <div class="event-timeline-box">
                                                        <p>
                                                            <span class="event-timeline-dot"></span>
                                                            <strong class="event-timeline-time">10:00 - 10:30
                                                                A.M</strong>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="col-lg-8 col-md-12">
                                                    <div class="event-des-box">
                                                        <p class="event-des-title mb-1"><strong>Des Title</strong></p>
                                                        <p class="event-des-des">Lorem ipsum dolor sit amet, consectetur
                                                            adipiscing elit, sed do ut labore et dolore magna aliqua.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>


                                <div class="list-row border-bottom">
                                    <div class="row mb-3 mr-0">
                                        <div class="col-lg-3 col-md-4 mb-3 mb-md-0">
                                            <div class="date-box">
                                                <span class="date-number">11</span>
                                                <p class="date-name"><strong>AUG, WED</strong></p>
                                            </div>
                                        </div>
                                        <div class="col-lg-9 col-md-8">
                                            <!-- Repeat time event loop -->
                                            <div class="row mb-4 mb-md-3">
                                                <div class="col-lg-4 col-md-12 mb-2 mb-lg-0">
                                                    <div class="event-timeline-box">
                                                        <p>
                                                            <span class="event-timeline-dot"></span>
                                                            <strong class="event-timeline-time">10:00 - 10:30
                                                                A.M</strong>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="col-lg-8 col-md-12">
                                                    <div class="event-des-box">
                                                        <p class="event-des-title mb-1"><strong>Des Title</strong></p>
                                                        <p class="event-des-des">Lorem ipsum dolor sit amet, consectetur
                                                            adipiscing elit, sed do ut labore et dolore magna aliqua.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row mb-4 mb-md-3">
                                                <div class="col-lg-4 col-md-12 mb-2 mb-lg-0">
                                                    <div class="event-timeline-box">
                                                        <p>
                                                            <span class="event-timeline-dot"></span>
                                                            <strong class="event-timeline-time">10:00 - 10:30
                                                                A.M</strong>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="col-lg-8 col-md-12">
                                                    <div class="event-des-box">
                                                        <p class="event-des-title mb-1"><strong>Des Title</strong></p>
                                                        <p class="event-des-des">Lorem ipsum dolor sit amet, consectetur
                                                            adipiscing elit, sed do ut labore et dolore magna aliqua.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row mb-4 mb-md-3">
                                                <div class="col-lg-4 col-md-12 mb-2 mb-lg-0">
                                                    <div class="event-timeline-box">
                                                        <p>
                                                            <span class="event-timeline-dot"></span>
                                                            <strong class="event-timeline-time">10:00 - 10:30
                                                                A.M</strong>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="col-lg-8 col-md-12">
                                                    <div class="event-des-box">
                                                        <p class="event-des-title mb-1"><strong>Des Title</strong></p>
                                                        <p class="event-des-des">Lorem ipsum dolor sit amet, consectetur
                                                            adipiscing elit, sed do ut labore et dolore magna aliqua.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>



                                <div class="list-row border-bottom">
                                    <div class="row mb-3 mr-0">
                                        <div class="col-lg-3 col-md-4 mb-3 mb-md-0">
                                            <div class="date-box">
                                                <span class="date-number">11</span>
                                                <p class="date-name"><strong>AUG, WED</strong></p>
                                            </div>
                                        </div>
                                        <div class="col-lg-9 col-md-8">
                                            <!-- Repeat time event loop -->
                                            <div class="row mb-4 mb-md-3">
                                                <div class="col-lg-4 col-md-12 mb-2 mb-lg-0">
                                                    <div class="event-timeline-box">
                                                        <p>
                                                            <span class="event-timeline-dot"></span>
                                                            <strong class="event-timeline-time">10:00 - 10:30
                                                                A.M</strong>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="col-lg-8 col-md-12">
                                                    <div class="event-des-box">
                                                        <p class="event-des-title mb-1"><strong>Des Title</strong></p>
                                                        <p class="event-des-des">Lorem ipsum dolor sit amet, consectetur
                                                            adipiscing elit, sed do ut labore et dolore magna aliqua.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row mb-4 mb-md-3">
                                                <div class="col-lg-4 col-md-12 mb-2 mb-lg-0">
                                                    <div class="event-timeline-box">
                                                        <p>
                                                            <span class="event-timeline-dot"></span>
                                                            <strong class="event-timeline-time">10:00 - 10:30
                                                                A.M</strong>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="col-lg-8 col-md-12">
                                                    <div class="event-des-box">
                                                        <p class="event-des-title mb-1"><strong>Des Title</strong></p>
                                                        <p class="event-des-des">Lorem ipsum dolor sit amet, consectetur
                                                            adipiscing elit, sed do ut labore et dolore magna aliqua.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row mb-4 mb-md-3">
                                                <div class="col-lg-4 col-md-12 mb-2 mb-lg-0">
                                                    <div class="event-timeline-box">
                                                        <p>
                                                            <span class="event-timeline-dot"></span>
                                                            <strong class="event-timeline-time">10:00 - 10:30
                                                                A.M</strong>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="col-lg-8 col-md-12">
                                                    <div class="event-des-box">
                                                        <p class="event-des-title mb-1"><strong>Des Title</strong></p>
                                                        <p class="event-des-des">Lorem ipsum dolor sit amet, consectetur
                                                            adipiscing elit, sed do ut labore et dolore magna aliqua.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>




    <!-- Tooltip model -->
    <!-- style is temparay don't need to apply  -->
    <div class="relative">
        <button class="btn btn-primary" (click)="closeTooltip()">tooltip</button>
        <div class="tooltip-boxx" [ngClass]="!closeTooltipVar ? 'd-none':'d-block'">
            <button class="btn-close btn-transparent btn-transparent-primary" (click)="closeTooltip()">X</button>
            <div class="tooltip-item-list">
                <div class="event-timeline-box mb-4">
                    <p class="mb-0">
                        <span class="event-timeline-dot yelow-dot"></span>
                        <strong class="event-timeline-time">10:00 - 10:30 A.M</strong>
                    </p>
                    <p class="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do ut labore et dolore
                        magna aliqua.</p>
                </div>

                <div class="event-timeline-box mb-4">
                    <p class="mb-0">
                        <span class="event-timeline-dot yelow-dot"></span>
                        <strong class="event-timeline-time">10:00 - 10:30 A.M</strong>
                    </p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do ut labore et dolore magna aliqua.
                    </p>
                </div>

                <div class="event-timeline-box mb-4">
                    <p class="mb-0">
                        <span class="event-timeline-dot yelow-dot"></span>
                        <strong class="event-timeline-time">10:00 - 10:30 A.M</strong>
                    </p>
                    <p class="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do ut labore et dolore
                        magna aliqua.</p>
                </div>
            </div>
        </div>
    </div>



    <!-- Model Add Event -->
    <ng-template #createNewEvent let-modal>
        <div class="new_UIstyle" [ngClass]="{submitting : groupProcessing}">
            <div class="create_mod">
                <form>
                    <div class="modal-header">
                        <h5 class="avenirBlack blue-lt-text d-flex d-lg-inline-block mb-0">
                            Add new Event</h5>
                        <button type="button" class="close close_pop" aria-label="Close" (click)="closeModel()">
                            <span aria-hidden="true" class="text-white">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body modalGroupBody crea_mod">
                        <div class="spinner-border text-warning formLoader" role="status" *ngIf="groupProcessing">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="row">
                            <div class="col-md-12 mt-2 form-group">
                                <input type="text" class="form-control form-control-style-2 form-control-lg"
                                    placeholder="Event name*" />
                            </div>
                            <div class="col-md-12 mt-2 form-group">
                                <textarea name="" id="" cols="30" rows="10"
                                    class="form-control form-control-style-2 form-control-textarea"
                                    placeholder="Type the note here..."></textarea>
                            </div>
                            <div class="col-md-12 mt-2 form-group">
                                <input type="date" class="form-control form-control-style-2 form-control-lg"
                                    placeholder="date" />
                            </div>
                            <div class="col-md-6 mt-2 form-group">
                                <input type="time" class="form-control form-control-style-2 form-control-lg"
                                    placeholder="Start time" />
                            </div>
                            <div class="col-md-6 mt-2 form-group">
                                <input type="time" class="form-control form-control-style-2 form-control-lg"
                                    placeholder="End time" />
                            </div>

                            <div class="col-md-12 mt-2 form-group">
                                <h6 class="avenirBlack">Select Catgeory</h6>
                                <div class="row">
                                    <div class="col-lg-4">
                                        <div class="form-check form-check-block bg-blue-block mb-3">
                                            <input class="form-check-input" type="radio" name="flexRadioDefault"
                                                id="checkC01">
                                            <label class="form-check-label" for="checkC01">
                                                Task
                                            </label>
                                        </div>
                                    </div>

                                    <div class="col-lg-4">
                                        <div class="form-check form-check-block bg-green-block mb-3">
                                            <input class="form-check-input" type="radio" name="flexRadioDefault"
                                                id="checkC02">
                                            <label class="form-check-label" for="checkC02">
                                                Reminder
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 form-group">
                                <button class="btn btn-primary btn-lg w-100">Create Event</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </ng-template>
</div>
