<div class="banner">
    <div class="container text-center">
        <h1>Teaching Assistants</h1>
        <p>Assign subscribers to teaching assistants</p>
        <button class="btn btn-primary" (click)="go_to_url('/courses/single-course')"><i
                class="fas fa-arrow-circle-left mr-2"></i></button>
    </div>
</div>

<div class="pageBody">
    <div class="container" [ngClass]="{submitting : loader}">
        <div class="spinner-border text-warning formLoader" role="status" [ngClass]="{submitting : loader}"
            *ngIf="loader">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="row align-items-center mb-3">
            <div class="col-12 col-sm-3 pb-3 pb-sm-0">
                <label class="d-inline-block mb-0 mr-3">Assign by:</label>
                <div class="d-inline-block mr-3">
                    <button type="button" class="btn btn-primary" (click)="generateOptions()"
                        [disabled]="this.mySubscriber(get_course_work_team).length == 0">Shuffle</button>
                </div>
            </div>
            <div class="col-12 col-sm-6 text-center pb-3 pb-sm-0">
                <button type="button" class="btn btn-link p-0"
                    (click)="go_to_url('/courses/teaching-assistant-report')"><i class="fas fa-eye mr-3"></i>View
                    Report</button>
            </div>
            <div class="col-12 col-sm-3 text-right pb-3 pb-sm-0">
                <button type="button" class="btn btn-primary" (click)="submit()"><i
                        class="fas fa-save mr-2"></i>Save</button>
            </div>
        </div> <!-- \ End: Top Assign Bar -->

        <div id="manualAssignment" *ngIf="assignType == 'manual'" cdkDropListGroup>
            <p class="text-center text-muted"><small>(Drag the subscriber(s) into assistant boxe(s) below to assign
                    manually)</small></p>
            <div class="genBxHldr mb-4">
                <h2>Subscribers</h2>
                <div class="bxWrap">
                    <div class="row no-gutters dndList" cdkDropList #subsList="cdkDropList"
                        [cdkDropListData]="mySubscriber(get_course_work_team)" [cdkDropListConnectedTo]="[assistList]"
                        (cdkDropListDropped)="drop($event)">
                        <!-- <div> -->
                        <div class="col-12 col-lg-4 mb-2 dndBx" *ngFor="let item of mySubscriber(get_course_work_team)"
                            cdkDrag>
                            <figure><img [src]='item?.profile_pic' onerror="this.src='assets/images/avatar-default.jpg'"
                                    alt="profile_pic"></figure>
                            <div class="bxD">
                                <h4>{{item?.name}}</h4>
                                <ng-container *ngFor="let name of get_member(item)">
                                    <span *ngIf="name.is_subscriber" ngbTooltip="Subscriber"
                                        class="badge badge-primary mr-2 mb-2">
                                        {{name.username}},
                                        {{name.organisation}}
                                    </span>
                                    <span *ngIf="!name.is_subscriber" ngbTooltip="Unsubscribe the course"
                                        class="badge badge-danger mr-2 mb-2">
                                        {{name.username}},
                                        {{name.organisation}}
                                    </span>
                                </ng-container>
                            </div>
                        </div>
                        <!-- </div> -->

                    </div>
                </div>
            </div> <!-- \ End: Subscribers Box Full Area -->

            <div class="asstFltContainer" *ngIf="getAssignList != undefined && getAssignList.length > 0">

                <div class="col-12 col-lg-4" *ngFor="let teachassis of getAssignList">
                    <div class="genBxHldr mb-4">
                        <h2 [ngbTooltip]="teachassis.username" container="body"> {{teachassis.username}}</h2>
                        <div class="bxWrap">
                            <div class="row no-gutters dndList" cdkDropList #assistList="cdkDropList"
                                [cdkDropListData]="teachassis.assignees" [cdkDropListConnectedTo]="[subsList]"
                                (cdkDropListDropped)="drop($event)">
                                <div class="mb-2 dndBx" *ngFor="let item of teachassis.assignees" cdkDrag>
                                    <!-- <div class="dndPH" *cdkDragPlaceholder></div> -->
                                    <figure><img [src]='item?.profile_pic'
                                            onerror="this.src='assets/images/avatar-default.jpg'" alt="profile_pic">
                                    </figure>
                                    <div class="bxD">
                                        <h4>{{item?.name}}</h4>
                                        <p>{{item?.organisation}}</p>
                                        <ng-container *ngFor="let name of get_member(item)">
                                            <span *ngIf="name.is_subscriber" ngbTooltip="Subscriber"
                                                class="badge badge-primary mr-2 mb-2">
                                                {{name.username}},
                                                {{name.organisation}}
                                            </span>
                                            <span *ngIf="!name.is_subscriber" ngbTooltip="Unsubscribe the course"
                                                class="badge badge-danger mr-2 mb-2">
                                                {{name.username}},
                                                {{name.organisation}}
                                            </span>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> <!-- End: Loop Assistant Boxes -->
            </div>
        </div>
    </div>
</div>