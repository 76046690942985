import { Component, OnInit } from '@angular/core';
import { QuestionService } from 'src/app/services/question.service';
import { my_questions, allCategory } from 'src/app/model/course.model';
import { Router, ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from 'src/app/services/notification.service';
import { CourseService } from 'src/app/services/course.service';
import { MatDialog } from '@angular/material';



@Component({
  selector: 'app-my-questions',
  templateUrl: './my-questions.component.html',
  styleUrls: ['./my-questions.component.scss']
})
export class MyQuestionsComponent implements OnInit {
  subjectOfQuestion: allCategory[];
  page_info = 'MYQUES'

  myquestions: my_questions[];
  loader = false;
  askQuestionForm: FormGroup;
  questionModel = null;
  pageName = '';
  titleOfPage = '';
  role = '';
  totalRecordeP: any;
  pageP: number = 1;

  selectedPic: any = [];
  selectedVideo: any = [];
  selectedDoc: any = [];
  picName = "No Picture selected";
  videoName = "No Video selected";
  questionId: string;
  question_Id: string;
  delete_image = false;
  delete_video = false;
  onEdit = false;
  onChangeSub: boolean = false;
  categoryValue;
  routerUrl: any;
  constructor(
    private questionService: QuestionService,
    private router: Router,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private ns: NotificationService,
    private courseService: CourseService,
    // private dialog:MatDialog
  ) { }

  ngOnInit() {
    let sp = this.router.url.split('?')
    this.routerUrl = sp[0];
    this.role = localStorage.getItem('role')
    if (this.role != 'student' && this.router.url == '/My-Questions') {
      this.router.navigate(['/']);
    } else if (this.role == 'student' && this.router.url == '/Student-Questions' && this.pageName == '/Student-Questions') {
      this.router.navigate(['/']);
    }
    this.pageName = this.router.url;
    const title = this.pageName.split('/');
    this.titleOfPage = title[1];
    if (this.titleOfPage == "My-Questions") {
      this.titleOfPage = "My Questions";
      this.page_info = 'MYQUES';
    } else if (this.titleOfPage == "Student-Questions") {
      this.titleOfPage = "Student Questions";
      this.page_info = 'STUQUES'
    }
    this.getAllCourses();
    if (this.role != 'student' && this.router.url == '/Student-Questions') {
      this.getAllQuestions();
    }
  }


  currentPageNo(pageNo) {
    this.pageP = pageNo;
    if (pageNo && !this.onChangeSub) {
      // this.allQuestionsList = [];
      if (this.role != 'student') {
        this.getAllQuestions();
      }
    } else if (pageNo && this.onChangeSub) {
      this.onChangeSubject2(this.categoryValue);
    }
  }

  openQuestion(question) {
    this.askQuestionForm = this.fb.group({
      subject: ['', Validators.required],
      coins: ['', Validators.required],
      yourQuestion: ['', Validators.required],
      picture: [''],
      video: ['']
    })

    // this.askQuestionForm.reset();
    this.onEdit = false;
    this.questionModel = this.modalService.open(question);
  }
  selectedValue;
  getMyQuestions() {
    this.loader = true;
    if (this.role == 'student' && this.router.url == '/My-Questions') {
      if (this.pageName == '/My-Questions') {
        this.loader = true;
        this.questionService.getMyQuesList().subscribe(res => {
          this.myquestions = res.response;
          this.loader = false;
        })
      }
    } else if (this.role != 'student' && this.router.url == '/Student-Questions' && this.pageName == '/Student-Questions') {
      if (!this.categoryId) {
        this.selectedValue = 'select'
      }
      // this.onChangeSubject(this.selectedValue);
    } else {
      this.router.navigate(['/']);
    }
  }


  EditQuestion(question_id: any) {
    if (this.role == 'student' && this.router.url == '/My-Questions' || this.role == 'super_admin' && this.router.url == '/Student-Questions' || this.role == 'teacher' && this.router.url == '/Student-Questions') {
      this.router.navigate(['/edit-ask-question'], { queryParams: { questionId: question_id, info: this.page_info } });
    }
  }


  getQuestionsAnswer(questionId) {
    this.router.navigate(['/question-details'], { queryParams: { questionId: questionId, info: this.page_info } });
  }

  // askQuestion() {
  //   if (this.role != 'student') {
  //     this.edit_question();
  //     return;
  //   } else {
  //     this.askQuestion_student();
  //   }
  // }

  getAllCourses() {
    this.loader = true;
    this.courseService.categoryAPI().pipe(finalize(() => {
      this.loader = false;
    })).subscribe(data => {
      this.subjectOfQuestion = data;
      this.getMyQuestions();
    });


  }
  categoryId;
  categoriesItem: any = [];
  onChangeSubject(value) {
    this.categoryValue = value;
    this.categoriesItem = value;
    if (value) {
      this.onChangeSub = true;
    }
    this.pageP = 1;
    let is_extracted = false;
    let total_question = 10;
    if (this.role != 'student') {
      if (this.categoryValue != 'select') {
        this.loader = true;
        this.questionService.getAllQuestionsList(is_extracted, this.pageP, total_question, this.categoriesItem).subscribe(res => {
          let resDataLen = res.response.length;
          this.myquestions = res.response.data;
          this.loader = false;
          this.totalRecordeP = (res.response.total_pages * 10);
          if (this.myquestions.length == 0 || this.myquestions.length == null || this.myquestions.length == undefined) {
            this.myquestions = [];
          }
        })
      } else if (this.categoryValue == 'select') {
        this.getAllQuestions();
      }
      // this.questionService.getStudentQuestionsList(body).pipe(finalize(() => {
      //   this.loader = false;
      // })).subscribe(res => {
      //   this.myquestions = res.questions;
      // })
    }
  }

  onChangeSubject2(value) {
    this.categoryValue = value;
    this.categoriesItem = value;
    if (value) {
      this.onChangeSub = true;
    }

    let is_extracted = false;
    let total_question = 10;
    if (this.role != 'student') {
      if (this.categoryValue != 'select') {
        this.loader = true;
        this.questionService.getAllQuestionsList(is_extracted, this.pageP, total_question, this.categoriesItem).subscribe(res => {
          let resDataLen = res.response.length;
          this.myquestions = res.response.data;
          this.loader = false;
          this.totalRecordeP = (res.response.total_pages * 10);
          if (this.myquestions.length == 0 || this.myquestions.length == null || this.myquestions.length == undefined) {
            this.myquestions = [];
          }
        })
      } else if (this.categoryValue == 'select') {
        this.getAllQuestions();
      }
      // this.questionService.getStudentQuestionsList(body).pipe(finalize(() => {
      //   this.loader = false;
      // })).subscribe(res => {
      //   this.myquestions = res.questions;
      // })
    }
  }

  // onPicSelected(event) {
  //   this.selectedPic = event.target.files[0];
  //   this.picName = this.selectedPic.name;
  //   this.delete_image = true;
  // }

  // onVideoSelected(event) {
  //   this.selectedVideo = event.target.files[0];
  //   this.videoName = this.selectedVideo.name;
  //   this.delete_video = true;

  // }
  // openEditQuestion(content, myquestion) {
  //   this.openQuestion(content);
  //   this.onEdit = true;
  //   this.questionId = myquestion._id;
  //   this.askQuestionForm.controls['subject'].setValue(myquestion.course_category_id);
  //   this.askQuestionForm.controls['coins'].setValue(myquestion.coins);
  //   this.askQuestionForm.controls['yourQuestion'].setValue(myquestion.qus_title);
  //   if (myquestion.question_image_path) {
  //     this.picName = "Picture is selected";
  //   }
  //   if (myquestion.question_video_path) {
  //     this.videoName = "Video is selected";
  //   }

  // }

  // delete_videoChange(event) {
  //   this.delete_video = event.target.checked;
  // }
  // delete_imageChange(event) {
  //   this.delete_image = event.target.checked;
  // }

  closeQuestionPopup() {
    this.questionModel.close();
    // this.questionId = null;
  }

  questionData
  openModelDeleteQuestion(deleteQuestion, myquestions) {
    this.questionData = myquestions;
    this.questionModel = this.modalService.open(deleteQuestion, { size: "sm", centered: true });
  }

  delQuestionYes(question_id) {
    this.loader = true;
    this.questionService.deleteQuestion(question_id).pipe(finalize(() => {
      this.loader = false;
    })).subscribe(req => {
      this.ns.showNotification("success", "Question Deleted successfully");
      this.getMyQuestions();
      if (this.onChangeSub) {
        this.onChangeSubject(this.categoriesItem);
      } else if (this.role != 'student') {
        this.getAllQuestions();
      }

    },
      (err) => {
        this.ns.showNotification("error", err.status);
      })
    this.questionModel.close();
  }


  // validation for edit question button
  question_type = 'Ask A Question';
  get_edit_question(myquestion) {
    if (myquestion.answers_count == 0 && this.role == 'student') {
      this.question_type = 'Ask A Question';
      return true;
    } else if (this.role != 'student') {
      this.question_type = 'Edit Question';
      return true;
    }
    return false;
  }
  // teacher can edit the question
  // edit_question() {
  //   this.courseService.teacher_edit_question(this.questionId, this.askQuestionForm.value.yourQuestion).subscribe(updated_question => {
  //     this.ns.showNotification("success", updated_question.message);
  //     this.getMyQuestions();
  //     this.onChangeSubject(this.categoryId);
  //     this.questionModel.close();
  //     this.askQuestionForm.reset();
  //     this.picName = "No Picture selected";
  //     this.videoName = "No Video selected";
  //     // this.getMyQuestions();
  //   },
  //     (err) => {
  //       this.ns.showNotification("error", err.message);
  //     })
  // }
  // ask question by student
  // askQuestion_student() {
  //   if (this.askQuestionForm.invalid) {
  //     this.ns.showNotification("info", "Please enter correct details")
  //     return false;
  //   }

  //   if (Number(this.askQuestionForm.value.coins) > Number(localStorage.getItem('coins'))) {
  //     this.ns.showNotification("info", "You do not have so much coins please check you balance first")
  //     return false;
  //   }

  //   this.loader = true;
  //   if (!this.selectedPic) {
  //     this.selectedPic = null;
  //   }
  //   if (!this.selectedVideo) {
  //     this.selectedVideo = null;
  //   }

  //   if (!this.questionId) {
  //     this.courseService.video_question(
  //       this.askQuestionForm.value,
  //       '',
  //       '',
  //       this.selectedPic,
  //       this.selectedVideo,
  //       this.selectedDoc,
  //       this.askQuestionForm.value.subject,
  //       '',
  //       '').pipe(finalize(() => {
  //         this.loader = false;
  //       })).subscribe(sendQuestion => {
  //         this.ns.showNotification((sendQuestion.Status).toLowerCase(), sendQuestion.message);
  //         this.questionModel.close();
  //         this.askQuestionForm.reset();
  //         this.picName = "No Picture selected";
  //         this.videoName = "No Video selected";
  //         localStorage.setItem('coins', sendQuestion.avail_coin);
  //         this.getMyQuestions();
  //       },
  //         (error) => {
  //           this.ns.showNotification("error", error.message);

  //         })
  //   }
  //   else {
  //     this.questionService.updateQuestion(this.askQuestionForm.value, this.selectedPic, this.selectedVideo, this.questionId, this.delete_image, this.delete_video).pipe(finalize(() => {
  //       this.loader = false;
  //     })).subscribe(updatedQuestion => {
  //       this.ns.showNotification((updatedQuestion.Status).toLowerCase(), updatedQuestion.Message);
  //       this.questionModel.close();
  //       this.askQuestionForm.reset();
  //       this.getMyQuestions();
  //       this.picName = "No Picture selected";
  //       this.videoName = "No Video selected";
  //       this.questionId = null;
  //     }, (error) => {
  //       this.ns.showNotification("error", error.message);

  //     })
  //   }
  // }

  // currentPage = 1;
  totalPages: number;
  itemPerPage: number;
  is_extracted = false;
  total_question: number = 10;
  getAllQuestions() {
    this.loader = true;
    this.questionService.getAllQuestionsList(this.is_extracted, this.pageP, this.total_question, '').pipe(finalize(() => {
    })).subscribe(res => {
      this.myquestions = res.response.data;
      this.loader = false;
      this.itemPerPage = res.response.data.length;
      // this.totalPages = this.itemPerPage * res.response.total_pages;
      this.totalRecordeP = (res.response.total_pages * 10);
    })
  }

  sidebarStatus: boolean = false;
  filterClickEvent() {
    this.sidebarStatus = !this.sidebarStatus;
  }

  sortByQus(value) {
    // console.log(value.target.value)
  }

  //FOR BUILD FIX
  onPicSelected(event) {
    //
  }

  onVideoSelected(event) {
    //
  } 

  delete_imageChange(event) {
    //
  }

  delete_videoChange(event) {
    //
  }

  askQuestion() {
    //
  }

}