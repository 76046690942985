import { Component, OnInit, ChangeDetectionStrategy, ViewChild, TemplateRef } from '@angular/core';

import { startOfDay, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours, isWednesday } from 'date-fns';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import { UserService } from 'src/app/services/user.service';
import { finalize } from 'rxjs/operators';
import RRule from "rrule";
// import moment from "moment-timezone";

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};



@Component({
  selector: 'app-schedule',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss']
})
export class ScheduleComponent implements OnInit {



  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;

  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;

  viewDate: Date = new Date();
  fromDate: string = '';
  toDate: string = '';

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pencil-alt"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      },
    },
    {
      label: '<i class="fas fa-fw fa-trash-alt"></i>',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter((iEvent) => iEvent !== event);
        this.handleEvent('Deleted', event);
      },
    },
  ];

  refresh: Subject<any> = new Subject();
  events = [];
  // events: CalendarEvent[] = [
  //   {
  //     start: subDays(startOfDay(new Date()), 1),
  //     end: addDays(new Date(), 1),
  //     title: 'A 3 day event',
  //     color: colors.red,
  //     // actions: this.actions,
  //     allDay: true,
  //     resizable: {
  //       beforeStart: true,
  //       afterEnd: true,
  //     },
  //     draggable: true,
  //   },
  //   {
  //     start: startOfDay(new Date()),
  //     title: 'An event with no end date',
  //     color: colors.yellow,
  //     // actions: this.actions,
  //   },
  //   {
  //     start: subDays(endOfMonth(new Date()), 3),
  //     end: addDays(endOfMonth(new Date()), 3),
  //     title: 'A long event that spans 2 months',
  //     color: colors.blue,
  //     allDay: true,
  //   },
  //   {
  //     start: addHours(startOfDay(new Date()), 2),
  //     end: addHours(new Date(), 2),
  //     title: 'A draggable and resizable event',
  //     color: colors.yellow,
  //     // actions: this.actions,
  //     resizable: {
  //       beforeStart: true,
  //       afterEnd: true,
  //     },
  //     draggable: true,
  //   },
  // ];

  activeDayIsOpen: boolean = true;
  loader: boolean;
  //eventContent: CalendarEvent<any>; //FOR BUILD FIX - COMMENTED
  eventContent: any;  //FOR BUILD FIX - ADDED
  eventDuration: string;


  constructor(private modal: NgbModal, private userService: UserService) { }

  ngOnInit() {
    this.getEvents(new Date());
  }

  getDateMonthFormat(val) {
    if (val < 10) return '0' + val;
    else return val;
  }

  getWeekDay(dayIndex) {
    return [[RRule.MO], [RRule.TU], [RRule.WE], [RRule.TH], [RRule.FR], [RRule.SA], [RRule.SU]][dayIndex] || '';
  }

  getEvents(dataval) {
    let d = new Date(dataval);
    if (this.view == 'month') {

      let firstDay = new Date(d.getFullYear(), d.getMonth(), 1);
      let lastDay = new Date(d.getFullYear(), d.getMonth() + 1, 0);

      this.fromDate = firstDay.getFullYear() + '-' + this.getDateMonthFormat((firstDay.getMonth() + 1)) + '-' + this.getDateMonthFormat(firstDay.getDate());
      this.toDate = lastDay.getFullYear() + '-' + this.getDateMonthFormat((lastDay.getMonth() + 1)) + '-' + this.getDateMonthFormat(lastDay.getDate());

    } else if (this.view == 'day') {
      this.fromDate = d.getFullYear() + '-' + this.getDateMonthFormat((d.getMonth() + 1)) + '-' + this.getDateMonthFormat(d.getDate());
      this.toDate = d.getFullYear() + '-' + this.getDateMonthFormat((d.getMonth() + 1)) + '-' + this.getDateMonthFormat(d.getDate());
    } else {
      let first = d.getDate() - d.getDay();
      let last = first + 6;

      let firstDay = new Date(d.setDate(first));
      let lastDay = new Date(d.setDate(last));

      this.fromDate = firstDay.getFullYear() + '-' + this.getDateMonthFormat((firstDay.getMonth() + 1)) + '-' + this.getDateMonthFormat(firstDay.getDate());
      this.toDate = lastDay.getFullYear() + '-' + this.getDateMonthFormat((lastDay.getMonth() + 1)) + '-' + this.getDateMonthFormat(lastDay.getDate());

    }
    this.loader = true;
    this.userService.fetchEvents(this.fromDate, this.toDate).pipe(
      finalize(() => {
        this.loader = false;
      })).subscribe((res) => {
        let calendarEvents: any = [];
        res.system_events.forEach(element => {
          let obj: any = {};
          obj.start = new Date(element.start);
          obj.end = new Date(element.end);
          obj.title = element.title;
          obj.description = element.description;
          obj.duration = element.duration;
          obj.color = colors[element.color];

          if (element.duration == 'all_day') obj.allDay = true;
          if (element.hasOwnProperty('recurring')) {
            let recurrObj: any = {};
            if (element.recurring.freq == 'daily') {
              recurrObj.freq = RRule.DAILY;
              if (element.recurring.hour) {
                recurrObj.byhour = element.recurring.hour,
                  recurrObj.byminute = element.recurring.min
              }

            }
            else if (element.recurring.freq == 'weekly') {
              recurrObj.freq = RRule.WEEKLY,
                recurrObj.byweekday = this.getWeekDay(element.recurring.day)
              // recurrObj.byweekday = [RRule.MO] //pending get month method              
            }
            else if (element.recurring.freq == 'monthly') {
              recurrObj.freq = RRule.MONTHLY,
                recurrObj.bymonthday = element.recurring.day
            }
            else {
              recurrObj.freq = RRule.YEARLY,
                // recurrObj.bymonth = moment().month() + 1,
                recurrObj.bymonthday = element.recurring.day

            }
            obj.rrule = recurrObj;
          }
          calendarEvents.push(obj);

        });
        
        this.events = calendarEvents;
        this.refresh.next();
      })
  }
  // Calendar

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: any): void {
    // this.modalData = { event, action };
    this.eventContent = event;
    if (event.duration == 'all_day') {
      if (event.hasOwnProperty('rrule')) {
        let freq =  ['year', 'month', 'week', 'day'][event.rrule.freq] || '';
        let Days: any = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
        let fromDay: any = new Date(this.fromDate);
        fromDay = fromDay.getDay();

        let toDay: any = new Date(this.toDate);
        toDay = toDay.getDay();

        this.eventDuration = 'Every' + freq + ' (' + Days[fromDay] + ' to ' + Days[toDay] + ')';

      } else {
        this.eventDuration = 'All Day';
      }

    } else {
      this.eventDuration = event.duration + ' min';
    }
    this.modal.open(this.modalContent, { size: 'sm' });
  }

  // addEvent(): void {
  //   this.events = [
  //     ...this.events,
  //     {
  //       title: 'New event',
  //       start: startOfDay(new Date()),
  //       end: endOfDay(new Date()),
  //       color: colors.red,
  //       draggable: true,
  //       resizable: {
  //         beforeStart: true,
  //         afterEnd: true,
  //       },
  //     },
  //   ];
  // }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter((event) => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay(e) {
    // console.log(e)
    this.getEvents(e);
    this.activeDayIsOpen = false;
  }


}
