<div class="new_UIstyle">
    <div class="pageBodyWrap passionProjectDetailMain">
        <!-- Banner Section Start -->
        <section class="banner banner-inner single-passion-banner"
            [style.background-image]="'url(' + ('assets/images/banner/mosaic-bg01.png') + ')'">
            <div class="container">
                <div class="banner-content">
                    <h2 class="white-text avenirBlack">{{passionProject?.title | capitalization }}</h2>
                    <div class="tags-badges">
                        <span class="badge badge-green-lt" *ngFor="let item of passionProject?.project_tags"
                            [ngStyle]="{'background-color': item.bgColor ? item.bgColor : 'white', 'color': true ? '#FFFFFF': '#FFFFFF'}">
                            {{item.tag }}
                        </span>
                    </div>
                </div>
                <button class="btn btn-primary" (click)="onGoBack()">
                    <i class="fas fa-arrow-left"></i> Back
                </button>
            </div>
        </section>
        <!-- Banner Section End -->

        <section class="section view-report-main">
            <div class="container" [ngClass]="{submitting : loader}">
                <div class="spinner-border text-warning formLoader" role="status" *ngIf="loader">
                    <span class="sr-only">Loading...</span>
                </div>
                <div class="view-report-des">
                    <div class="row">
                        <div class="col-lg-4 view-report-des-fig mb-3 mb-lg-0">
                            <figure class="img-responsive" style="background-color: #fff;">
                                <img *ngIf="teamData?.logo" [src]="teamData?.logo" alt="sst-01.png"
                                    class=" overView-project-img">
                                <img *ngIf="!teamData?.logo && !loader" [src]="defaultGroupThumbnailPic" alt="sst-01.png"
                                    class=" overView-project-img" style="object-fit: contain;" />
                            </figure>
                        </div>
                        <div class="col-lg-8 view-report-des-detail">
                            <h3 class="avenirBlack">{{teamData?.name | capitalization}}</h3>
                            <app-team-chat *ngIf="teamData" [actionLabel]="'Chat with team members'"
                                [roomId]="teamData.group_chat.room_id" [teamData]="teamData" [chatType]="'team'"
                                (teamStatus)="onGetTeamStatus()"></app-team-chat>

                            <div *ngIf="teamData" class="Instructor-profile mt-4">
                                <figure *ngIf="teamData?.lead?.profile_pic" class="Instructor-pro-fig">
                                    <img [src]="teamData?.lead?.profile_pic" [alt]="teamData?.lead?.name" />
                                </figure>
                                <default-name-icon *ngIf="teamData?.lead && !teamData?.lead?.profile_pic"
                                    [name]="teamData?.lead?.name" [figureClass]="''"></default-name-icon>
                                <div class="Instructor-pro-content">
                                    <h6 class="avenirBlack">
                                        {{ teamData?.lead?.name | capitalization }}
                                    </h6>
                                    <p class="blue-text mb-0">Lead</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <hr class="mt-3 mt-md-5 mb-3 mb-md-5" />

                <div class="team-member-wrap mb-5">
                    <h4 class="avenirBlack primary-text d-inline-block mb-0">Team Members</h4>
                    <div *ngIf="teamData?.members?.length > 0" class="row">
                        <div class="col-lg-3 col-md-4 mt-4" *ngFor="let member of teamData.members; let i = index">
                            <div class="Instructor-profile">
                                <figure *ngIf="member.profile_pic" class="Instructor-pro-fig">
                                    <img [src]="member.profile_pic" [alt]="member.name" />
                                </figure>
                                <default-name-icon *ngIf="!member.profile_pic" [name]="member.name"
                                    [figureClass]="''"></default-name-icon>
                                <div class="Instructor-pro-content">
                                    <h6 class="avenirBlack">
                                        {{ member.name | capitalization }}
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="alert alert-info text-center" *ngIf="teamData?.members?.length == 0"> 
                        <p>No Data Available</p>
                    </div>
                </div>

                <!-- <hr class="mt-3 mt-md-5 mb-3 mb-md-5" /> -->

                <div class="project-report-wrap">
                    <div class="title d-flex justify-content-between align-items-center border-top border-bottom pb-3 pt-3">
                        <h4 class="avenirBlack primary-text mb-0">Project Report</h4>
                        <div>
                            <button *ngIf="isReportAddAllowed && projectReports.length == 0"
                                class="btn btn-primary btn-sm" (click)="onAddProjectReport()">+ Add Project
                                Report</button>
                            <button *ngIf="isReportAddAllowed && projectReports.length > 0"
                                class="btn btn-primary btn-sm" (click)="onEditProjectReport()">
                                <i class="fas fa-pencil-alt"></i> Modify Project Report</button>
                        </div>
                    </div>

                    <div class="mb-5">
                        <div class="text-center">
                            <div class="spinner-border text-warning formLoader" role="status" *ngIf="reportLoader">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                        <h5 class="avenirBlack  mb-2" *ngIf="projectReports.length">{{ projectReports[0].title | titlecase }}</h5>
                        <div class="tags-badges">
                            <span class="badge badge-green-lt" *ngFor="let item of projectReports[0]?.tags"
                                [ngStyle]="{'background-color': item.bgColor ? item.bgColor : 'white', 'color': true ? '#FFFFFF': '#FFFFFF'}">
                                {{item.tag }}
                            </span>
                        </div>
                    </div>
                </div>

                <ng-container *ngIf="projectReports.length">
                    <div class="single-passion-row singlePassoinviewReportDes mb-4 mb-md-5">
                        <h6 class="avenirBlack primary-text">Report Description</h6>
                        <div class="ReportViewDes" *ngIf="projectReports[0].content">
                            <p [innerHTML]="projectReports[0].content"></p>
                        </div>
                        <div class="alert alert-info text-center" *ngIf="!projectReports[0].content"> 
                            <p>No Data Available</p>
                        </div>
                    </div>

                    <div
                        class="single-passion-row learning-course-items-wrap learning-course-liveSession-wrap mb-4 mb-md-5">
                        <h6 class="avenirBlack primary-text">Report Webinars</h6>
                        <div class="viewReport-passion-content-inn">
                        <div class="learning-course-items-wrap learning-course-resources-wrap">
                        <div class="learning-course-item" *ngFor="let webinarlink of projectReports[0].webinar_links;">
                            <div class="learning-course-link">
                                <div class="learning-course-elem-left">
                                        <p class="learning-course-link-title">
                                            <a [href]="webinarlink.resource_type == 'file' ? webinarlink.s3url : webinarlink.url"
                                            target="_blank" class="learning-course-download">
                                            <span class="title-icon" *ngIf="webinarlink.file_type === 'file'"><i class="fas fa-file"></i></span>
                                            <span class="title-icon" *ngIf="webinarlink.file_type === 'image'"><i class="fas fa-image"></i></span>
                                            <span class="title-icon" *ngIf="webinarlink.file_type === 'video'"><i class="fas fa-video"></i></span>
                                            <span class="title-icon" *ngIf="webinarlink.file_type === 'text'"><i class="fas fa-file"></i></span>
                                              <strong> {{webinarlink.title | titlecase}}</strong>
                                           </a>
                                        </p>
                                </div>
                                    <div class="learning-course-elem-right">
                                        <ul class="list-icon-wrap">
                                            <li>
                                                <a [href]="webinarlink.resource_type == 'file' ? webinarlink.s3url : webinarlink.url"
                                                target="_blank" class="learning-course-download"><i class="fas fa-link"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                            </div>
                        </div>

                        <div class="alert alert-info text-center" *ngIf="projectReports[0].webinar_links?.length === 0"> 
                            <p>No Data Available</p>
                        </div>
                    </div>
                </div>
                </div>

                    <div
                        class="single-passion-row learning-course-items-wrap learning-course-liveSession-wrap mb-4 mb-md-5">
                        <h6 class="avenirBlack primary-text">Code Repository</h6>
                        <div class="viewReport-passion-content-inn">
                            <div class="learning-course-items-wrap learning-course-resources-wrap">
                        <div class="learning-course-item" *ngFor="let codelink of projectReports[0].code_links;">
                            <div class="learning-course-link">
                                <div class="learning-course-elem-left">
                                        <p class="learning-course-link-title">
                                            <a [href]="codelink.resource_type == 'file' ? codelink.s3url : codelink.url"
                                            target="_blank" class="learning-course-download">
                                            <span class="title-icon" *ngIf="codelink.file_type === 'file'"><i class="fas fa-file"></i></span>
                                            <span class="title-icon" *ngIf="codelink.file_type === 'image'"><i class="fas fa-image"></i></span>
                                            <span class="title-icon" *ngIf="codelink.file_type === 'video'"><i class="fas fa-video"></i></span>
                                            <span class="title-icon" *ngIf="codelink.file_type === 'text'"><i class="fas fa-file"></i></span>
                                            <strong> {{codelink.title | titlecase}}</strong>
                                           </a>
                                        </p>
                                </div>
                                    <div class="learning-course-elem-right">
                                        <ul class="list-icon-wrap">
                                            <li>
                                                <a [href]="codelink.resource_type == 'file' ? codelink.s3url : codelink.url"
                                                target="_blank" class="learning-course-download"><i class="fas fa-link"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                            </div>
                        </div>


                        <div class="alert alert-info text-center" *ngIf="projectReports[0].code_links?.length === 0"> 
                            <p>No Data Available</p>
                        </div>
                    </div>
                </div>
                    </div>

                    <div class="single-passion-row singlePassoinviewReportRes">
                        <h6 class="avenirBlack primary-text">Report Resources</h6>
                        <div class="viewReport-passion-content-inn">
                            <div class="learning-course-items-wrap learning-course-resources-wrap">
                                <div class="learning-course-item"
                                    *ngFor="let resourcelink of projectReports[0].resource_links;">
                                    <div class="learning-course-link">
                                        <div class="learning-course-elem-left">
                                            <p class="learning-course-link-title">
                                              <a [href]="resourcelink.resource_type == 'file' ? resourcelink.s3url : resourcelink.url" target="_blank">                                                
                                                <span class="title-icon" *ngIf="resourcelink.file_type === 'file'"><i class="fas fa-file"></i></span>
                                                <span class="title-icon" *ngIf="resourcelink.file_type === 'image'"><i class="fas fa-image"></i></span>
                                                <span class="title-icon" *ngIf="resourcelink.file_type === 'video'"><i class="fas fa-video"></i></span>
                                                <span class="title-icon" *ngIf="resourcelink.file_type === 'text'"><i class="fas fa-file"></i></span>
                                                <strong>{{resourcelink.title}}</strong>
                                            </a>
                                            </p>
                                        </div>
                                            <div class="learning-course-elem-right">
                                                <ul class="list-icon-wrap">
                                                    <li>
                                                        <a class="learning-course-download" [href]="resourcelink.resource_type == 'file' ? resourcelink.s3url : resourcelink.url"
                                                        target="_blank"><i
                                                                class="fas fa-download"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                    </div>
                                </div>

                                <div class="alert alert-info text-center" *ngIf="projectReports[0].resource_links?.length === 0"> 
                                    <p>No Data Available</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <div class="alert alert-info text-center" *ngIf="projectReports.length === 0"> 
                    <p>No Data Available</p>
                </div>

            </div>
        </section>
    </div>
</div>
