<div class="new_UIstyle">
    <div class="pageBodyWrap">
        <div>

            <section class="banner banner-inner courseWork-banner text-center"
                style="background-image: url('assets/images/banner/assessment-01.png');">
                <div class="container">
                    <div class="banner-content">
                        <h1 class="white-text mb-2">Course work</h1>
                        <p class="avenirRoman white-text">Coursework - Edit, Delete and Create Coursework here</p>
                        <form class="assessment-banner-form">
                            <div class="input-with-icon input-with-icon-right">
                                <input class="form-control form-control-lg" type="text" #searchInput id="search"
                                    placeholder="Search for Course Work..." autofocus />
                                <button class="input-with-icon-btn">
                                    <i class="fa fa-search"></i>
                                </button>
                            </div>
                        </form>
                        <button class="btn btn-primary mt-2 mt-md-4" (click)="onAddCourseWork()">+ Create New
                            Coursework</button>
                    </div>
                </div>
            </section>

            <section class="section assessment-content-wrap">
                <div class="container" [ngClass]="{submitting : loader}">
                    <div class="spinner-border text-warning formLoader" role="status" *ngIf="loader">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="assessment-content-list">
                        <div class="questionBankItem assessment-content-item bg-grey"
                            *ngFor="let coursework of courseWorkList; let i = index">
                            <div class="questionItemLeft">
                                <h6 class="avenirBook mb-3">
                                    <a
                                        (click)="viewSelectedCourseWork(coursework.course_work_id)">{{coursework.course_work_title}}</a>
                                </h6>
                                <div class="assessment-content-options-list">
                                    <ul class="assessment-content-options-ul options-ul-style-label ul-list-100">
                                        <li><span class="options-ul-label">Status:</span>
                                            <div class="options-ul-content">
                                                <strong class="primary-text">
                                                    {{coursework.publish == true ? 'Published': 'Unpublished'}}
                                                </strong>
                                            </div>
                                        </li>
                                        <li>
                                            <span class="options-ul-label">Last Edited: </span>
                                            <div class="options-ul-content">
                                                <strong class="primary-text">{{coursework.created_at | date: 'MMMM d, y'}}
                                                </strong>
                                            </div>
                                        </li>
                                        <li *ngIf="coursework?.courses.length > 0">
                                            <span class="options-ul-label">Courses:</span>
                                            <div class="options-ul-content">
                                                <strong *ngFor="let item of coursework?.courses.slice(0, 2)" class="primary-text">
                                                {{item.course | titlecase}} 
                                                <span class="colma">,</span> 
                                                </strong>
                                                <ng-container *ngIf="coursework?.courses.length > 2">
                                                    <b [matTooltip]="courseListPreview[i]?.slice(2).toString()" matTooltipPosition="above"> 
                                                    + {{coursework?.courses.length - 2}} More</b></ng-container>
                                           </div>
                                        </li>
                                    </ul>
                                </div>
                                <div class="tags-badges">
                                    <span class="badge badge-green-lt"
                                        *ngFor="let a_tag of coursework.tags">{{a_tag.tag |
                                        titlecase}}</span>
                                </div>
                            </div>
                            <div class="questionItemRight icons-group">
                                <a class="questionItemLink ItemEditLink" ngbTooltip="Edit">
                                    <i class="fas fa-pencil-alt"
                                        (click)="editSelectedCourseWork(coursework.course_work_id)"></i>
                                </a>
                                <a class="questionItemLink ItemDeleteLink"
                                    (click)="openModelDeleteCoursework(delCoursework)">
                                    <i class="fas fa-trash-alt"></i>
                                </a>
                            </div>
                            <a class="questionItemLink ItemAnchorLink"
                                (click)="viewSelectedCourseWork(coursework.course_work_id)">
                                <i class="fa fa-angle-right"></i>
                            </a>
                            <ng-template #delCoursework let-c="close" let-d="dismiss">
                                <div class="new_UIstyle">
                                    <div class="modal-header justify-content-between align-items-center">
                                        <h6 class="modal-title avenirBlack primary-text">Delete</h6>
                                        <button type="button" class="close ml-0" aria-label="Close"
                                            (click)="closeQuestionPopup()">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        <p>Are you sure you want to delete coursework ?</p>
                                    </div>
                                    <div class="modal-footer">
                                        <div class="text-left">
                                            <button type="submit" class="btn btn-primary"
                                                (click)="closeQuestionPopup()">No</button>
                                        </div>
                                        <div class="text-right">
                                            <button type="submit" class="btn btn-primary"
                                                (click)="deleteCoursework(coursework.course_work_id)">Yes</button>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                        <div class="alert alert-info text-center" *ngIf="!loader && !courseWorkList?.length">
                            <p>No Coursework Found</p>
                        </div>
                    </div>

                    <div class="container text-center pagena justify-content-center">
                        <ngb-pagination [pageSize]="pageSize" [collectionSize]="totalDatalength" [(page)]="page" [maxSize]="maxPageNoDisplay" aria-label="Default pagination" class="pagena pagination-wrap" (pageChange)="onPageChanged($event)" [boundaryLinks]="false">
                            <ng-template ngbPaginationPrevious><</ng-template>
                            <ng-template ngbPaginationNext>></ng-template>
                        </ngb-pagination>
                    </div>
                </div>
            </section>

        </div>
    </div>
</div>
