import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CourseAssessmentShareService {
  topicId: any;
  courseName: any;
  userId: any;
  course_session_id: any;
  publishRight: any;

  course_category_id: any
  course_category: any;
  sessionKey: any;
  constructor() { }

  courseAssessmentData;
  sessionId;
  courseId;

  setCourseAssessment(data) {

    this.courseAssessmentData = data
  }

  getCourseAssessment() {
    return this.courseAssessmentData;
  }

  setSessionId(data) {

    this.sessionId = data
  }

  getSessionId() {
    return this.sessionId;
  }

  setCourseId(data) {

    this.courseId = data
  }

  getCourseId() {
    return this.courseId;
  }

  setTopicId(data) {

    this.topicId = data
  }

  getTopicId() {
    return this.topicId;
  }

  setCourseName(data) {

    this.courseName = data
  }

  getCourseName() {
    return this.courseName;
  }
  getUserId() {
    return this.userId;
  }

  setUserId(user_id) {
    this.userId = user_id
  }

  setCourseSessionId(course_session_id) {
    this.course_session_id = course_session_id
  }

  getCourseSessionId() {
    return this.course_session_id;
  }

  setpublishRight(publishRight) {
    this.publishRight = publishRight
  }

  getpublishRight() {
    return this.publishRight
  }

  setSessionKey(sessionkey) {
    this.sessionKey = sessionkey
  }
  setCourse_Category(course_category) {
    this.course_category = course_category

  }
  setCourse_category_id(course_category_id) {
    this.course_category_id = course_category_id

  }

  getSessionKey() {
    return this.sessionKey;
  }
  getCourse_Category() {
    return this.course_category;

  }
  gercourse_category_id() {
    return this.course_category_id;

  }

}
