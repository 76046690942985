import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BugReport {
  apiEndpoint: any;
  constructor(private http: HttpClient) {
    this.apiEndpoint = environment.APIEndpoint;
   }

  bugReportApi(body: any): Observable<any>{
  
    const formData = new FormData();
    formData.append('report', body.file);
    formData.append('Application_Type', body.applicationType);
    formData.append('description', body.description);
    formData.append('type', body.type);

    return this.http.post<any>(this.apiEndpoint + '/report_bug',formData).pipe(
      catchError(this.handleError)
    );
  }



  handleError(error) {

    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);

    return throwError(error);
  }
}

