import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CoursesComponent } from './courses.component';
import { TagInputModule } from 'ngx-chips';
import { SearchCourseWorkComponent } from './search-course-work/search-course-work.component';
import { AddCourseWorkComponent } from './add-course-work/add-course-work.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CreateAssessmentComponent } from '../assessments/create-assessment/create-assessment.component';
import { DateFormatPipe } from './pipe/dateFormat.pipe';
import { youtubesafeUrl } from '../pipe/youtubeSafeUrl.pipe';
import { youtubeVideoId } from '../pipe/youtubeVideoId.pipe';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { splitComma } from './pipe/splitComma.pipe';
import { TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { SingleEcCourseComponent } from './single-ec-course/single-ec-course.component';
import { AskQuestionComponent } from '../ask-question/ask-question.component';
import { CourseWorkComponent } from './course-work/course-work.component';
import { ViewAssessmentComponent } from '../assessments/view-assessment/view-assessment.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { CourseworkInfoComponent } from './coursework-info/coursework-info.component';
import { SubmitCourseAssessmentComponent } from './submit-course-assessment/submit-course-assessment.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FeedbackComponent } from './feedback/feedback.component';
import { AssessmentGraphComponent } from './assessment-graph/assessment-graph.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NoteTakingComponent } from './note-taking/note-taking.component';
import { VideoNotesComponent } from './video-notes/video-notes.component';
import { SubscribersNotesListComponent } from './subscribers-notes-list/subscribers-notes-list.component';
import { TeachingAssistantsComponent } from './teaching-assistants/teaching-assistants.component';
import { TeachingAssistantReportComponent } from './teaching-assistant-report/teaching-assistant-report.component';
import { StudentMonitoringComponent } from './student-monitoring/student-monitoring.component';
import { PendingRecordingsComponent } from './pending-recordings/pending-recordings.component';
import { CwTeachingAssisatntComponent } from './cw-teaching-assisatnt/cw-teaching-assisatnt.component';
import { AttendanceMarkingComponent } from './attendance-marking/attendance-marking.component';
import { EcCourseBoardComponent } from './ec-course-board/ec-course-board.component';
import { LearningListComponent } from './learning-list/learning-list.component';
import { TeachComponent } from './teach/teach.component';
import { OtherCoursesComponent } from './other-courses/other-courses.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { AddEcCourseComponent } from './add-ec-course/add-ec-course.component';
import { CommonAppModuleModule } from '../common-app-module/common-app-module.module';
import { SanitizUrl } from './pipe/sanitizurl.pipe';

import { MatExpansionModule } from '@angular/material/expansion';
import { DeactivatedCoursesComponent } from './ec-course-board/deactivated-courses/deactivated-courses.component';
import { MatAutocompleteModule, MatFormFieldModule, MatInputModule, MatTooltipModule } from '@angular/material';
import { Find_Key } from '../pipe/find_key_pair.pipe';

import { CourseWorkTeacherloginComponent } from './coursework-teacherlogin/coursework-teacherlogin.component';
import { CourseWorkSuperadminComponent } from './course-work-superadmin/course-work-superadmin.component';
import { TeacherCourseWorkComponent } from './teacher-course-work/teacher-course-work.component';
import { CourseWorkTestComponent } from './course-work-test/course-work-test.component';

import { CourseWorkTeachersComponent } from './coursework-teachers/coursework-teachers.component';
import { MarkAssessmentComponent } from './mark-assessment/mark-assessment.component';
import { DndDirective } from '../shared/directives/dnd.directive';
import { DateFormatFullmonthPipe } from './pipe/date-format-fullmonth.pipe';
import { DatePipe } from '@angular/common';
import { CourseWorkDetailComponent } from './course-work-detail/course-work-detail.component';
import { DateTimeAgoPipe } from './pipe/date-time-ago.pipe';
import { SplitTitlePipe } from './pipe/split-title.pipe';
import { NgxPaginationModule } from 'ngx-pagination';
import { LiveButtonComponent } from './live-button/live-button.component';
import { DurationTextPipe } from '../pipe/duration-text.pipe';
import { MarkAttendanceComponent } from './mark-attendance/mark-attendance.component';
import { TableModule } from 'primeng/table';
import { FormatTimePipe } from './pipe/formatTime.pipe';
import { CourseWorkTestReviewComponent } from './course-work-test-review/course-work-test-review.component';
import { CourseWorkTestReviewPeerComponent } from './course-work-test-review-peer/course-work-test-review-peer.component';
import { CourseWorkMarkingComponent } from './course-work-marking/course-work-marking.component';
import { CourseWorkMarkingFilesComponent } from './course-work-marking-files/course-work-marking-files.component';
import { CourseWorkMarkingRemarkComponent } from './course-work-marking-remark/course-work-marking-remark.component';
import { CourseWorkMarkingRemarkGradeComponent } from './course-work-marking-remark-grade/course-work-marking-remark-grade.component';
import { ExpireDatePipe } from './pipe/expire-date.pipe';
import { UserSubmissionsComponent } from './user-submissions/user-submissions.component';
import { DefaultIconComponent } from './default-icon/default-icon.component';
import { splitTitleFile } from './pipe/split-title-file.pipe';
import { MonitoringReportComponent } from './monitoring-report/monitoring-report.component';
import { DisableRightClickDirective } from '../shared/directives/disable-right-click.directive';
import { SharedModule } from '../shared/modules/shared/shared.module';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: EcCourseBoardComponent
      },
      {
        path: 'add-course',
        component: AddEcCourseComponent
      },
      { path: 'add',
        loadChildren: () => import("./add-courses/add-courses.module").then(m => m.AddCoursesModule)
      },
      {
        path: 'edit-course',
        component: AddEcCourseComponent
      },
      {
        path: 'single-course',
        component: SingleEcCourseComponent
      },
      {
        path: 'review-rating',
        component: SingleEcCourseComponent
      },
      {
        path: 'add-course-work',
        component: AddCourseWorkComponent
      },
      {
        path: 'course-work',
        component: CourseWorkComponent
      },
      {
        path: 'search-coursework',
        component: TeacherCourseWorkComponent
      },
      {
        path: 'coursework-view',
        component: CourseWorkTeacherloginComponent
      },

      { path: 'add-coursework', component: CourseWorkTeachersComponent },
      {
        path: 'coursework-info',
        component: CourseworkInfoComponent,
      },
      {
        path: 'course-worktest',
        component: CourseWorkTestComponent
      },
      {
        path: 'course-work-submissions',
        component: CourseWorkSuperadminComponent
      },
      {
        path: 'course-work-marking',
        component: CourseWorkMarkingComponent
      },
      {
        path: 'course-work-detail',
        component: CourseWorkDetailComponent
      },
      {
        path: 'assessment-marking',
        component: MarkAssessmentComponent,
      },
      {
        path: 'assessment-graph',
        component: AssessmentGraphComponent,
      },
      {
        path: 'video-with-notes',
        component: VideoNotesComponent,
      },
      {
        path: 'subscribers-notes-list',
        component: SubscribersNotesListComponent,
      },
      {
        path: 'teaching-assistants',
        component: TeachingAssistantsComponent,
      },
      {
        path: 'teaching-assistant-report',
        component: TeachingAssistantReportComponent,
      },
      {
        path: 'cw-teaching-assistant',
        component: CwTeachingAssisatntComponent,
      },
      { path: 'student-monitoring', component: StudentMonitoringComponent },
      { path: 'monitoring-report', component: MonitoringReportComponent },
      { path: 'ec-course-board', component: EcCourseBoardComponent },
      { path: 'submit-assessement', component: SubmitCourseAssessmentComponent },
      { path: 'user-submissions', component: UserSubmissionsComponent },
      { path: 'feedback-assessement', component: FeedbackComponent },

      {
        path: 'attendance-marking',
        component: AttendanceMarkingComponent
      },
      {
        path: 'attendance',
        component: MarkAttendanceComponent
      },
      {
        path: '**',
        redirectTo: '/courses/ec-course-board',
        pathMatch: 'full'
      }
    ]
  }
];
@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ReactiveFormsModule,
    TagInputModule,
    NgbModule,
    YouTubePlayerModule,
    FormsModule,
    NgSelectModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxChartsModule,
    CarouselModule,
    DragDropModule,
    Ng2SearchPipeModule,
    CommonAppModuleModule,
    MatExpansionModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    TableModule,
    NgxPaginationModule,
    MatTooltipModule,
    SharedModule
  ],
  declarations: [
    AddEcCourseComponent,
    SingleEcCourseComponent,
    CoursesComponent,
    AddCourseWorkComponent,
    CreateAssessmentComponent,
    SearchCourseWorkComponent,
    AskQuestionComponent,
    DateFormatPipe,
    youtubesafeUrl,
    youtubeVideoId,
    splitComma,
    CourseWorkComponent,
    CourseWorkTestComponent,
    TeacherCourseWorkComponent,
    CourseWorkTeacherloginComponent,
    CourseWorkSuperadminComponent,
    CourseWorkTeachersComponent,
    ViewAssessmentComponent,
    CourseworkInfoComponent,
    SubmitCourseAssessmentComponent,
    FeedbackComponent,
    AssessmentGraphComponent,
    NoteTakingComponent,
    VideoNotesComponent,
    SubscribersNotesListComponent,
    TeachingAssistantsComponent,
    TeachingAssistantReportComponent,
    StudentMonitoringComponent,
    PendingRecordingsComponent,
    CwTeachingAssisatntComponent,
    AttendanceMarkingComponent,
    EcCourseBoardComponent,
    LearningListComponent,
    TeachComponent,
    splitTitleFile,
    OtherCoursesComponent,
    SanitizUrl,
    Find_Key,
    DeactivatedCoursesComponent,
    MarkAssessmentComponent,
    DndDirective,
    DisableRightClickDirective,
    DateFormatFullmonthPipe,
    CourseWorkDetailComponent,
    DateTimeAgoPipe,
    SplitTitlePipe,
    splitTitleFile,
    FormatTimePipe,
    LiveButtonComponent,
    DurationTextPipe,
    MarkAttendanceComponent,
    CourseWorkTestReviewComponent,
    CourseWorkTestReviewPeerComponent,
    CourseWorkMarkingComponent,
    CourseWorkMarkingFilesComponent,
    CourseWorkMarkingRemarkComponent,
    CourseWorkMarkingRemarkGradeComponent,
    ExpireDatePipe,
    UserSubmissionsComponent,
    DefaultIconComponent,
    MonitoringReportComponent
  ],
  exports: [
    RouterModule,
    AddEcCourseComponent,
    SingleEcCourseComponent,
    CoursesComponent,
    AddCourseWorkComponent,
    SearchCourseWorkComponent,
    ReactiveFormsModule,
    CreateAssessmentComponent,
    AskQuestionComponent,
    FormsModule,
    NgbModule,
    ViewAssessmentComponent,
    DateFormatPipe,
    youtubesafeUrl,
    youtubeVideoId,
    YouTubePlayerModule,
    NgMultiSelectDropDownModule,
    splitComma,
    NgSelectModule,
    Ng2SearchPipeModule
  ],
  providers: [
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
    DatePipe
  ],
})
export class CourseModule { }
