import { Component, OnInit, ViewChild, ElementRef, HostListener, Renderer2,ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { NotificationService } from '../../services/notification.service';
import { ShareService } from '../../services/share.service';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { HeaderService } from '../../services/header.service';
import { SocketService } from '../../services/web/socket.service';
import { CourseService } from '../../services/course.service';
import { parentInter, courses_inFo } from '../../model/courseCategory';
import { finalize } from 'rxjs/internal/operators/finalize';
import { debounceTime, distinctUntilChanged, tap, filter } from 'rxjs/operators';
import { fromEvent } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit {

  @ViewChild('searchInput', {static: false}) searchInput: ElementRef;
  @ViewChild('headerSection', {static: false}) headerSection: ElementRef;

  navbarOpen = false;
  loginFlag = false;
  CategoriesCourse: boolean = true
  showNotiBx: boolean = false;
  listLoader:boolean = false;
  guftgu = 0;
  roles;
  countNumber:any;
  pmenus = [
    {
      name: 'Courses',
      link: '/courses/ec-course-board',
      slug: 'read_org_public_courses/read_all_courses',
    },
    // {
    //   name: 'My Content',
    //   link: 'my-content',
    //   slug: 'my_content'
    // },
    // {
    //   name: 'Marking',
    //   link: 'marking',
    //   slug: 'marking'
    // },
  ];

  pmenusBL = [
    {
      name: 'Log In',
      link: 'login'
    }
  ];
  permission: [];
  userLogo: string;
  user_id: string;
  userName: string
  Email_ID: string
  userFirstName:string;
  userLastName:string;
  defaultAvtar:string;
  defaultAvtarGroup:string;
  notificationList:any[] = [];
  currentUrl:string = '';
  get role() {
    return localStorage.getItem('role');
  }
  get permissions() {
    return this.permission = JSON.parse(localStorage.getItem("permissions"));
  }
  result: [] = [];

  isProduction: boolean = environment.production;
  routerUrl:any;
  constructor(public router: Router,
    private activatroute: ActivatedRoute,
    private shareService: ShareService,
    private userService: UserService,
    private ns: NotificationService,
    private route: Router,
    private socket_service: SocketService,
    ddConfig: NgbDropdownConfig,
    private courseService: CourseService,
    private headerService: HeaderService,
    private changeDetector:ChangeDetectorRef,
    private renderer: Renderer2) {
      this.defaultAvtar = environment.imageBasePath + environment.userDefaultProfilePic;
      this.defaultAvtarGroup = environment.imageBasePath + environment.userDefaultGroupPic;
    // ddConfig.autoClose = "outside";
    ddConfig.autoClose = true;
    if (localStorage.getItem('currentUser')) {
      this.loginFlag = true;
    }
    router.events.subscribe((val) => {
      if(val instanceof NavigationStart){
        this.currentUrl = val.url;
      }
    })

    this.result = this.findMenu(this.pmenus);
    this.lasestCount();
  }

  statOrders: string[] = ["Read", "Online", "Away"];
  selectedStatOrder: string = "Online";

  ChangeStatOrder(newStatOrder: string) {
    this.selectedStatOrder = newStatOrder;
  }

  get getResult(): any {
    return this.result = this.findMenu(this.pmenus);
  }
  findMenu(arr) {
    return arr.filter(function (ele) {
      if (localStorage.getItem('role') == 'student' && (ele.link != ("bulk-upload") && ele.link != ("report") && ele.link != ("addUser") && ele.link != ("manageRoles") && ele.link != ("question-bank"))) {
        return ele;
      } else if (localStorage.getItem('role') == 'teacher' && (ele.link != ("bulk-upload") && ele.link != ("report") && ele.link != ("manageRoles") && ele.link != ("addUser") && ele.link != ("my-content"))) {
        return ele;
      } else if (localStorage.getItem('role') == 'super_admin' || localStorage.getItem('role') == 'school_admin') {
        return ele;
      }
    });
  }

  ngOnInit() {
    this.user_id = localStorage.getItem('user_id');
    this.userName = localStorage.getItem('currentUser')
    this.userFirstName = localStorage.getItem('first_name')
    this.userLastName = localStorage.getItem('last_name')
    this.Email_ID = localStorage.getItem('Email_ID')

    //SET LATEST AVATAR IMAGE ON PAGE RELOAD
    if('currentUser' in localStorage) {
      if('avatarURL' in localStorage && localStorage.getItem('avatarURL')) {
        this.headerService.getLatestAvatar().subscribe(res => {
          this.headerService.setLogo(res.profile_pic);
          localStorage.setItem('avatarURL', res.profile_pic);
        }, err => {
          //console.log(err);
        });
      }
    }

    this.sortedCourseCategory();
    this.headerService.logo.subscribe(img => {
      if (!img) {
        this.userLogo = '';
      } else this.userLogo = img
    });

    //FOR SEARCH WITH QUERY PARAM
    this.activatroute.queryParams.subscribe({
      next: (params) => {
        if(this.router.url.indexOf('/search') != -1) {
          let routeParams = JSON.parse(JSON.stringify(params));
          if(Object.keys(routeParams).length > 0) {
            this.search_text = routeParams.q;
          }
        }
        else {
          this.search_text = '';
        }
        let sp = this.router.url.split('?')
        this.routerUrl = sp[0];
        
      }
    });


    
    this.headerService.notifyCountNumAs.subscribe((response:number)=>{
      this.notifyCount = response;
    })
    this.lasestCount();
    this.get_notify_status();
    this.getNotificationApiData();
  }

  ngAfterContentChecked() : void {
    this.changeDetector.detectChanges();
}


  getLastestNotify(){
    this.getNotificationApiData();
  }
  get_notify_status(){
    this.socket_service.get_notification_data().subscribe((response)=>{
      response.receivers.filter((receiver:any)=>{
          if(receiver == this.user_id){
            this.notificationList.unshift({...response});
            this.getNotificationApiData();
          }
        })
    })
  }

  notifyCount:number = 0;
  getNotificationApiData(){
    this.listLoader = true;
    this.notificationList = [];
    this.ns.getNotificationsDropDown().subscribe(response =>{
      let notificationsCount = response.count;
      this.headerService.SetNotifyCount(notificationsCount);
      this.notifyCount = notificationsCount;
      let notifications = response.notifications;
      notifications.forEach((notification:any)=>{
        notification.receivers.filter((receiver:any)=>{
          if(receiver == this.user_id){
            this.notificationList.push(notification);
          }
        })
      })
      this.listLoader = false;
    })
  }


  redirectTo(NotifiyData){
    this.readNotifyMethod(NotifiyData._id);   
    if(NotifiyData.type == 'messages'){
      this.headerService.SetNotifyEvent(true);      
      if(NotifiyData.content.group_chat == false){
        this.router.navigate(['/guftagu'], { queryParams:{ chatRoomId:NotifiyData.content.room_id}})
      } else if(NotifiyData.content.group_chat == true){
        this.router.navigate(['/guftagu'], { queryParams:{ group_room_id:NotifiyData.content.room_id}})
      }
    } else if(NotifiyData.type == 'chat_request'){
      this.headerService.SetNotifyEvent(true);      
      this.router.navigate(['/guftagu'], { queryParams:{ rq_sender_id:NotifiyData.content.sender_id}})
    } else if(NotifiyData.type == 'TA_assignment'){
        this.router.navigate(['/courses/single-course'], { queryParams:{ course_id:NotifiyData.content.course_id , tab_no:4}})
    } else if(NotifiyData.type == 'topic'){     
      this.router.navigate(['/courses/single-course'], { queryParams:{ course_id:NotifiyData.content.course_id, topic_id:NotifiyData.content.topic_id , tab_no:1}})
     } else if(NotifiyData.type == 'question'){     
      this.router.navigate(['/question-details'], { queryParams:{ questionId:NotifiyData.content.question_id, info:'allQus'}})
     } else if(NotifiyData.type == 'course_coursework'){     
      this.router.navigate(['/courses/single-course'], { queryParams:{ course_id:NotifiyData.content.course_id, tab_no:7}})
     } else if(NotifiyData.type == 'coursework_invite'){     
      this.router.navigate(['/courses/single-course'], { queryParams:{ course_id:NotifiyData.content.course_id, tab_no:7}})
     } else if(NotifiyData.type == 'course_assessment'){     
      this.router.navigate(['/courses/single-course'], { queryParams:{ course_id:NotifiyData.content.course_id, tab_no:6}})
     } else if(NotifiyData.type == 'assessment'){   
      if(NotifiyData.level == 'course'){
        this.router.navigate(['/courses/single-course'], { queryParams:{ course_id:NotifiyData.content.course_id,  topic_id:NotifiyData.content.topic_id, tab_no:6 }})
      }  else if(NotifiyData.level !== 'course'){
        this.router.navigate(['/courses/single-course'], { queryParams:{ course_id:NotifiyData.content.course_id,  topic_id:NotifiyData.content.topic_id, tab_no:1 }})
      }
     } 

  }

  readNotifyMethod(notify_id){
    this.ns.readNotify(notify_id).subscribe((response:any)=>{
      this.getNotificationApiData();
    },error=>{
      console.log(error.message);
    })
  }

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  signout() {
    this.user_id = localStorage.getItem('user_id');
    this.shareService.setUserStatus('away');
    this.shareService.IsSocketTrue.subscribe((res)=>{
      let isSocketTrue = res;
      if(isSocketTrue == true){
        this.socket_service.set_user_status('away', this.user_id);    
      }
    })
    this.loginFlag = false;
    localStorage.removeItem('currentUser');
    localStorage.removeItem('token');
    localStorage.removeItem('Email_ID');
    localStorage.removeItem('user_id');
    localStorage.removeItem('role');
    localStorage.removeItem('coins');
    localStorage.removeItem('permissions');
    localStorage.removeItem('avatarURL');
    localStorage.removeItem('first_name');
    localStorage.removeItem('last_name');
    this.result = [];
    setTimeout(() => {
      this.socket_service.close_socket();
    }, 1000);
    this.router.navigate(['/login']);
  }

  findIndexOf(slug, pmenusSlug) {
    //this.route_name = this.activate_route.
    let indexOfValue;
    let Slug = pmenusSlug.split('/')
    if (Slug[1] != undefined) {
      indexOfValue = this.shareService.getIndexArr(slug, Slug[0]);
      if (indexOfValue <= -1) {
        indexOfValue = this.shareService.getIndexArr(slug, Slug[1])
      }
      return indexOfValue;
    }
    indexOfValue = this.shareService.getIndexArr(slug, pmenusSlug)
    return indexOfValue;
  }

  getLength(permissions) {
    let add_user = this.shareService.getIndexArr(permissions, "add_user");
    let report = this.shareService.getIndexArr(permissions, "report");
    let bulk_upload_mul_users = this.shareService.getIndexArr(permissions, "bulk_upload_mul_users");
    if (bulk_upload_mul_users > 0 || report > 0 || add_user > 0) {
      return '';
    } else {
      return 'none'
    }

  }
  openNotiBx() {
    this.showNotiBx = !this.showNotiBx;
  }


  
  allCoins = '';
  get loginFLagValue(): boolean {
    if (localStorage.getItem('currentUser')) {
      this.allCoins = localStorage.getItem('coins');
      return this.loginFlag = true;
    }
    this.allCoins = '';
    return this.loginFlag = false;
  }
  pageName = '';
  Get_count() {
    this.pageName = this.router.url;
    const title = this.pageName.split('/');
    let titlename = title[1];
    console.log(titlename);
    if (titlename == 'guftagu') {
      this.socket_service.get_notification_list = [];
      return 0
    }
    return this.socket_service.get_notification_list.length;
  }


  setCoinEvent(){    
    this.headerService.getLatestCoin().subscribe((apiResponse)=>{
      localStorage.setItem("coins", apiResponse.coins);
      this.userFirstName = localStorage.getItem('first_name');
      this.userLastName = localStorage.getItem('last_name');
    })
  }


  sidebarMainMenu: boolean = false;
  mainMenuClickEvent() {
    this.sidebarMainMenu = !this.sidebarMainMenu;
  }

  navigate_to(url) {
    this.ns.navigate_to(url);
  }

  navigate_too(url, category_id) {
    // console.log(url, course_id);
    this.ns.navigate_wirh_id(url, category_id)
    this.sortedCourseCategory();
  }




  // loader = false;
  // sotedCategory: string[];

  // sortedCourseCategory() {
  //   this.loader = true;
  //   this.courseService.sortedCourseCategory().pipe(finalize(() => {
  //     this.loader = false;
  //   })).subscribe((data: any) => {
  //     this.sotedCategory = data;
  //   });
  // }
  sotedCategory;
  sortedCourseCategory(): void {
    if (this.role == 'student') {
      this.courseService.sortedCourseCategory().subscribe(
        (data) => {
          this.sotedCategory = data;
          //console.log(data)
          // if (this.role == 'student') {
          //   return false;
          // }
        }
      )
    }
  }


  loader = false
  onTopChangeCategory(cat_id) {
    this.router.navigate(['/category-list'], { queryParams: { course_category_id: cat_id, } })


  }


  sentToPublicProfile(value) {
    this.route.navigate(['/public-profile'])
  }

  search_text: any = '';
  searchType: any = 'question'

  onSearchTextChanged(searchVal) {
    //this.shareService.searchedText.next(searchVal);
    // console.log(searchVal.target.value)
    //this.route.navigate(['/search']);
  }

  //FOR SEARCH WITH QUERY PARAM
  ngAfterViewInit() {
    if(this.searchInput) {
      fromEvent(this.searchInput.nativeElement,'input')
          .pipe(
              //filter(Boolean),
              debounceTime(400),
              distinctUntilChanged(),
              tap((event) => {
                let searchVal = event;
                let searchPath = '/search';
                if(this.searchInput.nativeElement.value.length > 0){
                  searchPath = 'search?q='+this.searchInput.nativeElement.value;
                }
                this.router.navigateByUrl(searchPath);
              })
          )
          .subscribe();
    } 
    this.changeDetector.detectChanges();
  }


  // inputValue: string
  // isAssAvailable: boolean = false;
  // searchGlobal(ev: any) {
  //   this.roles = localStorage.getItem('role');
  //   if (this.roles === 'student') {
  //     // set val to the value of the searchbar
  //     let valP = ''
  //     if (typeof ev == "string") {
  //       valP = ev.toLowerCase();
  //       this.inputValue = ev.toLowerCase();
  //     } else {
  //       valP = ev.target.value.toLowerCase();
  //       this.inputValue = ev.target.value.toLowerCase();
  //     }
  //     // if the value is an empty string don't filter the items
  //     if (valP && valP.trim() != '') {
  //       valP = ev.target.value.toLowerCase();
  //       this.inputValue = ev.target.value.toLowerCase();
  //       console.log(this.inputValue)
  //       // this.onInput(this.inputValue)
  //       // this.fetchAssMent(valP)
  //       // this.isAssAvailable = true;
  //     } else {
  //       console.log('else', this.inputValue)

  //       // this.isAssAvailable = false;
  //       // this.fetchAssessments();
  //     }
  //   }
  // }

  //FOR STICKY HEADER |START
  @HostListener('window:scroll', [])
    onWindowScroll() {
      const headerElm = this.headerSection.nativeElement;
      const headerElmHeight = headerElm.offsetHeight;
      if(window.pageYOffset > headerElmHeight)
      {
        this.renderer.addClass(headerElm, 'sticky');
      }
      else
      {
        this.renderer.removeClass(headerElm, 'sticky');
      }
    }
    //FOR STICKY HEADER |END

    
    lasestCount(){
      this.shareService.getCountItem.subscribe((res)=>{        
        this.countNumber = res;
        // console.log(res);
        
        
      })
    }

    isAccept:boolean = false
    isdeclined:boolean = false
    send_to:string = null;
    receive_request(pc, answer) {
      // console.log(pc)
      let send_by = localStorage.getItem('user_id');
      this.send_to = pc.content.sender_id;
  
      let view_room: any[];
      this.socket_service.Accept_decline(send_by, this.send_to, answer).subscribe(res => {
        console.log(res)
        view_room = res.message;
        if (answer == 'accept') {
          this.isAccept = true;
          this.isdeclined = false;
          this.ns.showNotification('success', "Thank you for accepting the request. Let's chat!");
        } else if (answer == 'decline') {
          this.isAccept = false;
          this.isdeclined = true;
          this.ns.showNotification('success', 'No worries, Thank you!');
        }
      },
        (err) => {
  
        })
    }

  ngDoCheck(){
    this.countNumber = localStorage.getItem('unread_msg');
  }

  resetMessageCount(){  
    localStorage.removeItem('unread_msg')
    this.countNumber = 0;
  }
}


