import { Component, OnInit, Input, Output, SimpleChanges, EventEmitter } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { CourseService } from '../../services/course.service';
import { NotificationService } from '../../services/notification.service';
import { Router } from '@angular/router';
import * as fileSaver from 'file-saver';
import { CourseWorkUtilityService } from '../../services/course-work-utility.service';

@Component({
  selector: 'app-course-work-marking-files',
  templateUrl: './course-work-marking-files.component.html',
  styleUrls: ['./course-work-marking-files.component.scss']
})
export class CourseWorkMarkingFilesComponent implements OnInit {

  @Input() reportIndex: any;
  @Input() userId: any;
  @Input() teamName: any;
  @Input() showCourseWork: any;
  @Input() courseworks: any;
  @Input() courseId: any;
  @Input() teachingAssistant: any;
  @Input() reviewSavedStatus: any;
  @Output() sendSubmissionData = new EventEmitter();

  progressBar: number = 0;
  role: string = '';
  loader: boolean = false;
  zipDownloading: boolean = false;
  viewSubmissionData: any[] = [];

  loggedInUserId: string = '';
  isAssistant: boolean = false;
  uploadedWorkFiles: any[] = [];
  submissionReportDetail: any = {};
  submissionPublishStatus: boolean = false;
  isFileDeleteAllowed: boolean = false;

  //FOR USER SUBMISSION
  submissionLoader: boolean = false;
  submissionsData: any[] = [];

  constructor(
    private courseService: CourseService,
    private notificationService: NotificationService,
    private router: Router,
    private cwUtilityService: CourseWorkUtilityService) { }

  ngOnInit() {
    this.role = localStorage.getItem('role');
    this.loggedInUserId = localStorage.getItem('user_id');
    this.viewCourseSubmission(this.showCourseWork['submission_report']);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.reviewSavedStatus.firstChange) {
      if (this.reviewSavedStatus.status == true) {
        this.viewCourseSubmission(this.showCourseWork['submission_report']);
      }
    }
  }

  submitSubmission(schedule_id, course_work_id, submission_report, reportIndex, fileId, courseworkIndex, team_name, autograding_id?: string) {
    let body = {
      course_id: this.courseId,
      schedule_id: schedule_id,
      course_work_id: course_work_id,
      file_id: fileId,
      "report_name": submission_report,
      "report_index": reportIndex,
      "team_name": team_name
    }

    if (autograding_id) {
      body['autograding_id'] = autograding_id;
    }

    this.loader = true;
    this.courseService.submit_course_work_submission(body).subscribe(getSubmissionResponse => {
      this.loader = false;
      let course_work = {
        'team_info': [],
        '_id': course_work_id,
        'schedule_id': schedule_id,
      }
      this.viewCourseSubmission(courseworkIndex, team_name);
      this.notificationService.showNotification('success', getSubmissionResponse.message);
    }, (err) => {
      this.notificationService.showNotification('error', err.message);
      this.loader = false;
    });
  }

  deleteUploadedWork(courseId, schedule_id, course_work_id, fileId, report_name, reportIndex, team_name) {
    this.loader = true;
    this.courseService.delete_resouce(courseId, schedule_id, course_work_id, fileId, report_name, reportIndex, team_name).pipe(finalize(() => {
    })).subscribe(req => {
      this.loader = false;
      this.notificationService.showNotification("success", "File deleted successfully.");
      this.viewCourseSubmission();
    }, (err) => {
      this.loader = false;
      console.log(err);
      this.notificationService.showNotification("error", "This coursework is Expired");
    });
  }

  viewCourseSubmission(courseworkIndex?: string, team_n?: string) {
    let course_work: any = null;
    course_work = this.courseworks;

    let team_name = '';
    if (course_work.is_group) {
      team_name = this.teamName;
    }

    if (this.isCourseWorkStarted(course_work.date, course_work.time)) {

      this.loader = true;
      this.courseService.viewSubmission(team_name, course_work._id, course_work.schedule_id, this.courseId, course_work.peer_review, course_work.self_review, 'grade_view').subscribe(viewSubmissions => {
        this.loader = false;
        this.viewSubmissionData = [];
        if (Object.keys(viewSubmissions.response).length > 0) {
          this.submissionsData = viewSubmissions.response;
          this.viewSubmissionData = this.getUserSubmission();
          if (this.viewSubmissionData.length > 0) {
            this.uploadedWorkFiles = this.viewSubmissionData[0].submissions[this.showCourseWork.submission_report].files;
            this.submissionReportDetail = this.viewSubmissionData[0].submissions[this.showCourseWork.submission_report];
            this.submissionPublishStatus = viewSubmissions.submission_publish;
            this.manageSubmissionReport();
          }
        }
        //SEND SUBMISSION DATA
        this.sendSubmissionData.emit({ viewSubmissionData: this.viewSubmissionData, submissionPublish: viewSubmissions.submission_publish });
      }, (err) => {
        this.loader = false;
        if (err.error.detail) {
          console.log(err.error.detail);
        }
        else {
          console.log(err);
        }
      });
    }
  }

  getUserSubmission() {
    let userSubmissionData = [];
    let findedSubmissionData: any = {};
    if (this.courseworks.is_group == true) {
      findedSubmissionData = this.submissionsData.find(item => {
        return item.name == this.teamName;
      });
    }
    else {
      findedSubmissionData = this.submissionsData.find(item => {
        return item.user_id == this.userId;
      });
    }
    userSubmissionData[0] = findedSubmissionData;
    return userSubmissionData;
  }

  //SHOW HID FILE DELETE BUTTON
  manageSubmissionReport() {
    this.isFileDeleteAllowed = false;
    let selfReviewReport = '';
    let peerReviewReport = '';
    let remarkReport = '';
    let gradeReport = '';

    if (this.courseworks.is_group == false) {
      if (this.submissionReportDetail.self_review.content) {
        selfReviewReport = this.submissionReportDetail.self_review.content;
      }

      if (this.submissionReportDetail.peer_review.content) {
        peerReviewReport = this.submissionReportDetail.peer_review.content;
      }
    }

    if (this.submissionReportDetail.remark.content) {
      remarkReport = this.submissionReportDetail.remark.content;
    }

    if (this.submissionReportDetail.grade.content) {
      gradeReport = this.submissionReportDetail.grade.content;
    }

    if (this.submissionPublishStatus == false && !this.isCourseWorkExpired()) {
      if (!selfReviewReport && !peerReviewReport && !remarkReport && !gradeReport) {
        this.isFileDeleteAllowed = true;
      }
    }
  }

  isCourseWorkStarted(dateValue, time) {
    return this.cwUtilityService.isCourseWorkStarted(dateValue, time);
  }

  isCourseWorkExpired() {
    const cwExpireDate = this.cwUtilityService.getExpireDate(this.courseworks.date, this.showCourseWork.days_of_completion);
    return this.cwUtilityService.isCourseWorkExpired(cwExpireDate, this.courseworks.time);
  }

  openUploadedWork(url) {
    window.open(url, '_blank');
  }

  //DOWNLOAD ZIP FILE |START
  getZipFile() {

    const submissionReportUserId = this.viewSubmissionData[0].user_id;
    const submissionReportUsername = this.viewSubmissionData[0].name;

    let team_name = '';
    let username = submissionReportUsername;
    if (this.courseworks.is_group) {
      team_name = submissionReportUsername;
      username = team_name;
    }
    this.zipDownloading = true;
    this.courseService.get_zip_submission(this.courseworks._id, this.courseworks.schedule_id, submissionReportUserId, team_name).subscribe(get_zip => {
      this.zipDownloading = false;
      let d = new Date();
      let fullDate: any = this.appendZero(d.getDate()) + '-' + this.appendZero((d.getMonth() + 1)) + '-' + d.getFullYear() + '_' + this.appendZero(d.getHours()) + this.appendZero(d.getMinutes()) + this.appendZero(d.getSeconds());
      let zip_file: any = new Blob([get_zip.body], { type: 'application/zip' });
      let fileName = username + '_' + fullDate + '.zip';
      fileSaver.saveAs(zip_file, fileName);
      this.notificationService.showNotification('success', 'Your Zip file is downloading')
    },
      (err) => {
        this.zipDownloading = false;
        if (err.message == undefined) {
          this.notificationService.showNotification('error', 'No submission is present');
          return;
        }
      });
  }

  appendZero(val) {
    if (val < 10) {
      return '0' + val;
    }
    return val;
  }
  //DOWNLOAD ZIP FILE |END
}
