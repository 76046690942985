import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  private apiEndpoint: string = '';
  public logo = new BehaviorSubject("");
  private notifyCountNum = new BehaviorSubject(0);
   notifyCountNumAs = this.notifyCountNum.asObservable();
  private isNotifyClick = new BehaviorSubject(false);
  isNotifyClickSub = this.isNotifyClick.asObservable();

  constructor(private httpClient: HttpClient) {
    this.apiEndpoint = environment.APIEndpoint;
  }



  SetNotifyCount(val) {
    this.notifyCountNum.next(val);
  }
  SetNotifyEvent(val) {
    this.isNotifyClick.next(val);
  }
  setLogo(imgurl) {
    this.logo.next(imgurl);
  }

  getLatestAvatar() {
    return this.httpClient.get<any>(this.apiEndpoint + '/profile_avatar');
  }

  getLatestCoin() {
    return this.httpClient.get<any>(this.apiEndpoint + '/coins');
  }

}
