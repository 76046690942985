<figure class="title-icon liveBlink_icon  d-inline-block" [ngClass]="liveSessionStatus == 'meeting_started'  ? 'status_live' : ''">
    <svg class="liveBlink" width="559" height="263" viewBox="0 0 559 263" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_7584_5355)">
        <path  d="M212.9 203.866V22.3438H508.921C534.408 23.8674 539.641 41.8923 534.055 67.0262C528.47 92.16 506.129 161.976 506.129 161.976C497.751 189.903 498.755 202.359 469.824 203.866H212.9Z" fill="#1C1C1C"/>
        </g>
        <path d="M240.583 178.844L229.658 140.135H237.2L244.174 168.581H244.533L251.98 140.135H258.841L266.307 168.6H266.647L273.621 140.135H281.163L270.238 178.844H263.321L255.571 151.684H255.269L247.501 178.844H240.583Z" fill="white"/>
        <path d="M287.09 178.844V140.135H312.265V146.014H294.102V156.522H310.961V162.401H294.102V172.966H312.417V178.844H287.09Z" fill="white"/>
        <path d="M320.484 178.844V140.135H335.302C338.099 140.135 340.424 140.576 342.277 141.458C344.141 142.328 345.534 143.519 346.454 145.031C347.386 146.543 347.852 148.256 347.852 150.172C347.852 151.747 347.55 153.095 346.945 154.216C346.34 155.325 345.527 156.226 344.507 156.919C343.486 157.612 342.346 158.11 341.086 158.412V158.79C342.459 158.866 343.776 159.288 345.036 160.057C346.309 160.813 347.348 161.884 348.155 163.27C348.961 164.656 349.364 166.332 349.364 168.298C349.364 170.301 348.879 172.103 347.909 173.703C346.939 175.291 345.477 176.545 343.524 177.464C341.571 178.384 339.114 178.844 336.153 178.844H320.484ZM327.496 172.985H335.037C337.583 172.985 339.416 172.5 340.538 171.53C341.672 170.547 342.239 169.287 342.239 167.749C342.239 166.603 341.955 165.57 341.388 164.65C340.821 163.717 340.015 162.986 338.969 162.457C337.923 161.915 336.676 161.644 335.227 161.644H327.496V172.985ZM327.496 156.598H334.433C335.642 156.598 336.732 156.377 337.703 155.936C338.673 155.483 339.435 154.846 339.99 154.027C340.557 153.196 340.84 152.213 340.84 151.079C340.84 149.579 340.311 148.345 339.252 147.374C338.207 146.404 336.65 145.919 334.584 145.919H327.496V156.598Z" fill="white"/>
        <path d="M363.541 140.135V178.844H356.529V140.135H363.541Z" fill="white"/>
        <path d="M404.009 140.135V178.844H397.772L379.533 152.478H379.211V178.844H372.199V140.135H378.474L396.695 166.521H397.035V140.135H404.009Z" fill="white"/>
        <path d="M417.685 178.844H410.2L423.828 140.135H432.484L446.131 178.844H438.646L428.307 148.074H428.005L417.685 178.844ZM417.931 163.667H438.344V169.299H417.931V163.667Z" fill="white"/>
        <path d="M452.294 178.844V140.135H466.81C469.783 140.135 472.278 140.652 474.294 141.685C476.323 142.718 477.854 144.168 478.887 146.032C479.933 147.885 480.456 150.046 480.456 152.515C480.456 154.998 479.927 157.152 478.868 158.979C477.823 160.794 476.279 162.199 474.238 163.194C472.196 164.177 469.689 164.669 466.715 164.669H456.376V158.847H465.77C467.509 158.847 468.933 158.608 470.042 158.129C471.151 157.638 471.97 156.926 472.499 155.993C473.041 155.048 473.312 153.889 473.312 152.515C473.312 151.142 473.041 149.97 472.499 149C471.957 148.017 471.132 147.274 470.023 146.77C468.914 146.253 467.484 145.995 465.732 145.995H459.306V178.844H452.294ZM472.291 161.304L481.874 178.844H474.049L464.636 161.304H472.291Z" fill="white"/>
        <path d="M250.724 111.821V50.2686H263.738V101.091H290.126V111.821H250.724Z" fill="white"/>
        <path d="M321.914 50.2686V111.821H308.9V50.2686H321.914Z" fill="white"/>
        <path d="M353.897 50.2686L368.774 97.0337H369.345L384.253 50.2686H398.679L388.069 81.0446L377.46 111.821H360.69L339.441 50.2686H353.897Z" fill="white"/>
        <path d="M416.243 111.821V50.2686H457.719V60.9981H429.257V75.6648H455.585V86.3943H429.257V101.091H457.839V111.821H416.243Z" fill="white"/>
        <path id="blinkPath0" d="M215.691 22.3412V0L243.618 22.3412H215.691Z" fill="#D71C3B"/>
        <g filter="url(#filter1_d_7584_5355)"  class="blinkBg">
        <path id="blinkPath1" d="M215.693 198.278V0H42.5486C31.378 0 23 5.5853 23 22.3412V198.278C22.9979 212.241 31.378 217.827 42.5486 217.827H196.144C212.9 217.827 215.693 215.034 215.693 198.278Z" fill="#F41C33"/>
        </g>
        <path id="blinkPath2"  d="M159.843 128.429V92.1246L173.806 80.954C179.391 75.4035 184.977 72.6116 184.977 80.954V142.392C182.579 144.982 180.642 145.198 176.599 142.392L159.843 128.429Z" fill="white"/>
        <path  id="blinkPath3" d="M137.879 72.6119C129.728 72.6106 72.6671 72.6131 64.5156 72.6119C56.364 72.6107 50.9297 72.6113 50.9297 86.058V131.775C50.9297 142.531 56.364 145.22 67.2327 145.22H137.879C146.031 145.22 151.465 142.531 151.465 131.775V86.058C151.465 75.3011 146.031 72.6132 137.879 72.6119Z" fill="white"/>
        <defs>
        <filter id="filter0_d_7584_5355" x="190.559" y="22.3438" width="367.86" height="226.205" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="22.3412"/>
        <feGaussianBlur stdDeviation="11.1706"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7584_5355"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_7584_5355" result="shape"/>
        </filter>
        <filter id="filter1_d_7584_5355" x="0.658796" y="0" width="237.376" height="262.51" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="22.3412"/>
        <feGaussianBlur stdDeviation="11.1706"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7584_5355"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_7584_5355" result="shape"/>
        </filter>
        </defs>
    </svg>
</figure>
