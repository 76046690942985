<div class="new_UIstyle">
<div class="pageBodyWrap">
    <!-- banner section -->
    <div class="banner banner-gradient banner-C-assessment"  [ngClass]="titleVisible ? 'd-none':'d-block'">
        <div class="container">
            <div class="banner-content">
                <div class="banner-content-content">
                    <h2 class="white-text avenirBlack">{{assessmentForm.get('title').value | capitalization}}</h2>
                    <!-- <p class="white-text">Group Assignment: <strong class="ms-2 primary-text">Student - Led</strong></p> -->
                    <div class="tags-badges">
                        <span class="badge badge-light badge-primary" >{{draft}}</span>
                    </div>
                </div>
                <div class="banner-content-action btn-groups">
                   <button class="btn btn-primary" (click)="backTitle()">Back</button>
              </div>
            </div>
        </div>
    </div>

    <section class="section create-session-wrap">
        <div class="container">
            <form class="typo-16" [formGroup]="assessmentForm" (ngSubmit)="onSubmit(buttonType)">

                <!-- Add Title assessment -->
                <div class="add-assesssment-block" [ngClass]="titleVisible ? 'd-block':'d-none' ">
                <div class="row justify-content-center">
                    <div class="col-md-10 col-lg-8 m-auto">
                          <h2>What is the title of the Assessment?</h2>
                          <p>It's ok if can't think of a good title right now, You can always change it later</p>
                          <div>
                              <input class="form-control form-control-lg form-control-style-2" type="text"
                              maxlength="50" formControlName="title"  [value]="assessmentForm.get('title').value | capitalization" 
                             [ngClass]="{ 'is-invalid': submitAssessmentForm && aF.title.errors }"/>

                             <div *ngIf="submitAssessmentForm && aF.title.errors" class="alert alert-danger mb-0">
                                <div *ngIf="aF.title.errors.required">
                                    Title is required
                              </div>
                            </div>

                              <div class="btn-groups justify-content-center mt-4">
                                  <button type="button" class="btn btn-grey btn-w-100" (click)="discardTitle()">Discard</button>
                                  <button type="button" class="btn btn-primary btn-w-100"  (click)='nextStep()'>Next</button>
                              </div>
                            </div>
                      </div>
                  </div>
                </div>

                <!-- assessment main form sturcture -->
                <div class="add-assessment-secound" [ngClass]="titleVisible ? 'd-none':'d-block'">

                <div class="row">
                    <div class="col-md-12 mb-3">
                        <div class="create-session-wrap">
                           <div class="row">
                            <div class="col-md-12 mb-3 mb-lg-4">
                                <label class="avenirBlack"><strong>Assessment Name</strong></label>
                                <input type="text" class="form-control form-control-lg form-control-style-2 "
                                    [value]="assessmentForm.get('title').value | capitalization"
                                    [ngClass]="{ 'is-invalid': submitAssessmentForm && assessmentForm.controls.title.errors }"
                                    placeholder=" " formControlName="title" >
                                    
                                <div *ngIf="submitAssessmentForm && aF.title.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="aF.title.errors.required"> Title is required</div>
                                </div>
                           
                        </div>
                           </div>
                                <div class="row">
                                    <div class="col-md-6 mb-3 mb-lg-4">
                                        <label class="avenirBlack"><strong>Assessment Type</strong></label>

                                        <select
                                            class="form-control form-control-lg form-control-style-2 form-control-caret"
                                            formControlName="assessmentType">
                                            <option [selected]="assmentType == true" value="">Select Asessment Type</option>
                                            <option value="formative">Formative</option>
                                            <option value="summative">Summative</option>
                                        </select>

                                        <div *ngIf="assessmentForm.controls['assessmentType'].invalid && assessmentForm.controls['assessmentType'].touched" class="alert alert-danger mb-0">
                                            <div *ngIf="assessmentForm.controls['assessmentType'].errors.required">
                                                Assessment Type is required
                                          </div>
                                        </div>

                                    </div>

                                    <div class="col-md-6 mb-3 mb-lg-4">
                                        <label class="avenirBlack"><strong>Total Time (In Minutes)</strong></label>

                                        <input type='text' class="form-control form-control-lg form-control-style-2 "
                                            (input)="stripText(assessmentForm.get('totaltime'))" placeholder="Total Time"
                                            formControlName="totaltime" />
                                            <div *ngIf="assessmentForm.controls['totaltime'].invalid && assessmentForm.controls['totaltime'].touched" class="alert alert-danger mb-0">
                                                <div *ngIf="assessmentForm.controls['totaltime'].errors.required">
                                                    Total time is required
                                              </div>
                                            </div>

                                    </div>
                                </div>
                                <hr />
                                <div class="row ">
                                    <div class="col-md-12">
                                        <label class="avenirBlack"><strong>Assessment Questions</strong></label>

                                        <div class="mb-2" *ngFor="let aq of assessmentQus; let i = index;">
                                            <div class="qustionMarkList" >
                                                <div class="qustionMark-item bg-grey">
                                                    <div class="row align-items-lg-center">
                                                        <div class="col-lg-8 qustionMark-item-left">
                                                            <p><strong class="black-text">{{aq.filtered_question}}</strong></p>
                                                        </div>
                                                        <div class="col-lg-4 mt-2 mt-md-2 qustionMark-item-right">
                                                            <div class="me-md-4 mr-2 d-flex align-items-center">
                                                                <label class="mb-0 mr-2"><strong >Marks:</strong></label>
                                                                <input type="text" class="form-control w-100px"
                                                                    [value]="aq.marks?aq.marks:0" (keyup)="inputMarks(i,$event)" (keypress) = "numberOnly($event)"/>
                                                            </div>
                                                            <div class="btn-groups">
                                                                <button class="btn-transparent" (click)="removeQues(i)"><i
                                                                        class="fas fa-trash"></i></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-2">
                                            <ul ngbNav #aQNab="ngbNav" [(activeId)]="active" class="nav-tabs nav-tabs-custom">
                                               <!-- <li [ngbNavItem]="1" >
                                                        <a ngbNavLink>Create New Question</a>
                                                        <ng-template ngbNavContent>
                                                            <div class="tab-pane-inner">
                                                                <form>
                                                                    <div class="row">
                                                                        <div class="col-md-6 mb-3  mb-lg-4">
                                                                            <label class="avenirBlack"><strong>Course
                                                                                    Category</strong></label>
                                                                            
                                                                            <select class="form-control form-control-lg form-control-style-2 form-control-caret" formControlName="course"
                                                                            [ngClass]="{ 'is-invalid': submitAddQus && addQus.course.errors }">
                                                                            <option selected value="">Select option</option>
                                                                            <option *ngFor="let course of courses" [value]="course._id">
                                                                                {{course.Course_Category}}
                                                                            </option>
                                                                        </select>
                                                                        
                                                                        <div *ngIf="submitAddQus && addQus.course.errors" class="invalid-feedback">
                                                                            <div *ngIf="addQus.course.errors.required"> Please select any course</div>
                                                                        </div>
                                                                        </div>
                                                                        <div class="col-md-6 mb-3  mb-lg-4">
                                                                            <label class="avenirBlack"><strong>Question
                                                                                    Type</strong></label>
                                                                            <select
                                                                                class="form-control form-control-lg form-control-style-2 form-control-caret"
                                                                                [ngClass]="{ 'is-invalid': submitAddQus && addQus.type.errors }"
                                                                                (change)="selectQtype($event.target.value,0,'addques')">
                                                                                <option selected value="">Select option
                                                                                </option>
                                                                                <option *ngFor="let qt of qusTypes"
                                                                                    [value]="qt.value">
                                                                                    {{qt.name}}
                                                                                </option>
                                                                            </select>
                                                                         
                                                                        </div>

                                                                        <div class="col-md-12 mb-3  mb-lg-4">
                                                                            <label
                                                                                class="avenirBlack"><strong>Question</strong></label>
                                                                            <textarea
                                                                                class="form-control form-control-style-2 form-control-textarea"
                                                                                placeholder="Enter a little Desccription"></textarea>
                                                                        </div>

                                                                        <div class="col-md-12 mb-3  mb-lg-4">
                                                                            <label class="avenirBlack"><strong>Modal
                                                                                    Answer</strong></label>
                                                                            <textarea
                                                                                class="form-control form-control-style-2 form-control-textarea"
                                                                                placeholder="Enter a little Desccription"></textarea>
                                                                        </div>

                                                                        <div class="col-md-12 mb-3  mb-lg-4">
                                                                            <label
                                                                                class="avenirBlack"><strong>Tags</strong></label>
                                                                            <input type="text"
                                                                                class="form-control form-control-lg form-control-style-2"
                                                                                placeholder="Enter tags seperated by comma" />
                                                                        </div>

                                                                        <div class="col-md-6 mb-3  mb-lg-4">
                                                                            <label class="avenirBlack"><strong>Question
                                                                                    Duration</strong></label>
                                                                            <div class="form-row d-flex">
                                                                                <div class="col me-2">
                                                                                    <input type="text"
                                                                                        class="form-control form-control-lg form-control-style-2"
                                                                                        placeholder="Enter duration of question" />
                                                                                </div>
                                                                                <div class="col">
                                                                                    <select
                                                                                        class="form-control form-control-lg form-control-style-2 form-control-caret"
                                                                                        [ngClass]="{ 'is-invalid': submitAddQus && addQus.type.errors }"
                                                                                        (change)="selectQtype($event.target.value,0,'addques')">
                                                                                        <option selected value="">Select
                                                                                            option
                                                                                        </option>
                                                                                        <option
                                                                                            *ngFor="let qt of qusTypes"
                                                                                            [value]="qt.value">
                                                                                            {{qt.name}}
                                                                                        </option>
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-md-6 mb-3  mb-lg-4">
                                                                            <label
                                                                                class="avenirBlack"><strong>Complexity</strong></label>
                                                                        

                                                                            <select
                                                                                class="form-control form-control-lg form-control-style-2 form-control-caret"
                                                                                formControlName="complexity"
                                                                                [ngClass]="{ 'is-invalid': submitAddQus && addQus.complexity.errors }">
                                                                                <option selected value="">Select option
                                                                                </option>
                                                                                <option
                                                                                    *ngFor="let cmplxty of complexities"
                                                                                    [value]="cmplxty._id">
                                                                                    {{cmplxty.name}}
                                                                                </option>
                                                                            </select>
                                                                          
                                                                            <div *ngIf="submitAddQus && addQus.complexity.errors"
                                                                                class="invalid-feedback">
                                                                                <div
                                                                                    *ngIf="addQus.complexity.errors.required">
                                                                                    Complexity is required</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <div class="btn-groups justify-content-end">
                                                                        <button
                                                                            class="btn btn-primary-border btn-w-120">Cancel</button>
                                                                        <button
                                                                            class="btn btn-primary btn-w-120">Save</button>
                                                                    </div>

                                                                </form>
                                                            </div>
                                                        </ng-template>
                                                    </li> -->
                                                <li [ngbNavItem]="2">
                                                    <a ngbNavLink>Add from library</a>
                                                    <ng-template ngbNavContent>
                                                        <div class="tab-pane-inner">
                                                            <div class="border-box-content">
                                                                <form class="typo-16">
                                                                    <div class="input-with-icon input-with-icon-right">
                                                                        <input type="text"
                                                                        class="form-control form-control-lg"
                                                                        autocomplete="off"
                                                                        placeholder="Search question and select from list below"  #searchInput/>
                                                                        <button class="input-with-icon-btn"><i
                                                                                class="fa fa-search"></i></button>
                                                                    </div>

                                                                    <div class="question-library-list blue-check mt-4 relative" [ngClass]="{submitting : loader && qusList?.length === 0}">
                                    
                                                                        <ng-container *ngIf=isQusAvailable>
                                                                            <div class="text-center" *ngIf="loader && qusList?.length === 0">
                                                                                <div class="spinner-border text-warning" role="status">
                                                                                    <span class="sr-only">Loading...</span>
                                                                                </div>
                                                                            </div>
                                                                            <div class="form-check mb-2" *ngFor="let ql of qusList; let i = index;">
                                                                                <input class="form-check-input"
                                                                                    type="checkbox" 
                                                                                    name="flexRadioDefault"
                                                                                    [id]="'filter-QB-items-check' + (i)" 
                                                                                    [(ngModel)]="ql.Checked" 
                                                                                    (change)="getCheckboxValues($event,ql)" />
                                                                                <label class="form-check-label" [for]="'filter-QB-items-check' + (i)">
                                                                                    {{ql.filtered_question}}
                                                                                </label>
                                                                            </div>
                                                                        </ng-container>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                </li>
                                            </ul>
                                            <div [ngbNavOutlet]="aQNab"></div>
                                        </div>
                                    </div>
                                </div>

                                    <div class="btn-groups justify-content-end">
                                        <button type="button" (click)="getQusForAssesment()" class="btn btn-primary btn-w-120"> Select
                                        </button>
                                    </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="col-md-12">                        
                        <div class="row">
                            <div class="col-md-6 col-lg-3">
                                <div class="mb-4">
                                    <div class="form-group">
                                        <label class="avenirBlack"><strong>Marks</strong></label>
                                        <input type="text"  maxlength="5" class="w-200px form-control form-control-lg form-control-style-2"
                                        formControlName="totalMarks" (keyup)="onTotalMarks()" (keypress) = "numberOnly($event)" readonly />
                                    </div>
                                </div>

                                <div class="mb-3" >
                                    <div class="form-check form-switch form-switch-right mb-3 mb-md-3">
                                        <label class="form-check-label mr-2" for="negMrk">Negative
                                            Marking</label>
                                        <input class="form-check-input" type="checkbox" id="negMrk"
                                        formControlName="negativeMarking" (change)="onCheck()">
                                    </div>
                                    <div class="form-group mb-2" *ngIf="assessmentForm.value['negativeMarking']">
                                        <label class="avenirBlack"><strong>Negative Marks</strong></label>
                                        <input type="text" class="w-200px form-control form-control-lg form-control-style-2"
                                        formControlName="negativeValue" (keypress) = "numberOnly($event)" />
                                        <div *ngIf="assessmentForm.controls.negativeValue.errors?.required || assessmentForm.controls.negativeValue.errors?.min || assessmentForm.controls.negativeValue.errors?.max"
                                        class="text-danger mb-1"><small>Value is between 0 and 1.</small></div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

                <hr />
                <div class="btn-groups justify-content-end">
                    <button type="button" class="btn btn-primary-border btn-w-120" (click)="discardAssessment()" [disabled]="!assessmentForm.valid">Discard</button>
                    <button type="button" class="btn btn-primary-border btn-w-120" name="save" [disabled]="!assessmentForm.valid"
                        *ngIf="save_button" (click)="onSubmit('save')">Save as draft</button>
                    <button type="submit" class="btn btn-primary btn-w-120" [disabled]="!assessmentForm.valid"
                        (click)="onSubmit('publish')">Publish</button>
                </div>
               </div>
            </form>
        </div>
    </section>
</div>

</div>

