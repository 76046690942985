import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'formatTime',
  })
  export class FormatTimePipe implements PipeTransform {
    transform(value: number): string {      
      const hour:number = Math.floor(value / 3600);
      const minutes: number = (Math.floor(value / 60) - (hour * 60));
      const seconds: number = Math.floor(value % 60)
      return (
        ('00' + hour).slice(-2) +
        ':' +
        ('00' + minutes).slice(-2) +
        ':' +
        ('00' + seconds).slice(-2)
      );
    }
  }
