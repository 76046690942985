import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
  name: 'dateFormatPipe'
})
export class DateFormatPipe implements PipeTransform {
  constructor() { }
  transform(date: Object): string {
    let convertedDate = this.getVideoId(date);
    return convertedDate;
  }

  getVideoId(date) {
    if (date) {
      if (date.day) {
        let dateOfMont = date.day;
        let month = date.month;
        let year = date.year;
        date = dateOfMont + "/" + month + "/" + year;
        let dayFromMList = this.month_name(month)
        
        date = dateOfMont+" " + dayFromMList +" " +year;
      } else if (!date.day) {
        date = date.split(',');
      }
    }
    return date;
  }


  month_name(dt) { 
    let mlist = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
    return mlist[dt-1];
  };
}