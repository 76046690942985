<div class="pageBodyWrap new_UIstyle">
  <section class="section notification-wrap">
      <div class="container">
          <div class="notification-block">
           <div class="title d-flex align-items-center justify-content-between">
               <h3 class="avenirBlack mb-0">Notifications</h3>
               <div ngbDropdown class="dropdown title-sub-right toggle-none">
                  <a ngbDropdownToggle id="navbarDropdown" role="button" class="data-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="fas fa-cog"></i></a>
                  <div ngbDropdownMenu class="dropdown-menu dropdown-menu-regular dropdown-menu-end dropdown-menu-setting" aria-labelledby="navbarDropdown"> 
                      <ul>
                          <li><a href="javascript:void(0);"  (click)="showHideNotification()" class="dropdown-item">
                            <div class="fig-cont">
                              <figure><i class="fas {{!isNotifiyShow ? 'fa-bell':'fa-bell-slash'}}"></i></figure>
                              <p><strong>Turn {{!isNotifiyShow ? 'on':'off'}}</strong>
                              <small class="d-block">
                                {{!isNotifiyShow ? 'Start':'Stop'}} receiving notifications</small>
                              </p>
                          </div>
                          </a></li>
                      </ul>
                  </div>
               </div>
           </div>
           <hr />
           <div class="notification-all-list-wrap" >
            <div class="text-center" *ngIf="notifyLoader">
              <div class="spinner-border text-warning formLoader" role="status" >
                  <span class="sr-only">Loading...</span>
              </div>
          </div>
          <div class="mb-1" *ngFor="let notification of notificationList | paginate: { itemsPerPage: itemPerPage, currentPage: currentPage, totalItems:totalPages }; index as i;">
            <div class="drop-item-list notification-all-list" [ngClass]="notification.read_status == false ? 'notification-items-unread':''">
                           
              <ng-container  *ngIf="notification.type == 'TA_assignment'">
                <div class="drop-item-list-left">
                  <figure class="drop-item-list-figure">
                    <img *ngIf="notification.image !== ''" [src]="notification.image" alt="Avtar Image" />
                    <img *ngIf="notification.image == '' "[src]="defaultAvtar" alt="Avtar Image" />
                  </figure> 
                  <div class="drop-item-list-content">
                    <p>You received TA assignment notification from <a href="javascript:void(0);" (click)="redirectTo(notification)"><strong>{{notification.content.subject}}</strong></a> course.</p>
                    <!-- <p><small>Trends in Cloud Computing and DevOps for 2022 Powered by Restream</small></p> -->
                  </div>
                </div>
                <div class="drop-item-list-right">
                  <p class="mb-0"><small>{{notification.created_at | date:'short'}}</small></p>
                  <div ngbDropdown class="dropdown title-sub-right toggle-none">
                     <a ngbDropdownToggle id="navbarDropdown" role="button" class="data-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="fas fa-ellipsis-h"></i></a>
                     <div ngbDropdownMenu class="dropdown-menu dropdown-menu-regular dropdown-menu-end dropdown-menu-setting" aria-labelledby="navbarDropdown"> 
                         <ul>
                             <li><a href="javascript:void(0)" (click)="deleteNotification(notification)" class="dropdown-item">
                                 <div class="fig-cont">
                                     <figure><i class="far fa-trash-alt"></i></figure>
                                     <p><strong>Delete</strong>
                                         <small class="d-block">Delete this notification</small>
                                     </p>
                                  </div>
                             </a></li>
                         </ul>
                     </div>
                  </div>
                </div>
              </ng-container>
              
              
              <ng-container  *ngIf="notification.type == 'topic'">
                <div class="drop-item-list-left">
                  <figure class="drop-item-list-figure">
                    <img *ngIf="notification.image !== ''" [src]="notification.image" alt="Avtar Image" />
                    <img *ngIf="notification.image == '' "[src]="defaultAvtar" alt="Avtar Image" />
                  </figure> 
                  <div class="drop-item-list-content">
                    <p>You received topic notification for <a href="javascript:void(0);" (click)="redirectTo(notification)"><strong>{{notification.content.topic}}</strong></a> from course  <a href="javascript:void(0);" (click)="redirectTo(notification)"><strong>{{notification.content.course_name}}</strong></a>.</p>
                    <!-- <p><small>Trends in Cloud Computing and DevOps for 2022 Powered by Restream</small></p> -->
                  </div>
                </div>
                <div class="drop-item-list-right">
                  <p class="mb-0"><small>{{notification.created_at | date:'short'}}</small></p>
                  <div ngbDropdown class="dropdown title-sub-right toggle-none">
                     <a ngbDropdownToggle id="navbarDropdown" role="button" class="data-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="fas fa-ellipsis-h"></i></a>
                     <div ngbDropdownMenu class="dropdown-menu dropdown-menu-regular dropdown-menu-end dropdown-menu-setting" aria-labelledby="navbarDropdown"> 
                         <ul>
                             <li><a href="javascript:void(0)" (click)="deleteNotification(notification)" class="dropdown-item">
                                 <div class="fig-cont">
                                     <figure><i class="far fa-trash-alt"></i></figure>
                                     <p><strong>Delete</strong>
                                         <small class="d-block">Delete this notification</small>
                                     </p>
                                  </div>
                             </a></li>
                         </ul>
                     </div>
                  </div>
                </div>
              </ng-container>


              <ng-container  *ngIf="notification.type == 'question'">
                <div class="drop-item-list-left">
                  <figure class="drop-item-list-figure">
                    <img *ngIf="notification.image !== ''" [src]="notification.image" alt="Avtar Image" />
                    <img *ngIf="notification.image == '' "[src]="defaultAvtar" alt="Avtar Image" />
                  </figure> 
                  <div class="drop-item-list-content">
                    <p>You received question notification for <a href="javascript:void(0);" (click)="redirectTo(notification)"><strong>{{notification.content.question}}</strong></a>.</p>
                    <!-- <p><small>Trends in Cloud Computing and DevOps for 2022 Powered by Restream</small></p> -->
                  </div>
                </div>
                <div class="drop-item-list-right">
                  <p class="mb-0"><small>{{notification.created_at | date:'short'}}</small></p>
                  <div ngbDropdown class="dropdown title-sub-right toggle-none">
                     <a ngbDropdownToggle id="navbarDropdown" role="button" class="data-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="fas fa-ellipsis-h"></i></a>
                     <div ngbDropdownMenu class="dropdown-menu dropdown-menu-regular dropdown-menu-end dropdown-menu-setting" aria-labelledby="navbarDropdown"> 
                         <ul>
                             <li><a href="javascript:void(0)" (click)="deleteNotification(notification)" class="dropdown-item">
                                 <div class="fig-cont">
                                     <figure><i class="far fa-trash-alt"></i></figure>
                                     <p><strong>Delete</strong>
                                         <small class="d-block">Delete this notification</small>
                                     </p>
                                  </div>
                             </a></li>
                         </ul>
                     </div>
                  </div>
                </div>
              </ng-container>


              <ng-container  *ngIf="notification.type == 'course_coursework'">
                <div class="drop-item-list-left">
                  <figure class="drop-item-list-figure">
                    <img *ngIf="notification.image !== ''" [src]="notification.image" alt="Avtar Image" />
                    <img *ngIf="notification.image == '' "[src]="defaultAvtar" alt="Avtar Image" />
                  </figure> 
                  <div class="drop-item-list-content">
                    <p>You received coursework notification for  <a href="javascript:void(0);" (click)="redirectTo(notification)"><strong>{{notification.content.course_name}}</strong></a>  course.</p>
                    <!-- <p><small>Trends in Cloud Computing and DevOps for 2022 Powered by Restream</small></p> -->
                  </div>
                </div>
                <div class="drop-item-list-right">
                  <p class="mb-0"><small>{{notification.created_at | date:'short'}}</small></p>
                  <div ngbDropdown class="dropdown title-sub-right toggle-none">
                     <a ngbDropdownToggle id="navbarDropdown" role="button" class="data-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="fas fa-ellipsis-h"></i></a>
                     <div ngbDropdownMenu class="dropdown-menu dropdown-menu-regular dropdown-menu-end dropdown-menu-setting" aria-labelledby="navbarDropdown"> 
                         <ul>
                             <li><a href="javascript:void(0)" (click)="deleteNotification(notification)" class="dropdown-item">
                                 <div class="fig-cont">
                                     <figure><i class="far fa-trash-alt"></i></figure>
                                     <p><strong>Delete</strong>
                                         <small class="d-block">Delete this notification</small>
                                     </p>
                                  </div>
                             </a></li>
                         </ul>
                     </div>
                  </div>
                </div>
              </ng-container>


              <ng-container  *ngIf="notification.type == 'coursework'">
                <div class="drop-item-list-left">
                  <figure class="drop-item-list-figure">
                    <img *ngIf="notification.image !== ''" [src]="notification.image" alt="Avtar Image" />
                    <img *ngIf="notification.image == '' "[src]="defaultAvtar" alt="Avtar Image" />
                  </figure> 
                  <div class="drop-item-list-content">
                    <p>You have only one day left for submission for <strong>{{notification.content.title}}</strong> in <strong>{{notification.content.course_name}}</strong>  course.</p>
                    <!-- <p><small>Trends in Cloud Computing and DevOps for 2022 Powered by Restream</small></p> -->
                  </div>
                </div>
                <div class="drop-item-list-right">
                  <p class="mb-0"><small>{{notification.created_at | date:'short'}}</small></p>
                  <div ngbDropdown class="dropdown title-sub-right toggle-none">
                     <a ngbDropdownToggle id="navbarDropdown" role="button" class="data-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="fas fa-ellipsis-h"></i></a>
                     <div ngbDropdownMenu class="dropdown-menu dropdown-menu-regular dropdown-menu-end dropdown-menu-setting" aria-labelledby="navbarDropdown"> 
                         <ul>
                             <li><a href="javascript:void(0)" (click)="deleteNotification(notification)" class="dropdown-item">
                                 <div class="fig-cont">
                                     <figure><i class="far fa-trash-alt"></i></figure>
                                     <p><strong>Delete</strong>
                                         <small class="d-block">Delete this notification</small>
                                     </p>
                                  </div>
                             </a></li>
                         </ul>
                     </div>
                  </div>
                </div>
              </ng-container>

              <ng-container  *ngIf="notification.type == 'chat_request'">
                <div class="drop-item-list-left">
                  <figure class="drop-item-list-figure">
                    <img *ngIf="notification.image !== ''" [src]="notification.image" alt="Avtar Image" />
                    <img *ngIf="notification.image == '' " [src]="defaultAvtar" alt="Avtar Image" />
                  </figure> 
                  <div class="drop-item-list-content">
                    <p>You received a friend request from  <a href="javascript:void(0);" (click)="redirectTo(notification)"><b>{{notification.content.sender_name}}</b></a></p>
                    <!-- <div class="btn-groups mt-2">
                      <button class="btn btn-primary btn-blue btn-sm" (click)="receive_request(notification,'accept')" [ngClass]="isAccept?'disabled':''" *ngIf="!isdeclined">{{isAccept ? 'Accepted':'Accept'}}</button>
                      <button class="btn btn-primary btn-blue btn-sm" (click)="receive_request(notification,'decline')" [ngClass]="isdeclined?'disabled':''" *ngIf="!isAccept">{{ isdeclined ? 'Declined':'Decline'}}</button>
                    </div> -->
                    <!-- <p><small>Trends in Cloud Computing and DevOps for 2022 Powered by Restream</small></p> -->
                  </div>
                </div>
                <div class="drop-item-list-right">
                  <p class="mb-0"><small>{{notification.created_at | date:'short'}}</small></p>
                  <div ngbDropdown class="dropdown title-sub-right toggle-none">
                     <a ngbDropdownToggle id="navbarDropdown" role="button" class="data-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="fas fa-ellipsis-h"></i></a>
                     <div ngbDropdownMenu class="dropdown-menu dropdown-menu-regular dropdown-menu-end dropdown-menu-setting" aria-labelledby="navbarDropdown"> 
                         <ul>
                             <li><a href="javascript:void(0)" (click)="deleteNotification(notification)" class="dropdown-item">
                                 <div class="fig-cont">
                                     <figure><i class="far fa-trash-alt"></i></figure>
                                     <p><strong>Delete</strong>
                                         <small class="d-block">Delete this notification</small>
                                     </p>
                                  </div>
                             </a></li>
                         </ul>
                     </div>
                  </div>
                </div>
              </ng-container>


              <ng-container  *ngIf="notification.type == 'chat_invite'">
                <div class="drop-item-list-left">
                  <figure class="drop-item-list-figure">
                    <img *ngIf="notification.image !== ''" [src]="notification.image" alt="Avtar Image" />
                    <img *ngIf="notification.image == '' " [src]="defaultAvtar" alt="Avtar Image" />
                  </figure> 
                  <div class="drop-item-list-content">
                    <p>{{notification.receiver_name}} <span *ngIf="notification.subtype == 'reject'">rejected</span> <span *ngIf="notification.subtype == 'accept'">accepted</span> your request.</p>
                    <!-- <div class="btn-groups mt-2">
                      <button class="btn btn-primary btn-blue btn-sm" (click)="receive_request(notification,'accept')" [ngClass]="isAccept?'disabled':''" *ngIf="!isdeclined">{{isAccept ? 'Accepted':'Accept'}}</button>
                      <button class="btn btn-primary btn-blue btn-sm" (click)="receive_request(notification,'decline')" [ngClass]="isdeclined?'disabled':''" *ngIf="!isAccept">{{ isdeclined ? 'Declined':'Decline'}}</button>
                    </div> -->
                    <!-- <p><small>Trends in Cloud Computing and DevOps for 2022 Powered by Restream</small></p> -->
                  </div>
                </div>
                <div class="drop-item-list-right">
                  <p class="mb-0"><small>{{notification.created_at | date:'short'}}</small></p>
                  <div ngbDropdown class="dropdown title-sub-right toggle-none">
                     <a ngbDropdownToggle id="navbarDropdown" role="button" class="data-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="fas fa-ellipsis-h"></i></a>
                     <div ngbDropdownMenu class="dropdown-menu dropdown-menu-regular dropdown-menu-end dropdown-menu-setting" aria-labelledby="navbarDropdown"> 
                         <ul>
                             <li><a href="javascript:void(0)" (click)="deleteNotification(notification)" class="dropdown-item">
                                 <div class="fig-cont">
                                     <figure><i class="far fa-trash-alt"></i></figure>
                                     <p><strong>Delete</strong>
                                         <small class="d-block">Delete this notification</small>
                                     </p>
                                  </div>
                             </a></li>
                         </ul>
                     </div>
                  </div>
                </div>
              </ng-container>


              <ng-container  *ngIf="notification.type == 'coursework_invite'">
                <div class="drop-item-list-left">
                  <figure class="drop-item-list-figure">
                    <img *ngIf="notification.image !== ''" [src]="notification.image" alt="Avtar Image" />
                    <img *ngIf="notification.image == '' "[src]="defaultAvtar" alt="Avtar Image" />
                  </figure> 
                  <div class="drop-item-list-content">
                    <p>You received coursework invite notification for <a href="javascript:void(0);" (click)="redirectTo(notification)"><strong>{{notification.content.team_name}}</strong></a> team  from <a href="javascript:void(0);" (click)="redirectTo(notification)"><strong>{{notification.content.course_name}} </strong></a> course.</p>
                    <!-- <p><small>Trends in Cloud Computing and DevOps for 2022 Powered by Restream</small></p> -->
                  </div>
                </div>
                <div class="drop-item-list-right">
                  <p class="mb-0"><small>{{notification.created_at | date:'short'}}</small></p>
                  <div ngbDropdown class="dropdown title-sub-right toggle-none">
                     <a ngbDropdownToggle id="navbarDropdown" role="button" class="data-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="fas fa-ellipsis-h"></i></a>
                     <div ngbDropdownMenu class="dropdown-menu dropdown-menu-regular dropdown-menu-end dropdown-menu-setting" aria-labelledby="navbarDropdown"> 
                         <ul>
                             <li><a href="javascript:void(0)" (click)="deleteNotification(notification)" class="dropdown-item">
                                 <div class="fig-cont">
                                     <figure><i class="far fa-trash-alt"></i></figure>
                                     <p><strong>Delete</strong>
                                         <small class="d-block">Delete this notification</small>
                                     </p>
                                  </div>
                             </a></li>
                         </ul>
                     </div>
                  </div>
                </div>
              </ng-container>

              
              <ng-container  *ngIf="notification.type == 'course_assessment'">
                <div class="drop-item-list-left">
                  <figure class="drop-item-list-figure">
                    <img *ngIf="notification.image !== ''" [src]="notification.image" alt="Avtar Image" />
                    <img *ngIf="notification.image == '' "[src]="defaultAvtar" alt="Avtar Image" />
                  </figure> 
                  <div class="drop-item-list-content">
                    <p>You received assessment notification for  <a href="javascript:void(0);" (click)="redirectTo(notification)"><strong>{{notification.content.course_name}}</strong></a>  course.</p>
                    <!-- <p><small>Trends in Cloud Computing and DevOps for 2022 Powered by Restream</small></p> -->
                  </div>
                </div>
                <div class="drop-item-list-right">
                  <p class="mb-0"><small>{{notification.created_at | date:'short'}}</small></p>
                  <div ngbDropdown class="dropdown title-sub-right toggle-none">
                     <a ngbDropdownToggle id="navbarDropdown" role="button" class="data-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="fas fa-ellipsis-h"></i></a>
                     <div ngbDropdownMenu class="dropdown-menu dropdown-menu-regular dropdown-menu-end dropdown-menu-setting" aria-labelledby="navbarDropdown"> 
                         <ul>
                             <li><a href="javascript:void(0)" (click)="deleteNotification(notification)" class="dropdown-item">
                                 <div class="fig-cont">
                                     <figure><i class="far fa-trash-alt"></i></figure>
                                     <p><strong>Delete</strong>
                                         <small class="d-block">Delete this notification</small>
                                     </p>
                                  </div>
                             </a></li>
                         </ul>
                     </div>
                  </div>
                </div>
              </ng-container>


              <ng-container  *ngIf="notification.type == 'assessment'">
                <div class="drop-item-list-left">
                  <figure class="drop-item-list-figure">
                    <img *ngIf="notification.image !== ''" [src]="notification.image" alt="Avtar Image" />
                    <img *ngIf="notification.image == '' "[src]="defaultAvtar" alt="Avtar Image" />
                  </figure> 
                  <div class="drop-item-list-content">
                    <p>You received assessment notification for  <a href="javascript:void(0);" (click)="redirectTo(notification)"><strong>{{notification.content.title}}</strong></a>  from <a href="javascript:void(0);" (click)="redirectTo(notification)"><strong>{{notification.content.course_name}}</strong></a>  course.</p>
                    <!-- <p><small>Trends in Cloud Computing and DevOps for 2022 Powered by Restream</small></p> -->
                  </div>
                </div>
                <div class="drop-item-list-right">
                  <p class="mb-0"><small>{{notification.created_at | date:'short'}}</small></p>
                  <div ngbDropdown class="dropdown title-sub-right toggle-none">
                     <a ngbDropdownToggle id="navbarDropdown" role="button" class="data-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="fas fa-ellipsis-h"></i></a>
                     <div ngbDropdownMenu class="dropdown-menu dropdown-menu-regular dropdown-menu-end dropdown-menu-setting" aria-labelledby="navbarDropdown"> 
                         <ul>
                             <li><a href="javascript:void(0)" (click)="deleteNotification(notification)" class="dropdown-item">
                                 <div class="fig-cont">
                                     <figure><i class="far fa-trash-alt"></i></figure>
                                     <p><strong>Delete</strong>
                                         <small class="d-block">Delete this notification</small>
                                     </p>
                                  </div>
                             </a></li>
                         </ul>
                     </div>
                  </div>
                </div>
              </ng-container>



              <ng-container  *ngIf="notification.type == 'announcement'">
                <div class="drop-item-list-left">
                  <figure class="drop-item-list-figure">
                    <img *ngIf="notification.image !== ''" [src]="notification.image" alt="Avtar Image" />
                    <img *ngIf="notification.image == '' "[src]="defaultAvtar" alt="Avtar Image" />
                  </figure> 
                  <div class="drop-item-list-content">
                    <p><a href="javascript:void(0);" (click)="redirectTo(notification)"><strong>{{notification.content.announcement}}</strong></a> announcement has been published in <a href="javascript:void(0);" (click)="redirectTo(notification)"><strong>{{notification.content.course_name}}</strong></a>  course.</p>
                    <!-- <p><small>Trends in Cloud Computing and DevOps for 2022 Powered by Restream</small></p> -->
                  </div>
                </div>
                <div class="drop-item-list-right">
                  <p class="mb-0"><small>{{notification.created_at | date:'short'}}</small></p>
                  <div ngbDropdown class="dropdown title-sub-right toggle-none">
                     <a ngbDropdownToggle id="navbarDropdown" role="button" class="data-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="fas fa-ellipsis-h"></i></a>
                     <div ngbDropdownMenu class="dropdown-menu dropdown-menu-regular dropdown-menu-end dropdown-menu-setting" aria-labelledby="navbarDropdown"> 
                         <ul>
                             <li><a href="javascript:void(0)" (click)="deleteNotification(notification)" class="dropdown-item">
                                 <div class="fig-cont">
                                     <figure><i class="far fa-trash-alt"></i></figure>
                                     <p><strong>Delete</strong>
                                         <small class="d-block">Delete this notification</small>
                                     </p>
                                  </div>
                             </a></li>
                         </ul>
                     </div>
                  </div>
                </div>
              </ng-container>


              <ng-container  *ngIf="notification.type == 'mosaic_team'">
                <div class="drop-item-list-left">
                  <figure class="drop-item-list-figure">
                    <img *ngIf="notification.image !== ''" [src]="notification.image" alt="Avtar Image" />
                    <img *ngIf="notification.image == '' "[src]="defaultAvtar" alt="Avtar Image" />
                  </figure> 
                  <div class="drop-item-list-content">
                    <p> You received notification from mosaic team <a href="javascript:void(0);" (click)="redirectTo(notification)"><strong>{{notification.content.team_name}}</strong></a>.</p>
                    <!-- <p><small>Trends in Cloud Computing and DevOps for 2022 Powered by Restream</small></p> -->
                  </div>
                </div>
                <div class="drop-item-list-right">
                  <p class="mb-0"><small>{{notification.created_at | date:'short'}}</small></p>
                  <div ngbDropdown class="dropdown title-sub-right toggle-none">
                     <a ngbDropdownToggle id="navbarDropdown" role="button" class="data-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="fas fa-ellipsis-h"></i></a>
                     <div ngbDropdownMenu class="dropdown-menu dropdown-menu-regular dropdown-menu-end dropdown-menu-setting" aria-labelledby="navbarDropdown"> 
                         <ul>
                             <li><a href="javascript:void(0)" (click)="deleteNotification(notification)" class="dropdown-item">
                                 <div class="fig-cont">
                                     <figure><i class="far fa-trash-alt"></i></figure>
                                     <p><strong>Delete</strong>
                                         <small class="d-block">Delete this notification</small>
                                     </p>
                                  </div>
                             </a></li>
                         </ul>
                     </div>
                  </div>
                </div>
              </ng-container>


              <ng-container  *ngIf="notification.type == 'changes_by_admin'">
                <div class="drop-item-list-left">
                  <figure class="drop-item-list-figure">
                    <img *ngIf="notification.image !== ''" [src]="notification.image" alt="Avtar Image" />
                    <img *ngIf="notification.image == '' "[src]="defaultAvtar" alt="Avtar Image" />
                  </figure> 
                  <div class="drop-item-list-content">
                    <p>You received notification for some changes has be done by admin.</p>
                    <!-- <p><small>Trends in Cloud Computing and DevOps for 2022 Powered by Restream</small></p> -->
                  </div>
                </div>
                <div class="drop-item-list-right">
                  <p class="mb-0"><small>{{notification.created_at | date:'short'}}</small></p>
                  <div ngbDropdown class="dropdown title-sub-right toggle-none">
                     <a ngbDropdownToggle id="navbarDropdown" role="button" class="data-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="fas fa-ellipsis-h"></i></a>
                     <div ngbDropdownMenu class="dropdown-menu dropdown-menu-regular dropdown-menu-end dropdown-menu-setting" aria-labelledby="navbarDropdown"> 
                         <ul>
                             <li><a href="javascript:void(0)" (click)="deleteNotification(notification)" class="dropdown-item">
                                 <div class="fig-cont">
                                     <figure><i class="far fa-trash-alt"></i></figure>
                                     <p><strong>Delete</strong>
                                         <small class="d-block">Delete this notification</small>
                                     </p>
                                  </div>
                             </a></li>
                         </ul>
                     </div>
                  </div>
                </div>
              </ng-container>



              <ng-container  *ngIf="notification.type == 'calendar_reminders_or_tasks'">
                <div class="drop-item-list-left">
                  <figure class="drop-item-list-figure">
                    <img *ngIf="notification.image !== ''" [src]="notification.image" alt="Avtar Image" />
                    <img *ngIf="notification.image == '' "[src]="defaultAvtar" alt="Avtar Image" />
                  </figure> 
                  <div class="drop-item-list-content">
                    <p>You received notification from calendar.</p>
                    <!-- <p><small>Trends in Cloud Computing and DevOps for 2022 Powered by Restream</small></p> -->
                  </div>
                </div>
                <div class="drop-item-list-right">
                  <p class="mb-0"><small>{{notification.created_at | date:'short'}}</small></p>
                  <div ngbDropdown class="dropdown title-sub-right toggle-none">
                     <a ngbDropdownToggle id="navbarDropdown" role="button" class="data-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="fas fa-ellipsis-h"></i></a>
                     <div ngbDropdownMenu class="dropdown-menu dropdown-menu-regular dropdown-menu-end dropdown-menu-setting" aria-labelledby="navbarDropdown"> 
                         <ul>
                             <li><a href="javascript:void(0)" (click)="deleteNotification(notification)" class="dropdown-item">
                                 <div class="fig-cont">
                                     <figure><i class="far fa-trash-alt"></i></figure>
                                     <p><strong>Delete</strong>
                                         <small class="d-block">Delete this notification</small>
                                     </p>
                                  </div>
                             </a></li>
                         </ul>
                     </div>
                  </div>
                </div>
              </ng-container>

              </div>
            </div>

            <div class="alert alert-info text-center" *ngIf="notificationList.length === 0 && !notifyLoader">
              <p>No data found.</p>
            </div>
               <div class="mt-3 clearfix"></div>
              <pagination-controls  class="text-center my-pagination " *ngIf="notificationList?.length > 0"  previousLabel="<"
                nextLabel=">"  maxSize="5" (pageChange)="currentPageNo($event)"></pagination-controls>

           </div>
        </div>
      </div>
  </section>
</div> 