import { Component, OnInit, Input } from '@angular/core';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CourseService } from 'src/app/services/course.service';
import { ActivatedRoute } from '@angular/router';
import { EditCourseWork } from 'src/app/model/course.model';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-coursework-info',
  templateUrl: './coursework-info.component.html',
  styleUrls: ['./coursework-info.component.scss']
})
export class CourseworkInfoComponent implements OnInit {
  @Input() coursework;
  course_work_info: any;
  title = ''
  view_course = '';
  course_work_id = '';
  loader = false;
  constructor(config: NgbModalConfig,
    private modalService: NgbModal,
    private course_service: CourseService,
    private activate_route: ActivatedRoute) {
    config.backdrop = 'static';
  }

  ngOnInit() {
    this.view_course = this.activate_route.snapshot.queryParamMap.get('view_course');
    this.course_work_id = this.activate_route.snapshot.queryParamMap.get('course_work_id');
    this.course_work_info = this.coursework;
    if (this.view_course == 'view_course') {
      this.getCourse_work();
    }
    if (!this.course_work_id) {
      this.title = this.course_work_info.title
      this.getTime();
    }
  }

  openResVd(resourceVideo) {
    this.modalService.open(resourceVideo, { size: 'md' });
  }
  totalDays;
  getTime() {
    this.course_work_info.submission_requirement.forEach(element => {
      if (this.totalDays > element.days_of_completion) {
        return;
      } else {
        this.totalDays = element.days_of_completion
      }
    });

  }

  getExpireDate(dateValue, updateDays) {
    let expireDate;
    let updatedate;
    if (!this.view_course) {
      expireDate;
      updatedate;
      let day = dateValue.day;
      let month = dateValue.month;
      let year = dateValue.year;
      let date = year + '-' + month + '-' + day;
      updatedate = new Date(Date.parse(date));
      updatedate.setDate(updatedate.getDate() + Number(updateDays));
      expireDate = { day: updatedate.getDate(), month: updatedate.getMonth() + 1, year: updatedate.getFullYear() };

    } else if (this.view_course == 'view_course') {
      updatedate = new Date();
      updatedate.setDate(updatedate.getDate() + Number(updateDays));
      expireDate = { day: updatedate.getDate(), month: updatedate.getMonth() + 1, year: updatedate.getFullYear() };

    }

    return expireDate;
  }

  replaceHtml(value) {
    return value.replace(/(<([^>]+)>)/ig, '');
    // return value.replace(/<(?!img\s*\/?)[^↵>]+>/g, '');
  }

  openResource(url) {
    window.open(url, "blank")
  }

  getCourse_work() {
    let current_time = new Date();
    this.course_service.viewCourseWork(this.course_work_id).pipe(finalize(()=>{this.loader = false;})).subscribe(course_Work => {
      this.course_work_info = course_Work.course_work_info;
      this.title = this.course_work_info.course_work_title
      this.course_work_info['date'] = { day: current_time.getDate(), month: current_time.getMonth() + 1, year: current_time.getFullYear() };
      this.course_work_info['time'] = (current_time.getHours() + ':' + current_time.getMinutes())
      this.getTime();
    })
  }
}
