import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CourseService } from 'src/app/services/course.service';
import { finalize } from 'rxjs/operators';
import { NotificationService } from 'src/app/services/notification.service';
import { ShareService } from 'src/app/services/share.service';


@Component({
  selector: 'app-mark-attendance',
  templateUrl: './mark-attendance.component.html',
  styleUrls: ['./mark-attendance.component.scss']
})
export class MarkAttendanceComponent implements OnInit {
  presentText = 'Present';
  absentText = 'Absent';
  attendanceStatus;
  activeBtnGreen;
  activeBtnRed;
  loader: boolean;
  courseId: any;
  sessionId: any;
  topicId: any;
  email:any
  sessionTitle: any;
  topicTitle
  sessionParticipants: any = [];
  showParticipant: boolean = false;
  students:any = [];
  session_id:string;
  constructor(
    private activatedRoute: ActivatedRoute,
    private courseService: CourseService,
    private _notificationService: NotificationService,
    private sharedShervice: ShareService, 
    private route:Router) { }

  ngOnInit() {
    this.courseId = this.activatedRoute.snapshot.queryParamMap.get('courseId');
    this.sessionId = this.activatedRoute.snapshot.queryParamMap.get('liveSessionId');
    this.topicId = this.activatedRoute.snapshot.queryParamMap.get('topicId');
    this.session_id = this.activatedRoute.snapshot.queryParamMap.get('session_id');
    //this.sessionTitle = this.sharedShervice.sessionName;
    this.viewAttendance()
  }

  attendanceBtn(student,val, i){    
    this.loader = true;
   let body = {
      course_id: this.courseId,
      topic_id: this.topicId,
      live_session_id:this.sessionId,
      email:student.email,
      availability: val
    }

    this.courseService.updateLiveAttendence(body).subscribe((res) => {
      this._notificationService.showNotification('success', res.message);
      setTimeout(() => {
        this.viewAttendance();
      }, 500);
      this.loader = false;
      },
    (err) => {
      this.loader = false;
      this._notificationService.showNotification('error', err.message);
    })          
  }

   /**
   * @description: List of zoom participants 
   */
 
  viewAttendance() {
    this.loader = true;
    this.showParticipant = false;
    this.courseService.LiveSessionAttendance(this.courseId, this.sessionId,this.topicId).subscribe((res) => {
          // if (res.response.attendance.length == 0 && res.response.live_attendance) {
          //  // this.onFuzzyMatch('live_attendance');
          //   return;
          // }
          this.students = res.live_sessions.attendace_list;
          this.sessionTitle = res.live_sessions.liveSession_title;
          this.topicTitle = res.live_sessions.topic_title       
          this.showParticipant = true;
          this.loader = false;
        },
        (err) => {
          this.loader = false;
          this._notificationService.showNotification('error', err.message);
        })
  }

  backBtn(){
    this.route.navigate(['/courses/video-with-notes'], { queryParams: { course_id: this.courseId,topicId:this.topicId,session_id: this.session_id } });
  }

}
