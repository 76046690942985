import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PermissionService } from '../../services/permission.service';
import { CourseService } from '../../services/course.service';
import { NotificationService } from '../../services/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { ShowCourseView } from 'src/app/model/course.model';
import { CourseWorkUtilityService } from '../../services/course-work-utility.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-course-work-marking',
  templateUrl: './course-work-marking.component.html',
  styleUrls: ['./course-work-marking.component.scss']
})
export class CourseWorkMarkingComponent implements OnInit {

  //FOR SUBMISSION
  course_work_view: any;
  showCourseData: ShowCourseView;
  submission_button = 'Publish'
  courseworks_submission: any[] = [];
  isSubscribed = false;
  listdataarray = [];
  teacher_view_submission: any[] = [];
  getSubscribeStudentList = [];
  role: string = '';
  submission_publish = 'unpublished';

  //FOR STUDENT COURSE WORK
  loader: boolean = false;
  courseName: string = '';
  courseId: string = '';
  courseWorkId: string = '';
  topicId: string = '';
  courseworks: any = {};
  courseData: any = {};
  courseTitle: string = '';
  isTopicCoursework: boolean = false;
  teamName: string = '';
  userId: string = '';
  loggedInUserId: string = '';

  //CHILD COMPONENT DATA
  userDefaultPic: string = environment.imageBasePath + environment.userDefaultProfilePic;
  viewSubmissionData: any[] = [];
  reviewSavedStatusData: any = {};
  submissionsData: any[] = [];
  courseworkTeaminfo: any = [];
  markingTeamInfo: any[] = [];
  markingUserInfo: any[] = [];

  isTeachingAssistant: boolean = false;
  isPublishRight: boolean = false;
  isSuperAdmin: boolean = false;

  constructor(
    private permissionService: PermissionService,
    private courseService: CourseService,
    private notificationService: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
    private cwUtilityService: CourseWorkUtilityService) { }

  ngOnInit() {
    const role = localStorage.getItem('role');
    this.role = role;
    this.loggedInUserId = localStorage.getItem('user_id');

    this.route.queryParams.subscribe(params => {
      this.courseWorkId = params.courseWork;
      this.courseId = params.course_id;
      this.courseName = params.courseWork;
      this.topicId = params.topic_id;
      this.userId = params.userId;
      this.teamName = params.teamName;

      if (this.topicId) {
        this.isTopicCoursework = true;
      }
      this.getCourseWorkDetails();
      if (this.isTopicCoursework) {
        this.getTopicCourseWork();
      }
    });
  }

  getCourseWorkDetails() {
    this.loader = true;
    let current_time = new Date();

    this.courseService.getSingleCoursework(this.courseId, this.courseName).subscribe(res => {

      this.courseData = res.course_info;
      this.courseTitle = this.courseData.subject;

      //CHECK PERMISSION
      if (!this.permissionService.isAllowedSharedPageView(this.courseData.publish_right, this.role, this.courseData.teach_assis)) {
        this.router.navigate(['/']);
        return false;
      }

      //CHECK PUBLISH RIGHT
      if(this.courseData.publish_right == true) {
        this.isPublishRight = true;
      }
      else {
        this.isPublishRight = false;
      }

      //CHECK TEACHING ASSISTANT
      this.checkTeachingAssistant();

      //CHECK SUPER ADMIN
      if(this.role == 'super_admin') {
        this.isSuperAdmin = true;
      }
      else {
        this.isSuperAdmin = false;
      }

      //COURSE INFO DATA
      this.showCourseData = res;

      if (this.isTopicCoursework == false) {
        this.prepareCourseworkInfo(res.course_work_info, 'course');
      }
      this.loader = false;
    }, err => {
      this.loader = false;
      this.handleError(err);
    });
  }

  getTopicCourseWork() {
    this.loader = true;
    this.courseService.get_topicSession(this.courseId, this.topicId).subscribe(res => {
      //console.log(res.course_work);
      this.prepareCourseworkInfo(res.course_work, 'topic');
      this.loader = false;
    },
      (err) => {
        this.loader = false;
        this.handleError(err);
      });
  }

  prepareCourseworkInfo(course_works, ref = '') {
    let current_time = new Date();
    let list_course_work = course_works;
    let course_work_item: any = null;
    course_work_item = list_course_work.find(item => {
      return item._id == this.courseWorkId;
    });
    if (course_work_item) {
      if (!course_work_item.date) {
        course_work_item['date'] = { day: current_time.getDate(), month: current_time.getMonth() + 1, year: current_time.getFullYear() };
        course_work_item['startDate'] = current_time;
      }
      else {
        course_work_item['startDate'] = new Date(course_work_item.date.year, course_work_item.date.month - 1, course_work_item.date.day);
      }
      this.courseworks = course_work_item;
      this.getCourseWorkSubmissions();
    }
    // console.log(this.courseData);
    // console.log(this.courseworks);
    this.courseworks_submission = this.courseworks.submission_requirement;
    this.isSubscribed = this.courseData.subscription_status;
  }

  getCourseWorkSubmissions() {
    this.loader = true;
    this.courseService.viewSubmission('', this.courseworks._id, this.courseworks.schedule_id, this.courseId, this.courseworks.peer_review, this.courseworks.self_review, 'grade_view').subscribe(res => {
      this.loader = false;
      this.submissionsData = res.response;
      this.getMarkingUser();
    },
      (err) => {
        this.loader = false;
        console.log(err);
      });
  }

  getMarkingUser() {
    if(this.courseworks.is_group == true) {
      if(this.courseworks.team_info.length > 0) {
        let findedData: any = {};
        this.courseworkTeaminfo = this.courseworks.team_info;
        findedData = this.courseworkTeaminfo.find(item => {
          return item.name == this.teamName;
        });
        if(findedData) {
          this.markingTeamInfo[0] = findedData;
        }
      }
    }
    else {
      let findedData: any = {};
      findedData = this.submissionsData.find(item => {
        return item.user_id == this.userId;
      });
      if(findedData) {
        this.markingUserInfo[0] = findedData;
      }
    }
  }

  onGoBack() {
    this.router.navigate(['/courses/course-work-submissions'], { queryParams: { courseWork: this.courseWorkId, course_id: this.courseId, topic_id: this.topicId } });
  }

  //COURSE WORK USER SUBMISSION |START
  getExpireDate(dateValue, updateDays) {
    return this.cwUtilityService.getExpireDate(dateValue, updateDays);
  }

  getSubmissionData(data) {
    this.viewSubmissionData = data;
  }

  getReviewSavedStatus(data) {
    this.reviewSavedStatusData = data;
  }
  //COURSE WORK USER SUBMISSION |END

  checkTeachingAssistant() {
    if(this.permissionService.isAssistant(this.courseData.teach_assis)) {
      this.isTeachingAssistant = true;
    }
    else {
      this.isTeachingAssistant = false;
    }
  }

  openResource(url) {
    window.open(url, "blank")
  }

  handleError(error) {
    console.log(error);
  }
}
