import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { PassionProjectService } from '../../../services/passion-project.service';
import { NotificationService } from '../../../services/notification.service';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-discover-projects',
  templateUrl: './discover-projects.component.html',
  styleUrls: ['./discover-projects.component.scss']
})
export class DiscoverProjectsComponent implements OnInit {

  defaultThumbnailPic: string = environment.imageBasePath + environment.userDefaultProfilePic;

  discoverCourse: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    items: 3,
    navSpeed: 700,
    navText: ['<i class="fas fa-angle-left"></i>', '<i class="fas fa-angle-right"></i>'],
    nav: true,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      }
    },
  }

  loader: boolean = false;
  discoverProjectList: any[] = [];
  
  constructor(private passionProjectService: PassionProjectService, private router: Router, private ns: NotificationService) { }

  ngOnInit() {
    this.getDiscoverProjects();
  }

  getDiscoverProjects() {
    this.loader = true;
    this.passionProjectService
      .discoverProjectsList()
      .subscribe(
        res => {
          this.loader = false
          this.discoverProjectList = res.data
          //console.log(this.discoverProjectList);
        },
        err => {
          this.loader = false
          this.ns.showNotification('error', err.message);
        }
      );
  }

  changeSharedProject(id: string) {
    this.redirect('/passion-projects/view', id);
  }

  redirect(path: string, id: string) {
    if (id) this.router.navigate([path], { queryParams: { id } });
    else this.router.navigate([path]);
  }

}
