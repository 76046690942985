import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Router } from '@angular/router';
import { NotificationService } from './notification.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(
        private router: Router,
        private ns: NotificationService,
    ) { }


    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(
                catchError((error: HttpErrorResponse) => {

                    if (error.status === 401) {
                        localStorage.clear();
                        this.router.navigate(['login']);
                    }

                    let errorMessage;
                    if (error.error instanceof ErrorEvent)
                        errorMessage = { message: error.error.message };
                    else errorMessage = { status: error.status, message: error.error.message };

                    if (error.error.message === 'session expired') {
                        localStorage.clear();
                        this.ns.showNotification('info', 'Please login Again your session is exprired');
                        this.router.navigate(['login']);
                    }

                    if (error.status === 500) {
                        return throwError(error);
                    }

                    return throwError(errorMessage);
                }));
    }
}
