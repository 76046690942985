import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { CourseService } from '../../services/course.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ShowCourseView } from 'src/app/model/course.model';

@Component({
  selector: 'app-coursework-teacherlogin',
  templateUrl: './coursework-teacherlogin.component.html',
  styleUrls: ['./coursework-teacherlogin.component.scss']
})
export class CourseWorkTeacherloginComponent implements OnInit {

  courseId = '';
  course_work_id = '';
  view_course = '';
  course_work_info: any = [];
  showCourseData: ShowCourseView;
  topi: any;
  courseworks: any = [];
  courseworks_submission: any;
  courseworks_workresources: any = [];
  courseworks_teaminfo: any = [];
  courseworks_teaminfo_member: any = [];
  courseName = '';
  isSubscribed = false;
  course_category_id: string;
  course_category: string;
  rating: number;
  individual_rating: number;
  role;
  loader = false;

  constructor(
    private courseService: CourseService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    ) { }

  ngOnInit() {

    const role = localStorage.getItem('role');
    const allowedRoles = ['teacher', 'super_admin'];
    if(allowedRoles.indexOf(role) == -1) {
      this.router.navigate(['/']);
    }

    this.view_course = this.activatedRoute.snapshot.queryParamMap.get('view_course');
    this.course_work_id = this.activatedRoute.snapshot.queryParamMap.get('courseWork');
    this.courseId = this.activatedRoute.snapshot.queryParamMap.get('course_id');
    this.courseName = this.activatedRoute.snapshot.queryParamMap.get('course');
    this.role = localStorage.getItem('role');
   
    if(this.courseId) {
      this.getEditDetails();
    }
    else {
      this.getCourse_work(this.course_work_id);
    }
  }

  onGoBack(courseId) {
    this.router.navigate(['/courses/search-coursework']/*, { queryParams: { courseWork: this.course_work_id, course_id: courseId} }*/);
  }

  editSelectedCourseWork(courseId) {
    this.router.navigate(['/courses/add-coursework'], { queryParams: { courseWork: this.course_work_id, course_id: courseId } });
  }

  courseList:any = [];
  getCourse_work(course_work_id) {
    //console.log("course work id:", course_work_id)
    let current_time = new Date();
    this.loader = true;
    this.courseService.viewCourseWork(course_work_id).pipe(finalize(() => { this.loader = false; })).subscribe(course_Work => {
      this.course_work_info = course_Work.course_work_info;  
      this.courseList = this.course_work_info.courses;
      this.course_work_info.title = this.course_work_info.course_work_title;
      if(!this.course_work_info.date) {
        this.course_work_info['date'] = { day: current_time.getDate(), month: current_time.getMonth() + 1, year: current_time.getFullYear() };
        this.course_work_info['startDate'] = current_time;
      }
      else {
        this.course_work_info['startDate'] = new Date(this.course_work_info.date.year, this.course_work_info.date.month - 1, this.course_work_info.date.day);
        this.course_work_info['time'] = (current_time.getHours() + ':' + current_time.getMinutes());
        // this.getTime();
      }
      this.courseworks.push(this.course_work_info);
      this.setCourseWorkData();
    });
  }

  getEditDetails() {
    this.loader = true;
    let current_time = new Date();

    this.courseService.editCourse(this.courseId, this.courseName)
      .pipe(finalize(() => this.loader = false)).subscribe(res => {
        this.showCourseData = res;

        this.course_work_info = this.showCourseData;        
        this.course_work_info['date'] = { day: current_time.getDate(), month: current_time.getMonth() + 1, year: current_time.getFullYear() };        

        let list_course_work = res.course_info.course_work;
        let course_work_item:any = null;
        course_work_item = list_course_work.find(item => {
          return item._id == this.course_work_id;
        });
        if(course_work_item) {
          if(!course_work_item.date) {
            course_work_item['date'] = { day: current_time.getDate(), month: current_time.getMonth() + 1, year: current_time.getFullYear() };
            course_work_item['startDate'] = current_time;
          }
          else {
            course_work_item['startDate'] = new Date(course_work_item.date.year, course_work_item.date.month - 1, course_work_item.date.day);
          }
          course_work_item.publish = true;
          this.courseworks.push(course_work_item);
          this.setCourseWorkData();
        }
        else {
          this.getCourse_work(this.course_work_id);
        }

        this.isSubscribed = res.course_info.subscription_status;
        this.course_category = res.course_info.course_category_name;
        this.course_category_id = res.course_info.course_category_id;
        this.rating = res.course_info.rating;
        this.individual_rating = res.course_info.individual_rating
        this.topi = res.course_info.topics
      });
  }

  setCourseWorkData() {
    this.courseworks_submission = this.courseworks[0].submission_requirement;
    this.courseworks_workresources = this.courseworks[0].course_work_resources;

    if (this.courseworks[0].group_type == 'Student-led' || this.courseworks[0].group_type == 'manual') {
      this.courseworks_teaminfo = this.courseworks[0].team_info[0].name;
      this.courseworks_teaminfo_member = this.courseworks[0].team_info[0].members;
    }
    //TAGS COLORS
    if(this.courseworks[0].tag) { 
      for(let i = 0; i < this.courseworks[0].tag.length; i++) {
        this.courseworks[0].tag[i].bgColor = this.randomBgColor();
      }
    }
  }

  randomBgColor() {
    var x = Math.floor(Math.random() * 256);
    var y = Math.floor(Math.random() * 256);
    var z = Math.floor(Math.random() * 256);
    var bgColor = "rgb(" + x + "," + y + "," + z + ")";
   
    return bgColor;
  }

  openResource(url) {
    window.open(url, "blank")
  }

  getExpireDate(dateValue, updateDays) {
    let expireDate;
    let updatedate;

    if (!this.view_course) {
      expireDate;
      updatedate;
      let day = dateValue.day;
      let month = dateValue.month;
      let year = dateValue.year;
      let date = year + '-' + month + '-' + day;
      updatedate = new Date(Date.parse(date));
      updatedate.setDate(updatedate.getDate() + Number(updateDays));
      expireDate = { day: updatedate.getDate(), month: updatedate.getMonth() + 1, year: updatedate.getFullYear() };

    } else if (this.view_course == 'view_course') {
      updatedate = new Date();
      updatedate.setDate(updatedate.getDate() + Number(updateDays));
      expireDate = { day: updatedate.getDate(), month: updatedate.getMonth() + 1, year: updatedate.getFullYear() };

    }

    return expireDate;
  }

}