<div class="new_UIstyle">
    <div class="container">
        <div class="mt-3" [ngClass]="edit_note == true?'d-block':'d-none'">

            <form [formGroup]="NotesAndSummry" (ngSubmit)="saveNotesAndSummry(type)">
                <div class="editPanel" [ngClass]="notes_is?'d-block':'d-none'">
                    <div class="row mb-3" *ngIf="type=='note'">
                        <div class="col-12">
                            <input type="text" formControlName="title" placeholder="Add a title"
                                [value]="get_value() | capitalization" class="form-control form-control-lg" id="title">
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12">
                            <!-- <label for="addNoteandSummary">Description</label> -->
                            <textarea rows="6" cols="8" formControlName="content" class="form-control form-control-lg"
                                id="addNoteandSummary" placeholder="Write a note"></textarea>
                        </div>
                    </div>

                    <!---new add -->


                    <div class="row">
                        <div class="col-md-12 mt-4">
                            <div class="dragDropWrap" [ngClass]="{submitting : !(uploadArray.length == browseFileLen)}">
                                <div class="spinner-border text-warning outer_loader_over" role="status"
                                    *ngIf="!(uploadArray.length == browseFileLen)">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div>
                                    <div class="dragDrop dragDropOr  d-none d-md-flex">
                                        <figure class="dragDropIcon"><i class="fas fa-upload"></i></figure>
                                        <div class="dragDropContent">
                                            <input type="file" title=" " class="file-upload-input " id="fileU1"
                                                accept="image/*,video/*,audio/*,doc/*,application/*,text/*"
                                                (change)="onFileBrowse($event)" multiple #fileU1 />
                                            <p><strong class="blue-text d-block">Drag & Drop your file here
                                                </strong>Supports image, video, audio, pdf & text files
                                            </p>

                                            <div class="uploadedFiles">
                                                <p *ngIf="uploadedMedia.length > 0">
                                                    <small *ngIf="uploadedMedia.length == 1"><b>{{selectFilelenghth}}
                                                            file selected</b></small>
                                                    <small *ngIf="uploadedMedia.length > 1"><b>{{selectFilelenghth}}
                                                            files selected</b></small>
                                                </p>
                                                <p *ngIf="uploadedMedia.length == 0"><small><b>No File
                                                            Selected</b></small></p>
                                            </div>
                                        </div>
                                        <span class="divideOr"></span>
                                    </div>

                                    <div class="dragDropUpload btn-groups">
                                        <button type="button" (click)="fileU1.click()"
                                            class="custom-upload-btn btn btn-blue btn-shadow btn-icon-text">
                                            <span class=""><i class="fas fa-download"></i> Browse
                                                Files</span>
                                        </button>
                                    </div>

                                </div>

                            </div>
                            <div class="col-md-12 mt-4 uploadedItemWrap">
                                <div class="uploadedItem">
                                    <div class=" mb-3" *ngFor="let media of uploadedMedia; let i = index">

                                        <div class="uploadedItemTop">
                                            <div class="uploadedItemDetail">
                                                <p class="mb-1"><strong class="blue-text"> {{ media.FileName
                                                        }}</strong></p>
                                                <p class="uploadedItemStatus">
                                                    <small>{{media.FileProgress}}%
                                                        Complete</small>
                                                    <small class="dark-green-text"
                                                        *ngIf="media.FileProgress === 100">Uploaded
                                                        Successful</small>
                                                </p>
                                            </div>
                                            <div class="uploadedItemAction">
                                                <a href="javascript:void(0);"
                                                    *ngIf="media.FileProgress === 100 && (uploadArray.length == browseFileLen)"
                                                    (click)="openModelDeleteRes(delRes)" class="uploadedActionLink"><i
                                                        class="far fa-times-circle"></i></a>
                                                        <ng-template #delRes let-c="close" let-d="dismiss">
                                                            <div class="new_UIstyle">
                                                                <div class="modal-header justify-content-between align-items-center">
                                                                    <h5 class="avenirBook modal-title text-danger mb-0">Delete Resourse</h5>
                                                                    <button type="button" class="close ml-0" aria-label="Close"
                                                                        (click)="closeResPopup()">
                                                                        <span aria-hidden="true">&times;</span>
                                                                    </button>
                                                                </div>
                                                                <div class="modal-body">
                                                                    <p>Are you sure you want to delete Resource <strong class="text-danger">?</strong></p>
                                                                </div>
                                                    
                                                                <div class="modal-footer">
                                                                    <div class="text-left">
                                                                        <button type="submit" class="btn btn-primary btn-sm"
                                                                            (click)="closeResPopup()">NO</button>
                                                                    </div>
                                                                    <div class="text-right">
                                                                        <button type="submit" class="btn btn-primary btn-sm"
                                                                        (click)="deleteResouce(media,i)">YES</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ng-template>
                                            </div>
                                        </div>
                                        <div class="progress uploadedProgress">
                                            <div class="progress-bar uploadedProgressBlue" role="progressbar"
                                                [ngStyle]="{ 'width.%': media.FileProgress }"></div>
                                        </div>
                                        <!-- <p>
                                            <small>{{ media.FileProgessSize }} of {{ media.FileSize
                                                }}</small>
                                        </p> -->
                                    </div>
                                </div>
                            </div>


                            <div class="btn-groups mt-4 justify-content-end">
                                <button type="button" class="btn btn-grey btn-sm" (click)="cancel()"
                                    [disabled]="!(uploadArray.length == browseFileLen)">Cancel</button>
                                <button class="btn btn-primary btn-sm"
                                    [disabled]="!(uploadArray.length == browseFileLen)">Save Note</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>


        <div class="mt-2" [ngClass]="edit_note == false?'d-block':'d-none'">
            <form name="noteTaking">
                <div class="input-with-icon input-with-icon-right mb-2">
                    <input type="text" placeholder="Create a note" class="form-control form-control-lg" />
                    <button type="button" class="input-with-icon-btn search-btn"
                        (click)="addNotes(NotEditing, 'note', '')"><i class="fas fa-plus"></i></button>
                    <!-- <button type="button" class="input-with-icon-btn search-btn"
                        (click)="addNotes123()"><i class="fas fa-plus"></i></button> -->
                </div>
            </form>
            <div class="nWV-accordionCustom mt-5">
                <ngb-accordion #topics="ngbAccordion" [closeOthers]="true" activeIds="active" class="accordionCustom">
                    <ngb-panel id="active-{{notes_index}}"
                        *ngFor="let sessionNotes of session_detail_data.notes; let notes_index = index">


                        <ng-template ngbPanelHeader>
                            <div class="d-flex panelHeaderButton panelHeaderActionBtn">
                                <div class="panelHeaderElem panelHeaderLeft">
                                    <button ngbPanelToggle class="ngbPanelToggleBtn" id="ngbPanelToggle1">

                                        <span
                                            class="acc-headerTitle h6 avenirBlack d-block">{{sessionNotes.title}}</span>

                                        <span class="acc-headerAngel"><i class="fas fa-angle-down"></i></span>
                                    </button>
                                </div>
                                <div class="panelHeaderElem panelHeaderRight">
                                    <div class="panelHeaderActionBlock">
                                        <ul>

                                            <li (click)="addNotes(NotEditing, 'note',sessionNotes)">
                                                <a><i class="fas fa-pencil-alt"></i></a>
                                            </li>

                                            <li (click)="deleteNotesModel(deleteNotes)">
                                                <a><i class="fas fa-trash-alt"></i></a>
                                            </li>


                                            <li><span class="badgeNotesTime">{{sessionNotes.timestamp}}</span>
                                            </li>

                                          
                                        </ul>

                                                                <!-- new ui deative course -->
                                                                <ng-template #deleteNotes let-c="close" let-d="dismiss">
                                                                    <div class="new_UIstyle">
                                                                        <div class="modal-header justify-content-between align-items-center">
                                                                            <h5 class="avenirBook modal-title text-danger mb-0">Delete Notes
                                                                            </h5>
                                                                            <button type="button" class="close ml-0" aria-label="Close"
                                                                                (click)="closeNotesPopup()">
                                                                                <span aria-hidden="true">&times;</span>
                                                                            </button>
                                                                        </div>
                                                                        <div class="modal-body">
                                                                            <p>Are you sure you want to delete Notes <strong
                                                                                    class="text-danger">?</strong></p>
                                                                                    
                                                                        </div>
                        
                                                                        <div class="modal-footer">
                                                                            <div class="text-left">
                                                                                <button type="submit" class="btn btn-primary btn-sm"
                                                                                    (click)="closeNotesPopup()">NO</button>
                                                                            </div>
                                                                            <div class="text-right">
                                                                                <button type="submit" class="btn btn-primary btn-sm"
                                                                                    (click)="deleteNo(sessionNotes._id)">YES</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </ng-template>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="panelContentWrap">
                                <p [innerHTML]="sessionNotes.content"></p>
                                <!-- <div class="notesImg" *ngIf="sessionNotes.image_path"> -->
                                <!-- <span class=""><img [src]="sessionNotes.image_path" /></span> -->
                                <div class="answerVideoListItem" *ngIf="sessionNotes.image_path?.length > 0">
                                    <hr />
                                    <h6 class="primary-text avenirBlack">Images</h6>
                                    <div class="col-12">
                                        <div class="row ">
                                            <div class="col-lg-4 col-md-6  mb-3"
                                                *ngFor="let ansResourceImg of sessionNotes.image_path; let i = index;">
                                                <div class="answerVideoBox">
                                                    <div class="ratio ratio-16x9">
                                                        <img [src]="ansResourceImg" [alt]="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- </div> -->
                                <div class="notesVideo" *ngIf="sessionNotes.video_path?.length > 0">
                                    <hr />
                                    <h6 class="primary-text avenirBlack">Videos</h6>
                                    <div class="col-12">
                                        <div class="row">
                                            <div class="col-lg-4 col-md-6 mb-3"
                                                *ngFor="let ansResourceVideo of sessionNotes.video_path; let i = index;">
                                                <div class="answerVideoBox">
                                                    <div class="ratio ratio-16x9">
                                                        <video width="540" height="310" controls>
                                                            <source [src]="ansResourceVideo" type="video/mp4">
                                                        </video>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="learning-course-items-wrap learning-course-resources-wrap"
                                    *ngIf="sessionNotes.resources?.length > 0">
                                    <hr />
                                    <h6 class="primary-text avenirBlack">Files</h6>
                                    <div class="learning-course-item"
                                        *ngFor="let ansDocRes of sessionNotes.resources; let i = index;">
                                        <div class="learning-course-link">
                                            <div class="learning-course-elem-left">
                                                <p class="learning-course-link-title">
                                                    <span class="title-icon"><i
                                                            class="fas fa-folder"></i></span><strong>{{sessionNotes.resources_key[i] |
                                                                splitTitle}}</strong>
                                                </p>
                                            </div>
                                            <div class="learning-course-elem-right">
                                                <ul class="list-icon-wrap">
                                                    <li>
                                                        <a class="learning-course-download" [href]="ansDocRes"
                                                            target="_blank" [download]="ansDocRes | splitTitle">
                                                            <i class="fas fa-download"></i>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                </div>

                            </div>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
            </div>
        </div>
    </div>
</div>

<!-- [disabled]="saving_notes -->