<div class="new_UIstyle">
    <div class="pageBodyWrap questionBankMain">
        <!-- Banner Section Start -->
        <section class="banner banner-inner text-center"
            style="background-image: url('assets/images/banner/question-bank.jpg');">
            <div class="container">
                <div class="banner-content">
                    <h2 class="white-text amitaBold mb-2">{{titleOfPage}}</h2>
                    <p class="avenirRoman white-text lead" *ngIf="titleOfPage == 'My Questions'">List of questions asked by me</p>
                    <p class="avenirRoman white-text lead" *ngIf="titleOfPage != 'My Questions'">List of questions asked by students</p>
                    <div class="btn-groups justify-content-center" >
                        <button class="btn btn-primary" *ngIf="titleOfPage == 'My Questions'" routerLink="/ask-question">Ask a Question ?</button>
                        <!-- (click)="openQuestion(question)" -->
    
    
                        <!-- this is not working for now giving error in console while delete  quesio -->
                        <div class="row justify-content-center"  *ngIf="titleOfPage != 'My Questions'">
                            <div class="col-md-6">
                                <select class="form-control custom-select " [(ngModel)]="selectedValue"
                                    (change)="onChangeSubject($event.target.value)">
                                    <option value="select">Select Category</option>
                                    <option *ngFor="let sub of subjectOfQuestion" [value]="sub._id">
                                        {{sub.Course_Category}}
                                    </option>
                                </select>
                            </div>
                        </div>
                          <!-- ================================= -->
                    </div>
                </div>
            </div>
        </section>
    
        <section class="section questionBankContWrap">
            <div class="container">
                <!-- Filter Block Start -->
                <div class="filter-elem-block mb-4 d-none"  *ngIf="myquestions?.length > 0">
                    <div class="filter-elem-item filter-elem-left">
                        <a id="fliter-btn" class="filter-elem-item-link" (click)="filterClickEvent()">
                            <i class="fas fa-filter mr-2"></i>
                            <h6 class="avenirBook">Filters</h6>
                        </a>
                    </div>
                    <div class="filter-elem-item filter-elem-right">
                        <form>
                            <div class="sort-elenm">
                                <label><small>Sort by:</small></label>
                                <select class="form-control form-select form-control-caret">
                                    <option>Select</option>
                                    <option>Recent Questions</option>
                                    <option>Most Visited</option>
                                </select>
                            </div>
                        </form>
                    </div>
                </div>
                
                <div class="questionBankLists mb-3">
                    <div class="text-center" [ngClass]="{submitting : loader}">
                        <div class="spinner-border text-warning formLoader" role="status" *ngIf="loader">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                    <div class="questionBankItem bg-grey" *ngFor="let myquestion of myquestions | paginate: { itemsPerPage: 10, currentPage: pageP, totalItems:totalRecordeP }; let i = index;">
                        <div class="questionItemLeft">
                            <h6 class="avenirBook mb-2"><a
                                    (click)="getQuestionsAnswer(myquestion.id)">{{myquestion.title}}</a></h6>
                            <div class="tags-badges">
                                <span class="badge badge-green-lt" *ngFor="let tagss of myquestion.category; ">{{tagss}}</span>
                            </div>
    
                        </div>
                        <div class="questionItemRight icons-group">
                            <a class="questionItemLink ItemEditLink"
                            (click)="EditQuestion(myquestion.id)"
                            *ngIf="myquestion.count == 0"
                             ngbTooltip="Edit">
                                <i class="fas fa-pencil-alt"></i></a>
    
                            <a class="questionItemLink ItemDeleteLink" 
                            ngbTooltip="Delete"
                            *ngIf="myquestion.approved_answer == 0"
                            (click)="openModelDeleteQuestion(deleteQues,myquestion)">
                            <i class="fas fa-trash-alt"></i></a>
                        </div>
                        <div class="questionItemFooter">
                            <div class="questionItemFooterLeft">
                                <!-- <p>{{myquestion.count}} Answers Given</p> -->
                                <p>
                                    <span class="mr-3">
                                        {{myquestion.count}} Answers Given
                                    </span>
                                    <span class="avenirBlack color-grey">
                                        {{myquestion.Timestamp | date:'short'}}
                                    </span>
                                </p>
                            </div>
                            <div class="questionItemFooterRight">
                                <p class="primary-text"><strong>{{myquestion.approved_answer}} Answers Approved</strong></p>
                            </div>
                        </div>
                        <a  (click)="getQuestionsAnswer(myquestion.id)" class="questionItemLink ItemAnchorLink"><i class="fa fa-angle-right"></i></a>
                    </div>

                    <div class="alert alert-info text-center" *ngIf="myquestions?.length == 0"> 
                         <p>No Result Found</p>
                    </div>
                </div>
    
                <pagination-controls  class="text-center my-pagination" *ngIf="myquestions?.length > 0"  previousLabel="<"
                nextLabel=">" maxSize="7" (pageChange)="currentPageNo($event)"></pagination-controls>
                 
            </div>
        </section>
    </div>
    
    <!-- Filter Sidebar -->
    <div class="filter-sidebar" id="filterSidebar" [ngClass]="sidebarStatus ? 'open-sidebar': ''">
        <button class="sidebar-close-btn" (click)="filterClickEvent()"><i class="fas fa-times"></i></button>
        <div class="title filter-sidebar-title">
            <h2>Filter</h2>
        </div>
        <div class="filter-sidebar-items-wrap">
            <!-- Filter Sidebar Item Start -->
            <div class="filter-sidebar-items">
                <form>
                    <h6 class="avenirBlack">Categories</h6>
                    <div class="filter-sidebar-items-check">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" name="flexRadioDefault"
                                id="filter-QB-items-check01">
                            <label class="form-check-label" for="filter-QB-items-check01">
                                Design
                            </label>
                        </div>
    
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" name="flexRadioDefault"
                                id="filter-QB-items-check02">
                            <label class="form-check-label" for="filter-QB-items-check02">
                                Development
                            </label>
                        </div>
    
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" name="flexRadioDefault"
                                id="filter-QB-items-check03">
                            <label class="form-check-label" for="filter-QB-items-check03">
                                Marketing
                            </label>
                        </div>
    
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" name="flexRadioDefault"
                                id="filter-QB-items-check04">
                            <label class="form-check-label" for="filter-QB-items-check04">
                                IT & Software
                            </label>
                        </div>
    
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" name="flexRadioDefault"
                                id="filter-QB-items-check05">
                            <label class="form-check-label" for="filter-QB-items-check05">
                                Business
                            </label>
                        </div>
    
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" name="flexRadioDefault"
                                id="filter-QB-items-check06">
                            <label class="form-check-label" for="filter-QB-items-check06">
                                Music
                            </label>
                        </div>
    
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" name="flexRadioDefault"
                                id="filter-QB-items-check07">
                            <label class="form-check-label" for="filter-QB-items-check07">
                                Photography
                            </label>
                        </div>
    
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" name="flexRadioDefault"
                                id="filter-QB-items-check08">
                            <label class="form-check-label" for="filter-QB-items-check08">
                                Language
                            </label>
                        </div>
    
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" name="flexRadioDefault"
                                id="filter-QB-items-check09">
                            <label class="form-check-label" for="filter-QB-items-check09">
                                Health & Fitness
                            </label>
                        </div>
                    </div>
                </form>
            </div>
            <hr />
            <!-- Filter Sidebar Item Start -->
            <div class="filter-sidebar-items">
                <form>
                    <h6 class="avenirBlack">Question Type</h6>
                    <div class="filter-sidebar-items-check">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" name="flexCheckboxDefault"
                                id="filter-QT01">
                            <label class="form-check-label" for="filter-QT01">
                                Extracted Questions
                            </label>
                        </div>
                    </div>
                </form>
            </div>
            <hr />
                  <!-- Filter Sidebar Item Start -->
                  <div class="filter-sidebar-items">
                    <form>
                        <h6 class="avenirBlack">Answer Type</h6>
                        <div class="filter-sidebar-items-check">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" name="flexCheckboxDefault"
                                    id="filter-AT01">
                                <label class="form-check-label" for="filter-AT01">
                                    Text Answer
                                </label>
                            </div>
    
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" name="flexCheckboxDefault"
                                    id="filter-AT02">
                                <label class="form-check-label" for="filter-AT02">
                                    Recorded Video Answer
                                </label>
                            </div>
                        </div>
                    </form>
                </div>
    
        </div>
    </div>
    <div class="backlog-sidebar" [ngClass]="sidebarStatus ? 'backlog-active' : ''"></div>
    

    <ng-template #question let-c="close" let-d="dismiss">
        <div class="modal-header justify-content-between align-items-center">
            <h4 class="modal-title">{{question_type}}</h4>
            <button type="button" class="close ml-0" aria-label="Close" (click)="closeQuestionPopup()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="rounded embed-responsive-16by9">
                <form class="mw620 floatedInputForm" [ngClass]="{submitting : loader}" [formGroup]="askQuestionForm"
                    (ngSubmit)="askQuestion()">
                    <div *ngIf="role== 'student'">
                        <dl>
                            <dd>
                                <select class="form-control custom-select" placeholder=" " formControlName="subject">
                                    <option *ngFor="let sub of subjectOfQuestion" [value]="sub._id">{{sub.Course_Category}}
                                    </option>
                                </select>
                                <label class="flLabel">Category</label>
                            </dd>
                        </dl>
                        <dl>
                            <dd [ngClass]="onEdit ? 'disableProfileFIeld' : null">
                                <input type="text" class="form-control" placeholder=" " formControlName="coins">
                                <label class="flLabel">Coins</label>
    
                            </dd>
                        </dl>
                    </div>
                    <dl>
                        <dd>
                            <input type="text" [value]="askQuestionForm.get('yourQuestion').value | capitalization"
                                class="form-control" placeholder=" " formControlName="yourQuestion">
                            <label class="flLabel">Your Question</label>
    
                        </dd>
                    </dl>
                    <div *ngIf="role== 'student'">
                        <dl>
                            <dd>
                                <div class="custom-file">
                                    <input type="file" class="custom-file-input" id="choosePic" accept="image/*"
                                        (change)="onPicSelected($event)" formControlName="picture">
                                    <label class="custom-file-label" for="choosePic">{{picName}}</label>
                                </div>
                                <label class="flLabel">Choose Picture</label>
                            </dd>
                        </dl>
    
                        <dl>
                            <dd>
                                <div class="custom-file">
                                    <input type="file" class="custom-file-input" id="chooseVideo" accept="video/*"
                                        (change)="onVideoSelected($event)" formControlName="video">
                                    <label class="custom-file-label" for="chooseVideo">{{videoName}}</label>
                                </div>
                                <label class="flLabel">Choose Video</label>
    
                            </dd>
                        </dl>
                    </div>
    
                    <div *ngIf="questionId" class="form-inline">
                        <div class="custom-control custom-checkbox my-1 mr-sm-3" *ngIf="picName == 'Picture is selected'">
                            <input type="checkbox" class="custom-control-input" id="deletePic" name="deletePic"
                                (change)="delete_imageChange($event)">
                            <label class="custom-control-label" for="deletePic"> Delete Picture</label>
                        </div>
                        <div class="custom-control custom-checkbox my-1 mr-sm-3" *ngIf="videoName == 'Video is selected'">
                            <input type="checkbox" class="custom-control-input" id="deletevideo" name="deletevideo"
                                (change)="delete_videoChange($event)">
                            <label class="custom-control-label" for="deletevideo"> Delete Video</label>
                        </div>
                    </div>
    
                    <div class="text-right">
                        <button type="submit" class="btn btn-primary">Submit</button>
                    </div>
    
                </form>
            </div>
        </div>
    </ng-template>
    
    <ng-template #deleteQues let-c="close" let-d="dismiss">
        <div class="new_UIstyle">
        <div class="modal-header justify-content-between align-items-center">
            <h5 class="avenirBook modal-title text-danger mb-0">Delete Question</h5>
            <button type="button" class="close ml-0" aria-label="Close" (click)="closeQuestionPopup()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <p>Are you sure you want to delete <strong class="text-danger">?</strong></p>
        </div>
    
        <div class="modal-footer">
            <div class="text-left">
                <button type="submit" class="btn btn-primary btn-sm" (click)="closeQuestionPopup()">NO</button>
            </div>
            <div class="text-right">
                <button type="submit" class="btn btn-primary btn-sm" (click)="delQuestionYes(questionData.id)">YES</button>
            </div>
        </div>
    </div>
    </ng-template>
</div>

<!-- <div class="banner">
    <div class="container text-center">
        <h1>{{titleOfPage}}</h1>
    </div>
</div> -->

<!-- <div class="pageBody">

    <div class="container">

        <div class="clearfix filter mb-4">
            <div class="float-left" *ngIf="titleOfPage != 'My Questions'">
                <select class="custom-select" [(ngModel)]="selectedValue"
                    (change)="onChangeSubject($event.target.value)">
                    <option value="select">Select Category</option>
                    <option *ngFor="let sub of subjectOfQuestion" [value]="sub._id">
                        {{sub.Course_Category}}
                    </option>
                </select>
            </div>
            <div class="mb-3 text-right" *ngIf="titleOfPage == 'My Questions'">
                <button class="btn btn-danger" type="button" (click)="openQuestion(question)">
                    Ask a Question<i class="fas fa-question ml-2"></i>
                </button>
            </div>
        </div>

        <ul class="list-group questList mb-0" *ngIf="myquestions?.length > 0">
            <li class="list-group-item" *ngFor="let myquestion of myquestions; let i = index;">
                <a (click)="getQuestionsAnswer(myquestion._id)">{{myquestion.qus_title}}</a>
                <button class="editQuestLink" *ngIf="get_edit_question(myquestion)" ngbTooltip="Edit" type="button"
                    (click)="openEditQuestion(question,myquestion)">
                    <i class="fas fa-pencil-alt"></i>
                </button>
                <button class="deleteQuestLink" *ngIf="role != 'student'"   (click)="openModelDeleteQuestion(deleteQuestion,myquestion)" type="button"><i
                        class="fas fa-trash-alt"></i></button>

            </li>
        </ul>


        <div *ngIf="myquestions?.length === 0">
            No Question added yet for the this category
        </div>
        <div *ngIf="selectedValue == 'select'">
            Select the category and if Question will be preset then it will show here.
        </div>

    </div>
</div> -->

