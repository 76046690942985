<div class="banner">
    <div class="container text-center">
        <h1>My Profile</h1>
    </div>
</div>

  
<div class="pageBody">
    <div class="container">
        
        <div class="d-flex align-items-start profile">
            <ul ngbNav #profNav="ngbNav" [(activeId)]="active" class="nav-pills" orientation="vertical">
              <li ngbNavItem="pinfo" (click)="my_page('Pinfo')">
                <a ngbNavLink>Personal Information</a>
                <ng-template ngbNavContent>
                  <app-personal-info></app-personal-info>                  
                </ng-template>
              </li>
              <!-- <li ngbNavItem="ach">
                <a ngbNavLink>Achievement(s)</a>
                <ng-template ngbNavContent>
                  <app-achievement></app-achievement>
                </ng-template>
              </li> 
              <li ngbNavItem="rank">
                <a ngbNavLink>Rank</a>
                <ng-template ngbNavContent>
                  <app-my-rank></app-my-rank>
                </ng-template>
              </li>  -->
              <li class="nestedNav" (click)="my_page('settings')" ngbNavItem="settings" ngbDropdown>
                <a ngbNavLink ngbDropdownToggle>Settings</a>   
                <div ngbDropdownMenu>            
                  <ul ngbNav #setNav="ngbNav" [(activeId)]="activeChild" class="nav-pills">
                    <li ngbNavItem="security">
                      <a ngbNavLink>Security</a>
                      <ng-template ngbNavContent>
                        <app-security-settings></app-security-settings>
                      </ng-template>
                    </li>
                    <li ngbNavItem="apps">
                      <a ngbNavLink>Apps</a>
                      <ng-template ngbNavContent>
                        <app-apps-settings></app-apps-settings>
                      </ng-template>
                    </li>
                    <li ngbNavItem="zoom">
                      <a ngbNavLink (click)="Zoom_file()">Zoom Implementation</a>
                      <ng-template ngbNavContent>
                        <app-apps-settings></app-apps-settings>
                      </ng-template>
                    </li>
                  </ul>
                </div> 
              </li>
              
              <li (click)="my_page('sch')" ngbNavItem="sch">
                <a ngbNavLink>Schedule</a>
                <ng-template ngbNavContent>
                  <app-schedule></app-schedule>
                </ng-template>
              </li>
              <!-- <li (click)="my_page('pr')" ngbNavItem="pr">
                <a ngbNavLink>Guftagu</a>
                <ng-template ngbNavContent>
                  <app-my-pr></app-my-pr>
                </ng-template>
              </li> -->
            </ul>          
            <div class="tab-content pl-md-5">
              <div [ngbNavOutlet]="profNav"></div>
              <div [ngbNavOutlet]="setNav" *ngIf="page_name == 'settings'"></div>
            </div>
          </div>
          
    </div>
</div>

