import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'durationText'
})
export class DurationTextPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {  	
  	if(value) {
  		let durationData = value.split(':');
  		if(durationData.length == 3) {
  			let hr = parseInt(durationData[0]);
  			let min = parseInt(durationData[1]);
  			let sec = parseInt(durationData[2]);
  			return this.getDurationText(hr, min, sec);
  		}
  	}
    return null;
  }

  getDurationText(hr: number, min: number, sec: number) {
	let hours = '';
	let minutes = '';
	let seconds = '';
	let durationText = '';

	if (hr > 1) {
		hours = hr + ' Hrs ';
		durationText += hours;
	}
	else if (hr != 0) {
		hours = hr + ' Hr ';
		durationText += hours;
	}
	if(min != 0) {
		minutes = min + ' Min ';
		durationText += minutes;
	}
	if(sec != 0) {
		seconds = sec + ' Sec';
		durationText += seconds;
	}

	if(durationText == '') {
		durationText = '0 Sec';
	}
	
	return durationText;
  }

}
