import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MonitoringShareService {
  sessiontitle: string;
  coursename: string;
  mData: any;
  personalizeData: any;

  constructor() { }

  setCourseName(data) {
    this.coursename = data
  }

  getCourseName() {
    return this.coursename;
  }

}
