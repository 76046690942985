<div class="pageBody subPage">  
  <div class="row justify-content-end">
    <div class="col-12 col-xl-11 courseNorms">
      <h3 class="mb-2">Security</h3>
      <form name="security" class="floatedInputForm borderOff" [ngClass]="{submitting : loader}"
        [formGroup]="passwordForm" (ngSubmit)="saveSetting(passwordForm)">
        <div class="spinner-border text-warning formLoader" role="status" *ngIf="loader">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="row mb-3">
          <label class="col-12 col-md-4 pb-2 pb-md-0 m-0">Old Password</label>
          <div class="col-12 col-md-8">
            <input type="password" class="form-control" formControlName="password"
            [ngClass]="{ 'is-invalid': submitSettingForm && getSecuritySetting.password.errors }">
            <div *ngIf="submitSettingForm && getSecuritySetting.password.errors" class="invalid-feedback">
              <div *ngIf="getSecuritySetting.password.errors.required">
                 Required*</div>
            </div>
            <a class="txtLink" routerLink="/forgot-password">Forgot password ?</a>
          </div>
        </div>
        <div class="row mb-3">
          <label class="col-12 col-md-4 pb-2 pb-md-0 m-0">New Password</label>
          <div class="col-12 col-md-8">
            <div>
              <input type="password" class="form-control" formControlName="newpassword"
              [ngClass]="{ 'is-invalid': submitSettingForm && getSecuritySetting.newpassword.errors }">
              <div *ngIf="submitSettingForm && getSecuritySetting.newpassword.errors" class="invalid-feedback">
                <div *ngIf="getSecuritySetting.newpassword.errors.required">
                  Required*</div>              
                <div *ngIf="getSecuritySetting.newpassword.errors.minlength">
                  Password must be greater than or equal to 8 characters*</div>
                <div *ngIf="getSecuritySetting.newpassword.errors.maxlength">
                  Password must be less than or equal to 30 characters*</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <label class="col-12 col-md-4 pb-2 pb-md-0 m-0">Confirm Password</label>
          <div class="col-12 col-md-8">
            <div>
              <input type="password" class="form-control" formControlName="confirmpassword"
                [ngClass]="{ 'is-invalid': submitSettingForm && getSecuritySetting.confirmpassword.errors }">
              <div *ngIf="submitSettingForm && getSecuritySetting.confirmpassword.errors" class="invalid-feedback">
                              
                <div *ngIf="getSecuritySetting.confirmpassword.errors.required">
                  Required*</div>
                <div *ngIf="getSecuritySetting.confirmpassword.errors.minlength">
                  Password must be greater than or equal to 8 characters*</div>
                <div *ngIf="getSecuritySetting.confirmpassword.errors.maxlength">
                  Password must be less than or equal to 30 characters*</div>
                <div *ngIf="getSecuritySetting.confirmpassword.errors.mustMatch">Password mis-match</div>
              </div>
            </div>
          </div>
        </div>
        
        <div class="text-right">
          <button type="submit" class="btn btn-danger">Save Settings<i class="fas fa-check ml-2"></i></button>
        </div>
      </form>
    </div>
  </div>
</div>