<div class="new_UIstyle">
    <div class="pageBodyWrap assesssment-wrap">
        <!-- Banner Section Start -->
        <section class="banner banner-inner resourse-banner text-center"
            style="background-image: url('assets/images/banner/r-bank-01.jpg');">
            <div class="container">
                <div class="banner-content">
                    <h1 class="white-text mb-2">Resource Bank</h1>
                    <p class="avenirRoman white-text">Resources - Edit, Delete, Create Resource here</p>

                    <form class="resourse-banner-form">
                        <div class="input-with-icon input-with-icon-right">
                            <!-- FOR PAGINATION AND SEARCH |START -->
                            <input type="text" class="form-control form-control-lg" autocomplete="off"
                                #searchInput name="search" placeholder="Search for Resources"/>
                            <span class="input-with-icon-btn"><i class="fa fa-search"></i></span>
                            <!-- FOR PAGINATION AND SEARCH |END -->
                        </div>

                        <div class="mt-2">
                            <select  class="form-control form-control-lg form-control-caret" (change)="get_type($event)">
                                <option [value]="optFile.value" [title]="optFile.name" *ngFor="let optFile of files;">{{optFile.name}}</option>
                            </select>
                        </div>
                        <div class="mt-2 text-center">
                            <button class="btn btn-primary w-100 " (click)="add_resource()">+ Add New Resource</button>
                        </div>
                    </form>
                    <!-- ngbTooltip=" Add Resource" -->
                 
                </div>
            </div>
        </section>

        <section class="section resourse-bank-wrap relative" [ngClass]="{submitting : loader}">
            <div class="progress_loader" *ngIf="loader">
                <div class="spinner-border text-warning formLoader" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <div class="container">
                <div class="resourse-bank-items-list">

                    <!-- FOR PAGINATION AND SEARCH |START-->
                    <h6 class="avenirBlack" *ngIf="isSearchEnabled"><span class="primary-text">{{resource_list.length}}</span> results for “{{searchText}}”</h6>
                    <!-- FOR PAGINATION AND SEARCH |END-->
                   
                    <ng-container *ngIf="resource_list.length > 0">
                        <div class="questionBankItem resource-content-item bg-grey"
                            *ngFor="let res of resource_list | filter:searchText; let index_res = index">
                            <div class="questionItemLeft">
                                <h6 class="avenirBlack mb-3 relative resourse-title-base text-truncate">
                                    <span class="camera-icon resourse-title-icon" *ngIf="res.type == 'video'"><img src="assets/images/camera-icon.png" alt=""></span>
                                    <span class="file-icon resourse-title-icon" *ngIf="res.type == 'file'"> <i class="fas fa-folder"></i></span>
                                    <span class="file-icon resourse-title-icon" *ngIf="res.type == 'image'"> <i class='fas fa-images' ></i></span>
                                    <span class="file-icon resourse-title-icon" *ngIf="res.type == 'python'"> <i class="fab fa-python"></i></span>
                                    <a class="primary-text h6 font-bold  resourse-title-base-title" (click)="viewResource(res)">{{res.title !== '' ?  (res.title | capitalization ): ('no resource title available' | capitalization)}}</a> 
                                </h6>

                                <p *ngIf="res.type" class="mb-2">File Type: <strong class="primary-text">{{res.type | capitalization}}</strong></p>

                                <p *ngIf="res.username" class="mb-2">Added by: <strong class="primary-text">{{res.username | capitalization}}</strong></p>
                                <p *ngIf="res.created_by" class="mb-2">Added by: <strong class="primary-text">{{res.created_by | capitalization}}</strong></p>


                                <p *ngIf="res.history.length > 0" class="mb-2">Course: 
                                    <strong class="primary-text" *ngFor="let hist of res.history.slice(0, 2); let last = last">
                                    {{hist.course_title !== '' ?  hist.course_title : 'No course title available' | capitalization}} 
                                    <ng-container *ngIf="!last">,</ng-container></strong>
                                    <ng-container *ngIf="res.history.length > 2"><b>... {{res.history.length - 2}} More</b></ng-container>
                                </p>

                                <div class="tags-badges" *ngIf="res.tags.length > 0">
                                    <span class="badge badge-green-lt" *ngFor="let a_tag of res.tags">{{a_tag.tag | titlecase}}</span>
                                </div>
                            </div>
                            <div class="questionItemRight resourse-action icons-group">
                                <a href="javascript:void(0);" class="questionItemLink ItemGetLink" ngbTooltip="Get Link" 
                                (click)="copylinkUrl(res.url)">
                                    <i class="fa fa-link"></i></a>
                                <a href="javascript:void(0);" class="questionItemLink ItemEditLink" *ngIf="res.edit_rights" ngbTooltip="Edit"
                                    (click)="edit_resource(res)"><i class="fas fa-pencil-alt"></i></a>

                                <a href="javascript:void(0);" class="questionItemLink ItemDeleteLink" *ngIf="res.delete_rights"
                                    (click)="openModelDeleteRes(deleteRes)" ngbTooltip="Delete">
                                    <i class="fas fa-trash-alt"></i>
                                </a>

                                      <!-- new ui delete question -->
                    <ng-template #deleteRes let-c="close" let-d="dismiss">
                        <div class="new_UIstyle">
                            <div class="modal-header justify-content-between align-items-center">
                                <h5 class="avenirBook modal-title text-danger mb-0">Delete Resourse</h5>
                                <button type="button" class="close ml-0" aria-label="Close"
                                    (click)="closeResPopup()">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <p>Are you sure you want to delete Resource<strong class="text-danger">?</strong></p>
                            </div>

                            <div class="modal-footer">
                                <div class="text-left">
                                    <button type="submit" class="btn btn-primary btn-sm"
                                        (click)="closeResPopup()">NO</button>
                                </div>
                                <div class="text-right">
                                    <button type="submit" class="btn btn-primary btn-sm"
                                    (click)="delete_resource(res)">YES</button>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                            </div>
                            <a  href="javascript:void(0);" class="questionItemLink ItemAnchorLink" (click)="viewResource(res)"><i class="fa fa-angle-right"></i></a> 
                        </div>
                    </ng-container>
                    <div class="text-center mt-4" *ngIf="resource_list?.length == 0 && !loader || normVal">
                        <app-no-found [errorMsg]="searchMsg"></app-no-found>
                    </div> 
                </div>
                <!-- FOR PAGINATION AND SEARCH |START -->
                <div *ngIf="!isSearchEnabled" class="container text-center pagena justify-content-center mt-4">
                    <ngb-pagination [pageSize]="pageSize" [collectionSize]="totalDatalength" [(page)]="page" [maxSize]="maxPageNoDisplay" aria-label="Default pagination" class="pagena pagination-wrap" (pageChange)="onPageChanged($event)" [boundaryLinks]="false">
                        <ng-template ngbPaginationPrevious><</ng-template>
                        <ng-template ngbPaginationNext>></ng-template>
                    </ngb-pagination>
                </div>
                <!-- FOR PAGINATION AND SEARCH |END-->
            </div>
        </section>
    </div>
</div>



