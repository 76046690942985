import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { NotificationService } from 'src/app/services/notification.service';
import { ResourceService } from 'src/app/services/resources/resource.service';
import { SharedResourceService } from 'src/app/services/resources/shared-resource.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


//FOR PAGINATION AND SEARCH
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, filter } from 'rxjs/operators';

@Component({
  selector: 'app-search-resources',
  templateUrl: './search-resources.component.html',
  styleUrls: ['./search-resources.component.scss']
})
export class SearchResourcesComponent implements OnInit {

  //FOR PAGINATION AND SEARCH
  @ViewChild('searchInput', { static: false }) searchInput!: ElementRef;
  maxPageNoDisplay: number = 3;
  totalDatalength: any = 0;
  pageSize: number = 10;
  page: any = 1;
  isSearchEnabled: boolean = false;

  constructor(private resource_service: ResourceService,
    private ns: NotificationService,
    private router: Router,
    private modalService: NgbModal,
    private shared_resouce: SharedResourceService) { }

  searchText: string = '';
  file_name: string = '';
  loader: boolean = false;
  files: any[] = [
    { name: 'All Type', value: '' },
    { name: 'Video', value: 'video' },
    { name: 'Image', value: 'image' },
    { name: 'Audio', value: 'audio' },
    { name: 'Python', value: 'python' },
    { name: 'Others(PDF,CSV,Excel etc.)', value: 'file' },
  ]
  resource: any[] = [
  ]
  resource_list: any[] = [];
  isResourceAvailable: boolean = true;

  ngOnInit() {
    this.Serach_resource('', '1');
  }

  total_page;
  total_pages = [];
  current_page;
  normVal:boolean

  Serach_resource(file_type, page) {
    this.searchText = '';
    this.loader = true;
    this.total_pages = [];
    this.resource_list = [];
    this.resource_service.serach_resource(file_type, page).pipe(finalize(() => {
      this.loader = false;
    })).subscribe(resource => {
      
      //FOR PAGINATION AND SEARCH
      this.totalDatalength = resource.total_pages * this.pageSize;

      this.resource = resource.response;
      this.resource_list = resource.response;
      this.isResourceAvailable = true;
      this.total_page = resource.total_pages
      this.current_page = resource.current_page;
      this.normVal=false
      if (this.resource.length == 0) {
        this.ns.showNotification('info', 'No resource found in this category');
        this.total_pages.push(1);
        
        return;
      }
      if (this.current_page > 2) {
        for (let i = this.current_page - 1; i >= this.current_page - 1; i--) {
          if (i != 0 && this.total_page > i) {
            this.total_pages.push(i);
          }
          this.total_pages.sort();
        }
      }
      if (this.total_page > this.current_page) {
        for (let i = this.current_page; i <= this.current_page + 5; i++) {
          if (i != 0 && this.total_page > i) {
            this.total_pages.push(i);
          }
        }
      } else {
        if (this.current_page == this.total_page) {
          this.total_pages.push(this.total_page);
        }
        return;
      }
    },
      (err) => {
        console.log(err);
      })
  }

  //FOR PAGINATION AND SEARCH
  onPageChanged(page: string) {
    this.page = parseInt(page, 10) || 1;
    this.Serach_resource('', this.page);
  }

  //FOR PAGINATION AND SEARCH
  ngAfterViewInit() {
    fromEvent(this.searchInput.nativeElement, 'input')
      .pipe(
        debounceTime(400),
        distinctUntilChanged(),
        tap((event) => {
          this.page = 1;
          this.searchText = '';
          let searchVal = '';
          if (this.searchInput.nativeElement.value.length > 0) {
            this.isSearchEnabled = true;
            this.searchText = this.searchInput.nativeElement.value;
            this.search_resource(this.searchText);
          }
          else {
            this.isSearchEnabled = false;
            this.Serach_resource('', this.page);
          }
        })
      )
      .subscribe();
  }

  open_file(url) {
    window.open(url);
  }

  get_type(event) {
    if (event.target.value == 'select') {
      return;
    }
    this.page = 1;
    if (event.target.value != '') {
      this.file_name = event.target.value;
    } else {
      this.file_name = 'All File';
    }
    this.search_resource(this.searchText);

    // this.Serach_resource(event.target.value, this.page)
  }

  send_page(page) {
    let value = '';
    this.page = page
    if (this.file_name == 'All File') {
      value = '';
    } else {
      value = this.file_name;
    }
    if (this.total_page < this.page) {
      this.ns.showNotification('info', 'Next page is not available');
      this.page = this.current_page;
      return;
    }

    if (this.page < 1) {
      this.ns.showNotification('info', 'Previous page is not available');
      this.page = this.current_page;
      return;
    }
    this.Serach_resource(value, this.page)
  }

  check_file(type) {
    if (type == 'file') {
      return true
    } else if (type == 'python') {
      return true;
    }
    return false;
  }
  search_elastic = false;
  search_resource(value: string) {
    this.search_elastic = true;
    let type = ''
    if (this.file_name == 'All File') {
      type = '';
    } else {
      type = this.file_name;
    }

    if (value != '') {
      this.loader = true;
      this.resource_service.serach_resource_elastic(value, type).pipe(finalize(() => {
        this.loader = false;
        this.search_elastic = false;
      })).subscribe(elastic_search => {
        this.resource_list = elastic_search;

        console.log(this.resource_list);
        
        this.normVal = false
        if (this.resource_list.length == 0) {
          
          this.ns.showNotification('info', 'There is no resource for this name');
          // this.resource_list = this.resource;
          this.normVal = true
          return;
        }
      },
        (err) => {
          console.log(err);
        })
    }
    if (this.resource_list.length == 0 && value == '') {
      this.resource_list = this.resource;
      this.search_elastic = false;
      this.normVal = true
      this.loader = false;
      return;
    } else if (value == '') {
      this.search_elastic = false;
      this.loader = false;
      this.resource_list = [];
      this.resource_list = this.resource;
    }
  }

  showSchBx = false;
  openSchBx() {
    this.showSchBx = !this.showSchBx;
  }

  edit_resource(resource) {
    this.getData(resource)
    this.router.navigate(['/resource-bank/resources'], { queryParams: { resource_id: resource._id } })
  }

  getData(_resource) {
    if (Object.keys(_resource).length > 0) {
      this.shared_resouce.saveResouce(_resource);
    }
  }

  add_resource() {
    this.router.navigate(['/resource-bank/resources']);
  }

  viewResource(resource) {
    console.log(resource)
    this.router.navigate(['/resource-bank/resources-details'], { queryParams: { resourceId: resource._id ,instanceId:resource.instance_id} });
   
  }

  questionModel = null;
  openModelDeleteRes(deleteRes) {
    this.questionModel = this.modalService.open(deleteRes, { size: "sm", centered: true });
  }

  closeResPopup() {
    this.questionModel.close();
    // this.questionId = null;
  }

  delete_resource(res) {
      this.resource_service.delete_resource(res._id, res.instance_id).subscribe(get_info => {
        this.ns.showNotification('success', get_info.message);
        this.questionModel.close();
        let type = ''
        if (this.file_name != 'All File') {
          type = this.file_name;
        } else {
          type = ''
        }
        this.Serach_resource(type, this.page);
      },
        (err) => {
          this.ns.showNotification('error', err.message);
        })

  }
    /* Get Link */  
    copylinkUrl(url) {  
      navigator.clipboard.writeText(url);
      this.ns.showNotification('success', 'link copied');
    }

  //FOR BUILD FIX
  searchMsg: string = '';

}

