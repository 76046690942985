import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { NgbModalConfig, NgbModal, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter, finalize, map } from 'rxjs/operators';

import { ShowCourseView, allCategory } from '../../model/course.model';
import { CourseService } from '../../services/course.service';
import { NotificationService } from '../../services/notification.service';
import { teachers } from '../../model/user.model';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { UserService } from 'src/app/services/user.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ResourceService } from 'src/app/services/resources/resource.service';
import { fromEvent } from 'rxjs';
import { async } from '@angular/core/testing';
declare var tinymce: any;
@Component({
  selector: 'app-add-ec-course',
  templateUrl: './add-ec-course.component.html',
  styleUrls: ['./add-ec-course.component.scss']
})
export class AddEcCourseComponent implements OnInit {
  toolbarWithoutBlankButton = 'undo redo | formatselect | bold italic backcolor | \
  alignleft aligncenter alignright alignjustify | \
  bullist numlist outdent indent | removeformat | \
  link image | preview media help';

  public confFld: any;

  public addCourseForm: FormGroup;
  public assessmentForm: FormGroup;
  selectedFiles: FileList;
  fileName: string;
  model: NgbDateStruct;
  showLb2: boolean;
  showTopic: null;
  show: boolean;
  showSessionRes: null
  btnStat: any = 'maxi';
  btnStat2: any = 'maxi';

  submitAddCourse = false;
  liveSession: FormArray;
  topic: FormArray;
  session_resource: FormArray;
  course_assessments: FormArray;
  course_work: FormArray;
  course_resources: FormArray;
  addAssessment: FormArray;
  session_assessement: FormArray;

  live_session_topic_level: FormArray;
  loader = false;
  topicLevel = 1;
  underTopic = 1;

  listOfCourses: allCategory[] = [];

  organisationList: [];
  teachers: teachers[];

  listOfCoursesnew: allCategory[] = [];
  listOfCoursesnewdata:any;
  closelength:any;
  catgeorycourseListdata: [];

  componentName: string;
  ngdate: NgbDateStruct = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate()
  };
  startDateModel: NgbDateStruct = this.ngdate;
  endDateModel: NgbDateStruct;

  assessmentTopicIndex;
  assessmentSessionIndex;
  assessmentPopup;
  openModel;
  key;
  courseId = '';
  courseName;
  saveButton;
  showCourseData: ShowCourseView;
  option_values = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  save_button = false;
  resource_list = [];
  routerUrl: any;

  constructor(config: NgbModalConfig,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private courseService: CourseService,
    private notificationService: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
    private user_service: UserService,
    private resource_service: ResourceService,
    private renderer: Renderer2) {
    config.backdrop = 'static';
    this.callMceEditor('about_course')
    this.renderer.addClass(document.body, 'addEdit');
  }

  ngOnInit() {
    this.validateForm();
    this.getListOfCourses()
    this.organisations();
    this.getAllCourseWork();
    this.getcourseAssessmentList();
    this.getListOfCoursesnew();
    this.saveButton = 'unpublished';

    this.assessmentForm = this.formBuilder.group({
      assessmentArray: this.formBuilder.array([this.createAssessment('session')]),
    })
    this.componentName = "Add Course";
    if (this.courseId) {
      this.getEditCourse();
      this.getSubscribeStudent();
      this.componentName = "Edit Course";
    } else {
      let username = localStorage.getItem('currentUser');
      this.addCourseForm.controls['authors'].setValue([{ name: username }]);
      this.addCourseForm.controls.live_sessions['controls'][0].removeControl('meeting_details');
    }

    this.getZoom_account(this.courseId);

    let sp = this.router.url.split('?')
    this.routerUrl = sp[0];
    console.log(this.routerUrl)
  }


  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'addEdit');
  }

  resetConfURL(val, index) {
    if (val == 'Custom') {
      // session.controls.confFld.setValue('cfldCustom')
    } else if (val == 'zoom') {
      // session.controls.confFld.setValue('cfldZoom')
    }
    this.addCourseForm.controls.live_sessions['controls'][index].controls.confFld.setValue('');
  }

  setValueLS(value, index, zoom_account, user_id) {
    this.addCourseForm.controls.live_sessions['controls'][index].controls.confFld.setValue(value);
    if (this.addCourseForm.controls.live_sessions['controls'][index].controls.zoom_account == undefined) {
      this.addCourseForm.controls.live_sessions['controls'][index].addControl('zoom_account', this.creat_zoom_form());
      this.addCourseForm.controls.live_sessions['controls'][index].removeControl('meeting_details');
    }
    if (zoom_account != '') {
      if (this.addCourseForm.controls.live_sessions['controls'][index].controls.zoom_account != undefined) {
        this.addCourseForm.controls.live_sessions['controls'][index].controls.zoom_account.controls.api_key.setValue(zoom_account.api_key);
        this.addCourseForm.controls.live_sessions['controls'][index].controls.zoom_account.controls.email.setValue(zoom_account.email);
        this.addCourseForm.controls.live_sessions['controls'][index].controls.zoom_account.controls.secret_key.setValue(zoom_account.secret_key);
        this.addCourseForm.controls.live_sessions['controls'][index].controls.zoom_account.controls.user_id.setValue(user_id);
        this.addCourseForm.controls.live_sessions['controls'][index].controls.url.setValue('');
      }
    } else {
      if (this.addCourseForm.controls.live_sessions['controls'][index].controls.zoom_account != undefined) {
        this.addCourseForm.controls.live_sessions['controls'][index].controls.zoom_account.controls.api_key.setValue('');
        this.addCourseForm.controls.live_sessions['controls'][index].controls.zoom_account.controls.email.setValue('');
        this.addCourseForm.controls.live_sessions['controls'][index].controls.zoom_account.controls.secret_key.setValue('');
        this.addCourseForm.controls.live_sessions['controls'][index].controls.zoom_account.controls.user_id.setValue('');
        if (this.addCourseForm.controls.live_sessions['controls'][index].controls.meeting_details != undefined) {
          this.addCourseForm.controls.live_sessions['controls'][index].removeControl('meeting_details');
        }
      }
    }
  }

  get addCourse() { return this.addCourseForm.controls; }

  validateForm(): void {

    this.addCourseForm = this.formBuilder.group({
      course_category_id: ['', Validators.required],
      course_class:['', Validators.required],
      subject: ['', Validators.required],
      status: "",
      learning_objectives: ['', Validators.required],
      banner_img: ['', Validators.required],
      authors: [''],
      teach_assis: [''],
      instructors: ['', Validators.required],
      editors: [''],
      organisations: ['', Validators.required],
      live_sessions: this.formBuilder.array([this.createLiveSession()]),
      topics: this.formBuilder.array([this.createTopic()]),
      course_work: this.formBuilder.array([this.createArray('coursework')]),
      course_assessments: this.formBuilder.array([this.createArray('assessment')]),
      course_resources: this.formBuilder.array([this.createArray('resource')]),

    });
  }

  createLiveSession(): FormGroup {
    this.submitAddCourse = false;
    return this.formBuilder.group({
      liveSession_title :['',Validators.required],
      start_date: [null, Validators.required],
      end_date: [null, Validators.required],
      url: [''],
      start_time: ['', Validators.required],
      duration: ['', Validators.required],
      frequency: ['', Validators.required],
      confFld: false,
      waiting_room: false,
      password: true,
      zoom_account: this.creat_zoom_form(),
      meeting_details: this.meeting_details(),
    });
  }

  addLiveSession(type?: string): void {
    this.submitAddCourse = false;
    this.liveSession = this.addCourseForm.get('live_sessions') as FormArray;
    this.liveSession.push(this.createLiveSession());
    if (type == 'click') {
      this.addCourseForm.controls.live_sessions['controls'][this.liveSession.length - 1].removeControl('meeting_details');
    }
  }

  createTopic(): FormGroup {
    this.submitAddCourse = false;
    return this.formBuilder.group({
      title: ['Bayesian Learning', Validators.required],
      description: ['', Validators.required],
      _id: [''],
      sessions: this.formBuilder.array([this.createArray('session')]),
      resources: this.formBuilder.array([this.createArray('resource')]),
      course_work: this.formBuilder.array([this.createArray('coursework')]),
      assessments: this.formBuilder.array([this.createArray('assessment')])

    })
  }

  addMoreAssessement(value) {
    this.submitAddCourse = false;
    this.addAssessment = this.assessmentForm.get('assessmentArray') as FormArray;
    this.addAssessment.push(this.createAssessment(value));
  }

  createAssessment(value) {
    this.submitAddCourse = false;
    if (value == 'live_session') {
      return this.formBuilder.group({
        _id: ['', Validators.required],
      });
    } else if (value == 'session') {
      return this.formBuilder.group({
        _id: ['', Validators.required],
        time: ['']
      });
    }
  }

  saveAssessment() {
    let assessmentIndex = 0;
    if (this.assessmentForm.invalid) {
      if (this.assessmentForm.value.assessmentArray.length == 1) {
        this.notificationService.showNotification('info', 'add assessment at ' + this.key + ' level if you wants');
      }
      else {
        this.notificationService.showNotification('info', 'Please add correct assessment at ' + this.key + ' level');
        return;
      }

    }
    let assessment = this.addCourseForm.controls.topics['controls'][this.assessmentTopicIndex].controls.sessions['controls'][this.assessmentSessionIndex].controls.assessments;
    this.assessmentForm.controls.assessmentArray['controls'].forEach(element => {
      if (this.key == 'session') {
        assessment.controls[assessmentIndex].controls.time.setValue(element.value.time);
      }
      assessment.controls[assessmentIndex].controls._id.setValue(element.value._id);
      assessmentIndex = assessmentIndex + 1;
      if (assessment.value.length < this.assessmentForm.value.assessmentArray.length) {
        this.addArray('addSessionAssessment', 'level', this.assessmentTopicIndex, this.assessmentSessionIndex);
      }
    });
    let controls = <FormArray>this.assessmentForm.controls.assessmentArray;
    while (controls.length != 1) {
      controls.removeAt(0);
    }
    this.assessmentForm.reset();
    this.assessmentPopup.close();

  }

  closeAssessment() {
    let controls = <FormArray>this.assessmentForm.controls.assessmentArray;
    while (controls.length != 1) {
      controls.removeAt(0);
    }
    this.assessmentForm.reset();
    this.assessmentPopup.close();
  }

  remove_assessment(assessmentIndex) {
    let controls = <FormArray>this.assessmentForm.controls.assessmentArray;
    controls.removeAt(assessmentIndex);
    if (this.assessmentForm.value.assessmentArray.length > 0) {
      let assessment = this.addCourseForm.controls.topics['controls'][this.assessmentTopicIndex].controls.sessions['controls'][this.assessmentSessionIndex].controls.assessments;
      assessment.removeAt(assessmentIndex);
    }
    if (assessmentIndex == 0) {
      this.addMoreAssessement(this.key)
    }
  }

  print(topic_live_session_info) {
    console.log(topic_live_session_info);

  }
  createArray(type) {
    this.submitAddCourse = false;
    if (type == 'session') {
      return this.formBuilder.group({
        title: ['session_title', Validators.required],
        description: ['description', Validators.required],
        url: [''],
        file_id: [''],
        type: [''],
        key: 'session',
        _id: [""],
        status: [""],
        assessments: this.formBuilder.array([this.createAssessment('session')]),
        resources: this.formBuilder.array([this.createArray('resource')]),
      });
    } else if (type == 'live_session') {
      return this.formBuilder.group({
        title: ['session_title', Validators.required],
        description: ['description', Validators.required],
        url: [''],
        file_id: [''],
        key: 'live_session',
        _id: [""],
        status: [""],
        assessments: this.formBuilder.array([this.createAssessment('live_session')]),
        resources: this.formBuilder.array([]),

      });
    } else if (type == 'resource') {
      return this.formBuilder.group({
        title: ['title ', Validators.required],
        url: [''],
        _id: [''],
        type: ['', Validators.required],
      });
    } else if (type == 'coursework') {
      return this.formBuilder.group({
        title: ['title ', Validators.required],
        _id: ['', Validators.required],
        group_type: [""],
        date: [null, Validators.required],
        time: [null, Validators.required],
        peer_review: false,
        // peer_size: [''],
        self_review: false,
        is_group: false
      });
    } else if (type == 'assessment') {
      return this.formBuilder.group({
        title: ['title ', Validators.required],
        _id: ['', Validators.required],
        calendered: false,
        date: [null],
        time: [null],
        is_timed: false,
        one_time_assessment:false
      });
    }
  }

  addArray(event, level, index, sesstionIndex: any = null) {
    let value;

    if (event.target != undefined) {
      value = event.target.value;
      event.target.value = 'select'
    } else {
      value = event;
    }
    this.submitAddCourse = false;
    this.topic = this.addCourseForm.get('topics') as FormArray;
    for (let i = 0; i < this.topicLevel; i++) {
      if (value == 'topics') {
        this.topic.push(this.createTopic());
      } else if (value == 'session') {
        this.topic.controls[index]['controls'].sessions.push(this.createArray('session'));
      } else if (value == 'live_session') {
        this.topic.controls[index]['controls'].sessions.push(this.createArray('live_session'));
      } else if (value == 'coursework' && index != null) {
        this.topic.controls[index]['controls'].course_work.push(this.createArray('coursework'));
      } else if (value == 'assessment' && index != null) {
        this.topic.controls[index]['controls'].assessments.push(this.createArray('assessment'));
      } else if (value == 'courseworks' && index == null) {
        this.course_work = this.addCourseForm.get('course_work') as FormArray;
        this.course_work.push(this.createArray('coursework'));
      } else if (value == 'course_assessments' && index == null) {
        this.course_assessments = this.addCourseForm.get('course_assessments') as FormArray;
        this.course_assessments.push(this.createArray('assessment'));
      } else if (value == 'resource' && sesstionIndex != undefined) {
        this.session_resource = this.topic.controls[index]['controls'].sessions as FormArray;
        this.session_resource.controls[sesstionIndex]['controls'].resources.push(this.createArray('resource'));
      } else if (value == 'resource' && index != null && sesstionIndex == undefined) {
        this.topic.controls[index]['controls'].resources.push(this.createArray('resource'));
      } else if (value == 'course_resources' && index == null) {
        this.course_resources = this.addCourseForm.get('course_resources') as FormArray;
        this.course_resources.push(this.createArray('resource'));
      } else if (value == 'addSessionAssessment') {
        this.session_assessement = this.topic.controls[index]['controls'].sessions as FormArray;
        this.session_assessement.controls[sesstionIndex]['controls'].assessments.push(this.createAssessment(this.key));
      }
    }
    this.topicLevel = 1;
    this.checkState = false
  }

  removeArray(controlName: string, index: any, controlIndex: any, resIndex: any, type?: string) {
    if (type == 'manual') {
      if (confirm('Are you sure want to delete ' + controlName + ' ' + (index + 1))) {
        this.remove_value(controlName, index, controlIndex, resIndex);
      } else {
        return
      }
    } else {
      this.remove_value(controlName, index, controlIndex, resIndex);
    }

  }

  remove_value(controlName, index, controlIndex, resIndex) {
    let control;
    let deleteIndex;
    if (controlName == 'topics' || controlName == 'course_work' || controlName == 'course_assessments' || controlName == "live_sessions" || controlName == "course_resources") {
      control = <FormArray>this.addCourseForm.controls[controlName];
      deleteIndex = index;
    } else if (controlName == "assessments" || controlName == 'topic_course_work' || controlName == 'sessions' || controlName == 'topic_resource' || controlName == 'live_session') {
      if (controlName == 'topic_course_work') {
        controlName = 'course_work'
      } else if (controlName == 'topic_resource') {
        controlName = 'resources';
      }
      control = <FormArray>this.addCourseForm.get('topics')['controls'][index].controls[controlName]
      deleteIndex = controlIndex;
    } else if (controlName == "resources") {
      control = <FormArray>this.addCourseForm.get('topics')['controls'][index].controls['sessions'].controls[controlIndex].controls[controlName]
      deleteIndex = resIndex;
    } else if (controlName == "sessionassessments") {
      control = <FormArray>this.addCourseForm.get('topics')['controls'][index].controls['sessions'].controls[controlIndex].controls['assessments']
      deleteIndex = resIndex;
    }
    control.removeAt(deleteIndex);
  }

  organisations() {
    this.notificationService.getOrganisation().subscribe(orgList => {
      this.organisationList = orgList.data;
    });
  }

  teachersList(data, roles, type?: any) {
    let organization = [];
    if (type == 'teach_assis' && this.addCourseForm.value.organisations == undefined) {
      this.notificationService.showNotification('info', 'Please add organization first');
      return;
    }
    if (this.addCourseForm.value.organisations.length > 0) {
      this.addCourseForm.value.organisations.filter(org => {
        organization.push(org.name);
      })
    }
    let role = roles;
    this.notificationService.getTeachersList(data.target.value, role, organization).subscribe((list: teachers[]) => {
      this.teachers = list;
    })
  }
  progress = 0;
  upload_resource(modelName, file, type, parentIndex, childIndex, grandChildIndex) {
    let resource_data;
    this.notificationService.upload_resource(modelName, file).pipe(finalize(() => {
      this.loader = false;
    })).subscribe((res: HttpEvent<any>) => {
      switch (res.type) {
        case HttpEventType.Sent:
          break;
        case HttpEventType.ResponseHeader:
          break;
        case HttpEventType.UploadProgress:
          this.progress = Math.round(res.loaded / res.total * 100);
          break;
        case HttpEventType.Response:
          resource_data = res.body.resource_info[0];
          this.notificationService.showNotification('success', res.body.message);

          if (type == 'bannerImage') {
            this.addCourseForm.controls.banner_img.setValue(resource_data.resource_id);
          } else if (type == 'session_url') {
            this.addCourseForm.controls.topics['controls'][parentIndex].controls.sessions['controls'][childIndex].controls.type.setValue('file');
            this.addCourseForm.controls.topics['controls'][parentIndex].controls.sessions['controls'][childIndex].controls.file_id.setValue(resource_data.resource_id);
            this.addCourseForm.controls.topics['controls'][parentIndex].controls.sessions['controls'][childIndex].controls.url.setValue('');
          } else if (type == 'session_resource') {
            this.addCourseForm.controls.topics['controls'][parentIndex].controls.sessions['controls'][childIndex].controls.resources['controls'][grandChildIndex].controls.type.setValue('file');
            this.addCourseForm.controls.topics['controls'][parentIndex].controls.sessions['controls'][childIndex].controls.resources['controls'][grandChildIndex].controls._id.setValue(resource_data.resource_id);
            this.addCourseForm.controls.topics['controls'][parentIndex].controls.sessions['controls'][childIndex].controls.resources['controls'][grandChildIndex].controls.url.setValue('');
          } else if (type == 'topic_resource') {
            this.addCourseForm.controls.topics['controls'][parentIndex].controls.resources['controls'][childIndex].controls.type.setValue('file');
            this.addCourseForm.controls.topics['controls'][parentIndex].controls.resources['controls'][childIndex].controls._id.setValue(resource_data.resource_id);
            this.addCourseForm.controls.topics['controls'][parentIndex].controls.resources['controls'][childIndex].controls.url.setValue('');
          } else if (type == 'course_resource') {
            this.addCourseForm.controls.course_resources['controls'][parentIndex].controls.type.setValue('file');
            this.addCourseForm.controls.course_resources['controls'][parentIndex].controls._id.setValue(resource_data.resource_id);
            this.addCourseForm.controls.course_resources['controls'][parentIndex].controls.url.setValue('');
          }
          setTimeout(() => {
            this.progress = 0;
          }, 1500);
      }

    },
      (err) => {
        this.progress = 0;
        this.notificationService.showNotification('info', 'Please add again not able to save your file');
      })

  }

  saveType(type, parentIndex, childIndex, grandChildIndex) {
    if (type == 'session_url') {
      this.addCourseForm.controls.topics['controls'][parentIndex].controls.sessions['controls'][childIndex].controls.type.setValue('text');
    } else if (type == 'session_resource') {
      this.addCourseForm.controls.topics['controls'][parentIndex].controls.sessions['controls'][childIndex].controls.resources['controls'][grandChildIndex].controls.type.setValue('text');
    } else if (type == 'topic_resource') {
      this.addCourseForm.controls.topics['controls'][parentIndex].controls.resources['controls'][childIndex].controls.type.setValue('text');
    } else if (type == 'course_resource') {
      this.addCourseForm.controls.course_resources['controls'][parentIndex].controls.type.setValue('text');
    }
  }

  allCourseWork = [];
  getAllAssessment: any[];
  getAllCourseWork() {
    this.loader = true;
    this.allCourseWork = [];
    this.courseService.fetchAllCourseWork().pipe(finalize(() => {
      this.loader = false;
    })).subscribe(data => {
      let list_course_work = data.course_works
      list_course_work.filter(filter_course_work => {
        if (filter_course_work.publish) {
          this.allCourseWork.push(filter_course_work);
        }
      });
    });
  }

  getcourseAssessmentList() {
    this.courseService.courseAssessmentList().subscribe(assessment => {
      this.getAllAssessment = assessment.assessment_list;
    });
  }

  month_name(dt) {
    let mlist = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    return mlist.indexOf(dt)
  };

  courseClassDisabled:boolean = false;
  topi:any;
  topi1: any=[];
  getEditCourse() {
    this.loader = true;
    this.courseService.editCourse(this.courseId, this.courseName)
      .pipe(finalize(() => this.loader = true)).subscribe(async res => {
        this.showCourseData = res;
        this.saveButton = this.showCourseData.course_info.status;
        this.addCourseForm.controls['course_category_id'].setValue(this.showCourseData.course_info.course_category_id);
        this.addCourseForm.controls['course_class'].setValue(this.showCourseData.course_info.course_class);
        this.addCourseForm.controls['subject'].setValue(this.showCourseData.course_info.subject);
        this.addCourseForm.controls['learning_objectives'].setValue(this.showCourseData.course_info.learning_objectives);
        tinymce.get("about_course").setContent(this.showCourseData.course_info.learning_objectives);
        this.addCourseForm.controls['organisations'].setValue(this.showCourseData.course_info.organisations);
        this.addCourseForm.controls['authors'].setValue(this.showCourseData.course_info.authors);
        this.addCourseForm.controls['editors'].setValue(this.showCourseData.course_info.editors);
        this.addCourseForm.controls['instructors'].setValue(this.showCourseData.course_info.instructors);
        this.addCourseForm.controls['teach_assis'].setValue(this.showCourseData.course_info.teach_assis);
        this.addCourseForm.controls['banner_img'].setValue(this.showCourseData.course_info.banner_img);
        this.fileName = this.showCourseData.course_info.banner_img;
        this.courseClassDisabled = true;
  
        const getTopicDetails = async (item) => {
          try {
             const data = await this.courseService.get_topicSession(this.courseId,item['_id']).toPromise();
             return data;
          } catch(error) {
            return item;
          }
       };
       this.loader = true
       const promiseArray = await Promise.all(this.showCourseData.course_info.topics.map(async (item) => await getTopicDetails(item)));
       this.showCourseData.course_info.topics = promiseArray
       this.loader = false
      

        for (let live_sess = 0; live_sess < this.showCourseData.course_info.live_sessions.length; live_sess++) {
          let livesessionMinimumDateIS = this.showCourseData.course_info.live_sessions[live_sess]['start_date'];
          

          if (livesessionMinimumDateIS != null) {
            // if((Number(livesessionMinimumDateIS.day) < Number(this.liveSessionMinDate.day)) && (Number(livesessionMinimumDateIS.month) < Number(this.liveSessionMinDate.month))){
            // this.liveSessionMinDate = livesessionMinimumDateIS
            // }
          }
          this.addCourseForm.controls.live_sessions['controls'][live_sess].controls.liveSession_title.setValue(this.showCourseData.course_info.live_sessions[live_sess]['liveSession_title']);
          this.addCourseForm.controls.live_sessions['controls'][live_sess].controls.start_date.setValue(this.showCourseData.course_info.live_sessions[live_sess]['start_date']);
          this.addCourseForm.controls.live_sessions['controls'][live_sess].controls.end_date.setValue(this.showCourseData.course_info.live_sessions[live_sess]['end_date']);
          this.addCourseForm.controls.live_sessions['controls'][live_sess].controls.url.setValue(this.showCourseData.course_info.live_sessions[live_sess]['url']);
          this.addCourseForm.controls.live_sessions['controls'][live_sess].controls.start_time.setValue(this.showCourseData.course_info.live_sessions[live_sess]['start_time']);
          this.addCourseForm.controls.live_sessions['controls'][live_sess].controls.frequency.setValue(this.showCourseData.course_info.live_sessions[live_sess]['frequency']);
          this.addCourseForm.controls.live_sessions['controls'][live_sess].controls.duration.setValue(this.showCourseData.course_info.live_sessions[live_sess]['duration']);
          this.addCourseForm.controls.live_sessions['controls'][live_sess].controls.confFld.setValue("cfldCustom");
          this.addCourseForm.controls.live_sessions['controls'][live_sess].controls.password.setValue(this.showCourseData.course_info.live_sessions[live_sess]['password']);
          this.addCourseForm.controls.live_sessions['controls'][live_sess].controls.waiting_room.setValue(this.showCourseData.course_info.live_sessions[live_sess]['waiting_room']);
          if (this.showCourseData.course_info.live_sessions[live_sess]['meeting_details'] != undefined) {
            this.addCourseForm.controls.live_sessions['controls'][live_sess].controls.meeting_details.controls.host_id.setValue(this.showCourseData.course_info.live_sessions[live_sess]['meeting_details']['host_id']);
            this.addCourseForm.controls.live_sessions['controls'][live_sess].controls.meeting_details.controls.join_url.setValue(this.showCourseData.course_info.live_sessions[live_sess]['meeting_details']['join_url']);
            this.addCourseForm.controls.live_sessions['controls'][live_sess].controls.meeting_details.controls.start_url.setValue(this.showCourseData.course_info.live_sessions[live_sess]['meeting_details']['start_url']);
            this.addCourseForm.controls.live_sessions['controls'][live_sess].controls.meeting_details.controls.uuid.setValue(this.showCourseData.course_info.live_sessions[live_sess]['meeting_details']['uuid']);
            this.addCourseForm.controls.live_sessions['controls'][live_sess].controls.meeting_details.controls.meeting_id.setValue(this.showCourseData.course_info.live_sessions[live_sess]['meeting_details']['meeting_id']);

            this.addCourseForm.controls.live_sessions['controls'][live_sess].removeControl('zoom_account');
          } else {
            this.addCourseForm.controls.live_sessions['controls'][live_sess].removeControl('meeting_details');
          }
          this.addLiveSession();

          if (live_sess + 1 == this.showCourseData.course_info.live_sessions.length) {
            this.removeArray('live_sessions', live_sess + 1, null, null);
          }
        }

        if (this.showCourseData.course_info.live_sessions.length == 0) {
          this.removeArray('live_sessions', 0, null, null);
        }
             
        for (let topicarr = 0; topicarr < this.showCourseData.course_info.topics.length; topicarr++) {
          this.addCourseForm.controls.topics['controls'][topicarr].controls._id.setValue(this.showCourseData.course_info.topics[topicarr]['_id']);
          this.addCourseForm.controls.topics['controls'][topicarr].controls.title.setValue(this.showCourseData.course_info.topics[topicarr]['title']);
          this.addCourseForm.controls.topics['controls'][topicarr].controls.description.setValue(this.showCourseData.course_info.topics[topicarr]['description']);

           
          const sessionLength =this.showCourseData.course_info.topics[topicarr].sessions && this.showCourseData.course_info.topics[topicarr].sessions.length || 0;
          for (let sessionarr = 0; sessionarr < sessionLength; sessionarr++) {
            
            let session = this.addCourseForm.controls.topics['controls'][topicarr].controls.sessions;

            let topicsession = this.showCourseData.course_info.topics[topicarr].sessions[sessionarr]
            session.controls[sessionarr].controls.title.setValue(topicsession['title']);
            session.controls[sessionarr].controls.description.setValue(topicsession['description']);
            session.controls[sessionarr].controls.url.setValue(topicsession['url']);
            session.controls[sessionarr].controls.file_id.setValue(topicsession['file_id']);
            session.controls[sessionarr].controls.type.setValue(topicsession['type']);
            session.controls[sessionarr].controls._id.setValue(topicsession['_id']);
            session.controls[sessionarr].controls.key.setValue(topicsession['key']);
            session.controls[sessionarr].controls.status.setValue(topicsession['status']);

            const reslength = this.showCourseData.course_info.topics[topicarr].sessions[sessionarr].resources && this.showCourseData.course_info.topics[topicarr].sessions[sessionarr].resources.length || 0;
            for (let resarr = 0; resarr < reslength; resarr++) {
              session.controls[sessionarr].controls.resources.controls[resarr].controls._id.setValue(topicsession['resources'][resarr]['_id']);
              session.controls[sessionarr].controls.resources.controls[resarr].controls.url.setValue(topicsession['resources'][resarr]['url']);
              session.controls[sessionarr].controls.resources.controls[resarr].controls.type.setValue(topicsession['resources'][resarr]['type']);
              session.controls[sessionarr].controls.resources.controls[resarr].controls.title.setValue(topicsession['resources'][resarr]['title']);

              this.addArray("resource", 'level', topicarr, sessionarr)

              if (resarr + 1 == reslength) {
                this.removeArray('resources', topicarr, sessionarr, resarr + 1);
              }
            }

            this.key = session.controls[sessionarr].controls.key.value;
            for (let assarr = 0; assarr < this.showCourseData.course_info.topics[topicarr].sessions[sessionarr].assessments.length; assarr++) {

              if (assarr != this.showCourseData.course_info.topics[topicarr].sessions[sessionarr].assessments.length - 1) {
                this.addArray('addSessionAssessment', 'level', topicarr, sessionarr);
              }
              if (session.controls[sessionarr].controls.key.value == 'session') {
                session.controls[sessionarr].controls.assessments.controls[assarr].controls.time.setValue(topicsession['assessments'][assarr]['time']);
              }
              session.controls[sessionarr].controls.assessments.controls[assarr].controls._id.setValue(topicsession['assessments'][assarr]['_id']);

            }

            this.addArray("session", 'level', topicarr, null)

            if (sessionarr + 1 == this.showCourseData.course_info.topics[topicarr].sessions.length) {
              this.removeArray('sessions', topicarr, sessionarr + 1, null);
            }

            if (this.showCourseData.course_info.topics[topicarr].sessions[sessionarr].resources.length == 0) {
              this.removeArray('resources', topicarr, sessionarr, 0);
            }
          }

          const resArrLength = this.showCourseData.course_info.topics[topicarr].resources && this.showCourseData.course_info.topics[topicarr].resources.length || 0;
          for (let resarr = 0; resarr < resArrLength; resarr++) {
            let resources = this.addCourseForm.controls.topics['controls'][topicarr].controls.resources;

            let topicresources = this.showCourseData.course_info.topics[topicarr].resources;
            resources.controls[resarr].controls._id.setValue(topicresources[resarr]['_id']);
            resources.controls[resarr].controls.url.setValue(topicresources[resarr]['url']);
            resources.controls[resarr].controls.type.setValue(topicresources[resarr]['type']);
            resources.controls[resarr].controls.title.setValue(topicresources[resarr]['title']);

            this.addArray("resource", 'level', topicarr, null)

            if (resarr + 1 == resArrLength) {
              this.removeArray('topic_resource', topicarr, resarr + 1, null);
            }
          }
          
          const courseworkarrLength = this.showCourseData.course_info.topics[topicarr].course_work && this.showCourseData.course_info.topics[topicarr].course_work.length || 0;
          for (let courseworkarr = 0; courseworkarr < courseworkarrLength; courseworkarr++) {
            let course_workinfo = this.addCourseForm.controls.topics['controls'][topicarr].controls.course_work;
            let topic_coursework = this.showCourseData.course_info.topics[topicarr].course_work;
            course_workinfo.controls[courseworkarr].controls.title.setValue(topic_coursework[courseworkarr]['title']);
            course_workinfo.controls[courseworkarr].controls._id.setValue(topic_coursework[courseworkarr]['_id']);
            course_workinfo.controls[courseworkarr].controls.group_type.setValue(topic_coursework[courseworkarr]['group_type']);
            course_workinfo.controls[courseworkarr].controls.group_type.setValue(topic_coursework[courseworkarr]['group_type']);
            course_workinfo.controls[courseworkarr].controls.peer_review.setValue(topic_coursework[courseworkarr]['peer_review']);
            course_workinfo.controls[courseworkarr].controls.self_review.setValue(topic_coursework[courseworkarr]['self_review']);
            if (topic_coursework[courseworkarr]['is_group']) {
              course_workinfo.controls[courseworkarr].controls.is_group.setValue(true);
            }
            course_workinfo.controls[courseworkarr].controls.date.setValue(topic_coursework[courseworkarr]['date']);
            course_workinfo.controls[courseworkarr].controls.time.setValue(topic_coursework[courseworkarr]['time']);
            this.addArray("coursework", 'level', topicarr, null)

            if (courseworkarr + 1 == courseworkarrLength) {
              this.removeArray('topic_course_work', topicarr, courseworkarr + 1, null);
            }
          }
           

          const assessmentArrLength = this.showCourseData.course_info.topics[topicarr].assessments && this.showCourseData.course_info.topics[topicarr].assessments.length || 0;
          for (let assessmentArr = 0; assessmentArr < assessmentArrLength; assessmentArr++) {
            let assessmentInfo = this.addCourseForm.controls.topics['controls'][topicarr].controls.assessments;
            let topic_assessment = this.showCourseData.course_info.topics[topicarr].assessments;
            if (topic_assessment[assessmentArr]['start_time']) {
              let date = (topic_assessment[assessmentArr]['start_time']).split(',');
              let maindate = date[0].split(' ')
              let month = this.month_name(maindate[1]);

              let time = date[1].split(' ');
              let time3 = time[1];
              if (time[2] == 'PM') {
                let time1 = time[1].split(':');
                let time2 = Number(time1[0]);
                let added_time = 0;
                if (time2 == 12) {
                  added_time = 0;
                } else {
                  added_time = 12
                }
                time3 = (time2 + added_time) + ':' + time1[1]
              }
              assessmentInfo.controls[assessmentArr].controls.date.setValue({ day: Number(maindate[0]), month: month + 1, year: Number(maindate[2]) });
              assessmentInfo.controls[assessmentArr].controls.time.setValue(time3);
              assessmentInfo.controls[assessmentArr].controls.calendered.setValue(true);
              assessmentInfo.controls[assessmentArr].controls.one_time_assessment.setValue(true);
            } else {
              assessmentInfo.controls[assessmentArr].controls.calendered.setValue(false);
              assessmentInfo.controls[assessmentArr].controls.one_time_assessment.setValue(false);
            }
            assessmentInfo.controls[assessmentArr].controls.calendered.disable();
            assessmentInfo.controls[assessmentArr].controls.title.setValue(topic_assessment[assessmentArr]['title']);
            assessmentInfo.controls[assessmentArr].controls._id.setValue(topic_assessment[assessmentArr]['_id']);
            assessmentInfo.controls[assessmentArr].controls.is_timed.setValue(topic_assessment[assessmentArr]['is_timed']);
            assessmentInfo.controls[assessmentArr].controls.one_time_assessment.setValue(topic_assessment[assessmentArr]['one_time_assessment']);
            this.addArray("assessment", 'level', topicarr, null)
            if (assessmentArr + 1 == this.showCourseData.course_info.topics[topicarr].assessments.length) {
              this.removeArray('assessments', topicarr, assessmentArr + 1, null);
            }
          }
            
          if (sessionLength == 0) {
            this.removeArray('sessions', topicarr, 0, null);
          }

          if (resArrLength == 0) {
            this.removeArray('topic_resource', topicarr, 0, null);
          }

          if (courseworkarrLength == 0) {
            this.removeArray('topic_course_work', topicarr, 0, null);
          }

          if (assessmentArrLength== 0) {
            this.removeArray('assessments', topicarr, 0, null);
          }

          this.addArray("topics", 'level', topicarr, null)

          if (topicarr + 1 == this.showCourseData.course_info.topics.length) {
            this.removeArray('topics', topicarr + 1, null, null);
          }
        }

        for (let coursework = 0; coursework < this.showCourseData.course_info.course_work.length; coursework++) {
          this.addCourseForm.controls.course_work['controls'][coursework].controls.date.setValue(this.showCourseData.course_info.course_work[coursework]['date']);
          this.addCourseForm.controls.course_work['controls'][coursework].controls._id.setValue(this.showCourseData.course_info.course_work[coursework]['_id']);
          this.addCourseForm.controls.course_work['controls'][coursework].controls.time.setValue(this.showCourseData.course_info.course_work[coursework]['time']);
          this.addCourseForm.controls.course_work['controls'][coursework].controls.group_type.setValue(this.showCourseData.course_info.course_work[coursework]['group_type']);
          this.addCourseForm.controls.course_work['controls'][coursework].controls.peer_review.setValue(this.showCourseData.course_info.course_work[coursework]['peer_review']);
          this.addCourseForm.controls.course_work['controls'][coursework].controls.self_review.setValue(this.showCourseData.course_info.course_work[coursework]['self_review']);
          if (this.showCourseData.course_info.course_work[coursework]['is_group']) {
            this.addCourseForm.controls.course_work['controls'][coursework].controls.is_group.setValue(true);
          }
          this.addArray("courseworks", '', null, '')

          if (coursework + 1 == this.showCourseData.course_info.course_work.length) {
            this.removeArray('course_work', coursework + 1, null, null);
          }
        }



        for (let courseres = 0; courseres < this.showCourseData.course_info.course_resources.length; courseres++) {

          this.addCourseForm.controls.course_resources['controls'][courseres].controls.title.setValue(this.showCourseData.course_info.course_resources[courseres]['title']);
          this.addCourseForm.controls.course_resources['controls'][courseres].controls.url.setValue(this.showCourseData.course_info.course_resources[courseres]['url']);
          this.addCourseForm.controls.course_resources['controls'][courseres].controls._id.setValue(this.showCourseData.course_info.course_resources[courseres]['_id']);
          this.addCourseForm.controls.course_resources['controls'][courseres].controls.type.setValue(this.showCourseData.course_info.course_resources[courseres]['type']);
          this.addArray("course_resources", 'level', null, null)

          if (courseres + 1 == this.showCourseData.course_info.course_resources.length) {
            this.removeArray('course_resources', courseres + 1, null, null);
          }
        }

        for (let assessmentarr = 0; assessmentarr < this.showCourseData.course_info.course_assessments.length; assessmentarr++) {
          this.addCourseForm.controls.course_assessments['controls'][assessmentarr].controls._id.setValue(this.showCourseData.course_info.course_assessments[assessmentarr]['_id']);

          if (this.showCourseData.course_info.course_assessments[assessmentarr]['start_time']) {
            let date = this.showCourseData.course_info.course_assessments[assessmentarr]['start_time']
            let maindate = date.split(' ')
            let month = this.month_name(maindate[1]);

            let year = maindate[2].substring(4, 0);
            let time = maindate[3];
            if (maindate[4] == 'PM') {
              let time1 = maindate[3].split(':');
              let time2 = Number(time1[0]);
              let added_time = 0;
              if (time2 == 12) {
                added_time = 0;
              } else {
                added_time = 12
              }
              time = (time2 + added_time) + ':' + time1[1]
            }

            this.addCourseForm.controls.course_assessments['controls'][assessmentarr].controls.date.setValue({ day: Number(maindate[0]), month: month + 1, year: Number(year) });
            this.addCourseForm.controls.course_assessments['controls'][assessmentarr].controls.time.setValue(time);
            this.addCourseForm.controls.course_assessments['controls'][assessmentarr].controls.calendered.setValue(true);
            this.addCourseForm.controls.course_assessments['controls'][assessmentarr].controls.one_time_assessment.setValue(true);
          } else {
            this.addCourseForm.controls.course_assessments['controls'][assessmentarr].controls.calendered.setValue(false);
            this.addCourseForm.controls.course_assessments['controls'][assessmentarr].controls.one_time_assessment.setValue(false);
          }

          this.addCourseForm.controls.course_assessments['controls'][assessmentarr].controls.calendered.disable();
          this.addCourseForm.controls.course_assessments['controls'][assessmentarr].controls.title.setValue(this.showCourseData.course_info.course_assessments[assessmentarr]['title']);
          this.addCourseForm.controls.course_assessments['controls'][assessmentarr].controls.is_timed.setValue(this.showCourseData.course_info.course_assessments[assessmentarr]['is_timed']);
          this.addCourseForm.controls.course_assessments['controls'][assessmentarr].controls.one_time_assessment.setValue(this.showCourseData.course_info.course_assessments[assessmentarr]['one_time_assessment']);
          this.addArray("course_assessments", 'level', null, null)
          if (assessmentarr + 1 == this.showCourseData.course_info.course_assessments.length) {
            this.removeArray('course_assessments', assessmentarr + 1, null, null);
          }

        }


        if (this.showCourseData.course_info.course_assessments.length == 0) {
          this.removeArray('course_assessments', 0, null, null);
        }

        if (this.showCourseData.course_info.course_resources.length == 0) {
          this.removeArray('course_resources', 0, null, null);
        }

        if (this.showCourseData.course_info.course_work.length == 0) {
          this.removeArray('course_work', 0, null, null);
        }

        if (this.showCourseData.course_info.topics.length == 0) {
          this.removeArray('topics', 0, null, null);
        }


      });
  }

  selectCourseWork(topicIndex, course_work_index, value) {
    let item1 = this.allCourseWork.find((i) => i.course_work_id == value.target.value);
    if (topicIndex != null) {
      let course_work = this.addCourseForm.controls.topics['controls'][topicIndex].controls.course_work;
      course_work.controls[course_work_index].controls.is_group.setValue(item1.is_group);
      course_work.controls[course_work_index].controls.title.setValue(item1.course_work_title);
    } else if (topicIndex == null) {
      this.addCourseForm.controls.course_work['controls'][course_work_index].controls.is_group.setValue(item1.is_group);
      this.addCourseForm.controls.course_work['controls'][course_work_index].controls.title.setValue(item1.course_work_title);
    }
  }
  checkState = false;
  indexValue(index) {
    this.topicLevel = index;
    this.checkState = true;
  }

  closeCourseWork(val) {
    this.getAllCourseWork();
    if (val == 'closeCourseWork')
      this.openModel.close();
  }

  closeAssessmentPopup() {
    this.getcourseAssessmentList();
    this.assessmentPopup.close();
  }

  getListOfCoursesnew() {
    this.courseId = this.route.snapshot.queryParamMap.get('course_id');
    this.courseName = this.route.snapshot.queryParamMap.get('course');

    this.courseService.categorynewAPI().subscribe(data => {
      this.listOfCoursesnew = data;
      this.listOfCoursesnewdata = data.filter(value => {
        if (value.active == true) {
          return this.catgeorycourseListdata = value;
        }

      });
      console.log("datanew ", this.listOfCoursesnewdata)
    });
  }

  getListOfCourses() {
    this.courseId = this.route.snapshot.queryParamMap.get('course_id');
    this.courseName = this.route.snapshot.queryParamMap.get('course');

    this.courseService.categoryAPI().subscribe(data => {
      this.listOfCourses = data;
    });
  }
  onlyNumber(control) {
    control.setValue(control.value.replace(/[^0-9]/g, ''));
  }
  addValidation() {
    let topic_assessment;
    let assessment_length;
    let topics = this.addCourseForm.value.topics;
    for (let i = 0; i < topics.length; i++) {
      for (let j = 0; j < topics[i].sessions.length; j++) {

        if (topics[i].sessions[j].url != '' && !this.notificationService.weblink_validation(topics[i].sessions[j].url)) {
          this.notificationService.showNotification('info', 'Please add correct url in session ' + (j + 1) + ' topic ' + (i + 1));
          return false;
        }
        for (let r = 0; r < topics[i].sessions[j].resources.length; r++) {
          for (let res = 0; res < topics[i].sessions[j].resources[res].length; res++) {
            if (topics[i].sessions[j].resources[res].url != '' && !this.notificationService.weblink_validation(topics[i].sessions[j].resources[res].url)) {
              this.notificationService.showNotification('info', 'Please add correct url in resource ' + (r + 1) + ' session ' + (i + 1) + ' topic ' + (i + 1));
              return false;
            }
          }

        }
        for (let k = 0; k < topics[i].sessions[j].assessments.length; k++) {
          if (topics[i].sessions[j].assessments[k]._id == '') {
            this.removeArray('sessionassessments', i, j, k);
          }
        }
        for (let v = 0; v < topics[i].sessions.length; v++) {
          if (topics[i].sessions[v].file_id == '' && topics[i].sessions[v].url == '' && topics[i].sessions[v].key == 'session') {
            this.notificationService.showNotification('info', 'please add the url or video in the topic session ' + (v + 1));
            return false;
          }
        }
      }

      if (this.showCourseData && this.showCourseData.course_info.topics[i]) {
        topic_assessment = this.showCourseData.course_info.topics[i].assessments;
        assessment_length = topic_assessment.length;
      }

      if (topics[i].course_work.length > 0) {
        let valueArr = topics[i].course_work.map(function (item) { return item._id });
        let isDuplicate = valueArr.some(function (item, idx) {
          return valueArr.indexOf(item) != idx
        });
        if (isDuplicate) {
          this.notificationService.showNotification('info', 'course work contains duplicate please use unique course work at topic level');
          return false;
        }


        for (let j = 0; j < topics[i].course_work.length; j++) {
          if (topics[i].course_work[j].is_group) {
            if (topics[i].course_work[j].group_type == '') {
              this.notificationService.showNotification('error', 'Please add group type if you are selecting group course work');
              return false;
            }
          }
          let course_work_length = 0;
          if (this.showCourseData && this.showCourseData.course_info.topics[i]) {
            course_work_length = this.showCourseData.course_info.topics[i].course_work.length;
          }
          for (let k = course_work_length; k < topics[i].course_work.length; k++) {
            let date = topics[i].course_work[k].date;
            let time = topics[i].course_work[k].time;
            if (date && time) {
              if (!this.notificationService.checkValidDate(date, time)) {
                this.notificationService.showNotification('info', 'Please set current time or grater than time in topic course work.')
                return false;
              }
            }
          }
        }
      }

      if (topic_assessment == undefined || null) {
        assessment_length = 0;
      }
      for (let j = assessment_length; j < topics[i].assessments.length; j++) {
        let date = topics[i].assessments[j].date;
        let time = topics[i].assessments[j].time;
        if (date && time) {
          if (!this.notificationService.checkValidDate(date, time)) {
            this.notificationService.showNotification('info', 'Please set current time/date or grater than time/date in topic assessment.')
            return false;
          }
        }
      }
      for (let j = 0; j < topics[i].resources.length; j++) {
        if (topics[i].resources[j].url != '' && !this.notificationService.weblink_validation(topics[i].resources[j].url)) {
          this.notificationService.showNotification('info', 'Please add correct url in resource ' + (j + 1) + ' topic ' + (i + 1));
          return false;
        }
      }
    }


    if (this.addCourseForm.value.course_work.length > 0) {

      let valueArr = this.addCourseForm.value.course_work.map(function (item) { return item._id });
      let isDuplicate = valueArr.some(function (item, idx) {
        return valueArr.indexOf(item) != idx
      });
      if (isDuplicate) {
        this.notificationService.showNotification('info', 'course work contains duplicate please use unique course work at course level');
        return false;
      }
      for (let ccw = 0; ccw < this.addCourseForm.value.course_work.length; ccw++) {

        if (this.addCourseForm.value.course_work[ccw].is_group) {
          if (this.addCourseForm.value.course_work[ccw].group_type == '') {
            this.notificationService.showNotification('error', 'Please add group type in course level course work if you are selecting group course work');
            return false;
          }
        }
        let course_work_length_c = 0;
        if (this.showCourseData) {
          course_work_length_c = this.showCourseData.course_info.course_work.length;
        }
        for (let j = course_work_length_c; j < this.addCourseForm.value.course_work.length; j++) {
          let date = this.addCourseForm.value.course_work[j].date;
          let time = this.addCourseForm.value.course_work[j].time;
          if (date && time) {
            if (!this.notificationService.checkValidDate(date, time)) {
              this.notificationService.showNotification('info', 'Please set current time or grater than time in  course work.')
              return false;
            }
          }
        }
      }
    }
    let courseAssessment;
    if (this.showCourseData) {
      courseAssessment = this.showCourseData.course_info.course_assessments;
      assessment_length = courseAssessment.length;
    }
    if (courseAssessment == undefined || null) {
      assessment_length = 0;
    }
    for (let j = assessment_length; j < this.addCourseForm.value.course_assessments.length; j++) {
      let date = this.addCourseForm.value.course_assessments[j].date;
      let time = this.addCourseForm.value.course_assessments[j].time;
      if (date && time) {
        if (!this.notificationService.checkValidDate(date, time)) {
          this.notificationService.showNotification('info', 'Please set current date/time or grater than date/time in assessment.')
          return false;
        }
      }
    }

    if (this.addCourseForm.invalid) {
      this.notificationService.showNotification('info', 'please fill correct form');
      return false;
    }

    for (let ls of this.addCourseForm.controls.live_sessions['controls']) {
      if (ls.controls.confFld.value == "cfldCustom") {
        if (ls.controls.url.value == '') {
          this.notificationService.showNotification('info', 'add your custom url please otherwise use zoom link.');
          return false;
        }
      } else if (ls.controls.confFld.value == "cfldZoom") {
        if (ls.controls.url.value != '') {
          this.notificationService.showNotification('info', 'please do not add any url we will handle it.');
          return false;
        }
      } else {
        this.notificationService.showNotification('info', 'please select one of the option in live session to set the live sesstion url custom/zoom');
        return false;
      }
    }

    let v = false;
    let name = '';
    if (this.addCourseForm.value['teach_assis'].lenght > 0) {
      this.addCourseForm.value['teach_assis'].filter(item1 => {
        if (this.getSubscribeStudentList.some(item2 => (item2._id === item1._id))) {
          v = true;
          name = item1.username;
        }
      });
    }


    if (v) {
      this.notificationService.showNotification('info', 'Please do not enter the subscribe student as a teaching assistant ' + name);
      return false;
    }
    return true;
  }

  formSubmit(value) {
    this.submitAddCourse = true;
    this.addCourseForm.controls.status.setValue(value);
    let about = tinymce.get("about_course").getContent();
    this.addCourseForm.controls.learning_objectives.setValue(about);

    if (!this.addValidation()) {
      return;
    }

    this.loader = true;
    
    if (this.courseId == null && this.courseName == null) {
      this.courseService.addCourse(this.addCourseForm.value).pipe(
        finalize(() => {
          this.loader = false;
          this.submitAddCourse = false;
        })).subscribe(uploadCourse => {
          this.courseName = this.addCourseForm.value.subject
          this.reset();
          this.notificationService.showNotification("success", uploadCourse.message);
          this.router.navigate(['/courses/single-course'], { queryParams: { course_id: uploadCourse.course_info._id, course: this.courseName } });
        },
          (error) => {
            this.loader = false;
            if (error.message) {
              this.notificationService.showNotification("error", error.message);
            } else {
              this.notificationService.showNotification("error", "Unable to save course please try again later");
            }
          })
    } else {
      this.courseService.patchCourse(this.addCourseForm.getRawValue(), this.courseId).pipe(
        finalize(() => {
          this.loader = false;
          this.submitAddCourse = false;
        })).subscribe(uploadCourse => {

          this.reset();
          this.notificationService.showNotification("success", uploadCourse.message);
          this.router.navigate(['/courses/single-course'], { queryParams: { course_id: this.courseId, course: this.courseName } });
        },
          (error) => {
            this.loader = false;
            if (error.message) {
              this.notificationService.showNotification("error", error.message);
            } else {
              this.notificationService.showNotification("error", "Unable to save course please try again later");
            }
          })
    }
  }

  reset(): void {
    this.addCourseForm.reset();
    this.fileName = '';
  }

  backToCourses() {
    this.router.navigate(['/courses']);
  }
  upload_index = 0;
  upload_type = '';
  detectFiles(event, type, parentIndex, childIndex, grandChildIndex, topic_session?: any) {
    this.upload_type = type;
    if (grandChildIndex) {
      this.upload_index = grandChildIndex;
    } else if (childIndex) {
      this.upload_index = childIndex;
    } else if (parentIndex) {
      this.upload_index = parentIndex;
    }

    if (type == 'bannerImage') {
      this.fileName = event.target.files[0].name;
    }
    if (topic_session != null && topic_session.controls.key.value == 'Recorded_video') {
      topic_session.controls.key.value = 'live_session';
      topic_session.controls.url.value = '';
    }
    this.upload_resource('course', event.target.files[0], type, parentIndex, childIndex, grandChildIndex);

  }

  minMaxLb2() {
    this.showLb2 = !this.showLb2;
    if (this.showLb2)
      this.btnStat2 = "mini";
    else
      this.btnStat2 = "maxi";
  }
  minMax() {
    this.show = !this.show;
    if (this.show)
      this.btnStat = "mini";
    else
      this.btnStat = "maxi";
  }


  // Full-width popup
  openXl(content) {
    this.openModel = this.modalService.open(content, { size: 'xl' });
  }

  openAsmnt(contentAsmnt) {
    this.assessmentPopup = this.modalService.open(contentAsmnt, { size: 'xl' });
  }

  openAssessmentforSession(contentAsmnt, parentIndex, childIndex, key) {
    this.key = key;
    this.remove_assessment(0)

    this.assessmentTopicIndex = parentIndex;
    this.assessmentSessionIndex = childIndex;
    this.assessmentPopup = this.modalService.open(contentAsmnt, { size: 'md' });
    let assessment = this.addCourseForm.controls.topics['controls'][this.assessmentTopicIndex].controls.sessions['controls'][this.assessmentSessionIndex].controls.assessments.value;
    if (assessment.length > 0) {
      for (let i = 0; i < assessment.length; ++i) {
        if (i < assessment.length - 1) {
          this.addMoreAssessement(key);
        }
        if (key == 'session') {
          this.assessmentForm.controls.assessmentArray['controls'][i].controls.time.setValue(assessment[i].time);
        }
        this.assessmentForm.controls.assessmentArray['controls'][i].controls._id.setValue(assessment[i]._id);

      }
    } else {
      this.addMoreAssessement(key);
      this.createAssessment(key);
    }

  }

  selectAssessment(topicIndex, assessment_index, value) {
    let assessment_view = this.getAllAssessment.find((assessment) => assessment._id == value.target.value);
    if (topicIndex != null) {
      let assessment = this.addCourseForm.controls.topics['controls'][topicIndex].controls.assessments;
      assessment.controls[assessment_index].controls.title.setValue(assessment_view.assessment_name);
    } else if (topicIndex == null) {
      this.addCourseForm.controls.course_assessments['controls'][assessment_index].controls.title.setValue(assessment_view.assessment_name);
    }
  }
  closeAssessmentPopUp(value) {
    if (value == 'close') {
      this.getcourseAssessmentList();
      this.assessmentPopup.close();
    }
  }

  getSubscribeStudentList = [];
  getSubscribeStudent() {
    let role = 'student'
    this.loader = true;
    this.courseService.getSubscribeStudent(this.courseId, role).pipe(
      finalize(() => {
        this.loader = false;
      })).subscribe((getStudentList) => {
        if (getStudentList.users_info) {
          getStudentList.users_info.find(user => {
            if (user._id != localStorage.getItem('user_id')) {
              this.getSubscribeStudentList.push(user);
              this.getSubscribeStudentList.sort();
              this.getSubscribeStudentList.sort((a, b) => {
                let user1 = a.username.toLowerCase(),
                  user2 = b.username.toLowerCase();
                if (user1 < user2) {
                  return -1;
                }
                if (user1 > user2) {
                  return 1;
                }
                return 0;
              });
            }
          })
        }

      });
  }
  zoom_accounts = [];
  zoom_user_id = '';
  getZoom_account(course_id?: string) {
    this.user_service.get_zoom_account(course_id).pipe(finalize(() => {
      this.loader = false;
    })).subscribe(account => {
      this.zoom_accounts = account.response.apps['zoom'];
      this.zoom_user_id = account.response.user_id;
    })
  }
  creat_zoom_form() {
    return this.formBuilder.group({
      api_key: [''],
      email: [''],
      secret_key: [''],
      user_id: [''],
    });
  }

  meeting_details() {
    return this.formBuilder.group({
      host_id: "",
      join_url: "",
      meeting_id: Number,
      start_url: "",
      uuid: ""
    });
  }
  openZoom(openZoomCredentials = '') {
    // this.assessmentPopup = this.modalService.open(openZoomCredentials, { size: 'xl' });
    this.router.navigate(['/user-profile'])
  }


  save_pass_wait_room(value, live_SessionArr, event) {
    if (value == 'passcode') {
      if (this.addCourseForm.controls.live_sessions['controls'][live_SessionArr].controls.zoom_account != undefined) {
        this.addCourseForm.controls.live_sessions['controls'][live_SessionArr].controls.password.setValue(event.target.checked);
      }
    } else if (value == 'waiting_room') {
      if (this.addCourseForm.controls.live_sessions['controls'][live_SessionArr].controls.zoom_account != undefined) {
        this.addCourseForm.controls.live_sessions['controls'][live_SessionArr].controls.waiting_room.setValue(event.target.checked);
      }
    }
  }

  is_passcode(index) {
    if (this.addCourseForm.controls.live_sessions['controls'][index].controls.zoom_account) {
      return this.addCourseForm.controls.live_sessions['controls'][index].controls.password.value
    } else if (this.addCourseForm.controls.live_sessions['controls'][index].controls.meeting_details) {
      return this.addCourseForm.controls.live_sessions['controls'][index].controls.password.value != ''
    }
  }

  waiting_room(index) {
    if (this.addCourseForm.controls.live_sessions['controls'][index].controls.zoom_account) {
      return this.addCourseForm.controls.live_sessions['controls'][index].controls.waiting_room.value
    } else if (this.addCourseForm.controls.live_sessions['controls'][index].controls.meeting_details) {
      return this.addCourseForm.controls.live_sessions['controls'][index].controls.waiting_room.value != ''
    }
  }

  zoom_is_added(live_SessionArr) {
    if (this.addCourseForm.controls.live_sessions['controls'][live_SessionArr].controls.zoom_account) {
      return this.addCourseForm.controls.live_sessions['controls'][live_SessionArr].controls.zoom_account.controls.api_key.value != ''
    } else if (this.addCourseForm.controls.live_sessions['controls'][live_SessionArr].controls.meeting_details) {
      return this.addCourseForm.controls.live_sessions['controls'][live_SessionArr].controls.meeting_details.controls.join_url.value != ''
    }
  }
  check_read_only(live_SessionArr) {
    if (this.addCourseForm.controls.live_sessions['controls'][live_SessionArr].controls.meeting_details != undefined) {
      return true
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }

  add_recording(topic_index, topic_session_index, topic_session) {
    ['session', 'Recorded_video'].includes(topic_session.controls.key.value || '') ? topic_session.controls.key.value = 'live_session' : topic_session.controls.key.value = 'session';
  }

  search_resource(query) {
    this.resource_service.serach_resource_elastic(query.target.value).subscribe(get_resource => {
      this.resource_list = get_resource;
    },
      (err) => {
        console.log(err);
      })
  }

  serInput
  results = [];
  setInd(id, paranet_index, childIndex?: string, grand_childindex?: string) {
    let index_id = '';
    if (id == 'course_resource') {
      index_id = id + paranet_index;
    } else if (id == 'topic_resource') {
      index_id = id + paranet_index + childIndex;
    } else if (id == 'session_resource') {
      index_id = id + paranet_index + childIndex + grand_childindex;
    } else if (id == 'session_url') {
      index_id = id + paranet_index + childIndex;
    }
    this.serInput = paranet_index;
    this.results = [];
    // filter search for food items
    const typeahead = fromEvent<KeyboardEvent>(document.querySelector(('#' + index_id)) as HTMLInputElement, 'input').pipe(
      map((e: KeyboardEvent) => { return (<HTMLInputElement>e.target).value }),
      filter(text => text.length > 2),
      debounceTime(500),
      distinctUntilChanged(),
    );

    typeahead.subscribe(keyword => {
      const data = keyword
      this.resource_service.serach_resource_elastic(data).subscribe(resource => {
        if (resource.length > 0) {
          this.results = resource;
        } else {
          this.results = [];
          this.results = [{
            description: "",
            id: "",
            tags: [],
            title: data,
            type: "text",
            url: data,
          }]
        }
        // if (data['responseCode'] == 1) {
        //   if(data['errorMessage'] == "no items"){
        //     this.results = [{
        //       id:'',
        //       misc:["small", " regular", " large"],
        //       name: keyword + ' (Add New)',
        //       serving_unit:'bowl,Piece,Plate,Packet,Glass,cup,Number,Tablespoon,Serving,Slice,Tetrapack,dish'
        //     }];
        //   }else{
        //     this.results = data['data'];
        //   }
        // } else {
        //   this.notify.showNotification("error", "Something went wrong");
        // }
      },
        error => {
          this.notificationService.showNotification("error", "Something went wrong");
        });
    });
  }


  searchData(option, parentIndex, type, childIndex?: string, grandChildIndex?: string) {
    if (type == 'course_resource') {
      if (option.id != '') {
        this.addCourseForm.controls.course_resources['controls'][parentIndex].controls.type.setValue('file');
        this.addCourseForm.controls.course_resources['controls'][parentIndex].controls._id.setValue(option.id);
        this.addCourseForm.controls.course_resources['controls'][parentIndex].controls.url.setValue('');
      } else {
        this.addCourseForm.controls.course_resources['controls'][parentIndex].controls.url.setValue(option.title);
        this.addCourseForm.controls.course_resources['controls'][parentIndex].controls.type.setValue('text');
        this.addCourseForm.controls.course_resources['controls'][parentIndex].controls._id.setValue('');
      }
    }

    else if (type == 'topic_resource') {
      if (option.id != '') {
        this.addCourseForm.controls.topics['controls'][parentIndex].controls.resources['controls'][childIndex].controls.type.setValue('file');
        this.addCourseForm.controls.topics['controls'][parentIndex].controls.resources['controls'][childIndex].controls._id.setValue(option.id);
        this.addCourseForm.controls.topics['controls'][parentIndex].controls.resources['controls'][childIndex].controls.url.setValue('');
      } else {
        this.addCourseForm.controls.topics['controls'][parentIndex].controls.resources['controls'][childIndex].controls.url.setValue(option.title);
        this.addCourseForm.controls.topics['controls'][parentIndex].controls.resources['controls'][childIndex].controls.type.setValue('text');
        this.addCourseForm.controls.topics['controls'][parentIndex].controls.resources['controls'][childIndex].controls._id.setValue('');

      }
    } else if (type == 'session_resource') {
      if (option.id != '') {
        this.addCourseForm.controls.topics['controls'][parentIndex].controls.sessions['controls'][childIndex].controls.resources['controls'][grandChildIndex].controls.type.setValue('file');
        this.addCourseForm.controls.topics['controls'][parentIndex].controls.sessions['controls'][childIndex].controls.resources['controls'][grandChildIndex].controls._id.setValue(option.id);
        this.addCourseForm.controls.topics['controls'][parentIndex].controls.sessions['controls'][childIndex].controls.resources['controls'][grandChildIndex].controls.url.setValue('');
      } else {
        this.addCourseForm.controls.topics['controls'][parentIndex].controls.sessions['controls'][childIndex].controls.resources['controls'][grandChildIndex].controls.url.setValue(option.title);
        this.addCourseForm.controls.topics['controls'][parentIndex].controls.sessions['controls'][childIndex].controls.resources['controls'][grandChildIndex].controls.type.setValue('text');
        this.addCourseForm.controls.topics['controls'][parentIndex].controls.sessions['controls'][childIndex].controls.resources['controls'][grandChildIndex].controls._id.setValue('');


      }
    } else if (type == 'session_url') {
      if (option.id != '') {
        this.addCourseForm.controls.topics['controls'][parentIndex].controls.sessions['controls'][childIndex].controls.type.setValue('file');
        this.addCourseForm.controls.topics['controls'][parentIndex].controls.sessions['controls'][childIndex].controls.file_id.setValue(option.id);
        this.addCourseForm.controls.topics['controls'][parentIndex].controls.sessions['controls'][childIndex].controls.url.setValue('');
      } else {
        this.addCourseForm.controls.topics['controls'][parentIndex].controls.sessions['controls'][childIndex].controls.type.setValue('text');
        this.addCourseForm.controls.topics['controls'][parentIndex].controls.sessions['controls'][childIndex].controls.url.setValue(option.title);
        this.addCourseForm.controls.topics['controls'][parentIndex].controls.sessions['controls'][childIndex].controls.file_id.setValue('');
      }

      if (this.addCourseForm.controls.topics['controls'][parentIndex].controls.sessions['controls'][childIndex].controls.key.value == 'Recorded_video') {
        this.addCourseForm.controls.topics['controls'][parentIndex].controls.sessions['controls'][childIndex].controls.key.value = 'live_session';
        this.addCourseForm.controls.topics['controls'][parentIndex].controls.sessions['controls'][childIndex].controls.url.value = '';
      }
    }
  }
  get_res_val(type, val, parentIndex, childIndex?: string, grandChildIndex?: string) {

    if (type == 'course_resource') {
      if (val != '' && this.addCourseForm.controls.course_resources['controls'][parentIndex].controls.url.value == '') {
        return val;
      } else {
        return this.addCourseForm.controls.course_resources['controls'][parentIndex].controls.url.value;
      }
    } else if (type == 'topic_resource') {
      if (val != '' && this.addCourseForm.controls.topics['controls'][parentIndex].controls.resources['controls'][childIndex].controls.url.value == '') {
        return val;
      } else {
        return this.addCourseForm.controls.topics['controls'][parentIndex].controls.resources['controls'][childIndex].controls.url.value;
      }
    } else if (type == 'session_resource') {
      if (val != '' && this.addCourseForm.controls.topics['controls'][parentIndex].controls.sessions['controls'][childIndex]['controls'].resources['controls'][grandChildIndex].controls.url.value == '') {
        return val;
      } else {
        return this.addCourseForm.controls.topics['controls'][parentIndex].controls.sessions['controls'][childIndex]['controls'].resources['controls'][grandChildIndex].controls.url.value;
      }
    } else if (type == 'session_url') {
      if (val != '' && this.addCourseForm.controls.topics['controls'][parentIndex].controls.sessions['controls'][childIndex].controls.url.value == '') {
        return val;
      } else {
        return this.addCourseForm.controls.topics['controls'][parentIndex].controls.sessions['controls'][childIndex].controls.url.value
      }
    }
  }
  insertBlankFlag: boolean = false;
  callMceEditor(edId) {
    tinymce.remove();
    let that = this;
    setTimeout(() => {
      tinymce.baseURL = 'assets';
      tinymce.init({
        selector: 'textarea#' + edId,
        height: 300,
        // base_url: '/tinymce',
        suffix: '.min',
        themes: "assets/themes/silver",
        // icons: "assets/icons/",
        // content_css: '//www.tiny.cloud/css/codepen.min.css',

        plugins: [
          'advlist autolink lists link image imagetools charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount'
        ],
        toolbar: that.toolbarWithoutBlankButton,
        image_title: true,
        automatic_uploads: true,
        image_description: false,
        file_picker_types: 'file image media',
        // external_plugins: { tiny_mce_wiris: "https://www.wiris.net/demo/plugins/tiny_mce/plugin.js" }, 
        // toolbar1: "tiny_mce_wiris_formulaEditor, tiny_mce_wiris_formulaEditorChemistry",
        file_picker_callback: function (cb, value, meta) {

          let input = document.createElement('input');
          input.setAttribute('type', 'file');
          input.setAttribute('accept', 'image/*');

          // Note: In modern browsers input[type="file"] is functional without 
          // even adding it to the DOM, but that might not be the case in some older
          // or quirky browsers like IE, so you might want to add it to the DOM
          // just in case, and visually hide it. And do not forget do remove it
          // once you do not need it anymore.

          input.onchange = function () {

            // var file = $event.path[0].files[0];
            let file = input.files[0];

            let reader: any = new FileReader();

            reader.onload = function () {

              // Note: Now we need to register the blob in TinyMCEs image blob
              // registry. In the next release this part hopefully won't be
              // necessary, as we are looking to handle it internally.
              let id = 'blobid' + (new Date()).getTime();
              let blobCache = tinymce.activeEditor.editorUpload.blobCache;
              let base64 = reader.result.split(',')[1];
              let blobInfo = blobCache.create(id, file, base64);

              blobCache.add(blobInfo);

              // call the callback and populate the Title field with the file name

              cb(reader.result, { title: file.name });
            };
            reader.readAsDataURL(file);
          };

          input.click();
        },
        setup: function (editor) {
          if (that.insertBlankFlag) {
            editor.ui.registry.addButton('customBlankButton', {
              text: 'Add Blank',
            });
          }
        }
      });

    }, 100)
  }

  // saveType(type, parentIndex, childIndex, grandChildIndex) {
  //   if (type == 'session_url') {
  //     this.addCourseForm.controls.topics['controls'][parentIndex].controls.sessions['controls'][childIndex].controls.type.setValue('text');
  //   } else if (type == 'session_resource') {
  //     this.addCourseForm.controls.topics['controls'][parentIndex].controls.sessions['controls'][childIndex].controls.resources['controls'][grandChildIndex].controls.type.setValue('text');
  //   }  else if (type == 'course_resource') {
  //     this.addCourseForm.controls.course_resources['controls'][parentIndex].controls.type.setValue('text');
  //   }
  // }

  //FOR BUILD FIX
  topicArr: any = '';
}