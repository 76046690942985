import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { finalize } from 'rxjs/operators';

import { UserService } from 'src/app/services/user.service';
import { UserStatus } from 'src/app/model/user.model';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  constructor(
    private userService: UserService,
    private formBuilder: FormBuilder,
    private notifciationService: NotificationService) { }
  loader = false;
  editProfile: UserStatus;
  public myProfile: FormGroup;
  onEdit = false;
  ngOnInit() {
    this.validateProfileForm();
    this.getProfileDetails();
  }

  getProfileDetails(){
    this.loader = true;
    this.userService.getProfile().pipe(
      finalize(() => {
        this.loader = false;
      })).subscribe((Response) => {
        this.editProfile = Response;
        this.setValueInEditProfile();
      })
      
  }

  validateProfileForm(){
    
      this.myProfile = this.formBuilder.group({
        username: ['', Validators.required],
        name: ['', Validators.required],
        last_name: ['', Validators.required],
        email: ['',[ Validators.email]],
        institute: [''],
        password: [''],
        mobile: ['', [Validators.min(10)]],
        profile_pic: ['', null],
      });
    
  }

  stripText(control) {
    control.setValue(control.value.replace(/[^0-9]/g, ''));
  }

  setValueInEditProfile(){
    this.myProfile.controls['username'].setValue(this.editProfile.Status[0].username);
    this.myProfile.controls['email'].setValue(this.editProfile.Status[0].Email);
    this.myProfile.controls['institute'].setValue(this.editProfile.Status[0].Institute);
    this.myProfile.controls['mobile'].setValue(this.editProfile.Status[0].mobile);
    this.myProfile.controls['name'].setValue(this.editProfile.Status[0].first_name);
    this.myProfile.controls['last_name'].setValue(this.editProfile.Status[0].last_name);
    this.myProfile.controls['password'].setValue(this.editProfile.Status[0].Password);

  }

  onClickEdit(){
    this.onEdit  = this.onEdit ? false : true;
  }

  saveProfile(){
    console.log(this.onEdit)
    console.log(this.myProfile.value);
    
    if(!this.onEdit){
      this.notifciationService.showNotification("info", "Please edit some data");
      return;
    }
    if(this.myProfile.controls['password'].value.length < 8 ){
      this.notifciationService.showNotification("info", "Please Enter your password first");
      return;
    }
    if(this.myProfile.invalid){
      this.notifciationService.showNotification("info", "Please fill all the mandate fields");
      return;
    }
    this.loader = true;
    this.userService.setProfile(this.myProfile.value).pipe(
      finalize(() => {
        this.loader = false;
        this.onEdit  = false;
      })).subscribe((Response) => {
        this.notifciationService.showNotification("success", "profile successfully saved");
      },
      (error) => {
        this.notifciationService.showNotification("error", "Please try again later there is something wrong");
    }
      )



  }
}
