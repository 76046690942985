<div class="pageBodyWrap new_UIstyle">
    <!-- banner section -->
    <div class="banner banner-gradient banner-C-assessment banner-assessment-mark">
        <div class="container">
            <div class="banner-content">
                <div class="banner-content-content">
                    <h2 class="white-text avenirBlack">{{assessment_name}}</h2>
                    <!-- <p class="white-text">Group Assignment: <strong class="ms-2 primary-text">Student - Led</strong></p> -->
                    <div class="tags-badges">
                        <span class="badge badge-light badge-primary" >{{draft}}</span>
                    </div>
                </div>
                <div class="banner-content-action btn-groups">
                   <button class="btn btn-primary" (click)="backTOCourseInfo()">Back to Assessment</button>
              </div>
            </div>
        </div>
    </div>

    <div class="section assessment-marking-list">
        <div class="container">
            <div [ngClass]="{submitting : isLoadingUser}">
                <div class="text-center" *ngIf="isLoadingUser">
                    <div class="spinner-border text-warning formLoader" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
             <div class="row">
                 <div class="col-md-6 mb-3" *ngFor="let mark_assessment of view_assessment_submission | paginate: { itemsPerPage: 20, currentPage: currentPage, totalItems:totalItemss }; let i = index;">
                    <div class="p-4 assesment-markBlock bg-grey relative">
                        <div class="asm-left">
                            <h6 class="avenirBlack">
                                <span class="badge  badge-primary avenirRoman m-0 nagtiveStatus"
                                *ngIf="mark_assessment.negative_marking_status === true">Negative Marking</span>
                                <span class="d-block">
                                    {{mark_assessment.name}}
                                </span>                              
                             <small class="d-block mt-2 grey-text-55 avenirRoman">{{mark_assessment.username}}</small></h6>
                             <p class="grey-text-55 avenirRoman mb-0">Assessment Submitted: </p>
                             <p class="primary-text avenirBlack"><strong>{{mark_assessment.submitted_time | date:'short'}}</strong></p>
                        </div>
                        <div class="asm-right text-right">
                            <button class="btn btn-primary btn-sm" (click)="markButton(mark_assessment)">View Submission</button>
                            <p  *ngIf="mark_assessment.assessment_marked == true && ((role != 'student') || (role=='student'  && isTA == 'true'))"
                            class="dark-green-text avenirBlack mb-0 mt-3 markedAssStatus" ><img src="../assets/images/apps-icon/green-tick.png" alt="green-tick.png" class="d-inline-flex me-2" /> Marked</p>
                        </div>
                    </div>
                 </div>

                 <div class="col-12 alert alert-info text-center" *ngIf="submission_length == 0">
                    <p>{{noSubmissions}}</p>
                 </div>
             </div>

             

             <div class="mt-2 mt-lg-5">
                 <pagination-controls class="text-center my-pagination" *ngIf="maxPage > 1 && !isLoadingUser" previousLabel="<" nextLabel=">" maxSize="5" 
                     (pageChange)="currentPageNo($event)"></pagination-controls>
             </div>
        </div>
    </div>
</div>


<!-- <div class="mt-2 mt-lg-5">
    <pagination-controls class="text-center my-pagination" *ngIf="maxPage > 1 && !isLoadingUser" previousLabel="<" nextLabel=">" maxSize="5" 
        (pageChange)="currentPageNo($event)"></pagination-controls>
</div> -->