import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';

import { environment } from 'src/environments/environment';
import { User, UserStatus } from '../model/user.model';
import { Router } from '@angular/router';
import { NotificationService } from './notification.service';
import { FormGroup } from '@angular/forms';
import { ValueConverter } from '@angular/compiler/src/render3/view/template';
import { catchError, endWith } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  apiEndPoint: any;
  loginFlag = false;

  constructor(
    private http: HttpClient,
    public router: Router,
    private ns: NotificationService
  ) {
    this.apiEndPoint = environment.APIEndpoint;
  }

  loginApi() {
  }

  signUp(signUpForm): Observable<any> {
    return this.http.post<any>(this.apiEndPoint + '/signup', signUpForm);
  }

  login(info): Observable<User> {
    return this.http.post<User>(this.apiEndPoint + '/login', info).pipe(
      catchError(this.handleError)
    );
  }
  loginWithGmail(info): Observable<User> {
    return this.http.post<User>(this.apiEndPoint + '/login/gmail', info).pipe(
      catchError(this.handleError)
    );
  }

  getProfile(): Observable<UserStatus> {
    return this.http.get<UserStatus>(this.apiEndPoint + '/my_profile');
  }

  getProfileData(): Observable<any> {
    return this.http.get<any>(this.apiEndPoint + '/profile');
  }

  userLevels(): Observable<any> {
    return this.http.get<any>(this.apiEndPoint + '/user_levels');
  }

  fetchEvents(fromDate, toDate): Observable<any> {
    let params = new HttpParams();
    // fromDate = '2020-02-05';
    // toDate = '2021-01-06';
    params = params.append('from_date', fromDate);
    params = params.append('to_date', toDate)

    return this.http.get<any>(this.apiEndPoint + '/events', { params });
  }

  getAvatars(): Observable<any> {
    return this.http.get<any>(this.apiEndPoint + '/get-avatars');
  }

  setProfile(body): Observable<UserStatus> {
    return this.http.post<UserStatus>(this.apiEndPoint + '/edit_user_profile', body);
  }

  userProfile(body): Observable<any> {
    return this.http.patch<any>(this.apiEndPoint + '/profile', body);
  }

  logOut(): Observable<any> {
    return this.http.post<any>(this.apiEndPoint + '/logout', '');
  }

  logOutUser() {
    this.logOut().subscribe(res => {

      this.loginFlag = false;
      localStorage.removeItem('currentUser');
      localStorage.removeItem('token');
      localStorage.removeItem('Email_ID');
      localStorage.removeItem('user_id');
      this.ns.showNotification('success', res.message);

    },
      (err) => {
        this.ns.showNotification('error', err.message);
      }
    )
  }

  getUserList(csv_required): Observable<any> {
    let params = new HttpParams();
    params = params.append('csv_required', csv_required)
    return this.http.get<any>(this.apiEndPoint + '/all_users_list', { params });
  }

  getUsersData(csv_required, page = '', search = '', postData = null): Observable<any> {
    let params = new HttpParams();
    params = params.append('csv_required', csv_required);
    if(page) {
      params = params.append('page', page);
    }
    if(search) {
      params = params.append('search', search);
    }

    return this.http.post<any>(this.apiEndPoint + '/all_users_list', postData, { params });
  }
  
  setActiveAndDeactive(other_user_id, activeDeactiveValue): Observable<any> {
    let params = new HttpParams();
    params = params.append('other_user_id', other_user_id);
    params = params.append('active', '' + activeDeactiveValue);
    return this.http.get<any>(this.apiEndPoint + '/activate_deactivate_user', { params: params });

  }

  editUserByAdmin(value) {
    return this.http.post<any>(this.apiEndPoint + '/super_admin_edit_user_profile', value);
  }

  getUserPermission() {
    return this.http.get<any>(this.apiEndPoint + '/permissions');
  }

  getUserPermissionDetails() {
    return this.http.get<any>(this.apiEndPoint + '/permissions/roles');
  }

  getRoles() {
    return this.http.get<any>(this.apiEndPoint + '/roles');
  }

  addNewRole(data) {
    return this.http.post<any>(this.apiEndPoint + '/add_role', data);
  }

  saveRolePermissions(slug, flag, role_id) {
    let body = {
      'permissions_slug': slug,
      'flag': flag,
      'role_id': role_id
    }
    return this.http.patch<any>(this.apiEndPoint + '/permissions/roles', body);
  }

  forgotPassword(form) {
    return this.http.post<any>(this.apiEndPoint + '/Forget_password', { email: form.value.forgotPasswordInput });
  }

  verify_OTP(form, encriptedOtp) {
    const reqHeader = new HttpHeaders({
      OTP: encriptedOtp,
    });
    return this.http.post<any>(this.apiEndPoint + '/verify_OTP', { email: form.value.forgotPasswordInput, otp: form.value.enterOtp }, { headers: reqHeader });
  }

  reset_password(form) {
    return this.http.post<any>(this.apiEndPoint + '/reset_password', { email: form.value.forgotPasswordInput, password: form.value.resetPassword, confirm_password: form.value.confirmPassword });
  }

  messageItem: any;
  setEmail(data: any) {
    this.messageItem = data;
  }
  getItem() {
    return this.messageItem;
  }
  change_password(body) {
    return this.http.post<any>(this.apiEndPoint + '/change_password', body);
  }

  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        return;
      }

      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }
  zoom_integration(app_name, value): Observable<any> {
    console.log(value)
    let permissions = {
      co_authors: value.co_authors,
      public_to_org: value.public_to_org,
      collaborators: value.collaborators
    }
    let content = {
      account_id: value.api_key,
      client_id: value.client_id,
      client_secret_key: value.secret_key,
      permissions: permissions
    }
    let body = {
      app: app_name,
      app_email: value.app_email,
      content: content
    }
    return this.http.post(this.apiEndPoint + '/user/settings/apps/zoom_s2s', body)
  }

  edit_zoom_credentials(app_name, value): Observable<any> {
    let permissions = {
      co_authors: value.co_authors,
      public_to_org: value.public_to_org,
      collaborators: value.collaborators
    }
    let content = {
      account_id: value.api_key,
      client_id: value.client_id,
      client_secret_key: value.secret_key,
      permissions: permissions
    }
    let body = {
      app: app_name,
      app_email: value.app_email,
      content: content
    }
    return this.http.patch(this.apiEndPoint + '/user/settings/apps/zoom_s2s', body)

  }

  get_zoom_account(course_id?: string): Observable<any> {
    let params = new HttpParams();
    if (course_id != ('' || undefined || null)) {
      params = params.append('course_id', course_id)
    }
    return this.http.get(this.apiEndPoint + '/user/settings/apps/zoom_s2s', { params })
  }

  delete_zoom_account(value): Observable<any> {
    let params = new HttpParams();
    params = params.append('app', 'zoom');
    params = params.append('app_email', value.app_email);
    params = params.append('api_key', value.api_key);
    params = params.append('secret_key', value.secret_key);

    return this.http.delete(this.apiEndPoint + '/user/settings/apps/zoom_s2s', { params });
  }

  handleError(error) {

    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(error);
  }

  getExportuserList(endNo): Observable<any> {
    let body = {
      "start": 0,
      "end": endNo
    }
    return this.http.post<any>(this.apiEndPoint + '/users_list/export', body, { observe: 'response', responseType: 'blob' as 'json' }).pipe(
      catchError(this.handleError)
    );
  }
}
