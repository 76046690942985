import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AssessmentShareService {

  constructor() { }
  assessmentData;
  
  setAssessment(data){
    
    this.assessmentData = data
  }

  getAssessment() {
    return this.assessmentData;
  }
}
