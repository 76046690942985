export interface content {
  weeks?: weeks[]
  des_arr?: weeks[]
  weekly_Resourse: [];
}
export interface weeks {
  weeks_Content: weeksContent[];
  Weekly_description: string;
}
export interface weeksContent {
  Description: string;
  Link: string;
  title: string;
  resourse: [];
}
export class EditCourseRecomendation {
  Courses_Recommendations: EditCourseModel;
}

export class EditCourseModel {
  resource: string;
  title: string;
  About_Course: string;
  Course_Category: string;
  subject: string;
  course_detail: string;
  Content: content[];
  Instructors: string[];
  course_resources: string[]
  live_sess_links: string[];
  Subscription_Status: boolean;
  Rating: number;
  course_category_id: string;
  course_class: string;
  course_work: any[];
  course_assessment_list: any[];
  edit: boolean;
  _id: number;

}

export interface ShowCourseView {
  course_info: ShowCourse;
}
export interface ShowCourse {
  announcements: [],
  authors: [],
  banner_img: string,
  course_assessments: assessments[],
  course_category_id: string,
  editors: UserDetails[],
  instructors: [],
  course_class: string,
  teach_assis: [],
  is_teaching_assisstant:boolean,
  learning_objectives: string,
  live_sessions: [],
  organisations: UserDetails[],
  rating: number,
  individual_rating: number;
  subject: string,
  subscription_status: boolean;
  course_category_name: string;
  topics: Topic[];
  course_work: course_work[];
  course_assessment: assessments[];
  course_resources: [];
  publish_right: boolean;
  is_public:boolean;
  status: string;
  group_chat: Group_chat;
  //FOR BUILD FIX
  active: any;
  sub_category: any;
  rating_count: any;
  reviews: any;
}

export interface Group_chat {
  avatar_url: string;
  course_id: string;
  created_at: string;
  created_by: string;
  current_user_admin: boolean
  description: string;
  messages: any[]
  name: string;
  room_id: string;
  sub_heading: string;
  total_unread_messages: number;
  type: string;
}
export interface Topic {
  assessments: assessments[]
  course_work: course_work[]
  description: string,
  resources: [];
  sessions: session[],
  title: string,
  _id: string,
}

export class session {
  assessments: []
  description: string;
  file_id: string;
  key: string;
  resources: [];
  title: string;
  type: string;
  url: string;
  _id: string;
}

export interface assessments {
  end_time: string;
  schedule_id: string;
  start_time: string;
  title: string;
  total_time: string;
  _id: string;
}

export interface course_work {
  course_work_description: string;
  date: [];
  group_size: string;
  group_type: string;
  is_group: boolean;
  schedule_id: string;
  submission_requirement: [];
  time: string;
  title: string;
  _id: string;

}

export interface UserDetails {
  _id: string,
  name: string
}


export class my_questions {
  Course_id: string;
  Timestamp: string;
  Week: string;
  _id: string;
  answers: string[];
  language: string;
  questions: string;
  reward: number;
  status: boolean;
  subject: string;
  translated_question: string;
  user_id: string;
  video_index: number;
  video_time: number;
  course_category_id: string;
  question_image_path: string;
  question_video_path: string;
  Course_Category: string;
}



export interface allQuestions {
  approved_answer: string
  category: string[]
  coins: number
  count: string
  courseId: string
  courseSessionId: string
  id: string
  question_type: string
  title: string
  transcriptions: Transcriptions
  videoTime: number
  vtt: string
}

export interface Transcriptions {
  items: any[]
  transcripts: any[]
}

export class recommendedQuestions {
  Week: string;
  _id: string;
  course_id: string;
  question: string;
  resume_video: number
  subject: string;
  video_index: string;
}

export class myContentVideos {
  Course_id: string;
  Status: string;
  Video_time: string;
  Week: string;
  Week_index: string;
  answers: answers[];
  question: string;
  question_status: string;
}


export interface userAnswer {
  Disliked: boolean
  Feedback: any[]
  Liked: boolean
  Rating: number
  _id: string
  answer_html: string
  approval_status: string
  course_category: string
  course_category_taxonomy: string
  created_at: string
  question: string
  question_id: string
  question_status: string
  speech_text: string
  user_id: string
  video: Video
}

export interface Video {
  thumbnail: string
  video_path: string
}


export class answers {
  Rating: number;
  Subject: string;
  Timestamp: string;
  _id: string;
  approval_status: string;
  file_path: string;
  question_id: string;
  user_id: string;
  Disliked: string[];
  Feedback: Feedback[];
  Liked: Liked[];
  username: string;
  answer_html: string;
}
export class Feedback {
  Feedback: string;
  Timestamp: string;
  user_id: string;
}
export class Liked {
  user_id: string;
}

export interface StudentAnswers {
  Question: string;
  answer: answers[];
}

export class allCategory {
  Course_Category: string;
  _id: number;

}

export class CourseInfo {
  courses_info: CourseDetails;
}
export class CourseDetails {
  banner_img: string;
  course_category_id: string;
  course_id: string;
  edit_right: boolean;
  learning_objectives: string;
  rating: number;
  status: string;
  subject: string;
  course_category: string;
}

export class EditCourseWork {
  course_category_id: string
  course_url: string;
  course_work_description: string
  course_work_file_id: string
  course_work_resources: CourseWorkResource[]
  course_work_title: string;
  group_size: string;
  is_group: boolean
  submission_requirement: SubmissionRequirement[];
  tag: [];
  publish: boolean;
  courses: any [];
}

export class CourseWorkResource {
  resource_title: string;
  resource_type: string;
  resource_id: string;
  resource_url: string;
}

export class SubmissionRequirement {
  submission_report: string;
  total_number_of_percentage: string;
  days_of_completion: string;
  rubric: string
  online_presentation: boolean;
  autograding_id: string;
  autograding: boolean;
  autograding_input: [];
  autograding_output: [];
}

export class Tag {
  tag: string;
  _id: string;
}

export interface ResourceImage {
  message: string;
  resource_info: ResourceImageResponse[];
}

export interface ResourceImageResponse {
  module_name: string;
  path: string;
  resource_id: string;
  url: string;
}