import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { NotificationService } from 'src/app/services/notification.service';
import { ManageFileUploadService } from 'src/app/shared/modules/shared/services/manage-file-upload.service';
import { ResourceService } from 'src/app/services/resources/resource.service';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { FormGroup, FormArray, FormBuilder, Validators, AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-files-upload',
  templateUrl: './files-upload.component.html',
  styleUrls: ['./files-upload.component.scss']
})
export class FilesUploadComponent implements OnInit {

  @Output() fileUploadedStatus = new EventEmitter<any>();
  @Input() fieldName: string = '';
  @Input() fieldNameErr: string = '';
  @Input() fieldSupports: string = '';
  @Input() fieldControlName: string = '';
  @Input() checkChanges: boolean = false;
  @Input() checkAddMore: boolean = false;
  @Input() checkRemove: boolean = false;
  @Input() checkEdit: boolean = false;
  @Input() formType: string = '';
  @Input() fieldIndex: number = 0;
  @Input() dynamicField: boolean = false;
  moduleName: string = 'passion_project';
  showFieldErr: boolean = false;

  multiFilesData: any[] = [];
  uploadedFilesData: any[] = [];

  //TO REMOVE RESOURCES
  allUploadedFiles: any[] = [];
  allDeletedFiles: any[] = [];
  saveLoader: boolean = false;

  //FOR EDIT PROJECT
  editProject: any = {};

  constructor(private notificationService: NotificationService, private manageFileUploadService: ManageFileUploadService, private resourceService: ResourceService, private fb: FormBuilder) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('checkChanges' in changes) {
      if (!changes['checkChanges'].isFirstChange()) {
        this.isFieldHasError();
      }
    }
    if ('checkAddMore' in changes) {
      if (this.fieldIndex > 0) {
        if (this.manageFileUploadService.submitProject == true) {
          this.isAddedFieldHasError();
        }
      }
    }
    /*if('checkRemove' in changes) {
      if(!changes['checkRemove'].isFirstChange()) {
        this.removeResources(this.manageFileUploadService.actionRef);
      }
    }*/
    if ('checkEdit' in changes) {
      if (!changes['checkEdit'].isFirstChange() || Object.keys(this.manageFileUploadService.editProject).length > 0) {
        this.setEditProject();
      }
    }
  }

  //FOR FILE UPLOAD |START
  isFieldHasError() {
    if (this.fieldControlName == 'project_image') {
      this.showFieldErr = false;
      if (this.manageFileUploadService.addForm.controls[this.fieldControlName].errors) {
        this.showFieldErr = true;
      }
    }
    else if (this.fieldControlName == 'project_path') {
      this.showFieldErr = false;
      if (this.manageFileUploadService.addForm.controls[this.fieldControlName].errors) {
        this.showFieldErr = true;
      }
    }
    else if (this.fieldControlName == 'project_resources') {
      this.showFieldErr = false;
      if (this.manageFileUploadService.addForm.controls[this.fieldControlName]['controls'].length == 0) {
        this.showFieldErr = true;
      }
    }
    else if (this.fieldControlName == 'project_details') {
      this.showFieldErr = false;
      if (this.manageFileUploadService.addForm.controls[this.fieldControlName]['controls'][this.fieldIndex].controls.path.errors) {
        this.showFieldErr = true;
      }
    }

    //SEND INFO TO PARENT COMPONENT
    this.fileUploadedStatus.emit({ errorStatus: this.showFieldErr, dynamicField: this.dynamicField, fieldIndex: this.fieldIndex });
  }

  isAddedFieldHasError() {
    if (this.fieldControlName == 'project_details') {
      this.showFieldErr = false;
      if (this.manageFileUploadService.addForm.controls[this.fieldControlName]['controls'][this.fieldIndex].controls.path.errors) {
        this.showFieldErr = true;
      }
    }
  }

  onFileDropped(event) {
    let files = event.files;
    this.uploadMultiResource(files, event);
  }

  onSelectFiles(event) {
    let files = event.target.files;
    //console.log(files);
    this.uploadMultiResource(files, event);
  }

  uploadMultiResource(files, event) {
    //CHECK EDIT FILE EXISTS
    if (this.formType == 'edit') {
      if (this.checkFileExists()) {
        this.notificationService.showNotification('info', 'Please remove previous uploaded image.');
        return;
      }
    }

    //CHECK SINGLE OR MULTI FILE ALLOWED
    if (this.fieldSupports == 'image') {
      if (files.length > 1) {
        if (event.target) {
          event.target.value = '';
        }
        this.notificationService.showNotification('info', 'Please provide only one image.');
        return;
      }
    }

    this.multiFilesData = [];
    for (let i = 0; i < files.length; i++) {
      this.uploadSingleResource(i, files[i], 'file');
    }
  }

  checkFileExists() {
    if (this.fieldControlName == 'project_image') {
      if (this.manageFileUploadService.addForm.controls[this.fieldControlName].value.length > 0) {
        return true;
      }
    }
    else if (this.fieldControlName == 'project_path') {
      if (this.manageFileUploadService.addForm.controls[this.fieldControlName].value.length > 0) {
        return true;
      }
    }
    else if (this.fieldControlName == 'project_resources') {
      //
    }
    else if (this.fieldControlName == 'project_details') {
      if (this.manageFileUploadService.addForm.controls[this.fieldControlName]['controls'][this.fieldIndex].get('path').value.length > 0) {
        return true;
      }
    }

    return false;
  }

  uploadSingleResource(idx, file, type) {

    if (file) {
      const fileName = file.name;
      let progressBar = 0;
      let progressTime = 0;

      let startDate = new Date;
      this.notificationService.upload_resource(this.moduleName, file).subscribe(
        (response: HttpEvent<any>) => {
          switch (response.type) {
            case HttpEventType.Sent:
              break;
            case HttpEventType.ResponseHeader:
              break;
            case HttpEventType.UploadProgress:
              progressBar = Math.round(response.loaded / response.total * 100);

              //TIME CALCULATION
              let currDate = new Date;
              let timeElapsed: number = currDate.getTime() - startDate.getTime();
              let uploadSpeed = response.loaded / (timeElapsed / 1000);
              let seconds = (file.size - response.loaded) / uploadSpeed;
              progressTime = Math.round(seconds);

              this.multiFilesData[idx] = { name: fileName, progressBar: progressBar, progressTime: progressTime, uploadStatus: false };
              break;

            case HttpEventType.Response:
              this.notificationService.showNotification('success', 'file uploaded successfully');
              setTimeout(() => {
                progressBar = 0;
                progressTime = 0;
                this.multiFilesData[idx] = { name: fileName, progressBar: progressBar, progressTime: progressTime, uploadStatus: true };
                this.updateUploadedResources(this.multiFilesData[idx], response);
              }, 1000);
          }
        },
        (err) => {
          progressBar = 0;
          progressTime = 0;
          this.multiFilesData[idx] = { name: fileName, progressBar: progressBar, progressTime: progressTime, uploadStatus: false };
          this.notificationService.showNotification('info', 'Please add again not able to save your file');
        });
    }
  }

  updateUploadedResources(multiFile, response) {
    const resourceData = response.body.resource_info[0];
    const resourcePath = resourceData.path;
    const resourceId = resourceData.resource_id;
    const resourceUrl = resourceData.url;
    let resourceInfo = {
      resource_title: multiFile.name,
      resource_type: 'file',
      resource_id: resourceId,
      resource_url: resourceUrl,
      path: resourcePath,
      resource_deleted: false
    };
    this.uploadedFilesData.unshift(resourceInfo);
    //console.log(this.uploadedFilesData);

    //TO REMOVE RESOURCES
    this.allUploadedFiles.push(resourcePath);
    this.manageFileUploadService.allUploadedFiles.push(resourcePath);

    //SET DATA INTO FORM FIELD
    this.manageUploadedFiles();
  }

  deleteUploadedResource(uploadedFileIndex) {
    if (confirm('Are you sure want to remove this ' + this.fieldName + ' ? ')) {
      this.uploadedFilesData[uploadedFileIndex].resource_deleted = true;

      //SET DATA INTO FORM FIELD
      this.manageUploadedFiles();
    }
    return;
  }

  manageUploadedFiles() {
    if (this.fieldControlName == 'project_image') {
      this.setImageFiles();
    }
    else if (this.fieldControlName == 'project_path') {
      this.setImageFiles();
    }
    else if (this.fieldControlName == 'project_resources') {
      this.setProjectResourcesFiles();
    }
    else if (this.fieldControlName == 'project_details') {
      this.setDetailImageFiles();
    }
  }
  //FOR FILE UPLOAD |END

  setImageFiles() {

    this.manageFileUploadService.addForm.controls[this.fieldControlName].setValue('');
    this.allDeletedFiles = [];
    if (this.uploadedFilesData.length > 0) {
      this.uploadedFilesData.forEach((e, index) => {
        if (!e.resource_deleted) {
          this.manageFileUploadService.addForm.controls[this.fieldControlName].setValue(e.path);
        }
        else {
          this.allDeletedFiles.push(e.path);
          this.manageFileUploadService.allDeletedFiles.push(e.path);
        }
      });
    }

    this.checkErrors();
  }

  setProjectResourcesFiles() {

    const fieldFormArr = this.manageFileUploadService.addForm.controls[this.fieldControlName] as FormArray;
    fieldFormArr.clear();

    this.allDeletedFiles = [];
    if (this.uploadedFilesData.length > 0) {
      this.uploadedFilesData.forEach((e, index) => {
        if (!e.resource_deleted) {
          this.addMoreResource();
          let resourceIndex = this.manageFileUploadService.addForm.controls[this.fieldControlName]['controls'].length - 1;
          this.manageFileUploadService.addForm.controls[this.fieldControlName]['controls'][resourceIndex].get('title').setValue(e.resource_title);
          this.manageFileUploadService.addForm.controls[this.fieldControlName]['controls'][resourceIndex].get('_id').setValue(e.resource_id);
          this.manageFileUploadService.addForm.controls[this.fieldControlName]['controls'][resourceIndex].get('url').setValue(e.resource_url);
          this.manageFileUploadService.addForm.controls[this.fieldControlName]['controls'][resourceIndex].get('type').setValue(e.resource_type);
        }
        else {
          this.allDeletedFiles.push(e.path);
          this.manageFileUploadService.allDeletedFiles.push(e.path);
        }
      });
    }

    this.checkErrors();
  }

  addMoreResource() {
    const fieldFormArr = this.manageFileUploadService.addForm.controls[this.fieldControlName] as FormArray;
    fieldFormArr.push(this.createResource());
  }

  createResource() {
    return this.fb.group({
      title: ['', Validators.required],
      _id: [''],
      url: [''],
      type: ['', Validators.required]
    });
  }

  setDetailImageFiles() {

    this.manageFileUploadService.addForm.controls[this.fieldControlName]['controls'][this.fieldIndex].get('path').setValue('');
    this.allDeletedFiles = [];
    if (this.uploadedFilesData.length > 0) {
      this.uploadedFilesData.forEach((e, index) => {
        if (!e.resource_deleted) {
          this.manageFileUploadService.addForm.controls[this.fieldControlName]['controls'][this.fieldIndex].get('path').setValue(e.path);
        }
        else {
          this.allDeletedFiles.push(e.path);
          this.manageFileUploadService.allDeletedFiles.push(e.path);
        }
      });
    }

    this.checkErrors();
  }

  removeResources(actionRef = '') {
    let removeFileList: any[] = [];
    if (actionRef == 'add' || actionRef == 'edit') {
      removeFileList = this.allDeletedFiles;
    }
    else {
      removeFileList = this.allUploadedFiles;
    }

    if (removeFileList.length > 0) {
      this.saveLoader = true;
      this.resourceService.removeResource('', removeFileList).subscribe(res => {
        this.saveLoader = false;
      }, err => {
        this.saveLoader = false;
        console.log(err);
      });
    }
  }

  checkErrors() {
    if (this.manageFileUploadService.submitProject == true) {
      this.isFieldHasError();
    }
  }

  //FOR EDIT PROJECT
  setEditProject() {
    this.editProject = this.manageFileUploadService.editProject;

    if (this.fieldControlName == 'project_image') {
      this.manageFileUploadService.addForm.controls[this.fieldControlName].setValue('');
      if (this.editProject[this.fieldControlName].length > 0) {
        this.uploadedFilesData = [];
        this.uploadedFilesData[0] = {};
        this.uploadedFilesData[0].resource_title = '';
        this.uploadedFilesData[0].resource_type = 'file';
        this.uploadedFilesData[0].resource_id = '';
        this.uploadedFilesData[0].resource_url = this.editProject.project_image_s3;
        this.uploadedFilesData[0].path = this.editProject.project_image;
        this.uploadedFilesData[0].resource_deleted = false;
      }
    }

    if (this.fieldControlName == 'project_path') {
      this.manageFileUploadService.addForm.controls[this.fieldControlName].setValue('');
      if (this.editProject.project_overview.path.length > 0) {
        this.uploadedFilesData = [];
        this.uploadedFilesData[0] = {};
        this.uploadedFilesData[0].resource_title = '';
        this.uploadedFilesData[0].resource_type = 'file';
        this.uploadedFilesData[0].resource_id = '';
        this.uploadedFilesData[0].resource_url = this.editProject.project_overview.path_s3;
        this.uploadedFilesData[0].path = this.editProject.project_overview.path;
        this.uploadedFilesData[0].resource_deleted = false;
      }
    }

    if (this.fieldControlName == 'project_resources') {
      const fieldFormArr = this.manageFileUploadService.addForm.controls[this.fieldControlName] as FormArray;
      fieldFormArr.clear();

      if (this.editProject[this.fieldControlName]) {
        this.uploadedFilesData = [];
        this.editProject[this.fieldControlName].forEach((item, index) => {
          this.uploadedFilesData[index] = {};
          this.uploadedFilesData[index].resource_title = item.title;
          this.uploadedFilesData[index].resource_type = item.resource_type;
          this.uploadedFilesData[index].resource_id = item._id;
          this.uploadedFilesData[index].resource_url = item.url;
          this.uploadedFilesData[index].path = item.path;
          this.uploadedFilesData[index].resource_deleted = false;
        });
      }
    }

    if (this.fieldControlName == 'project_details') {
      if (this.editProject[this.fieldControlName][this.fieldIndex]) {
        this.manageFileUploadService.addForm.controls[this.fieldControlName]['controls'][this.fieldIndex].controls.path.setValue('');
        if (this.editProject[this.fieldControlName][this.fieldIndex].path.length > 0) {
          this.uploadedFilesData = [];
          this.uploadedFilesData[0] = {};
          this.uploadedFilesData[0].resource_title = '';
          this.uploadedFilesData[0].resource_type = 'file';
          this.uploadedFilesData[0].resource_id = '';
          this.uploadedFilesData[0].resource_url = this.editProject[this.fieldControlName][this.fieldIndex].path_s3;
          this.uploadedFilesData[0].path = this.editProject[this.fieldControlName][this.fieldIndex].path;
          this.uploadedFilesData[0].resource_deleted = false;
        }
      }
    }

    //console.log(this.uploadedFilesData);

    //SET DATA INTO FORM FIELD
    this.manageUploadedFiles();
  }

}
