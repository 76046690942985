import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AddReportRequest, ReportResponse, EditReportRequest } from 'src/app/model/passion-project-report';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  private apiEndPoint = environment.APIEndpoint;

  constructor(
    private http: HttpClient,
  ) { }

  getReportByTeamId(projectId: string, teamId: string): Observable<ReportResponse> {
    const path = '/passion_projects/report?project_id=' + projectId + '&team_id=' + teamId;
    return this.http.get<ReportResponse>(this.apiEndPoint + path);
  }

  addReport(data: AddReportRequest) {
    const path = this.apiEndPoint + '/passion_projects/report';
    return this.http.post<any>(path, data);
  }

  editReport(data: EditReportRequest) {
    const path = this.apiEndPoint + '/passion_projects/report';
    return this.http.patch<any>(path, data);
  }
}
