import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'youTubeVideoId'
  })
  export class youtubeVideoId implements PipeTransform {
      constructor(){}
      transform(link: string): string {
        let videoId = this.getVideoId(link);
        return videoId;
      }

      getVideoId(link) {
        var videoId = '';
        if (videoId = link.match(/(\?|&)v=([^&#]+)/)) {
          link = videoId
          link = link.pop();
    
        } else if (videoId = link.match(/(\.be\/)+([^\/]+)/)) {
          link = videoId;
          link = link.pop();
    
        } else if (videoId = link.match(/(\embed\/)+([^\/]+)/)) {
    
          link = videoId;
          link = link.pop().replace('?rel=0', '');;
    
        }
        return link;
      }
  }