import { Component, OnInit } from '@angular/core';
import { CourseService } from 'src/app/services/course.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../services/notification.service';
import { PermissionService } from '../../services/permission.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { error } from '@angular/compiler/src/util';

@Component({
  selector: 'app-course-work-superadmin',
  templateUrl: './course-work-superadmin.component.html',
  styleUrls: ['./course-work-superadmin.component.scss']
})
export class CourseWorkSuperadminComponent implements OnInit {

  loader: boolean = false;
  submissionLoader: boolean = false;
  publishMarksLoader: boolean = false;
  courseId: string = '';
  courseWorkId: string = '';
  topicId: string = '';
  courseworks: any = {};
  courseData: any = {};
  courseTitle: string = '';
  submissionsData: any[] = [];
  isTopicCoursework: boolean = false;
  role: string = '';
  isTeachingAssistant: boolean = false;
  isAllowedPublishMarks: boolean = false;
  publishMarksModalRef: any = null;
  createGroupModal: NgbModalRef;
  groupProcessing:boolean = false;
  studentsList:any = [];
  students_loader:boolean = false;
  selectedTechindex:number = 0;
  teching_Assistant_id:string = null;
  user_id:string = null;

  constructor(private courseService: CourseService, 
    private notificationService: NotificationService, 
    private route: ActivatedRoute, 
    private router: Router, 
    private permissionService: PermissionService, 
    private modalService: NgbModal) {
  }

  ngOnInit() {

    this.user_id = localStorage.getItem('user_id');
    const role = localStorage.getItem('role');
    this.role = role;

    this.route.queryParams.subscribe(params => {
      this.courseWorkId = params.courseWork;
      this.courseId = params.course_id;
      this.topicId = params.topic_id;
    });
    //console.log(this.courseWorkId);
    if (this.topicId) {
      this.isTopicCoursework = true;
    }
    this.getCourseworkData();
  }

  getCourseworkData() {
    this.getCourseWorkDetails();
    if (this.isTopicCoursework) {
      this.getTopicSession();
    }
  }

  onGoBack(ref = 'view-submissions', isGroupCoursework) {
    if (ref == 'view-submissions') {
      if (isGroupCoursework == true) {
        this.router.navigate(['/courses/course-work-detail'], { queryParams: { courseWork: this.courseWorkId, course_id: this.courseId, topic_id: this.topicId } });
      }
      else {
        this.router.navigate(['/courses/single-course'], { queryParams: { courseWork: this.courseWorkId, course_id: this.courseId } });
      }
    }
  }
  
  getSubscribeStudent() {
    let role = 'student'
    this.students_loader = true;
    this.courseService.getAssisstantSubscribe(this.courseId, role).subscribe((getStudentList) => {
        const hasUsers_info = getStudentList.hasOwnProperty('users_info');        
        if (hasUsers_info) {          
          this.studentsList = getStudentList.users_info;
          this.studentsList.forEach((subscriber, i)=>{
            subscriber.isChecked = false;
          })
          this.students_loader = false;
        } else{
          this.studentsList = [];
        }
      },
        (err) => {
          this.loader = false;
          this.notificationService.showNotification('info', err.message);
        });
  }

  getAssignList: any[];
  ta_loader:boolean = false;
  getassistant_list() {
    this.ta_loader = true;
    this.getAssignList = [];
    this.courseService.getInstructors(this.courseId).subscribe((apiResponse:any) => {
      let ApiResponseData = apiResponse.course_info;
        const hasResponse = ApiResponseData.hasOwnProperty('teach_assis'); 

        if(hasResponse){          
          let data_Ta = ApiResponseData.teach_assis;

          data_Ta.forEach((item:any)=>{
            if(item._id !== this.user_id)
              this.getAssignList.push(item);
          })
          this.selectedTech(this.getAssignList[0], 0);
        } else{
          this.getAssignList = [];
        }
          this.ta_loader = false;
      },
        (err) => {
          this.ta_loader = false;
          this.notificationService.showNotification('info', err.message);
        });
  }


  
  studentDataArray:any = [];
  isChecked:boolean = false;
  assignStudentBtn(subscriber, e:any, assIndex: number) {
  this.isChecked = e.target.checked;
  this.studentsList[assIndex].isAdded = this.isChecked;

    if (this.isChecked) {
      this.studentDataArray.push(subscriber._id);      
    } else {
      const index = this.studentDataArray.findIndex(id => id === subscriber._id);
      if (index !== -1) {
        this.studentDataArray.splice(index, 1);
      }
    }
  }

  selectedTech(teach_assis, index){   
    if(!index){
      this.selectedTechindex = 0;
    } else {
      this.selectedTechindex = index;
    }
    
    this.teching_Assistant_id = teach_assis._id;
  }

  submtDataLoader:boolean = false;
  onSubmitRequest(){
    this.submtDataLoader = true;
    if(this.getAssignList.length === 0 || this.studentsList.length === 0) {
      this.notificationService.showNotification('info', 'No item found in the subscriber or TA list.');
      this.submtDataLoader = false;
      return false;
    }

    if((this.teching_Assistant_id == undefined || 
      this.teching_Assistant_id == null) || 
      this.studentDataArray.length === 0) {
      this.notificationService.showNotification('info', 'Please select at least one subscriber and TA');
      this.submtDataLoader = false;
      return false;
    }

      let postDate = {
            course_id: this.courseId,
            optional_assignee_data: {
                teaching_assistant_id: this.teching_Assistant_id,
                optional_assignee: this.studentDataArray
            }
          }

   this.courseService.assignstudentData(postDate).subscribe((apiResponse:any)=>{
      this.submtDataLoader = false;
      this.notificationService.showNotification('success', apiResponse.message);
      this.closeGroupCreateModel();
   }, (error)=>{
       this.submtDataLoader = false;
       this.notificationService.showNotification('error', error.message);
  })

  }


  getCourseWorkDetails() {
    this.loader = true;
    let current_time = new Date();
    this.courseService.getSingleCoursework(this.courseId, this.courseWorkId).subscribe(res => {
      //console.log(res);
      if (res.course_info) {
        this.courseData = res.course_info;
        this.courseTitle = this.courseData.subject;
      }

      //CHECK PERMISSION
      if (!this.permissionService.isAllowedSharedPageView(this.courseData.publish_right, this.role, this.courseData.teach_assis)) {
        this.router.navigate(['/']);
        return false;
      }

      //CHECK ALLOWED PUBLISH MARKS
      if((this.courseData.publish_right == true) || (this.role == 'super_admin')) {
        this.isAllowedPublishMarks = true;
      }
      else {
        this.isAllowedPublishMarks = false;
      }

      //CHECK TEACHING ASSISTANT
      this.checkTeachingAssistant();

      if (this.isTopicCoursework == false) {
        this.prepareCourseworkInfo(res.course_work_info, 'course');
      }
      this.loader = false;
    },
      (err) => {
        this.loader = false;
        console.log(err);
      });
  }

  getTopicSession() {
    this.loader = true;
    this.courseService.get_topicSession(this.courseId, this.topicId).subscribe(res => {
      this.loader = false;
      this.prepareCourseworkInfo(res.course_work, 'topic');
    },
      (err) => {
        this.loader = false;
        console.log(err);
      });
  }

  prepareCourseworkInfo(course_works, ref = '') {
    let current_time = new Date();
    let list_course_work = course_works;
    let course_work_item: any = null;
    course_work_item = list_course_work.find(item => {
      return item._id == this.courseWorkId;
    });
    if (course_work_item) {
      if (!course_work_item.date) {
        course_work_item['date'] = { day: current_time.getDate(), month: current_time.getMonth() + 1, year: current_time.getFullYear() };
        course_work_item['startDate'] = current_time;
      }
      else {
        course_work_item['startDate'] = new Date(course_work_item.date.year, course_work_item.date.month - 1, course_work_item.date.day);
      }
      this.courseworks = course_work_item;
      this.getCourseWorkSubmissions();
    }
  }

  getCourseWorkSubmissions() {
    this.submissionLoader = true;
    this.courseService.viewSubmission('', this.courseworks._id, this.courseworks.schedule_id, this.courseId, this.courseworks.peer_review, this.courseworks.self_review, 'grade_view').subscribe(res => {
      this.submissionLoader = false;
      this.submissionsData = res.response;
    },
      (err) => {
        this.submissionLoader = false;
        console.log(err);
      });
  }

  checkTeachingAssistant() {
    if(this.permissionService.isAssistant(this.courseData.teach_assis)) {
      this.isTeachingAssistant = true;
    }
    else {
      this.isTeachingAssistant = false;
    }
  }

  openResource(url) {
    window.open(url, "blank")
  }

  //MARK COURSEWORK
  onMarkCoursework(userId = '', teamName = '') {
    if (this.courseworks.is_group == true) {
      this.router.navigate(['/courses/course-work-marking'], { queryParams: { courseWork: this.courseworks._id, course_id: this.courseId, topic_id: this.topicId, teamName: teamName } });
    }
    else {
      this.router.navigate(['/courses/course-work-marking'], { queryParams: { courseWork: this.courseworks._id, course_id: this.courseId, topic_id: this.topicId, userId: userId } });
    }
  }

  //PUBLISH MARKS
  openPublishMarksModel(publishMarksModal) {
    this.publishMarksModalRef = this.modalService.open(publishMarksModal);
  }

  closePublishMarksModal() {
    this.publishMarksModalRef.close();
  }

  onPublishMarks() {
    this.closePublishMarksModal();
    this.publishMarksLoader = true;
    this.courseService.publish_submission(this.courseId, this.courseworks.schedule_id, this.courseworks._id).subscribe(submission => {
      this.publishMarksLoader = false;
      this.getCourseworkData();
      this.notificationService.showNotification('success', submission.message);
    },
      (error) => {
        this.publishMarksLoader = false;
        this.notificationService.showNotification('error', error.message);
      });
  }

  
  openGroupCreateModel(content, formRef, editTeam: any = {}) {
    this.createGroupModal = this.modalService.open(content, { windowClass: 'createGroupModel', centered: true, size: "xl", backdrop: 'static', keyboard: false });
    this.getSubscribeStudent();
    this.getassistant_list();
  }

  closeGroupCreateModel() {
    this.studentsList = [];
    this.studentDataArray = [];
    this.getAssignList = [];
    this.createGroupModal.close();
  }

  onStartSearch(data) {
    // this.searchText = data.searchText;
    // if(this.searchText.length > 0) {
    //   this.isSearchEnabled = true;
    //   this.getSubscribeStudent(this.groupActionName);
    // }
    // else {
    //   this.isSearchEnabled = false;
    //   this.searchText = '';
    //   this.subscribeStudentList = [];
    // }
  }
  addMemberToGroup(subscriberStudent) {
    // const index = this.addedMembers.findIndex(addedMember => addedMember._id === subscriberStudent._id);
    // if (index == -1) {
    //   this.addedMembers.push(subscriberStudent);
    //   subscriberStudent.isAdded = true;
    // }
  }

  removeMemberFromGroup(member) {
    // const index = this.addedMembers.findIndex(addedMember => addedMember._id === member._id);
    // if (index > -1) {
    //   this.addedMembers.splice(index, 1);
    // }
    // const indexSubscriber = this.subscribeStudentList.find(subscriberStudent => {
    //   return subscriberStudent._id === member._id
    // });
    // if (indexSubscriber) {
    //   indexSubscriber.isAdded = false;
    // }
  }

  discardChanges(){}
  saveTeams(){}
}
