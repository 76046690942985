<div class="pageBody subPage">
  <div class="row">
    <div class="col-12 pb-3 col-xl-4 pb-xl-0">
      <div class="profSetBox d-flex flex-wrap align-items-center d-xl-block">
        <div class="profWrap mx-xl-auto">
          <span class="online"></span>
          <div>
            <figure class="prof {{selectedStatBorder | lowercase}}" (click)="openAvLibrary(avatarLibrary)">
              <div class="pLoader" *ngIf = "profileloader">
                <div class="spinner-border text-warning" role="status" >
                  <span class="sr-only">Loading...</span>
                </div>
              </div>                
              <span *ngIf = "myProfile.controls['avatar_url'].value !== ''"><img [src]="imgData.url" alt=""></span>
              <span *ngIf = "myProfile.controls['avatar_url'].value == ''"><img src="assets/images/avatar-default.jpg" alt=""></span>
              <span class="hoverAct"><i class="fas fa-pencil-alt"></i></span>
            </figure>            
          </div>
        </div>
        <div class="profActBtns mx-xl-auto">
          <div class="text-center mb-3">

          </div>



          <div class="text-center">            
            <div class="d-block statDD mx-2 mb-2" ngbDropdown>
              <button type="button" class="btn btn-block" id="borderDropdown"
                ngbDropdownToggle>{{selectedStatBorder}}</button>
              <div class="dropdown-menu" ngbDropdownMenu aria-labelledby="borderDropdown">
                <button ngbDropdownItem *ngFor="let ul of userLevels, let i = index"
                  (click)="ChangeStatBorder(ul)"
                  [ngClass]="{'border1' : i == 0, 'border2' : i == 1, 'border3' : i == 2, 'border4' : i == 3, 'border5' : i == 4}">
                  {{ul.user_type}}
                </button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-xl-8 courseNorms">
      <form class="floatedInputForm borderOff" [formGroup]="myProfile" (ngSubmit)="saveProfile()"
        [ngClass]="{submitting : loader}">
        <div class="spinner-border text-warning formLoader" role="status" *ngIf="loader">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="row mb-3">
          <label class="col-12 col-md-3 pb-2 pb-md-0 m-0">First Name</label>
          <div class="col-12 col-md-9">
            <div>
              <input type="text" [value]="myProfile.get('first_name').value | titlecase" class="form-control" formControlName="first_name">
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <label class="col-12 col-md-3 pb-2 pb-md-0 m-0">Last Name</label>
          <div class="col-12 col-md-9">
            <div>
              <input type="text" [value]="myProfile.get('last_name').value | titlecase" class="form-control" formControlName="last_name">
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <label class="col-12 col-md-3 pb-2 pb-md-0 m-0">User Name</label>
          <div class="col-12 col-md-9">
            <div>
              <input type="text" class="form-control" formControlName="username">
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <label class="col-12 col-md-3 pb-2 pb-md-0 m-0">Role</label>
          <div class="col-12 col-md-9">
            <div>
              <input type="text" class="form-control capital" formControlName="role" readonly>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <label class="col-12 col-md-3 pb-2 pb-md-0 m-0">Email</label>
          <div class="col-12 col-md-9">
            <div>
              <input type="text" class="form-control" formControlName="email" readonly>
            </div>
          </div>
        </div>

        <div class="row mb-3">
          <label class="col-12 col-md-3 pb-2 pb-md-0 m-0">Role</label>
          <div class="col-12 col-md-9">
            <div>
              <input type="text" class="form-control" formControlName="role" readonly>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <label class="col-12 col-md-3 pb-2 pb-md-0 m-0">About Me</label>
          <div class="col-12 col-md-9">
            <div>
              <textarea rows="4" cols="12" [value]="myProfile.get('about').value | capitalization" class="form-control" formControlName="about"></textarea>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <label class="col-12 col-md-3 pb-2 pb-md-0 m-0">Facebook</label>
          <div class="col-12 col-md-9">
            <div>
              <input type="text" class="form-control" formControlName = "facebook" placeholder="https://www.facebook.com/educollab">
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <label class="col-12 col-md-3 pb-2 pb-md-0 m-0">LinkedIn</label>
          <div class="col-12 col-md-9">
            <div>
              <input type="text" class="form-control" formControlName = "linkedin" placeholder="https://in.linkedin.com/educollab">
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <label class="col-12 col-md-3 pb-2 pb-md-0 m-0">Organisation </label>
          <div class="col-12 col-md-9">
            <div>
              <input type="text" class="form-control" formControlName="institute" readonly>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <label class="col-12 col-md-3 pb-2 pb-md-0 m-0">Mobile Number</label>
          <div class="col-12 col-md-9">
            <div>
              <input type="text" class="form-control" (input)="stripText(myProfile.controls.mobile)"
                formControlName="mobile" maxlength="10">
            </div>
          </div>
        </div>
        
        <div class="text-right">
          <button type="submit" class="btn btn-danger">Save Personal Info<i class="fas fa-check ml-2"></i></button>
        </div>
      </form>
    </div>
  </div>
</div>


<ng-template #avatarLibrary let-c="close" let-d="dismiss">
  <div class="modal-header" style="border: 0;padding:0;">
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-avatar-library (avatarPathValue)="getAvatarPath($event)"></app-avatar-library>
  </div>
</ng-template>