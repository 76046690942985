<!-- My Learning -->
<div class="row">
    <div class="col-12 col-md-6 py-2" *ngFor="let course of categoryVisDetails; let i=index">
        <div class="nUxCourseBox d-flex" *ngIf="course.subscribed">
            <label class="lblSubs">Subscribed</label>
            <div class="leftFigure">
                <figure class="d-flex">
                    <img src="{{ course.banner_img}}" alt="">
                </figure>
            </div>
            <div class="rightCont">
                <h6>{{ course.subject }}</h6>
                <div class="desc" [innerHTML]="course.learning_objectives"></div>
                <div class="d-flex justify-content-between">
                    <div>
                        <span class="badge badge-secondary">{{course.course_category}}</span> <!-- Category -->
                    </div>
                    <div>
                        <ngb-rating max="5" [(rate)]="course.rating"></ngb-rating>
                    </div>
                </div>
                <div class="pt-2 btnOpt">
                    <button class="btn btn-link p-0 mr-3" ngbTooltip="View Course" container="body" type="button"
                        (click)="sendCourseDetails(course.course_id, course.subject)">
                        <i class="fas fa-eye"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<nav class="pt-5">
    <ul class="pagination d-flex justify-content-center">
        <li class="page-item" [ngClass]="{'disabled': current_page == get_number(number_of_page[0])}" [attr.disabled]="check_previous('Previous')">
            <a class="page-link" (click)="Send_index(current_page-1)">Previous</a>
        </li>
        <li class="page-item" (click)="Send_index(page)"  [ngClass]="{ 'active': current_page == page }" *ngFor="let page of number_of_page; let pi= index"><a class="page-link">{{page}}</a></li>
        <li class="page-item" [ngClass]="{'disabled': current_page == total_pages}" [attr.disabled]="check_next('Next')">
            <a class="page-link"  (click)="Send_index(current_page+1)">Next</a>
        </li>
    </ul>
</nav>