
<div class="banner" style=" padding: 2em 0;min-height:auto">
    <div class="container text-center">
        <h3>edu-collab mobile upgrade</h3>
    </div>
</div>

<div class="pageBody" style="padding: 1.5rem 0;">
    <div class="container">
        <ul style="list-style-type:none; display: none"> Presenting all new design and structure for courses, assessment and course
            work. v9 features include:
            <li>Course work now has resources</li>

            <li>Assessments are at 3 levels - course, topic and session</li>

            <li>User friendly interface for courses </li>
        </ul>
        <br />
        <p><u> we'll soon be live on google play store.</u> Until then please follow below steps for the upgrade.</p>
      
        <h4>UPGRADE INSTRUCTIONS:</h4>
        <ol >
            <li> <u><a href="https://educollab-mobile-builds.s3.ap-south-1.amazonaws.com/android/master/Educollab.414.apk">Click here</a></u> to download latest version or
                paste below url into your browser and open <br />
                <span style="text-align: center;">https://educollab-mobile-builds.s3.ap-south-1.amazonaws.com/android/master/Educollab.414.apk</span>
            </li>
            <li>
                 Remove the existing application manually

            </li>
            <li>
                 Click on the app that you've downloaded using step-1 and proceed to install

            </li>

        </ol>

        <br />
        <h4>TROUBLESHOOTING GUIDE:</h4>
            If you're greeted with message like
            <p>
                <em>"Install blocked. For security, your phone is set to block installation of applications not
                    obtained from
                    Play
                    Store"</em>
            </p>
            

            To allow our app, follow this steps:
        <ol>

            <li> Navigate to Setting > Security.</li>
            <li>
                 Check the option "Unknown sources".

            </li>
            <li>
                 Tap OK on the prompt message.
             </li>
            <li>
                 Select "Trust".
            </li>

        </ol>
        <hr style="width: 50%;">
        <p style="margin-bottom:40px">
            Don't worry

            <strong>You're still protected</strong><br>
            Even when installing Apps from Unknown sources, Google Play Protect will continue scanning Apps, looking for
            viruses, malware and blocking forbidden Apps.
        </p>
        

        <hr>

        <h5>Need help with some issue
            Email us at <a>educollab@tatrasdata.com</a></h5>
    </div>

</div>
