import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CourseService } from 'src/app/services/course.service';
import { NotificationService } from 'src/app/services/notification.service';
import { PermissionService } from '../../services/permission.service';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, filter } from 'rxjs/operators';
import {TooltipPosition} from '@angular/material/tooltip';

@Component({
  selector: 'app-teacher-course-work',
  templateUrl: './teacher-course-work.component.html',
  styleUrls: ['./teacher-course-work.component.scss']
})
export class TeacherCourseWorkComponent implements OnInit {

  @ViewChild('searchInput', { static: false }) searchInput!: ElementRef;
  positionOptions: TooltipPosition[] = ['after', 'before', 'above', 'below', 'left', 'right'];

  allCourseWork: any[] = [];
  isCourseWorkAvailable: boolean = false;
  courseWorkList: any[];
  loader: boolean = false;
  questionModel: any = null;
  role: string = '';
  courseId: string = null;

  //FOR PAGINATION
  maxPageNoDisplay: number = 10;
  totalDatalength: any = 0;
  pageSize: number = 10;
  page: any = 1;
  searchText: string = '';

  constructor(private courseService: CourseService, private router: Router, private modalService: NgbModal,
    private notificationService: NotificationService, private activatedRoute: ActivatedRoute, private permissionService: PermissionService) {
  }

  ngOnInit() {

    const role = localStorage.getItem('role');
    this.role = role;

    //CHECK PERMISSION
    if (!this.permissionService.isAllowedPageView(['teacher', 'super_admin'], role)) {
      this.router.navigate(['/']);
      return false;
    }

    //GET ALL COURSEWORK
    this.getAllCourseWork();
  }

  courseWorkNewList = [];
  courseListPreview = [];
  getAllCourseWork() {
    this.loader = true;
    this.courseService.fetchAllCourseWork(this.page, this.searchText).pipe(finalize(() => {
      this.loader = false;
    })).subscribe(data => {
      this.allCourseWork = data.course_works.course_work_data;
      this.totalDatalength = data.course_works.total_pages * this.pageSize;
      this.courseWorkList = this.allCourseWork;      
      let co = this.courseWorkList.forEach((item, i)=>{
        this.courseWorkNewList = item.courses.map(x => x.course);
        this.courseListPreview.push(this.courseWorkNewList)
      });
      console.log(this.courseListPreview);      
    });
  }

  onPageChanged(page: string) {
    if(page){
      this.courseListPreview = [];
    }
    this.page = parseInt(page, 10) || 1;
    this.getAllCourseWork();
  }

  getPublish(value) {
    return value ? 'Publish' : 'Unpublised'
  }

  //FOR SEARCH
  ngAfterViewInit() {
    fromEvent(this.searchInput.nativeElement, 'input')
      .pipe(
        debounceTime(400),
        distinctUntilChanged(),
        tap((event) => {
          this.page = 1;
          this.searchText = '';
          let searchVal = '';
          if (this.searchInput.nativeElement.value.length > 0) {
            this.searchText = this.searchInput.nativeElement.value;
            this.getAllCourseWork();
          }
          else {
            this.getAllCourseWork();
          }
        })
      )
      .subscribe();
  }

  onAddCourseWork() {
    this.router.navigate(['/courses/add-coursework']);
  }

  editSelectedCourseWork(id) {
    this.router.navigate(['/courses/add-coursework'], { queryParams: { courseWork: id } });
  }

  viewSelectedCourseWork(id) {
    this.router.navigate(['/courses/coursework-view'], { queryParams: { courseWork: id, course_id: this.courseId } });
  }

  openModelDeleteCoursework(delCoursework, myquestions = null) {
    this.questionModel = this.modalService.open(delCoursework);
  }

  closeQuestionPopup() {
    this.questionModel.close();
  }

  deleteCoursework(coursework) {
    this.loader = true;
    this.courseService.delete_course(coursework).subscribe(req => {
      this.loader = false;
      this.notificationService.showNotification("success", "Deleted successfully");
      this.questionModel.close();
      this.page = 1;
      this.getAllCourseWork();
    },
      (err) => {
        this.loader = false;
        this.questionModel.close();
        if (err.message) {
          this.notificationService.showNotification("error", err.message);
        }
        else {
          this.notificationService.showNotification("error", "This coursework is being used in a course, cannot delete such coursework");
        }
      })
  }

}
