import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'default-name-icon',
  templateUrl: './default-name-icon.component.html',
  styleUrls: ['./default-name-icon.component.scss']
})
export class DefaultNameIconComponent implements OnInit {

  @Input() name: string = 'U';
  @Input() figureClass: string = '';

  constructor() { }

  ngOnInit() {
  	this.name = this.name[0];
  }

}
