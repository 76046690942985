import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl, FormArray } from '@angular/forms';
import { PassionProjectService } from 'src/app/services/passion-project.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { HandleUploadService } from 'src/app/shared/modules/shared/services/handle-upload.service';
import { ResourceService } from 'src/app/services/resources/resource.service';
import { ReportService } from 'src/app/services/passion-project/report.service';
import { Observable, of } from 'rxjs';

//FOR EDITOR
import { environment } from 'src/environments/environment';
declare var tinymce: any;

//FOR FILE UPLOAD
import { HttpEvent, HttpEventType } from '@angular/common/http';

@Component({
  selector: 'app-project-report-add',
  templateUrl: './project-report-add.component.html',
  styleUrls: ['./project-report-add.component.scss']
})
export class ProjectReportAddComponent implements OnInit {

  role: string = '';
  userId: string = '';
  loader: boolean = false;
  passionProjectId: string = '';
  teamId: string = '';
  teamData: any = null;
  passionProject: any = null;

  //FOR CREATE PROJECT
  saveLoader: boolean = false;
  addReportForm!: FormGroup;
  formType: string = '';
  reportResources!: FormArray;
  reportWebinars!: FormArray;
  reportCodes!: FormArray;
  submitReport: boolean = false;

  //FOR EDITOR
  insertBlankFlag: boolean = false;
  toolbarWithoutBlankButton = 'undo redo | formatselect | bold italic backcolor | \
  alignleft aligncenter alignright alignjustify | \
  bullist numlist outdent indent | removeformat | \
  link image | preview media help';
  apiEndpoint: string = '';
  fileArr: any = [];

  //FOR EDIT FORM
  reportId: string = '';
  editReport: any = {};

  constructor(private fb: FormBuilder, private passionProjectService: PassionProjectService, private notificationService: NotificationService, private activateRoute: ActivatedRoute, private route: Router, private commonService: CommonService, private handleUploadService: HandleUploadService, private resourceService: ResourceService, private reportService: ReportService) { }

  ngOnInit() {
    this.role = localStorage.getItem('role');
    this.userId = localStorage.getItem('user_id');

    this.initializeData();
  }

  initializeData() {
    this.createAddReportForm();

    this.activateRoute.queryParams.subscribe(params => {
      this.passionProjectId = params.project_id;
      this.teamId = params.team_id;

      //SET PROJECT ID AND TEAM ID
      this.addReportForm.patchValue({project_id: this.passionProjectId});
      this.addReportForm.patchValue({team_id: this.teamId});

      //CHECK PERMISSION
      // if (this.passionProject.edit_rights === false) {
      //   this.route.navigate(['/passion-projects']);
      //   return false;
      // }
    

      //GET TEAM DATA
      this.getTeamData();

      //FOR CREATE FORM
      this.formType = 'add';

      //FOR EDIT FORM
      if(params.report_id) {
        this.reportId = params.id;
        this.formType = 'edit';
        this.getProjectReport();
      }

    });
  }

  ngAfterViewInit() {
    this.initEditorData();
  }

  initEditorData() {
    //FOR EDITOR
    tinymce.remove();
    this.apiEndpoint = environment.APIEndpoint;
    const that = this;
    this.commonService.callMceEditor('textReportDescription', that, tinymce);
  }

  //FOR TEAM DATA GET
  getTeamData() {
    this.loader = true;
    this.passionProjectService.getTeamsById(this.passionProjectId, this.teamId)
      .subscribe(res => {        
        this.loader = false;
        this.teamData = res.teams[0];
        this.passionProject = this.teamData.projects[0];
        this.updateTagColors();

        //CHECK PERMISSIONS
        const findedIndex = this.teamData.members.findIndex(team => team._id === this.userId);
        
        if(findedIndex == -1 && (this.role !== 'student' && this.passionProject.edit_rights === false)) {
          this.route.navigate(['/passion-projects']);
          return false;
        }
      }, err => {
        this.loader = false;
        this.notificationService.showNotification('error', err.message);
      });
  }

  updateTagColors() {
    if (this.passionProject.project_tags) {
      for (let i = 0; i < this.passionProject.project_tags.length; i++) {
        this.passionProject.project_tags[i].bgColor = this.commonService.randomBgColor();
      }
    }
  }

  onGoBack() {
    this.route.navigate(['/passion-projects/team'], { queryParams: { project_id: this.passionProjectId, team_id: this.teamId } });
  }

  //FOR REPORT FORM |START
  createAddReportForm() {

    this.addReportForm = this.fb.group({
      title: ['', [Validators.required]],
      tags: [[], [Validators.required]],
      content: ['', [Validators.required]],     
      resource_links: this.fb.array([]),
      code_links: this.fb.array([this.createCode()]),
      webinar_links: this.fb.array([this.createWebinar()]),
      project_id: [this.passionProjectId],
      team_id: [this.teamId]
    });

    this.reportResources = this.addReportForm.get('resource_links') as FormArray;
    this.reportWebinars = this.addReportForm.get('webinar_links') as FormArray;
    this.reportCodes = this.addReportForm.get('code_links') as FormArray;

    //SHARE FORM WITH CHILD COMPONENT
    this.handleUploadService.addForm = this.addReportForm;
  }

  createCode() {
    return this.fb.group({
      title: ['', Validators.required],
      _id: [''],
      url: [''],
      type: ['', Validators.required]
    });
  }

  createWebinar() {
    return this.fb.group({
      title: ['', Validators.required],
      _id: [''],
      url: [''],
      type: ['', Validators.required]
    });
  }

  addMoreCode() {
    this.reportCodes.push(this.createCode());
  }

  removeCode(index: number) {
    if (confirm('Are you sure want to delete report code ' + (index + 1) + ' ?')) {
      this.reportCodes.removeAt(index);
    }
  }

  addMoreWebinar() {
    this.reportWebinars.push(this.createWebinar());
  }

  removeWebinar(index: number) {
    if (confirm('Are you sure want to delete report webinar ' + (index + 1) + ' ?')) {
      this.reportWebinars.removeAt(index);
    }
  }

  onInputUrl(i, fieldName) {
    this.addReportForm.controls[fieldName]['controls'][i].controls.type.setValue('text');
  }

  resetFileField(event) {
    event.target.value = '';
  }

  detectFiles(event, type, index) {
    if (event.target.files.length == 0) {
      return;
    }
    this.uploadResource('passion_project', event.target.files[0], type, index);
  }

  progresBarInfo: any[] = [];
  progressBar: number = 0;
  uploadResource(modelName, file, resourceType, index) {
    //FOR MULTI PROGRESS INFO
    let progressItem = this.progresBarInfo.find((item) => {
      if(item.resourceType === resourceType && item.index === index) {
        return true;
      }
      return false;
    });
    if(!progressItem) {
      progressItem = {resourceType: resourceType, index: index, progressBar: this.progressBar};
      this.progresBarInfo.push(progressItem);
    }

    let resourceData: any = null;
    this.notificationService.upload_resource(modelName, file).subscribe(
      (response: HttpEvent<any>) => {
        switch (response.type) {
          case HttpEventType.Sent:
          break;
          case HttpEventType.ResponseHeader:
          break;
          case HttpEventType.UploadProgress:
          this.progressBar = Math.round(response.loaded / response.total * 100);
          //FOR MULTI PROGRESS INFO
          progressItem.progressBar = this.progressBar;
          break;
          case HttpEventType.Response:
          if (response.body.resource_info.length > 0) {
            resourceData = response.body.resource_info[0];
            this.notificationService.showNotification('success', response.body.message);
            this.addReportForm.controls[resourceType]['controls'][index].controls.type.setValue('file');
            this.addReportForm.controls[resourceType]['controls'][index].controls.url.setValue('');
            this.addReportForm.controls[resourceType]['controls'][index].controls._id.setValue(resourceData.resource_id);
          }
          setTimeout(() => {
            this.progressBar = 0;
            //FOR MULTI PROGRESS INFO
            progressItem.progressBar = this.progressBar;
          }, 1000);
        }
      },
      (err) => {
        this.progressBar = 0;
        //FOR MULTI PROGRESS INFO
        progressItem.progressBar = this.progressBar;
        this.notificationService.showNotification('info', 'Please add again not able to save your file');
      });
  }

  //FOR MULTI PROGRESS INFO
  getProgressBar(resourceType: string, index: number) {
    let progressItem = this.progresBarInfo.find((item) => {
      if(item.resourceType === resourceType && item.index === index) {
        return true;
      }
      return false;
    });
    if(progressItem) {
      return progressItem.progressBar;
    }
    else {
      return 0;
    }
  }

  //FOR FORM SUBMIT
  onAddReportFormSubmit(submitAction: string) {
    this.submitReport = true;

    //SET DESCRIPTION
    let reportDescription = this.notificationService.titleCase(tinymce.get("textReportDescription").getContent());
    reportDescription = this.notificationService.paraString(reportDescription);
    this.addReportForm.patchValue({
      content: reportDescription
    });

    //SEND FORM SUBMIT STATUS TO CHILD COMPONENT
    this.handleUploadService.sendSubmitFormStatus({status: true, formSubmitStatus: this.submitReport});

    if (!this.validateForm()) {
      return false;
    }
    const postData = this.getPostData(submitAction);

    //SAVE PASSION PROJECT DATA
    if (this.formType == 'add') {
      this.saveLoader = true;
      this.reportService.addReport(postData)
        .subscribe(response => {
          this.saveLoader = false;
          this.submitReport = false;
          this.notificationService.showNotification('success', response.message);

          this.removeResources('add');
          this.route.navigate(['/passion-projects/team'], { queryParams: { project_id: this.passionProjectId, team_id: this.teamId } });
        },
        (err) => {
          this.saveLoader = false;
          this.notificationService.showNotification('error', err.message);
        }
      );
    }
    else if(this.formType == 'edit') {
      this.saveLoader = true;
      this.reportService.editReport(postData)
        .subscribe(response => {
          this.saveLoader = false;
          this.submitReport = false;
          this.notificationService.showNotification('success', response.message);

          this.removeResources('edit');
          this.route.navigate(['/passion-projects/team'], { queryParams: { project_id: this.passionProjectId, team_id: this.teamId } });
        },
        (err) => {
          this.saveLoader = false;
          this.notificationService.showNotification('error', err.message);
        }
      );
    }
  }

  validateForm() {
    if (this.addReportForm.invalid) {
      this.notificationService.showNotification("error", "Please correct the form like Resources, Description, Title, and other need to be correct.");
      return false;
    }

    if(!this.validationLinks()) {
      return false;
    }

    return true;
  }

  validationLinks() {

    for (let wl = 0; wl < this.addReportForm.value.webinar_links.length; wl++) {
      let webinarLink = this.addReportForm.value.webinar_links[wl];
      if (webinarLink.url != '' && !this.notificationService.weblink_validation(webinarLink.url)) {
        this.notificationService.showNotification('info', 'Please add correct webinar url: ' + webinarLink.title +' '+ (wl + 1));
        return false;
      } else if (webinarLink.url == '' && webinarLink._id == '') {
        this.notificationService.showNotification('info', 'Please add correct webinar URL or File: ' + webinarLink.title +' '+ (wl + 1));
        return false;
      }
    }

    for (let cl = 0; cl < this.addReportForm.value.code_links.length; cl++) {
      let codeLink = this.addReportForm.value.code_links[cl];
      if (codeLink.url != '' && !this.notificationService.weblink_validation(codeLink.url)) {
        this.notificationService.showNotification('info', 'Please add correct code url: ' + codeLink.title +' '+ (cl + 1));
        return false;
      } else if (codeLink.url == '' && codeLink._id == '') {
        this.notificationService.showNotification('info', 'Please add correct code URL or File: ' + codeLink.title +' '+ (cl + 1));
        return false;
      }
    }

    return true;
  }

  getPostData(submitAction) {
    let postData = this.addReportForm.value;

    //FOR EDIT FORM
    if (this.formType == 'edit') {
      postData._id = this.editReport._id;
    }

    return postData;
  }

  //TO REMOVE RESOURCES
  removeResources(actionRef = '') {
    let removeFileList: any[] = [];
    if (actionRef == 'add' || actionRef == 'edit') {
      removeFileList = this.handleUploadService.allDeletedFiles;
    }
    else {
      removeFileList = this.handleUploadService.allUploadedFiles;
    }

    if (removeFileList.length > 0) {
      this.saveLoader = true;
      this.resourceService.removeResource('', removeFileList).subscribe(res => {
        this.saveLoader = false;
        if (actionRef == 'discard') {
          this.route.navigate(['/passion-projects/team'], { queryParams: { project_id: this.passionProjectId, team_id: this.teamId } });
        }
        this.handleUploadService.allUploadedFiles = [];
        this.handleUploadService.allDeletedFiles = [];
      }, err => {
        this.saveLoader = false;        
      });
    }
    else {
      if (actionRef == 'discard') {
        this.route.navigate(['/passion-projects/team'], { queryParams: { project_id: this.passionProjectId, team_id: this.teamId } });
      }
    }
  }

  //FOR TAGS
  onInputTag = (searchText: string): Observable<any[]> => {
    searchText = searchText.trim();
    if (searchText.length) {
      return this.passionProjectService.fetchSearchTags(searchText);
    }
    return of([]);
  }

  //FOR EDIT FORM |START
  getProjectReport() {
    this.loader = true;
    this.reportService.getReportByTeamId(this.passionProjectId, this.teamId).subscribe(res => {
        this.loader = false;        
        this.editReport = res.reports[0];
        this.setEditReport();
      }, err => {
        this.loader = false;
        this.notificationService.showNotification('error', err.message);
    });
  }

  setEditReport() {

    if(this.editReport) {

      this.addReportForm.controls['title'].setValue(this.editReport.title);      
      this.addReportForm.controls['tags'].setValue(this.editReport.tags);
      this.addReportForm.controls['content'].setValue(this.editReport.content);
      tinymce.get("textReportDescription").setContent(this.editReport.content);

      this.addReportForm.controls['project_id'].setValue(this.editReport.project._id);
      this.addReportForm.controls['team_id'].setValue(this.editReport.team._id);

      this.reportWebinars.clear();
      this.editReport.webinar_links.forEach((item, index) => {
        this.addMoreWebinar();
        this.addReportForm.controls['webinar_links']['controls'][index].controls.title.setValue(item.title);
        this.addReportForm.controls['webinar_links']['controls'][index].controls._id.setValue(item.resource_type == 'file' ? item._id : '');
        this.addReportForm.controls['webinar_links']['controls'][index].controls.url.setValue(item.url);
        this.addReportForm.controls['webinar_links']['controls'][index].controls.type.setValue(item.resource_type);
      });

      this.reportCodes.clear();
      this.editReport.code_links.forEach((item, index) => {
        this.addMoreCode();
        this.addReportForm.controls['code_links']['controls'][index].controls.title.setValue(item.title);
        this.addReportForm.controls['code_links']['controls'][index].controls._id.setValue(item.resource_type == 'file' ? item._id : '');
        this.addReportForm.controls['code_links']['controls'][index].controls.url.setValue(item.url);
        this.addReportForm.controls['code_links']['controls'][index].controls.type.setValue(item.resource_type);
      });

      //FOR UPLOADED RESOURCES
      this.handleUploadService.sendSharedData({editData: this.editReport});
    }
  }
  //FOR EDIT FORM |END

  //FOR DISCARD DATA |START
  onDiscardClicked() {
    this.removeResources('discard');
  }
  //FOR DISCARD DATA |END

  //FOR RESET DATA |START
  onResetClicked() {
    if (confirm('Are you sure want to reset changes ?')) {
      this.resetReport();
    } else {
      return false;
    }
  }

  resetReport() {
    this.removeResources('reset');
    this.clearData();
    this.initializeData();
  }
  //FOR RESET DATA |END

  //FOR CLEAR SERVICE DATA |END
  clearData() {
    this.submitReport = false;
    this.handleUploadService.addForm = null;
    this.handleUploadService.allUploadedFiles = [];
    this.handleUploadService.allDeletedFiles = [];
  }

  ngOnDestroy() {
    this.clearData();
  }

}
