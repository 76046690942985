<!-- New Code -->
<div class="new_UIstyle">
    <div class="pageBodyWrap" *ngIf="resDetails">
       
        <!-- banner section -->
        <div class="banner banner-gradient banner-C-assessment">
            <div class="container" [ngClass]="{submitting : loader}">
                <div class="progress_loader" *ngIf="loader">
                    <div class="spinner-border text-warning formLoader" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <div class="banner-content">
                    <div class="banner-content-content">
                        <h2 class="white-text avenirBlack">{{resDetails?.title}}</h2>
                        <!-- <p class="white-text">Group Assignment: <strong class="ms-2 primary-text">Student - Led</strong></p> -->
                        <div class="tags-badges" *ngFor="let tags of resDetails.tags">
                            <span class="badge badge-green-lt">{{tags.tag}}</span>
                           
                        </div>

                        <div class="assessment-content-options-list" >
                            <ul class="assessment-content-options-ul ">
                                <li class="white-text" *ngIf="resDetails?.history?.length > 0">Course: <strong class="primary-text" *ngFor="let hist of resDetails?.history; let last = last">{{hist.course_title}}<ng-container *ngIf="!last && hist.course_title" class="white-text">,</ng-container></strong></li>
                               
                            </ul>
                        </div>
                        
                        <p class="white-text mt-3">Added by: <strong class="primary-text">{{resDetails?.created_by}}</strong></p>
                    </div>
                    <div class="banner-content-action btn-groups">
                        <button class="btn btn-primary" (click)="goBack()">Back</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- banner section -->

        <div class="section resource-detail-wrap">
            <div class="container">
                <div class="resource-detail-content scroll-content mx-height400">
                    <p>{{resDetails?.description}}</p>
                   
                </div>

                <div class="resource-files-wrap" *ngIf="resDetails.type == 'video'">
                <hr class="mt-3 mt-3" />
                    <h6 class="mb-3 avenirBlack primary-text">Video</h6>
                    <div class="row justify-content-center">
                        <div class="col-md-8 ">
                            <video width="540" height="310" controls>
                                <source [src]="resDetails?.url" type="video/mp4">
                            </video>
                        </div>
                    </div>
                </div>
                <div class="resource-files-wrap" *ngIf="resDetails.type == 'image'">
                <hr class="mt-3 mt-3" />
                    <h6 class="mb-3 avenirBlack primary-text">Image</h6>
                    <div class="row justify-content-center">
                        <div class="col-md-8 ">
                            <div class="ratio ratio-16x9">
                                
                                    <img [src]="resDetails?.url" [alt]="ansResourceImg" />
                                
                            </div>
                        </div>
                    </div>
                </div>

                <div class="resource-files-wrap" *ngIf="resDetails.type == 'file'">
                <hr class="mt-3 mt-3" />
                    <h6 class="mb-3 avenirBlack primary-text">File</h6>
                    <!-- <div class="row justify-content-center">
                        <div class="col-md-8 ">
                            <div class="ratio ratio-16x9">
                                <iframe src="//www.youtube.com/embed/YE7VzlLtp-4" title="YouTube video"
                                    allowfullscreen></iframe>
                            </div>
                        </div>
                    </div> -->
                     
                    <div class="learning-course-item">
                        <div class="learning-course-link">
                            <div class="learning-course-elem-left">
                            <p class="learning-course-link-title">
                                <span class="title-icon"><i class="fas fa-folder"></i></span><strong class=" ml-2" *ngIf="resDetails?.file_name">{{resDetails?.file_name}}</strong></p>
                            </div>
                            <div class="learning-course-elem-right">
                                <ul class="list-icon-wrap">
                                    <li>
                                        <a class="learning-course-download" [href]="resDetails?.url" target="_blank" [download]="resDetails?.url">
                                            <i class="fas fa-download"></i>
                                        </a>
                                    </li>
                                </ul>                                                   
                            </div>
                    </div>
                    </div>
                </div>

                <div class="resource-files-wrap" *ngIf="resDetails.type == 'python'">
                    <hr class="mt-3 mt-3" />
                        <h6 class="mb-3 avenirBlack primary-text">File</h6>
                        <!-- <div class="row justify-content-center">
                            <div class="col-md-8 ">
                                <div class="ratio ratio-16x9">
                                    <iframe src="//www.youtube.com/embed/YE7VzlLtp-4" title="YouTube video"
                                        allowfullscreen></iframe>
                                </div>
                            </div>
                        </div> -->
                         
                        <div class="learning-course-item">
                            <div class="learning-course-link">
                                <div class="learning-course-elem-left">
                                <p class="learning-course-link-title">
                                    <span class="title-icon"><i class="fas fa-folder"></i></span><strong class=" ml-2" *ngIf="resDetails?.file_name">{{resDetails?.file_name}}</strong></p>
                                </div>
                                <div class="learning-course-elem-right">
                                    <ul class="list-icon-wrap">
                                        <li>
                                            <a class="learning-course-download" [href]="resDetails?.url" target="_blank" [download]="resDetails?.url">
                                                <i class="fas fa-download"></i>
                                            </a>
                                        </li>
                                    </ul>                                                   
                                </div>
                        </div>
                        </div>
                    </div>
    
            
            </div>
        </div>


    </div>
</div>

