<div class="banner">
    <div class="container text-center">
        <h1>Change Password</h1>
        <p>lorem ipsum dolor sit amet</p>
    </div>
</div>

  
<div class="pageBody">
    <div class="container">
        <form class="mw480 floatedInputForm" id="changePassword">
            <dl>
                <dd>
                    <input type="text" class="form-control" placeholder=" ">
                    <label class="flLabel">Old Password :</label>                    
                </dd>
            </dl>
            <dl>
                <dd>
                    <input type="text" class="form-control" placeholder=" ">
                    <label class="flLabel">New Password :</label>                    
                </dd>
            </dl>
            <dl>
                <dd>
                    <input type="text" class="form-control" placeholder=" ">
                    <label class="flLabel">Confirm Password :</label>                    
                </dd>
            </dl>
            <hr class="my-4">
            <div class="text-right">
                <button type="button" class="btn btn-default mr-3" routerLink="/profile"><i class="fas fa-arrow-left mr-2"></i>Back</button>
                <button type="submit" class="btn btn-primary">Submit<i class="fas fa-check ml-2"></i></button>
            </div>
        </form>
    </div>
</div>