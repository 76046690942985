<div class="new_UIstyle">
    <!-- New Code -->
    <div class="pageBodyWrap">
        <!-- banner section -->
        <div class="banner banner-gradient banner-C-assessment" [ngClass]="showStepTwoForm?'d-block':'d-none'">
            <div class="container">
                <div class="banner-content">
                    <div class="banner-content-content">
                        <h3 class="white-text avenirBlack">{{projectTitle}}</h3>
                        <div class="tags-badges" *ngIf="formType == 'edit'">
                            <span class="badge badge-light badge-primary">{{editProject.publish ? 'Published' :
                                'Draft'}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- banner section -->

        <section class="section add-passProject-wrap">
            <div class="container">
                <form class="floatedInputForm pt-0 mb-0" [ngClass]="{submitting : loader}" autocomplete="off">
                    <div class="row create-coursework-wrap">
                        <div class="col-md-8 m-auto">
                            <div class="add-course-step add-course-step-title"
                                [ngClass]="showStepOneForm ? 'd-block' : 'd-none' ">
                                <div class="course-title text-center mb-4 teach_data">
                                    <h2>What is the title of the project?</h2>
                                    <p>It's ok if can't think of a good title right now, You can always change it later
                                    </p>
                                </div>
                                <div class="form-filed mb-4">
                                    <input type="text" name="projectTitle" [(ngModel)]="projectTitle" #projTitle=ngModel
                                        class="form-control form-control-style-2 form-control-lg"
                                        [ngClass]="{ 'is-invalid': isNextBtnClicked && !projTitle.valid && projTitle.touched }"
                                        [value]="projectTitle | capitalization" (change)="onTitleChange()"
                                        minlength="4" maxlength="200" placeholder="" required="" />
                                    <div *ngIf="isNextBtnClicked && !projTitle.valid && projTitle.touched">
                                        <div *ngIf="projTitle.errors['required']"
                                            class="alert alert-danger mb-0 text-center">Title is required
                                        </div>
                                        <div *ngIf="projTitle.errors['minlength']"
                                            class="alert alert-danger mb-0 text-center">Title must contain at least 4 characters
                                        </div>
                                    </div>
                                </div>
                                <div class="btn-groups justify-content-center">
                                    <button type="button" class="btn btn-grey-dark btn-md btn-w-120"
                                        (click)="onDiscardStepOne()">Discard</button>
                                    <button type="button" class="btn btn-primary btn-md btn-w-120"
                                        (click)="formSubmitStepOne(projTitle)">Next</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

                <form [formGroup]="addProjectForm" class="typo-16" autocomplete="off"
                    [ngClass]="{submitting : loader || saveLoader}">
                    <div class="spinner-border text-warning formLoader" role="status" *ngIf="loader">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div [ngClass]="showStepTwoForm?'d-block':'d-none'">
                        <div class="mb-3 mb-md-4">
                            <label class="form-label avenirBlack mb-1">Passion Project Topic</label>
                            <p>You have to have a title of the topic and a little description, to let the user know what
                                the project is about.</p>
                            <input type="text" formControlName="project_title"
                                class="form-control form-control-lg form-control-style-2"
                                [value]="addProjectForm.get('project_title').value | capitalization"
                                placeholder="Enter title"
                                [ngClass]="{ 'is-invalid': submitProject && addProjectForm.controls.project_title.errors }" />
                            <div *ngIf="submitProject && addProjectForm.controls.project_title.errors"
                                class="invalid-feedback">
                                <div *ngIf="addProjectForm.controls.project_title.errors.required">Title is required
                                </div>
                                <div *ngIf="addProjectForm.controls.project_title.errors.minlength">Title must contain at least 4 characters
                                </div>
                            </div>
                        </div>

                        <div class="mb-3 mb-md-4">
                            <label class="form-label avenirBlack">Project Overview</label>
                            <textarea formControlName="project_description"
                                class="form-control form-control-textarea form-control-style-2"
                                placeholder="Enter a learning Objective"
                                [ngClass]="{ 'is-invalid': submitProject && addProjectForm.controls.project_description.errors }"></textarea>
                            <div *ngIf="submitProject && addProjectForm.controls.project_description.errors"
                                class="invalid-feedback">
                                <div *ngIf="addProjectForm.controls.project_description.errors.required">Project
                                    Overview is required</div>
                            </div>

                            <div class="dragDropWrap mt-3">
                                <app-files-upload (fileUploadedStatus)="getFileUploadStatus($event)"
                                    [fieldControlName]="'project_path'" [fieldNameErr]="'Project Overview Image'"
                                    [fieldSupports]="'image'" [checkChanges]="checkChanges"
                                    [checkAddMore]="checkAddMore" [checkRemove]="checkRemove" [checkEdit]="checkEdit"
                                    [formType]="formType"></app-files-upload>
                            </div>
                        </div>

                        <div class="mb-3 mb-md-4">
                            <label class="form-label avenirBlack mb-1">Project Tags</label>
                            <tag-input [formControlName]="'project_tags'"
                                class="form-control form-control-lg form-control-style-2" [addOnBlur]="true"
                                [clearOnBlur]="true" [secondaryPlaceholder]="'Enter tags'" [displayBy]="'tag'"
                                [identifyBy]="'_id'"
                                [ngClass]="{ 'is-invalid': submitProject && addProjectForm.controls.project_tags.errors }">
                                <tag-input-dropdown [displayBy]="'tag'" [identifyBy]="'_id'"
                                    [autocompleteObservable]="onInputTag">
                                </tag-input-dropdown>
                            </tag-input>

                            <div *ngIf="submitProject && addProjectForm.controls.project_tags.errors"
                                class="invalid-feedback">
                                <div *ngIf="addProjectForm.controls.project_tags.errors.required">Project Tags is
                                    required</div>
                            </div>
                        </div>


                        <div class="mb-3 mb-md-4">
                            <label class="form-label avenirBlack mb-1">Mentors</label>
                            <app-select-mentors [viewItemId]="passionProjectId" [btnRef]="(!passionProjectId ? 'Create' : 'Edit')" [teamData]="editProject?.project_mentors" (teamStatus)="onGetTeamStatus($event)" [ngClass]="{ 'is-invalid': submitProject && addProjectForm.controls.project_mentors.errors }"></app-select-mentors>

                            <!-- <div *ngIf="submitProject && addProjectForm.controls.project_mentors.errors"
                                class="invalid-feedback">
                                <div *ngIf="addProjectForm.controls.project_mentors.errors.required">Mentors is required
                                </div>
                            </div> -->
                        </div>

                        <hr />

                        <div class="mb-3 mb-md-4">
                            <label class="form-label avenirBlack mb-1">Project Resources</label>
                            <app-files-upload (fileUploadedStatus)="getFileUploadStatus($event)"
                                [fieldControlName]="'project_resources'" [fieldNameErr]="'Project Resources'"
                                [fieldSupports]="'file'" [checkChanges]="checkChanges" [checkAddMore]="checkAddMore"
                                [checkRemove]="checkRemove" [checkEdit]="checkEdit"
                                [formType]="formType"></app-files-upload>
                        </div>

                        <hr />

                        <div class="mb-3 mb-md-4">
                            <label class="form-label avenirBlack mb-1">Project Goals</label>
                            <div class="plan-course-components">
                                <ng-container formArrayName="project_goals">
                                    <ng-container
                                        *ngFor="let projectGoal of projectGoals.controls; let goalIndex = index;">
                                        <div
                                            class="plan-course-component-list {{(submitProject && projectGoal.controls.name.errors) ? 'm-0': ''}}">
                                            <ng-container [formGroupName]="goalIndex">
                                                <input type="text" formControlName="name"
                                                    class="form-control form-control-style-2 form-control-lg"
                                                    placeholder="Enter goal"
                                                    [ngClass]="{ 'is-invalid': submitProject && projectGoal.controls.name.errors }" />
                                                <div *ngIf="goalIndex != 0"
                                                    class="iconBtn-groups iconBtn-groups-right iconBtn-borderBox">
                                                    <button class="iconBtn iconDeleteBtn"><i class="fas fa-trash-alt"
                                                            (click)="removeGoal(goalIndex)"></i></button>
                                                </div>
                                            </ng-container>
                                        </div>
                                        <div *ngIf="submitProject && projectGoal.controls.name.errors"
                                            class="invalid-feedback d-block mb-3">
                                            <div *ngIf="projectGoal.controls.name.errors.required">Project Goal is
                                                required</div>
                                        </div>
                                    </ng-container>
                                </ng-container>
                                <div class="textBtn-groups">
                                    <a class="textBtn primary-text avenirBlack" (click)="addMoreGoal()">+ Add more
                                        Project Goals</a>
                                </div>
                            </div>
                        </div>

                        <hr />

                        <!-- <div class="mb-3 mb-md-4">
                            <label class="form-label avenirBlack mb-1">Project Details</label>
                            <ng-container formArrayName="project_details">
                                <div *ngFor="let projectDetail of projectDetails.controls; let detailIndex = index;"
                                    class="border-box" [ngClass]="{'mt-4': detailIndex != 0}">
                                    <div class="modal_cont float-right p-0">
                                        <button type="button" class="close-btn lg-close" *ngIf="detailIndex != 0"
                                            (click)="removeDetail(detailIndex)">
                                            <i class="fa fa-trash" aria-hidden="true"></i>
                                        </button>
                                    </div>
                                    <ng-container [formGroupName]="detailIndex">
                                        <div class="mb-3 mb-md-4">
                                            <label class="form-label avenirBlack mb-1">Title</label>
                                            <input type="text" formControlName="title"
                                                class="form-control form-control-lg form-control-style-2" value=""
                                                placeholder="Enter title"
                                                [ngClass]="{ 'is-invalid': submitProject && projectDetail.controls.title.errors }" />
                                            <div *ngIf="submitProject && projectDetail.controls.title.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="projectDetail.controls.title.errors.required">Title is
                                                    required</div>
                                            </div>
                                        </div>
                                        <div class="mb-3 mb-md-4">
                                            <label class="form-label avenirBlack mb-1">Detail</label>
                                            <textarea formControlName="details"
                                                class="form-control form-control-textarea form-control-style-2"
                                                placeholder="Enter a learning Objective"
                                                [ngClass]="{ 'is-invalid': submitProject && projectDetail.controls.details.errors }"></textarea>
                                            <div *ngIf="submitProject && projectDetail.controls.details.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="projectDetail.controls.details.errors.required">Detail is
                                                    required</div>
                                            </div>
                                        </div>
                                        <div class="dragDropWrap">
                                            <app-files-upload (fileUploadedStatus)="getFileUploadStatus($event)"
                                                [fieldControlName]="'project_details'"
                                                [fieldNameErr]="'Project Detail Image'" [fieldSupports]="'image'"
                                                [checkChanges]="checkChanges" [checkAddMore]="checkAddMore"
                                                [checkRemove]="checkRemove" [checkEdit]="checkEdit"
                                                [formType]="formType" [fieldIndex]="detailIndex"
                                                [dynamicField]="true"></app-files-upload>
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-container>
                            <div class="textBtn-groups mt-3">
                                <a class="textBtn primary-text avenirBlack" (click)="addMoreProjectDetail()">+ Add more
                                    Details</a>
                            </div>
                        </div> -->
                        <!-- <hr /> -->

                        <div class="btn-groups justify-content-end">
                            <div class="spinner-border text-warning formLoader d-inline-block mr-4 inline-loader"
                                role="status" *ngIf="saveLoader">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <button type="button" class="btn btn-primary-border" (click)="onDiscardClicked()"
                                [disabled]="saveLoader">Discard</button>
                            <button class="btn btn-primary-border mr-2" type="button" (click)="onResetClicked()"
                                [disabled]="saveLoader">Reset</button>
                            <button type="button" class="btn btn-primary-border"
                                (click)="onAddProjectFormSubmit('save_draft')"
                                [disabled]="isSavedDraft || saveLoader">Save as draft</button>
                            <button type="submit" class="btn btn-primary" (click)="onAddProjectFormSubmit('add')"
                                [disabled]="saveLoader">Publish</button>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    </div>
</div>
