
<div class="new_UIstyle">
    <!-- banner section -->
        <div class="banner banner-gradient banner-course-assement">
            <div class="container">
                <div class="banner-content">
                    <div class="banner-content-content">
                        <p class="position-relative pos-top-10 mb-0" *ngIf="negative_marking_status"><span class="badge badge-light badge-primary avenirRoman m-0 mb-1" >Negative Marking</span></p>
                        <h2 class="white-text avenirBlack">
                            {{assessment_name | capitalization}}
                        </h2>
                    </div>
                    <div class="banner-content-action btn-groups">
                        <button class="btn btn-primary btn-w-120" *ngIf="role == 'student' && isTA == 'false'" (click)="go_to_url()">Back</button>
                        <button class="btn btn-primary btn-w-120" *ngIf="(role != 'student') || (role == 'student' && isTA == 'true') || (isTA == 'true')" (click)="go_to_list()">Back</button>
                    </div>
                </div>
            </div>
        </div>
    
        <section class="section userSubmissionWrap">
            <div class="container" [ngClass]="{submitting : loader}">
                <div class="text-center pt-2 pb-2">
                    <div class="spinner-border text-warning formLoader" role="status" *ngIf="loader">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>

                <div class="table-responsive userSubmissionTable" *ngIf="submissionResult.length > 0">
                    <table class="table  table-bordered">
                        <!-- <caption>List of users</caption> -->
                        <thead>
                          <tr>
                            <th scope="col" style="width: 300px;">Date and Time</th>
                            <th scope="col">Submission Name</th>
                            <th scope="col">Status</th>
                            <th scope="col">Score</th>
                            <th scope="col" style="width: 300px;">Feedback</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let us of submissionResult; let i = index;">
                            <td scope="row"> {{ us.submitted_time | date:'shortDate'}} At {{us.submitted_time | date:'shortTime'}}</td>
                            <td>Submisson {{i + 1}}</td>
                            <td class="primary-text">{{us.marked ? 'Marked' : 'Yet to be marked'}}</td>
                            <td>{{us.total_score}} / {{us.maximum_score}}</td>
                            <td><button class="btn btn-transparent btn-transparent-primary" (click)="getFeedBack(us)">Click Here</button></td>
                          </tr>
                        </tbody>
                      </table>
                </div>

                <div class="alert alert-info text-center"  *ngIf="submissionResult.length == 0">
                    <p>No Submission Found</p>
                </div>

            </div>
        </section>
</div>