<div class="pageBody subPage">
    <form name="setAvatar" enctype="multipart/form-data">
        <div class="text-center profActBtns mb-4">
            <button type="button" class="btn uploadBtn">
                <label><i class="fas fa-upload mr-2"></i>Upload Image</label>
                <input type="file" accept="image/*"  (change)="detectFiles($event)">
              </button>
        </div>
        <div class="text-center mb-4"><span class="orFl">or</span></div>
        <h4 class="text-center mb-3">Choose Avatars</h4>
        <div class="prSelection text-center">
            <div class="prInBox" *ngFor="let pc of prCarousel | slice:0:8;let i = index;">
                <div class="prBoxHldr">
                    <input type="radio" name="myPr" (change) = "detectAvatar(pc)">
                    <div class="prBoxWrap">
                        <div class="prBox">
                            <img [src]="pc.url" alt="" >
                        </div>
                    </div>                        
                </div>                    
            </div>
        </div>    
    </form>    
</div>