import { Component, OnInit, Input } from '@angular/core';
import { HandleUploadService } from 'src/app/shared/modules/shared/services/handle-upload.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ResourceService } from 'src/app/services/resources/resource.service';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-resource-upload',
  templateUrl: './resource-upload.component.html',
  styleUrls: ['./resource-upload.component.scss']
})
export class ResourceUploadComponent implements OnInit {

  @Input() fieldLabelName: string = '';	
  @Input() moduleName: string = '';
  @Input() fieldControlName: string = '';
  @Input() isRequiredField: boolean = false;
  submitFormSub!: Subscription;  
  formSubmitStatus: boolean = false;
  formData!: any;

  //FOR FILE UPLOAD PROGRESS STATUS
  multiFilesData: any[] = [];
  uploadedFilesData: any[] = [];

  //FOR EDIT FORM
  sharedDataSub!: Subscription;
  editData: any = null;

  constructor(private notificationService: NotificationService, private handleUploadService: HandleUploadService, private resourceService: ResourceService, private fb: FormBuilder) { }

  ngOnInit() {

  	this.submitFormSub = this.handleUploadService.submitFormStatus.subscribe((data: any) => {  		
  		this.formData = this.handleUploadService.addForm;
  		this.formSubmitStatus = data.formSubmitStatus;
  	});

    this.sharedDataSub = this.handleUploadService.getSharedData.subscribe((data: any) => {
      this.editData = data.editData;
      //FOR EDIT FORM
      this.setEditFormResource();
    });
  }

  ngAfterViewInit() {
    this.formData = this.handleUploadService.addForm;
  }

  resetFileField(event) {
  	event.target.value = '';
  }

  onFileDropped(event) {
    let files = event.files;
    this.uploadMultiResource(files, event);
  }

  onSelectFiles(event) {
    let files = event.target.files;
    this.uploadMultiResource(files, event);
  }

  uploadMultiResource(files, event) {
    this.multiFilesData = [];
    for (let i = 0; i < files.length; i++) {
      this.uploadSingleResource(i, files[i], 'file');
    }
  }

  uploadSingleResource(idx, file, type) {
    if (file) {
      const fileName = file.name;
      let progressBar = 0;
      let progressTime = 0;
      let startDate = new Date;

      this.notificationService.upload_resource(this.moduleName, file).subscribe(
        (response: HttpEvent<any>) => {
          switch (response.type) {
            case HttpEventType.Sent:
              break;
            case HttpEventType.ResponseHeader:
              break;
            case HttpEventType.UploadProgress:
              progressBar = Math.round(response.loaded / response.total * 100);

              //TIME CALCULATION
              let currDate = new Date;
              let timeElapsed: number = currDate.getTime() - startDate.getTime();
              let uploadSpeed = response.loaded / (timeElapsed / 1000);
              let seconds = (file.size - response.loaded) / uploadSpeed;
              progressTime = Math.round(seconds);

              this.multiFilesData[idx] = { name: fileName, progressBar: progressBar, progressTime: progressTime, uploadStatus: false };
              break;

            case HttpEventType.Response:
              this.notificationService.showNotification('success', 'File uploaded successfully');
              setTimeout(() => {
                progressBar = 0;
                progressTime = 0;
                this.multiFilesData[idx] = { name: fileName, progressBar: progressBar, progressTime: progressTime, uploadStatus: true };
                this.updateUploadedResources(this.multiFilesData[idx], response);
              }, 1000);
          }
        },
        (err) => {
          progressBar = 0;
          progressTime = 0;
          this.multiFilesData[idx] = { name: fileName, progressBar: progressBar, progressTime: progressTime, uploadStatus: false };
          this.notificationService.showNotification('info', 'Please add again not able to save your file');
        });
    }
  }

  updateUploadedResources(multiFile, response) {
    const resourceData = response.body.resource_info[0];
    const resourcePath = resourceData.path;
    const resourceId = resourceData.resource_id;
    const resourceUrl = resourceData.url;
    let resourceInfo = {
      resource_title: multiFile.name,
      resource_type: 'file',
      resource_id: resourceId,
      resource_url: resourceUrl,
      path: resourcePath,
      resource_deleted: false
    };
    this.uploadedFilesData.unshift(resourceInfo);
    //console.log(this.uploadedFilesData);

    //TO REMOVE RESOURCES
    this.handleUploadService.allUploadedFiles.push(resourcePath);

    //SET DATA INTO FORM FIELD
    this.manageUploadedFiles();
  }

  deleteUploadedResource(uploadedFileIndex) {
    if (confirm('Are you sure want to remove this ' + this.fieldLabelName + ' ? ')) {
      this.uploadedFilesData[uploadedFileIndex].resource_deleted = true;      
      this.manageUploadedFiles();
    }
    return;
  }

  manageUploadedFiles() {
    this.setResourcesFiles();
  }

  fieldFormArr(fieldControl) {
  	return this.handleUploadService.addForm.controls[fieldControl] as FormArray;
  }

  setResourcesFiles() {
    this.fieldFormArr(this.fieldControlName).clear();
   
    if (this.uploadedFilesData.length > 0) {
      this.uploadedFilesData.forEach((e, index) => {
        if (!e.resource_deleted) {
          this.addMoreResource();
          let resourceIndex = this.handleUploadService.addForm.controls[this.fieldControlName]['controls'].length - 1;
          this.handleUploadService.addForm.controls[this.fieldControlName]['controls'][resourceIndex].get('title').setValue(e.resource_title);
          this.handleUploadService.addForm.controls[this.fieldControlName]['controls'][resourceIndex].get('_id').setValue(e.resource_id);
          this.handleUploadService.addForm.controls[this.fieldControlName]['controls'][resourceIndex].get('url').setValue(e.resource_url);
          this.handleUploadService.addForm.controls[this.fieldControlName]['controls'][resourceIndex].get('type').setValue(e.resource_type);
        }
        else {
          this.handleUploadService.allDeletedFiles.push(e.path);
        }
      });
    }
  }

  addMoreResource() {
    this.fieldFormArr(this.fieldControlName).push(this.createResource());
  }

  createResource() {
    return this.fb.group({
      title: ['', Validators.required],
      _id: [''],
      url: [''],
      type: ['', Validators.required]
    });
  }

  //FOR EDIT FORM |START
  setEditFormResource() {
    this.fieldFormArr(this.fieldControlName).clear();

    if(this.editData[this.fieldControlName]) {
      this.uploadedFilesData = [];
      this.editData[this.fieldControlName].forEach((item, index) => {
        this.uploadedFilesData[index] = {};
        this.uploadedFilesData[index].resource_title = item.title;
        this.uploadedFilesData[index].resource_type = item.resource_type;
        this.uploadedFilesData[index].resource_id = item._id;
        this.uploadedFilesData[index].resource_url = item.s3url;
        this.uploadedFilesData[index].path = item.path;
        this.uploadedFilesData[index].resource_deleted = false;
      });
      //SET DATA INTO FORM FIELD
      this.manageUploadedFiles();
    }
  }
  //FOR EDIT FORM |END

  ngOnDestroy() {
    this.submitFormSub.unsubscribe();
    this.sharedDataSub.unsubscribe();
  }

}
