<div class="new_UIstyle">
	<!-- New Code -->
	<div class="pageBodyWrap">
		<!-- Banner Section Start -->
        <section class="banner banner-inner single-passion-banner"
            [style.background-image]="'url(' + ('assets/images/banner/mosaic-bg01.png') + ')'">
            <div class="container">
                <div class="banner-content">
                    <h2 class="white-text avenirBlack">{{passionProject?.title | capitalization }}</h2>
                    <div class="tags-badges">
                        <span class="badge badge-green-lt" *ngFor="let item of passionProject?.project_tags"
                            [ngStyle]="{'background-color': item.bgColor ? item.bgColor : 'white', 'color': true ? '#FFFFFF': '#FFFFFF'}">
                            {{item.tag }}
                        </span>
                    </div>
                    <div class="mt-4">
						<h4 class="white-text avenirBlack d-inline-block">Team: </h4>&nbsp;
						<h3 class="white-text avenirBlack d-inline-block">{{teamData?.name | capitalization}}</h3>
                    </div>
                </div>
                <button class="btn btn-primary" (click)="onGoBack()">
                    <i class="fas fa-arrow-left"></i> Back
                </button>
            </div>
        </section>
        <!-- Banner Section End -->

		<section class="section add-passProject-wrap">
			<div class="container">
				<form [formGroup]="addReportForm" class="typo-16" [ngClass]="{submitting : loader || saveLoader}">
					<div class="spinner-border text-warning formLoader" role="status" *ngIf="loader">
					    <span class="sr-only">Loading...</span>
					</div>
					<div class="mb-3 mb-md-4 text-center">
						<h4 class="avenirBlack primary-text d-inline-block mb-0">Submit Report</h4>
					</div>
					<div class="mb-3 mb-md-4">
						<label class="form-label avenirBlack mb-1">Report Title</label>
						<input type="text" formControlName="title" class="form-control form-control-lg form-control-style-2" [value]="addReportForm.get('title').value | capitalization" placeholder="Enter title" [ngClass]="{ 'is-invalid': submitReport && addReportForm.controls.title.errors }" />
						<div *ngIf="submitReport && addReportForm.controls.title.errors"
						    class="invalid-feedback">
						    <div *ngIf="addReportForm.controls.title.errors.required">Report Title is required
						    </div>
						</div>
					</div>

					<div class="mb-3 mb-md-4">
						<label class="form-label avenirBlack mb-1">Report Tags</label>
						<tag-input [formControlName]="'tags'"
						    class="form-control form-control-lg form-control-style-2" [addOnBlur]="true"
						    [clearOnBlur]="true" [secondaryPlaceholder]="'Enter tags'" [displayBy]="'tag'"
						    [identifyBy]="'_id'"
						    [ngClass]="{ 'is-invalid': submitReport && addReportForm.controls.tags.errors }">
						    <tag-input-dropdown [displayBy]="'tag'" [identifyBy]="'_id'"
						        [autocompleteObservable]="onInputTag">
						    </tag-input-dropdown>
						</tag-input>
						<div *ngIf="submitReport && addReportForm.controls.tags.errors"
						    class="invalid-feedback">
						    <div *ngIf="addReportForm.controls.tags.errors.required">Report Tags is
						        required</div>
						</div>
					</div>

					<div class="mb-3 mb-md-4">
						<label class="form-label avenirBlack">Report Description</label>
						<!-- <textarea class="form-control form-control-textarea form-control-style-2"
							placeholder="Enter description"></textarea> -->
						<div class="position-relative">
						    <textarea rows="6" cols="8" class="form-control form-control-style-2 form-control-lg"
						        [ngClass]="{ 'is-invalid': submitReport && addReportForm.controls.content.errors }"
						        placeholder="Enter description" formControlName="content" id="textReportDescription"></textarea>

						    <div *ngIf="submitReport && addReportForm.controls.content.errors"
						        class="invalid-feedback">
						        <div *ngIf="addReportForm.controls.content.errors.required">
						            Descrption is required
						        </div>
						    </div>
						</div>
					</div>

					<div class="mb-3 mb-md-4">
						<label class="form-label avenirBlack mb-1">Report Resources</label>
						<app-resource-upload [moduleName]="'passion_project'" [fieldControlName]="'resource_links'" [fieldLabelName]="'Report Resource'" [isRequiredField]="false"></app-resource-upload>
					</div>

					<div class="mb-3 mb-md-4">
						<label class="form-label avenirBlack mb-1">Report Webinars</label>
						<ng-container formArrayName="webinar_links">
							<div *ngFor="let reportWebinar of reportWebinars.controls; let webinarIndex = index;" class="border-box" [ngClass]="{'mt-4': webinarIndex != 0}">
								<div class="modal_cont float-right p-0">
								    <button type="button" class="close-btn lg-close" (click)="removeWebinar(webinarIndex)">
								        <i class="fa fa-trash" aria-hidden="true"></i>
								    </button>
								</div>
								<ng-container [formGroupName]="webinarIndex">
									<div class="mb-3 mb-md-4">
										<label class="form-label avenirBlack mb-1">Webinar Title</label>
										<input type="text" formControlName="title" class="form-control form-control-lg form-control-style-2" value="" placeholder="Enter title" [ngClass]="{ 'is-invalid': submitReport && reportWebinar.controls.title.errors }" />
										<div *ngIf="submitReport && reportWebinar.controls.title.errors"
										    class="invalid-feedback">
										    <div *ngIf="reportWebinar.controls.title.errors.required">Webinar Title is required</div>
										</div>
									</div>

									<div class="mb-3 mb-md-4">
										<div class="row">
											<div class="col-12 col-md-10">
												<input type="text" formControlName="url" class="form-control form-control-lg form-control-style-2" value="" [placeholder]="(reportWebinar.controls._id.value.length > 0) ? reportWebinar.controls._id.value : 'Enter a URL or upload file from your computer'" [ngClass]="{ 'is-invalid': submitReport && reportWebinar.controls.type.errors }" (input)="onInputUrl(webinarIndex, 'webinar_links')" />
											</div>
											<div class="col-12 col-md-2">
												<div class="dragDropUpload btn-groups float-right">
													<label class="custom-upload-btn btn btn-blue btn-shadow btn-icon-text">
														<input type="file" class="file-upload-input" [id]="'webinar'+webinarIndex" (change)="detectFiles($event, 'webinar_links', webinarIndex)" (click)="resetFileField($event)" />
														<span class=""><i class="fas fa-download"></i> Browse File</span>
													</label>
												</div>
											</div>
										</div>
										<div *ngIf="submitReport && reportWebinar.controls.type.errors"
										    class="invalid-feedback">
										    <div *ngIf="reportWebinar.controls.type.errors.required">Webinar URL or File is required</div>
										</div>
										<!-- UPLOADED FILE PROGRESS -->
										<div class="uploadedItemTop" *ngIf="getProgressBar('webinar_links', webinarIndex) > 0">
										    <div class="uploadedItemDetail">
										        <p class="uploadedItemStatus">
										          <small>{{getProgressBar('webinar_links', webinarIndex)}}% Complete</small>
										        </p>
										    </div>
										    <div class="uploadedItemAction">
										    </div>
										</div>
										<div class="uploadedItem">
										  <div class="progress uploadedProgress" *ngIf="getProgressBar('webinar_links', webinarIndex) > 0">
										    <div class="progress-bar uploadedProgressBlue"
										    role="progressbar" [style.width.%]="getProgressBar('webinar_links', webinarIndex)" aria-valuenow="progressBar"
										    aria-valuemin="0" aria-valuemax="100"></div>
										  </div>
										</div>
									</div>
								</ng-container>
							</div>
						</ng-container>
						<div class="textBtn-groups mt-3">
							<a class="textBtn primary-text avenirBlack" (click)="addMoreWebinar()">+ Add New Webinar</a>
						</div>
					</div>

					<div class="mb-3 mb-md-4">
						<label class="form-label avenirBlack mb-1">Code Repository</label>
						<ng-container formArrayName="code_links">
							<div *ngFor="let reportCode of reportCodes.controls; let codeIndex = index;" class="border-box" [ngClass]="{'mt-4': codeIndex != 0}">
								<div class="modal_cont float-right p-0">
								    <button type="button" class="close-btn lg-close" (click)="removeCode(codeIndex)">
								        <i class="fa fa-trash" aria-hidden="true"></i>
								    </button>
								</div>
								<ng-container [formGroupName]="codeIndex">
									<div class="mb-3 mb-md-4">
										<label class="form-label avenirBlack mb-1">Code Title</label>
										<input type="text" formControlName="title" class="form-control form-control-lg form-control-style-2" value="" placeholder="Enter title" [ngClass]="{ 'is-invalid': submitReport && reportCode.controls.title.errors }"/>
										<div *ngIf="submitReport && reportCode.controls.title.errors"
										    class="invalid-feedback">
										    <div *ngIf="reportCode.controls.title.errors.required">Code Title is required</div>
										</div>
									</div>

									<div class="mb-3 mb-md-4">
										<div class="row">
											<div class="col-12 col-md-10">
												<input type="text" formControlName="url" class="form-control form-control-lg form-control-style-2" value="" [placeholder]="(reportCode.controls._id.value.length > 0) ? reportCode.controls._id.value : 'Enter a URL or upload file from your computer'" [ngClass]="{ 'is-invalid': submitReport && reportCode.controls.type.errors }" (input)="onInputUrl(codeIndex, 'code_links')" />
											</div>
											<div class="col-12 col-md-2">
												<div class="dragDropUpload btn-groups float-right">
													<label class="custom-upload-btn btn btn-blue btn-shadow btn-icon-text">
														<input type="file" class="file-upload-input" [id]="'webinar'+codeIndex" (change)="detectFiles($event, 'code_links', codeIndex)" (click)="resetFileField($event)" />
														<span class=""><i class="fas fa-download"></i> Browse File</span>
													</label>
												</div>
											</div>
										</div>
										<div *ngIf="submitReport && reportCode.controls.type.errors"
										    class="invalid-feedback">
										    <div *ngIf="reportCode.controls.type.errors.required">Code URL or File is required</div>
										</div>
										<!-- UPLOADED FILE PROGRESS -->
										<div class="uploadedItemTop" *ngIf="getProgressBar('code_links', codeIndex) > 0">
										    <div class="uploadedItemDetail">
										        <p class="uploadedItemStatus">
										          <small>{{getProgressBar('code_links', codeIndex)}}% Complete</small>
										        </p>
										    </div>
										    <div class="uploadedItemAction">
										    </div>
										</div>
										<div class="uploadedItem">
										  <div class="progress uploadedProgress" *ngIf="getProgressBar('code_links', codeIndex) > 0">
										    <div class="progress-bar uploadedProgressBlue"
										    role="progressbar" [style.width.%]="getProgressBar('code_links', codeIndex)" aria-valuenow="progressBar"
										    aria-valuemin="0" aria-valuemax="100"></div>
										  </div>
										</div>
									</div>
								</ng-container>
							</div>
						</ng-container>
						<div class="textBtn-groups mt-3">
							<a class="textBtn primary-text avenirBlack" (click)="addMoreCode()">+ Add New Code</a>
						</div>
					</div>

					<div class="btn-groups justify-content-end">
						<div class="spinner-border text-warning formLoader d-inline-block mr-4 inline-loader"
						    role="status" *ngIf="saveLoader">
						    <span class="sr-only">Loading...</span>
						</div>
						<button type="button" class="btn btn-primary-border" [disabled]="saveLoader" (click)="onDiscardClicked()">Discard</button>
						<button class="btn btn-primary-border mr-2" type="button" (click)="onResetClicked()" [disabled]="saveLoader">Reset</button>
						<button type="submit" class="btn btn-primary" (click)="onAddReportFormSubmit('add')" [disabled]="saveLoader">Submit</button>
					</div>

				</form>
			</div>
		</section>
	</div>
</div>
