<!-- New Code -->
<div class="new_UIstyle">
    <!-- banner section -->
    <div class="banner banner-gradient banner-monitoring">
      <div class="container">
           <div class="banner-content">
               <div class="banner-content-content">
                   <h2 class="white-text avenirBlack"><small *ngIf="courseTitle">{{courseTitle}}:</small> <br /> Subscriber Notes</h2>
                   <p class="white-text">Session: {{sessionTitle}}</p>
                  </div>
               <div class="banner-content-action">
                    <button class="btn btn-primary" (click)="redirect()"> Back</button>
               </div>
           </div>
      </div>
   </div>


<div class="pageBody" [ngClass]="{submitting : loader}">
  <div class="container">
    <div class="clmLdr" *ngIf="loader">
      <div class="spinner-border text-warning formLoader" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div class="table-responsive" *ngIf="sessionNotes.length > 0">
      <table class="table alt" id = "tableid">
        <thead>
          <tr>
            <th class="text-center w30p">#</th>
            <th class="text-center w300p">Student Name</th>
            <th class="text-center">Notes</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let sessionNote of sessionNotes;let i = index" id = "tr{{i}}" [ngClass] = "getActiveTr(sessionNote._id)?'activeTr':''">
            <td class="text-center">{{i+1}}</td>
            <td class="text-center">
              <p class="m-0">{{sessionNote?.first_name | titlecase }} {{sessionNote?.last_name | titlecase}}</p>
              <span class="badge badge-secondary">{{sessionNote?.organisation | titlecase}}</span>
            </td>
            <td>
              <div class="notesSlideWrap">
                <owl-carousel-o [options]="customOptions">

                  <ng-container *ngFor="let note of sessionNote.notes">
                    <ng-template carouselSlide [id]="note._id">
                      <div class="noteSlOuter">
                        <div class="noteSlideBox">
                          <h3 class="bxTitle noteBxTitle text-truncate avenirBlack" *ngIf="note?.title">{{note?.title}}</h3>
                          <div class="bodyHTML" [innerHTML]="note?.content"></div>
                        </div>
                      </div>
                    </ng-template>
                  </ng-container>

                </owl-carousel-o>
              </div>
            </td>
          </tr>

        </tbody>
      </table>
    </div>
    <div class="text-center" *ngIf="sessionNotes.length == 0">No data found</div>
  </div>
</div>
</div>