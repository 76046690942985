<div class="new_UIstyle">
    <div class="pageBodyWrap pb-5">
        <!-- BANNER |START -->
        <div class="banner banner-gradient banner-calendar">
            <div class="container">
                <div class="banner-content">
                    <div class="banner-content-content">
                        <h2 class="white-text avenirBlack">{{courseTitle}}: <br />
                            {{courseworks?.title}}
                        </h2>
                        <p class="white-text" *ngIf="courseworks?.is_group">Group Assignment:
                            <span class="manual_ass">
                                <strong class="ms-2 primary-text ml-5">
                                    {{courseworks?.group_type | capitalization}}
                                </strong>
                            </span>
                        </p>
                        <p class="white-text">Coursework Start Date:
                            <span class="manual_ass">
                                <strong class="ms-2 primary-text ml-3">
                                    {{courseworks?.date | dateFormatFullmonth}}
                                </strong>
                            </span>
                        </p>
                        <p class="white-text">Start Time:
                            <span class="manual_ass">
                                <strong class="ms-2 primary-text coursework-starttime">
                                    {{courseworks?.time}}
                                </strong>
                            </span>
                        </p>
                        <p class="white-text">Status:
                            <span *ngIf="courseworks?.title" class="manual_ass">
                                <strong class="ms-2 primary-text coursework-submission-status">{{courseworks.publish ==
                                    true ? 'Marked' : 'Yet to be marked'}}</strong>
                            </span>
                        </p>
                    </div>
                    <div class="banner-content-action btn-groups">
                        <button class="btn btn-primary" (click)="onGoBack()">
                            <i class="fas fa-arrow-left"></i> Back</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- BANNER |END -->

        <!-- COURSEWORK DESCRIPTION |START -->
        <div class="row p-5">
            <div class="col-md-12 m-auto">
                <div class="container" [ngClass]="{submitting : loader}">
                    <div class="spinner-border text-warning formLoader" role="status" *ngIf="loader">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <p [innerHTML]="courseworks?.course_work_description"></p>
                </div>
            </div>
        </div>
        <!-- COURSEWORK DESCRIPTION |END -->

        <!-- My RESOURCES |START -->
        <div class="container">
            <div class="learning-course-items-wrap learning-course-resources-wrap mb-5"
                *ngIf="courseworks && courseworks?.course_work_resources?.length > 0">
                <!-- <h5 class="primary-text avenirBlack">Resources</h5> -->
                <div class="learning-course-item" *ngFor="let resource of courseworks?.course_work_resources">
                    <div class="learning-course-link">
                        <div class="learning-course-elem-left">
                            <p class="learning-course-link-title">
                                <span class="title-icon">
                                    <i class="fas fa-folder"></i></span>
                                <strong>{{resource.resource_title}}</strong>
                            </p>
                        </div>
                        <div class="learning-course-elem-right">
                            <ul class="list-icon-wrap">
                                <li>
                                    <a href="javascript:void(0);" class="learning-course-download"
                                        (click)="openResource(resource.resource_url)">
                                        <i class="fas fa-download"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- My RESOURCES |END -->

        <!-- MARKING TEAM |START -->
        <hr *ngIf="markingTeamInfo.length > 0" class="mb-0">
        <div class="container" *ngIf="markingTeamInfo.length > 0">
            <div class="course-work-detail-elem course-work-teams-detail-box">
                <div class="courseWork-teams-box course-work-my-team" *ngFor="let courseworkTeam of markingTeamInfo">
                    <div class="course-work-createGroup">
                        <h5 class="team_in avenirBlack primary-text mb-0">
                            <b> {{courseworkTeam.name | titlecase}} </b>
                        </h5>
                    </div>
                    <div class="team-list-box grey-Box member pt-4 mt-4">
                        <div class="row team_inner">
                            <div class="col-lg-8 col-md-6">
                                <p class="avenirBlack primary-text mb-3">Members name</p>
                            </div>
                            <div class="col-lg-4 col-md-6 team_memb text-right">
                            </div>
                        </div>

                        <div class="row gy-3 member_data">
                            <div class="col-lg-4 col-md-6 team-list-elemBox mt-4" *ngFor="let member of courseworkTeam.members">
                                <div class="Instructor-profile">
                                    <app-default-icon *ngIf="!member.profile_pic" [name]="member.name" [figureClass]="''"></app-default-icon>
                                    <figure *ngIf="member.profile_pic" class="Instructor-pro-fig">
                                        <img src="{{!member.profile_pic ? userDefaultPic: member.profile_pic}}" alt="dp-4.png" />
                                    </figure>
                                    <div class="Instructor-pro-content">
                                        <h6 class="avenirBlack">{{member.name}}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- MY TEAMS |END-->

        <!-- MARKING USER |START -->        
        <div class="container" *ngIf="markingUserInfo.length > 0">
            <hr class="mb-0">
            <div class="course-work-detail-elem course-work-teams-detail-box">
                <div class="courseWork-teams-box course-work-my-team" *ngFor="let courseworkUser of markingUserInfo">
                    <div class="row gy-3 member_data">
                        <div class="col-lg-4 col-md-6">
                            <div class="Instructor-profile">
                                <app-default-icon *ngIf="!courseworkUser.profile_pic" [name]="courseworkUser.profile_name" [figureClass]="''"></app-default-icon>
                                <figure *ngIf="courseworkUser.profile_pic" class="Instructor-pro-fig">
                                    <img src="{{!courseworkUser.profile_pic ? userDefaultPic: courseworkUser.profile_pic}}" alt="dp-4.png" />
                                </figure>
                                <div class="Instructor-pro-content">
                                    <h6 class="avenirBlack">{{courseworkUser.profile_name}}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- MARKING USER |END-->

        <!-- MY SUBMISSIONS START -->
        <div class="container">
            <hr *ngIf="courseworks_submission.length > 0">
            <div class="course-work-detail-elem course-work-submission-wrap" *ngIf="courseworks_submission.length > 0">
                <h5 class="avenirBlack primary-text">Submissions</h5>
                <ngb-accordion #submissions="ngbAccordion" [closeOthers]="true" activeIds="active"
                    class="accordionCustom">
                    <ngb-panel *ngFor="let course_works of courseworks_submission; let i = index">

                        <ng-template ngbPanelHeader>
                            <div class="d-flex panelHeaderButton panelHeaderActionBtn">
                                <div class="panelHeaderElem panelHeaderLeft">
                                    <button ngbPanelToggle class="ngbPanelToggleBtn" id="ngbPanelToggle1">
                                        <span class="acc-headerTitle h6 avenirBlack d-block mb-0">
                                            {{course_works?.submission_report}}
                                        </span>
                                        <div class="float-left mt-2">
                                            <span>Marks:</span> <strong class="marks_course primary-text">
                                                {{course_works?.total_number_of_percentage}}%
                                            </strong>
                                        </div>
                                        <div class="learning-course-elem-right">
                                            <p class=" mb-0">Deadline:
                                                <strong class="primary-text">
                                                    {{getExpireDate(courseworks.date,course_works?.days_of_completion)
                                                    | dateFormatFullmonth}}
                                                </strong>
                                            </p>
                                        </div>
                                        <span class="acc-headerAngel"><i class="fas fa-angle-down"></i></span>
                                    </button>
                                </div>
                            </div>
                        </ng-template>

                        <ng-template ngbPanelContent>
                            <div class="panelContentWrapNoScroller">
                                <div class="panelHeaderElem rubric_des">
                                    {{course_works.rubric}}
                                </div>
                                <div class="selector_data">
                                    <section class="contentBlocks">
                                        <!-- USER UPLOADED WORK -->
                                        <app-course-work-marking-files [reportIndex]="i" [userId]="userId"
                                            [teamName]="teamName" [showCourseWork]="course_works"
                                            [courseworks]="courseworks" [courseId]="courseId"
                                            [teachingAssistant]="showCourseData.course_info.teach_assis"
                                            [reviewSavedStatus]="reviewSavedStatusData"
                                            (sendSubmissionData)="getSubmissionData($event)">
                                        </app-course-work-marking-files>
                                    </section>
                                </div>
                            </div>

                            <!-- USER GRADE REMARK -->
                            <app-course-work-marking-remark [reportIndex]="i" [userId]="userId" [teamName]="teamName" [isSuperAdmin]="isSuperAdmin" [isTeachingAssistant]="isTeachingAssistant" [isPublishRight]="isPublishRight"
                                [showCourseWork]="course_works" [courseworks]="courseworks" [courseId]="courseId"
                                [teachingAssistant]="showCourseData.course_info.teach_assis"
                                [submissionData]="viewSubmissionData"
                                (sendReviewSavedStatus)="getReviewSavedStatus($event)">
                            </app-course-work-marking-remark>

                        </ng-template>

                    </ngb-panel>
                </ngb-accordion>
            </div>
        </div>
        <!-- MY SUBMISSIONS END -->

    </div>
</div>
