<!-- New Code -->
<div class="new_UIstyle">
    <div class="pageBodyWrap">

        <!-- banner section -->
        <div class="banner banner-gradient banner-C-assessment"
            style="background-image:url('../../../assets/images/banner/banner-question.png');">
            <div class="container">
                <div class="banner-content">
                    <div class="banner-content-content">
                        <h2 class="white-text avenirBlack">Question</h2>
                        <!-- <p class="white-text">Group Assignment: <strong class="ms-2 primary-text">Student - Led</strong></p> -->
                        <!-- <div class="tags-badges">
                     <span class="badge badge-light badge-primary">Draft</span>
                 </div> -->
                    </div>
                    <div class="banner-content-action btn-groups">
                        <button class="btn btn-primary" routerLink="/question-bank"><i class="fa fa-arrow-left"></i>
                            Back</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- banner section -->

        <section class="section addQuestionWrap">
            <div class="container" [ngClass]="{submitting : loader}">
                <div class="text-center pt-2 pb-2">
                    <div class="spinner-border text-warning formLoader" role="status" *ngIf="loader">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <div class="questionDetailWrap">
                    <div class="course-assessment-qsn  mb-3" *ngIf="resDetails?.questype.type != 'Text' && resDetails?.questype.type != 'Fill in the blanks'" >
                        <!-- <span class="badge badge-green-lt mb-2">Marks: 60</span> -->
                        <h5 class="avenirBlack">
                           <div [innerHTML]="resDetails?.question" ></div>
                           <span class="number-icon">1 </span>
                        </h5>
                        <div class="row gy-2">                   
                                    <div class="col-md-6 custom-control custom-radio mb-2"
                                        *ngFor="let option of resDetails?.meta_data;let ii = index">
                                        <input type="radio" [value]="option.option"
                                            class="form-check-input custom-control-input me-2"  readonly>
                                        <label class="form-check-label custom-control-label" >
                                            <div class="d-inline-block vTop"
                                                [innerHTML]="option.option"></div>
                                        </label>
                                    </div>
                        </div>
                        <p class="dark-green-text mb-5" ><strong>Correct Answer: <span
                                    class="primary-text" [innerHTML]="resDetails?.correct_answer"></span></strong></p>
                    </div>

                </div>
              
                <div class="questionDetailWrap">
                    <div class="course-assessment-qsn  mb-3" *ngIf="resDetails?.questype.type == 'Text' && resDetails?.questype.type != 'Fill in the blanks'" >
                       
                        <!-- <span class="badge badge-green-lt mb-2">Marks: 60</span> -->
                        <h5 class="avenirBlack">
                           <div [innerHTML]="resDetails?.question"></div>
                           <span class="number-icon">1 </span>
                        </h5>
                        <label class="avenirBlack dark-green-text">Correct Answer:</label> 
                        <div class="form-control form-control-textarea" [innerHTML]="resDetails?.correct_answer"></div> 
                        <!-- <p class="dark-green-text mt-3" ><strong>Correct Answer: <span
                                    class="primary-text" [innerHTML]="resDetails?.correct_answer"></span></strong></p> -->
                    </div>

                </div>


                <div class="questionDetailWrap">
                    <div class="course-assessment-qsn  mb-3" *ngIf="resDetails?.questype.type == 'Fill in the blanks'" >
                       
                        <!-- <span class="badge badge-green-lt mb-2">Marks: 60</span> -->
                        <h5 class="avenirBlack">
                           <div [innerHTML]="resDetails?.question"></div>
                           <span class="number-icon">1 </span>
                        </h5>
                        <label class="avenirBlack dark-green-text">Correct Answer:</label> 
                        <div class="form-control form-control-textarea" [innerHTML]="resDetails?.correct_answer"></div> 
                        <!-- <p class="dark-green-text mt-3" ><strong>Correct Answer: <span
                                    class="primary-text" [innerHTML]="resDetails?.correct_answer"></span></strong></p> -->
                    </div>

                </div>
            </div>
        </section>

    </div>
</div>

