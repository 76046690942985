import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileUploadModule } from 'primeng/fileupload';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TagInputModule } from 'ngx-chips';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { youtubeId } from '../pipe/youtubeId.pipe';
import { Ng2SearchPipeModule } from 'ng2-search-filter';	
import { CommonAppModuleModule } from '../common-app-module/common-app-module.module';

import { ProjectsListComponent } from './projects-list/projects-list.component';
import { DiscoverProjectsComponent } from './projects-list/discover-projects/discover-projects.component';
import { SharedModule } from '../shared/modules/shared/shared.module';
import { ProjectViewComponent } from './project-view/project-view.component';
import { NgxPaginationModule } from 'ngx-pagination';

import {
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
} from '@angular/material';

import { CarouselModule } from 'ngx-owl-carousel-o';
import { ProjectAddComponent } from './project-add/project-add.component';
import { TeamCreateComponent } from './team-create/team-create.component';
import { SelectMentorsComponent } from './project-add/select-mentors/select-mentors.component';
import { TeamViewComponent } from './team-view/team-view.component';
import { ProjectReportAddComponent } from './project-report-add/project-report-add.component';
import { TeamChatComponent } from './team-view/team-chat/team-chat.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: ProjectsListComponent
      },
      {
        path: 'add',
        component: ProjectAddComponent
      },
      {
        path: 'view',
        component: ProjectViewComponent
      },
      {
        path: 'team',
        component: TeamViewComponent
      },
      {
        path: 'team/add-report',
        component: ProjectReportAddComponent
      },
      {
        path: '**',
        redirectTo: '/passion-projects',
        pathMatch: 'full'
      }
    ]
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    FileUploadModule,
    NgSelectModule,
    NgbModule,
    TagInputModule,
    YouTubePlayerModule,
    Ng2SearchPipeModule,
    CommonAppModuleModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    NgxPaginationModule,
    CarouselModule,
    SharedModule
  ],
  declarations: [
    youtubeId,
    ProjectsListComponent,
    DiscoverProjectsComponent,
    ProjectViewComponent,
    ProjectAddComponent,
    TeamCreateComponent,
    SelectMentorsComponent,
    TeamViewComponent,
    ProjectReportAddComponent,
    TeamChatComponent
  ],
  exports: [
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FileUploadModule,
    NgbModule,
    NgSelectModule,
    YouTubePlayerModule,
    youtubeId,
    Ng2SearchPipeModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
  ],
})
export class PassionProjectsModule { }
