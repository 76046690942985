import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  redirectUrl: string;
  isLoggedIn = new BehaviorSubject<boolean>(false);
  constructor(private router: Router) {
    
  }
get isLogin():boolean{
  if (localStorage.getItem("user_id")) {
   return true;
  } else {
   return  false;

  }
}

}

