import { Component, OnInit } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { NotificationService } from '../services/notification.service';
import { ResourceService } from '../services/resources/resource.service';

@Component({
  selector: 'app-marking',
  templateUrl: './marking.component.html',
  styleUrls: ['./marking.component.scss']
})
export class MarkingComponent implements OnInit {
  searchTextChanged = new Subject<string>();
  subscription
  results = [];
  constructor(private resource_service: ResourceService, private notify: NotificationService) {

  }


  ngOnInit(): void {
  }

  serInput
  setInd(index) {
    this.serInput = index;
    this.results = [];
    // filter search for food items
    const typeahead = fromEvent<KeyboardEvent>(document.querySelector(('#resource')) as HTMLInputElement, 'input').pipe(
      map((e: KeyboardEvent) => { return (<HTMLInputElement>e.target).value }),
      filter(text => text.length > 2),
      debounceTime(500),
      distinctUntilChanged(),
    );

    typeahead.subscribe(keyword => {
      const data = keyword
      this.resource_service.serach_resource_elastic(data).subscribe(resource => {
        console.log(resource.length);
        if (resource.length > 0) {
          this.results = resource;
        } else {
          this.results = [];
          this.results = [{
            description: "",
            id: "",
            tags: [],
            title: data,
            type: "text",
            url: data,
          }]

          console.log(this.results);
          
        }
        // if (data['responseCode'] == 1) {
        //   if(data['errorMessage'] == "no items"){
        //     this.results = [{
        //       id:'',
        //       misc:["small", " regular", " large"],
        //       name: keyword + ' (Add New)',
        //       serving_unit:'bowl,Piece,Plate,Packet,Glass,cup,Number,Tablespoon,Serving,Slice,Tetrapack,dish'
        //     }];
        //   }else{
        //     this.results = data['data'];
        //   }
        // } else {
        //   this.notify.showNotification("error", "Something went wrong");
        // }
      },
        error => {
          this.notify.showNotification("error", "Something went wrong");
        });
    });
  }

  //FOR BUILD FIX
  i: any = 0;
  myControl: any = '';

}
