<!-- <div class="d-none">
  <div class="banner" >
    <div class="container text-center">
      <h1>{{courseAssessmentData?.title | titlecase}}</h1>
      <button type="button" class="btn btn-primary" ngbTooltip="Go Back" (click) = "redirect()"><i class="fas fa-chevron-left"></i></button>
    </div>
  </div>
  <div class="pageBody" [ngClass]="{submitting : loader}">
    <div class="spinner-border text-warning formLoader" role="status" [ngClass]="{submitting : loader}" *ngIf="loader">
      <span class="sr-only">Loading...</span>
    </div>
    <div class="container" *ngIf = "respData.length > 0">

      <div class="resultMainWrap contentBlocks" *ngFor="let data of respData;let i = index;">
        <div class="row no-gutters">
          <div class="col-12 col-lg-7 py-4 pr-lg-4">
              <h3><span>Question {{i+1}}</span></h3>
              <p><strong [innerHTML]="data.question"></strong></p>
              <div class="pt-2" *ngFor="let option of data.options;let ii = index;">              
                <dl class="optFld">
                  <dt><span [innerHTML]="getAlphabet(ii+1)"></span>)</dt>
                  <dd>
                    <div [innerHTML]="option.value"></div>
                  </dd>
                </dl>
              </div>
          </div>
          <div class="col-12 col-lg-5 p-4">
            <div class="d-block ngChartHldr">
              <ngx-charts-advanced-pie-chart [view]="view" [scheme]="colorScheme" [results]="data.graph_data"
                [gradient]="gradient" (select)="onSelect($event)" (activate)="onActivate($event)"
                (deactivate)="onDeactivate($event)">
              </ngx-charts-advanced-pie-chart>
              <div class="usrIGWrapHldr">
                <div class="usrIcoWGraph" [ngStyle]="{'border-color':getColor(i)}" *ngFor="let option of data.options;let i = index;">
                  <div class="lftG">
                    <span class="numb" [innerHTML] = "data.graph_data[i].value"></span>
                    <span [innerHTML]="getAlphabet(i+1)"></span>
                  </div>
                  <div class="usrIcWrap">
                    <span class="userIco" ngbTooltip="{{student?.first_name | titlecase }} {{student?.last_name | titlecase}}" *ngFor = "let student of option.students">{{student.short_name | uppercase}}</span>                 
                  </div>
                </div>
              </div>
            </div>            
          </div>
        </div>
      </div>

    </div>
    <div class="container text-center" *ngIf = "respData.length < 1">
      <h1><i class="fas fa-chart-pie mb-3"></i></h1>
      <strong>No Data Found</strong>
    </div>
  </div>
</div> -->


<!-- new UI graph -->

<div class="new_UIstyle">
  <!-- banner section -->
      <div class="banner banner-gradient banner-course-assement">
          <div class="container">
              <div class="banner-content">

                  <div class="banner-content-content">
                      <h2 class="white-text avenirBlack">{{assessmentName}}</h2>
                  </div>
                  <div class="banner-content-action btn-groups">
                      <button class="btn btn-primary btn-w-120"  (click)="go_to_url()">Back to Assessments</button>
                  </div>
              </div>
          </div>
      </div>
  
      <section class="section">
          <div class="container" [ngClass]="{submitting : loader}">
            <div class="row" *ngIf="last_Update_at">
              <div class="col-12 text-right">
                <p>Last updated on: <b>{{last_Update_at}}</b></p>
              </div>
            </div>

            <div class="text-center pt-2 pb-2">
                  <div class="spinner-border text-warning formLoader" role="status" *ngIf="loader">
                      <span class="sr-only">Loading...</span>
                  </div>
              </div>

              <div class="assessment-graph-wraper border">
                
                  <div class="row">
                      <div class="col-12 col-lg-6">
                          <div class="assessment-graph-col assessment-graph-info">
                               <div class="assessment-graph border-bottom">
                                <ngx-charts-pie-chart
                                [results]="graphData"
                                [view]="view"
                                [scheme]="colorScheme"
                                [gradient]="gradient"
                                [legend]="showLegend"
                                [legendTitle]="legendTitle"
                                [legendPosition]="legendPosition"
                                [labels]="showLabels"
                                [doughnut]="isDoughnut">
                              <ng-template #tooltipTemplate let-model="model">
                                <p class="mb-0">
                                  <strong>{{model.name}}</strong>
                                </p>
                                <p class="mb-0">{{model.value}}%</p>
                              </ng-template>
                              <!-- (select)="onSelect($event)"
                              (activate)="onActivate($event)"
                              (deactivate)="onDeactivate($event)" -->
                              </ngx-charts-pie-chart>
                              
                               </div>
                               <div class="assessment-graph-students">
                                  <div class="row">
                                      <div class="col-6 pr-0">
                                        <div class="assessment-graph-students-col assessment-graph-students-attemped border-right">
                                            <h6 class="avenirBlack">Attempted</h6>
                                            <ul class="assessment-graph-student-attendance">
                                                <li *ngFor="let attemtedStudent of attemtedStudentList">{{attemtedStudent.name}} {{attemtedStudent.last_name}}</li>
                                            </ul>
                                            <div  *ngIf="attemtedStudentList.length == 0">
                                              <div class="alert alert-info text-center p-1">
                                               <p>0 Attempted.</p>
                                              </div>
                                            </div>
                                        </div>
                                      </div>
                                      <div class="col-6 pl-0">
                                        <div class="assessment-graph-students-col assessment-graph-students-missed">
                                          <h6 class="avenirBlack">Missed</h6>
                                            <ul class="assessment-graph-student-attendance">
                                              <li *ngFor="let missedStudent of missedStudentList">{{missedStudent.name}} {{missedStudent.last_name}}</li>
                                            </ul>
                                            <div *ngIf="missedStudentList.length == 0">
                                              <div class="alert alert-info text-center p-1">
                                               <p>0 Missed.</p>
                                              </div>
                                            </div>
                                        </div>
                                      </div>
                                  </div>
                               </div>
                          </div>
                      </div>
                      <div class="col-12 col-lg-6 ">
                        <div class="assessment-graph-col assessment-graph-questions pr-2 pl-2 pr-lg-0 pl-lg-0">
                           <div class="questionsRow mb-5" *ngFor="let ques of graphAssessmentQUS; let i = index">
                              <div class="row mb-2">
                                  <div class="col-md-4">
                                       <p class="grey-text">Question {{i + 1}}</p>
                                  </div>
                                  <div class="col-md-8">
                                       <ul class="row list-horiz d-flex flex-wrap">
                                          <li class="col-4"><p class="grey-text"><span class="dotStatus greenDot"></span> Correct {{ques.correct_count}}</p></li>
                                          <li class="col-4"><p class="grey-text"><span class="dotStatus redDot"></span> Incorrect {{ques.incorrect_count}}</p></li>
                                          <li class="col-4"><p class="grey-text"><span class="dotStatus yellowDot"></span> Missed {{ques.missed_count}}</p></li>
                                       </ul>
                                  </div>
                              </div>
                               <div class="row">
                                  <div class="col-12">
                                       <p class="avenirBlack">Question {{i +1}}. {{ques.question_title}}</p>
                                       <ul class="list-vertical">
                                         <li *ngFor="let option of ques.options">
                                          <div class="quesWrap" *ngIf="ques.question_type !== 'text' && ques.question_type !== 'fib'"><span class="dotStatus" [ngClass]="option.answer == true ? 'greenDot': ''"></span><div  class="quesData" [innerHtml]="option.option"></div></div>
                                          <div class="quesWrap" *ngIf="ques.question_type == 'text' || ques.question_type == 'fib'"><span class="dotStatus greenDot"></span><div class="quesData" [innerHtml]="option.answer"></div></div>
                                        </li>
                                       </ul>
                                  </div>
                               </div>
                           </div>
                           <div class="pr-4" *ngIf="graphAssessmentQUS.length == 0">
                             <div class="alert alert-info text-center" >
                              <p> There is no question found.</p>
                             </div>
                           </div>
                         </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
</div>
