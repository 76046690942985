import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { NotificationService } from 'src/app/services/notification.service';
import { UserService } from 'src/app/services/user.service';
import { finalize } from 'rxjs/operators';
import { HttpEventType } from '@angular/common/http';

@Component({
  selector: 'app-avatar-library',
  templateUrl: './avatar-library.component.html',
  styleUrls: ['./avatar-library.component.scss']
})
export class AvatarLibraryComponent implements OnInit {

  deskWidth: number;
  private innerWidth: number;
  private desktopBreakpoint = 1199;
  private tabletBreakpoint = 767;
  @Output() avatarPathValue = new EventEmitter();
  prCarousel = [];
  loader: boolean;
  ci = 2;

  constructor(private ns: NotificationService, private userService: UserService,) { }

  ngOnInit() {
    this.adjustWidth();
    this.fetchAvatars();
  }

  fetchAvatars() {
    this.loader = true;
    this.userService.getAvatars().pipe(
      finalize(() => {
        this.loader = false;
      })).subscribe((res) => {
        this.prCarousel = res.resource_info
        // this.userTypes = res.data;
      })
  }

  private adjustWidth() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth > this.desktopBreakpoint) {
      this.deskWidth = 600;
    }
    else if (this.innerWidth > this.tabletBreakpoint) {
      this.deskWidth = 400;
    } else {
      this.deskWidth = 200;
    }
  }

  detectFiles(event) {
    this.upload_resource('profile-avatar', event.target.files[0]);
  }

  detectAvatar(data) {
    this.avatarPathValue.emit(JSON.stringify(data));
  }
  progressBar = 0;
  upload_resource(modelName, file) {
    let resource_data;
    this.loader = true;
    this.ns.upload_resource(modelName, file).subscribe(res => {
      this.loader = false;
      switch (res.type) {
        case HttpEventType.Sent:
          break;
        case HttpEventType.ResponseHeader:
          break;
        case HttpEventType.UploadProgress:
          this.progressBar = Math.round(res.loaded / res.total * 100);
          break;
        case HttpEventType.Response:
          if (res.body.resource_info.length > 0) {
            resource_data = res.body.resource_info[0];
            this.ns.showNotification('success', res.body.message);
            this.avatarPathValue.emit(JSON.stringify(resource_data));
          }
      }

      setTimeout(() => {
        this.progressBar = 0;
      }, 1500);

    },
      (err) => {
        this.loader = false;
        this.ns.showNotification('info', 'Please add again not able to save your file');
      })
  }


  // upload_resource(modelName, file, type, index) {
  //   let resource_data;
  //   this.loader = true;
  //   this.upload_index = index
  //   this.ns.upload_resource(modelName, file).subscribe(res => {
  //     this.loader = false;
  //     switch (res.type) {
  //       case HttpEventType.Sent:
  //         break;
  //       case HttpEventType.ResponseHeader:
  //         break;
  //       case HttpEventType.UploadProgress:
  //         this.progressBar = Math.round(res.loaded / res.total * 100);
  //         break;
  //       case HttpEventType.Response:
  //         if (res.body.resource_info.length > 0) {
  //           resource_data = res.body.resource_info[0];
  //           this.ns.showNotification('success', res.body.message);
  //           this.editProjectForm.controls.project_resources['controls'][index].controls.type.setValue('file');
  //           this.editProjectForm.controls.project_resources['controls'][index].controls._id.setValue(resource_data.resource_id);
  //           this.editProjectForm.controls.project_resources['controls'][index].controls.url.setValue('');
  //         }
  //     }
  //     setTimeout(() => {
  //       this.progressBar = 0;
  //     }, 1500);
  //   },
  //     (err) => {
  //       this.loader = false;
  //       this.ns.showNotification('info', 'Please add again not able to save your file');
  //     })
  // }
}
