export class UserRole {
    private role: string;
    public roleList = {
        mentor:             'mentor',
        teacher:            'teacher',
        student:            'student',
        superAdmin:         'super_admin',
        tutor:              'tutor',
        resourceAnalyst:    'resource_analyst',
        schoolAdmin:        'school_admin'
    };

    constructor() {
        this.role = localStorage.getItem('role');
    }

    get userRole(): string {
        return this.role;
    }

    get permissions() {
        return JSON.parse(localStorage.getItem("permissions"));
      }

    public matchRole(role: string): boolean {
        return this.role === this.roleList[role] ? true : false;
    }

    public create_permission(project) {
        let permission = this.permissions;
        let _id = localStorage.getItem('user_id');
    
        if (project == 'add_project') {
          if (this.userRole == 'super_admin') {
            return true;
          } else if (permission.indexOf("passion_project") > -1) {
            return true
          }
        } else {
          if (permission.indexOf("passion_project") > -1 && project.created_by._id == _id) {
            return true;
          } else if (permission.indexOf("passion_project") > -1 && project.project_mentors.findIndex(p => { p.id == _id }) > -1) {
            return true;
          }
        }
        if (this.userRole == 'super_admin') {
          return true;
        }
      }
    
}
