import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { PermissionService } from '../../services/permission.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CourseService } from 'src/app/services/course.service';
import { NotificationService } from '../../services/notification.service';
import { environment } from '../../../environments/environment';
import { NgbDateStruct, NgbModal, NgbModalRef, NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators, NgForm, FormArray } from '@angular/forms';
import { HttpEvent, HttpEventType } from '@angular/common/http';

@Component({
  selector: 'app-course-work-detail',
  templateUrl: './course-work-detail.component.html',
  styleUrls: ['./course-work-detail.component.scss']
})
export class CourseWorkDetailComponent implements OnInit {
  @ViewChild('createGroup', { static: false }) createGroup: TemplateRef<any>; // Reference to the template variable

  loader: boolean = false;
  courseName: string = '';
  courseId: string = '';
  courseWorkId: string = '';
  topicId: string = '';
  courseworks: any = {};
  courseData: any = {};
  courseTitle: string = '';
  isTopicCoursework: boolean = false;

  groupProcessing: boolean = false;
  courseworkTeaminfo: any = [];
  userDefaultPic: string = environment.imageBasePath + environment.userDefaultProfilePic;
  createGroupModal: NgbModalRef;
  addTeamByStudnet: FormGroup;
  subscribeStudentList = [];
  addedMembers: any = [];
  resetTeam: boolean = false;
  groupActionName = 'Create';
  editCourseworkTeam: any = {};
  uploadedImg: string = '';
  resourcePath: string = '';
  role: string = '';
  showCreateGroup: boolean = false;
  isTeachingAssistant: boolean = false;

  constructor(private permissionService: PermissionService, private route: ActivatedRoute, private router: Router, private courseService: CourseService, private notificationService: NotificationService, private modalService: NgbModal, private fb: FormBuilder) { }

  ngOnInit() {
    const role = localStorage.getItem('role');
    this.role = role;

    this.route.queryParams.subscribe(params => {
      this.courseWorkId = params.courseWork;
      this.courseId = params.course_id;
      this.courseName = params.courseWork;
      this.topicId = params.topic_id;
      if (this.topicId) {
        this.isTopicCoursework = true;
      }
      this.initializePage();
    });
  }

  initializePage() {
    this.getCourseWorkDetails();
  }

  getCourseWorkDetails() {
    this.loader = true;
    //console.log("geteditdetailsdata ", this.courseId);
    let current_time = new Date();

    this.courseService.getSingleCoursework(this.courseId, this.courseName).subscribe(res => {

      this.courseData = res.course_info;
      this.courseTitle = this.courseData.subject;

      //CHECK PERMISSION
      if (!this.permissionService.isAllowedSharedPageView(this.courseData.publish_right, this.role, this.courseData.teach_assis)) {
        this.router.navigate(['/']);
        return false;
      }

      if (this.isTopicCoursework == false) {
        this.loader = false;
        this.prepareCourseworkInfo(res.course_work_info, 'course');
      }
      else {
        // INITIALIZE TOPIC COURSEWORK
        this.getTopicCourseWork();
      }
    }, err => {
      this.loader = false;
      this.handleError(err);
    });
  }

  getTopicCourseWork() {
    this.loader = true;
    this.courseService.get_topicSession(this.courseId, this.topicId).subscribe(res => {
      this.prepareCourseworkInfo(res.course_work, 'topic');
      this.loader = false;
    },
      (err) => {
        this.loader = false;
        this.handleError(err);
      });
  }

  prepareCourseworkInfo(course_works, ref = '') {
    let current_time = new Date();
    let list_course_work = course_works;
    let course_work_item: any = null;
    course_work_item = list_course_work.find(item => {
      return item._id == this.courseWorkId;
    });
    if (course_work_item) {
      if (!course_work_item.date) {
        course_work_item['date'] = { day: current_time.getDate(), month: current_time.getMonth() + 1, year: current_time.getFullYear() };
        course_work_item['startDate'] = current_time;
      }
      else {
        course_work_item['startDate'] = new Date(course_work_item.date.year, course_work_item.date.month - 1, course_work_item.date.day);
      }
      this.courseworks = course_work_item;
      this.getCourseWorkteam(this.courseworks);
    }
    //console.log(this.courseData);

    //CHECK TEAM CREATE ALLOWED
    this.isTeamCreateAllowed();

    //CHECK TEACHING ASSISTANT
    this.checkTeachingAssistant();
  }

  getCourseWorkteam(course_works) {
    if (course_works.group_type == 'Student-led' || course_works.group_type == 'manual') {
      if (course_works.team_info.length > 0) {
        this.courseworkTeaminfo = course_works.team_info;
      }
      else {
        this.courseworkTeaminfo = [];
      }
    }
  }

  chatWithGroup(cwGroupChat) {
    if (cwGroupChat.user_status != "active") {
      this.notificationService.showNotification('info', 'You are not in this group. Connect with admin');
      return;
    }
    this.router.navigate(['/guftagu'], { queryParams: { group_name: cwGroupChat.name, group_room_id: cwGroupChat.room_id } });
  }

  onGoBack() {
    this.router.navigate(['/courses/single-course'], { queryParams: { courseWork: this.courseWorkId, course_id: this.courseId } });
  }


  //COURSE WORK CREATE AND EDIT |START
  validateForm(): void {
    this.addTeamByStudnet = this.fb.group({
      name: [""],
      userlist: [""],
    });
  }

  onSelectFile(event) {
    let files = event.target.files;
    //console.log(files);
    if (files.length) {
      const fileSize = files[0].size / 1024 / 1024;
      const limitSize = environment.groupProfilePicSize;
      if (fileSize > limitSize) {
        this.notificationService.showNotification('info', 'File size exceeds ' + limitSize + ' MB');
        return;
      }
      this.uploadGroupPic(files[0]);
    }
  }

  onFileInputClick(event) {
    event.target.value = null;
  }

  uploadGroupPic(file) {
    this.groupProcessing = true;
    this.notificationService.upload_resource('group-photo', file).subscribe((response: HttpEvent<any>) => {
      switch (response.type) {
        case HttpEventType.Response:
          const resourceData = response.body.resource_info[0];
          this.resourcePath = resourceData.path;
          this.uploadedImg = resourceData.url;
          this.groupProcessing = false;
          this.notificationService.showNotification('success', 'File uploaded successfully');
      }
    },
      (err) => {
        this.groupProcessing = false;
        this.notificationService.showNotification('info', 'Please add again not able to save your file');
      });
  }

  openGroupCreateModel(content, formRef, courseworkTeam = {}) {
    this.addedMembers = [];
    this.groupActionName = formRef;
    this.resourcePath = '';
    this.uploadedImg = '';
    this.validateForm();
    this.getSubscribeStudent(formRef);

    //FOR EDIT GROUP
    this.editCourseworkTeam = courseworkTeam;
    if (formRef == 'Edit') {
      this.addTeamByStudnet.controls['name'].setValue(this.editCourseworkTeam.name);
      this.editCourseworkTeam.members.forEach((elm) => {
        this.addedMembers.push(elm);
      });
    }

    this.createGroupModal = this.modalService.open(content, { windowClass: 'createGroupModel', centered: true, size: "xl", backdrop: 'static', keyboard: false });
  }

  closeGroupCreateModel() {
    this.addedMembers = [];
    this.createGroupModal.close();
  }

  discardChanges() {
    this.addedMembers = [];
    this.getSubscribeStudent('Discard');
  }

  getSubscribeStudent(ref = '') {
    let role = 'student';
    this.groupProcessing = true;
    this.courseService.getCourseworkSubscribers(this.courseId, role, this.courseworks.schedule_id).subscribe((response) => {
      this.subscribeStudentList = response.users_info;

      //FOR EDIT GROUP
      if(ref == 'Edit' || (ref == 'Discard' && this.groupActionName == 'Edit')) {
        this.initSubscribers(ref);
      }

      this.groupProcessing = false;
    }, err => {
      this.groupProcessing = false;
      this.handleError(err);
    });
  }

  initSubscribers(ref) {
    if (ref == 'Edit') {
      this.editCourseworkTeam.members.forEach(element => {
        let subscriberStudent = this.subscribeStudentList.find(subscriber => {
          return subscriber._id == element._id;
        });
        subscriberStudent.isAdded = true;
      });
    }
    if (ref == 'Discard') {
      this.editCourseworkTeam.members.forEach(element => {
        let subscriberStudent = this.subscribeStudentList.find(subscriber => {
          return subscriber._id == element._id;
        });
        subscriberStudent.isAdded = false;
        //UPDATE MEMBER TEAM STATUS
        subscriberStudent.team_status = false;
        subscriberStudent.team_approval_status = false;
      });
    }
  }

  addMemberToGroup(subscriberStudent) {
    const index = this.addedMembers.findIndex(addedMember => addedMember._id === subscriberStudent._id);
    if (index == -1) {
      this.addedMembers.push(subscriberStudent);
      subscriberStudent.isAdded = true;
    }
  }

  removeMemberFromGroup(member) {
    const index = this.addedMembers.findIndex(addedMember => addedMember._id === member._id);
    if (index > -1) {
      this.addedMembers.splice(index, 1);
    }
    const indexSubscriber = this.subscribeStudentList.find(subscriberStudent => {
      return subscriberStudent._id === member._id
    });
    if (indexSubscriber) {
      indexSubscriber.isAdded = false;
      //UPDATE MEMBER TEAM STATUS
      indexSubscriber.team_status = false;
      indexSubscriber.team_approval_status = false;
    }
  }

  saveCourseWorkTeams() {
    const groupName = this.addTeamByStudnet.value.name.trim();
    if (!groupName) {
      this.notificationService.showNotification('info', 'You have not entered any team name please enter it.');
      return;
    }
    if (this.addedMembers.length == 0) {
      this.notificationService.showNotification('info', 'You have not added any member in the group.');
      return;
    }

    //PREPARE POST DATA
    this.resetTeam = false;
    let usersData = [];
    let groupRoomId = '';
    let oldTeamName = '';
    if (this.groupActionName == 'Edit') {
      oldTeamName = this.editCourseworkTeam.name;
      if (groupName.toLowerCase() == this.editCourseworkTeam.name.toLowerCase()) {
        if (this.editCourseworkTeam.cw_group_chat) {
          groupRoomId = this.editCourseworkTeam.cw_group_chat.room_id;
        }
      }
    }
    this.addedMembers.forEach(element => {
      usersData.push({ approval_status: true, user_room_id: groupRoomId, _id: element._id });
    });
    const finalData = [{ group_pic: this.resourcePath, group_room_id: groupRoomId, name: groupName, users: usersData }];
    //console.log(finalData);
    //return;

    this.groupProcessing = true;
    this.courseService.saveCourseWorkTeams(finalData, this.courseworks.group_type, this.courseworks._id, this.courseworks.schedule_id, this.courseId, 'manual', this.resetTeam, groupName, this.groupActionName, oldTeamName).subscribe(getTeams => {
      this.notificationService.showNotification('success', getTeams.message);
      this.addedTeam();
      this.groupProcessing = false;
    },
      (error) => {
        this.groupProcessing = false;
        this.notificationService.showNotification('error', error.message);
      });
  }

  addedTeam() {
    this.closeGroupCreateModel();
    this.addedMembers = [];
    this.initializePage();
    this.validateForm();
  }

  isTeamCreateAllowed() {
    if ((this.courseData.publish_right == true) || (this.role == 'super_admin')) {
      this.showCreateGroup = true;
    }
    else {
      this.showCreateGroup = false;
    }
  }

  checkTeachingAssistant() {
    if (this.permissionService.isAssistant(this.courseData.teach_assis)) {
      this.isTeachingAssistant = true;
    }
    else {
      this.isTeachingAssistant = false;
    }
  }

  onAssignSubscriber(_id, schedule_id) {
    this.router.navigate(['courses/cw-teaching-assistant'], { queryParams: { course_id: this.courseId, courseName: this.courseTitle, course_work_id: _id, schedule_id: schedule_id, topic_id: this.topicId } });
  }

  onViewSubmissions(courseworks_id, schedule_id) {
    this.router.navigate(['courses/course-work-submissions'], { queryParams: { courseWork: this.courseWorkId, course_id: this.courseId, course: this.courseTitle, topic_id: this.topicId } });
  }
  //COURSE WORK CREATE AND EDIT |END

  openResource(url) {
    window.open(url, "blank")
  }

  handleError(error) {
    console.log(error);
  }

  //FOR BUILD FIX
  searchTexts: any = '';

}
