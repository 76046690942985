<div class="new_UIstyle">
    <div class="pageBodyWrap pb-5">
        <!-- BANNER |START -->
        <div class="banner banner-gradient banner-calendar">
            <div class="container">
                <div class="banner-content">
                    <div class="banner-content-content">
                        <h2 class="white-text avenirBlack">{{courseTitle}}: <br />
                            {{courseworks?.title}}
                        </h2>
                        <p class="white-text" *ngIf="courseworks?.is_group">Group Assignment:
                            <span class="manual_ass">
                                <strong class="ms-2 primary-text ml-5">
                                    {{courseworks?.group_type | capitalization}}
                                </strong>
                            </span>
                        </p>
                        <p class="white-text">Coursework Start Date:
                            <span class="manual_ass">
                                <strong class="ms-2 primary-text ml-3">
                                    {{courseworks?.date | dateFormatFullmonth}}
                                </strong>
                            </span>
                        </p>
                        <p class="white-text">Start Time:
                            <span class="manual_ass">
                                <strong class="ms-2 primary-text coursework-starttime">
                                    {{courseworks?.time}}
                                </strong>
                            </span>
                        </p>
                        <p class="white-text">Status:
                            <span *ngIf="courseworks?.title" class="manual_ass">
                                <strong class="ms-2 primary-text coursework-submission-status">{{courseworks.publish ==
                                    true ? 'Marked' : 'Yet to be marked'}}</strong>
                            </span>
                        </p>
                    </div>
                    <div class="banner-content-action btn-groups">
                        <button class="btn btn-primary" (click)="onGoBack()">
                            <i class="fas fa-arrow-left"></i> Back</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- BANNER |END -->

        <!-- COURSEWORK DESCRIPTION |START -->
        <div class="row p-5">
            <div class="col-md-12 m-auto">
                <div class="container" [ngClass]="{submitting : loader}">
                    <div class="spinner-border text-warning formLoader" role="status" *ngIf="loader">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <p [innerHTML]="courseworks?.course_work_description"></p>
                </div>
            </div>
        </div>
        <!-- COURSEWORK DESCRIPTION |END -->

        <!-- My RESOURCES |START -->
        <div class="container">
            <div class="learning-course-items-wrap learning-course-resources-wrap mb-5"
                *ngIf="courseworks && courseworks?.course_work_resources?.length > 0">
                <div class="learning-course-item" *ngFor="let resource of courseworks?.course_work_resources">
                    <div class="learning-course-link">
                        <div class="learning-course-elem-left">
                            <p class="learning-course-link-title">
                                <span class="title-icon">
                                    <i class="fas fa-folder"></i></span>
                                <strong>{{resource.resource_title}}</strong>
                            </p>
                        </div>
                        <div class="learning-course-elem-right">
                            <ul class="list-icon-wrap">
                                <li>
                                    <a href="javascript:void(0);" class="learning-course-download"
                                        (click)="openResource(resource.resource_url)">
                                        <i class="fas fa-download"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- My RESOURCES |END -->

        <!-- CREATE GROUP |START -->
        <div class="container">
            <hr *ngIf="courseworks.is_group && (showCreateGroup == true)">
            <div *ngIf="courseworks.is_group && (showCreateGroup == true)">
                <div class="course-work-detail-elem course-work-createGroup">
                    <h5 class="avenirBlack primary-text mb-0"><b>Create Your Group</b></h5>
                    <button class="btn btn-primary chat-btn btn-icon-text"
                        (click)="openGroupCreateModel(createGroup, 'Create')">+ Create</button>
                </div>
            </div>
        </div>
        <!-- CREATE GROUP |END -->

        <!-- MY GROUP INVITES |START -->
        <div class="container" *ngIf="isPendingInvite && !isCourseWorkStarted(courseworks.date, courseworks.time)">
            <hr class="mb-0">
            <div class="course-work-detail-elem course-work-teams-detail-box" *ngIf="courseworkTeaminfo.length > 0" [ngClass]="{submitting : inviteProcess}">
                <div class="courseWork-teams-box course-work-my-team" *ngFor="let courseworkTeam of courseworkTeaminfo">
                    <ng-container *ngFor="let member of courseworkTeam.members">
                        <ng-container *ngIf="member._id == loggedInUserId && member.approval_status == false">
                            <div class="course-work-createGroup">
                                <h5 class="team_in avenirBlack primary-text mb-3">
                                    <b> {{courseworkTeam.name | titlecase}} </b>
                                </h5>
                            </div>
                            <div class="team-list-box member">
                                <div class="row team_inner">
                                    <div class="col-lg-4 col-md-6">
                                        <!-- FOR COURSE LEVEL COURSEWORK -->
                                        <p class="mb-3" *ngIf="!isTopicCoursework && showCourseData?.course_work_info[0]?.pending_request.length > 0">{{showCourseData.course_work_info[0].pending_request[0].invited_by_name | titlecase}} has invited you to join thier group.</p>

                                        <!-- FOR TOPIC LEVEL COURSEWORK -->
                                        <p class="mb-3" *ngIf="isTopicCoursework && courseworks?.pending_request.length > 0">{{courseworks.pending_request[0].invited_by_name | titlecase}} has invited you to join thier group.</p>
                                    </div>
                                    <div class="col-lg-4 col-md-6 team_memb">
                                        <div *ngIf="inviteProcess" class="spinner-border text-warning formLoader d-inline-block mr-4 inline-loader" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                        <button class="btn btn-primary btn-sm" (click)="onAcceptReject('reject', courseworkTeam, member)" [disabled]="inviteProcess">Ignore</button>
                                        <button class="btn btn-primary btn-sm ml-4" (click)="onAcceptReject('accept', courseworkTeam, member)" [disabled]="inviteProcess">Accept</button>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
        <!-- MY GROUP INVITES |END -->

        <!-- MY TEAMS |START -->
        <div class="container" *ngIf="!isPendingInvite">
            <hr *ngIf="courseworkTeaminfo.length > 0" class="mb-0">
            <div class="course-work-detail-elem course-work-teams-detail-box" *ngIf="courseworkTeaminfo.length > 0">
                <div class="courseWork-teams-box course-work-my-team" *ngFor="let courseworkTeam of courseworkTeaminfo">
                    <div class="course-work-createGroup">
                        <h5 class="team_in avenirBlack primary-text mb-3">
                            <b> {{courseworkTeam.name | titlecase}} </b>
                        </h5>
                    </div>
                    <div class="team-list-box grey-Box member pt-4">
                        <div class="row team_inner">
                            <div class="col-lg-8 col-md-6">
                                <p class="avenirBlack primary-text mb-3">Members name</p>
                            </div>
                            <div class="col-lg-4 col-md-6 team_memb text-right" *ngIf="courseworkTeam.cw_group_chat"
                                (click)="chatWithGroup(courseworkTeam.cw_group_chat)">
                                <button class="btn btn-primary chat-btn btn-icon-text btn-sm"><i
                                        class="far fa-comment-dots"></i> Chat</button>
                            </div>
                        </div>

                        <div class="row gy-3 member_data">
                            <div class="col-lg-4 col-md-6 team-list-elemBox mt-4"
                                *ngFor="let member of courseworkTeam.members">
                                <div class="Instructor-profile">
                                    <app-default-icon *ngIf="!member.profile_pic" [name]="member.name" [figureClass]="''"></app-default-icon>
                                    <figure *ngIf="member.profile_pic" class="Instructor-pro-fig">
                                        <img src="{{!member.profile_pic ? userDefaultPic: member.profile_pic}}"
                                            alt="dp-4.png" />
                                    </figure>
                                    <div class="Instructor-pro-content">
                                        <h6 class="avenirBlack">{{member.name}}</h6>
                                        <span *ngIf="member.approval_status"
                                            class="badge badge-secondary">Approved</span>
                                        <span *ngIf="!member.approval_status" class="badge badge-danger">Pending</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- MY TEAMS |END-->

        <!-- MY SUBMISSIONS START -->
        <div class="container">
            <hr *ngIf="courseworks_submission.length > 0">
            <div class="course-work-detail-elem course-work-submission-wrap" *ngIf="courseworks_submission.length > 0">
                <h5 class="avenirBlack primary-text">Submissions</h5>
                <ngb-accordion #submissions="ngbAccordion" [closeOthers]="true" activeIds="active"
                    class="accordionCustom">
                    <ngb-panel *ngFor="let course_works of courseworks_submission; let i = index">

                        <ng-template ngbPanelHeader>
                            <div class="d-flex panelHeaderButton panelHeaderActionBtn">
                                <div class="panelHeaderElem panelHeaderLeft">
                                    <button ngbPanelToggle class="ngbPanelToggleBtn" id="ngbPanelToggle1">
                                        <span class="acc-headerTitle h6 avenirBlack d-block mb-0">
                                            {{course_works?.submission_report}}
                                        </span>
                                        <div class="float-left mt-2">
                                            <span>Marks:</span> <strong class="marks_course primary-text">
                                                {{course_works?.total_number_of_percentage}}%
                                            </strong>
                                        </div>
                                        <div class="learning-course-elem-right">
                                            <p class=" mb-0">Deadline:
                                                <strong class="primary-text">
                                                    {{getExpireDate(courseworks.date,course_works?.days_of_completion)
                                                    | dateFormatFullmonth}}
                                                </strong>
                                            </p>
                                        </div>
                                        <span class="acc-headerAngel"><i class="fas fa-angle-down"></i></span>
                                    </button>
                                </div>
                            </div>
                        </ng-template>

                        <ng-template ngbPanelContent>
                            <div class="panelContentWrapNoScroller">
                                <div class="panelHeaderElem rubric_des">
                                    {{course_works.rubric}}
                                </div>
                                <div class="selector_data">
                                    <section class="contentBlocks" *ngIf="isSubscribed">
                                        <app-course-work-test [reportIndex]="i" [showCourseWork]="course_works"
                                            [courseworks]="courseworks" [courseId]="courseId"
                                            [teachingAssistant]="showCourseData.course_info.teach_assis"
                                            [reviewSavedStatus]="reviewSavedStatusData"
                                            (sendSubmissionData)="getSubmissionData($event)">
                                        </app-course-work-test>
                                    </section>
                                </div>
                            </div>

                            <!-- USER REVIEWS -->
                            <app-course-work-test-review *ngIf="isSubscribed" [reportIndex]="i" [showCourseWork]="course_works"
                                [courseworks]="courseworks" [courseId]="courseId"
                                [teachingAssistant]="showCourseData.course_info.teach_assis"
                                [submissionData]="viewSubmissionData"
                                (sendReviewSavedStatus)="getReviewSavedStatus($event)">
                            </app-course-work-test-review>

                        </ng-template>

                    </ngb-panel>
                </ngb-accordion>
            </div>
        </div>
        <!-- MY SUBMISSIONS END -->

    </div>
</div>


<!-- MODAL CREATE TEAMS |START-->
<ng-template #createGroup let-modal>
    <div class="new_UIstyle" [ngClass]="{submitting : groupProcessing}">
        <div class="create_mod">
            <div class="modal-header">
                <h5 class="avenirBlack blue-lt-text d-flex d-lg-inline-block mb-0">
                    {{groupActionName}} Group</h5>
                <button type="button" class="close close_pop" aria-label="Close" (click)="closeGroupCreateModel()">
                    <span aria-hidden="true" class="text-white">&times;</span>
                </button>
            </div>
            <div class="modal-body modalGroupBody crea_mod">
                <div class="spinner-border text-warning formLoader" role="status" *ngIf="groupProcessing">
                    <span class="sr-only">Loading...</span>
                </div>
                <div class="guftagu-addUserGroup-body-wrap pt-0">
                    <div class="row g-0 gy-3 gy-md-0">
                        <div class="col-lg-7 mt-3  mt-lg-0 order-2 order-lg-1 pt-3">
                            <div class="guftagu-addUser-search mb-4">
                                <form class="input-with-icon input-with-icon-left search-form">
                                    <input type="text" name="search" placeholder="Search user"
                                        class="form-control form-control-style-2 form-control-lg create-group-control"
                                        [(ngModel)]="searchTexts" autocomplete="off" />
                                    <button class="input-with-icon-btn search-btn"><i
                                            class="fas fa-search"></i></button>
                                </form>
                            </div>

                            <div class="guftagu-addUserList mt-2">
                                <div class="guftagu-item-list guftagu-item-list-addChatUser status-dot-none"
                                    *ngFor="let subscriberStudent of subscribeStudentList | filter: searchTexts">
                                    <div class="guftagu-item-link">
                                        <div class="row g-0 gy-3 gy-md-0 align-items-center">
                                            <div class="col-12 col-md-8 guftagu-item-link-left">
                                                <app-default-icon *ngIf="!subscriberStudent.profile_pic" [name]="subscriberStudent.name" [figureClass]="''"></app-default-icon>
                                                <figure *ngIf="subscriberStudent.profile_pic" class="guftagu-item-fig"><img
                                                        [src]="subscriberStudent.profile_pic" alt="dp-2.jpg" /></figure>
                                                <div class="ml-2">
                                                    <p class="guftagu-item-desc-title avenirBlack mb-0">
                                                        {{subscriberStudent.name}}</p>
                                                    <p class="guftagu-item-desc-title mb-0">
                                                        {{subscriberStudent.username}}</p>
                                                </div>
                                            </div>

                                            <div class="col-12 col-md-4 guftagu-item-link-right text-end">
                                                <button class="btn btn-sm btn-blue"
                                                    (click)="addMemberToGroup(subscriberStudent)" name="grouplist"
                                                    [disabled]="subscriberStudent?.isAdded || subscriberStudent.team_status == true">{{subscriberStudent?.isAdded
                                                    ? 'Added to group' : 'Add to group'}}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="alert alert-info text-center p-2"
                                    *ngIf="!groupProcessing && subscribeStudentList.length == 0">
                                    <p>User Not Found.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5 ps-0 ps-lg-3 order-1 order-lg-2">
                            <form [formGroup]="addTeamByStudnet">
                                <div class="guftaguCreateGroup">
                                    <div class="guftaguUploadProfile">
                                        <label class="UploadHoverable" for="fileInput">
                                            <img class="UploadHoverableImg"
                                                [src]="uploadedImg ? uploadedImg : userDefaultPic" alt="dp-2.jpg">
                                            <div class="UploadHoverableBackground"></div>
                                            <span class="camra-icon"><i class="fas fa-camera"></i></span>
                                        </label>
                                        <input id="fileInput" type='file' (change)="onSelectFile($event)"
                                            (click)="onFileInputClick($event)" accept="image/*">
                                    </div>
                                    <div class="mt-2 ceateGroupName">
                                        <p class="mb-0">Group Name</p>
                                        <input type='text' maxlength="50"
                                            class="form-control form-control-style-2 create-group-control"
                                            placeholder="Enter group name" formControlName="name">
                                    </div>

                                    <div class="mt-2 memberListWrap">
                                        <p class="mb-0">Members Added</p>
                                        <hr class="mt-0" />
                                        <div class="mt-2 addGroupMenmerList">
                                            <div class="guftagu-item-list guftagu-item-list-addChatUser status-dot-none"
                                                *ngFor="let member of addedMembers;">
                                                <div class="guftagu-item-link">
                                                    <div class="row g-0 align-items-center">
                                                        <div class="col-9 guftagu-item-link-left">
                                                            <app-default-icon *ngIf="!member.profile_pic" [name]="member.name" [figureClass]="''"></app-default-icon>
                                                            <figure *ngIf="member.profile_pic" class="guftagu-item-fig"><img
                                                                    src="{{!member.profile_pic ? userDefaultPic: member.profile_pic}}"
                                                                    alt="dp-2.jpg" />
                                                            </figure>
                                                            <div class="guftagu-item-desc">
                                                                <p class="guftagu-item-desc-title avenirBlack mb-0">
                                                                    {{member.name}}
                                                                </p>
                                                                <p class="guftagu-item-desc-title mb-0">
                                                                    {{member.username}}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div class="col-3 guftagu-item-link-right text-right">
                                                            <button *ngIf="member._id != loggedInUserId"
                                                                class="btn-transparent primary-text"
                                                                (click)="removeMemberFromGroup(member)"><i
                                                                    class="fa fa-times"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                </div>

                                <div class="col-12 order-3">
                                    <div class="btn-groups justify-content-end">
                                        <button type="button" class="btn btn-sm btn-primary-border" aria-label="Close"
                                            (click)="discardChanges()">Discard
                                        </button>
                                        <button type="button" (click)="saveCourseWorkTeams()"
                                            class="btn btn-sm btn-primary">
                                            Create Group
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<!-- MODAL CREATE TEAMS |END-->
