import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { CourseService } from 'src/app/services/course.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-search-organisation',
  templateUrl: './search-organisation.component.html',
  styleUrls: ['./search-organisation.component.scss']
})
export class SearchOrganisationComponent implements OnInit {

  organization_list: any[] = [];
  organizations = [];
  isCourseWorkAvailable: boolean = false;
  loader = false;
  constructor(private course_service:CourseService, private router: Router, private ns:NotificationService) { }

  ngOnInit() {
    this.get_organization();
  }

  get_organization() {
    this.loader = true;
    this.course_service.get_organization().pipe(finalize(() => {
      this.loader = false;
    })).subscribe(organization => {
      this.organization_list = organization.data
      this.organizations = this.organization_list;
    },
      (error) => {
        this.ns.showNotification('error', error.message);
      })
  }

  searchCourseWork(ev: any) {

    // set val to the value of the searchbar
    const val = ev.target.value.toLowerCase();

    // if the value is an empty string don't filter the items
    if (val && val.trim() != '') {
      this.isCourseWorkAvailable = true;
      this.organizations = this.organization_list.filter((item) => {
        return (item.name.toLowerCase().trim().indexOf(val.trim()) >= 0);
      });
    } else {
      this.isCourseWorkAvailable = false;
      this.organizations = this.organization_list;
    }
  }

  editOrganisation(organisation){
    this.router.navigate(['/add_organisation'], { queryParams: { organisation_id: organisation._id, org_name:organisation.name, type:organisation.organisation_type._id } });
  }
}
