import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm, FormGroup, FormBuilder, Validators } from '@angular/forms';

import { finalize } from 'rxjs/operators';

import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { QuestionService } from 'src/app/services/question.service';
import { answers, myContentVideos, my_questions, userAnswer } from 'src/app/model/course.model';
import { NotificationService } from 'src/app/services/notification.service';
import { CourseService } from 'src/app/services/course.service';
import { environment } from 'src/environments/environment';
import { MatCalendarBody } from '@angular/material';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { tr } from 'date-fns/locale';
//import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

declare var tinymce: any;

@Component({
  selector: 'app-question-details',
  templateUrl: './question-details.component.html',
  styleUrls: ['./question-details.component.scss'],
  providers: [NgbModalConfig, NgbModal]
})
export class QuestionDetailsComponent implements OnInit {
  answer_details: answers[];
  user_answer_details: userAnswer[];
  myContentVideos: myContentVideos;
  link = null;
  FeedbacksList = [];

  questionLink = '';
  questionFeedBack = [];
  videoTitle = '';
  startTime = '';

  currentUsername = '';
  answerId = '';
  allsimilarQuestions: my_questions[];
  loader = false;

  Rating: number;
  isLiked: boolean;
  isDisLiked: boolean;
  question_status = '';

  typeOfpopup = '';
  FeedBackOfVideo = "";

  questionId = '';
  pageInfo = '';
  startSeconds: number;
  question_video_path: string;
  question_image_path: string;

  // selectedPic: File;
  // selectedVideo: File;

  selectedPic: any = [];
  selectedVideo: any = [];
  selectedDoc: any = [];
  fileItems: any = [];
  pageP: number = 1;
  totalAnsLen: number;
  progressBar: number;
  picName = "No Picture selected";
  videoName = "No Video selected";
  delete_image = false;
  delete_video = false;
  askQuestionForm: FormGroup;
  giveAnswerForm: FormGroup;
  categoryId = null;
  categoryName = null
  quesionModel = null;
  openModel = null;
  role: any;
  apiEndpoint = '';
  courseId: string;
  session_id: string;
  videoStartTime: string;
  ansLiked: boolean = false;
  ansDisLiked: boolean = false;
  ansDetailUserID: any;
  answerHtml: any;
  pageComment: number = 1;
  totalcomments: number = 0;
  constructor(
    config: NgbModalConfig,
    private modalService: NgbModal,
    private questionService: QuestionService,
    private route: ActivatedRoute,
    private router: Router,
    private ns: NotificationService,
    private fb: FormBuilder,
    private courseService: CourseService) {
    // customize default values of modals used by this component tree
    config.backdrop = 'static';
    config.keyboard = false;
    this.apiEndpoint = environment.APIEndpoint;
  }
  answer_user_id;

  insertBlankFlag: boolean = false;
  toolbarWithoutBlankButton = 'undo redo | formatselect | bold italic backcolor | \
  alignleft aligncenter alignright alignjustify | \
  bullist numlist outdent indent | removeformat | \
  link | preview';
  fileArr: any = [];

  ngAfterViewInit() {
    this.callMceEditor('textanswer');
  }



  // open(content, link, type, Feedback, videoTitle, answerId, user_id) {
  //   for (let i = 0; i < this.user_answer_details.length; i++) {
  //     if (this.user_answer_details[i].answer_html == '') {
  //       let vid = <HTMLVideoElement>document.getElementById('video' + i);
  //       if (vid.play()) {
  //         vid.pause();
  //       }
  //     }
  //   }

  //   this.answerSubmitType = '';
  //   this.typeOfpopup = type;
  //   this.videoTitle = videoTitle;
  //   this.FeedBackOfVideo = Feedback;
  //   this.openModel = this.modalService.open(content, { size: 'lx' });
  //   this.picName = "No Picture selected";
  //   this.videoName = "No Video selected";
  //   this.link = link;
  //   this.answer_user_id = user_id;
  //   if (answerId) {
  //     this.answerId = answerId;
  //     this.getAnswerMetaData();
  //     this.videoTitle = videoTitle;
  //   }


  //   if (!this.videoTitle) {
  //     this.videoTitle = this.myContentVideos.question
  //   } else if (videoTitle == 'giveAnswer') {
  //     this.videoTitle = 'Give Your Answer';
  //   }
  // }

  routerUrl: any;
  userId = '';
  avatarURL;
  // answerId: any;
  ngOnInit() {
    let sp = this.router.url.split('?')
    this.routerUrl = sp[0];
    // if (this.routerUrl == '/question-details' || this.routerUrl == '/answer-details') {
    //   this.router.navigate(['/']);
    // }
    this.answerId = this.route.snapshot.queryParamMap.get('answerId');
    this.questionId = this.route.snapshot.queryParamMap.get('questionId');
    this.pageInfo = this.route.snapshot.queryParamMap.get('info');
    this.getAnswerDetails(this.answerId, '');
    this.userQustionAnswer();
    // this.getAnswerMetaData();
    // this.getRandomColor();
    // this.getQuestionsResult();
    this.askQuestionForm = this.fb.group({
      subject: ['', Validators.required],
      coins: ['', Validators.required],
      yourQuestion: ['', Validators.required],
      picture: [''],
      video: ['']
    })
    this.giveAnswerForm = this.fb.group({
      answer_text: [''],
    })
    this.currentUsername = localStorage.getItem("currentUser");
    this.userId = localStorage.getItem('user_id');
    this.role = localStorage.getItem('role');
    this.avatarURL = localStorage.getItem('avatarURL');
    this.courseId = this.route.snapshot.queryParamMap.get('courseId');
    this.session_id = this.route.snapshot.queryParamMap.get('session_id')
    this.videoStartTime = this.route.snapshot.queryParamMap.get('videoStartTime')
  }

  // write New Design Code Here Start=====
  questionName: string;
  tags: any = [];
  answersList: any = [];
  resourceVideoItems: any = [];
  resourceImageItems: any = [];
  resourceDocItems: any = [];
  answerslength: any;
  question_user_id: any;
  ans_user_id: any;
  ans_approval_status: any;
  categories:any = [];
  selectedDockey:any = [];
  selectedPickey:any = [];
  selectedVideokey:any = [];

  resourceImages:any = [];
  resourceDocs:any = [];
  resourceVideos = [];
  userQustionAnswer() {
    this.resourceDocItems = [];
    this.resourceImageItems = [];
    this.resourceVideoItems = [];
    if (this.routerUrl == '/question-details' || this.routerUrl == '/answer-details') {
      this.loader = true;
      this.questionId = this.route.snapshot.queryParamMap.get('questionId');
      this.pageInfo = this.route.snapshot.queryParamMap.get('info');
      this.questionService.getUserQuestionAnswer(this.questionId).subscribe(res => {
        // console.log(res);
        let questionData = res.response;
        this.loader = false;
        this.questionName = questionData.question;
        this.question_user_id = questionData.user_id;
        this.categories = questionData.course_category_taxonomy.split('/');
        this.tags = questionData.tags;
        this.answersList = questionData.answers;
        this.user_answer_details = questionData.answers;
        this.question_status = questionData.question_status;
        for (let a = 0; a < this.answersList.length; a++) {
          let ansItem = this.answersList[a];
          this.ans_user_id = ansItem.user_id;
          this.ans_approval_status = ansItem.approval_status;
        }
        this.answerslength = questionData.answers.length;
        this.totalAnsLen = questionData.answers.length;

        this.selectedDockey =  questionData.question_doc_key;
        this.selectedPickey =  questionData.question_image_key;
        this.selectedVideokey =  questionData.question_video_key;

        // Resources paths
        this.resourceVideos = questionData.question_video_path;
        this.resourceImages = questionData.question_image_path;
        this.resourceDocs = questionData.question_doc_path;
        // Resources paths condition basis
        let resourceGet;


        // = questionData.question_doc_path;
        // if (resourceVideos) {
        //   this.resourceCondition(this.selectedDockey, this.resourceVideoItems);

        // }
        // if (resourceImages) {
        //   this.resourceCondition(this.selectedPickey, this.resourceImageItems);

        // }
        // if (resourceDocs) {
        //   this.resourceCondition( this.selectedVideokey, this.resourceDocItems);

        // }
        
      })
    }
  }

  giveYourAnswer() {
    this.router.navigate(['/give-your-answer'], { queryParams: { questionId: this.questionId, info: this.pageInfo } });
  }

  getAnswerDetailRoute(answerId: any) {
    this.router.navigate(['/answer-details'], { queryParams: { questionId: this.questionId, info: this.pageInfo, answerId: answerId, } });
    this.getAnswerDetails(answerId,'');
  }
  approval_status: any;
  answer_doc_List: any = [];
  answer_image_list: any = [];
  answer_video_List: any = [];
  like_count:number;
  dislike_count:number;
  resourceDocsAns= [];
  resourceImagesAns = [];
  ansResourceVideosAns = [];
  ansResourceVideosKeyAns = [];
  resourceImagesKeyAns = [];
  resourceDocsKeyAns = [];
  getAnswerDetails(answerId: any, commentFilter) {
    this.answer_doc_List = [];
    this.answer_image_list = [];
    this.answer_video_List = [];
    if (this.routerUrl == '/answer-details') {
      this.questionService.getUserAnswerDetail(answerId,commentFilter).subscribe((res: any) => {
        let ansDataDetail = res.response;
        this.FeedbacksList = ansDataDetail.Feedback;
        this.totalcomments = ansDataDetail.Feedback.length;
        this.isLiked = ansDataDetail.Liked;
        this.isDisLiked = ansDataDetail.Disliked;
        this.like_count = ansDataDetail.like_count;
        this.dislike_count = ansDataDetail.dislike_count;
        this.ansDetailUserID = ansDataDetail.user_id;
        this.approval_status = ansDataDetail.approval_status;
        this.answerHtml = ansDataDetail.answer_html;

        // Resources key
        this.ansResourceVideosKeyAns = ansDataDetail.answer_video_key;
        this.resourceImagesKeyAns = ansDataDetail.answer_image_key;
        this.resourceDocsKeyAns = ansDataDetail.answer_doc_key;
        // Resources paths
        this.ansResourceVideosAns = ansDataDetail.answer_video;
        this.resourceImagesAns = ansDataDetail.answer_image;
        this.resourceDocsAns = ansDataDetail.answer_doc;

      })
    }
  }
  getWeekVideos() {
    if (this.myContentVideos.Course_id != undefined && this.myContentVideos.Week != undefined && this.myContentVideos.Week_index != undefined) {
      this.questionService.getQuestionVideo(this.myContentVideos.Course_id, this.myContentVideos.Week, this.myContentVideos.Week_index).subscribe(questionData => {
        if (questionData.Content_detail) {
          this.questionLink = questionData.Content_detail.Link;
          this.questionFeedBack = questionData.Feedback_List;
          this.videoTitle = questionData.Content_detail.title;
          this.startTime = questionData.Content_detail.video_time;
        }
      })
    }
  }

  getSimilarTypesQuestions(question, question_id) {
    this.questionService.getSimilarTypesQuestion(question, question_id).subscribe(similarQuestions => {
      this.allsimilarQuestions = similarQuestions.questions;
    })

  }

  similarQuestionAnswer(questionId) {
    this.router.navigate(['/question-details'], { queryParams: { questionId: questionId } });
    // this.getQuestionsResult();
  }

  sendAnswerFeedback(form: NgForm) {
      let description = this.ns.titleCase(tinymce.get("textanswer").getContent());
      description = this.ns.paraString(description);
      form.value.feedBack= description;
      console.log(form.value.feedBack)

    if (form.value.feedBack != '' && form.value.feedBack.trim() != '' && form.value.feedBack != null) {
      this.loader = true;
      this.newFilter = false;
      this.topFilter = false;
      this.questionService.answerFeedbackApi(this.answerId, form.value.feedBack).subscribe(answerFeedback => {
        this.ns.showNotification("success", answerFeedback.message);
        this.loader = false
        this.FeedbacksList.unshift({ username: localStorage.getItem('currentUser'), Feedback: form.value.feedBack, Timestamp: new Date() })
        form.reset();
        this.getAnswerDetails(this.answerId, '');
        // this.getAnswerMetaData();
        tinymce.get("textanswer").resetContent();
      },
        (error) => {
          this.ns.showNotification("info", error.message);
        })
    } else {
      this.ns.showNotification("info", 'Please add your comment.');
    }
  }

  resetFeedBack(feedBackForm: NgForm) {
    feedBackForm.reset();
  }


  setLikeDislike(value) {
    this.questionService.setLikeDislike(value, this.answerId).pipe(finalize(() => this.loader)).subscribe(liked => {
      this.isLiked = liked.Liked;
      this.isDisLiked = liked.Disliked;
      this.getAnswerDetails(this.answerId, '');
    })
  }

  setRating(value, answerId) {
    if (confirm("Are you sure you want give rating?")) {
      this.questionService.setRating(value, answerId, '', this.courseId).pipe(finalize(() => this.loader)).subscribe(rating => {
        this.ns.showNotification("success", rating.Status);
      })
    }
  }

  // approveAnswer(answerId, questionId, approved, index) {
  approveAnswer() {
    if (this.approval_status.toLowerCase() == "approved") {
      this.ns.showNotification("info", "Alreday Approved ");
      return false;
    }
    if (confirm("Are you sure you want approve this answer?")) {
      this.questionService.approveAnswer(this.answerId, this.questionId).pipe(finalize(() => this.loader = false)).subscribe(res => {
        this.ns.showNotification("success", "Approved Successfully");
        this.approval_status = "approved";
        console.log(res)
      },
        (err) => {
          this.ns.showNotification("error", err.Status);
        })
    }
  }

  closeQuestion() {
    if (this.user_answer_details.length == 0) {
      this.ns.showNotification("info", "Can't close the question because it has no answer yet.");
      return false;
    }
    if (confirm("Are you sure that you wants to close the question?")) {
      this.loader = true;
      this.questionService.closeQuestion(this.questionId).pipe(finalize(() => { this.loader = false })).subscribe(closeQuestion => {
        this.question_status = closeQuestion.question_status;
        this.loader = false;
        this.ns.showNotification("success", closeQuestion.Message);
      })
    }
  }
  private ytEvent;
  savePlayer(event) {
    this.ytEvent = event.target;
    this.ytEvent.seekTo(this.startSeconds)
    this.ytEvent.playVideo();
  }
  video_id: any;
  askAQuestion(question) {
    if (this.ytEvent) {
      this.ytEvent.playVideo();
    } else {
      this.video_id = document.getElementById('htmlVideo');
      this.video_id.pause();
    }

    this.askQuestionForm.reset();
    this.askQuestionForm.controls['subject'].setValue(this.categoryId);
    this.quesionModel = this.modalService.open(question)
  }

  callMceEditor(edId) {
    tinymce.remove();
    let that = this;
    tinymce.baseURL = 'assets';
    tinymce.init({
      selector: 'textarea#' + edId,
      height: 300,
      // base_url: '/tinymce',
      suffix: '.min',
      themes: "assets/themes/silver",
      // icons: "assets/icons/",
      // content_css: '//www.tiny.cloud/css/codepen.min.css',

      // plugins: [
      //   'advlist autolink lists link image imagetools charmap print preview anchor',
      //   'searchreplace visualblocks code fullscreen',
      //   'insertdatetime media table paste code help wordcount'
      // ],
      toolbar: that.toolbarWithoutBlankButton,
      image_title: true,
      automatic_uploads: true,
      image_description: false,
      file_picker_types: 'file image media',
      // external_plugins: { tiny_mce_wiris: "https://www.wiris.net/demo/plugins/tiny_mce/plugin.js" }, 
      // toolbar1: "tiny_mce_wiris_formulaEditor, tiny_mce_wiris_formulaEditorChemistry",
      file_picker_callback: function (cb, value, meta) {

        let input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');

        // Note: In modern browsers input[type="file"] is functional without 
        // even adding it to the DOM, but that might not be the case in some older
        // or quirky browsers like IE, so you might want to add it to the DOM
        // just in case, and visually hide it. And do not forget do remove it
        // once you do not need it anymore.

        input.onchange = function () {

          // var file = $event.path[0].files[0];
          let file = input.files[0];

          let reader: any = new FileReader();

          reader.onload = function () {

            // Note: Now we need to register the blob in TinyMCEs image blob
            // registry. In the next release this part hopefully won't be
            // necessary, as we are looking to handle it internally.
            let id = 'blobid' + (new Date()).getTime();
            let blobCache = tinymce.activeEditor.editorUpload.blobCache;
            let base64 = reader.result.split(',')[1];
            let blobInfo = blobCache.create(id, file, base64);

            blobCache.add(blobInfo);

            // call the callback and populate the Title field with the file name

            cb(reader.result, { title: file.name });
          };
          reader.readAsDataURL(file);
        };

        input.click();
      },
      images_upload_handler: function (blobInfo, success, failure, progress) {
        let xhr, formData;

        xhr = new XMLHttpRequest();

        xhr.withCredentials = false;
        xhr.open('POST', that.apiEndpoint + '/upload-resource?module_name=answer');
        xhr.setRequestHeader('session-token', localStorage.getItem('token')); // manually set header

        xhr.upload.onprogress = function (e) {
          progress(e.loaded / e.total * 100);
        };

        xhr.onerror = function () {
          failure("Image upload failed due to a XHR Transport error. Code: " + xhr.status);
        };

        xhr.onload = function () {
          let json;
          if (xhr.status != (201 || 200)) {
            failure('HTTP Error: ' + xhr.status);
            return;
          }
          json = JSON.parse(xhr.response);
          if (!json || typeof json.resource_info[0].url != 'string') {
            failure('Invalid JSON: ' + xhr.response.resource_info);
            return;
          }

          success(json.resource_info[0].url);
          let fileObj: any = {};
          fileObj._id = json.resource_info[0].url,
            fileObj.type = "file"
          that.fileArr.push(fileObj);
        };

        formData = new FormData();
        formData.append('resource', blobInfo.blob(), blobInfo.filename());


        xhr.send(formData);
      },
      setup: function (editor) {
        if (that.insertBlankFlag) {
          editor.ui.registry.addButton('customBlankButton', {
            text: 'Add Blank',
          });
        }
      }
    });
  }

  getTime(time) {
    return new Date(time).toString().split(' ').slice(0, 4).join(' ');
  }

  questionModel = null;
  openModelDeleteAns(deleteAns) {
    this.questionModel = this.modalService.open(deleteAns, { size: "sm", centered: true });
  }

  closeQuesPopup() {
    this.questionModel.close();
    // this.questionId = null;
  }

  deleteToAnswer(answer_id) {
      this.questionService.deleteAnswer(answer_id, this.questionId).pipe(finalize(() => this.loader = false)).subscribe(res => {
        this.ns.showNotification("success", "Deleted Answer Successfully");
        this.questionModel.close()
        this.userQustionAnswer();
        // below is old methord need to remove
        // this.getQuestionsResult();
      },
        (err) => {
          this.ns.showNotification("error", err.Status);
        })
  }


  backToSearch() {
    if (this.pageInfo == 'RECOM' && this.courseId != null) {
      this.router.navigate(['courses/video-with-notes'], { queryParams: { course_id: this.courseId, session_id: this.session_id, category_id: this.categoryId, videoStartTime: this.videoStartTime } })
      console.log(this.videoStartTime)
      return
    }
    if (this.pageInfo == 'MYQUES') {
      this.router.navigate(['/My-Questions']);
    } else if (this.pageInfo == 'RECOM') {
      this.router.navigate(['/recommended-questions'])
    } else if (this.pageInfo == 'STUQUES') {
      this.router.navigate(['/Student-Questions'])
    }
  }

  answer_validation() {
    if (this.question_status != 'closed' && this.pageInfo != 'MYQUES') {
      return true;
    }
    return false;
  }

  newFilter = false;
  topFilter = false;
  sortComment(type:string){
    if(type == 'top'){
      this.topFilter = !this.topFilter;
      this.newFilter = false;
      this.getAnswerDetails(this.answerId,'top');
    }  if(type == 'Newest'){ 
      this.topFilter = false;
      this.newFilter = !this.newFilter;
      this.getAnswerDetails(this.answerId,'Newest');
    } 
    if(this.topFilter == false && this.newFilter == false){
      this.getAnswerDetails(this.answerId,'');
    }

  } 

  ansCommentLikeDis(commentId, status, boolStatus){
    let body = {
       'answer_id':this.answerId,
       'comment_id':commentId,
       'status':status,
       'is_true':boolStatus
    }
    // if(boolStatus == true){
    //   // this.newFilter = false;
    //   this.topFilter = false;
    // }
    this.questionService.commentLikeDislike(body).subscribe(res => {
      this.ns.showNotification("success", res.Status);
      if(this.newFilter){
        this.getAnswerDetails(this.answerId, 'Newest');
      } else if(this.topFilter){
        this.getAnswerDetails(this.answerId, 'top');
      } else{
        this.getAnswerDetails(this.answerId, '');
      }
    })
  }

  EditQuestion(ansId:any){
    this.router.navigate(['/edit-your-answer'], { queryParams: { questionId: this.questionId, answerId: ansId } });
  }
} 