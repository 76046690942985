<div class="banner">
    <div class="container text-center">
        <h1>{{componentName}}</h1>
        <button class="btn btn-primary" (click)="backToSearch()"><i class="fas fa-arrow-circle-left mr-2"></i>
        </button>
    </div>
</div>

<div class="pageBody cw2">
    <div class="container courseNorms">
        <form class="floatedInputForm pt-0 mb-0" [ngClass]="{submitting : loader}" [formGroup]="addCourseWork"
            (ngSubmit)="formSubmit(true)">
            <div class="spinner-border text-warning formLoader" role="status" *ngIf="loader">
                <span class="sr-only">Loading...</span>
            </div>
            <dl class="row">
                <dd class="col">
                    <select class="form-control custom-select"
                        [ngClass]="{ 'is-invalid': submitCoursework && addCourseWork.controls.course_category_id.errors }"
                        placeholder=" " formControlName="course_category_id">
                        <option *ngFor="let sub of allCategory" [value]="sub._id">{{sub.Course_Category}}
                        </option>
                    </select>
                    <label class="flLabel pl-1">Category</label>
                    <div *ngIf="submitCoursework && addCourseWork.controls.course_category_id.errors"
                        class="invalid-feedback">
                        <div *ngIf="addCourseWork.controls.course_category_id.errors.required"> Category is required
                        </div>
                    </div>
                </dd>
                <dd class="col">
                    <input type="text" class="form-control"
                        [value]="addCourseWork.get('course_work_title').value | capitalization"
                        [ngClass]="{ 'is-invalid': submitCoursework && addCourseWork.controls.course_work_title.errors }"
                        placeholder=" " formControlName="course_work_title" (blur)="keyChange($event)">
                    <label class="flLabel">Title</label>
                    <div *ngIf="submitCoursework && addCourseWork.controls.course_work_title.errors"
                        class="invalid-feedback">
                        <div *ngIf="addCourseWork.controls.course_work_title.errors.required"> Title is required</div>
                    </div>
                </dd>
            </dl>


            <dl>
                <dd class="position-relative">
                    <textarea rows="6" cols="8" class="form-control"
                        [ngClass]="{ 'is-invalid': submitCoursework && addCourseWork.controls.course_work_description.errors }"
                        formControlName="course_work_description" id="textCourseWork"></textarea>
                    <label class="flLabel">Description</label>
                    <div *ngIf="submitCoursework && addCourseWork.controls.course_work_description.errors"
                        class="invalid-feedback">
                        <div *ngIf="addCourseWork.controls.course_work_description.errors.required"> Descrption is
                            required</div>
                    </div>
                </dd>
            </dl>

            <br>
            <div class="instructorLoopHldr mb-3" formArrayName="course_work_resources" style="min-height: 20px;">
                <ng-container>
                <div class="sectBoxNorms mb-4"
                    *ngFor="let courseRes of addCourseWork.controls.course_work_resources['controls']; let courseWorkResourcesIndex=index"
                    [formGroupName]="courseWorkResourcesIndex" [ngClass]="{noRemoveBtn: courseWorkResourcesIndex == 0}">
                    <div class="whiteBx mb-0">
                        <!-- White Layer Box For Resource -->
                        <button type="button" class="btn bxClose"><i class="fas fa-times-circle"
                                (click)="removeSubmission(courseWorkResourcesIndex,'course_work_resources', 'manual')"></i></button>
                        <div class="bxTitle minHe8">
                            <span class="tl">Resource</span>
                            <input type="text" class="secTtleInput" formControlName="resource_title">
                            <div class="invalid-feedback">
                                <div>Resource title is required</div>
                            </div>
                            <ng-container>
                                <div class="p-3 mb-4">
                                    <div class="row mb-3">
                                        <label class="col-12 col-md-3 pb-2 pb-md-0 m-0 pt0">
                                            Enter URL: <br><small>or Add
                                                File:</small>
                                        </label>
                                        <div class="col-12 col-md-9">
                                            <div class="progress form-group" style="height: 8px;"
                                                *ngIf="progressBar > 0 && (uploaded_index == courseWorkResourcesIndex)">
                                                <div class="progress-bar progress-bar-striped bg-success"
                                                    role="progressbar" [style.width.%]="progressBar">
                                                </div>
                                            </div>
                                            <div class="inputNBrowse">
                                                <mat-form-field class="example-full-width">
                                                    <input type="text" class="form-control"
                                                        (focus)="setInd(courseWorkResourcesIndex)"
                                                         matInput
                                                        [id]="'resource'+courseWorkResourcesIndex" aria-label="Resource"
                                                        [matAutocomplete]="auto" #inputField
                                                        [value]="set_resource_val(courseRes.controls.resource_id.value, courseWorkResourcesIndex)"
                                                        [ngClass]="{ 'is-invalid': submitCoursework && courseRes.controls.resource_url.errors }"
                                                        (change)="saveType(courseWorkResourcesIndex)">
                                                    <mat-autocomplete #auto="matAutocomplete">
                                                        <mat-option *ngFor="let option of results"
                                                            [value]="option.title"
                                                            (onSelectionChange)="searchData(option,courseWorkResourcesIndex)">
                                                           {{ option.title }}
                                                           <span *ngIf="option.id == ''">(Add URL)</span>
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                </mat-form-field>

                                                <!-- <div class="custom-file fltdBrowse">
                                                    <input type="file" class="custom-file-input"
                                                        [id]="'upCourseImageresarr'+courseWorkResourcesIndex"
                                                        (change)="uploadResourse($event, courseWorkResourcesIndex, 'course_work_resources', courseRes.value.resource_title, 'file')">
                                                    <label class="custom-file-label"
                                                        [for]="'upCourseImageresarr'+courseWorkResourcesIndex"></label>
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div> <!-- End: \ White Layer Box For Resource -->

                </div>
                </ng-container>
                <div class="text-right">
                    <button type="button" (click)="addcourseWorkResource()"
                        class="resourceAddBtn btn btn-primary btn-sm">
                        <i class="fas fa-plus-circle mr-2"></i>Add Resource
                    </button>
                </div>
            </div>

            <br />
            <dl>
                <dd>

                    <tag-input [formControlName]="'tag'" [addOnBlur]="true" [clearOnBlur]="true"
                        [secondaryPlaceholder]="'Enter a tag'" [displayBy]="'tag'" [identifyBy]="'_id'"
                        [ngClass]="{ 'is-invalid': submitCoursework && addCourseWork.controls.tag.errors }">
                        <tag-input-dropdown [autocompleteItems]="submissionTags" [displayBy]="'tag'"
                            [identifyBy]="'_id'">
                        </tag-input-dropdown>
                    </tag-input>
                    <label class="flLabel">Tags</label>
                    <div *ngIf="submitCoursework && addCourseWork.controls.tag.errors" class="invalid-feedback">
                        <div *ngIf="addCourseWork.controls.tag.errors.required"> Tag is required</div>
                    </div>
                </dd>
            </dl>

            <h5 class="mb-1">Submission Schedule</h5>
            <div class="table-wrap-x">
                <div class="table-responsive">
                    <table class="table alt">
                        <thead>
                            <tr class="text-center">
                                <th>Name</th>
                                <th class="text-center">Marks {{totalPercentage}} %</th>
                                <th class="text-center">Days {{TotalDays}}</th>
                                <th class="w200p">Rubric</th>
                                <th class="w200p">Presentation</th>
                                <th class="w200p">Autograde</th>
                                <th>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody formArrayName="submission_requirement"
                            *ngFor="let opt of submissionOptions; let submissionId = index;">
                            <tr [formGroupName]="submissionId">
                                <td>
                                    <input type="text" class="form-control bg-white" [ngClass]="{ 'is-invalid': submitCoursework && submissionOptions[submissionId].controls.submission_report
                                    .errors }" formControlName="submission_report"
                                    [value]="submissionOptions[submissionId].get('submission_report').value | capitalization">
                                <div *ngIf="submitCoursework && submissionOptions[submissionId].controls.submission_report.errors"
                                    class="invalid-feedback">
                                    <div
                                        *ngIf="submissionOptions[submissionId].controls.submission_report.errors.required">
                                        Name is
                                        required</div>
                                </div>
                            </td>
                            <td>
                                <input type="text"
                                    (input)="stripText(opt.controls['total_number_of_percentage'], 'Percentage')"
                                    [ngClass]="{ 'is-invalid': submitCoursework && submissionOptions[submissionId].controls.total_number_of_percentage.errors }"
                                    class="form-control" formControlName="total_number_of_percentage">
                                <div *ngIf="submitCoursework && submissionOptions[submissionId].controls.total_number_of_percentage.errors"
                                    class="invalid-feedback">
                                    <div
                                        *ngIf="submissionOptions[submissionId].controls.total_number_of_percentage.errors.required">
                                        Marks Percentage is
                                        required</div>
                                    <div
                                        *ngIf="submissionOptions[submissionId].controls.total_number_of_percentage.errors.min">
                                        Marks can't be zero</div>
                                </div>

                                </td>

                                <td>
                                    <input type="text" (input)="stripText(opt.controls['days_of_completion'], 'days')"
                                        [ngClass]="{ 'is-invalid': submitCoursework && submissionOptions[submissionId].controls.days_of_completion.errors }"
                                        class="form-control bg-white" formControlName="days_of_completion">
                                    <div *ngIf="submitCoursework && submissionOptions[submissionId].controls.days_of_completion.errors"
                                        class="invalid-feedback">
                                        <div
                                            *ngIf="submissionOptions[submissionId].controls.days_of_completion.errors.required">
                                            Day is
                                            required</div>
                                        <div
                                            *ngIf="submissionOptions[submissionId].controls.days_of_completion.errors.min">
                                            Day can't be zero</div>
                                    </div>
                                </td>

                                <td>
                                    <textarea rows="3" cols="6"
                                        [value]="submissionOptions[submissionId].get('rubric').value | capitalization"
                                        class="form-control bg-white" formControlName="rubric"
                                        [ngClass]="{ 'is-invalid': submitCoursework && submissionOptions[submissionId].controls.rubric.errors }">
                                </textarea>
                                    <div *ngIf="submitCoursework && submissionOptions[submissionId].controls.rubric.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="submissionOptions[submissionId].controls.rubric.errors.required">
                                            Rubric is
                                            required</div>
                                    </div>
                                </td>

                                <td class="text-center">
                                    <div class="custom-control custom-checkbox d-inline-block">
                                        <input type="checkbox" class="custom-control-input"
                                            [id]="'onlinePresentationChk'+(submissionId)"
                                            formControlName="online_presentation">
                                        <label class="custom-control-label"
                                            [for]="'onlinePresentationChk'+(submissionId)"></label>
                                    </div>
                                </td>
                                <td class="text-center">
                                    <div class="autograde-flex">
                                        <div class="custom-control custom-checkbox mb-2">
                                            <input type="checkbox" class="custom-control-input"
                                                [id]="'autograde'+(submissionId)"
                                                (click)="check_auto_grad($event, submissionId)"
                                                [attr.readonly]="is_disabled(submissionId)"
                                                formControlName="autograding">
                                            <label class="custom-control-label"
                                                [for]="'autograde'+(submissionId)"></label>
                                        </div>
                                        <button *ngIf="submissionOptions[submissionId].controls.autograding.value" class="btn btn-primary mb-1"
                                            [disabled]="!submissionOptions[submissionId].controls.autograding.value"
                                            type="button" (click)="openautogradeModal(content, submissionId)">Test
                                            Cases</button>
                                        <!-- <p><a href="#" class="link-btn">View Demo</a></p> -->
                                    </div>
                                </td>

                                <td>
                                    <button type="button" class="btn btn-danger" *ngIf="submissionId != 0"
                                        (click)="removeSubmission(submissionId, 'submission_requirement', 'manual')"><i
                                            class="fas fa-times"></i></button>
                                </td>
                            </tr>
                        </tbody>
                        <!-- <tbody>
                        <tr>
                            <td colspan="7">
                               
                            </td>
                        </tr>
                    </tbody> -->
                    </table>
                </div>
            </div>
            <div class="butomSumnitTable mb-2">
                <button type="button" class="btn btn-primary" (click)="addsubmission()">
                    <i class="fas fa-plus-circle mr-2"></i>Add Submission
                </button>
            </div>
            <dl class="row align-items-center">
                <dd class="col col-6 col-lg-2">
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="isgroupChk" formControlName="is_group">
                        <label class="custom-control-label" for="isgroupChk">Is group Project?</label>
                    </div>
                </dd>
                <dd class="col col-6 col-lg-2">
                    <div *ngIf="addCourseWork.value.is_group">
                        <input type="text" class="form-control"
                            (input)="stripText(addCourseWork.controls.group_size, 'group_size')" placeholder=" "
                            formControlName="group_size">
                        <label class="flLabel">Group Size</label>
                    </div>
                </dd>
            </dl>
            
            <div class="text-right">
                <button type="button" class="btn btn-primary" [disabled]="save_draft" (click)="formSubmit(false)">Save
                    as Draft</button>
                <button type="submit" class="btn btn-primary ml-2">Publish</button>
            </div>
        </form>
    </div>
</div>


<ng-template #content let-modal>
    <div class="modal_cont">
        <div class="modal-header modal_cont_Header">
            <h3>Add Test Cases</h3>
            <button class="close" type="button"><span aria-hidden="true" (click)="back()">×</span></button>
        </div>
        <div class="modal-body">
            <div class="resourseInputOut">
                <form [formGroup]="testCases">
                    <div class="resourseTestItem form-group" formArrayName="text_case">
                        <div *ngFor="let textcase of testCases.controls.text_case['controls']; let test_index = index;"
                            [formGroupName]="test_index">
                            <label>Test Case - {{test_index+1}}</label>
                            <div class="resourseTestItemCont" formArrayName="_inputarr">
                                <div *ngFor="let input_arr of textcase.controls._inputarr['controls']; let input_index = index;"
                                    [formGroupName]="input_index">
                                    <button class="close-btn lg-close" type="button" *ngIf="test_index != '0'"
                                        (click)="delete_test_case('text_case', test_index, '')">X</button>
                                    <div class="form-group">
                                        <!-- <label>Input</label> -->
                                        <!-- <div class="resourseTestInputCont"> -->
                                        <div class="form-group">
                                            <label>Input - {{input_index+1}}</label>
                                            <div class="resourseTestInputBlock">
                                                <input type="text" formControlName="input" class="form-control" />
                                                <button class="close-btn sm-close" type="button"
                                                    *ngIf="input_index != '0'"
                                                    (click)="delete_test_case('_inputarr', test_index, input_index)">X</button>
                                            </div>
                                        </div>
                                        <!-- </div> -->
                                    </div>
                                </div>
                                <div class="text-right">
                                    <button class="btn btn-primary sm-btn" (click)="add_inputs(test_index)">+ Add
                                        Input</button>
                                </div>
                            </div>

                            <div class="form-group">
                                <label>Output</label>
                                <input type="text" formControlName="output" class="form-control" />
                            </div>

                        </div>

                        <div class="form-group text-right">
                            <button class="btn btn-primary" type="button" (click)="add_test_case()">+ Add New Test
                                Case</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <hr class="dashed-hr" />
        <div class="modal_footer btn_group text-right">
            <button class="btn btn-default" (click)="back()" type="button">Cancel</button>
            <button class="btn btn-primary" (click)="save_test_cases()" type="button">Save</button>
        </div>
    </div>
</ng-template>