import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ec-user-profile',
  templateUrl: './ec-user-profile.component.html',
  styleUrls: ['./ec-user-profile.component.scss']
})
export class EcUserProfileComponent implements OnInit {

  active = 'pinfo';
  activeChild = 'security';
  page_name = 'pinfo';
  constructor() { }
  my_page(value) {
    this.page_name = value;
  }

  ngOnInit() { }
  Zoom_file() {
    let fileName = 'ZOOM JWT APP Implemetation.pdf';
    window.open("assets/zoom/" + fileName, '_blank');
  }

}
