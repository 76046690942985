import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BulkUploadService {
  apiEndpoint: any;
  constructor(private http: HttpClient) {
    this.apiEndpoint = environment.APIEndpoint;
  }

  bulk_upload(body: any): Observable<any> {
    const formData = new FormData();
    formData.append('file', body.file);
    formData.append('org_type', body.type);
    formData.append('organisation', body.Institute);
    formData.append('role', body.role);
    formData.append('courses', '['  +body.course + ']');
    return this.http.post<any>(this.apiEndpoint + '/signup/bulk', formData).pipe(
      catchError(this.handleError)
    );
  }

  handleError(error) {

    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(error);
  }

  getOrganizationType() {
    return this.http.get<any>(this.apiEndpoint + '/organisations/types').pipe(catchError(this.handleError));
  }

  //FOR BULK UPLOAD USER WITH PROGRESS
  bulkUploadUsers(body: any): Observable<any> {
    let params = new HttpParams();

    const formData = new FormData();
    formData.append('file', body.file);
    formData.append('org_type', body.type);
    formData.append('organisation', body.Institute);
    formData.append('role', body.role);
    formData.append('courses', '['  +body.course + ']');

    return this.http.post<any>(this.apiEndpoint + '/signup/bulk', formData, {
      params,
      reportProgress: true,
      observe: 'events'
    });
  }
}

