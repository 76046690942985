import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HandleUploadService {

  addForm: any = null;

  //TO REMOVE RESOURCES
  allUploadedFiles: any[] = [];
  allDeletedFiles: any[] = [];

  //FOR SUBMIT FORM STATUS SHARE
  private submitFormSubject = new Subject<any>();
  public submitFormStatus = this.submitFormSubject.asObservable();

  //FOR SEND FORM DATA
  private sendSharedDataSubject = new Subject<any>();
  public getSharedData = this.sendSharedDataSubject.asObservable();

  constructor() { }

  sendSubmitFormStatus(data: any) {
    this.submitFormSubject.next(data);
  }

  sendSharedData(data: any) {
    this.sendSharedDataSubject.next(data);
  }
}
