<div class="" [ngClass]="{submitting : processLoader || isSubmissionDataLoading}">
    <div>
        <div *ngIf="showReport" class="report-wrapper">
            <div class="grade">
                <hr>
                <p class="primary-text mb-4 mt-4"><span *ngIf="(editBtnRef != 'grade' || !showGradeForm)" class="cw-grade-heading">Grade :</span>
                    <span *ngIf="(editBtnRef != 'grade' || !showGradeForm) && !isSubmissionDataLoading" class="cw-grade ml-2">{{gradeReport}}</span>
                    <a *ngIf="(showGradeReviewEditBtn && (editBtnRef != 'grade' || !showGradeForm) && !isSubmissionDataLoading) && checkPremission()" class="float-right" ngbTooltip="Edit"
                        (click)="onEditReview('grade')"><i class="fas fa-pencil-alt"></i></a>
                </p>
                <!-- EDIT GRADE REMARK FORM |START -->
                <div>
                    <app-course-work-marking-remark-grade *ngIf="showGradeForm && editBtnRef == 'grade'" [editBtnClicked]="editBtnClicked"
                        [editBtnRef]="editBtnRef" [isSuperAdmin]="isSuperAdmin" [isTeachingAssistant]="isTeachingAssistant" [isPublishRight]="isPublishRight" [submissionReportDetail]="submissionReportDetail" [courseworks]="courseworks"
                        [viewSubmissionData]="viewSubmissionData" [findedIndex]="findedIndex" [submissionData]="submissionData"
                        [showCourseWork]="showCourseWork" [courseId]="courseId"
                        (sendGradeReviewSavedStatus)="getGradeReviewSavedStatus($event)">
                    </app-course-work-marking-remark-grade>
                </div>
                <!-- EDIT GRADE REMARK FORM |END -->
            </div>

            <!-- {{viewSubmissionData | json}} -->
            <div class="report-review-wrapper">
                <div>
                    <hr>
                    <p class="primary-text mb-2 mt-4"><span class="reviews-heading">Reviews:</span></p>
                </div>

                <div class="teaching-assistant">
                    <p class="primary-text mb-2 mt-4">
                        <span class="reviews-sub-heading">Final Review</span>
                        <a *ngIf="(showGradeReviewEditBtn && (editBtnRef != 'remark' || !showGradeForm) && !isSubmissionDataLoading) && checkPremission()" class="float-right" ngbTooltip="Edit"
                            (click)="onEditReview('remark')"><i class="fas fa-pencil-alt"></i></a>
                    </p>
                    <p *ngIf="(editBtnRef != 'remark' || !showGradeForm) && !isSubmissionDataLoading" class="reviews-content">
                        {{remarkReport | capitalization}}
                    </p>
                    <!-- EDIT GRADE REMARK FORM |START -->
                    <div>
                        <app-course-work-marking-remark-grade *ngIf="showGradeForm && editBtnRef == 'remark'" [editBtnClicked]="editBtnClicked"
                            [editBtnRef]="editBtnRef" [isSuperAdmin]="isSuperAdmin" [isTeachingAssistant]="isTeachingAssistant" [isPublishRight]="isPublishRight" [submissionReportDetail]="submissionReportDetail" [courseworks]="courseworks"
                            [viewSubmissionData]="viewSubmissionData" [findedIndex]="findedIndex" [submissionData]="submissionData"
                            [showCourseWork]="showCourseWork" [courseId]="courseId"
                            (sendGradeReviewSavedStatus)="getGradeReviewSavedStatus($event)">
                        </app-course-work-marking-remark-grade>
                    </div>
                    <!-- EDIT GRADE REMARK FORM |END -->
                </div>

                <div *ngIf="courseworks.peer_review == true" class="peer">
                    <p class="primary-text mb-2 mt-4">
                        <span class="reviews-sub-heading">Peer Review</span>
                        <a *ngIf="showPeerReviewEditBtn && (editReviewType != 'peer_review' || !showReviewForm)" class="float-right" ngbTooltip="Edit"
                            (click)="onEditReview('peer_review')"><i class="fas fa-pencil-alt"></i></a>
                    </p>
                    <p *ngIf="editReviewType != 'peer_review' || !showReviewForm" class="reviews-content">
                        {{peerReviewReport | capitalization}}
                    </p>
                    <!-- EDIT REVIEW FORM |START -->
                    <div *ngIf="showReviewForm && editReviewType == 'peer_review'">
                        <div *ngIf="!showReport">
                            <hr>
                            <p class="primary-text mb-2 mt-4"><span class="reviews-heading">Reviews:</span></p>
                        </div>
                        <div class="form-group">
                            <div>
                                <form [formGroup]="reviewForm" autocomplete="off">
                                    <textarea type="text" formControlName="review" id="reviewInputBox"
                                        class="mt-2 form-control reviews-textarea" cols="10" rows="6"
                                        [value]="reviewForm.get('review').value | capitalization"></textarea>
                                    <div class="text-center mt-4">
                                        <div class="spinner-border text-warning formLoader d-inline-block mr-4 inline-loader"
                                            *ngIf="processLoader" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                        <button type="button" *ngIf="editReviewType == 'peer_review'" class="btn btn-primary btn-w-120 mr-2"
                                            (click)="onDiscardReview()" [disabled]="processLoader">
                                            Discard
                                        </button>
                                        <button type="button" class="btn btn-primary btn-w-120" (click)="onSaveReview()"
                                            [disabled]="processLoader">
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <!-- EDIT REVIEW FORM |END -->
                </div>
                <div *ngIf="courseworks.self_review == true" class="self">
                    <p class="primary-text mb-2 mt-4">
                        <span class="reviews-sub-heading">Self Review</span>
                        <a *ngIf="showSelfReviewEditBtn && (editReviewType != 'self_review' || !showReviewForm)" class="float-right" ngbTooltip="Edit"
                            (click)="onEditReview('self_review')"><i class="fas fa-pencil-alt"></i></a>
                    </p>
                    <p *ngIf="editReviewType != 'self_review' || !showReviewForm" class="reviews-content">
                        {{selfReviewReport | capitalization}}
                    </p>
                    <div *ngIf="showReviewForm && editReviewType == 'self_review'">
                        <div *ngIf="!showReport">
                            <hr>
                            <p class="primary-text mb-2 mt-4"><span class="reviews-heading">Reviews:</span></p>
                        </div>
                        <!-- EDIT REVIEW FORM |START -->
                        <div class="form-group">
                            <div>
                                <form [formGroup]="reviewForm" autocomplete="off">
                                    <textarea type="text" formControlName="review" id="reviewInputBox"
                                        class="mt-2 form-control reviews-textarea" cols="10" rows="6"
                                        [value]="reviewForm.get('review').value | capitalization"></textarea>
                                    <div class="text-center mt-4">
                                        <div class="spinner-border text-warning formLoader d-inline-block mr-4 inline-loader"
                                            *ngIf="processLoader" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                        <button type="button" *ngIf="editReviewType == 'self_review'" class="btn btn-primary btn-w-120 mr-2"
                                            (click)="onDiscardReview()" [disabled]="processLoader">
                                            Discard
                                        </button>
                                        <button type="button" class="btn btn-primary btn-w-120" (click)="onSaveReview()"
                                            [disabled]="processLoader">
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <!-- EDIT REVIEW FORM |END -->
                    </div>
                </div>
            </div>
        </div>

        <div>
            <app-course-work-marking-remark-grade *ngIf="showGradeForm && !editBtnRef" [editBtnClicked]="editBtnClicked"
                [editBtnRef]="editBtnRef" [isSuperAdmin]="isSuperAdmin" [isTeachingAssistant]="isTeachingAssistant" [isPublishRight]="isPublishRight" [submissionReportDetail]="submissionReportDetail" [courseworks]="courseworks"
                [viewSubmissionData]="viewSubmissionData" [findedIndex]="findedIndex" [submissionData]="submissionData"
                [showCourseWork]="showCourseWork" [courseId]="courseId"
                (sendGradeReviewSavedStatus)="getGradeReviewSavedStatus($event)">
            </app-course-work-marking-remark-grade>
        </div>

    </div>
</div>
