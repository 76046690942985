import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { BulkUploadService } from '../services/bulk-upload.service';
import { CourseService } from '../services/course.service';
import { NotificationService } from '../services/notification.service';

@Component({
  selector: 'app-add-organisation',
  templateUrl: './add-organisation.component.html',
  styleUrls: ['./add-organisation.component.scss']
})
export class AddOrganisationComponent implements OnInit {
  loader = false;
  add_organization_form: FormGroup;
  save_organization = 'Add Organization';
  organization_id: string;
  organization_list = [];
  organization_name = ''
  org_type_id = '';
  submit_org = false;
  constructor(private fb: FormBuilder,
    private course_service: CourseService,
    private bulk_upload: BulkUploadService,
    private ActivatRoute: ActivatedRoute,
    private router: Router,
    private ns: NotificationService) { }
  ngOnInit() {
    this.getOrganizationtype();
    this.organization_id = this.ActivatRoute.snapshot.queryParamMap.get('organisation_id');
    this.organization_name = this.ActivatRoute.snapshot.queryParamMap.get('org_name');
    this.org_type_id = this.ActivatRoute.snapshot.queryParamMap.get('type');

    this.add_organization_form = this.fb.group({
      organization_name: ['', Validators.required],
      org_type: ['', Validators.required]
    })
    if (this.organization_id) {
      this.editOrganisation();
    }
  }

  get organization_form() { return this.add_organization_form.controls; }

  add_organization() {
    this.submit_org = true;
    if ((/[^a-z A-Z\-\/]/).test(this.add_organization_form.value.organization_name)) {
      this.ns.showNotification('info', 'we only allows characters for organization')
      return;
    }
    if (this.add_organization_form.invalid) {
      this.ns.showNotification('error', "Please add all the organization details");
      return
    }
    if (!this.organization_id) {
      this.add_organization_api();
    } else if (this.organization_id) {
      this.patch_organization();
    }
  }

  editOrganisation() {
    this.add_organization_form.controls['organization_name'].setValue(this.organization_name);
    this.add_organization_form.controls['org_type'].setValue(this.org_type_id)
    this.save_organization = 'Edit Organization';
  }
  getValue(event) {
    if (!event.target.value) {
      this.save_organization = 'Add Organization';
    }
  }

  add_organization_api() {
    this.loader = true;
    this.course_service.add_organization(this.add_organization_form.value).pipe(finalize(() => {
      this.loader = false;
      this.submit_org = false;
    })).subscribe(organization => {
      this.ns.showNotification('success', "Add the organization as an educollab user");
      this.add_organization_form.reset();
      this.add_organization_form.controls['org_type'].setValue('');
      this.router.navigate(['/search_organisation']);
      this.organization_id = '';
    },
      (error) => {
        this.ns.showNotification('error', error.message);
      })
  }
  patch_organization() {
    this.loader = true;
    let name_org = '';
    this.add_organization_form.value.organization_name == this.organization_name ? name_org = '' : name_org = this.add_organization_form.value.organization_name

    let body = {
      org_id: this.organization_id,
      name: name_org,
      org_type_id: this.add_organization_form.value.org_type
    }
    this.course_service.patch_organization(body).pipe(finalize(() => {
      this.loader = false;
      this.submit_org = false;
    })).subscribe(org => {
      this.ns.showNotification('success', org.message);
      this.add_organization_form.reset();
      this.organization_id = '';
      this.add_organization_form.controls['org_type'].setValue('');
      this.save_organization = 'Add Organization';
      this.router.navigate(['/search_organisation']);

    },
      (err) => {
        this.ns.showNotification('error', err.message);
      })
  }
  org_type = [];
  getOrganizationtype() {
    this.loader = true;
    this.bulk_upload.getOrganizationType().pipe(finalize(() => {
      this.loader = false;
    })).subscribe(getType => {
      this.org_type = getType;
    })
  }
  reset() {
    this.add_organization_form.reset();
    this.add_organization_form.controls['org_type'].setValue('');
    this.save_organization = 'Add Organization';
    this.organization_id = '';
    this.router.navigate(['/add_organisation']);
  }
}
