import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpEventType, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UploadFilesService {
  fileSizeUnit: number = 1024;
  apiEndpoint: any;

  public isApiSetup = true;

  constructor(private http: HttpClient) {
    this.apiEndpoint = environment.APIEndpoint;

   }

  getFileSize(fileSize: number): number {
    if (fileSize > 0) {
      if (fileSize < (this.fileSizeUnit * this.fileSizeUnit)) {
        fileSize = parseFloat((fileSize / this.fileSizeUnit).toFixed(2));
      } else if (
        fileSize <
        (this.fileSizeUnit * this.fileSizeUnit * this.fileSizeUnit)
      ) {
        fileSize = parseFloat(
          (fileSize / this.fileSizeUnit / this.fileSizeUnit).toFixed(2)
        );
      }
    }

    return fileSize;
  }

  getFileSizeUnit(fileSize: number) {
    let fileSizeInWords = 'bytes';

    if (fileSize > 0) {
      if (fileSize < this.fileSizeUnit) {
        fileSizeInWords = 'bytes';
      } else if (fileSize < this.fileSizeUnit * this.fileSizeUnit) {
        fileSizeInWords = 'KB';
      } else if (
        fileSize <
        this.fileSizeUnit * this.fileSizeUnit * this.fileSizeUnit
      ) {
        fileSizeInWords = 'MB';
      }
    }

    return fileSizeInWords;
  }

  uploadMedia(formData: any,moduleName) {
    let params = new HttpParams();
    // const headers = new HttpHeaders().set('Content-Type', 'application/json');
    params = params.append('module_name', moduleName);

    return this.http
      .post(this.apiEndpoint + "/upload-resource", formData, {
        // headers,
        params,
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        map((event) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              const progress = Math.round((100 * event.loaded) / event.total);
              return { status: 'progress', message: progress };

            case HttpEventType.Response:
              return event.body;
            default:
              return `Unhandled event: ${event.type}`;
          }
        })
      );
  }

  
}
