import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbRatingConfig } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';
import { allCategory, CourseInfo } from 'src/app/model/course.model';
import { CourseService } from 'src/app/services/course.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ShareService } from 'src/app/services/share.service';

@Component({
  selector: 'app-teach',
  templateUrl: './teach.component.html',
  styleUrls: ['./teach.component.scss']
})
export class TeachComponent implements OnInit {

  @Input() allCategory: allCategory[] = [];
  @Input() categoryVisDetails: any = [];
  @Input() total_pages: number;
  @Input() current_page: number
  @Input() number_of_page;
  @Output() send_index_number = new EventEmitter();
  currentRate: number;
  role = '';
  loader = false;
  get permissions() {
    return JSON.parse(localStorage.getItem("permissions"));
  }


  constructor(
    private courseService: CourseService,
    private router: Router,
    private ns: NotificationService,
    rateConfig: NgbRatingConfig,
    private shareService: ShareService) {
    rateConfig.readonly = true;
    this.role = localStorage.getItem('role');
  }

  ngOnInit() {
    this.send_index_number.emit('1+teach');
  }
  my_page(p) {
    console.log(p);
  }
  sendEditDetails(id, course) {
    this.router.navigate(['/courses/add'], { queryParams: { courseId: id, course: course } });
  }

  sendCourseDetails(id, course) {
    this.router.navigate(['/courses/single-course'], { queryParams: { course_id: id, course: course } });
  }

  redirect(link) {
    this.router.navigate([link]);
  }
  // Delete course
  delCourse(courseId) {
    this.loader = true;
    if (confirm("Are you sure you want to deactivate this course?")) {
      this.courseService.deactivateCourse(courseId).pipe(finalize(() => {
        this.loader = false;
      })).subscribe(req => {
        this.ns.showNotification("success", req.Status);
        this.send_index_number.emit(this.current_page+'+teach');
      },
        (err) => {
          this.ns.showNotification("error", err.status);
        })
    }
  }
  
  is_true
  makeCourseAvailableToall(courseId) {
    if (confirm("Are you sure you want to make this course universal?")) {
      this.loader = true;
      this.courseService.makeCourseUniversalforTeach(courseId).pipe(finalize(() => {
        this.loader = false;
      })).subscribe(req => {
        this.ns.showNotification("success", req.message);
        // this.onChangeCategory('');
      },
        (err) => {
          this.ns.showNotification("error", err.message);
        })
    }
  }

  findIndexOf(slug, pmenusSlug) {
    let indexOfValue;
    indexOfValue = this.shareService.getIndexArr(slug, pmenusSlug)
    return indexOfValue;
  }

  Send_index(number) {
    this.send_index_number.emit(number+'+teach');
  }

  check_previous(type) {
    if (type == 'Previous') {
      return this.number_of_page &&  (this.current_page == this.number_of_page[0] )? true : false;
    }
  }

  check_next(type) {
    if (type == 'Next') {
      return this.current_page == this.total_pages ? false : true;
    }
  }
  
  get_number(number_of_page) {
    if(number_of_page) {
      return number_of_page
    } 
    return 1;
  }
  
}
