import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { NotificationService } from 'src/app/services/notification.service';
import { finalize } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-security-settings',
  templateUrl: './security-settings.component.html',
  styleUrls: ['./security-settings.component.scss']
})
export class SecuritySettingsComponent implements OnInit {

  isShownUpdate:boolean = false;
  buttonName:any = "Change Password";
  passwordForm: FormGroup;
  submitSettingForm: boolean;
  loader: boolean;

  get getSecuritySetting(){
    return this.passwordForm.controls;
  }

  constructor(    private userService: UserService,
    private fb: FormBuilder,
    private ns: NotificationService,
    private router: Router) { }

  ngOnInit() {
    this.validateForm()
  }

  validateForm(){
    this.passwordForm = this.fb.group({
      password: ['', [Validators.maxLength(30),Validators.required]],
      newpassword: ['', [Validators.minLength(8),Validators.maxLength(30),Validators.required]],
      confirmpassword: ['',[Validators.minLength(8),Validators.maxLength(30),Validators.required]],
    },
    {
      validator: this.userService.MustMatch('newpassword', 'confirmpassword')
    })
  }

  
  saveSetting(data){
    this.submitSettingForm = true;
    if(this.passwordForm.invalid) return;
    

    let obj:any={};
    obj.old_password = data.value.password,
    obj.new_password = data.value.newpassword


    this.loader = true;
    this.userService.change_password(obj).pipe(
      finalize(() => {
        this.loader = false;
      })).subscribe((res) => {
        // console.log(res);
        this.passwordForm.reset();
        this.ns.showNotification('success', res.message);        
      },
      (err)=>{
        this.ns.showNotification('error', err.message);        
      });
  }
  
  changePass() {
    this.isShownUpdate = ! this.isShownUpdate;
  }

}
