import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PassionProjects } from '../model/PassionProject';

@Injectable({
  providedIn: 'root'
})
export class SharedPassionProjectService {
  private passionProjectSource = new BehaviorSubject<object>({});

  passionProject  = this.passionProjectSource.asObservable();

  constructor() { }

  changePassionProject(passionProject: PassionProjects) {
    this.passionProjectSource.next(passionProject);
  }
}
