import { Component, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { CourseService } from 'src/app/services/course.service';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-teaching-assistants',
  templateUrl: './teaching-assistants.component.html',
  styleUrls: ['./teaching-assistants.component.scss']
})
export class TeachingAssistantsComponent implements OnInit {

  assignType = 'manual';
  subscriberList:any;
  loader = false;
  courseId = '';
  getSubscribeStudentList = [];
  courseName = '';
  isSubscriberChecked:boolean = false;
  selectedTechindex:number = 0;
  hasItem:boolean = false;
  resetAll:boolean = false;
  constructor(private ns: NotificationService, private courseService: CourseService, private route: Router, private activatedRoute: ActivatedRoute,) { }

  ngOnInit() {
    this.courseId = this.activatedRoute.snapshot.queryParamMap.get('courseId');
    this.courseName = this.activatedRoute.snapshot.queryParamMap.get('course');
    this.selectedTechindex = 0;
    this.getassistant_list();
    this.getSubscribeStudent();
    // this.getAssignList = [];
  }

  getSubscribeStudent() {
    let role = 'student'
    this.loader = true;
    this.courseService.getSubscribeStudent(this.courseId, role).pipe(
      finalize(() => {
        this.loader = false;
      })).subscribe((getStudentList) => {
        const hasUsers_info = getStudentList.hasOwnProperty('users_info');        
        if (hasUsers_info) {          
          this.getSubscribeStudentList = getStudentList.users_info;
          this.getSubscribeStudentList.forEach((subscriber, i)=>{
              subscriber.isChecked = false;
             })
        } else{
          this.getSubscribeStudentList = [];
        }

      },
        (err) => {
          this.loader = false;
          this.ns.showNotification('info', err.message);
        });
  }

  getAssignList: any[];
  getassistant_list() {
    this.loader = true;
    this.getAssignList = [];
    this.courseService.get_assign_teams(this.courseId).pipe(
      finalize(() => {
        this.loader = false;
      })).subscribe((getAssignList) => {
        const hasResponse = getAssignList.hasOwnProperty('response');         
        if(hasResponse){
          this.getAssignList = getAssignList.response;
        } else{
          this.getAssignList = [];
        }
        this.getSubscribeStudentList.filter((subscirber, i) =>{   
          subscirber.isRemoved = false;
        })
        this.getAssignList.forEach((assistant)=>{
          assistant.assignees.filter((assignees, i) =>{   
           const index =  this.getSubscribeStudentList.findIndex(subscriber => subscriber._id === assignees._id); 4
              if (index > -1) {
                 this.getSubscribeStudentList[index].isAdded = true;
              } 
          })
        })

          this.getAssignList[this.selectedTechindex].assignees.filter((assignees, i) =>{   
           const index =  this.getSubscribeStudentList.findIndex(subscriber => subscriber._id === assignees._id); 4
              if (index > -1) {
                 this.getSubscribeStudentList[index].isRemoved = true;
              } 
          })
          this.onResetAll();
      },
        (err) => {
          this.loader = false;
          this.ns.showNotification('info', err.message);
        });
  }



  submit() {
    this.loader = true;
    
    this.courseService.save_assiatnt(this.getAssignList, this.courseId).pipe(
      finalize(() => {
        this.loader = false;
      })).subscribe(getData => {
        this.ns.showNotification('success', getData.message);
      })

  }

  go_to_url(url) {
    this.route.navigate([url], { queryParams: { course_id: this.courseId, course: this.courseName } });
  }

  findValue(result, index) {
    return result;
  }


  assignStudentBtn(subscriber, isVal, assIndex){
    const index =  this.getAssignList[this.selectedTechindex].assignees.findIndex(assignees => assignees._id === subscriber._id);
      if (index == -1) {
        this.getAssignList[this.selectedTechindex].assignees.push(subscriber);
        this.getSubscribeStudentList[assIndex].isAdded = true;
        this.getSubscribeStudentList[assIndex].isRemoved = true;
      }
      this.onResetAll();
  }

  selectedTech(teach_assis, index){   
    if(!index){
      this.selectedTechindex = 0;
    } else {
      this.selectedTechindex = index;
    }
    this.getSubscribeStudentList.forEach(subStudent =>{
      subStudent.isRemoved = false;
     })

    if(this.getAssignList[this.selectedTechindex].assignees.length > 0){

      this.getSubscribeStudentList.filter((subscirber, i) =>{   
        subscirber.isRemoved = false;
      })

      this.getAssignList[this.selectedTechindex].assignees.filter((assignees, i) =>{
        const index =  this.getSubscribeStudentList.findIndex(subscriber => subscriber._id === assignees._id);                      
        
          if (index > -1) {
              this.getSubscribeStudentList[index].isAdded = true;
              this.getSubscribeStudentList[index].isRemoved = true;
            }       
       })
    }
  }

  // shuffleList(){
   
    
    // var data = this.getSubscribeStudentList;
    // var chunksize = this.getAssignList.length;
    // var chunks = [];
    
    // data.forEach((item)=>{
    //   if(!chunks.length || chunks[chunks.length-1].length == chunksize)
    //   chunks.push([]);
    
    //   this.getAssignList.forEach((item2)=>{
    //     item2.assignees.push(item._id);
    //   })
    //   // chunks[chunks.length-1].push(item._id);
    //   console.log(chunks);
    // });
    
  // }


  onResetAll(){
    let  sum = 0;
    this.getAssignList.forEach(num => { sum += num.assignees.length });
    if(sum >= 1){
      this.resetAll = true;
    }
  }

  offResetAll(){
    let  sum = 0;
    this.getAssignList.forEach(num => { sum += num.assignees.length });
    if(sum == 0){
      this.resetAll = false;
    }
  }

  removefromTechAssis(subscriber, indexx){
    const index =  this.getAssignList[this.selectedTechindex].assignees.findIndex(assignees => assignees._id === subscriber._id);
    if (index > -1) {
      this.getAssignList[this.selectedTechindex].assignees.splice(index, 1);
      this.getSubscribeStudentList[indexx].isRemoved = false;
      this.getSubscribeStudentList[indexx].isAdded = false;
    }
    this.offResetAll();
  }

  resetAllList(){
    this.getAssignList.forEach(assignSubList => {
      assignSubList.assignees = [];
    });
    this.getSubscribeStudentList.forEach(subscriber => {
      subscriber.isRemoved = false;
      subscriber.isAdded = false;
    });
    this.offResetAll();
  }

  findSub(subId){
    // console.log(subId);
      this.getAssignList.forEach((item)=>{
           item.assignees.filter((ele, index)=>{
            if(ele._id === subId._id){
              
            }
          })
      })
  }

  //FOR BUILD FIX
  assistList: any;
}