import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';
import { QuestionBankService } from 'src/app/services/question-bank.service';
import { CourseService } from 'src/app/services/course.service';
import { debounceTime, distinctUntilChanged, finalize, tap } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { NotificationService } from 'src/app/services/notification.service';
import { AssessmentShareService } from 'src/app/services/assessment-share.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { fromEvent } from 'rxjs';
import { error } from '@angular/compiler/src/util';


@Component({
  selector: 'app-create-assessment',
  templateUrl: './create-assessment.component.html',
  styleUrls: ['./create-assessment.component.scss']
})
export class CreateAssessmentComponent implements OnInit {
  public assessmentForm: FormGroup;
  @ViewChild('searchInput', {static: false}) searchInput: ElementRef;
  loader = false;
  @Input() save_button = true;
  // allQues: any[] = [];
  search_text;
  isQusAvailable: boolean = false;
  qusList: any[];
  selectedQusArr = [];
  assessmentQus = [];
  assessmentTags = [];
  a_Tags = [];
  assessment_id: string;
  componentName: string = 'Create Assessment';
  editAssessmentData: any;
  search;
  isShown: boolean = false;
  alertMsg = '';
  draft = ''
  titleVisible: boolean = false;
  submitAssessmentForm: boolean = false;
  active = 2;
  role;

  @Output() closeAssessmentPopUp = new EventEmitter();


  constructor(private _questionBankService: QuestionBankService,
    private formBuilder: FormBuilder,
    private courseService: CourseService,
    private _notificationService: NotificationService,
    private _assessmentShareService: AssessmentShareService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    this.role = localStorage.getItem('role');
    this.titleVisible = true;
    this.validateForm();
    this.getAssessmentDetails();
    this.getAllQusTypes();
    this.getQusComplexity();
    this.getTags();
    this.getAllCourses();
    // if (this.role == 'student' && ) {

    // }
  }

  ngAfterViewInit() {
    if(this.searchInput) {
      fromEvent(this.searchInput.nativeElement,'input')
          .pipe(
              //filter(Boolean),
              debounceTime(500),
              distinctUntilChanged(),
              tap((event) => {
                 this.loader = true;
                 this.qusList = [];
                if(this.searchInput.nativeElement.value.length > 0){
                  this.getQusUsingSearch(this.searchInput.nativeElement.value)
                } else {
                  this.loader = false;
                  this.isQusAvailable = false;
                }
              })
          )
          .subscribe();
    }    
  }

  validateForm(): void {
    this.assessmentForm = this.formBuilder.group({
      title: ['', Validators.required],
      // topic: ['', Validators.required],
      assessmentType: ['', Validators.required],
      totaltime: ['', Validators.required],
      negativeMarking: [false, Validators.required],
      negativeValue: [0, Validators.compose([Validators.required, Validators.min(0), Validators.max(1)])],
      assignMarks: [true, Validators.required],
      totalMarks: [0, Validators.required]
      // meta_data: this.formBuilder.array([])
    });
  }

  get aF() { return this.assessmentForm.controls; }



  getQusUsingSearch(val) {
    this._questionBankService.fetchQus(val).pipe().subscribe(data => {
      this.qusList = [];
      if (data.length > 0) {
        this.qusList = data;
        this.isQusAvailable = true;
      }
      this.loader = false;
    }, (error)=>{
      this.loader = false;
    });
  }

  getCheckboxValues(ev, data) {
    // let obj = {
    //   "question_id" : data._id
    // }

    if (ev.target.checked) {
      // Pushing the object into array
      this.selectedQusArr.push(data);

    } else {
      let el = this.selectedQusArr.find(itm => itm === data);

      if (el)
        this.selectedQusArr.splice(this.selectedQusArr.indexOf(el), 1);
    }

    //Duplicates the obj if we uncheck it
    //How to remove the value from array if we uncheck it
    // console.log(this.selectedQusArr);
  }

  getMark = 0;
  getQusForAssesment() {
    this.getMark = this.assessmentForm.controls['totalMarks'].value;
    for (let i = 0; i < this.selectedQusArr.length; i++) {
      const found = this.assessmentQus.some(el => el._id === this.selectedQusArr[i]._id);
      if (!found) {
        this.selectedQusArr[i].marks;
        this.getMark += this.selectedQusArr[i].marks;
        this.assessmentQus.push(this.selectedQusArr[i]);
      } else {
        this._notificationService.showNotification("error", "Question already selected ");
      }
    }
    this.assessmentForm.controls['totalMarks'].setValue(this.getMark);
    this.selectedQusArr = [];
    this.assessmentForm.patchValue({
      totaltime: this.getTotalTime()
    })
    this.getTags();
    this.uncheckedselectedQus();
    this.onTotalMarks();
    this.search = '';
    this.qusList = [];
  }

  removeQues(i: number) {
    let currentVal = this.assessmentQus[i].marks;
    this.assessmentQus.splice(i, 1);
    this.getTags();
    this.onTotalMarks();
    this.removeMarks(i, currentVal);
    this.assessmentForm.patchValue({
      totaltime: this.getTotalTime()
    })
  }

  getTotalTime() {
    let total = 0;
    this.assessmentQus.forEach(element => {
      if (element.duration == "") {
        element.duration = 0;
      }
      total += parseInt(element.duration)
    });
    return total;
  }

  getTags() {
    this.a_Tags = [];
    this.assessmentTags = [];
    this.assessmentQus.forEach(element => {
      element.tags.forEach(ele => {
        if (!this.a_Tags.includes(ele._id)) {
          this.a_Tags.push(ele._id);
          this.assessmentTags.push(ele);
        }
      });

    });
  }


  stripText(control) {
    let val = control.value.replace(/[^0-9]/g, '');
    control.setValue(control.value.replace(/[^0-9]/g, ''));
    // this.minfixed(val);
  }

  getAssessmentQusIds() {
    let quesids = []
    let obj: any = {};

    this.assessmentQus.forEach(element => {
      obj = {};
      obj.question_id = element._id,
        obj.marks = element.marks
      quesids.push(obj)
    });

    return quesids;
  }

  uncheckedselectedQus() {
    if (this.qusList) {
      for (let i = 0; i < this.qusList.length; i++) {
        this.qusList[i].Checked = false;
      }
    }

  }

  onSubmit(buttonType) {

    if (buttonType) {
      if (this.assessmentQus.length == 0) {
        this._notificationService.showNotification("error", "Please add questions");
        return
      }

      if (this.assessmentForm.value.totaltime < this.getTotalTime()) {
        this._notificationService.showNotification("error", "Total time not less than " + this.getTotalTime());
        return
      }

      if (this.assessmentForm.value.negativeMarking) {
        if (this.assessmentForm.value.negativeValue == 0) {
          this._notificationService.showNotification("error", "Please input value between 0 and 1 for negative marking");
          return
        }

      }

      if (this.assessmentForm.value.assignMarks) {
        let marksCount = 0;
        let count = 0;
        if (this.assessmentForm.value.totalMarks == 0) {
          this.isShown = true;
          this.alertMsg = 'Please input some total marks.'
          return;
        } else {
          let regexp = /^[0-9]+([,.][0-9]+)?$/g;
          if (!regexp.test(this.assessmentForm.value.totalMarks)) {
            this._notificationService.showNotification("error", "Please input valid value for total marks");
            return
          }
          this.assessmentQus.forEach(element => {

            if (element.marks == 0) {
              count++;
              this._notificationService.showNotification("error", "Please input some value for marks");
              return
            }
            marksCount += element.marks;
          })
          if (count > 0) {
            return
          }
          if (this.assessmentForm.value.totalMarks < marksCount) {
            this.isShown = true;
            this.alertMsg = 'Please edit that marks to get to the desired total.'
            return;
          }
          if (this.assessmentForm.value.totalMarks > marksCount) {
            this.isShown = true;
            this.alertMsg = 'Please edit that marks to get to the desired total.'
            return;
          }

        }
      } else {
        this.isShown = true;
        this.alertMsg = 'Please assign marks.'
        return;
      }

      // console.log(this.assessmentForm.value)
      let publishAssessment = false;
      // let buttonName = document.activeElement.getAttribute("name");// This works in IE,CHROME but not works in safari!!!
      if (buttonType == 'save') {
        publishAssessment = false;
        this.draft = 'Drafted'
      } else if (buttonType == 'publish') {
        publishAssessment = true;
      }
      let formvalue = this.assessmentForm.value;
      let assesmentObj: any = {};
      assesmentObj.assessment_name = formvalue.title;
      assesmentObj.assessment_type = formvalue.assessmentType;
      assesmentObj.total_time = formvalue.totaltime;
      // assesmentObj.questions = this.getAssessmentQusIds();
      assesmentObj.selectedQus = this.getAssessmentQusIds();
      assesmentObj.publish = publishAssessment;
      assesmentObj.tags = this.a_Tags;
      assesmentObj.negativeMarking = formvalue.negativeMarking;
      assesmentObj.negativeValue = formvalue.negativeValue;
      assesmentObj.assignMarks = formvalue.assignMarks;
      assesmentObj.totalMarks = formvalue.totalMarks;
      // console.log(assesmentObj);
      if (this.editAssessmentData) {
        assesmentObj._id = this.assessment_id;
        assesmentObj.user_id = this.editAssessmentData.user_id;

        this._questionBankService.edit_assessment(assesmentObj).pipe(
          finalize(() => {

          })).subscribe(
            (response) => {

              this._notificationService.showNotification("success", response.message);
              this.router.navigate(['/retrieve-assessment']);
            },
            (error) => {
              let msg = '';
              msg = error.message ? error.message.toUpperCase() : 'Unable to save assessment. Please try again later';

              this._notificationService.showNotification("error", msg);
            })
      } else {
        this._questionBankService.create_assessment(assesmentObj).pipe(
          finalize(() => {

          })).subscribe(
            (response) => {
              this.reset();
              let msg = '';
              msg = 'ASSESSMENT SAVED'
              this._notificationService.showNotification("success", msg);
              this.router.navigate(['/retrieve-assessment']);
              // this._notificationService.showNotification("success", response.message);
              this.closeAssessmentPopUp.emit('close');
            },
            (error) => {
              let msg = '';
              msg = error.message ? error.message.toUpperCase() : 'Unable to save assessment. Please try again later';

              this._notificationService.showNotification("error", msg);

            })
      }
    }
  }

  assmentType;
  reset() {
    this.assessmentQus = [];
    this.assessmentTags = [];
    this.a_Tags = [];
    this.uncheckedselectedQus();
    this.assessmentForm.reset({
      assessmentType: "",
      assignMarks: true,
      negativeMarking: false,
      negativeValue: 0,
      title: "",
      totalMarks: 0,
      totaltime: ''
    });
    // this.assessmentForm.controls['title'].setValue('');
    this.submitAssessmentForm = false;
    this.assmentType = true;
    this.search = '';
    this.isQusAvailable = false;
    this.isShown = false;
    this.alertMsg = '';
    // this.titleVisible = true;
  }
  discardAssessment() {
    this.reset();
    this.titleVisible = true;
    console.log('discard', this.assessmentForm.value)
  }

  getAssessmentDetails() {
    this.assessment_id = this.route.snapshot.queryParamMap.get('assessment_id');

    if (this.assessment_id) {
      this.componentName = 'Edit Assessment';
      this.editAssessmentData = this._assessmentShareService.getAssessment();

      if (this.editAssessmentData) {
        this.assessmentForm.controls['title'].setValue(this.editAssessmentData.assessment_name);
        this.assessmentForm.controls['assessmentType'].setValue(this.editAssessmentData.assessment_type);
        this.assessmentForm.controls['totaltime'].setValue(this.editAssessmentData.total_time);
        this.assessmentForm.controls['negativeMarking'].setValue(this.editAssessmentData.negativeMarking);
        this.assessmentForm.controls['negativeValue'].setValue(this.editAssessmentData.negativeValue);
        this.assessmentForm.controls['assignMarks'].setValue(this.editAssessmentData.assignMarks);
        this.assessmentForm.controls['totalMarks'].setValue(this.editAssessmentData.totalMarks);

        for (let i = 0; i < this.editAssessmentData.selectedQus.length; i++) {
          const found = this.assessmentQus.some(el => el._id === this.editAssessmentData.selectedQus[i]._id);
          if (!found) {
            this.assessmentQus.push(this.editAssessmentData.selectedQus[i]);
          }
        }
        this.getTags();
      } else {
        this.router.navigate(['/create-assessment'])
      }
    }
  }
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.assessmentQus, event.previousIndex, event.currentIndex);
  }
  onCheck() {
    this.assessmentForm.controls['negativeValue'].setValue(0);
  }

  onAssignMarks() {
    this.assessmentForm.controls['totalMarks'].setValue(0);
    this.assessmentQus.forEach(element => {
      element.marks = 0;
    })
  }

  totalmarks: number
  onTotalMarks() {
    this.totalmarks = this.assessmentForm.controls['totalMarks'].value;
    if (isNaN(this.totalmarks)) {
      return 0;
    }
    if (this.totalmarks && this.totalmarks > 0) {
      for (let i = 0; i < this.assessmentQus.length; i++) {
        // this.assessmentQus[i].marks = Math.round((totalmarks * this.assessmentQus[i].duration) / this.assessmentForm.controls['totaltime'].value);
        // this.assessmentQus[i].marks = Math.round(this.totalmarks / this.assessmentQus.length);
      }
    }
  }


  removeMarks(i, currentVal) {
    this.totalmarks = this.assessmentForm.controls['totalMarks'].value;
    if (isNaN(this.totalmarks)) {
      return 0;
    }
    let newVal = this.totalmarks - currentVal;
    this.assessmentForm.controls['totalMarks'].setValue(newVal);
    // if (this.totalmarks && this.totalmarks > 0) {
    //   for (let i = 0; i < this.assessmentQus.length; i++) {
    //     this.assessmentQus[i].marks = (Math.round(newVal / this.assessmentQus.length));
    //   }
    // }
  }

  inputMarks(i, event) {
    if (event.target.value >= 0) {
      let val = +event.target.value;
      // console.log(val);
      if (isNaN(val)) {
        return 0;
      }
      // this.assessmentQus[i].marks = val;

      let mark = this.assessmentQus[i].marks;
      this.getMark = this.assessmentForm.controls['totalMarks'].value;

      if (mark > val) {
        let lesM = mark - val;
        this.assessmentQus[i].marks = val;
        this.assessmentForm.controls['totalMarks'].setValue(this.getMark - lesM);
      }
      if (mark < val) {
        let plusM = val - mark;
        this.assessmentQus[i].marks = val;
        this.assessmentForm.controls['totalMarks'].setValue(this.getMark + plusM);
      }


    } else {
      this.assessmentQus[i].marks = 0;
    }
  }

  handleOk() {
    this.isShown = false;
    this.alertMsg = '';
  }


  qusTypes = [];
  getAllQusTypes() {
    this._questionBankService.fetchQusType().pipe(finalize(() => {
    })).subscribe(data => {
      console.log(data);
      this.qusTypes = data.ques_type;
    });
  }

  ques_type: string
  selectQtype(ques_type) {
    this.ques_type = ques_type
  }

  complexities: []
  getQusComplexity() {
    this.courseService.qusComplexity().pipe(finalize(() => {

    })).subscribe(data => {
      this.complexities = data.complexity;
    });
  }

  courses: []
  getAllCourses() {
    this.loader = true;
    this.courseService.categoryAPI().pipe(finalize(() => {
      this.loader = false;
    })).subscribe(data => {
      this.courses = data;
    });
    this.getAllTags();
  }

  qusTags: []
  getAllTags() {
    this._questionBankService.fetchTags().pipe(finalize(() => {
    })).subscribe(data => {
      this.qusTags = data.message;
      console.log(this.qusTags)
    });
  }

  numberOnly(event: any) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  backTitle() {
    this.titleVisible = true;
  }
 
  nextStep() {
    this.submitAssessmentForm = true;
    if(this.aF.title.valid){
      this.titleVisible = false;
    }
  }
  discardTitle() {
    this.submitAssessmentForm = false;
    // this.assessmentForm.reset({'title': ''});
    this.assessmentForm.controls['title'].setValue('');
    this.router.navigate(['/retrieve-assessment']);

  }

  //FOR BUILD FIX
  buttonType: any = '';
}
