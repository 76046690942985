<div class="new_UIstyle">
    <div class="pageBodyWrap">
        <!-- banner section -->
        <div class="banner banner-gradient banner-C-assessment"
            style="background-image:url('../../../assets/images/banner/banner-question.png');">
            <div class="container">
                <div class="banner-content">
                    <div class="banner-content-content">
                        <h2 class="white-text avenirBlack">Import Question</h2>
                        <!-- <p class="white-text">Group Assignment: <strong class="ms-2 primary-text">Student - Led</strong></p> -->
                        <!-- <div class="tags-badges">
                         <span class="badge badge-light badge-primary">Draft</span>
                     </div> -->
                    </div>
                    <div class="banner-content-action btn-groups">
                        <button class="btn btn-primary" (click)="goBack()">
                            Back</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class=" text-center mb-3" *ngIf="importedQues.length>0">
            <div class="custom-control custom-checkbox d-inline-block">
                <input type="checkbox" class="custom-control-input" id="importQst"
                    [ngModel]="importQus" (ngModelChange)="onImportedQuesChange($event)">
                <label class="custom-control-label" for="importQst"><small>Include Imported
                        Questions ({{importedQues.length}})</small></label>
            </div>
        </div> -->

        <section class="section addQuestionWrap" *ngIf="importForm">
            <div class="container">
                <div class="importQuestion">
                    <form class="typo-16">
                        <div class="row">
                            <div class="col-md-6 mb-3 mb-md-5">
                                <label class="avenirBlack">Question Category</label>
                                <select class="form-control form-control-lg form-control-style-2 form-control-caret"
                                    (change)="selectQtype($event.target.value,0,'addques')">
                                    <option selected value="">Select option</option>
                                    <option *ngFor="let qt of qusTypes" [value]="qt.value">
                                        {{qt.name}}
                                    </option>
                                </select>
                            </div>

                            <div class="col-md-12 mb-2">
                                <div class="sessionDragDropWrap ">
                                    <div class="dragDropWrap">
                                        <div class="dragDrop dragDropOr  d-md-flex">
                                            <figure class="dragDropIcon"><i class="fas fa-cloud-upload-alt"></i>
                                            </figure>
                                            <div class="dragDropContent">
                                                <p><strong class="blue-text d-block">Drag & Drop your file here</strong>
                                                   <small>Supports Csv Files Only</small>
                                                </p>
                                                <p><small><b class="d-block">{{fileName}}</b></small></p>
                                            </div>
                                            <span class="divideOr"></span>
                                        </div>

                                        <div class="dragDropUpload btn-groups">
                                            <label class="custom-upload-btn btn btn-blue btn-shadow btn-icon-text">
                                                <input type="file" id="fileU1" class="file-upload-input" #fileInput ng2FileSelect
                                                    accept=".csv" (change)="handleFileInput($event)" />
                                                <span class=""><i class="fas fa-download"></i> Browse Files</span>
                                            </label>
                                        </div>
                                    </div>
                                     <div class="mt-4 uploadedItemWrap">
                                        <div class="uploadedItem" *ngFor="let multiFile of multiFilesData; let idx = index" [ngClass]="{noRemoveBtn: idx == 0}">
                                            <div class="uploadedItemTop" *ngIf="multiFile.progressBar > 0">
                                                <div class="uploadedItemDetail">
                                                    <p class="mb-1"><strong
                                                            class="blue-text">{{multiFile.name}}</strong>
                                                    </p>
                                                    <p class="uploadedItemStatus">
                                                        <small>{{multiFile.progressBar}}%
                                                            Complete</small>
                                                        <!-- <small>{{multiFile.progressTime}}
                                                            seconds remaining</small> -->
                                                    </p>
                                                </div>
                                                <div class="uploadedItemAction">
                                                    <!-- <a href="#" class="uploadedActionLink"><i
                                                            class="fas fa-pause-circle"></i></a> -->
                                                    <!-- <a href="javascript:void(0)" class="uploadedActionLink"><i
                                                            class="far fa-times-circle"></i></a> -->
                                                </div>
                                            </div>
                                            <div class="progress uploadedProgress" *ngIf="multiFile.progressBar > 0">
                                                <div class="progress-bar uploadedProgressBlue"
                                                    role="progressbar" [style.width.%]="multiFile.progressBar" aria-valuenow="multiFile.progressBar"
                                                    aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                            </div>

                            <div class="col-md-2 mt-4">
                                
                                <div class="btn-groups justify-content-end">
                                    <button class="btn btn-primary btn-block" [disabled]="fileToUpload == null"
                                        (click)="uploadMultiResource(ques_type)">Import</button>
                                </div>

                            </div>
                           
                            <div class="col-8 d-md-flex mt-4">
                                
                                <div class="col-4 btn-groups justify-content-end" ngbTooltip="Sample File" *ngIf="ques_type">
                                    <button class="btn btn-primary btn-block" (click)="download_file()"><i
                                            class="ms-3 fas fa-file-download"></i> {{get_question_type()}}</button>
                                </div>
                              
                            </div>


                        </div>
                    </form>
                </div>


            </div>
        </section>

       
        <section class="section addQuestionWrap" *ngIf="notPresent">
            <div class="container">
                <div class="col-8 d-md-flex" >
                                
                    <div class="col-4 btn-groups justify-content-end" ngbTooltip="Sample File" *ngIf="ques_type">
                        <button class="btn btn-primary btn-block" (click)="download_file()"><i
                                class="ms-3 fas fa-file-download"></i> {{get_question_type()}}</button>
                    </div>
                     
                    <div class="col-4 btn-groups justify-content-end" *ngIf="notPresent">
                        <button class="btn btn-primary btn-block" (click)="save_questions()">Save</button>
                    </div> 
                </div>
            </div>
        </section>
       

       

        <section class="section questionBankContWrap" >
            <div class="container">
            <div class="table-responsive maxHe8 questionBankUploadTable" *ngIf=notPresent>
             
                <table class="table alt" style="width:1760px">
                    <thead>
                        <tr>
                            <th class="text-left" style="width: 60px; min-width: 60px;">S.No.</th>
                            <th class="text-left" style="width: 300px;">Question</th>
                            <th class="text-center" style="width: 250px;">Answer</th>
                            <!-- <th class="w200p text-left">Filter Question</th> -->
                            <th class="text-left"  style="width: 250px;">Question Type</th>
                            <th  *ngIf="validateOption()" style="width: 250px;">Option</th>
                            <th style="width: 250px;">
                                <span style="margin-left: 35px;"> Category</span><br />
                                <div class="custom-control custom-checkbox d-inline-block">
                                    <input type="checkbox" class="custom-control-input" [checked]="checked" id="sameForAllQue"
                                        [ngModel]="checked" (click)="sameForAll($event)">
                                    <label class="custom-control-label" for="sameForAllQue">Same For All</label>
                                </div>
                            </th>
                            <th>Complexity</th>
                            <th>Duration</th>
                            <th>Tags</th>
                            <th>Marks</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let csvQ of csvQues; let i = index;">
                            <td class="text-left">{{i+1}}</td>
                            <td class="text-left">{{csvQ.question}}</td>
                            <td>{{get_answer(csvQ.answer)}}</td>
                            <!-- <td>{{csvQ.filtered_question}}</td> -->
                            <td>
                                <div>{{csvQ.meta_data[0].question_type | titlecase}}</div>
                            </td>
                            <td *ngIf="validateOption()">
                                <div *ngFor="let optmeta of csvQ.meta_data; let option_ind=index">
                                    {{option_ind+1}} : {{optmeta.option}}
                                </div>
                            </td>
                            <td>
                                <select class="custom-select bg-white"
                                    (change)="selectCourseCat($event.target.value, csvQ)"
                                    [(ngModel)]="csvQ.category">
                                    <option selected value="">Select option</option>
                                    <option *ngFor="let course of courses"  [value]="course._id">
                                        {{course.Course_Category}}
                                    </option>
                                </select>
                            </td>
                            <td>{{csvQ.complexity}}</td>
                            <td>{{csvQ.duration}}</td>
                            <td class="text-center">
                                <span *ngFor="let tag of csvQ.tags" class="badge badge-primary m-1">{{tag |
                                    titlecase}}</span>
                            </td>
                            <td>{{ csvQ.marks }}</td>
            
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        </section>
       
    </div>
</div>

