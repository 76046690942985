import { Component, ElementRef, OnInit, Output, Input, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, distinctUntilChanged, filter, finalize, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CourseService } from 'src/app/services/course.service';
import { QuestionBankService } from 'src/app/services/question-bank.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EditCourseWork } from 'src/app/model/course.model';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResourceService } from 'src/app/services/resources/resource.service';
import { fromEvent } from 'rxjs';
import { Observable, of } from 'rxjs';
import { MatAutocompleteTrigger } from '@angular/material';
declare var tinymce: any;

import {Location} from '@angular/common';

@Component({
  selector: 'app-coursework-teachers',
  templateUrl: './coursework-teachers.component.html',
  styleUrls: ['./coursework-teachers.component.scss']
})
export class CourseWorkTeachersComponent implements OnInit {

  model: NgbDateStruct;

  showId: any = 'plancourse01';
  planCourse: boolean = false
  addNewCourseForm: FormGroup;
  categoryVisDetails: any;
  fileName: string;
  addCourseWork: FormGroup;
  loader = false;
  allCategory: any[];
  submitCoursework = false;
  submissionTags: any = [];
  SubmissionReports: FormArray;
  courseWorkResources: FormArray;
  apiEndpoint;
  fileArr: any = [];
  TotalDays = 0;
  totalPercentage = 0;
  progressBar = 0;
  uploaded_index;
  @Input() course_category;
  @Output() closeCouserWork = new EventEmitter();
  //@Output() addCourseValueEmit = new EventEmitter();

  insertBlankFlag: boolean = false;
  toolbarWithoutBlankButton = 'undo redo | formatselect | bold italic backcolor | \
  alignleft aligncenter alignright alignjustify | \
  bullist numlist outdent indent | removeformat | \
  link image | preview media help';

  // edit course work
  editCourseWork: EditCourseWork;
  pageName = '';
  course_work_id = '';
  componentName = '';
  save_draft = false;
  // testcase form
  testCases: FormGroup;
  test_case_input: FormArray;

  //NEW DESIGN FEATURE
  courseworkTitle: string = '';
  @ViewChild('cwTitle', {static: true}) cwTitle: any;
  showStepOneForm: boolean = true;
  showStepTwoForm: boolean = false;
  isNextBtnClicked: boolean = false;
  fileOver = false;
  multiFilesData: any[] = [];
  uploadedFilesData: any[] = [];
  @ViewChild('multiFileField', {static: false}) multiFileField : ElementRef;

  //TO REMOVE RESOURCES
  processLoader: boolean = false;
  allUploadedFiles: any[] = [];
  allDeletedFiles: any[] = [];

  constructor(
    private fb: FormBuilder,
    private courseService: CourseService,
    private _questionBankService: QuestionBankService,
    private modalReview: NgbModal,
    private route: ActivatedRoute,
    private resource_service: ResourceService,
    private _notificationService: NotificationService,
    private router: Router,
    private _location: Location
    ) {}

  ngOnInit() {
    const role = localStorage.getItem('role');
    const allowedRoles = ['teacher', 'super_admin'];
    if(allowedRoles.indexOf(role) == -1) {
      this.router.navigate(['/']);
    }

    this.getAllCourses();
    this.validateAddCourseWork();
    //this.getAllTags();
    tinymce.remove();
    this.removeSubmission(0, 'course_work_resources');
    //this.ValidateTest_case();
    this.componentName = "Add Course Work";
    this.apiEndpoint = environment.APIEndpoint;
    if (this.course_category) {
      this.addCourseWork.controls['course_category_id'].setValue(this.course_category);
    }
  }

  //NEW DESIGN FEATURE |START
  formSubmitStepOne(cwTitle = null) {
    this.isNextBtnClicked = true;
    if(!this.cwTitle.valid) {
      this.cwTitle.control.markAsTouched();
      return false;
    }
    this.showStepOneForm = false;
    this.showStepTwoForm = true;
    this.addCourseWork.controls['course_work_title'].setValue(this.courseworkTitle);
  }

  onTitleChange() {
    this.courseworkTitle = this.courseworkTitle.trim();
  }

  onDiscardClicked() {
    //TO REMOVE RESOURCES
    this.removeResources('discard');
  }

  goBackPage() {
    this._location.back();
  }
  //NEW DESIGN FEATURE |END

  //NEW DESIGN CODE COPY

  //@ViewChild('inputField', { read: MatAutocompleteTrigger, static: true }) autoTrigger: MatAutocompleteTrigger;

  ngAfterViewInit() {
    this.callMceEditor('textCourseWork');
    setTimeout(() => {
      if (this.course_work_id) {
        this.viewCourseWork()
        this.componentName = "Edit Course Work";
      }
    }, 300)

    /*this.autoTrigger.panelClosingActions.subscribe(x => {
      if (this.autoTrigger.activeOption) {
        this.setInd(0)
      }
    })*/
  }

  get submissionOptions() {
    return this.addCourseWork.get('submission_requirement')['controls'];
  }

  validateAddCourseWork() {
    this.addCourseWork = this.fb.group({
      course_category_id: ['', Validators.required],
      course_work_title: ['', Validators.required],
      course_work_description: ['', Validators.required],
      tag: ['', Validators.required],
      submission_requirement: this.fb.array([this.createSubmissions()]),
      course_work_resources: this.fb.array([this.createCourseWorkResources()]),
      course_work_file_id: [''],
      is_group: [false, Validators.required],
      group_size: ['', Validators.required],
      publish: [''],
    });    
  }

  getAllCourses() {
    this.course_work_id = this.route.snapshot.queryParamMap.get('courseWork');
    
    //SHOW STEP TWO FORM FOR EDIT 
    if(this.course_work_id) {
      this.showStepTwoForm = true;
      this.showStepOneForm = false;
    }

    this.loader = true;
    this.courseService.categoryAPI().pipe(finalize(() => {
      this.loader = false;
    })).subscribe(data => {
      this.allCategory = data;
    });
  }

  getAllTags() {
    this._questionBankService.fetchTags().pipe(finalize(() => {
    })).subscribe(data => {
      this.submissionTags = data.message;
    });
  }

  addsubmission() {
    this.SubmissionReports = this.addCourseWork.get('submission_requirement') as FormArray;
    this.SubmissionReports.push(this.createSubmissions());
  }

  addcourseWorkResource() {
    this.courseWorkResources = this.addCourseWork.get('course_work_resources') as FormArray;
    this.courseWorkResources.push(this.createCourseWorkResources());
  }

  createCourseWorkResources(): FormGroup {
    this.submitCoursework = false;
    return this.fb.group({
      resource_title: ['title', Validators.required],
      resource_type: ['', Validators.required],
      resource_id: [''],
      resource_url: [''],
      path: ['']
    });
  }

  removeSubmission(i: number, controlName: string, type?: string) {
    if (type == 'manual') {
      if (confirm('Are you sure want to delete ' + controlName + ' ' + (i + 1))) {
        this.remove_item(i, controlName, type);
      } else {
        return
      }
    } else {
      this.remove_item(i, controlName);
    }
  }

  remove_item(i: number, controlName, type?: string) {
    this.submitCoursework = false;
    let control;
    if (controlName == "submission_requirement") {
      control = <FormArray>this.addCourseWork.controls[controlName];
      //RESET TOTAL PERCENT AND DAYS
      if(type == 'manual') {
        control.controls[i].controls['total_number_of_percentage'].setValue("");
        control.controls[i].controls['days_of_completion'].setValue("");
        this.stripText(control.controls[i].controls['total_number_of_percentage'], 'Percentage');
        this.stripText(control.controls[i].controls['days_of_completion'], 'days');
      }
    } else if (controlName == "course_work_resources") {
      control = <FormArray>this.addCourseWork.controls[controlName];
    }
    control.removeAt(i);
  }

  saveType(index) {
    this.addCourseWork.controls.course_work_resources['controls'][index].controls.resource_type.setValue('text');
  }

  createSubmissions(): FormGroup {
    this.submitCoursework = false;
    return this.fb.group({
      submission_report: ['', Validators.required],
      total_number_of_percentage: ['', [Validators.required, Validators.min(1)]],
      days_of_completion: ['', [Validators.required, Validators.min(1)]],
      rubric: ['', Validators.required],
      online_presentation: [false],
      autograding: [false],
      autograding_input: [
        { inputs: [] }
      ],
      autograding_output: [
        { output: [] }
      ],
      autograding_id: ''
    });
  }

  stripText(control, inputType) {
    control.setValue(control.value.replace(/[^0-9]/g, ''));
    if (inputType == 'days') {
      this.TotalDays = 0;
      for (let submissionstart = 0; submissionstart < this.submissionOptions.length; submissionstart++) {
        if (this.TotalDays < this.submissionOptions[submissionstart].value.days_of_completion) {
          this.TotalDays = Number(this.submissionOptions[submissionstart].value.days_of_completion)
        }
      }
    }

    else if (inputType == 'Percentage') {
      this.totalPercentage = 0;
      for (let submissionstart = 0; submissionstart < this.submissionOptions.length; submissionstart++) {
        this.totalPercentage += Number(this.submissionOptions[submissionstart].value.total_number_of_percentage)
      }
    }
  }

  callMceEditor(edId) {

    let that = this;
    setTimeout(() => {
      tinymce.baseURL = 'assets';
      tinymce.init({
        selector: 'textarea#' + edId,
        draggable_modal: true,
        height: 300,
        // base_url: '/tinymce',
        suffix: '.min',
        themes: "assets/themes/silver",
        // icons: "assets/icons/",
        // content_css: '//www.tiny.cloud/css/codepen.min.css',

        plugins: [
          'advlist autolink lists link image imagetools charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount'
        ],
        toolbar: that.toolbarWithoutBlankButton,
        image_title: true,
        automatic_uploads: true,
        image_description: false,
        file_picker_types: 'file image media',
        // external_plugins: { tiny_mce_wiris: "https://www.wiris.net/demo/plugins/tiny_mce/plugin.js" }, 
        // toolbar1: "tiny_mce_wiris_formulaEditor, tiny_mce_wiris_formulaEditorChemistry",
        file_picker_callback: function (cb, value, meta) {

          let input = document.createElement('input');
          input.setAttribute('type', 'file');
          input.setAttribute('accept', 'image/*');

          // Note: In modern browsers input[type="file"] is functional without 
          // even adding it to the DOM, but that might not be the case in some older
          // or quirky browsers like IE, so you might want to add it to the DOM
          // just in case, and visually hide it. And do not forget do remove it
          // once you do not need it anymore.

          input.onchange = function () {

            // var file = $event.path[0].files[0];
            let file = input.files[0];

            let reader: any = new FileReader();

            reader.onload = function () {

              // Note: Now we need to register the blob in TinyMCEs image blob
              // registry. In the next release this part hopefully won't be
              // necessary, as we are looking to handle it internally.
              let id = 'blobid' + (new Date()).getTime();
              let blobCache = tinymce.activeEditor.editorUpload.blobCache;
              let base64 = reader.result.split(',')[1];
              let blobInfo = blobCache.create(id, file, base64);

              blobCache.add(blobInfo);

              // call the callback and populate the Title field with the file name

              cb(reader.result, { title: file.name });
            };
            reader.readAsDataURL(file);
          };

          input.click();
        },
        images_upload_handler: function (blobInfo, success, failure, progress) {
          let xhr, formData;

          xhr = new XMLHttpRequest();

          xhr.withCredentials = false;
          xhr.open('POST', that.apiEndpoint + '/upload_course_resource');
          xhr.setRequestHeader('session-token', localStorage.getItem('token')); // manually set header

          xhr.upload.onprogress = function (e) {
            progress(e.loaded / e.total * 100);
          };

          xhr.onerror = function () {
            failure("Image upload failed due to a XHR Transport error. Code: " + xhr.status);
          };

          xhr.onload = function () {
            let json;

            if (xhr.status != 200) {
              failure('HTTP Error: ' + xhr.status);
              return;
            }
            json = JSON.parse(xhr.responseText);

            if (!json || typeof json.resource_id != 'string') {
              failure('Invalid JSON: ' + xhr.responseText);
              return;
            }

            success(json.s3_location);
            let fileObj: any = {};
            fileObj._id = json.resource_id,
              fileObj.type = "file"
            that.fileArr.push(fileObj);
          };

          formData = new FormData();
          formData.append('resource_content', blobInfo.blob(), blobInfo.filename());


          xhr.send(formData);
        },
        setup: function (editor) {
          // if (edId == 'fibtype') {
          if (that.insertBlankFlag) {
            editor.ui.registry.addButton('customBlankButton', {
              text: 'Add Blank',
            });
          }
        }
      });

    }, 100)
  }

  courseList:any = [];
  viewCourseWork() {
    this.loader = true;
    this.courseService.viewCourseWork(this.course_work_id).subscribe(res => {
      this.loader = false;
      this.editCourseWork = res.course_work_info;
      this.courseList = this.editCourseWork.courses;
      this.save_draft = this.editCourseWork.publish
      this.setEditCourseWork();
    }, err => {
      this.loader = false;
      console.log(err);
    });
  }

  randomBgColors: any[] = [];
  setEditCourseWork() {
    if (this.editCourseWork) {
      //SET COURSEWORK TITLE
      this.courseworkTitle = this.editCourseWork.course_work_title;
      //SET TAGS COLORS
      if(this.editCourseWork.tag) {
        for(let i = 0; i < this.editCourseWork.tag.length; i++) {
          this.randomBgColors[i] = this.randomBgColor();
        }
      }

      this.addCourseWork.controls['course_category_id'].setValue(this.editCourseWork.course_category_id);
      this.addCourseWork.controls['course_work_title'].setValue(this.editCourseWork.course_work_title);

      this.addCourseWork.controls['course_work_description'].setValue(this.editCourseWork.course_work_description);
      tinymce.get("textCourseWork").setContent(this.editCourseWork.course_work_description);
      this.addCourseWork.controls['course_work_file_id'].setValue(this.editCourseWork.course_work_file_id);
      this.addCourseWork.controls['is_group'].setValue(this.editCourseWork.is_group);
      this.addCourseWork.controls['group_size'].setValue(this.editCourseWork.group_size);
      this.addCourseWork.controls['tag'].setValue(this.editCourseWork.tag);

      if (this.editCourseWork.course_work_resources.length > 0) {
        /*this.editCourseWork.course_work_resources.forEach((e, index) => {
          this.addcourseWorkResource();
          this.addCourseWork.controls.course_work_resources['controls'][index].controls.resource_title.setValue(e.resource_title);
          this.addCourseWork.controls.course_work_resources['controls'][index].controls.resource_type.setValue(e.resource_type);
          this.addCourseWork.controls.course_work_resources['controls'][index].controls.resource_id.setValue(e.resource_id);
          this.addCourseWork.controls.course_work_resources['controls'][index].controls.resource_url.setValue(e.resource_url);
        });*/

        //SET UPLOADED RESOURCES
        this.uploadedFilesData = [];
        this.editCourseWork.course_work_resources.forEach((e, index) => {
          const resInfo = JSON.parse(JSON.stringify(e));
          this.uploadedFilesData[index] = {};
          this.uploadedFilesData[index].resource_title = resInfo.resource_title;
          this.uploadedFilesData[index].resource_type = resInfo.resource_type;
          this.uploadedFilesData[index].resource_id = resInfo.resource_id;
          this.uploadedFilesData[index].resource_url = resInfo.resource_url;
          this.uploadedFilesData[index].path = resInfo.path;
          this.uploadedFilesData[index].resource_deleted = false;
        });
      }

      if (this.editCourseWork.submission_requirement.length > 0) {
        let indexOfSubmission = this.editCourseWork.submission_requirement.length - 1;
        this.editCourseWork.submission_requirement.forEach((e, index) => {
          this.addCourseWork.controls.submission_requirement['controls'][index].controls.days_of_completion.setValue(e.days_of_completion);
          this.addCourseWork.controls.submission_requirement['controls'][index].controls.online_presentation.setValue(e.online_presentation);
          this.addCourseWork.controls.submission_requirement['controls'][index].controls.rubric.setValue(e.rubric);
          this.addCourseWork.controls.submission_requirement['controls'][index].controls.submission_report.setValue(e.submission_report);
          this.addCourseWork.controls.submission_requirement['controls'][index].controls.total_number_of_percentage.setValue(e.total_number_of_percentage);
          this.addCourseWork.controls.submission_requirement['controls'][index].controls.autograding_id.setValue(e.autograding_id);
          this.addCourseWork.controls.submission_requirement['controls'][index].controls.autograding.setValue(e.autograding);
          this.addCourseWork.controls.submission_requirement['controls'][index].controls.autograding_input.setValue(e.autograding_input);
          this.addCourseWork.controls.submission_requirement['controls'][index].controls.autograding_output.setValue(e.autograding_output);

          //SET TEST CASES 
          if(e.autograding == true) {
            this.openautogradeModal(index);
          }          

          this.totalPercentage += Number(e.total_number_of_percentage);
          if (this.TotalDays < Number(e.days_of_completion)) {
            this.TotalDays = Number(e.days_of_completion);
          }
          this.addsubmission();
          if (index == indexOfSubmission) {
            this.removeSubmission(indexOfSubmission + 1, 'submission_requirement')
          }
        })
      }
    }
  }

  formSubmit(key) {
    let course_work_id = this.course_work_id;
    this.submitCoursework = true;
    let description = this._notificationService.titleCase(tinymce.get("textCourseWork").getContent());

    description = this._notificationService.paraString(description);

    this.addCourseWork.patchValue({
      course_work_description: description
    });

    //console.log(this.uploadedFilesData);
    //SET UPLOADED RESOURCES
    let frmArray = this.addCourseWork.get('course_work_resources') as FormArray;
    frmArray.clear();
    
    //TO REMOVE RESOURCES
    this.allDeletedFiles = [];

    if (this.uploadedFilesData.length > 0) {
      this.uploadedFilesData.forEach((e, index) => {
        if(!e.resource_deleted) {
          this.addcourseWorkResource();
          let cwindex = this.addCourseWork.controls.course_work_resources['controls'].length - 1;
          this.addCourseWork.controls.course_work_resources['controls'][cwindex].controls.resource_title.setValue(e.resource_title);
          this.addCourseWork.controls.course_work_resources['controls'][cwindex].controls.resource_type.setValue(e.resource_type);
          this.addCourseWork.controls.course_work_resources['controls'][cwindex].controls.resource_id.setValue(e.resource_id);
          this.addCourseWork.controls.course_work_resources['controls'][cwindex].controls.resource_url.setValue(e.resource_url);
          this.addCourseWork.controls.course_work_resources['controls'][cwindex].controls.path.setValue(e.path);
        }
        else {
          //TO REMOVE RESOURCES
          this.allDeletedFiles.push(e.path);
        }
      });
    }

    //SET TEST CASES
    const allSubmissions = this.addCourseWork.controls.submission_requirement['controls'];
    const totSubmissions = allSubmissions.length;
    for(let subidx = 0; subidx < totSubmissions; subidx++) {
      if(allSubmissions[subidx].controls.autograding.value == true) {
        const testCaseStatus = this.save_test_cases(subidx);
        if(testCaseStatus == false) {
          return false;
        }
      }
    }
    // console.log(this.addCourseWork.value.submission_requirement);
    // return;

    if (this.fileArr.length > 0) {
      this.addCourseWork.controls['course_work_file_id'].setValue(this.fileArr[0]._id);
    }
    if (!this.addCourseWork.controls['is_group'].value) {
      this.addCourseWork.controls['group_size'].setValue('1');
    }
    if (Number(this.addCourseWork.controls['group_size'].value) > 10) {
      this._notificationService.showNotification('info', "Group size is not allow more than 10 students");
      return;
    }
    if (Number(this.addCourseWork.controls['group_size'].value) <= (1 || '') && (this.addCourseWork.controls['is_group'].value)) {
      this._notificationService.showNotification('info', "Group size must be greater than or equal to 2");
      return;
    }

    if (this.addCourseWork.invalid && key == true) {
      this._notificationService.showNotification("error", "Please correct the form like Resources, Description, Title, and other need to be correct.");
      return;
    }

    if (this.totalPercentage > 100 && key == true) {
      this._notificationService.showNotification("info", "Marks can't be greater than 100 %");
      return;
    } else if (this.totalPercentage < 100 && key == true) {
      this._notificationService.showNotification("info", "Marks can't be less than 100 %");
      return;
    }
    this.addCourseWork.controls['publish'].setValue(key);

    if (this.addCourseWork.value.course_work_title == null) {
      this._notificationService.showNotification('info', "title must be present if you wants to save the course work");
      return;
    }
    for (let cw of this.addCourseWork.value.course_work_resources) {
      if (cw.resource_url != '' && !this._notificationService.weblink_validation(cw.resource_url)) {
        this._notificationService.showNotification('info', 'Please add correct url in course work resource which has title ' + "'" + cw.resource_title + "'");
        return false;
      } else if (cw.resource_id == '' && cw.resource_url == '') {
        this._notificationService.showNotification('info', 'Please add correct url/resource which has title' + "'" + cw.resource_title + "'");
        return false;
      }
    }
    for (let submission of this.addCourseWork.value.submission_requirement) {
      if (submission.autograding == true) {
        if (submission.autograding_input.length == 0) {
          this._notificationService.showNotification('info', 'Please add test cases for the autograde');
          return false;
        }
      }
    }

    if (this.editCourseWork) {
      if (this.addCourseWork.value.course_work_title != this.editCourseWork.course_work_title) {
        course_work_id = null;
      } else if (this.addCourseWork.value.course_work_title == this.editCourseWork.course_work_title) {
        this.course_work_id = this.route.snapshot.queryParamMap.get('courseWork');
        course_work_id = this.course_work_id;
      }
    }
    this.processLoader = true;
    if (!course_work_id) {
      this.course_work_id == null ? this.course_work_id = '' : this.course_work_id;
      this.courseService.addCourseWork(this.addCourseWork.value, this.course_work_id).pipe(
        finalize(() => {
          this.processLoader = false;
          this.submitCoursework = false;
        })).subscribe(
          (response) => {
            this._notificationService.showNotification("success", response.message);
            if (this.course_category) {
              this.closeCouserWork.emit('closeCourseWork')
            } else {
              //TO REMOVE RESOURCES
              this.removeResources('save');
              //this.router.navigate(['/search-course-work']);
              this.router.navigate(['courses/search-coursework']);
            }            
          },
          (error) => {
            this._notificationService.showNotification("error", error.message);
          })
    } else if (course_work_id) {
      this.courseService.editCourseWork(this.course_work_id, this.addCourseWork.value).pipe(
        finalize(() => {
          this.processLoader = false;
          this.submitCoursework = false;
        })
      ).subscribe(saveEditCourseWork => {
        this._notificationService.showNotification('success', saveEditCourseWork.message);
        this.reset();
        //TO REMOVE RESOURCES
        this.removeResources('edit');
        //this.router.navigate(['/search-course-work']);
        this.router.navigate(['courses/search-coursework']);        
      },
        (error) => {
          this._notificationService.showNotification('error', error.message);
        }
      )
    }
  }

  //NEW DESIGN FEATURE |START
  randomBgColor() {
    var x = Math.floor(Math.random() * 256);
    var y = Math.floor(Math.random() * 256);
    var z = Math.floor(Math.random() * 256);
    var bgColor = "rgb(" + x + "," + y + "," + z + ")";
    return bgColor;
  }

  onFileDropped($event) {
    //console.log($event);
  }

  onDragOver(event) {
    event.preventDefault();
    this.fileOver = true;
  }

  onDragLeave(event) {
    event.preventDefault();
    this.fileOver = false;
  }

  onDropSuccess(event) {
    event.preventDefault();
    this.fileOver = false;
    let files = event.dataTransfer.files;
    //console.log(files);    
    this.uploadMultiResource(files);
  }

  onSelectMultiResource(event) {    
    let files = event.target.files;
    //console.log(files);
    this.uploadMultiResource(files);
  }

  totSelFiles: number = 0;
  processedFiles: number = 0;
  uploadMultiResource(files) {
    this.multiFilesData = [];
    this.totSelFiles = files.length;
    for(let i = 0; i < files.length; i++) {
      this.processedFiles++;
      this.uploadSingleResource(event, i, 'course_work_resources', files[i], 'file');
    }
    if(this.processedFiles == this.totSelFiles) {
      //console.log(this.multiFileField.nativeElement.files);
    }
  }

  uploadSingleResource(event, idx, controlName, file, type) {
    let control;
    this.uploaded_index = idx
    control = <FormArray>this.addCourseWork.controls[controlName];

    if (file) {

      const fileName = file.name;
      let progressBar = 0;
      let progressTime = 0;

      // this.loader = true;
      let startDate = new Date;
      this._notificationService.upload_resource('global-resources', file).pipe(
        finalize(() => {
          // this.loader = false;
        })).subscribe(
          (response: HttpEvent<any>) => {
            switch (response.type) {
              case HttpEventType.Sent:
                break;
              case HttpEventType.ResponseHeader:
                break;
              case HttpEventType.UploadProgress:
                progressBar = Math.round(response.loaded / response.total * 100);

                //TIME CALCULATION  
                let currDate = new Date;
                let timeElapsed: number = currDate.getTime() - startDate.getTime();
                let uploadSpeed = response.loaded / (timeElapsed / 1000);
                let seconds = (file.size - response.loaded) / uploadSpeed;
                progressTime = Math.round(seconds);

                this.multiFilesData[idx] = {name: fileName, progressBar: progressBar, progressTime: progressTime, uploadStatus: false};
                break;
              case HttpEventType.Response:
                if (controlName == 'course_work_resources') {                  
                  this._notificationService.showNotification('success', 'file uploaded successfully');
                }
                setTimeout(() => {
                  progressBar = 0;
                  progressTime = 0;
                  this.multiFilesData[idx] = {name: fileName, progressBar: progressBar, progressTime: progressTime, uploadStatus: true};
                  this.updateUploadedResources(this.multiFilesData[idx], response);
                }, 1500);
            }
          },
          (err) => {
            progressBar = 0;
            progressTime = 0;
            this.multiFilesData[idx] = {name: fileName, progressBar: progressBar, progressTime: progressTime, uploadStatus: false};
            this._notificationService.showNotification('info', 'Please add again not able to save your file');
          });
    }
  }

  updateUploadedResources(multiFile, response) {
    const resourceData = response.body.resource_info[0];
    const resourcePath = resourceData.path;
    const resourceId = resourceData.resource_id;
    const resourceUrl = resourceData.url;
    let resourceInfo = {
      resource_title: multiFile.name,
      resource_type: 'file',
      resource_id: resourceId,
      resource_url: resourceUrl,
      path: resourcePath,
      resource_deleted: false
    };
    this.uploadedFilesData.unshift(resourceInfo);
    //console.log(this.uploadedFilesData);

    //TO REMOVE RESOURCES
    this.allUploadedFiles.push(resourcePath);
  }

  deleteUploadedResource(uploadedFileIndex) {
    if (confirm('Are you sure to remove this from coursework resources ? ')) {
      this.uploadedFilesData[uploadedFileIndex].resource_deleted = true;
    }
    return;
  }

  //NEW DESIGN FEATURE |END

  uploadResourse(event, resourseIndex, controlName, resourceName, type) {
    let file;
    let control;
    this.uploaded_index = resourseIndex
    control = <FormArray>this.addCourseWork.controls[controlName];
    if (event.target.files) {
      file = event.target.files[0];
    } else {
      if (controlName == 'course_work_resources') {
        control.controls[resourseIndex]['controls'].resource_type.setValue('text');
      }
      return;
    }

    if (file) {
      if (controlName == 'course_work_resources') {
        control.controls[resourseIndex]['controls'].resource_type.setValue('file');
      }

      // this.loader = true;
      this._notificationService.upload_resource('global-resources', file).pipe(
        finalize(() => {
          // this.loader = false;
        })).subscribe(
          (response: HttpEvent<any>) => {
            switch (response.type) {
              case HttpEventType.Sent:
                break;
              case HttpEventType.ResponseHeader:
                break;
              case HttpEventType.UploadProgress:
                this.progressBar = Math.round(response.loaded / response.total * 100);
                break;
              case HttpEventType.Response:
                if (controlName == 'course_work_resources') {
                  control.controls[resourseIndex]['controls'].resource_url.setValue('');
                  control.controls[resourseIndex]['controls'].resource_id.setValue(response.body.resource_id);
                  this._notificationService.showNotification('success', 'file uploaded successfully');

                }
                setTimeout(() => {
                  this.progressBar = 0;
                }, 1500);
            }

          },
          (err) => {
            this.progressBar = 0;
            this._notificationService.showNotification('info', 'Please add again not able to save your file');
          })
    }
  }

  reset() {
    this.loader = false;
    this.submitCoursework = false;
    this.submissionTags = [];
    this.addCourseWork.reset();
    this.addCourseWork.controls.is_group.setValue(false);
    this.addCourseWork.controls.course_work_file_id.setValue('');
    this.addCourseWork.controls.submission_requirement.reset();
    for (let i = 0; i <= this.addCourseWork.controls.submission_requirement['controls'].length; i++) {
      this.addCourseWork.controls.submission_requirement['controls'][0].controls.online_presentation.setValue(false);
      this.removeSubmission(1, 'submission_requirement');

    }
    for (let res = 0; res <= this.addCourseWork.controls.course_work_resources['controls'].length; res++) {
      this.removeSubmission(0, 'course_work_resources');
    }
    this.fileArr = [];
    this.SubmissionReports = null;
    this.courseWorkResources = null;
    this.totalPercentage = 0;
    this.TotalDays = 0;

    tinymce.get("textCourseWork").setContent('')
    this.course_work_id = '';
  }

  backToSearch() {
    if (this.course_category) {
      this.closeCouserWork.emit('closeCourseWork')
    } else {
      //this.router.navigate(['/search-course-work']);
      this.router.navigate(['courses/search-coursework']);
    }
  }

  keyChange(event) {
    if (this.editCourseWork) {
      if (event.target.value != this.editCourseWork.course_work_title) {
        this.save_draft = false;
      }
    }
  }

  susbmission_id;
  test_modal;
  openautogradeModal(submission_id) {
    //console.log("kk");
    //this.test_modal = this.modalReview.open(content, { windowClass: 'autogradeModal', centered: true });
    this.ValidateTest_case(submission_id);
    this.set_test_case(submission_id);
  }

  allTestCases: any = [];
  ValidateTest_case(submission_id) {
    this.testCases = this.fb.group({
      text_case: this.fb.array([this.add_test_cases()]),
    });
    this.allTestCases[submission_id] = this.testCases; 
  }

  add_test_cases() {
    this.submitCoursework = false;
    return this.fb.group({
      _inputarr: this.fb.array([this.add_input()]),
      output: ['', Validators.required]
    });
  }

  add_inputs(index, submission_id) {
    this.submitCoursework = false;
    this.test_case_input = <FormArray>this.allTestCases[submission_id].get('text_case')['controls'][index].controls['_inputarr'];
    this.test_case_input.push(this.add_input());
  }

  add_test_case(submission_id) {
    this.test_case_input = this.allTestCases[submission_id].get('text_case') as FormArray;
    this.test_case_input.push(this.add_test_cases());
  }

  add_input() {
    return this.fb.group({
      input: ['', Validators.required],
    });
  }

  delete_test_case(type, test_index, input_index, submission_id) {
    if (confirm('Are you sure to remove this from test cases ? ')) {
      this.delete_test(type, test_index, input_index, submission_id)
    }
    return;
  }

  delete_test(type, test_index, input_index, submission_id) {
    let control;
    if (type == 'text_case') {
      control = <FormArray>this.allTestCases[submission_id].controls[type];
      control.removeAt(test_index);

    } else if (type == '_inputarr') {
      control = <FormArray>this.allTestCases[submission_id].controls['text_case']['controls'][test_index].controls['_inputarr'];
      control.removeAt(input_index);
    }
  }

  save_test_cases(submission_id) {
    let input = [];
    let output = [];

    if (this.allTestCases[submission_id].invalid) {
      this._notificationService.showNotification('info', 'Please fill test case form with valid data');
      return false;
    }

    if (!this.validate_input_length(submission_id)) {
      return false;
    }
    for (let j = 0; j < this.allTestCases[submission_id].value.text_case.length; j++) {
      var x = [];
      for (let k = 0; k < this.allTestCases[submission_id].value.text_case[j]._inputarr.length; k++) {
        if (this.allTestCases[submission_id].value.text_case[j]._inputarr[k].input != (null)) {
          x.push(this.allTestCases[submission_id].value.text_case[j]._inputarr[k].input)
        }

        if (this.allTestCases[submission_id].value.text_case[j]._inputarr.length - 1 == k) {
          input.push(x)
        }
      }
      output.push(this.allTestCases[submission_id].value.text_case[j].output);
    }

    this.addCourseWork.controls.submission_requirement['controls'][submission_id].controls.autograding_input.setValue([]);
    this.addCourseWork.controls.submission_requirement['controls'][submission_id].controls.autograding_output.setValue([]);

    this.addCourseWork.controls.submission_requirement['controls'][submission_id].controls.autograding_input.setValue(input);
    this.addCourseWork.controls.submission_requirement['controls'][submission_id].controls.autograding_output.setValue(output);
    //this.allTestCases[submission_id].reset();
    //this.test_modal.close();
    return true;
  }


  set_test_case(submission_id) {
    if (this.addCourseWork.controls.submission_requirement['controls'][submission_id].controls.autograding_input.value.length > 0) {
      for (let j = 0; j < this.addCourseWork.controls.submission_requirement['controls'][submission_id].controls.autograding_input.value.length; j++) {
        for (let k = 0; k < this.addCourseWork.controls.submission_requirement['controls'][submission_id].controls.autograding_input.value[j].length; k++) {
          this.allTestCases[submission_id].controls.text_case['controls'][j].controls._inputarr['controls'][k].controls.input.setValue(this.addCourseWork.controls.submission_requirement['controls'][submission_id].controls.autograding_input.value[j][k]);
          this.add_inputs(j, submission_id);
          if (k == this.addCourseWork.controls.submission_requirement['controls'][submission_id].controls.autograding_input.value[j].length - 1) {
            this.delete_test('_inputarr', j, this.addCourseWork.controls.submission_requirement['controls'][submission_id].controls.autograding_input.value[j].length, submission_id);
            this.allTestCases[submission_id].controls.text_case['controls'][j].controls.output.setValue(this.addCourseWork.controls.submission_requirement['controls'][submission_id].controls.autograding_output.value[j]);
          }
        }
        this.add_test_case(submission_id);

        if (j == this.addCourseWork.controls.submission_requirement['controls'][submission_id].controls.autograding_input.value.length - 1) {
          this.delete_test('text_case', j + 1, '', submission_id);
        }
      }
    }
  }


  validate_input_length(submission_id) {
    let input = true;
    if (this.allTestCases[submission_id].value.text_case.length > 1) {
      for (let j = 0; j < this.allTestCases[submission_id].value.text_case.length; j++) {
        if (this.allTestCases[submission_id].value.text_case[0]._inputarr.length != this.allTestCases[submission_id].value.text_case[j]._inputarr.length) {
          this._notificationService.showNotification('info', 'In your test cases input is not same length');
          input = false;
        }
      }
    }
    return input;
  }

  is_test_case_visible(index) {
    // && index < this.editCourseWork.submission_requirement.length in new test case
    if (this.editCourseWork && this.editCourseWork.publish) {
      return false;
    }
    return true;
  }

  back() {
    this.test_modal.close();
  }

  is_disabled(index) {
    if (this.editCourseWork && this.editCourseWork.publish) {
      return true;
    }

    return false;
  }


  serInput
  results = [];
  setInd(index) {
    this.serInput = index;
    this.results = [];
    // filter search for food items
    const typeahead = fromEvent<KeyboardEvent>(document.querySelector(('#resource' + this.serInput)) as HTMLInputElement, 'input').pipe(
      map((e: KeyboardEvent) => { return (<HTMLInputElement>e.target).value }),
      filter(text => text.length > 2),
      debounceTime(500),
      distinctUntilChanged(),
    );

    typeahead.subscribe(keyword => {
      const data = keyword
      this.resource_service.serach_resource_elastic(data).subscribe(resource => {
        if (resource.length > 0) {
          this.results = resource;
        } else {
          this.results = [];
          this.results = [{
            description: "",
            id: "",
            tags: [],
            title: data,
            type: "text",
            url: data,
          }]
        }
        // if (data['responseCode'] == 1) {
        //   if(data['errorMessage'] == "no items"){
        //     this.results = [{
        //       id:'',
        //       misc:["small", " regular", " large"],
        //       name: keyword + ' (Add New)',
        //       serving_unit:'bowl,Piece,Plate,Packet,Glass,cup,Number,Tablespoon,Serving,Slice,Tetrapack,dish'
        //     }];
        //   }else{
        //     this.results = data['data'];
        //   }
        // } else {
        //   this.notify.showNotification("error", "Something went wrong");
        // }
      },
        error => {
          this._notificationService.showNotification("error", "Something went wrong");
        });
    });
  }


  searchData(option, index) {
    if (option.id != '') {
      this.addCourseWork.controls.course_work_resources['controls'][index].controls.resource_id.setValue(option.id);
      this.addCourseWork.controls.course_work_resources['controls'][index].controls.resource_type.setValue('file');

    } else {
      this.addCourseWork.controls.course_work_resources['controls'][index].controls.resource_url.setValue(option.title);
      this.addCourseWork.controls.course_work_resources['controls'][index].controls.resource_type.setValue('text');
      this.addCourseWork.controls.course_work_resources['controls'][index].controls.resource_id.setValue('');
    }

  }

  set_resource_val(val, index) {
    if (val != '' && this.addCourseWork.controls.course_work_resources['controls'][index].controls.resource_url.value == '') {
      return val;
    } else {
      return this.addCourseWork.controls.course_work_resources['controls'][index].controls.resource_url.value;
    }
  }

  check_auto_grad(event, index) {
    if (!event.target.checked) {
      if (confirm('Are you sure ?')) {
        this.addCourseWork.controls.submission_requirement['controls'][index].controls.autograding_input.setValue({ inputs: [] });
        this.addCourseWork.controls.submission_requirement['controls'][index].controls.autograding_output.setValue({ output: [] });
      } else {
        event.target.checked = !event.target.checked
        return
      }
    }
    if(event.target.checked) {
      const submissionId = index;
      this.openautogradeModal(submissionId);
    }
  }

  //FOR RESET COURSEWORK |START
  onResetClicked() {
    if (confirm('Are you sure want to reset changes ?')) {
      this.resetCourseWork();
    } else {
      return
    }
  }

  resetCourseWork() {
    this.fileArr = [];
    this.totalPercentage = 0;
    this.TotalDays = 0;
    tinymce.get("textCourseWork").setContent('');
    this.uploadedFilesData = [];
    this.multiFilesData = [];
    this.validateAddCourseWork();
    this.removeSubmission(0, 'course_work_resources');
    this.removeResources('reset');
    this.viewResetCourseWork();
  }

  viewResetCourseWork() {
    this.processLoader = true;
    this.courseService.viewCourseWork(this.course_work_id).subscribe(res => {
      this.processLoader = false;
      this.editCourseWork = res.course_work_info;
      this.save_draft = this.editCourseWork.publish
      this.setEditCourseWork();
    }, err => {
      this.processLoader = false;
      console.log(err);
    });
  }

  removeResources(actionRef = '') {
    let removeFileList: any[] = [];
    if(actionRef == 'save' || actionRef == 'edit') {
      removeFileList = this.allDeletedFiles;
    }
    else {
      removeFileList = this.allUploadedFiles;
    }
    if(removeFileList.length > 0) {
      this.processLoader = true;
      this.resource_service.removeResource('', removeFileList).subscribe(res => {
        this.processLoader = false;
        if(actionRef == 'discard') {
          this._location.back();
        }
        this.allDeletedFiles = [];
        this.allUploadedFiles = [];
      }, err => {
        this.processLoader = false;
        console.log(err);
      });
    }
    else {
      if(actionRef == 'discard') {
        this._location.back();
      }
    }
  }
  //FOR RESET COURSEWORK |END

  //FOR SEARCH TAG COURSEWORK |START
  onInputTag = (searchText: string): Observable<any[]> => {
    searchText = searchText.trim();
    if(searchText.length) {
      return this._questionBankService.fetchSearchTags(searchText);
    }
    return of([]);
  }
  //FOR SEARCH TAG COURSEWORK |END
  
  //FOR BUILD FIX
  ResousreActive: any = 1;
}
