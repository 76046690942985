import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { PassionProjectService } from 'src/app/services/passion-project.service';
import { NotificationService } from 'src/app/services/notification.service';
import { QuestionBankService } from 'src/app/services/question-bank.service';
import { ManageFileUploadService } from 'src/app/shared/modules/shared/services/manage-file-upload.service';
import { ResourceService } from 'src/app/services/resources/resource.service';

import { Observable, of } from 'rxjs';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-project-add',
  templateUrl: './project-add.component.html',
  styleUrls: ['./project-add.component.scss']
})
export class ProjectAddComponent implements OnInit {

  loader: boolean = false;

  //FOR STEP ONE FORM
  showStepOneForm: boolean = true;
  showStepTwoForm: boolean = false;
  isNextBtnClicked: boolean = false;
  @ViewChild('projTitle', { static: true }) projTitle: any;

  //FOR CREATE PROJECT
  saveLoader: boolean = false;
  addProjectForm!: FormGroup;
  formType: string = '';
  allProjectResources!: FormArray;
  projectGoals!: FormArray;
  projectDetails!: FormArray;
  submitProject: boolean = false;

  //FOR FILE UPLOAD
  checkChanges: boolean = false;
  checkAddMore: boolean = false;
  checkRemove: boolean = false;
  checkEdit: boolean = false;

  //FOR EDIT PROJECT
  isSavedDraft: boolean = false;
  passionProjectId: string = '';
  editProject: any = {};
  projectTitle: string = '';
  userId: string = '';
  role: string = '';

  constructor(private notificationService: NotificationService, private passionProjectService: PassionProjectService, private router: Router, private fb: FormBuilder, private quesBankService: QuestionBankService, private route: ActivatedRoute, private resourceService: ResourceService, private manageFileUploadService: ManageFileUploadService) { }

  ngOnInit() {

    this.role = localStorage.getItem('role');

    //CHECK EDIT PERMISSION
    if (this.role == 'student') {
      this.router.navigate(['/passion-projects']);
      return false;
    }

    this.initializeData();
  }

  initializeData() {
    this.userId = localStorage.getItem('user_id');
    this.createAddProjectForm();

    this.route.queryParams.subscribe(params => {
      this.passionProjectId = params.id;
      if (this.passionProjectId) {
        //FOR STEP ONE FORM HIDE
        this.showStepTwoForm = true;
        this.showStepOneForm = false;

        this.formType = 'edit';
        this.getPassionProject();
      }
      else {
        this.formType = 'add';
      }
    });
  }

  //FOR STEP ONE FORM |START
  formSubmitStepOne(projTitle: any) {
    this.isNextBtnClicked = true;
    if (!this.projTitle.valid) {
      this.projTitle.control.markAsTouched();
      return false;
    }
    this.showStepOneForm = false;
    this.showStepTwoForm = true;
    this.addProjectForm.controls['project_title'].setValue(this.projectTitle);
  }

  onTitleChange() {
    this.projectTitle = this.projectTitle.trim();
  }

  onDiscardStepOne() {
    this.router.navigate(['/passion-projects']);
  }
  //FOR STEP ONE FORM |END

  createAddProjectForm() {

    this.addProjectForm = this.fb.group({
      project_title: ['', [Validators.required, Validators.minLength(4)]],
      project_image: [''],
      project_description: ['', [Validators.required]],
      project_path: ['', [Validators.required]],
      project_tags: [[], [Validators.required]],
      project_mentors: [[]],
      project_resources: this.fb.array([]),
      project_goals: this.fb.array([this.createGoal()]),
      project_details: this.fb.array([this.createDetail()]),
      publish: [''],
    });

    this.allProjectResources = this.addProjectForm.get('project_resources') as FormArray;
    this.projectGoals = this.addProjectForm.get('project_goals') as FormArray;
    this.projectDetails = this.addProjectForm.get('project_details') as FormArray;

    //SHARED DATA
    this.manageFileUploadService.addForm = this.addProjectForm;
  }

  createResource() {
    return this.fb.group({
      title: ['', Validators.required],
      _id: [''],
      url: [''],
      type: ['', Validators.required]
    });
  }

  createGoal() {
    return this.fb.group({
      name: ['', [Validators.required]]
    });
  }

  createDetail() {
    return this.fb.group({
      title: [''],
      details: [''],
      path: ['']
    });
  }

  addMoreResource() {
    this.allProjectResources.push(this.createResource());
  }

  addMoreGoal() {
    this.projectGoals.push(this.createGoal());
  }

  removeGoal(index: number) {
    if (confirm('Are you sure want to delete project goal ' + (index + 1) + ' ?')) {
      this.projectGoals.removeAt(index);
    }
  }

  addMoreProjectDetail(ref = '') {
    this.projectDetails.push(this.createDetail());
    if (ref != 'edit') {
      this.checkAddMore = !this.checkAddMore;
    }
  }

  removeDetail(index: number) {
    if (confirm('Are you sure want to delete project detail ' + (index + 1) + ' ?')) {
      this.projectDetails.removeAt(index);
      //console.log(this.addProjectForm.value);
    }
  }

  //FOR TAGS |START
  onInputTag = (searchText: string): Observable<any[]> => {
    searchText = searchText.trim();
    if (searchText.length) {
      return this.quesBankService.fetchSearchTags(searchText);
    }
    return of([]);
  }

  onInputMentor = (searchText: string): Observable<any[]> => {
    searchText = searchText.trim();
    if (searchText.length) {
      return this.passionProjectService.searchTeamMember(searchText, 'mentor');
    }
    return of([]);
  }
  //FOR TAGS |END

  //FOR FORM SUBMIT
  onAddProjectFormSubmit(submitAction: string) {
    this.submitProject = true;

    this.manageFileUploadService.submitProject = this.submitProject;
    this.checkChanges = !this.checkChanges;

    //console.log(this.addProjectForm.value);
    if (submitAction != 'save_draft') {
      if (!this.validateForm()) {
        return false;
      }
    }
    else {
      if (this.addProjectForm.get('project_title').invalid) {
        this.notificationService.showNotification("error", "Project title can not be empty.");
        return false;
      }
    }
    const postData = this.getPostData(submitAction);
    //console.log(postData);

    //SAVE PASSION PROJECT DATA
    if (this.formType == 'add') {
      this.saveLoader = true;
      this.passionProjectService
        .addNewPassionProject(postData)
        .subscribe(addProject => {
          this.saveLoader = false;
          this.submitProject = false;
          this.notificationService.showNotification('success', addProject.message);
          //TO REMOVE RESOURCES
          this.removeResources('add');
          this.router.navigate(['/passion-projects']);
        },
          (err) => {
            this.saveLoader = false;
            this.submitProject = false;
            this.notificationService.showNotification('error', err.message);
          }
        );
    }
    else {
      this.saveLoader = true;
      this.passionProjectService
        .updatePassionProject(postData)
        .subscribe(addProject => {
          this.saveLoader = false;
          this.submitProject = false;
          this.notificationService.showNotification('success', addProject.message);
          //TO REMOVE RESOURCES
          this.removeResources('edit');
          this.router.navigate(['/passion-projects']);
        },
          (err) => {
            this.saveLoader = false;
            this.submitProject = false;
            this.notificationService.showNotification('error', err.message);
          }
        );
    }
  }

  getFileUploadStatus(data) {
    //console.log(data);
  }

  getPostData(submitAction) {
    let postData = this.addProjectForm.value;

    //SET PROJECT OVERVIEW
    postData.project_overview = {
      description: postData.project_description,
      path: postData.project_path
    }

    //SET MENTORS LIST
    const mentorsList = postData.project_mentors.map((item) => ({ id: item.id, username: item.username }));
    postData.project_mentors = mentorsList;

    //SET PUBLISH STATUS
    if (submitAction == 'save_draft') {
      postData.publish = false;
    }
    else {
      postData.publish = true;
    }

    //SET PROJECT GOALS
    postData.project_goals = postData.project_goals.map((item) => (item.name == '') ? '' : item.name);

    //SET PROJECT ID
    if (this.formType == 'edit') {
      postData.project_id = this.passionProjectId;
    }

    //REMOVE EXTRA KEYS
    delete postData.project_description;
    delete postData.project_path;

    return postData;
  }

  validateForm() {
    if (this.addProjectForm.invalid) {
      this.notificationService.showNotification("error", "Please correct the form like Resources, Overview, Title, and other need to be correct.");
      return false;
    }
    return true;
  }

  //FOR EDIT PROJECT |START
  getPassionProject() {
    this.loader = true;
    this.passionProjectService
      .getPassionProject(this.passionProjectId).subscribe(res => {
        this.loader = false
        this.editProject = res.projects;
        //console.log(this.editProject);

        //CHECK EDIT PERMISSION
        if (!this.editProject.edit_rights) {
          this.router.navigate(['/passion-projects']);
          return false;
        }

        this.isSavedDraft = res.projects.publish;
        this.setEditProject();
      },
        err => {
          this.loader = false
          this.notificationService.showNotification('error', err.message);
        });
  }

  setEditProject() {

    if (this.editProject) {

      this.projectTitle = this.editProject.project_title;
      this.addProjectForm.controls['project_title'].setValue(this.editProject.project_title);
      this.addProjectForm.controls['project_description'].setValue(this.editProject.project_overview.description);
      this.addProjectForm.controls['project_tags'].setValue(this.editProject.project_tags);

      const mentorsList = this.editProject.project_mentors.map((item) => ({ id: item.id, username: item.user_name })).filter((item) => item.id != this.userId);
      this.addProjectForm.controls['project_mentors'].setValue(mentorsList);

      this.projectGoals.clear();
      this.editProject.project_goals.forEach((item, index) => {
        this.addMoreGoal();
        this.addProjectForm.controls['project_goals']['controls'][index].controls.name.setValue(item);
      });

      this.projectDetails.clear();
      this.editProject.project_details.forEach((item, index) => {
        this.addMoreProjectDetail('edit');
        this.addProjectForm.controls['project_details']['controls'][index].controls.title.setValue(item.title);
        this.addProjectForm.controls['project_details']['controls'][index].controls.details.setValue(item.details);
      });

      //SET UPLOADED RESOURCES
      this.manageFileUploadService.editProject = this.editProject;
      this.checkEdit = !this.checkEdit;
    }
  }
  //FOR EDIT PROJECT |END

  //FOR RESET DATA |START
  onDiscardClicked() {
    //TO REMOVE RESOURCES
    this.removeResources('discard');
  }
  //FOR RESET DATA |END

  //FOR RESET DATA |START
  onResetClicked() {
    if (confirm('Are you sure want to reset changes ?')) {
      this.resetPassionProject();
    } else {
      return false;
    }
  }

  resetPassionProject() {
    this.removeResources('reset');
    this.clearData();
    this.initializeData();
  }
  //FOR RESET DATA |END

  removeResources(actionRef = '') {
    let removeFileList: any[] = [];
    if (actionRef == 'add' || actionRef == 'edit') {
      removeFileList = this.manageFileUploadService.allDeletedFiles;
    }
    else {
      removeFileList = this.manageFileUploadService.allUploadedFiles;
    }

    if (removeFileList.length > 0) {
      this.saveLoader = true;
      this.resourceService.removeResource('', removeFileList).subscribe(res => {
        this.saveLoader = false;
        if (actionRef == 'discard') {
          this.router.navigate(['/passion-projects']);
        }
        this.manageFileUploadService.allUploadedFiles = [];
        this.manageFileUploadService.allDeletedFiles = [];
      }, err => {
        this.saveLoader = false;
        console.log(err);
      });
    }
    else {
      if (actionRef == 'discard') {
        this.router.navigate(['/passion-projects']);
      }
    }
  }

  //FOR SELECT MENTORS
  onGetTeamStatus(data) {
    const selectedMentors = data.selectedMembers.map((item) => ({ id: item.id, username: item.username }));
    this.addProjectForm.get('project_mentors').setValue(selectedMentors);
  }

  clearData() {
    this.submitProject = false;
    this.manageFileUploadService.addForm = null;
    this.manageFileUploadService.actionRef = '';
    this.manageFileUploadService.editProject = {};
    this.manageFileUploadService.submitProject = false;
    this.manageFileUploadService.allUploadedFiles = [];
    this.manageFileUploadService.allDeletedFiles = [];
  }

  ngOnDestroy() {
    this.clearData();
  }
}
