import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterSidebarComponent } from './components/filter-sidebar/filter-sidebar.component';
import { DefaultNameIconComponent } from './components/default-name-icon/default-name-icon.component';
import { NotFoundResultComponent } from '../../../not-found-result/not-found-result.component';
import { SearchFieldDirective } from './directives/search-field.directive';
import { DragDropFilesDirective } from './directives/drag-drop-files.directive';
import { FilesUploadComponent } from './components/files-upload/files-upload.component';
import { ResourceUploadComponent } from './components/resource-upload/resource-upload.component';
import { SingleFileUploadComponent } from './components/single-file-upload/single-file-upload.component';
import { ScrollableTabsComponent } from './components/scrollable-tabs/scrollable-tabs.component';
import { FileUploadUiComponent } from './components/file-upload-ui/file-upload-ui.component';
import { SplitTitlePipe } from './pipes/split-title.pipe';
import { TodayYesterdayPipe } from './pipes/today-yesterday.pipe';
import { TimeDayZonePipe } from './pipes/time-day-zone.pipe';


@NgModule({
  declarations: [FilterSidebarComponent, DefaultNameIconComponent, SearchFieldDirective, DragDropFilesDirective, FilesUploadComponent, NotFoundResultComponent, ResourceUploadComponent, SingleFileUploadComponent, ScrollableTabsComponent, FileUploadUiComponent,SplitTitlePipe, TodayYesterdayPipe, TimeDayZonePipe],
  imports: [
    CommonModule
  ],
  exports: [
  	FilterSidebarComponent,
  	DefaultNameIconComponent,
  	SearchFieldDirective,
  	DragDropFilesDirective,
    FilesUploadComponent,
    NotFoundResultComponent,
    ResourceUploadComponent,
    SingleFileUploadComponent,
    ScrollableTabsComponent,
    FileUploadUiComponent,
    SplitTitlePipe,
    TodayYesterdayPipe,
    TimeDayZonePipe
  ]
})
export class SharedModule { }
