<div class="banner">
    <div class="container text-center">
        <h1>Marking</h1>
    </div>
</div>
  
<div class="pageBody">


    <div class="container">

        <form class="example-form">
            <mat-form-field class="example-full-width">
              <input 
              (click)="setInd(i)" matInput id="resource"
              placeholder="Pick one" 
              aria-label="Resource"
              [matAutocomplete]="auto"
              #inputField 
              type="text" 
              [formControl]="myControl" 
              >
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let option of results" [value]="option.title">
                  {{ option.title }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </form>

        <form>
            <dl class="row">
                <dd class="col-md-12 resource_dd">
                    <h5 class="mb-1">Resource</h5>
                    <div class="form-group resourse_form_item">
                        <!-- <div class="resourse_form addResourceForm">
                            <button class="close-btn">X</button>
                            <div class="form-group">
                                <label class="mb-1">Title</label>
                                <input type="text" class="form-control" />
                            </div>
                            <div class="form-group">
                                <label class="mb-1">Browse Resource</label>
                                <div class="relative input_icon">
                                    <input type="text" class="form-control"
                                        (change)="search_resource($event)"
                                        placeholder="Search Resource Bank or type a URL" />
                                    <button class="searchBtn btn-on-input"><i
                                            class="fas fa-search"></i></button>
                                </div>
                              --  <datalist id="resource">
                                    <option value="Edge" *ngFor= "let res_list of resource_list">{{res_lits.title}}</option>
                                    <option value="Firefox">
                                    <option value="Chrome">
                                    <option value="Opera">
                                    <option value="Safari">
                                  </datalist>
                            </div>
                        </div> -->

                        <!-- <mat-form-field class="example-full-width" appearance="fill">
                            <mat-label>Number</mat-label>
                            <div class="relative input_icon">
                            <input type="text"
                                   placeholder="Pick one"
                                   aria-label="Number"
                                   matInput
                                   class="form-control"
                                   [formControl]="myControl"
                                   [matAutocomplete]="auto">
                            </div>
                            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                              <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                                {{option}}
                              </mat-option>
                            </mat-autocomplete>
                          </mat-form-field> -->
                          
                    </div>

                </dd>
            </dl>
        </form>
        <div class="d-flex justify-content-between align-items-center mb-4">
            <div class="pr-2">
                <select class="custom-select">
                    <option value="" selected>Courseware</option>
                    <option value="1">Option 1</option>
                    <option value="2">Option 2</option>
                </select>
            </div>
            <div class="pl-2">
                <button class="btn btn-primary ml-3">Button 1</button>
                <button class="btn btn-danger ml-3">Button 2</button>
            </div>
        </div>

        <div class="table-responsive">
            <table class="table table-bordered marking">
                <thead>
                    <tr>
                        <th>Team / Student</th>
                        <th>Submission</th>
                        <th>Doc</th>
                        <th>Self</th>
                        <th class="text-center">Peer</th>
                        <th>Feedback</th>
                        <th>Grade</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td rowspan="4">
                            Team
                        </td>
                        <td>
                            Outline Map
                        </td>
                        <td>
                            abc
                        </td>
                        <td>
                            abc
                        </td>
                        <td class="text-center">
                            <a href="#" class="btn btn-sm btn-secondary mx-2">Link 1</a>
                            <a href="#" class="btn btn-sm btn-secondary mx-2">Link 2</a>
                            <!-- <button class="btn btn-sm btn-primary">Link 2</button> -->
                        </td>
                        <td>
                            abc
                        </td>
                        <td>
                            abc
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Powerpoint
                        </td>
                        <td>
                            abc
                        </td>
                        <td>
                            abc
                        </td>
                        <td class="text-center">
                            <a href="#" class="btn btn-sm btn-secondary mx-2">Link 1</a>
                            <a href="#" class="btn btn-sm btn-secondary mx-2">Link 2</a>
                            <!-- <button class="btn btn-sm btn-primary">Link 2</button> -->
                        </td>
                        <td>
                            abc
                        </td>
                        <td>
                            abc
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Presentation
                        </td>
                        <td>
                            abc
                        </td>
                        <td>
                            abc
                        </td>
                        <td class="text-center">
                            <a href="#" class="btn btn-sm btn-secondary mx-2">Link 1</a>
                            <a href="#" class="btn btn-sm btn-secondary mx-2">Link 2</a>
                            <!-- <button class="btn btn-sm btn-primary">Link 2</button> -->
                        </td>
                        <td>
                            abc
                        </td>
                        <td>
                            abc
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Final Sub
                        </td>
                        <td>
                            abc
                        </td>
                        <td>
                            abc
                        </td>
                        <td class="text-center">
                            <a href="#" class="btn btn-sm btn-secondary mx-2">Link 1</a>
                            <a href="#" class="btn btn-sm btn-secondary mx-2">Link 2</a>
                            <!-- <button class="btn btn-sm btn-primary">Link 2</button> -->
                        </td>
                        <td>
                            abc
                        </td>
                        <td>
                            abc
                        </td>
                    </tr>
                    <tr>
                        <td rowspan="4">
                            Student
                        </td>
                        <td>
                            Outline Map
                        </td>
                        <td>
                            abc
                        </td>
                        <td>
                            abc
                        </td>
                        <td class="text-center">
                            <a href="#" class="btn btn-sm btn-secondary mx-2">Link 1</a>
                            <a href="#" class="btn btn-sm btn-secondary mx-2">Link 2</a>
                            <!-- <button class="btn btn-sm btn-primary">Link 2</button> -->
                        </td>
                        <td>
                            abc
                        </td>
                        <td>
                            abc
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Powerpoint
                        </td>
                        <td>
                            abc
                        </td>
                        <td>
                            abc
                        </td>
                        <td class="text-center">
                            <a href="#" class="btn btn-sm btn-secondary mx-2">Link 1</a>
                            <a href="#" class="btn btn-sm btn-secondary mx-2">Link 2</a>
                            <!-- <button class="btn btn-sm btn-primary">Link 2</button> -->
                        </td>
                        <td>
                            abc
                        </td>
                        <td>
                            abc
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Presentation
                        </td>
                        <td>
                            abc
                        </td>
                        <td>
                            abc
                        </td>
                        <td class="text-center">
                            <a href="#" class="btn btn-sm btn-secondary mx-2">Link 1</a>
                            <a href="#" class="btn btn-sm btn-secondary mx-2">Link 2</a>
                            <!-- <button class="btn btn-sm btn-primary">Link 2</button> -->
                        </td>
                        <td>
                            abc
                        </td>
                        <td>
                            abc
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Final Sub
                        </td>
                        <td>
                            abc
                        </td>
                        <td>
                            abc
                        </td>
                        <td class="text-center">
                            <a href="#" class="btn btn-sm btn-secondary mx-2">Link 1</a>
                            <a href="#" class="btn btn-sm btn-secondary mx-2">Link 2</a>
                            <!-- <button class="btn btn-sm btn-primary">Link 2</button> -->
                        </td>
                        <td>
                            abc
                        </td>
                        <td>
                            abc
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>            
    </div>
</div>