import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { allCategory, CourseInfo } from 'src/app/model/course.model';
import { CourseService } from 'src/app/services/course.service';
import { ShareService } from 'src/app/services/share.service';

@Component({
  selector: 'app-ec-course-board',
  templateUrl: './ec-course-board.component.html',
  styleUrls: ['./ec-course-board.component.scss']
})
export class EcCourseBoardComponent implements OnInit {
  showSchBx: boolean = false;
  
  constructor(private courseService: CourseService, private shareService: ShareService) { }
  allCategory: allCategory[] = [];
  categoryVisDetails: CourseInfo[] = [];
  susbcribe_courses: CourseInfo[] = [];
  teach_on_edu: CourseInfo[] = [];
  other_course: CourseInfo[] = [];
  role = '';
  total_pages
  current_page;
  number_of_page;

  total_pages_deactivate;
  current_page_deactivate;
  number_of_pages_deactivate = []
  get permissions() {
    return JSON.parse(localStorage.getItem("permissions"));
  }

  //FOR BUILD FIX
  active: any = '';

  ngOnInit() {
    let val = '';
    let status = false;
    this.role = localStorage.getItem('role');

    //FOR BUILD FIX
    if(this.role !== 'student') {
     this.active = 'teach';
    }
    else {
      this.active = 'myLearn';
    }

    if(this.role == 'student') {
      status = true;
    }
    if(this.role == 'super_admin') {
      this.get_deactivated_course(1);
    }
    this.getAllCourses();
  //  this.onChangeCategory(val, 1, status);
  }
  loader = false;
  getAllCourses() {
    this.loader = true;
    this.courseService.categoryAPI().pipe(finalize(() => {
      this.loader = false;
    })).subscribe(data => {
      this.allCategory = data;
    });
  }

  


  onChangeCategory(value, page, status, component_name?:string) {
    this.loader = true;
    this.number_of_page = [];
    this.courseService.categoryVisCourses(value, page, status,component_name).pipe(finalize(() => {
      this.loader = false;
    })).subscribe(courseDetails => {
      this.categoryVisDetails = [];
      this.teach_on_edu = [];
      this.susbcribe_courses = [];
      this.other_course = []
      this.categoryVisDetails = courseDetails.courses_info.data;
      this.total_pages = courseDetails.courses_info.total_pages;
      this.current_page = courseDetails.courses_info.current_page;
      
      for (let i = 0; i < this.total_pages; i++) {
        this.number_of_page.push(i + 1);
      }

      this.getReletedCourses();
    })
  }

  openSchBx() {
    this.showSchBx = !this.showSchBx;
  }

  getReletedCourses() {
    this.categoryVisDetails.forEach(element => {
      if (element['subscribed'] === true) {
        this.susbcribe_courses.push(element)
      }
      if (element['edit_right'] === true) {
        this.teach_on_edu.push(element);
      }
      if (element['subscribed'] == - false) {
        this.other_course.push(element)
      }
    });
  }

  findIndexOf(slug, pmenusSlug) {
    let indexOfValue;
    indexOfValue = this.shareService.getIndexArr(slug, pmenusSlug)
    return indexOfValue;
  }

  componentname
  get_index_number(page, status?: string, category_id?: string) {
    this.componentname = page.split('+');
    let page_number = this.componentname[0];
    if(this.componentname[1] == 'deactivate') {
      this.get_deactivated_course(Number(page_number));
    }
    this.onChangeCategory('', Number(page_number), '',this.componentname[1]);
  }

  get_index_number_unsubs(page, status, category_id) {
    this.componentname = page.split('+');
    let page_number = this.componentname[0];
    this.onChangeCategory(category_id, Number(page_number), status, this.componentname[1]);
  }

  get_index_number_subs(page, status, category_id) {
    this.componentname = page.split('+');
    let page_number = this.componentname[0];
    this.onChangeCategory(category_id, Number(page_number), status, this.componentname[1]);
  }
  deactivated_course_list = [];
  get_deactivated_course(page) {
    this.courseService.deactivated_course(page).subscribe(get_list=> {
      this.deactivated_course_list = get_list.response.data; 
      this.current_page_deactivate = get_list.response.current_page;
      this.total_pages_deactivate = get_list.response.total_pages;
      this.number_of_pages_deactivate = [];
      for (let i = 0; i < this.total_pages_deactivate; i++) {
        this.number_of_pages_deactivate.push(i + 1);
      }
    },
    (err)=> {
      console.log(err);
    })
  }
}
