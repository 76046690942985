import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpParams, HttpHeaders, HttpRequest, HttpEvent } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class QuestionService {
  apiEndpoint: any;
  constructor(private http: HttpClient) {
    this.apiEndpoint = environment.APIEndpoint;
  }

  getMyQuestionsList(): Observable<any> {
    return this.http.get<any>(this.apiEndpoint + "/questions?user_specific=true")
  }
  getMyQuesList(): Observable<any> {
    return this.http.get<any>(this.apiEndpoint + "/user_questions?user_specific=true")
  }
  getAllQuestionsList(is_extracted, currentPage, total_question, categories): Observable<any> {
    let params = new HttpParams();

//    backend not recived Boolean
    // params = params.append('is_extracted', is_extracted);
    params = params.append('page', currentPage);
    params = params.append('total_question', total_question);
    params = params.append('categories', categories);
    return this.http.get<any>(this.apiEndpoint + "/question_bank", { params }).pipe(catchError(this.handleError))
  }
  getMyAnswerList(): Observable<any> {
    return this.http.get<any>(this.apiEndpoint + "/my_answers").pipe(catchError(this.handleError))
  }



  getRecommendedQuestionsList(count): Observable<any> {
    return this.http.post<any>(this.apiEndpoint + "/recommend_questions", { count: count })
  }
  getRecommendedQuestions(count): Observable<any> {
    return this.http.post<any>(this.apiEndpoint + "/my_recommend_questions", { count: count })
  }

  getMyAnswer(): Observable<any> {
    return this.http.get<any>(this.apiEndpoint + "/my_answers")
  }

  myAnswer(answer_id): Observable<any> {
    let params = new HttpParams();
    params = params.append('answer_id', answer_id);
   

    return this.http.get<any>(this.apiEndpoint + "/answers", { params })
  }

   myAnswersession(answer_id,commentFilter): Observable<any> {
    let params = new HttpParams();
    params = params.append('answer_id', answer_id);
    params = params.append('comment_filter', commentFilter);

    return this.http.get<any>(this.apiEndpoint + "/answers", { params })
  }

  getQuestionAnswer(questionId): Observable<any> {
    let params = new HttpParams();
    params = params.append('id', questionId);
    return this.http.get<any>(this.apiEndpoint + "/user_questions", { params })
  }


  getUserQuestionAnswer(questionId): Observable<any> {
    let params = new HttpParams();
    params = params.append('id', questionId);
    return this.http.get<any>(this.apiEndpoint + "/user_questions", { params })
  }
  getUserAnswerDetail(answerId, commentFilter): Observable<any> {
    let params = new HttpParams();
    params = params.append('answer_id', answerId);
    params = params.append('comment_filter', commentFilter);
    return this.http.get<any>(this.apiEndpoint + "/answers", { params })
    // return this.http.get<any>(this.apiEndpoint + "/user_answers", { params })
  }


  getSimilarTypesQuestion(question, question_id) {
    let params = new HttpParams();
    params = params.append('question_id', question_id);
    params = params.append('search_text', question)
    return this.http.get<any>(this.apiEndpoint + "/similar_questions", { params })
  }

  getQuestionVideo(Course_id, Week, Week_index) {
    let body = {
      course_id: Course_id,
      week: Week,
      week_index: Week_index
    }
    return this.http.post<any>(this.apiEndpoint + "/week_detail", body)
  }

  answerFeedbackApi(answer_id, value) {
    let body = {
      answer_id: answer_id,
      feedback: value
    }
    return this.http.post<any>(this.apiEndpoint + "/answer/feedback", body).pipe(catchError(this.handleError))

  }

  setLikeDislike(value, answer_id) {
    console.log(answer_id)
    let body = {
      answer_id: answer_id,
      status: value
    }
    return this.http.post<any>(this.apiEndpoint + "/submit_like_dislike", body).pipe(catchError(this.handleError))

  }

  setRating(value, review, answer_id, courseId) {
    let body = {
      answer_id: answer_id,
      course_id: courseId,
      rating: value.rating,
      review: value.feedBackReview
    }
    return this.http.post<any>(this.apiEndpoint + "/submit_rating", body).pipe(catchError(this.handleError))

  }

  handleError(error) {
    console.log('error abh',error)

    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(error);
  }

  answerMetaData(answer_id) {
    let params = new HttpParams();
    params = params.append('answer_id', answer_id);
    return this.http.get<any>(this.apiEndpoint + "/answers", { params }).pipe(catchError(this.handleError))
  }

  askQuestion(formValue, selectedPic, selectedVideo) {
    const askQuestion = new FormData();
    askQuestion.append('question_image', selectedPic);
    askQuestion.append('question', formValue.yourQuestion);
    askQuestion.append('language', "en");
    askQuestion.append('coins', formValue.coins);
    askQuestion.append('category_id', formValue.subject);
    askQuestion.append('video_path', selectedVideo);
    askQuestion.append('video_time', '');

    // askQuestion.append('subject', formValue.subject);

    return this.http.post<any>(this.apiEndpoint + "/ask_question", askQuestion).pipe(catchError(this.handleError))
  }

  approveAnswer(answerId, questionId) {
    let body = {
      answer_id: answerId,
      question_id: questionId,
      status: "approved"

    }

    return this.http.post<any>(this.apiEndpoint + "/answer/verification", body).pipe(catchError(this.handleError));
  }

  closeQuestion(questionId) {
    let body = {
      question_id: questionId,
      question_status: "closed"

    }

    return this.http.post<any>(this.apiEndpoint + "/close_question", body).pipe(catchError(this.handleError));
  }

  getStudentQuestionsList(body): Observable<any> {
    return this.http.post<any>(this.apiEndpoint + "/teacher_student_question", body);
  }

  getStudentAnswerList(body): Observable<any> {
    return this.http.post<any>(this.apiEndpoint + "/teacher_student_answer", body)
  }

  updateQuestion(formValue, selectedPic, selectedVideo, question_id, delete_image, delete_video) {
    const updateQuestion = new FormData();
    updateQuestion.append('question_image', selectedPic);
    updateQuestion.append('language', "en");
    updateQuestion.append('question', formValue.yourQuestion);
    updateQuestion.append('category_id', formValue.subject);
    updateQuestion.append('question_id', question_id);
    updateQuestion.append('delete_image', delete_image);
    updateQuestion.append('delete_video', delete_video);
    updateQuestion.append('coins', formValue.coins);
    updateQuestion.append('video_path', selectedVideo);
    //
    return this.http.post<any>(this.apiEndpoint + "/update_question", updateQuestion).pipe(catchError(this.handleError))
  }

  saveAnswer(answerFormValue, question_id) {
    let answer_text = '';
    answer_text = answerFormValue.answer_text;
    let body = {
      answer_text: answer_text,
      question_id: question_id,
      speech_text: answer_text
    }
    return this.http.post<any>(this.apiEndpoint + "/questions/answer", body).pipe(catchError(this.handleError));
  }

  saveAnswerInVideo(selectedVideo, selectedPicture, question_id) {
    const body = new FormData();
    body.append('answer', selectedVideo);
    body.append('image_frame', selectedPicture);

    var reqHeader = new HttpHeaders({
      'question-id': question_id
    });
    return this.http.post<any>(this.apiEndpoint + "/answer_question", body, { headers: reqHeader }).pipe(catchError(this.handleError));
  }

  getSearch_question(search): Observable<any> {
    let params = new HttpParams();
    params = params.append('search_text', search)
    return this.http.get<any>(this.apiEndpoint + "/search/questions", { params })
  }

  saveAnswers(body) {
    return this.http.post<any>(this.apiEndpoint + "/questions/answer", body).pipe(catchError(this.handleError));

  }

  deleteQuestion(question_id) {
    let params = new HttpParams();
    params = params.append('question_id', question_id)
    return this.http.delete<any>(this.apiEndpoint + "/delete_questions", { params }).pipe(catchError(this.handleError))
  }

  deleteAnswer(answer_id, question_id) {
    let params = new HttpParams();
    // params = params.append('question_id',questionId)
    params = params.append('answer_id', answer_id);
    params = params.append('question_id', question_id);
    return this.http.delete<any>(this.apiEndpoint + "/delete_answers", { params }).pipe(catchError(this.handleError))
  }

  get_categories() {
    return this.http.get<any>(this.apiEndpoint + "/get_taxonomy").pipe(catchError(this.handleError))
  }

  get_search_categories(search_text:string) {
    let params = new HttpParams();
    params = params.append('search', search_text);
    return this.http.get<any>(this.apiEndpoint + "/search_taxonomy", { params }).pipe(catchError(this.handleError))
  }

  get_sub_categories(category_is:any,searchQuery:string) {
    let params = new HttpParams();
    // params = params.append('question_id',questionId)
    params = params.append('category_id', category_is);
    if(searchQuery !== ''){
      params = params.append('search', searchQuery);
    }
    return this.http.get<any>(this.apiEndpoint + "/taxonomy", { params }).pipe(catchError(this.handleError))
  }

  add_categories(value) {
    let body = {
      "category_name": value.catgoryName,
    }
    if (value.catgoryId != 'None') {
      body["parent_id"] = value.catgoryId
    }
    return this.http.post<any>(this.apiEndpoint + "/add_taxonomy", body).pipe(catchError(this.handleError))
  }

  edit_categories(value) {
    let body = {
      "category_name": value.catgoryName,
    }
    // if (value.catgoryId != 'None') {
    body["id"] = value.catgoryId
    // }
    return this.http.patch<any>(this.apiEndpoint + "/edit_taxonomy", body).pipe(catchError(this.handleError))
  }

  // Upload Files

upload(file: File): Observable<HttpEvent<any>> {
  const formData: FormData = new FormData();

  formData.append('file', file);

  const req = new HttpRequest('POST', `${this.apiEndpoint}/upload`, formData, {
    reportProgress: true,
    responseType: 'json'
  });

  return this.http.request(req);
}

getFiles(): Observable<any> {
  return this.http.get(`${this.apiEndpoint}/files`);
}

commentLikeDislike(body) {
  return this.http.post<any>(this.apiEndpoint + "/answers/comments/like_dislike", body).pipe(catchError(this.handleError));
}

deleteResource(s3_key_val): Observable<any>{
  let params = new HttpParams();
  params = params.append('s3_key', s3_key_val);
  return this.http.post<any>(this.apiEndpoint + "/delete_resource",'', {params}).pipe(catchError(this.handleError));
}

edit_answer(body) {
  return this.http.patch<any>(this.apiEndpoint + "/edit_answer", body).pipe(catchError(this.handleError))
}

categoryAPI(val): Observable<any> {
  let params = new HttpParams();
  params = params.append('filter', val);
  return this.http.get<any>(this.apiEndpoint + '/course_category',{params}).pipe(
    catchError(this.handleError)
  );
}

}

