<div class="banner">
    <div class="container text-center fltdOptBar">
        <h1>Courses</h1>
        <!-- <div class="fltBtnBx">
            <button type="button" class="btn btn-danger fltBtn" (click)="openSchBx()"><i class="fas fa-search"></i></button>
            <div class="ddSearch" *ngIf="showSchBx">
              <div class="search-hero">
                  <i class="fas fa-search"></i>
                  <input
                    class="form-control glyphicon glyphicon-name"
                    type="text"
                    name="search"
                    autocomplete="off"
                    [(ngModel)]="searchText"
                    placeholder="Search Course">
              </div>
            </div>
        </div> -->
    </div>
</div>
  
<div class="pageBody">
    <div class="container" [ngClass]="{submitting : loader}">
            <div class="spinner-border text-warning formLoader" role="status" *ngIf="loader">
                <span class="sr-only">Loading...</span>
            </div>
        <div class="d-md-flex h-bar justify-content-between profile">
            <div class="order-md-2" *ngIf="findIndexOf(permissions, 'add_course') > -1">
                <button class="btn btn-link" routerLink="/courses/add-course"><i class="fas fa-plus mr-2"></i>Add Course</button>
            </div>
            <div class="order-md-1">
                <ul ngbNav #coursesNav="ngbNav" [(activeId)]="active" class="nav-pills course" orientation="vertical">
                    <li ngbNavItem="myLearn" *ngIf="role == 'student'">
                        <a ngbNavLink>My Courses</a>
                        <ng-template ngbNavContent>
                            <app-learning-list
                            [total_pages]="total_pages"
                            [current_page]="current_page"
                            [number_of_page]="number_of_page"
                            [categoryVisDetails]="susbcribe_courses"
                            (send_index_number_subs)="get_index_number_subs($event, true, '')"
                            [allCategory]="allCategory"></app-learning-list>                
                        </ng-template>
                    </li>
                    <li ngbNavItem="teach" *ngIf="role != 'student'">
                        <a ngbNavLink>Teach on Educollab</a>
                        <ng-template ngbNavContent>
                            <app-teach
                            [total_pages]="total_pages"
                            [current_page]="current_page"
                            [number_of_page]="number_of_page"
                            [categoryVisDetails]="teach_on_edu"
                            (send_index_number)="get_index_number_unsubs($event, false, '')"
                            [allCategory]="allCategory"></app-teach>
                            
                        </ng-template>
                    </li>
                    <li ngbNavItem="Deactivated" *ngIf="role == 'super_admin'">
                        <a ngbNavLink>Deactivated Courses</a>
                        <ng-template ngbNavContent>
                            <app-deactivated-courses
                            [total_pages]="total_pages_deactivate"
                            [current_page]="current_page_deactivate"
                            [number_of_page]="number_of_pages_deactivate"
                            [categoryVisDetails]="deactivated_course_list"
                            (send_index_number_deactivate)="get_index_number($event)"
                            [allCategory]="allCategory"></app-deactivated-courses>
                        </ng-template>
                    </li>

                    <li ngbNavItem="others" *ngIf="role != 'super_admin'">
                        <a ngbNavLink>Other Courses</a>
                        <ng-template ngbNavContent>
                            <app-other-courses
                            [total_pages]="total_pages"
                            [current_page]="current_page"
                            [number_of_page]="number_of_page"
                            [categoryVisDetails]="other_course"
                            (send_index_number_unsubs)="get_index_number($event)"
                            [allCategory]="allCategory"></app-other-courses>
                        </ng-template>
                    </li>
                </ul> 
            </div>   
                     
        </div>
        <div [ngbNavOutlet]="coursesNav" class="pt-4"></div>
    </div>
</div>