<div class="dragDropWrap" appDragDropFiles (fileDropped)="onFileDropped($event)" [ngClass]="{ 'is-invalid': showFieldErr }">
    <div class="dragDrop dragDropOr  d-none d-md-flex">
        <figure *ngIf="fieldSupports == 'image'" class="dragDropIcon"><img src="../../../assets/images/apps-icon/flower-icon.png" alt="flower-icon.png" /></figure>
        <figure *ngIf="fieldSupports == 'file'" class="dragDropIcon"><i class="fas fa-upload"></i></figure>
        <div class="dragDropContent">
            <p *ngIf="fieldSupports == 'image'">
                <strong class="blue-text d-block">Drag & Drop your Image here
                </strong>Supports image
            </p>
            <p *ngIf="fieldSupports == 'file'">
                <strong class="blue-text d-block">Drag & Drop your file here
                </strong>Supports image, video, audio, pdf & text files
            </p>
        </div>
        <span class="divideOr"></span>
    </div>
    <div class="dragDropUpload btn-groups">
        <label class="custom-upload-btn btn btn-blue btn-shadow btn-icon-text">
            <input *ngIf="fieldSupports == 'image'" type="file" class="file-upload-input" accept="image/*" (change)="onSelectFiles($event)" />
            <input *ngIf="fieldSupports == 'file'" type="file" class="file-upload-input" accept="image/*,video/*,audio/*,doc/*,application/*,text/*" multiple (change)="onSelectFiles($event)" />
            <span class=""><i class="fas fa-download"></i>{{fieldSupports == 'file' ? 'Browse Files': 'Browse File'}}</span>
        </label>
    </div>
</div>

<div *ngIf="showFieldErr == true" class="invalid-feedback">
    <div>{{fieldNameErr}} is required</div>
</div>

<div class="uploadedItemWrap">
    <div class="mt-4 uploadedItem" *ngFor="let multiFile of multiFilesData; let idx = index" [ngClass]="{noRemoveBtn: idx == 0}">
        <div class="uploadedItemTop" *ngIf="multiFile.progressBar > 0">
            <div class="uploadedItemDetail">
                <p class="mb-1">
                    <strong class="blue-text">{{multiFile.name}}</strong>
                </p>
                <p class="uploadedItemStatus">
                    <small>{{multiFile.progressBar}}% Complete</small>
                </p>
            </div>
            <div class="uploadedItemAction">
            </div>
        </div>
        <div class="progress uploadedProgress" *ngIf="multiFile.progressBar > 0">
            <div class="progress-bar uploadedProgressBlue"
                role="progressbar" [style.width.%]="multiFile.progressBar" aria-valuenow="multiFile.progressBar"
                aria-valuemin="0" aria-valuemax="100"></div>
        </div>
    </div>

    <div class="mt-4 uploadedItem" *ngFor="let uploadedFile of uploadedFilesData; let uploadedFileIndex=index">
        <div class="uploadedItemTop" *ngIf="!uploadedFile.resource_deleted">
            <div class="uploadedItemDetail">
                <p class="mb-1">
                    <a href="{{uploadedFile.resource_url}}" target="_blank"><strong class="blue-text">{{uploadedFile.resource_title}}</strong></a>
                    <a *ngIf="!uploadedFile.resource_title" href="{{uploadedFile.resource_url}}" target="_blank"><strong class="blue-text">{{'Selected Image'}}</strong></a>
                </p>                    
            </div>
            <div class="uploadedItemAction">
                <a href="javascript:void(0)" (click)="deleteUploadedResource(uploadedFileIndex)" class="uploadedActionLink">
                <i class="far fa-times-circle"></i>
                </a>
            </div>
        </div>
    </div>
</div>