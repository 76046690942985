<div class="new_UIstyle">
    <div class="pageBodyWrap questionBankMain">
        <!-- Banner Section Start -->
        <section class="banner banner-inner text-center"
            style="background-image: url('assets/images/banner/question-bank.jpg');">

            <div class="container">
                <!-- <button class="btn btn-danger" routerLink="/add-question" ngbTooltip="Add Question">
                    <i class="fas fa-plus-circle"></i>
                </button> -->
                <div class="banner-content">
                    <h2 class="white-text amitaBold mb-2">Question Pool</h2>
                    <p class="avenirRoman white-text lead">Collection of all the Questions on Edu-collab.</p>

                    <form>
                        <div class="input-with-icon input-with-icon-right">
                            <input type="text" class="form-control form-control-lg" placeholder="Search for Questions"
                                appSearchField (sendSearchData)="onStartSearch($event)" />
                            <button class="input-with-icon-btn"><i class="fa fa-search"></i></button>
                        </div>
                    </form>
                    <div class="btn-group  mt-4">
                        <button class="btn btn-primary" routerLink="/add-question"> + Add New Question</button>
                        <button class="btn btn-primary ml-4" routerLink="/import-question"> + Import Question</button>
                    </div>

                </div>
            </div>
        </section>

        <section class="section addQuestionWrap pb-0">
            <div class="container">
                <div class="add-QuestionOption form mb-3">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-4 col-md-3 qb-col border-top  border-bottom">
                                <!-- <label class="avenirBlack mb-2">Question Type</label> -->
                                <select
                                    class="form-control form-control-transparent pl-0 form-control-lg form-control-caret"
                                    [ngClass]="{ 'is-invalid': submitAddQus && addQus.type.errors }"
                                    (change)="selectQtype($event.target.value,0,'addques')">
                                    <option selected value="">Question Type</option>
                                    <option *ngFor="let qt of qusTypes" [value]="qt._id">
                                        {{qt.name}}
                                    </option>
                                </select>
                            </div>

                            <div class="col-4 col-md-3 qb-col border-top  border-bottom">
                                <!-- <label class="avenirBlack mb-2">Tags</label> -->
                                <tag-input
                                    class="form-control form-control-transparent form-control-lg form-control-caret"
                                    [maxItems]='1' [ngModel]="[]" (onAdd)="onAdded($event)"
                                    (onRemove)="removeTag($event)" [secondaryPlaceholder]="'Search Tags'">
                                    <tag-input-dropdown [autocompleteItems]="qusTags"
                                        [displayBy]="'tag'"></tag-input-dropdown>
                                </tag-input>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>



        <section class="section questionBankContWrap"
            [ngClass]="{submitting : loader || loader1 || loader2 || loader3}">
            <div class="progress_loader" *ngIf="loader || loader1 || loader2 || loader3">
                <div class="spinner-border text-warning formLoader" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <div class="container">
                <div class="questionBankLists">
                    <div class="questionBankItem bg-grey" *ngFor="let ql of qusList; let i = index;">
                        <div class="questionItemLeft">
                            <p class=" mb-2" [innerHTML]="ql.question"></p>
                            <div class="tags-badges">
                                <div *ngFor="let tags of ql.tags">
                                    <span class="badge badge-green-lt">{{tags.tag}}</span>
                                </div>
                            </div>

                        </div>
                        <div class="questionItemRight icons-group">
                            <a class="questionItemLink ItemEditLink">
                                <i class="fas fa-pencil-alt" (click)="editSelectedQues(ql)"></i> </a>
                            <a class="questionItemLink ItemDeleteLink" (click)='openModelDeleteQuestion(deleteQues) '><i
                                    class="fas fa-trash-alt"></i></a>
                        </div>
                        <!-- <div class="questionItemFooter">
                            <div class="questionItemFooterLeft">
                                <p>10 Answers Given</p>
                            </div>
                            <div class="questionItemFooterRight">
                                <p class="primary-text"><strong>2 Answers Approved</strong></p>
                            </div>
                        </div> -->
                        <a class="questionItemLink ItemAnchorLink" (click)="viewQuestion(ql._id)"><i
                                class="fa fa-angle-right"></i></a>


                 <!-- new ui delete question -->
                    <ng-template #deleteQues let-c="close" let-d="dismiss">
                        <div class="new_UIstyle">
                            <div class="modal-header justify-content-between align-items-center">
                                <h5 class="avenirBook modal-title text-danger mb-0">Delete Question</h5>
                                <button type="button" class="close ml-0" aria-label="Close"
                                    (click)="closeQuestionPopup()">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <p>Are you sure you want to delete Question<strong class="text-danger">?</strong></p>
                            </div>

                            <div class="modal-footer">
                                <div class="text-left">
                                    <button type="submit" class="btn btn-primary btn-sm"
                                        (click)="closeQuestionPopup()">NO</button>
                                </div>
                                <div class="text-right">
                                    <button type="submit" class="btn btn-primary btn-sm"
                                        (click)="deletequestionInQb(ql._id)">YES</button>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    </div>
                    
                 

                    <div class="alert alert-info text-center" *ngIf="qusList.length === 0">
                        <p>No Data Found.</p>
                    </div>
                </div>

                <!-- <div *ngIf="length > -1">
                    <strong>Please Search For Result</strong>
                </div> -->




                <!-- <div class="table-responsive maxHe8" *ngIf=isQusAvailable>
                    <table class="table alt">
                        <thead>
                            <tr>
                                <th class="w60p text-left">S.No.</th>
                                <th class="text-left">Question</th>
                                <th class="w100p text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let ql of qusList; let i = index;">
                                <td class="text-left">{{i+1}}</td>
                                <td class="text-left" [innerHTML]="ql.question"></td>
                                <td>
                                    <a class="questionItemRight icons-group"  ngbTooltip="Edit" type="button"><i
                                            class="fas fa-pencil-alt" (click)="editSelectedQues(ql)"></i></a>
    
                                    <a class="questionItemLink ItemDeleteLink" (click)="deletequestionInQb(ql._id)" ngbTooltip="Delete"
                                        type="button">
                                        <i class="fas fa-trash-alt"></i>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div> -->

                <!-- <div class="pagination-wrap mt-4 mt-md-5">
                        <a href="" class="pagination-link pagination-prev-btn"><i class="fas fa-angle-left"></i></a>
                        <a href="" class="pagination-link">1</a>
                        <a href="" class="pagination-link">2</a>
                        <a href="" class="pagination-link">3</a>
                        <a href="" class="pagination-link pagination-next-btn"><i class="fas fa-angle-right"></i></a>
                    </div> -->
            </div>
        </section>
    </div>

</div>

