import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { NotificationService } from 'src/app/services/notification.service';
import { SocketService } from 'src/app/services/web/socket.service';
import { environment } from 'src/environments/environment';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { saveAs } from 'file-saver';
import { sortByTime } from '@swimlane/ngx-charts';
@Component({
  selector: 'app-my-pr',
  templateUrl: './my-pr.component.html',
  styleUrls: ['./my-pr.component.scss']
})
export class MyPrComponent implements OnInit, OnDestroy {
  api_url = '';
  public ifOnline: boolean = true;
  @ViewChildren('scrollMe') myScrollContainer;

  public chatConvScreen1: boolean = true;
  public gchatScreen2: boolean = false;
  public gchatScreen3: boolean = false;
  public gchatScreen4: boolean = false;
  public update_group: boolean = false;
  combine_group_chat = [];
  member_is_two = false;

  user_list: any[];
  loader = false;
  message_view = '';
  room_id = '';
  send_to = '';
  user_list_info_ = [];
  chat_with_us = [];

  my_message = [];
  avatar_url = ''
  role = ''
  user_id = '';
  my_name = '';
  online_user = '';
  username = '';
  avatar_color = '';
  chat_type = 'single'
  Friends = 'Friends';
  group_list = [];
  group_lists = [];


  // images and files send using these variables
  files = [];
  all_image_event = [];
  send_files = false;
  modal_view: NgbModalRef;
  file_type;
  all_files_name = []
  resource_ids = [];
  progress = 0;
  image_url;

  constructor(private socket_service: SocketService, private ns: NotificationService, private router: Router,
    private activate_route: ActivatedRoute, private modalReview: NgbModal) {
    this.api_url = environment.APIEndpoint
  }

  show_group_name = false;


  info_group_name = '';
  info_group_description = ''
  admin_url = '';
  group_chat_name = '';
  group_room_id = '';
  openGInfo() {
    this.chatConvScreen1 = false;
    this.gchatScreen2 = true;
    this.gchatScreen3 = false;
    this.gchatScreen4 = false;
    this.info_group_name = this.group_details['name'];
    this.is_admin_true = false;
    this.info_group_description = this.group_details['group_description'].description;
  }
  createGroup() {
    this.chatConvScreen1 = false;
    this.gchatScreen2 = false;
    this.gchatScreen3 = true;
    this.gchatScreen4 = false;
    this.add_in_group = true;
    this.chat_type = 'single'
    this.Friends = 'Friends';
    this.add_user_group = [];
  }

  back() {
    this.chatConvScreen1 = true;
    this.gchatScreen2 = false;
    this.gchatScreen3 = false;
    this.gchatScreen4 = false;
    this.add_in_group = false;
    this.chat_type = 'single'
    this.Friends = 'Friends';
    this.add_user_group = [];
  }
  addMember(group_info) {
    this.chatConvScreen1 = false;
    this.add_in_group = true;
    this.update_group = true;
    this.chat_type = 'single'
    this.Friends = 'Friends';
  }
  next2Screen4() {
    if (this.add_user_group.length < 1) {
      this.ns.showNotification('info', 'Please select minimum 1 person in your group');
      return;
    }
    this.gchatScreen4 = true;
    this.gchatScreen3 = false;
    this.chatConvScreen1 = false;
    this.gchatScreen2 = false;
  }

  closeGroupScreen() {
    this.gchatScreen2 = false;
    this.chatConvScreen1 = true;
    this.gchatScreen3 = false;
    this.gchatScreen4 = false;
    this.add_user_group = [];
    this.update_group_member = [];
    this.res = [];
    this.update_group = false;
    this.add_in_group = false;
    this.chat_type = 'group';
    this.Friends = 'Groups';
  }

  closeimage_scree() {
    this.chatConvScreen1 = true;
    this.send_files = false;
    this.isShown = false;
    this.modal_view.close();
    this.all_files_name = [];
    this.all_image_event = []
    this.resource_ids = [];
    this.message_view = '';

  }

  ngOnInit() {
    this.user_list_info();
    this.chat_hostory();
    this.get_all_group();
    localStorage.removeItem('room_id');
    this.role = localStorage.getItem('currentUser');
    this.user_id = localStorage.getItem('user_id');
    this.admin_url = localStorage.getItem('avatarURL');
    this.group_chat_name = this.activate_route.snapshot.queryParamMap.get('group_name');

    if (this.group_chat_name) {
      this.chat_type = 'group'
      this.Friends = 'Groups';
    }
  }

  get_connected(pc) {
    this.socket_service.send_request(pc._id, pc.username);
    pc.request_status = 'pending';
    this.ns.showNotification('success', 'Your request has been sent successfully!');
  }

  Search_user(value) {
    if (value == '' && this.Friends != 'Groups') {
      this.user_list_info();
      return;
    }

    if ((this.Friends == 'Groups')) {
      this.group_lists = this.group_list.filter((group) => {
        return (group.name.toLowerCase().trim().indexOf(value.toLowerCase().trim()) >= 0);
      });
      return;
    } else {
      this.group_lists = [];
      this.group_lists = this.group_list;
    }

    if ((value != '' && this.Friends != 'Groups')) {
      this.ns.find_user(value).pipe(finalize(() => {
        this.loader = false;
      })).subscribe((list) => {
        this.user_list = [];
        list.find(user => {
          if (user.username != localStorage.getItem('currentUser')) {
            this.user_list.push(user);
          }
        })
      });
    }
  }

  user_list_info() {
    this.user_list = [];
    this.ns._user_list().pipe(finalize(() => {
      this.loader = false;
    })).subscribe(res => {
      this.user_list_info_ = res;
      this.user_list_info_.find(user => {
        if (user.username != this.role) {
          if (user.request_status === 'accept') {
            this.user_list.push(user);
          }

        }
      })
      this.user_list_info_.find(user => {
        if (user.username != this.role) {
          if (user.request_status == 'invites') {
            this.user_list.push(user);
          }

        }
      })

      this.user_list_info_.find(user => {
        if (user.username != this.role) {
          if (user.request_status == 'pending') {
            this.user_list.push(user);
          }

        }
      })

      this.user_list_info_.find(user => {
        if (user.username != this.role) {
          if (user.request_status == 'not sent') {
            this.user_list.push(user);
          }

        }
      })
    });
  }

  chat_hostory() {
    this.ns.chat_history().pipe(finalize(() => {
      this.loader = false;
    })).subscribe((list) => {
      this.chat_with_us = [];
      this.combine_group_chat = list;
      list.find(user => {
        if (user.username != this.role) {
          this.chat_with_us.push({
            avatar_url: user.room_members[0].avatar_url,
            color: user.room_members[0].color,
            name: user.room_members[0].name,
            username: user.room_members[0].username,
            room_id: user.room_id,
            room_status: true,
            request_status: "accept"
          })
        }
      })
    });
  }

  receive_request(pc, answer) {
    let send_by = localStorage.getItem('user_id');
    this.send_to = pc._id;

    let view_room: any[];
    this.socket_service.Accept_decline(send_by, pc._id, answer).subscribe(res => {
      view_room = res.message;
      if (answer == 'accept') {
        pc.room_status = true;
        pc.request_status = 'accept';
        this.ns.showNotification('success', "Thank you for accepting the request. Let's chat!");
      } else if (answer == 'decline') {
        pc.room_status = false;
        pc.request_status = 'not sent';
        this.ns.showNotification('success', 'No worries, Thank you!');

      }

      this.room_id = view_room['_id'];
      pc.room_id = view_room['_id'];
      this.socket_service.join_room(send_by, view_room['_id'])
      this.chat_hostory();
    },
      (err) => {

      })
  }
  unread_count = 0;
  view_room(room_id, user_details, type) {
    this.member_is_two = false;
    if (this.room_id != null || this.room_id != '') {
      this.socket_service.leave_room(this.room_id, localStorage.getItem('user_id'));
    }
    this.unread_count = user_details['total_unread_messages'];
    if (type == 'single') {
      this.group_details = [];
      this.show_group_name = false;
      this.is_admin_true = false;
      user_details['total_unread_messages'] = 0;
    }

    this.room_id = room_id;
    this.my_message = [];
    this.avatar_url = user_details.avatar_url;
    this.my_name = this.getName(user_details);
    this.avatar_color = user_details.color;
    this.username = user_details.username;
    let send_by = localStorage.getItem('user_id');
    this.socket_service.view_room(room_id).subscribe(view_room => {
      this.my_message = view_room.messages;
      localStorage.setItem('room_id', this.room_id);
      this.socket_service.join_room(send_by, room_id);
      this.group_details['total_unread_messages'] = 0;
      
      let list = document.getElementById('message');
      let length = list.childNodes.length;
      if (list != null && list.hasChildNodes()) {
        for (let i = 0; i <= length; i++) {
          list.removeChild(list.childNodes[0]);
        }
      }
      this.get_Date();
    },
      (err) => {
        this.ns.showNotification('error', err.message)
      })
  }
  group_details: any = [];
  view_room_group(room_id, group_details) {
    console.log(group_details);
    this.show_group_name = true;
    this.group_details = group_details;
    this.is_admin_true = false;
    this.back();
    this.chat_type = 'group'
    this.Friends = 'Groups';
    this.view_room(room_id, group_details, 'group');

    if (this.group_details['room_members'].length == 2) {
      this.group_details['room_members'].filter(member => {
        if (member.status == "deactivate") {
          this.member_is_two = true;
        }
      })
    }
  }


  send_message() {
    if (!this.room_id) {
      this.ns.showNotification('info', 'Please join with the person first..');
      return;
    }
    if (this.member_is_two) {
      this.ns.showNotification('info', 'This group is deactivated. Please join other group');
      return;
    }
    let send_by = localStorage.getItem('user_id')
    this.socket_service.send_message(send_by, this.room_id, this.message_view);
    this.message_view = '';
  }

  get_request_status(status) {
    if (status != 'not sent' || status != '') {
      return false;
    } else if (status == 'pending') {
      return true;
    }
    return false;
  }

  accep_decline(status) {
    if (status == 'invites') {
      return true;
    }
    return false;
  }

  date(date, type) {
    if (type == 'time') {
      return new Date(date).toLocaleTimeString(navigator.language, { hour: '2-digit', minute: '2-digit' });
    }
    if (type == 'date') {
      return new Date(date).toDateString();
    }
    // return this.ns.get_time_zone(date);
  }
  // scrolldelay;
  // scrollToBottom(): void {
  //   if (this.myScrollContainer) {
  //     this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
  //   }
  // }

  getName(pc) {
    if (pc.last_name) {
      return pc.name[0].toUpperCase() + pc.last_name[0].toUpperCase();
    }
    else {
      if (pc.name) {
        return pc.name[0].toUpperCase();
      }
      return 'U';
    }
  }

  show_name(pc) {
    if (pc.last_name) {
      return pc.name + ' ' + pc.last_name;
    }
    else {
      return pc.name;
    }
  }

  add_in_group = false;
  add_user_group = [];
  update_group_member = [];
  Add_in_group(user_info, type) {
    if (this.update_group) {
      this.update_group_member.push(user_info);
    } else {
      this.add_user_group.push(user_info);
    }
    return;
  }
  get_from_list(pc) {
    let index;
    if (this.update_group) {
      index = this.update_group_member.findIndex(user => user._id == pc._id);
      if (index > -1) {
        return index;
      }
      if (index == -1) {
        index = this.group_details['room_members'].findIndex(user => (user.user_id == pc._id) && pc.status != 'deactivate');
      }
      if (index > -1) {
        if (this.group_details['room_members'][index].status == 'deactivate') {
          index = -1;
        }
      }
    } else {
      index = this.add_user_group.findIndex(user => user._id == pc._id)
    }
    return index
  }
  group_name = '';
  create_group() {
    let member = [];
    //this.show_group_name = false;

    if (this.group_name == '') {
      this.ns.showNotification('info', 'Please add the group name');
      return;
    }
    if (this.group_name == '') {
      this.ns.showNotification('info', 'Add your group icon for your group name');
    }

    this.add_user_group.filter(user => {
      member.push(user._id);
    })
    this.loader = true;
    this.socket_service.create_group(member, this.group_name, this.s3_id)
      .pipe(finalize(() => this.loader = false))
      .subscribe(get_info => {
        this.ns.showNotification('success', 'Your group have been made successfully');
        this.get_all_group();
        this.chatConvScreen1 = true;
        this.gchatScreen2 = false;
        this.gchatScreen3 = false;
        this.gchatScreen4 = false;
        this.chat_type = 'group';
        this.Friends = 'Groups';
        this.add_in_group = false;
        this.add_user_group = [];
        this.group_icon_url = '';
        this.group_icon_s3 = '';
        this.group_name = 'Add Group Name'
        this.s3_id = ''
        //this.show_group_name = true;
      });
  }

  chat_info_type(type: string) {
    this.chat_type = type;
    this.Friends == 'Groups' ? this.Friends = 'Friends' : this.Friends = 'Groups';
  }

  get_all_group() {
    this.socket_service.get_all_group().pipe(finalize(() => {
      this.loader = false;
    })).subscribe(list => {
      this.group_list = list;
      this.group_lists = list;
      if (this.group_details) {
        this.group_lists.filter(group => {
          if (group.room_id == this.group_details['room_id']) {
            this.group_details = [];
            this.group_details = group;
          }
        })
      }

      if (this.group_chat_name) {
        this.Search_user(this.group_chat_name.toLowerCase());
        if (this.group_lists.length > 0) {
          let group_info = this.group_lists.find((group_name: any) => {
            return group_name.room_id == this.group_room_id
          });

          if (group_info) {
            this.view_room_group(group_info.room_id, group_info)
            this.router.navigate([], {
              queryParams: {
                'group_name': null,
                'group_room_id': null,
              },
              queryParamsHandling: 'merge'
            })
            this.group_chat_name = '';
            this.group_room_id = '';

          }
        }
        else {
          this.ns.showNotification('info', 'Group not found');
          return;
        }
      }
    },
      (err) => {
        console.log(err);
      })
  }
  group_icon_url = '';
  group_icon_s3 = '';
  s3_id = ''
  add_group_icon(event, type) {
    let file;
    if (event.target.files) {
      file = event.target.files[0];
    } else {
      this.ns.showNotification('info', 'No icon is added')
      return;
    }

    this.ns.upload_resource('group-photo', file).pipe(
      finalize(() => {
        // this.loader = false;
      })).subscribe(
        (response: HttpEvent<any>) => {
          switch (response.type) {
            case HttpEventType.Sent:
              break;
            case HttpEventType.ResponseHeader:
              break;
            case HttpEventType.UploadProgress:
              ;
              break;
            case HttpEventType.Response:
              this.group_icon_s3 = response.body.resource_info[0].url;
              this.group_icon_url = this.group_icon_s3;
              this.s3_id = response.body.resource_info[0].resource_id;
              this.ns.showNotification('success', 'Icon added successfully');
              if (type == 'description') {
                this.update_group_name(this.group_details, this.s3_id)
              }
          }
          setTimeout(() => {
          }, 1500);
        },
        (err) => {
          this.ns.showNotification('info', 'Please add again not able to save your file');
        })
  }


  save_group_info(value, type) {

  }

  delete_member(group_details, member) {
    if (confirm('Are you sure to remove this member from group')) {
      if (this.group_details['room_members'].length == 2) {
        this.member_is_two = true;
      }
      this.socket_service.update_group([], group_details.name, group_details.group_description.description, null, group_details.room_id, member).subscribe(update_info => {
        this.ns.showNotification('success', update_info.message);
        this.get_all_group();
      },
        (err) => {
          this.ns.showNotification('error', err.message);
        })
    }
    return
  }

  refresh() {
    this.user_list_info();
    this.chat_hostory();
    this.get_all_group();
  }

  update_group_name(group_info, url) {
    this.socket_service.update_group([], this.info_group_name, this.info_group_description, url, group_info.room_id, []).subscribe(update_info => {
      this.ns.showNotification('success', update_info.message);
      this.get_all_group();
      this.group_icon_url = '';
      this.group_icon_s3 = '';
      this.s3_id = ''
    },
      (err) => {
      })
  }

  remove_from_group(member, index) {
    if (confirm('Are you sure to remove this member from group')) {
      if (this.update_group) {
        this.update_group_member.splice(index, 1)
      }
      else {
        this.add_user_group.splice(index, 1);
      }
    }
    return;
  }

  is_admin_true = false;
  is_admin(group_detail) {
    group_detail.room_members.filter(admin => {
      if (admin.is_admin == true && this.role == admin.username) {
        this.is_admin_true = true;
      }
    })
    return this.is_admin_true;
  }
  exit_group(group_details) {
    if (confirm('Are you sure to exit this group ?')) {
      this.socket_service.exit_group(group_details.room_id).subscribe(exit_group => {
        this.ns.showNotification('success', exit_group.message);
        this.gchatScreen2 = false;
        this.gchatScreen3 = false;
        this.gchatScreen4 = false;
        this.chatConvScreen1 = false;
        this.get_all_group();
      },
        (err) => {
          this.ns.showNotification('error', err.message);
        })
    }
    return;
  }

  delete_group(group_details) {
    if (confirm('Are you sure to delete this group ?')) {
      this.socket_service.delete_group(group_details.room_id).subscribe(delete_group => {
        this.ns.showNotification('success', 'Successfully deleted the group');
        this.gchatScreen2 = false;
        this.gchatScreen3 = false;
        this.gchatScreen4 = false;
        this.chatConvScreen1 = false;
        this.get_all_group();
      },
        (err) => {
          this.ns.showNotification('error', err.message);
        })
    }
    return;
  }

  update_member(group_details) {
    let update_member = [];
    if (this.update_group_member.length == 0) {
      this.ns.showNotification('info', 'Please add some member fisrt after that update the group');
      return;
    }
    this.update_group_member.filter(user => {
      update_member.push(user._id)
    })
    this.socket_service.update_group(update_member, group_details.name, group_details.group_description.description, null, group_details.room_id, []).subscribe(get_info => {
      this.get_all_group()
      this.closeGroupScreen();
      this.ns.showNotification('success', get_info.message);
    },
      (err) => {
        this.ns.showNotification('error', err.message);
      })
  }


  all_date = [];
  get_Date() {
    this.all_date = [];
    if (this.my_message.length > 0) {
      let last_date = new Date().toDateString();
      for (let i = 0; i < this.my_message.length; i++) {
        if (new Date(this.my_message[i].created_at).toDateString() != last_date) {
          this.all_date[i] = (new Date(this.my_message[i].created_at).toDateString());
          last_date = new Date(this.my_message[i].created_at).toDateString();
        } else if (last_date == (new Date().toDateString())) {
          if (this.all_date.length == 0) {
            this.all_date[0] = new Date().toDateString();
            break;
          }
          break;
        }
      }
    }
  }


  get_count(group_details) {
    if (group_details.total_unread_messages == 0 && this.socket_service.count_update.length == 0) {
      return;
    }
    if (this.socket_service.count_update.length > 0) {
      let result = this.combine_group_chat.filter((value) => this.socket_service.count_update.some((value1) => value.room_id === value1.room_id));
      if (result.length > 0) {
        if (this.socket_service.count_update[0].type == "group") {
          let group = this.group_lists.find(group => group.room_id == result[0].room_id)
          group.total_unread_messages = this.socket_service.count_update[0].total_unread_messages;
        } else if (this.socket_service.count_update[0].type == 'single') {
          let user = this.user_list.find(user => user.room_id == result[0].room_id)
          user.total_unread_messages = this.socket_service.count_update[0].total_unread_messages;
        }
      }
      this.socket_service.count_update = [];
    }

    return group_details.total_unread_messages
  }

  ngOnDestroy() {
    localStorage.removeItem('room_id');
    this.socket_service.leave_room(this.room_id, localStorage.getItem('user_id'));
  }



  isShown: boolean = false; // hidden by default
  attachToggleShow() {
    this.isShown = !this.isShown;
  }

  get_img_url(group) {
    if (group.group_photo && group.group_photo.avatar_url) {
      return group.group_photo.avatar_url
    } else {
      return 'assets/images/svg/group-icon-circle.svg';
    }
  }
  get_msg_length(group) {
    if (group.messages && group.messages.length > 0) {
      return true;
    }
    return false;
  }

  scrollTop_height = 200
  count = 0;
  get_height(height, group_details = null) {
    if (this.my_message.length > 0) {
      let i = 0;
      for (let msg of this.my_message) {
        i++;
        if (!msg.is_read && this.user_id != msg.user_id && this.chat_type != 'group') {
          const itemToScrollTo = document.getElementById('chat_msg' + (i - 1));
          itemToScrollTo.scrollIntoView(true);
          this.count = i - 1;
          break;
        }
      }
    }
    if (this.scrollTop_height == height && this.count != 0 && this.chat_type != 'group') {
      setTimeout(() => {
        this.scrolled()
      }, 10000);

    }

    return this.scrollTop_height = height
  }

  scrolled() {
    if (this.my_message.length > 0) {
      let i = 0;
      for (let msg of this.my_message) {
        i++;
        if (!msg.is_read && this.user_id != msg.user_id) {
          msg.is_read = true;
        }
      }
      this.count = 0;
    }
  }


  openImgModal(content, img_info) {
    this.image_url = img_info;
    this.modal_view = this.modalReview.open(content, { windowClass: 'prevImgModal', centered: true });
  }

  get_message() {
    if (this.socket_service.receive_message_view && this.socket_service.receive_message_view.length > 0) {
      this.my_message.push(this.socket_service.receive_message_view[0]);
      this.socket_service.receive_message_view = [];
      this.get_Date();
    }
    return this.my_message;
  }

  openInNewWindow(viewSubmissions) {
    window.open(viewSubmissions.url);
  }

  get_path(path) {
    if (path.extension == "application/pdf") {
      return 'fas fa-file-pdf';
    } else if (path.extension == 'application/msword' || path.extension == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      return 'far fa-file-word';
    } else if (path.extension == 'application/vnd.openxmlformats-officedocument.spreadsheetml.template' || path.extension == "text/csv" || path.extension == 'application/vnd.ms-excel') {
      return 'fas fa-file-csv';
    } else {
      return 'fas fa-file-alt';
    }
  }



  onFileChange(module, event, view_image, type) {
    this.resource_ids = [];
    this.file_type = type;
    if (event.target.files && event.target.files[0]) {
      this.send_files = true;
      this.modal_view = this.modalReview.open(view_image, { windowClass: 'prevImgModal', centered: true });
      this.modalReview
      this.files = [];
      this.all_image_event = [];
      this.all_image_event = event.target.files;
      let filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (event: any) => {
          this.files.push(event.target.result);
        }
        reader.readAsDataURL(event.target.files[i]);
      }
    }
    this.send_images('chat-files');
  }

  send_images(module_name) {
    if (!this.room_id) {
      this.ns.showNotification('info', 'Please join with the person first..');
      return;
    }

    for (let i = 0; i < this.all_image_event.length; i++) {
      this.all_files_name.push(this.all_image_event[i].name)
    }
    this.upload_urls(module_name, this.all_files_name);

  }

  res = [];
  upload_urls(modelName, fileName) {
    this.ns.upload_urls(modelName, fileName).pipe(finalize(() => {
      this.loader = false;
    })).subscribe((res) => {
      this.res = res.url;
      this.upload_to_s3();
    },
      (err) => {
        this.progress = 0;
        this.ns.showNotification('info', 'Please add again not able to save your file');
      })
  }

  upload_all_images() {
    if (this.all_image_event.length != this.resource_ids.length) {
      this.ns.showNotification('info', 'Your images are under uploading');
      return;
    }
    let send_by = localStorage.getItem('user_id')
    if (this.resource_ids.length == this.all_image_event.length) {
      this.socket_service.send_message(send_by, this.room_id, this.message_view, this.resource_ids);
      this.message_view = '';
      this.send_files = false;
      this.chatConvScreen1 = true;
      this.isShown = false;
      this.resource_ids = [];
      this.all_image_event = [];
      this.all_files_name = []
      this.res = [];
      this.message_view = '';
      this.modal_view.close();
    }
  }

  upload_to_s3() {
    for (let i = 0; i < this.res.length; i++) {
      this.resource_ids.push({ 'resource_id': this.res[i].resource_id, "file_name": this.all_image_event[i].name });
      this.progress = 40;
      this.ns.upload_to_s3(this.res[i][this.all_image_event[i].name], this.all_image_event[i]).pipe(finalize(() => {
        this.progress = 0;
      })).subscribe(api => {
      });
    }
  }

  get_type(path) {
    if (path.type == "application/pdf") {
      return 'fas fa-file-pdf';
    } else if (path.type == 'application/msword' || path.type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      return 'far fa-file-word';
    } else if (path.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.template' || path.type == 'application/vnd.ms-excel') {
      return 'fas fa-file-csv';
    } else {
      return 'fas fa-file-alt';
    }
  }

  get_extention(path) {
    if (path.extention == "application/pdf") {
      return 'fas fa-file-pdf';
    } else if (path.extention == 'application/msword' || path.extention == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      return 'far fa-file-word';
    } else if (path.extention == 'application/vnd.openxmlformats-officedocument.spreadsheetml.template' || path.extention == 'application/vnd.ms-excel') {
      return 'fas fa-file-csv';
    } else {
      return 'fas fa-file-alt';
    }
  }

  download_image(url, fileName) {
    this.ns.downloadImage(url, fileName)
  }
}

