<div class="new_UIstyle">

    <!-- Banner Section Start -->
    <section class="banner banner-inner banner-answer" style="background-image: url('assets/images/banner/question-banner-02.png');">
        <div class="container">
             <div class="banner-content">
                <h2  class="white-text avenirBlack" *ngIf="questionName">{{questionName}}</h2>
                <div class="tags-badges" *ngIf="tags.length > 0">
                    <span class="badge badge-blue" *ngFor="let tag of tags;">{{tag.tag}}</span>
                </div>
                <div class="tags-badges" *ngIf="categoryList.length > 0">
                    <span class="badge badge-green-lt" *ngFor="let cate of categoryList;">{{cate}}</span>
                </div>
             </div>
        </div>
    </section> 
        <!-- Banner Section End -->
 
<section class="section my-anser-wrap">
    <div class="container">
        <div class="row">
            <div class="col-lg-10 m-auto">
                <div class="title">
                    <h5 class="avenirBlack primary-text mb-0">My Answer</h5>
                    <hr />
                </div>
                <form  [formGroup]="giveAnswerForm" (ngSubmit)="saveAnswer()">
                <div class="mt-4">                   
                        <textarea class="form-control form-control-textarea" 
                        formControlName="answer_text"
                        [ngClass]="{ 'is-invalid': isSubmitAnswer && giveAnswerForm.controls.answer_text.errors }"
                        placeholder="Write a Your answer" id="textanswer"></textarea>
                        <div *ngIf="isSubmitAnswer && giveAnswerForm.controls.answer_text.errors"
                        class="invalid-feedback">
                        <div *ngIf="giveAnswerForm.controls.answer_text.errors.required">Answer is
                            required
                        </div>
                    </div>
                </div>
                <div class="mt-4">
                    <label class="avenirBlack">Upload document to support</label>

                    <div class="dragDropWrap" [ngClass]="{submitting : !(uploadArray.length == browseFileLen)}">
                        <div class="spinner-border text-warning outer_loader_over" role="status"  *ngIf="!(uploadArray.length == browseFileLen)">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div>
                            <div class="dragDrop dragDropOr  d-none d-md-flex">
                                <figure class="dragDropIcon"><i class="fas fa-upload"></i></figure>
                                <div class="dragDropContent">
                                    <input type="file" title=" " class="file-upload-input "
                                        accept="image/*,video/*,audio/*,doc/*,application/*,text/*"
                                        (change)="onFileBrowse($event)" multiple #fileU1 />
                                    <p><strong class="blue-text d-block">Drag & Drop your file here
                                        </strong>Supports image, video, audio, pdf & text files
                                    </p>

                                    <div class="uploadedFiles">
                                        <p *ngIf="uploadedMedia.length > 0">
                                            <small *ngIf="uploadedMedia.length == 1"><b>{{selectFilelenghth}} file selected</b></small>
                                            <small *ngIf="uploadedMedia.length > 1"><b>{{selectFilelenghth}}  files selected</b></small>
                                        </p>
                                        <p *ngIf="uploadedMedia.length == 0"><small><b>No File
                                                    Selected</b></small></p>
                                    </div>
                                </div>
                                <span class="divideOr"></span>
                            </div>

                            <div class="dragDropUpload btn-groups">
                                <button type="button" (click)="fileU1.click()"
                                    class="custom-upload-btn btn btn-blue btn-shadow btn-icon-text">
                                    <span class=""><i class="fas fa-download"></i> Browse
                                        Files</span>
                                </button>
                            </div>

                        </div>

                    </div>
                    <div class="col-md-12 mt-4 uploadedItemWrap">
                        <div class="uploadedItem">
                            <div class=" mb-3" *ngFor="let media of uploadedMedia; let i = index">
                                <div class="uploadedItemTop">
                                    <div class="uploadedItemDetail">
                                        <p class="mb-1"><strong class="blue-text"> {{ media.FileName
                                                }}</strong></p>
                                        <p class="uploadedItemStatus">
                                            <small>{{media.FileProgress}}%
                                                Complete</small>
                                            <small class="dark-green-text"
                                                *ngIf="media.FileProgress === 100">Uploaded
                                                Successful</small>
                                        </p>
                                    </div>
                                    <div class="uploadedItemAction">
                                    <a href="javascript:void(0);"
                                    *ngIf="media.FileProgress === 100 && (uploadArray.length == browseFileLen)" 
                                    (click)="openModelDeleteRes(delRes)" class="uploadedActionLink"><i
                                            class="far fa-times-circle"></i></a>

                                            <ng-template #delRes let-c="close" let-d="dismiss">
                                                <div class="new_UIstyle">
                                                    <div class="modal-header justify-content-between align-items-center">
                                                        <h5 class="avenirBook modal-title text-danger mb-0">Delete Resourse</h5>
                                                        <button type="button" class="close ml-0" aria-label="Close"
                                                            (click)="closeResPopup()">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                                    <div class="modal-body">
                                                        <p>Are you sure you want to delete Resource <strong class="text-danger">?</strong></p>
                                                    </div>
                                            
                                                    <div class="modal-footer">
                                                        <div class="text-left">
                                                            <button type="submit" class="btn btn-primary btn-sm"
                                                                (click)="closeResPopup()">NO</button>
                                                        </div>
                                                        <div class="text-right">
                                                            <button type="submit" class="btn btn-primary btn-sm"
                                                            (click)="deleteResouce(media,i)">YES</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                </div>
                                </div>
                                <div class="progress uploadedProgress">
                                    <div class="progress-bar uploadedProgressBlue"
                                        role="progressbar"
                                        [ngStyle]="{ 'width.%': media.FileProgress }"></div>
                                </div>
                                <!-- <p>
                                    <small>{{ media.FileProgessSize }} of {{ media.FileSize
                                        }}</small>
                                </p> -->
                            </div>
                        </div> 
                    </div>
                <div class="btn-groups mt-4 justify-content-end">
                    <button type="button" class="btn btn-grey btn-sm" (click)="discardaskForm()" [disabled]="!(uploadArray.length == browseFileLen)">Discard</button>
                    <button type="submit" class="btn btn-primary btn-sm" [disabled]="!(uploadArray.length == browseFileLen)">Submit</button>
                </div> 
                </div>
            </form>
            </div>
        </div>
    </div>
</section>
</div>