<!-- New Code -->
<div class="new_UIstyle">
<div class="pageBodyWrap" *ngIf="!subscriberNotes">
    <!-- banner section -->
 <div class="banner banner-gradient banner-monitoring" *ngIf="!subscriberNotes">
     <div class="container">
          <div class="banner-content">
              <div class="banner-content-content">
                  <h2 class="white-text avenirBlack" *ngIf="courseNameData"><small>{{courseNameData}}:</small>  <br /> Monitoring report</h2>
              </div>
              <div class="banner-content-action">
                   <button class="btn btn-primary" (click)="goBack()"> Cancel</button>
              </div>
          </div>
     </div>
  </div>
  
<ng-container *ngIf="preGenerate">
    <!-- step 1 start-->
<section class="section monitoring-report-wrap" [ngClass]="session ? 'd-block':'d-none'">
    <div class="container">
      <ul ngbNav #monitoringNav="ngbNav" [(activeId)]="activeNav"  class="nav-tabs nav-tabs-custom monitoring-report-nav">
          <li ngbNavItem="1">
            <a ngbNavLink [ngClass]="sessionTopicWiseFlag === '0' ? 'active':''" (click)="handleClick('0')">Session</a>
            <ng-template ngbNavContent>
              <div class="sc-tabs-content-block mR-tabs-session-content">
                <div class="row">
                    <div class="col-md-7">
                            <div class=" form input-with-icon input-with-icon-right mb-3">
                                <input type="text"  [(ngModel)]="searchText" [ngModelOptions]="{standalone: true}"
                                (input)="searchSession()" type="text" class="form-control form-control-md"
                                placeholder="Search by session title"  />
                                <button class="input-with-icon-btn"><i class="fa fa-search"></i></button>
                            </div>

                            <div class="mR-session-conHeight">
                            <ng-container *ngIf="isSearchFinder">
                                <div class="learning-course-items-wrap mR-session-items-wrap">
                                    <div class="spinner-border text-warning m-auto d-table" role="status" *ngIf="searchSessionLoader">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <div class="learning-course-item" *ngFor="let searchSession of searchedSessions;let i = index">
                                        <div class="learning-course-link  border-bottom rounded-0">
                                            <div class="learning-course-elem-left">
                                                <p class="learning-course-link-title">
                                                    <strong>{{searchSession.title | titlecase}}</strong>
                                                    <small class="text-muted d-block">{{searchSession.description}}</small>
                                                    <!-- <span *ngIf="searchSession.date_time" class="d-block">{{searchSession.date_time | date:'short' }}</span> -->
                                                </p>
                                                <p class="learning-course-link-subDes d-flex flex-wrap align-items-center" *ngIf="!searchSession.live_session">
                                                    <span class=""><img src="../../../assets/images/apps-icon/video-icon.png" alt="video-icon.png" /></span><small></small></p>
                                                <p class="learning-course-link-subDes d-flex flex-wrap align-items-center" *ngIf="searchSession.live_session">
                                                    <app-live-button [liveSessionStatus]="searchSession.live_session"></app-live-button>
                                                    <small>{{searchSession.date_time | date:'short' }}</small></p>
                                            </div>
                                            <div class="learning-course-elem-right">
                                                <p>
                                                    <input [disabled]="(checkedSessions.length >= 15 && !searchSession.checked) ? true:false"
                                                                type="checkbox" class="custom-control-input"
                                                                id="searchChk-{{i}}" [(ngModel)]="searchSession.checked"
                                                                (change)="getCheckedSession(searchSession)" />
                                                    <label class="btn btn-primary btn-w-100 btn-sm" for="searchChk-{{i}}" 
                                                    [ngClass]="(checkedSessions.length >= 15 && !searchSession.checked) || (get_from_list(searchSession) > -1) ? 'disabled':''">
                                                        {{searchSession.checked ? 'Selected' : 'Select'}}
                                                    </label>
                                                </p>
                                            </div>
                                        </div>
                                     </div>
                                     <div class="alert alert-info text-center" *ngIf="!searchSessionLoader && searchedSessions.length == 0">
                                        <p>No Result Found.</p>
                                     </div>
                               </div>
                            </ng-container>

                        <ngb-accordion #mRsessionAcc="ngbAccordion" [closeOthers]="true"  activeIds="active" class="accordionCustom" *ngIf="!isSearchFinder">
                            <ngb-panel *ngFor="let topicsSession of topicsWithSessions;let i = index">
                              <ng-template ngbPanelHeader>
                                  <button ngbPanelToggle class="d-block panelHeaderButton">
                                    <span class="acc-headerTitle h6 avenirBlack d-block" >{{topicsSession.topic_title}}</span>
                                    <span class="acc-headerDes p d-block" *ngIf="topicsSession.sessions.length > 0">{{topicsSession.sessions.length}} {{topicsSession.sessions.length > 1 ? 'Sessions':'Session'}}</span>  
                                    <span class="acc-headerAngel"><i class="fas fa-angle-down"></i></span>     
                                  </button>
                              </ng-template>
                              <ng-template ngbPanelContent>  
                                  <div class="panelContentWrap">
                                    <div class="learning-course-items-wrap mR-session-items-wrap">
                                        <div class="learning-course-item" *ngFor="let searchSession of topicsSession.sessions;let ii = index">
                                       <div class="learning-course-link  border-bottom">
                                           <div class="learning-course-elem-left">
                                              <p class="learning-course-link-title">
                                                <strong>{{searchSession.title | titlecase}}</strong>
                                                <small class="text-muted d-block">{{searchSession.description}}</small>
                                                <!-- <span *ngIf="searchSession.date_time" class="d-block">{{searchSession.date_time | date:'short' }}</span> -->
                                            </p>
                                              <p class="learning-course-link-subDes d-flex flex-wrap align-items-center" *ngIf="!searchSession.live_session">
                                                <span class=""><img src="../../../assets/images/apps-icon/video-icon.png" alt="video-icon.png" /></span><small></small></p>
                                              <p class="learning-course-link-subDes d-flex flex-wrap align-items-center" *ngIf="searchSession.live_session">
                                                <app-live-button [liveSessionStatus]="searchSession.live_session"></app-live-button>
                                                <small>{{searchSession.date_time | date:'short' }}</small></p>
                                           </div>
                                           <div class="learning-course-elem-right">
                                              <p>
                                                <input [disabled]="(checkedSessions.length >= 15 && !searchSession.checked) ? true:false"
                                                            type="checkbox" class="custom-control-input"
                                                            id="searchChk-{{ii}}" [(ngModel)]="searchSession.checked"
                                                            [checked]="searchSession.checked"
                                                            [ngModelOptions]="{standalone: true}"
                                                            (change)="getCheckedSession(searchSession)" />
                                                <label class="btn btn-primary btn-w-100 btn-sm" for="searchChk-{{ii}}" 
                                                [ngClass]="(checkedSessions.length >= 15 && !searchSession.checked) || (get_from_list(searchSession) > -1) ? 'disabled':''">
                                                    {{searchSession.checked ? 'Selected' : 'Select'}}
                                                </label>
                                            </p>
                                          </div>
                                      </div>
                                         </div>
                                   </div>
                                  </div>
                              </ng-template> 
                            </ngb-panel>                              
                          </ngb-accordion>
                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="border" style="min-height: 400px;">
                            <div *ngIf="selectedSessions.length == 0" class="mt-5">
                                <!-- If no session chosen yet -->
                                <h5 class="text-center text-muted avenirBlack">No session chosen yet</h5>
                                <p class="text-center text-muted" style="line-height: 1.2;"><small>Search
                                        session from left side and <br>click the button to choose.</small>
                                </p>
                            </div>
                            <h6 class="text-center avenirBlack mt-3 pb-3 border-bottom text-muted" *ngIf="selectedSessions.length > 0">You have chosen</h6>
                            <div class="learning-course-items-wrap mR-session-items-wrap mR-sessionLeft-items-wrap">
                                <div class="learning-course-item" *ngFor="let selectedSession of selectedSessions;let idx = index">
                                    <div class="learning-course-link  border-bottom rounded-0">
                                        <div class="learning-course-elem-left">
                                            <p class="learning-course-link-title">
                                                <strong>{{selectedSession.title | titlecase}}</strong>
                                                <small class="text-muted d-block">{{selectedSession.description}}</small>
                                                <!-- <span *ngIf="searchSession.date_time" class="d-block">{{searchSession.date_time | date:'short' }}</span> -->
                                            </p>
                                            <p class="learning-course-link-subDes d-flex flex-wrap align-items-center" *ngIf="!selectedSession.live_session">
                                                <span class=""><img src="../../../assets/images/apps-icon/video-icon.png" alt="video-icon.png" /></span><small></small></p>
                                            <p class="learning-course-link-subDes d-flex flex-wrap align-items-center" *ngIf="selectedSession.live_session">
                                                <app-live-button [liveSessionStatus]="selectedSession.live_session"></app-live-button>
                                                <small>{{selectedSession.date_time | date:'short' }}</small></p>
                                        </div>
                                        <div class="learning-course-elem-right">
                                            <button type="button" (click)="removeChoosedSession(selectedSession, idx)"
                                            class="btn removeItem btn-primary btn-sm" ngbTooltip="Remove" container="body"><i
                                                class="fas fa-times-circle"></i></button>
                                        </div>
                                    </div>
                                 </div>
                           </div>
                            
                        </div>

                        <button  class="btn btn-sm btn-primary mt-4" *ngIf="checkedSessions.length > 0" (click)="choosedSessions()" >Choose Selected session</button>


                    </div>
                </div>
                    
              </div>
            </ng-template>
          </li>
          <li  ngbNavItem="2">
            <a ngbNavLink [ngClass]="sessionTopicWiseFlag === '1' ? 'active':''" (click)="handleClick('1')">Topic</a>
            <ng-template ngbNavContent>
              <div class="sc-tabs-content-block mR-tabs-Topic-content">
                  <div class="learning-course-items-wrap mR-tabs-topic-course-items" >
                    <div class="learning-course-item" *ngFor="let topicsWithSession of topicsWithSessions">
                         <div class="learning-course-link bg-grey align-items-center">
                             <div class="learning-course-elem-left">
                              <p class="learning-course-link-title h6 avenirBlack"><strong>{{topicsWithSession.topic_title}}</strong></p>
                              <div class="mt-2 mt-md-2 assessment-time-slot">
                                  <p><small class="d-inline-block" *ngIf="topicsWithSession.sessions.length > 0">{{topicsWithSession.sessions.length}} {{topicsWithSession.sessions.length > 1 ? 'Sessions':'Session' }} </small></p>
                              </div>
                             </div>
                             <div class="learning-course-elem-right">
                                 <p><a class="btn btn-primary btn-w-150 btn-sm" (click)="onSelectTopic(topicsWithSession)" [style.opacity]="topicsWithSession.topic_id == selectedDD ? 0.7 : 1">{{topicsWithSession.topic_id == selectedDD  ? 'Selected':'Select'}}</a></p>
                             </div>
                        </div>
                     </div>

                  </div>
              </div>
            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="monitoringNav"></div>
    </div>
</section>

 <!-- step 2 start-->
<section class="section genrateMonitoringReprot"  [ngClass]="personalize?'d-block':'d-none'">
    <div class="container">
       <ngb-accordion #mRsessionAcc="ngbAccordion" activeIds="active" class="accordionCustom">
           <ngb-panel id="active" >
             <ng-template ngbPanelHeader >
                 <div  class="d-block panelHeaderButton">
                   <span class="acc-headerTitle h6 avenirBlack d-block" >Personalize - <small>Choose the data you need</small></span>
                   <!-- <span class="acc-headerDes p d-block">10 Sessions</span>   -->
                   <!-- <span class="acc-headerAngel"><i class="fas fa-angle-down"></i></span>      -->
                 </div>
             </ng-template>
             <ng-template ngbPanelContent>  
                 <div class="panelContentWrap p-3">
                   <div class="row justify-content-center">
                       <div class="col-md-12 asslist form">
                           <div class="form-group mb-2 mb-md-2">
                               <div class="assessment-item">
                                   <div class="assessment-item-checkBox">
                                    <input  class="form-check-input"  type="checkbox" name="attendance" (change)="togglePersonalize()"
                                            [(ngModel)]="attendancePersonalize" />
                                   </div>
                                   <div class="assessment-item-label">
                                       <div class="col-12 h-100">
                                           <div class="row align-items-center h-100">
                                               <div class="col-xl-12 col-md-12">
                                                   <p>Attendance</p>
                                               </div>
                                           </div>
                                       </div>
                                   </div>
                               </div>
                           </div>
   
                           <div class="form-group mb-2 mb-md-2">
                               <div class="assessment-item">
                                   <div class="assessment-item-checkBox">
                                       <input class="form-check-input" type="checkbox" name="notes" (change)="togglePersonalize()"
                                       [(ngModel)]="notesPersonalize" />
                                   </div>
                                   <div class="assessment-item-label">
                                       <div class="col-12 h-100">
                                           <div class="row align-items-center h-100">
                                               <div class="col-xl-12 col-md-12">
                                                   <p>Notes</p>
                                               </div>
                                           </div>
                                       </div>
                                   </div>
                               </div>
                           </div>
   
                           <div class="form-group mb-2 mb-md-2">
                               <div class="assessment-item"  >
                                   <div class="assessment-item-checkBox" >
                                       <input  class="form-check-input" [checked]="statusCW"  type="checkbox" (click)="onSelectAllCheckBox('coursework')">
                                   </div>
                                   <div class="assessment-item-label">
                                       <div class="col-12 h-100">
                                           <div class="row align-items-center h-100">
                                               <div class="col-xl-6 col-md-12 mb-2 mb-xl-0">
                                                   <p>Coursework</p>
                                               </div>
                                               <div class="col-xl-2 col-md-4 mb-2 mb-md-0">
                                                   <div class="form-group form-check">
                                                    <input type="checkbox" class="form-check-input" id="topiclevel"
                                                     (change)="togglePersonalize()" [(ngModel)]="cwTopicLevel">
                                                       <label class="form-check-label" for="topiclevel">Topic Level</label>
                                                     </div>
                                               </div>
                                               <div class="col-xl-2 col-md-4 mb-2 mb-md-0">
                                                   <div class="form-group form-check">
                                                    <input type="checkbox" class="form-check-input" id="courselevel"
                                                    (change)="togglePersonalize()" [(ngModel)]="cwCourseLevel">
                                                       <label class="form-check-label" for="courselevel">Course Level</label>
                                                     </div>
                                               </div>
                                           </div>
                                       </div>
                                   </div>
                               </div>
                           </div>
       
                           <div class="form-group mb-2 mb-md-2">
                               <div class="assessment-item" >
                                   <div class="assessment-item-checkBox">
                                       <input class="form-check-input" [checked]="statusAsmnt" type="checkbox" (click)="onSelectAllCheckBox('assessment')" />
                                   </div>
                                   <div class="assessment-item-label">
                                       <div class="col-12 h-100">
                                           <div class="row align-items-center h-100">
                                               <div class="col-xl-6 col-md-12 mb-2 mb-xl-0">
                                                   <p>Assessments</p>
                                               </div>
                                               <div class="col-xl-2 col-md-4 mb-2 mb-lg-0">
                                                   <div class="form-group form-check">
                                                    <input type="checkbox"class="form-check-input" id="AssTopiclevel"
                                                    (change)="togglePersonalize()" [(ngModel)]="assTopicLevel" />
                                                       <label class="form-check-label" for="AssTopiclevel">Topic Level</label>
                                                     </div>
                                               </div>
                                               <div class="col-xl-2 col-md-4 mb-2 mb-lg-0">
                                                   <div class="form-group form-check">
                                                    <input type="checkbox" class="form-check-input" id="assTopiclevel"
                                                   (change)="togglePersonalize()" [(ngModel)]="assSessionLevel" />
                                                       <label class="form-check-label" for="assTopiclevel">Session Level</label>
                                                     </div>
                                               </div>
                                               <div class="col-xl-2 col-md-4 mb-2 mb-lg-0">
                                                   <div class="form-group form-check">
                                                    <input type="checkbox" class="form-check-input" id="assCourselevel"
                                                     (change)="togglePersonalize()" [(ngModel)]="assCourseLevel" />
                                                       <label class="form-check-label" for="assCourselevel">Course Level</label>
                                                     </div>
                                               </div>
                                           </div>
                                       </div>
                                   </div>
                               </div>
                           </div>

                           <!-- <div class="btn-groups justify-content-end mt-5">
                               <button type="button" class="btn btn-sm btn-primary">Generate Report</button>
                           </div> -->
                       </div>
                   </div>
                  </div>
             </ng-template> 
           </ngb-panel>
             
         </ngb-accordion>
    </div>
 </section>

 <section class="section summaryPage"  [ngClass]="summary?'d-block':'d-none'">
    <div class="container">
        <h5 class="avenirBlack">Summary <small>- The data you've chosen</small></h5>
        <div class="p-4">
            <dl class="sumStyle mb-3" *ngIf = "!selectedDD">
                <dt><label>Sessions</label></dt>
                <dd *ngIf="selectedSessions.length>0">
                    <ul>
                        <li *ngFor="let selectedSession of selectedSessions;let idx = index">
                            <div class="d-flex justify-content-between">
                                <div>{{selectedSession.title | titlecase}}</div>
                                <div>
                                    <span class="dateLbl" *ngIf="selectedSession.date_time">{{
                                        selectedSession.date_time | date:'short' }}</span>
                                </div>
                            </div>
                        </li>
                    </ul>
                </dd>
                <dd *ngIf="selectedSessions.length == 0">
                    No session chosen yet
                </dd>
            </dl>
            <dl class="sumStyle mb-3" *ngIf = "selectedDD">
                <dt><label>Topic</label></dt>
                <dd>
                    {{selectedTopic | titlecase}}
                </dd>
            </dl>
            <dl class="sumStyle mb-3">
                <dt><label>Personalize</label></dt>
                <dd *ngIf="attendancePersonalize || notesPersonalize || cwTopicLevel || cwCourseLevel ||
                assTopicLevel || assSessionLevel || assCourseLevel">
                    <ul>
                        <li *ngIf="attendancePersonalize">All available <b>Attendance</b> for selected
                            sessions.</li>
                        <li *ngIf="notesPersonalize">All available <b>Notes</b> for selected sessions.
                        </li>
                        <li *ngIf="cwTopicLevel || cwCourseLevel" class="mt-3">
                           <b>Coursework -</b> 
                            <ol>
                                <li *ngIf="cwTopicLevel">All <b>Topic Level</b> coursework.</li>
                                <li *ngIf="cwCourseLevel">All <b>Course Level</b> coursework.</li>
                            </ol>
                        </li>
                        <li *ngIf="assTopicLevel || assSessionLevel || assCourseLevel" class="mt-3">
                           <b>Assessment -</b> 
                            <ol>
                                <li *ngIf="assTopicLevel">All <b>Topic Level</b> assessment.</li>
                                <li *ngIf="assSessionLevel">All assessments for selected sessions.</li>
                                <li *ngIf="assCourseLevel">All <b>Course Level</b> assessment.</li>
                            </ol>
                        </li>
                    </ul>
                </dd>
                <dd *ngIf="!attendancePersonalize && !notesPersonalize && !cwTopicLevel && !cwCourseLevel &&
                !assTopicLevel && !assSessionLevel && !assCourseLevel">
                    No data chosen yet
                </dd>
            </dl>
        </div>
    </div>
 </section>
 <div class="text-center pt-4 pb-4">
    <button class="btn btn-primary mx-2" (click)="next1()" *ngIf="nextBtn1">
        Next <i class="fas fa-arrow-right ml-2"></i>
    </button>
    <button class="btn btn-primary mx-2" (click)="next2()" *ngIf="nextBtn2">
        Next <i class="fas fa-arrow-right ml-2"></i>
    </button>
    <button class="btn btn-primary mx-2" (click)="generateReport()" *ngIf="nextBtn3">
        Generate Report
        <i class="fas fa-arrow-right ml-2" *ngIf="!loader"></i>
        <span class="spinner-border loader-sm" role="status" *ngIf="loader">
            <span class="sr-only">Loading...</span>
        </span>
    </button>
</div>
</ng-container>

<ng-container *ngIf="postGenerate">
    <section class="section">
        <div class="container-fluid">
            <div class="form manage-table-nav mb-3">
                <div class="manage-table-nav-left">
                    <ul class="manage-table-nav-list manage-table-nav-list-checkbox">
                    <li *ngIf="monitorData?.attendance.length > 0">
                        <div class="form-check">
                            <input #attendance type="checkbox" name="attendanceMonitor" [(ngModel)]="attendanceMonitor"
                                (change)="toggleVisibility('attendance')" id="attendanceCheck" class="form-check-input" />
                            <label class="form-check-label" for="attendanceCheck">Attendance</label>
                        </div>
                    </li>
                    <li  *ngIf="monitorData?.notes.length > 0">
                        <div class="form-check">
                            <input #notes type="checkbox" name="notesMonitor" [(ngModel)]="notesMonitor"
                                (change)="toggleVisibility('notes')"  id="notesCheck" class="form-check-input" />
                                <label class="form-check-label" for="notesCheck">Notes</label>
                        </div>
                    </li>
                    <li *ngIf="monitorData?.course_work.topic_level.length > 0 || monitorData?.course_work.course_level.length > 0">
                        <div class="form-check" >
                            <input #coursework type="checkbox" name="courseworkMonitor" [(ngModel)]="courseworkMonitor"
                                (change)="toggleVisibility('coursework')" id="courseWorkCheck" class="form-check-input"  />
                                <label class="form-check-label" for="courseWorkCheck">Coursework</label>
                        </div>
                    </li>
                    <li *ngIf="monitorData?.assessment.topic_level.length > 0 ||
                        monitorData?.assessment.course_level.length > 0 || monitorData?.assessment.session_level.length > 0">
                        <div class="form-check" >
                        <input #assessment type="checkbox" name="assessmentMonitor" [(ngModel)]="assessmentMonitor"
                            (change)="toggleVisibility('assessment')"  id="assessmentCheck" class="form-check-input"   />
                            <label class="form-check-label" for="assessmentCheck">Assessment</label>
                        </div>
                    </li>
                    </ul>
                </div>
                <div class="manage-table-nav-right btn-groups">
                    <!-- <button class="btn btn-sm btn-primary" (click)="openaddEditUserModal(addEditUser)">+ Add user</button>
                    <button class="btn btn-sm btn-primary" (click)="openaddEditUserModal(bulkUpload)">+ Bulk Upload</button> -->
                    <button class="btn-transparent btn-transparent-primary manage-tableExport-btn" ngbTooltip="Edit Report" (click)="editReport()"><i
                        class="fas fa-pencil-alt mx-2" ></i></button>
                    <!-- <button class="btn-transparent btn-transparent-primary manage-tableExport-btn" ngbTooltip="Refresh Report" (click)="regenerateReport()"><i
                        class="fas fa-redo-alt mx-2" ></i></button> -->
                    <button class="btn-transparent btn-transparent-primary manage-tableExport-btn" (click)="export()" ngbTooltip="Export Report"><img class="export-icon" src="../../assets/images/apps-icon/export.png" alt="export.png" />Export</button>
                </div>
            </div> 


            <div class="monitorBoxOutFrame">
                <div class="row no-gutters" #epltable>
                    <div class="col-4">
                        <div class="innerHead">
                        <table class="table alt hdl">
                            <thead>
                                <tr>
                                    <th class="wCaret" colspan="4">&nbsp;</th>
                                </tr>
                                <tr>
                                    <th class="cw30 comp">Student Name</th>
                                    <th class="comp">Username</th>
                                    <th class="comp">Email</th>
                                        <th class="comp">Organisation</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of monitorData?.subscribers">
                                    <td>
                                        <span [ngbTooltip]="tipContentFL" tooltipClass="light"
                                            container="body">{{data?.first_name}} {{data?.last_name}}</span>
                                        <ng-template #tipContentFL>
                                            <b>{{data?.first_name}} {{data?.last_name}}</b>
                                        </ng-template>
                                    </td>
                                    <td>
                                        <span [ngbTooltip]="tipContentUN" tooltipClass="light"
                                            container="body">{{data.username}}</span>
                                        <ng-template #tipContentUN>
                                            <b>{{data.username}}</b>
                                        </ng-template>
                                    </td>
                                    <td>
                                        <span [ngbTooltip]="tipContentEM" tooltipClass="light"
                                            container="body">{{data.email}}</span>
                                        <ng-template #tipContentEM>
                                            <b>{{data.email}}</b>
                                        </ng-template>
                                    </td>
                                    <td>
                                        <span [ngbTooltip]="tipContentOg" tooltipClass="light"
                                            container="body">{{data.organisation}}</span>
                                        <ng-template #tipContentOg>
                                            <b>{{data.organisation}}</b>
                                        </ng-template>
                                    </td>
                                </tr>
                                <tr *ngIf="monitorData?.subscribers.length == 0">
                                    <td colspan="2">No Data Found</td>
                                </tr>
                            </tbody>
                        </table>
                        </div>
                    </div>
                    <div class="col-8">
                        <div class="d-flex dynMonitorArea border-left">
                            <div *ngIf="attendanceMonitor && monitorData.attendance.length > 0">
                                <div class="clmLdr" *ngIf="attendanceLoader">
                                    <div class="spinner-border text-warning" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                <table class="table alt">
                                    <thead>
                                        <tr>
                                            <th [attr.colspan]="selectedAttendance.length" class="text-center wCaret">
                                                <div ngbDropdown class="ddTitle">
                                                    <button class="tableHeadToggle" ngbDropdownToggle>Attendance</button>
                                                    <div ngbDropdownMenu *ngIf="monitorData.attendance.length > 0">
                                                        <div class="custom-control custom-checkbox">
                                                            <input class="custom-control-input" type="checkbox" value=""
                                                                name="attendancechkall"
                                                                [(ngModel)]="isAllAttendanceSelect"
                                                                id="checkValAttendance0"
                                                                (change)="onSelectAll('attendance','')">
                                                            <label class="custom-control-label"
                                                                for="checkValAttendance0">Select
                                                                All</label>
                                                        </div>
                                                        <div class="custom-control custom-checkbox"
                                                            *ngFor="let mdattendance of monitorData?.attendance;let i=index ">
                                                            <input class="custom-control-input" name="attendancechk"
                                                                type="checkbox" [(ngModel)]="mdattendance.isSelected"
                                                                [value]="mdattendance.session_title"
                                                                id="checkValAttendance{{i+1}}"
                                                                (change)="onSelect(mdattendance,'attendance','',i)">
                                                            <label class="custom-control-label"
                                                                for="checkValAttendance{{i+1}}">
                                                                <span
                                                                    [title]="mdattendance.session_title">{{mdattendance.session_title}}</span>
                                                            </label>
                                                        </div>
        
                                                    </div>
                                                    <div ngbDropdownMenu class="text-center"
                                                        *ngIf="monitorData.attendance.length == 0">
                                                        No data found
                                                    </div>
                                                </div>
        
                                            </th>
                                        </tr>
                                        <tr>
                                            <th class="text-center comp"
                                                *ngFor="let selectedAttdnc of selectedAttendance;let ii = index">
                                                <span [ngbTooltip]="tipContent1" tooltipClass="light" container="body"
                                                    placement="left">{{selectedAttdnc.session_title}}</span>
                                                <ng-template #tipContent1>
                                                    &nbsp;{{selectedAttdnc.session_title}}
                                                    <b>[{{sDate | date: 'mediumDate'}}]</b>
                                                </ng-template>
                                            </th>
        
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let data of monitorData?.subscribers">
                                            <td class="text-center dtlF"
                                                [ngClass]="displayAttendanceActiveness(data._id,selectedAttdnc.content)"
                                                *ngFor="let selectedAttdnc of selectedAttendance">
                                                &nbsp;
        
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div *ngIf="notesMonitor && monitorData.notes.length > 0">
                                <div class="clmLdr" *ngIf="notesLoader">
                                    <div class="spinner-border text-warning" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                <table class="table alt">
                                    <thead>
                                        <tr>
                                            <th [attr.colspan]="selectedNotes.length" class="text-center wCaret">
                                                <div ngbDropdown class="ddTitle">
                                                    <button class="tableHeadToggle" ngbDropdownToggle>Notes</button>
                                                    <div ngbDropdownMenu *ngIf="monitorData.notes.length > 0">
                                                        <div class="custom-control custom-checkbox">
                                                            <input class="custom-control-input" type="checkbox" value=""
                                                                name="notechkall" [(ngModel)]="isAllNotesSelect"
                                                                id="checkValNote0" (change)="onSelectAll('note','')">
                                                            <label class="custom-control-label"
                                                                for="checkValNote0">Select
                                                                All</label>
                                                        </div>
                                                        <div class="custom-control custom-checkbox"
                                                            *ngFor="let mdnote of monitorData?.notes;let i=index ">
                                                            <input class="custom-control-input" name="notechk"
                                                                type="checkbox" [(ngModel)]="mdnote.isSelected"
                                                                [value]="mdnote.session_title" id="checkValNote{{i+1}}"
                                                                (change)="onSelect(mdnote,'note','',i)">
                                                            <label class="custom-control-label"
                                                                for="checkValNote{{i+1}}">
                                                                <span
                                                                    [title]="mdnote.session_title">{{mdnote.session_title}}</span>
                                                            </label>
                                                        </div>
        
                                                    </div>
                                                    <div ngbDropdownMenu class="text-center"
                                                        *ngIf="monitorData.notes.length == 0">
                                                        No data found
                                                    </div>
                                                </div>
        
                                            </th>
                                        </tr>
                                        <tr>
                                            <th class="text-center comp"
                                                *ngFor="let selectedNote of selectedNotes;let ii = index">
                                                <span [ngbTooltip]="tipContent1" tooltipClass="light" container="body"
                                                    placement="left">{{selectedNote.session_title}}</span>
                                                <ng-template #tipContent1>
                                                    &nbsp;{{selectedNote.session_title}}
                                                    <b>[{{sDate | date: 'mediumDate'}}]</b>
                                                </ng-template>
                                            </th>
        
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let data of monitorData?.subscribers">
                                            <td class="text-center dtlF"
                                                [ngClass]="displayNoteActiveness(data._id,selectedNote.content)"
                                                *ngFor="let selectedNote of selectedNotes">
                                                &nbsp;
                                                <a *ngIf="displayNoteActiveness(data._id,selectedNote.content) == 'green'"
                                                    (click)="redirect(selectedNote.session_id,selectedNote.session_title,data._id)"
                                                    container="body">
                                                    <i class="fas fa-file-alt"></i>
                                                </a>
                                            </td>
        
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div *ngIf="courseworkMonitor">
                                <div class="clmLdr" *ngIf="courseWorkLoader">
                                    <div class="spinner-border text-warning" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                <table class="table alt">
                                    <thead>
                                        <tr>
                                            <th [attr.colspan]="getCWColSpan()" class="text-center wCaret comp">
                                                Coursework
                                            </th>
                                        </tr>
                                        <tr>
                                            <ng-container *ngIf="cwTopicLevel">
                                                <th [attr.colspan]="selectedTopicLevelCW.length"
                                                    class="text-center wCaret comp border-right">
                                                    <div ngbDropdown class="ddTitle">
                                                        <button class="tableHeadToggle" ngbDropdownToggle>Topic</button>
                                                        <div ngbDropdownMenu
                                                            *ngIf="monitorData.course_work.topic_level.length>0">
                                                            <div class="custom-control custom-checkbox">
                                                                <input class="custom-control-input" type="checkbox"
                                                                    value="" name="cwtlchkall"
                                                                    [(ngModel)]="isAllTopicCWSelected"
                                                                    id="checkValTLCW0"
                                                                    (change)="onSelectAll('coursework','cwTopicLevel')">
                                                                <label class="custom-control-label"
                                                                    for="checkValTLCW0">Select
                                                                    All</label>
                                                            </div>
                                                            <div class="custom-control custom-checkbox"
                                                                *ngFor="let mdCoursework of monitorData.course_work.topic_level;let i=index ">
                                                                <input class="custom-control-input" name="cwchk"
                                                                    type="checkbox" [value]="mdCoursework.title"
                                                                    id="checkValTLCW{{i+1}}"
                                                                    [(ngModel)]="mdCoursework.isSelected"
                                                                    (change)="onSelect(mdCoursework,'coursework','cwTopicLevel',i)">
                                                                <label class="custom-control-label"
                                                                    for="checkValTLCW{{i+1}}">
                                                                    <span [title]="mdCoursework.title">CWT{{i+1}}</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div ngbDropdownMenu class="text-center"
                                                            *ngIf="monitorData.course_work.topic_level.length == 0">
                                                            No data found
                                                        </div>
                                                    </div>
                                                </th>
                                            </ng-container>
                                            <ng-container *ngIf="cwCourseLevel">
                                                <th [attr.colspan]="selectedCourseWork.length"
                                                    class="text-center wCaret comp">
                                                    <div ngbDropdown class="ddTitle">
                                                        <button class="tableHeadToggle" ngbDropdownToggle>Course</button>
                                                        <div ngbDropdownMenu
                                                            *ngIf="monitorData.course_work.course_level.length>0">
                                                            <div class="custom-control custom-checkbox">
                                                                <input class="custom-control-input" type="checkbox"
                                                                    value="" name="cwchkall"
                                                                    [(ngModel)]="isAllCWSelected" id="checkValCW0"
                                                                    (change)="onSelectAll('coursework','cwCourseLevel')">
                                                                <label class="custom-control-label"
                                                                    for="checkValCW0">Select
                                                                    All</label>
                                                            </div>
                                                            <div class="custom-control custom-checkbox"
                                                                *ngFor="let mdCoursework of monitorData.course_work.course_level;let i=index ">
                                                                <input class="custom-control-input" name="cwchk"
                                                                    type="checkbox" [value]="mdCoursework.title"
                                                                    id="checkValCW{{i+1}}"
                                                                    [(ngModel)]="mdCoursework.isSelected"
                                                                    (change)="onSelect(mdCoursework,'coursework','cwCourseLevel',i)">
                                                                <label class="custom-control-label"
                                                                    for="checkValCW{{i+1}}">
                                                                    <span [title]="mdCoursework.title">CWC{{i+1}}</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div ngbDropdownMenu class="text-center"
                                                            *ngIf="monitorData.course_work.course_level.length == 0">
                                                            No data found
                                                        </div>
                                                    </div>
                                                </th>
                                            </ng-container>
                                        </tr>
                                        <tr>
                                            <ng-container *ngIf="cwTopicLevel && selectedTopicLevelCW.length>0">
                                                <th class="text-center comp"
                                                    *ngFor="let selectedCW of selectedTopicLevelCW;let i = index">
                                                    <span [ngbTooltip]="tipContent1" tooltipClass="light"
                                                        container="body" placement="left">CWT
                                                        &nbsp;{{selectedCW.serial}}</span>
                                                    <ng-template #tipContent1>
                                                        <b>{{selectedCW.title | titlecase}}</b>
                                                    </ng-template>
                                                </th>
                                            </ng-container>
                                            <ng-container *ngIf="cwTopicLevel && selectedTopicLevelCW.length == 0">
                                                <th class="text-center comp">&nbsp;</th>
                                            </ng-container>
                                            <ng-container *ngIf="cwCourseLevel && selectedCourseWork.length>0">
                                                <th class="text-center comp"
                                                    *ngFor="let selectedCW of selectedCourseWork;let i = index">
                                                    <span [ngbTooltip]="tipContent1" tooltipClass="light"
                                                        container="body" placement="left">CWC
                                                        &nbsp;{{selectedCW.serial}}</span>
                                                    <ng-template #tipContent1>
                                                        <b>{{selectedCW.title | titlecase}}</b>
                                                    </ng-template>
                                                </th>
                                            </ng-container>
                                            <ng-container *ngIf="cwCourseLevel && selectedCourseWork.length == 0">
                                                <th class="text-center comp">&nbsp;</th>
                                            </ng-container>
                                        </tr>
        
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let data of monitorData?.subscribers">
                                            <ng-container *ngIf="cwTopicLevel && selectedTopicLevelCW.length>0">
                                                <td class="text-center"  [ngbTooltip]="tipContentCW" tooltipClass="light"
                                                container="body"   *ngFor="let selectedCW of selectedTopicLevelCW"
                                                    [ngClass]="displayCWActiveness(data._id,selectedCW.content)">
                                                    <span [ngClass]="display_color(data._id,selectedCW.content)">
                                                        &nbsp;{{displayCWGrade(data._id,selectedCW.content)}}</span>
                                                    <ng-template #tipContentCW>
                                                        <b>Grade (Marked/Submitted/Total Submissions)</b>
                                                        <br/>
                                                        <b *ngIf="display_color(data._id,selectedCW.content) == 'text_color'"> {{late_days(data._id,selectedCW.content)}} </b>
                                                    </ng-template>
                                                </td>
                                            </ng-container>
                                            <ng-container *ngIf="cwTopicLevel && selectedTopicLevelCW.length == 0">
                                                <td class="text-center red">&nbsp;</td>
                                            </ng-container>
                                            <ng-container *ngIf="cwCourseLevel && selectedCourseWork.length>0">
                                                <td class="text-center" [ngbTooltip]="tipContentCW" tooltipClass="light"
                                                container="body" placement="left"  *ngFor="let selectedCW of selectedCourseWork"
                                                    [ngClass]="displayCWActiveness(data._id,selectedCW.content)">
                                                    <span  [ngClass]="display_color(data._id,selectedCW.content)">
                                                        &nbsp;{{displayCWGrade(data._id,selectedCW.content)}}</span>
                                                    <ng-template #tipContentCW>
                                                        <b>Grade (Marked/Submitted/Total Submissions)</b>
                                                        <br/>
                                                        <b *ngIf="display_color(data._id,selectedCW.content) == 'text_color'"> {{late_days(data._id,selectedCW.content)}} </b>
                                                    </ng-template>
                                                </td>
                                            </ng-container>
                                            <ng-container *ngIf="cwCourseLevel && selectedCourseWork.length == 0">
                                                <td class="text-center red">&nbsp;</td>
                                            </ng-container>
                                        </tr>
        
                                    </tbody>
                                </table>
                            </div>
                            <div *ngIf="assessmentMonitor">
                                <div class="clmLdr" *ngIf="assessmentLoader">
                                    <div class="spinner-border text-warning" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                <table class="table alt">
                                    <thead>
                                        <tr>
                                            <th [attr.colspan]="getAssessmentColSpan()" class="text-center wCaret comp">
                                                Assessment
                                            </th>
                                        </tr>
                                        <tr>
                                            <ng-container *ngIf="assTopicLevel">
                                                <th [attr.colspan]="selectedTLAsmnt.length"
                                                    class="text-center wCaret comp border-right">
                                                    <div ngbDropdown class="ddTitle">
                                                        <button class="tableHeadToggle" ngbDropdownToggle>Topic</button>
                                                        <div ngbDropdownMenu
                                                            *ngIf="monitorData.assessment.topic_level.length>0">
                                                            <div class="custom-control custom-checkbox">
                                                                <input class="custom-control-input" type="checkbox"
                                                                    value="" name="asmnttlchkall"
                                                                    [(ngModel)]="isAllTLAsmntSelected"
                                                                    id="checkValAssT0"
                                                                    (change)="onSelectAll('assessment','assTopicLevel')">
                                                                <label class="custom-control-label"
                                                                    for="checkValAssT0">Select
                                                                    All</label>
                                                            </div>
                                                            <div class="custom-control custom-checkbox"
                                                                *ngFor="let asmnt of monitorData.assessment.topic_level;let ii=index ">
                                                                <input class="custom-control-input" name="asmntchk"
                                                                    type="checkbox" [value]="asmnt.title"
                                                                    id="checkValAssT{{ii+1}}"
                                                                    [(ngModel)]="asmnt.isSelected"
                                                                    (change)="onSelect(asmnt,'assessment','assTopicLevel',ii)">
                                                                <label class="custom-control-label"
                                                                    for="checkValAssT{{ii+1}}">
                                                                    <span [title]="asmnt.title">AT{{ii+1}}</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div ngbDropdownMenu class="text-center"
                                                            *ngIf="monitorData.assessment.topic_level.length==0">
                                                            No data found
                                                        </div>
                                                    </div>
                                                </th>
                                            </ng-container>
                                            <ng-container *ngIf="assSessionLevel">
                                                <th [attr.colspan]="selectedSLAsmnt.length"
                                                    class="text-center wCaret comp border-right">
                                                    <div ngbDropdown class="ddTitle ">
                                                        <button class="tableHeadToggle" ngbDropdownToggle>Session</button>
                                                        <div ngbDropdownMenu
                                                            *ngIf="monitorData.assessment.session_level.length>0">
                                                            <div class="custom-control custom-checkbox">
                                                                <input class="custom-control-input" type="checkbox"
                                                                    value="" name="asmnttlchkall"
                                                                    [(ngModel)]="isAllSLAsmntSelected"
                                                                    id="checkValAssS0"
                                                                    (change)="onSelectAll('assessment','assSessionLevel')">
                                                                <label class="custom-control-label"
                                                                    for="checkValAssS0">Select
                                                                    All</label>
                                                            </div>
                                                            <div class="custom-control custom-checkbox"
                                                                *ngFor="let asmnt of monitorData.assessment.session_level;let i=index ">
                                                                <input class="custom-control-input" name="asmntchk"
                                                                    type="checkbox" [value]="asmnt.title"
                                                                    id="checkValAssS{{i+1}}"
                                                                    [(ngModel)]="asmnt.isSelected"
                                                                    (change)="onSelect(asmnt,'assessment','assSessionLevel',i)">
                                                                <label class="custom-control-label"
                                                                    for="checkValAssS{{i+1}}">
                                                                    <span [title]="asmnt.title">AS{{i+1}}</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div ngbDropdownMenu class="text-center"
                                                            *ngIf="monitorData.assessment.session_level.length==0">
                                                            No data found
                                                        </div>
                                                    </div>
                                                </th>
                                            </ng-container>
                                            <ng-container *ngIf="assCourseLevel">
                                                <th [attr.colspan]="selectedCLAsmnt.length"
                                                    class="text-center wCaret comp">
                                                    <div ngbDropdown class="ddTitle">
                                                        <button class="tableHeadToggle" ngbDropdownToggle>Course</button>
                                                        <div ngbDropdownMenu
                                                            *ngIf="monitorData.assessment.course_level.length>0">
                                                            <div class="custom-control custom-checkbox">
                                                                <input class="custom-control-input" type="checkbox"
                                                                    value="" name="asmnttlchkall"
                                                                    [(ngModel)]="isAllCLAsmntSelected"
                                                                    id="checkValAssC0"
                                                                    (change)="onSelectAll('assessment','assCourseLevel')">
                                                                <label class="custom-control-label"
                                                                    for="checkValAssC0">Select
                                                                    All</label>
                                                            </div>
                                                            <div class="custom-control custom-checkbox"
                                                                *ngFor="let asmnt of monitorData.assessment.course_level;let i=index ">
                                                                <input class="custom-control-input" name="asmntchk"
                                                                    type="checkbox" [value]="asmnt.title"
                                                                    id="checkValAssC{{i+1}}"
                                                                    [(ngModel)]="asmnt.isSelected"
                                                                    (change)="onSelect(asmnt,'assessment','assCourseLevel',i)">
                                                                <label class="custom-control-label"
                                                                    for="checkValAssC{{i+1}}">
                                                                    <span [title]="asmnt.title">AC{{i+1}}</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div ngbDropdownMenu class="text-center"
                                                            *ngIf="monitorData.assessment.course_level.length==0">
                                                            No data found
                                                        </div>
                                                    </div>
                                                </th>
                                            </ng-container>
                                        </tr>
                                        <tr>
                                            <ng-container *ngIf="assTopicLevel && selectedTLAsmnt.length>0">
                                                <th class="text-center comp"
                                                    *ngFor="let selectedTL of selectedTLAsmnt;let i = index" [ngbTooltip]="tipContent1" tooltipClass="light"
                                                    container="body" placement="left">
                                                    <span>AT
                                                        {{selectedTL.serial}}</span>
                                                    <ng-template #tipContent1>
                                                        <b>{{selectedTL.title | titlecase}}</b>
                                                    </ng-template>
                                                </th>
                                            </ng-container>
                                            <ng-container *ngIf="assTopicLevel && selectedTLAsmnt.length == 0">
                                                <th class="text-center comp">&nbsp;</th>
                                            </ng-container>
        
                                            <ng-container *ngIf="assSessionLevel && selectedSLAsmnt.length>0">
                                                <th class="text-center comp"
                                                    *ngFor="let selectedSL of selectedSLAsmnt;let i = index" [ngbTooltip]="tipContent1" tooltipClass="light"
                                                    container="body" placement="left">
                                                    <span>AS
                                                        {{selectedSL.serial}}</span>
                                                    <ng-template #tipContent1>
                                                        <b>{{selectedSL.title | titlecase}}</b>
                                                    </ng-template>
                                                </th>
                                            </ng-container>
                                            <ng-container *ngIf="assSessionLevel && selectedSLAsmnt.length == 0">
                                                <th class="text-center comp">&nbsp;</th>
                                            </ng-container>
        
                                            <ng-container *ngIf="assCourseLevel && selectedCLAsmnt.length>0">
                                                <th class="text-center comp"
                                                    *ngFor="let selectedCL of selectedCLAsmnt;let i = index"  [ngbTooltip]="tipContent1" tooltipClass="light"
                                                    container="body" placement="left">
                                                    <span>AC
                                                        {{selectedCL.serial}}</span>
                                                    <ng-template #tipContent1>
                                                        <b>{{selectedCL.title | titlecase}}</b>
                                                    </ng-template>
                                                </th>
                                            </ng-container>
                                            <ng-container *ngIf="assCourseLevel && selectedCLAsmnt.length == 0">
                                                <th class="text-center comp">&nbsp;</th>
                                            </ng-container>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let data of monitorData?.subscribers">
                                            <ng-container *ngIf="assTopicLevel && selectedTLAsmnt.length>0">
                                                <td class="text-center" *ngFor="let tlAsmnt of selectedTLAsmnt"
                                                    [ngClass]="displayAssessmentActiveness(data._id,tlAsmnt.content)"  [ngbTooltip]="tipContentASTL" tooltipClass="light"
                                                    container="body">
                                                    <span [ngClass]="display_color(data._id,tlAsmnt.content)">
                                                        &nbsp;{{displayASMNTGrade(data._id,tlAsmnt.content)}}</span>
                                                    <ng-template #tipContentASTL>
                                                        <b>Grade</b>
                                                        <br/>
                                                        <b *ngIf="display_color(data._id,tlAsmnt.content) == 'text_color'"> {{late_days(data._id,tlAsmnt.content)}} </b>
                                                    </ng-template>
                                                </td>
                                            </ng-container>
                                            <ng-container *ngIf="assTopicLevel && selectedTLAsmnt.length == 0">
                                                <td class="text-center red">&nbsp;</td>
                                            </ng-container>
        
                                            <ng-container *ngIf="assSessionLevel && selectedSLAsmnt.length>0">
                                                <td class="text-center" *ngFor="let slAsmnt of selectedSLAsmnt"
                                                    [ngClass]="displayAssessmentActiveness(data._id,slAsmnt.content)" [ngbTooltip]="tipContentASSL" tooltipClass="light"
                                                    container="body">
                                                    <span  [ngClass]="display_color(data._id,slAsmnt.content)">
                                                        &nbsp;{{displayASMNTGrade(data._id,slAsmnt.content)}}</span>
                                                    <ng-template #tipContentASSL>
                                                        <b>Grade</b>
                                                        <br/>
                                                        <b *ngIf="display_color(data._id,slAsmnt.content) == 'text_color'"> {{late_days(data._id,slAsmnt.content)}} </b>
                                                    </ng-template>
                                                </td>
                                            </ng-container>
                                            <ng-container *ngIf="assSessionLevel && selectedSLAsmnt.length == 0">
                                                <td class="text-center red">&nbsp;</td>
                                            </ng-container>
        
                                            <ng-container *ngIf="assCourseLevel && selectedCLAsmnt.length>0">
                                                <td class="text-center" *ngFor="let clAsmnt of selectedCLAsmnt"
                                                    [ngClass]="displayAssessmentActiveness(data._id,clAsmnt.content)" [ngbTooltip]="tipContentASCL" tooltipClass="light"
                                                    container="body" >
                                                    <span [ngClass]="display_color(data._id,clAsmnt.content)">
                                                        &nbsp;{{displayASMNTGrade(data._id,clAsmnt.content)}}</span>
                                                    <ng-template #tipContentASCL>
                                                        <b>Grade</b>
                                                        <br/>
                                                        <b *ngIf="display_color(data._id,clAsmnt.content) == 'text_color'"> {{late_days(data._id,clAsmnt.content)}} </b>
                                                    </ng-template>
                                                </td>
                                            </ng-container>
                                            <ng-container *ngIf="assCourseLevel && selectedCLAsmnt.length == 0">
                                                <td class="text-center red">&nbsp;</td>
                                            </ng-container>
                                        </tr>
        
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</ng-container>

 </div>
</div>
<app-subscribers-notes-list *ngIf="subscriberNotes" (sub_notes)="isSubNotes($event)" [sessionId]='sessionId'
    [courseId]='courseId' [sessionTitle]='sessionTitle' [subscriberId]="subscriberId"></app-subscribers-notes-list>


    <!-- Custom Alert Box For monitoring report -->
<div class="modalCustom" *ngIf="isSubscriberLarge">
    <div class="new_UIstyle">
    <div class="mBoxInner">
        <button type="button" class="close ml-0" aria-label="Close" (click)="sendCourseDetails(courseId, courseName)">
            <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="mb-4">Alert!</h4>
        <p>{{monitorDataMessage}}</p>

        <div class="text-center pt-2" >
            <button type="button" (click)="sendCourseDetails(courseId, courseName)" class="btn btn-primary btn-sm ml-3">Ok</button>
        </div>
    </div>
    </div>
</div>
