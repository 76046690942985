import { Component, OnInit } from '@angular/core';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { QuestionService } from '../services/question.service';
import { answers, StudentAnswers, allCategory } from '../model/course.model';
import { finalize } from 'rxjs/operators';
import { NgbRatingConfig } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from '../services/notification.service';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { CourseService } from '../services/course.service';

@Component({
  selector: 'app-my-content',
  templateUrl: './my-content.component.html',
  styleUrls: ['./my-content.component.scss'],
  providers: [NgbModalConfig, NgbModal]
})
export class MyContentComponent implements OnInit {
  myAnswer: answers[];
  path: string;
  comments = [];
  likes = [];
  rating: number;
  videoTitle: string;
  showBtns = false;

  Rating: number;
  answer_Id = '';
  currentUsername = '';

  loader = false;;

  isLiked: boolean;
  isDisLiked: boolean;
  ContentTitle = '';
  pageUrl = '';
  studentAnswer: StudentAnswers[];

  subjectOfQuestion: allCategory[]

  constructor(
    config: NgbModalConfig,
    private modalService: NgbModal,
    private questionService: QuestionService,
    rateConfig: NgbRatingConfig,
    private router: Router,
    private ns: NotificationService,
    private courseService: CourseService) {

    config.backdrop = 'static';
    config.keyboard = false;
    rateConfig.readonly = true;
  }

  open(content, file_path, Feedback, Liked, Rating, title, answers_id) {
    this.modalService.open(content);
    this.path = file_path;

    this.likes = Liked,
      this.rating = Rating;
    this.videoTitle = title;
    this.answer_Id = answers_id;
    this.getAnswerMetaData();
  }

  showCmntsBtn() {
    this.showBtns = true;
  }
  cancelFeedBack(ngForm: NgForm) {
    ngForm.reset();
    this.showBtns = false;
  }

  ngOnInit() {
    this.pageUrl = this.router.url;
    this.currentUsername = localStorage.getItem("currentUser");

    const title = this.pageUrl.split('/');
    this.ContentTitle = title[1];
    if (this.ContentTitle == "my-content") {
      this.ContentTitle = "My Content";
      // this.getMyAnswer();
    } else if (this.ContentTitle == "Student-Answer") {
      this.ContentTitle = "Student Answer";
       this.getAllCourses();
    }
  }
  selectedValue = 'select';
  getMyAnswer() {
    this.loader = true;
    this.questionService.getMyAnswer().pipe(finalize(() => {
      this.loader = false;
    })).subscribe(ans => {
      this.myAnswer = ans.my_content
      if (this.ContentTitle == "Student Answer") {
        this.onChangeSubject(this.selectedValue);
      }
    })
  }

  sendAnswerFeedback(form: NgForm) {
    this.loader = true;
    this.questionService.answerFeedbackApi(this.answer_Id, form.value).pipe(finalize(() => { this.loader = false })).subscribe(answerFeedback => {
      this.ns.showNotification("success", "Comment added successfully");
      this.comments.unshift({ username: localStorage.getItem('currentUser'), Feedback: form.value.feedbackAnswer, Timestamp: "0 minutes" })
      form.reset();
    })
  }

  getAnswerMetaData() {
    this.loader = true;
    this.questionService.answerMetaData(this.answer_Id).pipe(finalize(() => { this.loader = false })).subscribe(answerMetaData => {
      this.comments = answerMetaData.answers[0].Feedback;
      this.isLiked = answerMetaData.answers[0].Liked;
      this.isDisLiked = answerMetaData.answers[0].Disliked;
    })
  }

  onChangeSubject(value) {
    if (value == 'select') {
      this.studentAnswer = [];
      return;
    }
    const body = {
      course_category_id: value,
      role: localStorage.getItem('role'),
    }
    this.questionService.getStudentAnswerList(body).pipe(finalize(() => {
      this.loader = false;
    })).subscribe(res => {
      this.studentAnswer = res.answers;
      this.ns.showNotification('success', res.message);
    },
      (err) => {
        this.ns.showNotification('info', err.message);
      })
  }

  getAllCourses() {
    this.loader = true;
    this.courseService.categoryAPI().pipe(finalize(() => {
      this.loader = false;
    })).subscribe(data => {
      this.subjectOfQuestion = data;
    });


  }

  //FOR BUILD FIX
  myquestions: any = [];

}