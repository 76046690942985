import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileUploadModule } from 'primeng/fileupload';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TagInputModule } from 'ngx-chips';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { CommonAppModuleModule } from '../common-app-module/common-app-module.module';
import { SearchResourcesComponent } from './search-resources/search-resources.component';
import { AddResourcesComponent } from './add-resources/add-resources.component';
import { NoFoundComponent } from './not-found/no-found.component';
import { ResourceDetailComponent } from './resource-detail/resource-detail.component';
import { splitTitleFile } from '../courses/pipe/split-title-file.pipe';
import { splitTitleA } from './pipe/split-titleA.pipe';

const routes: Routes = [
    {
        path: '',
        children: [
            {
                path: '',
                component: SearchResourcesComponent
            },
            {
                path: 'resources',
                component: AddResourcesComponent
            },
            {
                path: 'resources-details',
                component: ResourceDetailComponent
            },
            {
                path: '**',
                redirectTo: '/resource-bank',
                pathMatch: 'full'
            },
          
        ]
    }
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        FormsModule,
        ReactiveFormsModule,
        FileUploadModule,
        NgSelectModule,
        NgbModule,
        TagInputModule,
        Ng2SearchPipeModule,
        CommonAppModuleModule,
        
    ],
    declarations: [
        SearchResourcesComponent,
        AddResourcesComponent,
       NoFoundComponent,
       ResourceDetailComponent,
       splitTitleA
    ],
    exports: [
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        FileUploadModule,
        NgbModule,
        NgSelectModule,
        Ng2SearchPipeModule,
        SearchResourcesComponent,
        AddResourcesComponent,
        ResourceDetailComponent
    ],
})
export class ResourceBankModule { }
