<div class="banner">
    <div class="container text-center">
        <h1>{{componentName}}</h1>
    </div>
</div>

<div class="pageBody">
    <div class="container courseNorms cstAcc">
        <form class="mw680 pt-0 px-2 mb-0" id="addCourseNew" [ngClass]="{submitting : loader}"
            [formGroup]="addCourseForm" (ngSubmit)="formSubmit('unpublish')">
            <div class="spinner-border text-warning formLoader" role="status" *ngIf="loader">
                <span class="sr-only">Loading...</span>
            </div>


            <div class="row mb-3">
                <label class="col-12 col-md-3 pb-2 pb-md-0 m-0">Course Type : </label>
                <div class="col-12 col-md-9">
                    <select class="custom-select"
                    [ngClass]="courseClassDisabled ? 'disabled': ''"
                        [ngClass]="{ 'is-invalid': submitAddCourse && addCourseForm.controls.course_class.errors }"
                        formControlName="course_class">
                        <option selected value="">Select Course Type</option>
                        <option [value]="courseclassdata._id" *ngFor="let courseclassdata of listOfCoursesnewdata" >{{courseclassdata.value}}
                        </option>
                    </select>
                    <div *ngIf="submitAddCourse && addCourseForm.controls.course_class.errors"
                        class="invalid-feedback">
                        <div *ngIf="addCourseForm.controls.course_class.errors.required"> Course is required
                        </div>
                    </div>
                </div>

            </div>

            <div class="row mb-3">
                <label class="col-12 col-md-3 pb-2 pb-md-0 m-0">Category:</label>
                <div class="col-12 col-md-9">
                    <select class="custom-select"
                        [ngClass]="{ 'is-invalid': submitAddCourse && addCourseForm.controls.course_category_id.errors }"
                        formControlName="course_category_id">
                        <option selected value="">Select Category</option>
                        <option [value]="option._id" *ngFor="let option of listOfCourses">{{option.Course_Category}}
                        </option>
                    </select>
                    <div *ngIf="submitAddCourse && addCourseForm.controls.course_category_id.errors"
                        class="invalid-feedback">
                        <div *ngIf="addCourseForm.controls.course_category_id.errors.required"> Category is required
                        </div>
                    </div>
                </div>

            </div>
            <div class="row mb-3">
                <label class="col-12 col-md-3 pb-2 pb-md-0 m-0">Title:</label>
                <div class="col-12 col-md-9">
                    <input type="text" class="form-control"
                        [value]="addCourseForm.get('subject').value | capitalization"
                        [ngClass]="{ 'is-invalid': submitAddCourse && addCourseForm.controls.subject.errors }"
                        formControlName="subject">
                    <div *ngIf="submitAddCourse && addCourseForm.controls.subject.errors" class="invalid-feedback">
                        <div *ngIf="addCourseForm.controls.subject.errors.required"> subject is required</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-12 col-md-3 pb-2 pb-md-0 m-0">Learning Objectives:</label>
                <div class="col-12 col-md-9">
                    <textarea class="form-control"
                        [value]="addCourseForm.get('learning_objectives').value | capitalization" id="about_course"
                        rows="7" cols="10"></textarea>
                    <div *ngIf="submitAddCourse && addCourseForm.controls.learning_objectives.errors"
                        class="invalid-feedback">
                        <div *ngIf="addCourseForm.controls.learning_objectives.errors.required"> learning objectives is
                            required
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mb-3">
                <label class="col-12 col-md-3 pb-2 pb-md-0 m-0">Upload Image:</label>
                <div class="col-12 col-md-9">
                    <div class="progress form-group" style="height: 8px;"
                        *ngIf="progress > 0 && (upload_type == 'bannerImage')">
                        <div class="progress-bar progress-bar-striped bg-success" role="progressbar"
                            [style.width.%]="progress">
                        </div>
                    </div>
                    <div class="custom-file">
                        <input type="file" class="custom-file-input" accept="image/*"
                            [ngClass]="{ 'is-invalid': submitAddCourse && addCourse.banner_img.errors }"
                            (change)="detectFiles($event, 'bannerImage', null, null, null, null)">
                        <label class="custom-file-label" for="upCourseImagecourse">{{ fileName }}</label>
                        <div *ngIf="submitAddCourse && addCourse.banner_img.errors" class="invalid-feedback">
                            <div *ngIf="addCourse.banner_img.errors.required"> Banner Image is required</div>
                        </div>
                    </div>
                </div>
            </div>
            <div formArrayName="live_sessions">
                <div class="row mb-3">
                    <label class="col-12 col-md-3 pb-2 pb-md-0 m-0">Live Session(s):</label>
                    <div class="col-12 col-md-9">
                        <div class=" dynRowWrap">
                            <div class="row loop live_session"
                                *ngFor="let liveSession of addCourseForm.controls.live_sessions['controls']; let live_SessionArr=index"
                                [formGroupName]="live_SessionArr">
                                <!-- // For Loop Starts here // -->
                                
                                <div class="col-12 col-md-12 mb-2">
                                    <input type="text" class="form-control" placeholder="Session Title"   
                                        [ngClass]="{ 'is-invalid': submitAddCourse && liveSession.controls.liveSession_title.errors}"
                                        formControlName="liveSession_title">
                                </div>

                                <div class="col-3 mb-2 cld">
                                    <input type="text" class="form-control" placeholder="Start Date" name="startDp"
                                        ngbDatepicker #dStart="ngbDatepicker" formControlName="start_date"
                                        [ngClass]="{ 'is-invalid': submitAddCourse && liveSession.controls.start_date.errors }">
                                    <button class="btn calendar" (click)="dStart.toggle()" type="button"><i
                                            class="far fa-calendar-alt"></i></button>
                                    <div *ngIf="submitAddCourse && liveSession.controls.start_date.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="liveSession.controls.start_date.errors.required"> Start Date
                                            is
                                            required</div>
                                    </div>
                                </div>

                                <div class="col-3 mb-2 cld">
                                    <input type="text" class="form-control" placeholder="End Date" name="endDp"
                                        ngbDatepicker #dEnd="ngbDatepicker"
                                        [ngClass]="{ 'is-invalid': submitAddCourse && liveSession.controls.end_date.errors }"
                                        formControlName="end_date">
                                    <button class="btn calendar" (click)="dEnd.toggle()" type="button"><i
                                            class="far fa-calendar-alt"></i></button>
                                    <div *ngIf="submitAddCourse && liveSession.controls.end_date.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="liveSession.controls.end_date.errors.required"> End Date is
                                            required
                                        </div>
                                    </div>
                                </div>

                                <div class="col-3 mb-2">
                                    <input type="time" class="form-control text-center" placeholder="Time"
                                        [ngClass]="{ 'is-invalid': submitAddCourse && liveSession.controls.start_time.errors }"
                                        formControlName="start_time">
                                    <div *ngIf="submitAddCourse && liveSession.controls.start_time.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="liveSession.controls.start_time.errors.required"> Start Time
                                            is
                                            required</div>
                                    </div>
                                </div>

                                <div class="col-3 mb-2">
                                    <select class="custom-select" formControlName="frequency"
                                        [ngClass]="{ 'is-invalid': submitAddCourse && liveSession.controls.frequency.errors }">
                                        <option value="" disabled>Frequency</option>
                                        <option value="daily">Daily</option>
                                        <option value="weekly">Weekly</option>
                                    </select>
                                    <div *ngIf="submitAddCourse && liveSession.controls.frequency.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="liveSession.controls.frequency.errors.required"> Frequency
                                            of Live
                                            session is
                                            required</div>
                                    </div>
                                </div>

                                <div class="col-3 mb-2">
                                    <input type="text" (input)="onlyNumber(liveSession.controls['duration'])"
                                        class="form-control" placeholder="Duration (mins)" formControlName="duration"
                                        [ngClass]="{ 'is-invalid': submitAddCourse && liveSession.controls.duration.errors }">
                                    <div *ngIf="submitAddCourse && liveSession.controls.duration.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="liveSession.controls.duration.errors.required"> Duration of
                                            Live
                                            session is
                                            required</div>
                                    </div>
                                </div>

                                <div class="col-9 mb-1">
                                    <div class="btn-group btnWDD" ngbDropdown role="group"
                                        *ngIf="liveSession.controls.confFld.value != 'cfldZoom' && liveSession.controls.confFld.value != 'cfldCustom'">
                                        <button type="button" class="btn btn-secondary">Add video conferencing
                                            URL</button>
                                        <div class="btn-group" role="group">
                                            <button id="btnGroupDrop1" type="button" class="btn btn-secondary"
                                                ngbDropdownToggle></button>
                                            <div class="dropdown-menu" ngbDropdownMenu aria-labelledby="btnGroupDrop1">
                                                <div *ngIf="zoom_accounts.length > 0">
                                                    <button type="button" *ngFor="let zoom_account of zoom_accounts"
                                                        ngbDropdownItem
                                                        (click)="setValueLS('cfldZoom', live_SessionArr, zoom_account, zoom_user_id)">
                                                        {{zoom_account.email}} Wants to use Zoom account
                                                    </button>
                                                </div>
                                                <div *ngIf="zoom_accounts.length == 0">
                                                    <button type="button" ngbDropdownItem (click)="openZoom()">Add zoom
                                                        Credentials
                                                    </button>
                                                </div>
                                                <button type="button" ngbDropdownItem
                                                    (click)="setValueLS('cfldCustom', live_SessionArr, '', '')">
                                                    Custom <span>(use your own url)</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="zoomFlds" *ngIf="liveSession.controls.confFld.value == 'cfldZoom'">
                                        <input type="text" class="form-control" readonly
                                            value="zoom meeting (Auto-generated URL)">
                                        <button type="button" class="btn bxClose"
                                            (click)="resetConfURL('Zoom', live_SessionArr)">
                                            <i class="fas fa-times-circle"></i></button>
                                    </div>
                                    <div class="customUrlFlds"
                                        *ngIf="liveSession.controls.confFld.value == 'cfldCustom'">
                                        <input type="text" class="form-control" placeholder="URL" formControlName="url"
                                            [ngClass]="{ 'is-invalid': submitAddCourse && liveSession.controls.url.errors }">
                                        <button type="button" class="btn bxClose"
                                            (click)="resetConfURL('Custom', live_SessionArr)">
                                            <i class="fas fa-times-circle"></i></button>
                                        <div *ngIf="submitAddCourse && liveSession.controls.url.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="liveSession.controls.url.errors.required"> URL is
                                                required
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <dl class="row align-items-center mb-0" *ngIf="zoom_is_added(live_SessionArr)">
                                    <dd class="col col-8 pt-0">
                                        <div class="custom-control custom-checkbox d-inline-block">
                                            <input type="checkbox" class="custom-control-input"
                                                [checked]="waiting_room(live_SessionArr)"
                                                [attr.disabled]="check_read_only(live_SessionArr)"
                                                (change)="save_pass_wait_room('waiting_room', live_SessionArr, $event)"
                                                [id]="'waiting_room'+live_SessionArr">
                                            <label class="custom-control-label"
                                                [for]="'waiting_room'+live_SessionArr"><small>Waiting
                                                    Room</small></label>
                                        </div>
                                    </dd>
                                    <dd class="col col-4">
                                        <div class="row align-items-center">
                                            <div class="custom-control custom-checkbox d-inline-block">
                                                <input type="checkbox" class="custom-control-input"
                                                    [id]="'passcode'+live_SessionArr"
                                                    [checked]="is_passcode(live_SessionArr)"
                                                    [attr.disabled]="check_read_only(live_SessionArr)"
                                                    (change)="save_pass_wait_room('passcode', live_SessionArr, $event)">
                                                <label class="custom-control-label"
                                                    [for]="'passcode'+live_SessionArr"><small>Passcode</small></label>
                                            </div>
                                        </div>
                                    </dd>
                                </dl>

                                <div class="col-12 removeBtnHldr text-right">
                                    <button type="button" class="btn btn-link"
                                        (click)="removeArray('live_sessions', live_SessionArr, '', '', 'manual')">Remove</button>
                                </div>
                            </div> <!-- // End: For Loop // -->
                            <button type="button" class="addBtn btn btn-link"
                                (click)="addLiveSession('click')">Add</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-12 col-md-3 pb-2 pb-md-0 m-0">Author:</label>
                <div class="col-12 col-md-9">
                    <!-- <input type="text" class="form-control" formControlName="author"> -->
                    <tag-input [formControlName]="'authors'" [addOnBlur]="true" [clearOnBlur]="true"
                        [secondaryPlaceholder]="'Enter authors'" [displayBy]="'name'" [identifyBy]="'name'">
                    </tag-input>
                </div>
            </div>

            <div class="row mb-3">
                <label class="col-12 col-md-3 pb-2 pb-md-0 m-0">Accessible to:</label>
                <div class="col-12 col-md-9">
                    <tag-input [formControlName]="'organisations'" [addOnBlur]="true" [clearOnBlur]="true"
                        [secondaryPlaceholder]="'Enter Organisations'" [displayBy]="'name'"
                        [ngClass]="{ 'is-invalid': submitAddCourse && addCourseForm.controls.organisations.errors }"
                        [identifyBy]="'_id'">
                        <tag-input-dropdown [autocompleteItems]="organisationList" [displayBy]="'name'"
                            [identifyBy]="'_id'">
                        </tag-input-dropdown>
                    </tag-input>
                    <div *ngIf="submitAddCourse && addCourseForm.controls.organisations.errors"
                        class="invalid-feedback">
                        <div *ngIf="addCourseForm.controls.organisations.errors.required"> organisation is
                            required
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mb-3">
                <label class="col-12 col-md-3 pb-2 pb-md-0 m-0">Instructor:</label>
                <div class="col-12 col-md-9">
                    <tag-input [formControlName]="'instructors'" [addOnBlur]="true" [clearOnBlur]="true"
                        [secondaryPlaceholder]="'Enter instructors'"
                        [ngClass]="{ 'is-invalid': submitAddCourse && addCourseForm.controls.instructors.errors }"
                        [displayBy]="'name'" [identifyBy]="'_id'" (input)="teachersList($event, 'teacher', '')">

                        <tag-input-dropdown [autocompleteItems]="teachers" [displayBy]="'username'"
                            [identifyBy]="'_id'">
                        </tag-input-dropdown>
                    </tag-input>
                    <div *ngIf="submitAddCourse && addCourseForm.controls.instructors.errors" class="invalid-feedback">
                        <div *ngIf="addCourseForm.controls.instructors.errors.required"> Instructor is required
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-12 col-md-3 pb-2 pb-md-0 m-0">Editors:</label>
                <div class="col-12 col-md-9">
                    <tag-input [formControlName]="'editors'" [addOnBlur]="true" [clearOnBlur]="true"
                        [secondaryPlaceholder]="'Enter editors'" [displayBy]="'name'" [identifyBy]="'_id'"
                        (input)="teachersList($event, 'teacher', '')">
                        <tag-input-dropdown [autocompleteItems]="teachers" [displayBy]="'username'"
                            [identifyBy]="'_id'">
                        </tag-input-dropdown>
                    </tag-input>
                </div>
            </div>

            <div class="row mb-3">
                <label class="col-12 col-md-3 pb-2 pb-md-0 m-0">Teaching Assistant:</label>
                <div class="col-12 col-md-9">
                    <!-- <input type="text" class="form-control" formControlName="author"> -->
                    <tag-input [formControlName]="'teach_assis'" [addOnBlur]="true" [clearOnBlur]="true"
                        [secondaryPlaceholder]="'Enter Teaching Assistant'"
                        [ngClass]="{ 'is-invalid': submitAddCourse && addCourseForm.controls.teach_assis.errors }"
                        [displayBy]="'username'" [identifyBy]="'_id'" (input)="teachersList($event, '', 'teach_assis')">
                        <tag-input-dropdown [autocompleteItems]="teachers" [displayBy]="'username'"
                            [identifyBy]="'_id'">
                        </tag-input-dropdown>
                    </tag-input>

                    <div *ngIf="submitAddCourse && addCourse.teach_assis.errors" class="invalid-feedback">
                        <div *ngIf="addCourse.teach_assis.errors.required">Teaching Assistant is required</div>
                    </div>
                </div>
            </div>
            <mat-accordion formArrayName="topics">
                <mat-expansion-panel *ngFor="let topic of addCourseForm.controls.topics['controls']; let topicArr=index"
                    [formGroupName]="topicArr">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Topic {{topicArr+1}}
                        </mat-panel-title>
                        <button type="button" class="btn bxClose"
                            (click)="removeArray('topics', topicArr, '', '', 'manual')">
                            <i class="fas fa-times-circle"></i></button>
                    </mat-expansion-panel-header>
                    <div>
                        <div class="sectBoxNorms">
                            <!-- // Topic Box // -->
                            <div class="bxTitle">
                                <div class="bxTitle">
                                    <input type="text" class="secTtleInput" formControlName="title"
                                        [value]="topic.get('title').value | capitalization"
                                        [ngClass]="{ 'is-invalid': submitAddCourse && topic.controls.title.errors }">
                                    <div *ngIf="submitAddCourse && topic.controls.title.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="topic.controls.title.errors.required"> Topic Title is
                                            required
                                        </div>
                                    </div>
                                </div>
                                <ng-container>
                                    <div class="bxBody">
                                        <div class="row mb-3">
                                            <label class="col-12 col-md-3 pb-2 pb-md-0 m-0">Topic
                                                Description:</label>
                                            <div class="col-12 col-md-9">
                                                <textarea class="form-control"
                                                    [value]="topic.get('description').value | capitalization"
                                                    [ngClass]="{ 'is-invalid': submitAddCourse && topic.controls.description.errors }"
                                                    formControlName="description" rows="4" cols="10"></textarea>
                                                <div *ngIf="submitAddCourse && topic.controls.description.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="topic.controls.description.errors.required">
                                                        Topic
                                                        description
                                                        is required</div>
                                                </div>
                                            </div>
                                        </div>

                                        <mat-accordion formArrayName="sessions">
                                            <mat-expansion-panel
                                                *ngFor="let topic_session_info of topic.controls.sessions['controls']; let topic_sessionArr=index"
                                                [formGroupName]="topic_sessionArr">
                                                <mat-expansion-panel-header>
                                                    <mat-panel-title>
                                                        Session
                                                        {{topic_sessionArr+1}}
                                                    </mat-panel-title>
                                                    <button type="button" class="btn bxClose"
                                                        (click)="removeArray('sessions', topicArr, topic_sessionArr, '', 'manual')">
                                                        <i class="fas fa-times-circle"></i></button>
                                                </mat-expansion-panel-header>
                                                <div>
                                                    <div class="whiteBx">
                                                        <!-- White Layer Box For Session -->
                                                        <div class="bxTitle minHe8">
                                                            <input type="text" class="secTtleInput"
                                                                [value]="topic_session_info.get('title').value | capitalization"
                                                                [ngClass]="{ 'is-invalid': submitAddCourse && topic_session_info.controls.title.errors }"
                                                                formControlName="title">
                                                            <div *ngIf="submitAddCourse && topic_session_info.controls.title.errors"
                                                                class="invalid-feedback">
                                                                <div
                                                                    *ngIf="topic_session_info.controls.title.errors.required">
                                                                    Session
                                                                    title
                                                                    is required</div>
                                                            </div>
                                                            <ng-container>
                                                                <div class="px-3 py-2">
                                                                    <div class="row mb-3">
                                                                        <label
                                                                            class="col-12 col-md-3 pb-2 pb-md-0 m-0">Description:</label>
                                                                        <div class="col-12 col-md-9">
                                                                            <textarea class="form-control"
                                                                                [value]="topic_session_info.get('description').value | capitalization"
                                                                                [ngClass]="{ 'is-invalid': submitAddCourse && topic_session_info.controls.description.errors }"
                                                                                formControlName="description" rows="4"
                                                                                cols="10"></textarea>
                                                                            <div *ngIf="submitAddCourse && topic_session_info.controls.description.errors"
                                                                                class="invalid-feedback">
                                                                                <div
                                                                                    *ngIf="topic_session_info.controls.description.errors.required">
                                                                                    Session
                                                                                    description
                                                                                    is required
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <!-- {{print(topic_session_info)}} -->
                                                                    <div class="row mb-3"
                                                                        *ngIf="topic_session_info.controls.key.value == 'session' || topic_session_info.controls.key.value == 'Recorded_video'">
                                                                        <label
                                                                            class="col-12 col-md-3 pb-2 pb-md-0 m-0 pt0">
                                                                            <span
                                                                                *ngIf="topic_session_info.controls.key.value == 'session'">Enter
                                                                                Video
                                                                                URL:or</span>
                                                                            <br><small>
                                                                                Add
                                                                                File:</small>
                                                                        </label>
                                                                        <div class="col-12 col-md-9">
                                                                            <div class="progress form-group"
                                                                                style="height: 8px;"
                                                                                *ngIf="progress > 0 && (upload_index == topic_sessionArr && upload_type == 'session_url')">
                                                                                <div class="progress-bar progress-bar-striped bg-success"
                                                                                    role="progressbar"
                                                                                    [style.width.%]="progress">
                                                                                </div>
                                                                            </div>
                                                                            <div class="inputNBrowse">
                                                                                <mat-form-field
                                                                                    class="example-full-width">
                                                                                    <input type="text"
                                                                                        class="form-control"
                                                                                        [value]="get_res_val('session_url',topic_session_info.controls.file_id.value,topicArr, topic_sessionArr)"
                                                                                        (focus)="setInd('session_url', topicArr, topic_sessionArr)"
                                                                                        matInput
                                                                                        [id]="'session_url'+topicArr+topic_sessionArr"
                                                                                        aria-label="session_url"
                                                                                        [matAutocomplete]="auto"
                                                                                        #inputField
                                                                                        [ngClass]="{ 'is-invalid': submitAddCourse && topic_session_info.controls.title.errors }">
                                                                                    <mat-autocomplete
                                                                                        #auto="matAutocomplete">
                                                                                        <mat-option
                                                                                            *ngFor="let option of results"
                                                                                            [value]="option.title"
                                                                                            (onSelectionChange)="searchData(option, topicArr, 'session_url',topic_sessionArr)">
                                                                                            {{ option.title }}
                                                                                            <span
                                                                                                *ngIf="option.id == ''">(Add
                                                                                                URL)</span>
                                                                                        </mat-option>
                                                                                    </mat-autocomplete>
                                                                                </mat-form-field>

                                                                                <!-- <input type="text" class="form-control"
                                                                                    formControlName="url"
                                                                                    [placeholder]="topic_session_info.controls.file_id.value"
                                                                                    (change)="saveType('session_url', topicArr, topic_sessionArr, null)"
                                                                                    [readOnly]="topic_session_info.controls.key.value == 'Recorded_video' || topic_session_info.controls.key.value == 'session'"
                                                                                    [ngClass]="{ 'is-invalid': submitAddCourse && (topic_session_info.controls.url.value == '' && topic_session_info.controls.file_id.value == '') }">
                                                                                <div class="custom-file fltdBrowse">
                                                                                    <input type="file"
                                                                                        class="custom-file-input"
                                                                                        accept="video/*"
                                                                                        [id]="'upCourseImagesession'+topic_sessionArr"
                                                                                        (change)="detectFiles($event, 'session_url', topicArr, topic_sessionArr, null, topic_session_info)">
                                                                                    <label class="custom-file-label"
                                                                                        for="'upCourseImagesession'+topic_sessionArr"></label>
                                                                                </div> -->
                                                                                <div *ngIf="submitAddCourse && (topic_session_info.controls.url.value == '' && topic_session_info.controls.file_id.value == '')"
                                                                                    class="invalid-feedback">
                                                                                    <div
                                                                                        *ngIf="(topic_session_info.controls.url.value == '' && topic_session_info.controls.file_id.value == '')">
                                                                                        Session
                                                                                        URL
                                                                                        is
                                                                                        required
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <p class="text-right">
                                                                        <button type="button"
                                                                            class="btn p-0 btn-link nonAbs"
                                                                            (click)="openAssessmentforSession(contentAsmntAtSesseion, topicArr, topic_sessionArr, topic_session_info.controls.key.value)">+
                                                                            Add
                                                                            Assessment</button>
                                                                    </p>
                                                                    <p class="text-right"
                                                                        *ngIf="topic_session_info.controls.key.value != 'session' && topic_session_info.controls.url.value != ''">
                                                                        <button type="button"
                                                                            class="btn p-0 btn-link nonAbs"
                                                                            (click)="add_recording(topicArr, topic_sessionArr, topic_session_info)">
                                                                            <span
                                                                                *ngIf="!['session', 'Recorded_video'].includes(topic_session_info.controls.key.value)">+
                                                                                Add Recorded
                                                                                Video</span>
                                                                            <span
                                                                                *ngIf="['session', 'Recorded_video'].includes(topic_session_info.controls.key.value)">-
                                                                                Remove Recorded
                                                                                Video</span></button>
                                                                    </p>
                                                                    <mat-accordion cdkDropList
                                                                        #courseTopicSessionResource="cdkDropList"
                                                                        [cdkDropListData]="topic_session_info.controls.resources['controls']"
                                                                        (cdkDropListDropped)="drop($event)"
                                                                        formArrayName="resources">
                                                                        <mat-expansion-panel
                                                                            *ngFor="let session_resource_info of topic_session_info.controls.resources['controls']; let session_resourceArr=index"
                                                                            [formGroupName]="session_resourceArr"
                                                                            cdkDrag>
                                                                            <mat-expansion-panel-header>
                                                                                <mat-panel-title>
                                                                                    Resource
                                                                                    {{session_resourceArr+1}}
                                                                                </mat-panel-title>
                                                                                <button type="button"
                                                                                    class="btn bxClose"
                                                                                    (click)="removeArray('resources', topicArr, topic_sessionArr, session_resourceArr, 'manual')">
                                                                                    <i
                                                                                        class="fas fa-times-circle"></i></button>
                                                                            </mat-expansion-panel-header>
                                                                            <div>
                                                                                <div class="whiteBx">
                                                                                    <!-- White Layer Box For Resource -->
                                                                                    <div class="bxTitle minHe8">
                                                                                        <input type="text"
                                                                                            class="secTtleInput"
                                                                                            formControlName="title"
                                                                                            [value]="session_resource_info.get('title').value | capitalization"
                                                                                            [ngClass]="{ 'is-invalid': submitAddCourse && session_resource_info.controls.title.errors }">
                                                                                        <div *ngIf="submitAddCourse && session_resource_info.controls.title.errors"
                                                                                            class="invalid-feedback">
                                                                                            <div
                                                                                                *ngIf="session_resource_info.controls.title.errors.required">
                                                                                                Resource
                                                                                                title
                                                                                                is
                                                                                                required
                                                                                            </div>
                                                                                        </div>
                                                                                        <ng-container>
                                                                                            <div class="p-3">
                                                                                                <div class="row mb-3">
                                                                                                    <label
                                                                                                        class="col-12 col-md-3 pb-2 pb-md-0 m-0 pt0">
                                                                                                        Enter
                                                                                                        URL:
                                                                                                        <br><small>or
                                                                                                            Add
                                                                                                            File:</small>
                                                                                                    </label>
                                                                                                    <div
                                                                                                        class="col-12 col-md-9">
                                                                                                        <div class="progress form-group"
                                                                                                            style="height: 8px;"
                                                                                                            *ngIf="progress > 0 && (upload_index == session_resourceArr && upload_type == 'session_resource')">
                                                                                                            <div class="progress-bar progress-bar-striped bg-success"
                                                                                                                role="progressbar"
                                                                                                                [style.width.%]="progress">
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            class="inputNBrowse">
                                                                                                            <mat-form-field
                                                                                                                class="example-full-width">
                                                                                                                <input
                                                                                                                    type="text"
                                                                                                                    class="form-control"
                                                                                                                    [value]="get_res_val('session_resource',session_resource_info.controls._id.value,topicArr, topic_sessionArr,session_resourceArr)"
                                                                                                                    (focus)="setInd('session_resource', topicArr, topic_sessionArr, session_resourceArr)"
                                                                                                                    matInput
                                                                                                                    [id]="'session_resource'+topicArr+topic_sessionArr+session_resourceArr"
                                                                                                                    aria-label="session_resource"
                                                                                                                    [matAutocomplete]="auto"
                                                                                                                    #inputField
                                                                                                                    [ngClass]="{ 'is-invalid': submitAddCourse && session_resource_info.controls.title.errors }">
                                                                                                                <mat-autocomplete
                                                                                                                    #auto="matAutocomplete">
                                                                                                                    <mat-option
                                                                                                                        *ngFor="let option of results"
                                                                                                                        [value]="option.title"
                                                                                                                        (onSelectionChange)="searchData(option, topicArr, 'session_resource',topic_sessionArr, session_resourceArr)">
                                                                                                                        {{
                                                                                                                        option.title
                                                                                                                        }}
                                                                                                                        <span
                                                                                                                            *ngIf="option.id == ''">(Add
                                                                                                                            URL)</span>
                                                                                                                    </mat-option>
                                                                                                                </mat-autocomplete>
                                                                                                            </mat-form-field>

                                                                                                            <!-- <input
                                                                                                                type="text"
                                                                                                                class="form-control"
                                                                                                                [placeholder]="session_resource_info.controls._id.value"
                                                                                                                (change)="saveType('session_resource', topicArr, topic_sessionArr,session_resourceArr)"
                                                                                                                [ngClass]="{ 'is-invalid': submitAddCourse && (session_resource_info.controls.url.value == '' && session_resource_info.controls._id.value == '') }"
                                                                                                                formControlName="url"> -->
                                                                                                            <!-- <div
                                                                                                                class="custom-file fltdBrowse">
                                                                                                                <input
                                                                                                                    type="file"
                                                                                                                    class="custom-file-input"
                                                                                                                    [id]="'upCourseImageresarr'+session_resourceArr"
                                                                                                                    (change)="detectFiles($event, 'session_resource', topicArr, topic_sessionArr,session_resourceArr, null )">
                                                                                                                <label
                                                                                                                    class="custom-file-label"
                                                                                                                    [for]="'upCourseImageresarr'+session_resourceArr"></label>
                                                                                                            </div> -->
                                                                                                            <div *ngIf="submitAddCourse && (session_resource_info.controls.url.value == '' && session_resource_info.controls._id.value == '')"
                                                                                                                class="invalid-feedback">
                                                                                                                <div
                                                                                                                    *ngIf="(session_resource_info.controls.url.value == '' && session_resource_info.controls._id.value == '')">
                                                                                                                    Resource
                                                                                                                    URL
                                                                                                                    is
                                                                                                                    required
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </ng-container>
                                                                                    </div>
                                                                                </div>
                                                                                <!-- End: \ White Layer Box For Resource -->
                                                                            </div>
                                                                        </mat-expansion-panel>
                                                                    </mat-accordion>

                                                                    <div class="addContBAR">
                                                                        <!-- // Section for Adding Elements // -->
                                                                        <div class="barGrp d-inline-flex flex-row">
                                                                            <div class="lbl">Add
                                                                            </div>
                                                                            <div>
                                                                                <select class="custom-select"
                                                                                    (change)="addArray($event, 'underSession', topicArr, topic_sessionArr)">
                                                                                    <option value="select">
                                                                                        select
                                                                                    </option>
                                                                                    <option value="resource">
                                                                                        Resource
                                                                                    </option>
                                                                                </select>
                                                                            </div>

                                                                            <div>
                                                                                <select class="custom-select"
                                                                                    (change)="indexValue($event.target.value)">
                                                                                    <option value="1"
                                                                                        [selected]='!checkState'>
                                                                                        1
                                                                                    </option>
                                                                                    <option value="2">
                                                                                        2
                                                                                    </option>
                                                                                    <option value="3">
                                                                                        3
                                                                                    </option>
                                                                                    <option value="4">
                                                                                        4
                                                                                    </option>
                                                                                    <option value="5">
                                                                                        5
                                                                                    </option>
                                                                                    <option value="6">
                                                                                        6
                                                                                    </option>
                                                                                    <option value="7">
                                                                                        7
                                                                                    </option>
                                                                                    <option value="8">
                                                                                        8
                                                                                    </option>
                                                                                    <option value="9">
                                                                                        9
                                                                                    </option>
                                                                                    <option value="10">
                                                                                        10
                                                                                    </option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                    <!-- End: \ White Layer Box For Session -->
                                                </div>
                                            </mat-expansion-panel>
                                        </mat-accordion>
                                        <mat-accordion cdkDropList #courseTopicResource="cdkDropList"
                                            [cdkDropListData]="topic.controls.resources['controls']"
                                            (cdkDropListDropped)="drop($event)" formArrayName="resources">
                                            <mat-expansion-panel
                                                *ngFor="let topic_resource_info of topic.controls.resources['controls']; let topic_resourceArr=index"
                                                [formGroupName]="topic_resourceArr" cdkDrag>
                                                <mat-expansion-panel-header>
                                                    <mat-panel-title>
                                                        Resource {{topic_resourceArr+1}}
                                                    </mat-panel-title>
                                                    <button type="button" class="btn bxClose"
                                                        (click)="removeArray('topic_resource', topicArr, topic_resourceArr, '', 'manual')">
                                                        <i class="fas fa-times-circle"></i></button>
                                                </mat-expansion-panel-header>
                                                <div>
                                                    <div class="whiteBx">
                                                        <!-- White Layer Box For Resource -->
                                                        <div class="bxTitle minHe8">
                                                            <input type="text" class="secTtleInput"
                                                                [ngClass]="{ 'is-invalid': submitAddCourse && topic_resource_info.controls.title.errors }"
                                                                formControlName="title"
                                                                [value]="topic_resource_info.get('title').value | capitalization">
                                                            <div *ngIf="submitAddCourse && topic_resource_info.controls.title.errors"
                                                                class="invalid-feedback">
                                                                <div
                                                                    *ngIf="topic_resource_info.controls.title.errors.required">
                                                                    Resource
                                                                    title
                                                                    is required</div>
                                                            </div>
                                                            <ng-container>
                                                                <div class="p-3">
                                                                    <div class="row mb-3">
                                                                        <label
                                                                            class="col-12 col-md-3 pb-2 pb-md-0 m-0 pt0">
                                                                            Enter URL:
                                                                            <br><small>or Add
                                                                                File:</small>
                                                                        </label>
                                                                        <div class="col-12 col-md-9">
                                                                            <div class="progress form-group"
                                                                                style="height: 8px;"
                                                                                *ngIf="progress > 0 && (upload_index == topic_resourceArr && upload_type == 'topic_resource')">
                                                                                <div class="progress-bar progress-bar-striped bg-success"
                                                                                    role="progressbar"
                                                                                    [style.width.%]="progress">
                                                                                </div>
                                                                            </div>
                                                                            <div class="inputNBrowse">
                                                                                <mat-form-field
                                                                                    class="example-full-width">
                                                                                    <input type="text"
                                                                                        class="form-control"
                                                                                        [value]="get_res_val('topic_resource', topic_resource_info.controls._id.value,topicArr, topic_resourceArr)"
                                                                                        (focus)="setInd('topic_resource', topicArr, topic_resourceArr)"
                                                                                        matInput
                                                                                        [id]="'topic_resource'+topicArr+topic_resourceArr"
                                                                                        aria-label="topic_resource"
                                                                                        [matAutocomplete]="auto"
                                                                                        #inputField
                                                                                        [ngClass]="{ 'is-invalid': submitAddCourse && topic_resource_info.controls.title.errors }">
                                                                                    <mat-autocomplete
                                                                                        #auto="matAutocomplete">
                                                                                        <mat-option
                                                                                            *ngFor="let option of results"
                                                                                            [value]="option.title"
                                                                                            (onSelectionChange)="searchData(option, topicArr, 'topic_resource', topic_resourceArr)">
                                                                                            {{ option.title }}
                                                                                            <span
                                                                                                *ngIf="option.id == ''">(Add
                                                                                                URL)</span>
                                                                                        </mat-option>
                                                                                    </mat-autocomplete>
                                                                                </mat-form-field>

                                                                                <!-- <input type="text" class="form-control"
                                                                                   
                                                                                    (change)="saveType('topic_resource', topicArr, topic_resourceArr, null)"
                                                                                    [ngClass]="{ 'is-invalid': submitAddCourse && (topic_resource_info.controls.url.value == '' && topic_resource_info.controls._id.value == '') }"
                                                                                    formControlName="url"> -->
                                                                                <!-- <div class="custom-file fltdBrowse">
                                                                                    <input type="file"
                                                                                        class="custom-file-input"
                                                                                        [id]="'upCourseImage'+topic_resourceArr"
                                                                                        (change)="detectFiles($event, 'topic_resource', topicArr, topic_resourceArr, null, null)">
                                                                                    <label class="custom-file-label"
                                                                                        [for]="'upCourseImage'+topic_resourceArr"></label>
                                                                                </div> -->
                                                                                <div *ngIf="submitAddCourse && (topic_resource_info.controls.url.value == '' && topic_resource_info.controls._id.value == '')"
                                                                                    class="invalid-feedback">
                                                                                    <div
                                                                                        *ngIf="(topic_resource_info.controls.url.value == '' && topic_resource_info.controls._id.value == '')">
                                                                                        Resource
                                                                                        URL
                                                                                        is
                                                                                        required
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                    <!-- End: \ White Layer Box For Resource -->
                                                </div>
                                            </mat-expansion-panel>
                                        </mat-accordion>
                                        <mat-accordion cdkDropList #courseTopicCoursework="cdkDropList"
                                            [cdkDropListData]="topic.controls.course_work['controls']"
                                            (cdkDropListDropped)="drop($event)" formArrayName="course_work">
                                            <mat-expansion-panel
                                                *ngFor="let topic_course_work_info of topic.controls.course_work['controls']; let topic_course_work_infoArr=index"
                                                [formGroupName]="topic_course_work_infoArr" cdkDrag>
                                                <mat-expansion-panel-header>
                                                    <mat-panel-title>
                                                        Coursework {{topic_course_work_infoArr+1}}
                                                    </mat-panel-title>
                                                    <button type="button" class="btn bxClose"
                                                        (click)="removeArray('topic_course_work', topicArr, topic_course_work_infoArr, '', 'manual')">
                                                        <i class="fas fa-times-circle"></i></button>
                                                </mat-expansion-panel-header>
                                                <div>
                                                    <div class="whiteBx">
                                                        <!-- White Layer Box For Corsework -->
                                                        <div class="bxTitle minHe8">
                                                            <input type="text" class="secTtleInput"
                                                                formControlName="title"
                                                                [value]="topic_course_work_info.get('title').value | capitalization"
                                                                [ngClass]="{ 'is-invalid': submitAddCourse && topic_course_work_info.controls.title.errors }">
                                                            <div *ngIf="submitAddCourse && topic_course_work_info.controls.title.errors"
                                                                class="invalid-feedback">
                                                                <div
                                                                    *ngIf="topic_course_work_info.controls.title.errors.required">
                                                                    course work
                                                                    title is required
                                                                </div>
                                                            </div>
                                                            <ng-container>
                                                                <div class="p-3">
                                                                    <div class="row mb-3">
                                                                        <div class="col-12">
                                                                            <div class="btnTxtFldRow">
                                                                                <button type="button"
                                                                                    class="btn btn-primary popLinkBx"
                                                                                    (click)="openXl(content)"><i
                                                                                        class="fas fa-plus"></i></button>
                                                                                <div class="txtFld sch lftAln">
                                                                                    <select class="custom-select"
                                                                                        [ngClass]="{ 'is-invalid': submitAddCourse && topic_course_work_info.controls._id.errors }"
                                                                                        formControlName="_id"
                                                                                        (change)="selectCourseWork(topicArr,topic_course_work_infoArr,$event)">
                                                                                        <option selected value="">
                                                                                            Select
                                                                                            Course
                                                                                            Work
                                                                                        </option>
                                                                                        <option
                                                                                            [value]="option.course_work_id"
                                                                                            *ngFor="let option of allCourseWork">
                                                                                            {{option.course_work_title}}
                                                                                        </option>
                                                                                    </select>
                                                                                    <div *ngIf="submitAddCourse && topic_course_work_info.controls._id.errors"
                                                                                        class="invalid-feedback">
                                                                                        <div
                                                                                            *ngIf="topic_course_work_info.controls._id.errors.required">
                                                                                            course
                                                                                            work
                                                                                            is
                                                                                            required
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row mb-1"
                                                                        *ngIf="topic_course_work_info.controls.is_group.value == true">
                                                                        <label class="col-3 m-0">Group
                                                                            Assignment:</label>
                                                                        <div class="col">
                                                                            <div class="cutsCheckRow d-flex flex-row">
                                                                                <div class="chkFld">
                                                                                    <input type="radio"
                                                                                        [ngClass]="{ 'is-invalid': submitAddCourse && topic_course_work_info.controls.group_type.errors }"
                                                                                        value="manual"
                                                                                        id="{{'Manual1'+topic_course_work_infoArr}}"
                                                                                        formControlName="group_type">
                                                                                    <label class="chkLbl"
                                                                                        for="{{'Manual1'+topic_course_work_infoArr}}">Manual</label>
                                                                                </div>
                                                                                <div class="chkFld">
                                                                                    <input type="radio"
                                                                                        value="Student-led"
                                                                                        [ngClass]="{ 'is-invalid': submitAddCourse && topic_course_work_info.controls.group_type.errors }"
                                                                                        id="{{'Student1'+topic_course_work_infoArr}}"
                                                                                        formControlName="group_type">
                                                                                    <label class="chkLbl"
                                                                                        for="{{'Student1'+topic_course_work_infoArr}}">Student-Led</label>
                                                                                </div>
                                                                                <div class="chkFld">
                                                                                    <input type="radio" value="auto"
                                                                                        id="{{'Auto1'+topic_course_work_infoArr}}"
                                                                                        [ngClass]="{ 'is-invalid': submitAddCourse && topic_course_work_info.controls.group_type.errors }"
                                                                                        formControlName="group_type">
                                                                                    <label class="chkLbl"
                                                                                        for="{{'Auto1'+topic_course_work_infoArr}}">Auto</label>
                                                                                </div>
                                                                                <div *ngIf="submitAddCourse && topic_course_work_info.controls.group_type.errors"
                                                                                    class="invalid-feedback">
                                                                                    <div
                                                                                        *ngIf="topic_course_work_info.controls.group_type.errors.required">
                                                                                        group
                                                                                        type is
                                                                                        required
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row no-gutters">
                                                                        <div class="col d-flex pr-3">
                                                                            <label class="lbl pr-3">Date:</label>
                                                                            <div class="flex-fill cld">
                                                                                <input type="text" class="form-control"
                                                                                    placeholder="YYYY/MM/DD"
                                                                                    name="startDp"
                                                                                    [ngClass]="{ 'is-invalid': submitAddCourse && topic_course_work_info.controls.date.errors }"
                                                                                    ngbDatepicker #date="ngbDatepicker"
                                                                                    formControlName="date">
                                                                                <button class="btn calendar mr-3"
                                                                                    (click)="date.toggle()"
                                                                                    type="button"><i
                                                                                        class="far fa-calendar-alt"></i></button>
                                                                                <div *ngIf="submitAddCourse && topic_course_work_info.controls.date.errors"
                                                                                    class="invalid-feedback">
                                                                                    <div
                                                                                        *ngIf="topic_course_work_info.controls.date.errors.required">
                                                                                        Date is
                                                                                        required
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col d-flex">
                                                                            <label class="lbl pr-3">Time:</label>
                                                                            <div class="flex-fill">
                                                                                <input type="time" class="form-control"
                                                                                    formControlName="time"
                                                                                    [ngClass]="{ 'is-invalid': submitAddCourse && topic_course_work_info.controls.time.errors }">
                                                                                <div *ngIf="submitAddCourse && topic_course_work_info.controls.time.errors"
                                                                                    class="invalid-feedback">
                                                                                    <div
                                                                                        *ngIf="topic_course_work_info.controls.time.errors.required">
                                                                                        Time is
                                                                                        required
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="row no-gutters align-items-center pt-3" *ngIf="topic_course_work_info.controls.is_group.value == false">
                                                                        <div class="col d-flex pr-3">
                                                                            <div class="custom-control custom-checkbox">
                                                                                <input type="checkbox"
                                                                                    class="custom-control-input"
                                                                                    [id]="'peer'+topic_course_work_infoArr+topicArr"
                                                                                    formControlName="peer_review">
                                                                                <label class="custom-control-label"
                                                                                    [for]="'peer'+topic_course_work_infoArr+topicArr">Peer
                                                                                    Review</label>
                                                                            </div>
                                                                        </div>
                                                                        <!-- <div class="col d-flex  p-2"
                                                                            *ngIf="topic_course_work_info.controls['peer_review'].value == true">
                                                                            <label class="lbl pr-3">Peer
                                                                                size:</label>
                                                                            <div class="flex-fill">
                                                                                <input type="text"
                                                                                    (input)="onlyNumber(topic_course_work_info.controls['peer_size'])"
                                                                                    maxlength="1" class="form-control"
                                                                                    formControlName="peer_size">
                                                                                <div
                                                                                    *ngIf="submitAddCourse && topic_course_work_info.controls['peer_review'].value == true">
                                                                                    <div
                                                                                        *ngIf="topic_course_work_info.controls['peer_review'].value == true">
                                                                                        Peer
                                                                                        Size is
                                                                                        required
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div> -->
                                                                    </div>

                                                                    <div class="row no-gutters align-items-center" *ngIf="topic_course_work_info.controls.is_group.value == false">
                                                                        <div class="col d-flex pr-3">
                                                                            <div class="custom-control custom-checkbox">
                                                                                <input type="checkbox"
                                                                                    class="custom-control-input"
                                                                                    [id]="'self'+topic_course_work_infoArr+topicArr"
                                                                                    formControlName="self_review">
                                                                                <label class="custom-control-label"
                                                                                    [for]="'self'+topic_course_work_infoArr+topicArr">Self
                                                                                    Review</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                    <!-- End: \ White Layer Box For Corsework -->
                                                </div>
                                            </mat-expansion-panel>
                                        </mat-accordion>

                                        <mat-accordion cdkDropList #courseTopicAssessment="cdkDropList"
                                            [cdkDropListData]=" topic.controls.assessments['controls']"
                                            (cdkDropListDropped)="drop($event)" formArrayName="assessments">
                                            <mat-expansion-panel
                                                *ngFor="let topic_assessment_info of topic.controls.assessments['controls']; let topic_assessment_infoArr=index"
                                                [formGroupName]="topic_assessment_infoArr" cdkDrag>
                                                <mat-expansion-panel-header>
                                                    <mat-panel-title>
                                                        Assessment {{topic_assessment_infoArr+1}}
                                                    </mat-panel-title>
                                                    <button type="button" class="btn bxClose"
                                                        (click)="removeArray('assessments', topicArr, topic_assessment_infoArr, '', 'manual')">
                                                        <i class="fas fa-times-circle"></i></button>
                                                </mat-expansion-panel-header>
                                                <div>
                                                    <div class="whiteBx">
                                                        <!-- White Layer Box For Assessment -->
                                                        <div class="bxTitle minHe8">
                                                            <input type="text" class="secTtleInput"
                                                                formControlName="title"
                                                                [value]="topic_assessment_info.get('title').value | capitalization"
                                                                [ngClass]="{ 'is-invalid': submitAddCourse && topic_assessment_info.controls.title.errors }">

                                                            <div *ngIf="submitAddCourse && topic_assessment_info.controls.title.errors"
                                                                class="invalid-feedback">
                                                                <div
                                                                    *ngIf="topic_assessment_info.controls.title.errors.required">
                                                                    Title is required
                                                                </div>
                                                            </div>
                                                            <ng-container>
                                                                <div class="p-3">
                                                                    <div class="row mb-3">
                                                                        <div class="col-12">
                                                                            <div class="btnTxtFldRow">
                                                                                <button type="button"
                                                                                    class="btn btn-primary popLinkBx"
                                                                                    (click)="openAsmnt(contentAsmnt)"><i
                                                                                        class="fas fa-plus"></i></button>
                                                                                <div class="txtFld sch lftAln">
                                                                                    <select class="custom-select"
                                                                                        formControlName="_id"
                                                                                        [ngClass]="{ 'is-invalid': submitAddCourse && topic_assessment_info.controls._id.errors }">
                                                                                        <option selected value="">
                                                                                            Select
                                                                                            assessment
                                                                                        </option>
                                                                                        <option [value]="option._id"
                                                                                            *ngFor="let option of getAllAssessment">
                                                                                            {{option.assessment_name}}
                                                                                        </option>
                                                                                    </select>
                                                                                    <div *ngIf="submitAddCourse && topic_assessment_info.controls._id.errors"
                                                                                        class="invalid-feedback">
                                                                                        <div
                                                                                            *ngIf="topic_assessment_info.controls._id.errors.required">
                                                                                            Assessment
                                                                                            is
                                                                                            required
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row mb-3 no-gutters">
                                                                        <div class="custom-control custom-checkbox">
                                                                            <input type="checkbox"
                                                                                class="custom-control-input"
                                                                                [id]="'calenderedAssessment'+topic_assessment_infoArr+topicArr"
                                                                                #calenderedAssessment (change)="0"
                                                                                formControlName="calendered">
                                                                            <label class="custom-control-label"
                                                                                [for]="'calenderedAssessment'+topic_assessment_infoArr+topicArr">Calendared</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row mb-3 no-gutters"
                                                                        *ngIf="topic_assessment_info.controls.calendered.value == true">
                                                                        <div class="col d-flex pr-3">
                                                                            <label class="lbl pr-3">Date:</label>
                                                                            <div class="flex-fill cld">
                                                                                <input type="text" class="form-control"
                                                                                    placeholder="YYYY/MM/DD"
                                                                                    name="startDp" ngbDatepicker
                                                                                    #date="ngbDatepicker"
                                                                                    formControlName="date"
                                                                                    [ngClass]="{ 'is-invalid': submitAddCourse && (topic_assessment_info.controls.calendered.value== true && topic_assessment_info.controls.date.value== null) }">
                                                                                <button class="btn calendar mr-3"
                                                                                    (click)="date.toggle()"
                                                                                    type="button"><i
                                                                                        class="far fa-calendar-alt"></i></button>
                                                                                <div *ngIf="submitAddCourse && (topic_assessment_info.controls.calendered.value== true && topic_assessment_info.controls.date.value== null)"
                                                                                    class="invalid-feedback">
                                                                                    <div
                                                                                        *ngIf="(topic_assessment_info.controls.calendered.value== true && topic_assessment_info.controls.date.value== null)">
                                                                                        Date is
                                                                                        required
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col d-flex">
                                                                            <label class="lbl pr-3">Time:</label>
                                                                            <div class="flex-fill">
                                                                                <input type="time" class="form-control"
                                                                                    [ngClass]="{ 'is-invalid': submitAddCourse && (topic_assessment_info.controls.calendered.value== true && topic_assessment_info.controls.time.value== null) }"
                                                                                    formControlName="time">
                                                                                <div *ngIf="submitAddCourse && (topic_assessment_info.controls.calendered.value== true && topic_assessment_info.controls.time.value== null)"
                                                                                    class="invalid-feedback">
                                                                                    <div
                                                                                        *ngIf="(topic_assessment_info.controls.calendered.value== true && topic_assessment_info.controls.time.value== null)">
                                                                                        Time is
                                                                                        required
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row mb-3 no-gutters">
                                                                        <div class="custom-control custom-checkbox">
                                                                            <input type="checkbox"
                                                                                class="custom-control-input"
                                                                                [id]="'timed'+topic_assessment_infoArr+topicArr"
                                                                                formControlName="is_timed">
                                                                            <label class="custom-control-label"
                                                                                [for]="'timed'+topic_assessment_infoArr+topicArr">Timed</label>
                                                                        </div>
                                                                    </div>

                                                                    <div class="row mb-3 no-gutters">
                                                                        <div class="custom-control custom-checkbox">
                                                                            <input type="checkbox"
                                                                                class="custom-control-input"
                                                                                [id]="'oneTimeAssessment'+topic_assessment_infoArr+topicArr"
                                                                                formControlName="one_time_assessment">
                                                                            <label class="custom-control-label"
                                                                                [for]="'oneTimeAssessment'+topic_assessment_infoArr+topicArr">One
                                                                                Time Assessment</label>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                    <!-- End: \ White Layer Box For Assessment -->
                                                </div>
                                            </mat-expansion-panel>
                                        </mat-accordion>

                                        <div class="addContBAR">
                                            <!-- // Section for Adding Elements // -->
                                            <div class="barGrp d-inline-flex flex-row">
                                                <div class="lbl">Add</div>
                                                <div>
                                                    <select class="custom-select"
                                                        (change)="addArray($event, 'underTopic', topicArr)">
                                                        <option value="select">select</option>
                                                        <option value="coursework">Coursework</option>
                                                        <option value="assessment">Assessment</option>
                                                        <option value="resource">Resource</option>
                                                        <option value="session">Session</option>
                                                        <option value="live_session">Live Session</option>
                                                    </select>
                                                </div>

                                                <div>
                                                    <select class="custom-select"
                                                        (change)="indexValue($event.target.value)">
                                                        <option value="1" [selected]='!checkState'>1
                                                        </option>
                                                        <option value="2">2</option>
                                                        <option value="3">3</option>
                                                        <option value="4">4</option>
                                                        <option value="5">5</option>
                                                        <option value="6">6</option>
                                                        <option value="7">7</option>
                                                        <option value="8">8</option>
                                                        <option value="9">9</option>
                                                        <option value="10">10</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <!-- </ng-template>
                        </ngb-panel>
                    </ngb-accordion> -->
                </mat-expansion-panel>
            </mat-accordion>
            <mat-accordion cdkDropList #courseCoursework="cdkDropList" class="nonTopic"
                [cdkDropListData]=" addCourseForm.controls.course_work['controls']" (cdkDropListDropped)="drop($event)"
                formArrayName="course_work">
                <mat-expansion-panel
                    *ngFor="let course_level_course_work_info of addCourseForm.controls.course_work['controls']; let course_level_course_work_infoArr=index"
                    [formGroupName]="course_level_course_work_infoArr" cdkDrag>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Coursework
                        </mat-panel-title>
                        <button type="button" class="btn bxClose"
                            (click)="removeArray('course_work', course_level_course_work_infoArr, '', '', 'manual')">
                            <i class="fas fa-times-circle"></i></button>
                    </mat-expansion-panel-header>
                    <div>
                        <div class="sectBoxNorms">
                            <!-- // Coursework Box // -->
                            <div class="bxTitle">
                                <input type="text" class="secTtleInput" value="Title" formControlName="title"
                                    [value]="course_level_course_work_info.get('title').value | capitalization"
                                    [ngClass]="{ 'is-invalid': submitAddCourse && course_level_course_work_info.controls.title.errors }">

                                <div *ngIf="submitAddCourse && course_level_course_work_info.controls.title.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="course_level_course_work_info.controls.title.errors.required">
                                        Course work title is required
                                    </div>
                                </div>

                                <ng-container>
                                    <div class="bxBody">
                                        <div class="row mb-3">
                                            <div class="col-12">
                                                <div class="btnTxtFldRow">
                                                    <button type="button" class="btn btn-primary popLinkBx"
                                                        (click)="openXl(content)"><i class="fas fa-plus"></i></button>
                                                    <div class="txtFld sch lftAln">

                                                        <select class="custom-select" formControlName="_id"
                                                            (change)="selectCourseWork(null,course_level_course_work_infoArr,$event)"
                                                            [ngClass]="{ 'is-invalid': submitAddCourse && course_level_course_work_info.controls._id.errors }">
                                                            <option selected value="">Select Course work
                                                            </option>
                                                            <option [value]="option.course_work_id"
                                                                *ngFor="let option of allCourseWork">
                                                                {{option.course_work_title}}
                                                            </option>
                                                        </select>
                                                        <div *ngIf="submitAddCourse && course_level_course_work_info.controls._id.errors"
                                                            class="invalid-feedback">
                                                            <div
                                                                *ngIf="course_level_course_work_info.controls._id.errors.required">
                                                                Course work is required
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-1"
                                            *ngIf="course_level_course_work_info.controls.is_group.value == true">
                                            <label class="col-3 m-0">Group Assignment:</label>
                                            <div class="col">
                                                <div class="cutsCheckRow d-flex flex-row">
                                                    <div class="chkFld">
                                                        <input type="radio"
                                                            id="{{'Manual'+course_level_course_work_infoArr}}"
                                                            value="manual" formControlName="group_type" (change)="0">
                                                        <label class="chkLbl"
                                                            for="{{'Manual'+course_level_course_work_infoArr}}">Manual</label>
                                                    </div>
                                                    <div class="chkFld">
                                                        <input type="radio"
                                                            id="{{'Student'+course_level_course_work_infoArr}}"
                                                            value="Student-led" formControlName="group_type" #studentLed
                                                            (change)="0">
                                                        <label class="chkLbl"
                                                            for="{{'Student'+course_level_course_work_infoArr}}">Student-Led</label>
                                                    </div>
                                                    <div class="chkFld">
                                                        <input type="radio"
                                                            id="{{'Auto'+course_level_course_work_infoArr}}"
                                                            value="auto" formControlName="group_type" (change)="0">
                                                        <label class="chkLbl"
                                                            for="{{'Auto'+course_level_course_work_infoArr}}">Auto</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row no-gutters">
                                            <div class="col d-flex pr-3">
                                                <label class="lbl pr-3">Date:</label>
                                                <div class="flex-fill cld">
                                                    <input type="text" class="form-control" placeholder="YYYY/MM/DD"
                                                        name="startDp" ngbDatepicker #date="ngbDatepicker"
                                                        formControlName="date"
                                                        [ngClass]="{ 'is-invalid': submitAddCourse && course_level_course_work_info.controls.date.errors }">
                                                    <button class="btn calendar mr-3" (click)="date.toggle()"
                                                        type="button"><i class="far fa-calendar-alt"></i></button>
                                                    <div *ngIf="submitAddCourse && course_level_course_work_info.controls.date.errors"
                                                        class="invalid-feedback">
                                                        <div
                                                            *ngIf="course_level_course_work_info.controls.date.errors.required">
                                                            Course work date is required
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col d-flex">
                                                <label class="lbl pr-3">Time:</label>
                                                <div class="flex-fill">
                                                    <input type="time" class="form-control"
                                                        [ngClass]="{ 'is-invalid': submitAddCourse && course_level_course_work_info.controls.time.errors }"
                                                        formControlName="time">
                                                    <div *ngIf="submitAddCourse && course_level_course_work_info.controls.time.errors"
                                                        class="invalid-feedback">
                                                        <div
                                                            *ngIf="course_level_course_work_info.controls.time.errors.required">
                                                            Course work time is required
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row no-gutters align-items-center pt-3" *ngIf="course_level_course_work_info.controls.is_group.value == false">
                                            <div class="col d-flex pr-3">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input"
                                                        [id]="'peer'+course_level_course_work_infoArr"
                                                        formControlName="peer_review">
                                                    <label class="custom-control-label"
                                                        [for]="'peer'+course_level_course_work_infoArr">Peer
                                                        Review</label>
                                                </div>
                                            </div>

                                            <!-- <div class="col d-flex p-2"
                                                *ngIf="course_level_course_work_info.controls['peer_review'].value == true">
                                                <label class="lbl pr-3">Peer size:</label>
                                                <div class="flex-fill">
                                                    <input type="text"
                                                        (input)="onlyNumber(course_level_course_work_info.controls['peer_size'])"
                                                        maxlength="1" class="form-control" formControlName="peer_size">
                                                    <div
                                                        *ngIf="submitAddCourse && course_level_course_work_info.controls['peer_review'].value == true">
                                                        <div
                                                            *ngIf="course_level_course_work_info.controls['peer_review'].value == true">
                                                            Peer Size is required
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> -->
                                        </div>

                                        <div class="row no-gutters align-items-center" *ngIf="course_level_course_work_info.controls.is_group.value == false">
                                            <div class="col d-flex pr-3">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input"
                                                        [id]="'self'+course_level_course_work_infoArr"
                                                        formControlName="self_review">
                                                    <label class="custom-control-label"
                                                        [for]="'self'+course_level_course_work_infoArr">Self
                                                        Review</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
            <mat-accordion cdkDropList #courseAssessment="cdkDropList" class="nonTopic"
                [cdkDropListData]="addCourseForm.controls.course_assessments['controls']"
                (cdkDropListDropped)="drop($event)" formArrayName="course_assessments">
                <mat-expansion-panel cdkDrag
                    *ngFor="let course_level_assessment_info of addCourseForm.controls.course_assessments['controls']; let course_level_assessmentArr=index"
                    [formGroupName]="course_level_assessmentArr">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Assessment
                        </mat-panel-title>
                        <button type="button" class="btn bxClose"
                            (click)="removeArray('course_assessments', course_level_assessmentArr, '', '', 'manual')">
                            <i class="fas fa-times-circle"></i></button>
                    </mat-expansion-panel-header>
                    <div class="sectBoxNorms">
                        <!-- // Assessment Box // -->
                        <div class="bxTitle">
                            <input type="text" class="secTtleInput" value="Title"
                                [value]="course_level_assessment_info.get('title').value | capitalization"
                                [ngClass]="{ 'is-invalid': submitAddCourse && course_level_assessment_info.controls.title.errors }"
                                formControlName="title">
                            <div *ngIf="submitAddCourse && course_level_assessment_info.controls.title.errors"
                                class="invalid-feedback">
                                <div *ngIf="course_level_assessment_info.controls.title.errors.required">
                                    Assessment title is required
                                </div>
                            </div>

                            <ng-container>
                                <div class="bxBody">
                                    <div class="row mb-3">
                                        <div class="col-12">
                                            <div class="btnTxtFldRow">
                                                <button type="button" class="btn btn-primary popLinkBx"
                                                    (click)="openAsmnt(contentAsmnt)"><i
                                                        class="fas fa-plus"></i></button>
                                                <div class="txtFld sch lftAln">
                                                    <select class="custom-select"
                                                        (change)="selectAssessment(null,course_level_assessmentArr,$event)"
                                                        [ngClass]="{ 'is-invalid': submitAddCourse && course_level_assessment_info.controls._id.errors }"
                                                        formControlName="_id">
                                                        <option selected value="">Select assessment..
                                                        </option>
                                                        <option [value]="option._id"
                                                            *ngFor="let option of getAllAssessment">
                                                            {{option.assessment_name}}
                                                        </option>
                                                    </select>
                                                    <div *ngIf="submitAddCourse && course_level_assessment_info.controls._id.errors"
                                                        class="invalid-feedback">
                                                        <div
                                                            *ngIf="course_level_assessment_info.controls._id.errors.required">
                                                            Assessment is required
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3 no-gutters">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input"
                                                [id]="'calendered2'+course_level_assessmentArr" #calendered (change)="0"
                                                formControlName="calendered">
                                            <label class="custom-control-label"
                                                [for]="'calendered2'+course_level_assessmentArr">Calendared</label>
                                        </div>
                                    </div>
                                    <div class="row mb-3 no-gutters"
                                        *ngIf="course_level_assessment_info.controls.calendered.value == true">
                                        <div class="col d-flex pr-3">
                                            <label class="lbl pr-3">Date:</label>
                                            <div class="flex-fill cld">
                                                <input type="text" class="form-control" placeholder="YYYY/MM/DD"
                                                    name="startDp" ngbDatepicker #date="ngbDatepicker"
                                                    formControlName="date"
                                                    [ngClass]="{ 'is-invalid': submitAddCourse && (course_level_assessment_info.controls.calendered.value== true && course_level_assessment_info.controls.date.value== null) }">
                                                <button class="btn calendar mr-3" (click)="date.toggle()"
                                                    type="button"><i class="far fa-calendar-alt"></i></button>
                                                <div *ngIf="submitAddCourse && (course_level_assessment_info.controls.calendered.value== true && course_level_assessment_info.controls.date.value== null)"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="(course_level_assessment_info.controls.calendered.value== true && course_level_assessment_info.controls.date.value== null)">
                                                        Date is required
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col d-flex">
                                            <label class="lbl pr-3">Time:</label>
                                            <div class="flex-fill">
                                                <input type="time" class="form-control" formControlName="time"
                                                    [ngClass]="{ 'is-invalid': submitAddCourse && (course_level_assessment_info.controls.calendered.value== true && course_level_assessment_info.controls.time.value== null) }">
                                                <div *ngIf="submitAddCourse && (course_level_assessment_info.controls.calendered.value== true && course_level_assessment_info.controls.time.value== null)"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="(course_level_assessment_info.controls.calendered.value== true && course_level_assessment_info.controls.time.value== null)">
                                                        Time is required
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3 no-gutters">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input"
                                                [id]="'timed'+course_level_assessmentArr" formControlName="is_timed">
                                            <label class="custom-control-label"
                                                [for]="'timed'+course_level_assessmentArr">Timed</label>
                                        </div>
                                    </div>

                                    <div class="row mb-3 no-gutters">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input"
                                                [id]="'oneTimeAssessment'+course_level_assessmentArr"
                                                formControlName="one_time_assessment">
                                            <label class="custom-control-label"
                                                [for]="'oneTimeAssessment'+course_level_assessmentArr">One Time
                                                Assessment</label>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
            <mat-accordion cdkDropList #courseResources="cdkDropList" class="nonTopic"
                [cdkDropListData]="addCourseForm.controls.course_resources['controls']"
                (cdkDropListDropped)="drop($event)" formArrayName="course_resources">
                <mat-expansion-panel cdkDrag
                    *ngFor="let course_resource_info of addCourseForm.controls.course_resources['controls']; let course_resourceArr=index"
                    [formGroupName]="course_resourceArr">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Course Resource {{course_resourceArr+1}}
                        </mat-panel-title>
                        <button type="button" class="btn bxClose"
                            (click)="removeArray('course_resources', topicArr, '', '', 'manual')">
                            <i class="fas fa-times-circle"></i></button>
                    </mat-expansion-panel-header>
                    <div>
                        <div class="sectBoxNorms">
                            <!-- White Layer Box For Resource -->
                            <div class="bxTitle minHe8">
                                <input type="text" class="secTtleInput" formControlName="title"
                                    [value]="course_resource_info.get('title').value | capitalization"
                                    [ngClass]="{ 'is-invalid': submitAddCourse && course_resource_info.controls.title.errors }">
                                <div *ngIf="submitAddCourse && course_resource_info.controls.title.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="course_resource_info.controls.title.errors.required">
                                        title is required</div>
                                </div>
                                <ng-container>
                                    <div class="p-3">
                                        <div class="row mb-3">
                                            <label class="col-12 col-md-3 pb-2 pb-md-0 m-0 pt0">
                                                Enter URL: <br><small>or Add File:</small>
                                            </label>
                                            <div class="col-12 col-md-9">
                                                <div class="progress form-group" style="height: 8px;"
                                                    *ngIf="progress > 0 && (upload_index == course_resourceArr && upload_type == 'course_resource')">
                                                    <div class="progress-bar progress-bar-striped bg-success"
                                                        role="progressbar" [style.width.%]="progress">
                                                    </div>
                                                </div>
                                                <div class="inputNBrowse">
                                                    <mat-form-field class="example-full-width">
                                                        <input type="text" class="form-control"
                                                            [value]="get_res_val('course_resource', course_resource_info.controls._id.value,course_resourceArr)"
                                                            (focus)="setInd('course_resource', course_resourceArr)"
                                                            matInput [id]="'course_resource'+course_resourceArr"
                                                            aria-label="course_Resource" [matAutocomplete]="auto"
                                                            #inputField
                                                            [ngClass]="{ 'is-invalid': submitAddCourse && course_resource_info.controls.title.errors }">
                                                        <mat-autocomplete #auto="matAutocomplete">
                                                            <mat-option *ngFor="let option of results"
                                                                [value]="option.title"
                                                                (onSelectionChange)="searchData(option,course_resourceArr, 'course_resource')">
                                                                {{ option.title }}
                                                                <span *ngIf="option.id == ''">(Add URL)</span>
                                                            </mat-option>
                                                        </mat-autocomplete>
                                                    </mat-form-field>

                                                    <!-- <input type="text" class="form-control"
                                                        (change)="saveType('course_resource', course_resourceArr, null, null)"
                                                       
                                                        [ngClass]="{ 'is-invalid': submitAddCourse && (course_resource_info.controls.url.value == '' && course_resource_info.controls._id.value == '') }"
                                                        formControlName="url"> -->
                                                    <!-- <div class="custom-file fltdBrowse">
                                                        <input type="file" class="custom-file-input"
                                                            [id]="'upCourseImageRes'+course_resourceArr"
                                                            (change)="detectFiles($event, 'course_resource', course_resourceArr, null, null, null)">
                                                        <label class="custom-file-label"
                                                            [for]="'upCourseImageRes'+course_resourceArr"></label>
                                                    </div> -->
                                                    <div *ngIf="submitAddCourse && (course_resource_info.controls.url.value == '' && course_resource_info.controls._id.value == '')"
                                                        class="invalid-feedback">
                                                        <div
                                                            *ngIf="(course_resource_info.controls.url.value == '' && course_resource_info.controls._id.value == '')">
                                                            URL is required</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div> <!-- End: \ White Layer Box For Resource -->
                    </div>
                    <!-- <p>This is the primary content of the panel.</p> -->
                </mat-expansion-panel>
            </mat-accordion>
            <div class="addContBAR">
                <!-- // Section for Adding Elements // -->
                <div class="barGrp d-inline-flex flex-row">
                    <div class="lbl">Add</div>
                    <div>
                        <select class="custom-select" (change)="addArray($event, 'topicLevel', null)">
                            <option value="select">select</option>
                            <option value="topics">Topic</option>
                            <option value="courseworks">Coursework</option>
                            <option value="course_assessments">Assessment</option>
                            <option value="course_resources">Course Resource</option>

                        </select>
                    </div>
                    <div>
                        <select class="custom-select" (change)="indexValue($event.target.value)">
                            <option value="1" [selected]='!checkState'>1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                        </select>
                    </div>
                </div>
            </div>
            <div *ngIf="showCourseData">
            </div>
            <div class="text-right btnHldr">
                <button type="button" (click)="backToCourses()" class="btn btn-secondary ml-2">Back</button>
                <button type="submit" class="btn btn-primary ml-2" *ngIf="saveButton != 'publish'">Save as
                    draft</button>
                <button type="button" class="btn btn-primary ml-2" (click)="formSubmit('publish')">Publish</button>
            </div>
        </form>
    </div>
</div>

<!-- // Popup for coursework // -->
<ng-template #content let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Add Coursework</h4>
        <button type="button" class="close" aria-label="Close" (click)="closeCourseWork('closeCourseWork')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <app-add-course-work [course_category]="addCourse.course_category_id.value"
            (closeCouserWork)="closeCourseWork($event)"></app-add-course-work>
    </div>
</ng-template>


<!-- // Popup for assessment // -->
<ng-template #contentAsmnt let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Add Assessment</h4>
        <button type="button" class="close" aria-label="Close" (click)="closeAssessmentPopup()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <app-create-assessment [save_button]="save_button" (closeAssessmentPopUp)="closeAssessmentPopUp($event)">
        </app-create-assessment>
    </div>
</ng-template>

<ng-template #contentAsmntAtSesseion let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Add Assessment</h4>
        <button type="button" class="close" aria-label="Close" (click)="closeAssessment()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body px-0">
        <form [formGroup]="assessmentForm">
            <div formArrayName="assessmentArray">
                <div class="contMaxHe8 px-4 mb-2">
                    <div class="row mb-2 assR"
                        *ngFor="let assessment of assessmentForm.controls.assessmentArray['controls']; let assessmentIndex=index"
                        [formGroupName]="assessmentIndex">
                        <div class="col-6">
                            <select class="custom-select" formControlName="_id">
                                <option selected value="">Select assessment</option>
                                <option [value]="option._id" *ngFor="let option of getAllAssessment">
                                    {{option.assessment_name}}
                                </option>
                            </select>
                        </div>
                        <div class="col-6" *ngIf="key == 'session'">
                            <input type="text" (input)="onlyNumber(assessment.controls['time'])" class="form-control"
                                placeholder="time" formControlName="time"
                                [ngClass]="{ 'is-invalid': submitAddCourse && assessment.controls.time.errors }">
                            <div *ngIf="submitAddCourse && assessment.controls.time.errors" class="invalid-feedback">
                                <div *ngIf="assessment.controls.time.errors.required"> time of Live
                                    time is
                                    required</div>
                            </div>
                        </div>
                        <button type="button" title="Remove Assessment" (click)="remove_assessment(assessmentIndex)"
                            class="btnRemove"><i class="far fa-times-circle"></i></button>
                    </div>
                </div>

                <div class="text-right px-4 expPad">
                    <button type="button" class="btn btn-secondary ml-3" (click)="addMoreAssessement(key)">Add</button>
                    <button type="submit" class="btn btn-primary ml-3" (click)="saveAssessment()">Save</button>
                </div>
            </div>
        </form>
    </div>
</ng-template>

<ng-template ngbNavContent #openZoomCredentials let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Add Zoom Credentials</h4>
        <button type="button" class="close" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <!-- <app-apps-settings></app-apps-settings> -->
</ng-template>