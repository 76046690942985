<div class="cwBoxOuter projView mt-4" [ngClass]="{submitting : processLoader || zipDownloading || isSubmissionDataLoading}">
	<hr *ngIf="peerSubmissionReportDetail.length > 0">
	<div class="spinner-border text-warning formLoader mb-2 mt-4" role="status"  *ngIf="zipDownloading">
	  <span class="sr-only">Loading...</span>
	</div>

	<div *ngFor="let peerSubmissionReport of peerSubmissionReportDetail; let peerReviewIndex = index;">
		<!-- UPLOADED WORK BY PEER-->
		<div class="learning-course-items-wrap learning-course-resources-wrap mb-4">
			<div class="text-right mt-4">
				<h6 class="primary-text avenirBlack mb-0 mt-2 float-left">Submissions By Peer <span *ngIf="peerSubmissionReportDetail.length > 1">{{peerReviewIndex + 1}}</span></h6>
				<button *ngIf="peerSubmissionReport.submissions[showCourseWork.submission_report].files.length > 0" type="button" class="btn btn-grey-primary btn-icon-text pt-2 pb-2 mb-2" (click)="getZipFile(peerSubmissionReport)" [disabled]="zipDownloading == true">
					<i class="fa fa-download" aria-hidden="true"></i><strong class="ml-2">Download All</strong>
				</button>
				<div *ngIf="peerSubmissionReport.submissions[showCourseWork.submission_report].files.length == 0" class="pt-2 pb-2"></div>
			</div>

			<div class="learning-course-item"
			*ngFor="let submissions of peerSubmissionReport.submissions[showCourseWork.submission_report].files; let i=index">
				<div class="learning-course-link">
					<div class="learning-course-elem-left">
						<p class="learning-course-link-title">
							<span class="title-icon"><i class="fas fa-folder"></i></span>
							<strong ngbTooltip="{{submissions.timestamp}}" placement="top">{{submissions.filename}}</strong>
						</p>
					</div>
					<div class="learning-course-elem-right">
						<ul class="list-icon-wrap">
							<li>
								<a class="learning-course-download" (click)="openUploadedWork(submissions.file_path)">
									<i class="fas fa-download"></i>
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>

		<div class="col-md-12" *ngIf="peerSubmissionReport.submissions[showCourseWork.submission_report].files.length == 0">
		    <div class="alert alert-info text-center">
		        <p>There are no submissions.</p>
		    </div>
		</div>

		<!-- REVIEW TO PEER -->
		<div *ngIf="peerSubmissionReport.submissions[showCourseWork.submission_report].files.length > 0" class="report-wrapper">
			<div class="peer">
			    <p class="primary-text mb-2 mt-4">
			    	<span class="reviews-sub-heading">Peer Review</span>
					<a *ngIf="(submissionPublishStatus == false) && (role == 'student') && (peerSubmissionReport.submissions[showCourseWork.submission_report].peer_review.content && !editPeerReviewIndex[peerReviewIndex])" class="float-right" ngbTooltip="Edit" (click)="onEditReview('peer_review', peerSubmissionReport.submissions[showCourseWork.submission_report], peerReviewIndex, peerSubmissionReport)"><i class="fas fa-pencil-alt"></i></a>
			    </p>
			    <p *ngIf="!editPeerReviewIndex[peerReviewIndex]" class="reviews-content">
			    	{{peerSubmissionReport.submissions[showCourseWork.submission_report].peer_review.content | capitalization}}
			    </p>
			</div>
			<!-- REVIEW TO PEER FORM -->
		    <div *ngIf="(submissionPublishStatus == false) && (role == 'student')" [hidden]="(peerSubmissionReport.submissions[showCourseWork.submission_report].peer_review.content && !editPeerReviewIndex[peerReviewIndex])">
		        <p *ngIf="!editPeerReviewIndex[peerReviewIndex]" class="primary-text mb-2 mt-4"><span class="reviews-sub-heading">Submit Your Review</span></p>
		        <div class="form-group">
		            <div>
						<form [formGroup]="peerReviewForm[peerReviewIndex]" autocomplete="off">
			                <textarea type="text" formControlName="review" id="peerReviewInputBox_{{peerReviewIndex}}" class="mt-2 form-control reviews-textarea" cols="10" rows="6" [value]="peerReviewForm[peerReviewIndex].get('review').value | capitalization"></textarea>
			                <div class="text-center mt-4">
		                        <div class="spinner-border text-warning formLoader d-inline-block mr-4 inline-loader" *ngIf="processLoader" role="status" >
		                            <span class="sr-only">Loading...</span>
		                        </div>
		                        <button type="button" *ngIf="editPeerReviewIndex[peerReviewIndex]" class="btn btn-primary btn-w-120 mr-2" (click)="onDiscardReview(peerReviewIndex)" [disabled]="processLoader">
		                            Discard
		                        </button>
			                    <button type="button" class="btn btn-primary btn-w-120" (click)="onSaveReview(peerReviewIndex, peerSubmissionReport)" [disabled]="processLoader">
									Submit
								</button>
			                </div>
						</form>
		            </div>
		        </div>
		    </div>
		</div>
	</div>

</div>
