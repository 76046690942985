import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitTitle'
})
export class SplitTitlePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
      let valA =  value.split('/');
    let valB = valA[1].split('__');
    let valC = valB.pop().split('.');
    let title = valB[0] + '.' + valC[1];
    return title;
  }

}
