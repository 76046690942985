import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'find_key'
})
export class Find_Key implements PipeTransform {
    constructor() { }
    transform(teacher_view_submission, arg): string {
        let videoId = this.teach_view(teacher_view_submission);
        if (arg == 'obj') {
            return videoId;
        } else if (arg == 'length') {
            return videoId.length;
        }
    }

    teach_view(teacher_view_submission): any {
        let value = [];

        Object.keys(teacher_view_submission.submissions).forEach(key => {
            value.push({ k: key, v: teacher_view_submission.submissions[key] });
        });
        return value;
    }

}