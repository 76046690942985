import { PipeTransform, Pipe } from '@angular/core';
import { NotificationService } from '../services/notification.service';

@Pipe({
  name: 'capitalization'
})
export class CapitalizationPipe implements PipeTransform {
  constructor(private ns:NotificationService) { }
  transform(string): string {
	if(!string) {
		string = '';
	}
    let capitaizationFormat = this.titleCase(string);
    return capitaizationFormat;
  }

   titleCase(str) {
    return this.ns.titleCase(str);
  }

}