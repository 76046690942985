<!-- New Code -->
<div class="pageBodyWrap new_UIstyle">
    <!-- banner section -->
  <div class="banner banner-gradient banner-monitoring">
     <div class="container">
          <div class="banner-content">
              <div class="banner-content-content">
                  <h2 class="white-text avenirBlack">Teaching Assistants <br /><small>Progress Report of Teaching Assistants</small></h2>
              </div>
              <div class="banner-content-action btn-groups">
                   <button class="btn btn-primary" (click)="go_to_url()"><i class="fas fa-arrow-left mr-2"></i> Back</button>
              </div>
          </div>
     </div>
  </div>


<section class="section">
    <div class="container">
        <div class="table-responsive" [ngClass]="{submitting : loader}">
            <div class="spinner-border text-warning formLoader" role="status" *ngIf="loader">
                <span class="sr-only">Loading...</span>
            </div>
            <table class="table altRow">
                <thead>
                    <tr>
                        <th>Assistant Name</th>
                        <th>Organization</th>
                        <th class="w150p"># Subscribers</th>
                        <th>Teams</th>
                        <th class="w300p">Progress</th>
                    </tr>
                </thead>
                <tbody *ngIf="report_data">
                    <tr *ngFor="let my_report of report_data.teach_assistants">
                        <td>{{my_report.username}}</td>
                        <td>{{my_report.organisation}}</td>
                        <td>{{my_report.subscribers.length}}</td>
                        <td *ngIf="my_report.teams.length > 0">
                            <div *ngFor="let teams of my_report.teams" [title]="teams.members">
                                <span class="badge badge-primary mr-2 mb-2">
                                    Name: {{teams.name}}
                                    Member: {{teams.members}}
                                </span>
                            </div>
                        </td>
                        <td *ngIf="my_report.teams.length == 0">
                            No team for this course work yet
                        </td>
                        <td>
                            <div class="progress"
                                ngbTooltip="{{my_report.marked_submissions}} / {{my_report.sub_need_to_mark}}">
                                <div class="progress-bar" role="progressbar" [style.width]="myProgress(my_report)+'%'"
                                    style="width: 25%;">{{myProgress(my_report)}}</div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</section>

</div>