import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ResourceService {

  apiEnviroment: any;
  constructor(private httpClient: HttpClient) {
    this.apiEnviroment = environment.APIEndpoint;
  }

  serach_resource(file_type, page): Observable<any> {
    let params = new HttpParams();
    params = params.append('file_type', file_type);
    params = params.append('page', page);
    return this.httpClient.get<any>(this.apiEnviroment + '/resource-bank/new', { params })

  }

  serach_resource_elastic(value, type?: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('query', value);
    if (type) {
      params = params.append('type', type);
    }
    return this.httpClient.get<any>(this.apiEnviroment + '/search-resource', { params })

  }

  add_resource(value): Observable<any> {
    console.log(value)
    let body = {
      'description': value.description,
      'resource_id': value.resource_id,
      'title': value.resource_title,
      'tags': value.tags
    }
    return this.httpClient.post<any>(this.apiEnviroment + '/resource-bank/add', body)
  }

  edit_resource(value, instance_id): Observable<any> {
    if (!instance_id) {
      instance_id = null
    }
    let body = {
      'description': value.description,
      'resource_id': value.resource_id,
      'title': value.resource_title,
      'tags': value.tags,
      'instance_id': instance_id
    }
    return this.httpClient.patch<any>(this.apiEnviroment + '/resource-bank/edit', body)
  }

  delete_resource(_id, instance_id): Observable<any> {
    let params = new HttpParams();
    params = params.append('resource_id', _id);
    params = params.append('instance_id', instance_id);

    return this.httpClient.delete<any>(this.apiEnviroment + '/resource-bank/delete', { params })
  }

  removeResource(s3_key_val = '', deleted_files: any[] = []): Observable<any>{
    const postData = {deleted_files: deleted_files};
    return this.httpClient.post<any>(this.apiEnviroment + "/delete_resource?s3_key_val="+s3_key_val, postData);
  }
}
