import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { allCategory, CourseInfo } from 'src/app/model/course.model';
import { CourseService } from 'src/app/services/course.service';
import { ShareService } from 'src/app/services/share.service';

@Component({
  selector: 'app-other-courses',
  templateUrl: './other-courses.component.html',
  styleUrls: ['./other-courses.component.scss']
})
export class OtherCoursesComponent implements OnInit {
  currentRate: number;
  role = '';
  @Input() allCategory: allCategory[] = [];
  @Input() categoryVisDetails: CourseInfo[] = [];
  @Input() total_pages: number;
  @Input() current_page: number;
  @Input() number_of_page;
  @Output() send_index_number_unsubs = new EventEmitter();

  loader = false;
  category_id = '';
  get permissions() {
    return JSON.parse(localStorage.getItem("permissions"));
  }

  constructor(private shareService: ShareService, private router: Router, private courseService: CourseService) { }

  ngOnInit() {
    this.role = localStorage.getItem('role');
    this.send_index_number_unsubs.emit('1+other');
  }

  findIndexOf(slug, pmenusSlug) {
    let indexOfValue;
    indexOfValue = this.shareService.getIndexArr(slug, pmenusSlug)
    return indexOfValue;
  }

  sendCourseDetails(id, course) {
    this.router.navigate(['/courses/single-course'], { queryParams: { course_id: id, course: course } });
  }

  redirect(link) {
    this.router.navigate([link]);
  }

  onChangeCategory(value, page = null, status = null) {
    this.loader = true;
    this.category_id = value
    this.courseService.categoryVisCourses(value, page, status).pipe(finalize(() => {
      this.loader = false;
    })).subscribe(courseDetails => {
      this.categoryVisDetails = courseDetails.courses_info;
    })

  }

  Send_index(number) {
    this.send_index_number_unsubs.emit(number + '+other');
  }

  check_previous(type) {
    if (type == 'Previous') {
      return this.current_page == this.number_of_page[0] ? true : false;
    }
  }

  check_next(type) {
    if (type == 'Next') {
      return this.current_page == this.total_pages ? false : true;
    }
  }
  get_number(number_of_page) {
    if (number_of_page) {
      return number_of_page
    }
    return 1;
  }
}
