import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { AuthService } from '../services/auth.service';

@Injectable({providedIn: "root"})
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private authService: AuthService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot):  boolean {
      if (this.authService.isLogin) {
        return true;
      } 
      this.authService.redirectUrl = state.url; 
      this.router.navigate(['/login'], { queryParams: { redirect: state.url } });
    return false;
      
  }
}