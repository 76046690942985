




<div class="new_UIstyle site-footer-main">
<footer class="site-footer">
    <div class="container">
        <!-- <div class="footer-menu-wrap">
           <div class="row">
               <div class="col-md-6 col-lg-3 footer-menu-col footer-menu-1 d-none">
                   <h6>Company</h6>
                   <ul class="v-list">
                       <li><a href="#">About</a></li>
                       <li><a href="#">What We Offer</a></li>
                       <li><a href="#">Leadership</a></li>
                       <li><a href="#">Careers</a></li>
                       <li><a href="#">Catalog</a></li>
                   </ul>
               </div>
               <div class="col-md-6 col-lg-3 footer-menu-col footer-menu-2 d-none">
                <h6>Teaching</h6>
                <ul class="v-list">
                    <li><a href="#">Become a Teacher</a></li>
                    <li><a href="#">Teaching Academy</a></li>
                    <li><a href="#">Teacher Handbook</a></li>
                </ul>
                </div>
                <div class="col-md-6 col-lg-3 footer-menu-col footer-menu-3 d-none">
                    <h6>More</h6>
                    <ul class="v-list">
                        <li><a href="#">Become a Teacher</a></li>
                        <li><a href="#">Teaching Academy</a></li>
                        <li><a href="#">Teacher Handbook</a></li>
                    </ul>
                </div>
               <div class="col-md-12 footer-menu-col footer-menu-right d-none">
                   <a  [routerLink]="['/']" class="footer-logo"><img src="../../assets/images/logo-large.png" alt="logo-large.png" /></a>
                   <div class="sm-items d-none">
                       <a href="#" class="sm-link sm-link-insta"><i class="fab fa-instagram"></i></a>
                       <a href="#" class="sm-link sm-link-linkdin"><i class="fab fa-linkedin-in"></i></a>
                       <a href="#" class="sm-link sm-link-facebook"><i class="fab fa-facebook-f"></i></a>
                   </div>
               </div>
           </div>
        </div> -->
        <div class="footer-copyright-wrap">
           <div class="row">
               <div class="col-md-6 order-2 order-md-1 footer-copyright-left">
                <p>&copy; Copyright {{dateYear | date:'yyyy'}} educollab. All rights reserved.</p>
               </div>
               <div class="col-md-6 order-1 order-md-2 footer-copyright-right">
                   <ul>
                     <li><a href="javascript:void(0);" routerLink="privacy-policy">Privacy Policy</a></li>
                     <li><a href="javascript:void(0);" routerLink="terms">Terms</a></li>
                     <!-- <li><a href="#">Help</a></li> -->
                   </ul>
               </div>
           </div>
        </div>
        </div>
    </footer>
    </div>
    
    <!--  *ngIf="loginFLagValue" -->
    <!-- <footer class="main-footer"> 
        <div class="container">
            <p>&copy; Copyright {{dateYear | date:'yyyy'}} educollab. All rights reserved &nbsp; | &nbsp; <a routerLink="/privacy-policy" routerLinkActive="active">Privacy Policy</a></p>
        </div>    
    </footer> -->
    
    
    <!--Scroll to top-->
    <button [ngClass]="{'show-scroll': showScroll}" (click)="scrollToTop()" class="scroll-to-top" title="Scroll to Top">
        <i class="fas fa-angle-up"></i>
    </button>
    
    <div class="eduBot d-none" [ngClass]="'close'">
            <!-- guftagu-user-header -->
            <div class="eduBot-mobile">
                <figure class="guftagu-item-fig"><img src="../../assets/images/common-avatars/edu-bot-mobile.png" alt="edu-bot-mobile.png" /></figure>
            </div>
            <div class="guftagu-user-header eduBotHeader">
                <div class="guftagu-user-header-left">
                    <div class="guftagu-item-list guftagu-user-header-pro active">
                        <div class="guftagu-item-link">
                            <figure class="guftagu-item-fig"><img src="../../../assets/images/common-avatars/edu-bot.png" alt="edu-bot.png" /></figure>
                            <div class="guftagu-item-desc">
                                <p class="guftagu-item-desc-title mb-0"><strong>Ask Erica</strong></p>
                                <p class="guftagu-item-desc-subtitle mb-0"><small>Edu-collab helpbot</small></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="guftagu-user-header-right">
                    <ul class="guftagu-user-header-menus">
                        <li><a class="icon-hover-btn edubot-close-btn">
                            <i class="fa fa-times"></i>
                            <i class="fas fa-minus"></i>
                        </a></li>
                      </ul>
                </div>
            </div>
            <!-- guftagu-user-body -->
            <div class="guftagu-user-body-wrap eduBotBody d-none">
                <div class="guftagu-user-body-inner">
                   <div class="guftagu-user-chat-msg guftagu-user-chat-you">
                       <figure class="guftagu-user-chat-fig">
                           <img src="../../../assets/images/common-avatars/edu-bot.png" alt="edu-bot.png" />
                       </figure>
                       <div class="guftagu-user-chat-content">
                           <p class="guftagu-user-chat-name">
                            <small><strong>Samantha J.</strong></small>
                           </p>
                           <p class="guftagu-user-chat-textMsgs">
                            I scheduled a meeting for you today! Check your email for detalized information. Also, could you come to my office for a little talk?
                           </p>
                           <p class="guftagu-user-chat-date">
                               <small><strong>Yesterday, 11 AM</strong></small>
                           </p>
                       </div>
                   </div>
    
                   <div class="guftagu-user-chat-msg guftagu-user-chat-you">
                    <figure class="guftagu-user-chat-fig">
                        <img src="../../../assets/images/common-avatars/edu-bot.png" alt="edu-bot.png" />
                    </figure>
                    <div class="guftagu-user-chat-content">
                        <p class="guftagu-user-chat-name">
                            <small><strong>Samantha J.</strong></small>
                        </p>
                        <p class="guftagu-user-chat-textMsgs">
                         I scheduled a meeting.
                        </p>
                        <p class="guftagu-user-chat-date">
                            <small><strong>Yesterday, 11 AM</strong></small>
                        </p>
                    </div>
                </div>
    
                <div class="guftagu-user-chat-msg guftagu-user-chat-you">
                    <figure class="guftagu-user-chat-fig">
                        <img src="../../../assets/images/common-avatars/edu-bot.png" alt="edu-bot.png" />
                    </figure>
                    <div class="guftagu-user-chat-content">
                        <p class="guftagu-user-chat-name">
                            <small><strong>Samantha J.</strong></small>
                        </p>
                        <p class="guftagu-user-chat-textMsgs">
                          hi
                        </p>
                        <p class="guftagu-user-chat-date">
                            <small><strong>Yesterday, 11 AM</strong></small>
                        </p>
                    </div>
                </div>
    
                <div class="guftagu-user-chat-msg guftagu-user-chat-me">
                    <figure class="guftagu-user-chat-fig">
                        <img src="../../../assets/images/dp/dp-2.jpg" alt="dp-2.jpg" />
                    </figure>
                    <div class="guftagu-user-chat-content">
                        <p class="guftagu-user-chat-name">
                            <small><strong>Samantha J.</strong></small>
                        </p>
                        <p class="guftagu-user-chat-textMsgs">
                            I scheduled a meeting for you today! 
                        </p>
                        <p class="guftagu-user-chat-date">
                            <small><strong>Yesterday, 11 AM</strong></small>
                        </p>
                    </div>
                </div>
    
                <div class="guftagu-user-chat-msg guftagu-user-chat-me">
                    <figure class="guftagu-user-chat-fig">
                        <img src="../../../assets/images/dp/dp-2.jpg" alt="dp-2.jpg" />
                    </figure>
                    <div class="guftagu-user-chat-content">
                        <p class="guftagu-user-chat-name">
                            <small><strong>Samantha J.</strong></small>
                        </p>
                        <p class="guftagu-user-chat-textMsgs">
                          hi
                        </p>
                        <p class="guftagu-user-chat-date">
                            <small><strong>Yesterday, 11 AM</strong></small>
                        </p>
                    </div>
                </div>
    
                <div class="guftagu-user-chat-msg guftagu-user-chat-me">
                    <figure class="guftagu-user-chat-fig">
                        <img src="../../../assets/images/dp/dp-2.jpg" alt="dp-2.jpg" />
                    </figure>
                    <div class="guftagu-user-chat-content">
                        <p class="guftagu-user-chat-name">
                            <small><strong>Samantha J.</strong></small>
                        </p>
                        <p class="guftagu-user-chat-textMsgs">
                            I scheduled a meeting for you today! 
                        </p>
                        <p class="guftagu-user-chat-date">
                            <small><strong>Yesterday, 11 AM</strong></small>
                        </p>
                    </div>
                </div>
                </div>
            </div>
            <!-- guftagu-user-body -->
            <div class="guftagu-user-footer-wrap eduBotFooter d-none">
                <form class="guftagu-user-typing-block">
                    <div class="guftagu-user-footer-msg">
                        <textarea class="form-control form-textarea" placeholder="Type Your Message..."></textarea>
                    </div>
                    <div class="guftagu-user-footer-action">
                        <ul class="guftagu-user-footer-action-items">
                            <li class="guftagu-user-footer-action-item">
                                <a class="guftagu-user-action-link icon-hover-btn"><i class="fas fa-microphone-alt"></i></a>
                            </li>
                            <li>
                                <button class="btn btn-sm btn-primary msg-send-btn"><i class="fas fa-paper-plane me-2"></i> Send</button>
                            </li>
                        </ul>
    
                    </div>
                </form>
            </div>
    </div>
    
    <!-- <footer class="main-footer"> 
          *ngIf="loginFLagValue" 
        <div class="container">
            <p>&copy; Copyright {{dateYear | date:'yyyy'}} educollab. All rights reserved &nbsp; | &nbsp; <a routerLink="/privacy-policy" routerLinkActive="active">Privacy Policy</a></p>
        </div>    
    </footer> -->
    
    
    <!--Scroll to top
    <button [ngClass]="{'show-scroll': showScroll}" (click)="scrollToTop()" class="scroll-to-top" title="Scroll to Top">
        <i class="fas fa-chevron-circle-up"></i>
    </button>-->