import { DOCUMENT, Location } from '@angular/common';
import { Component, OnInit, Inject, OnDestroy, Renderer2, EventEmitter, Output, Input } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ActivatedRoute } from '@angular/router';
import { CourseService } from 'src/app/services/course.service';
import { finalize } from 'rxjs/operators';
import { MonitoringShareService } from 'src/app/services/monitoring-share.service';

@Component({
  selector: 'app-subscribers-notes-list',
  templateUrl: './subscribers-notes-list.component.html',
  styleUrls: ['./subscribers-notes-list.component.scss']
})
export class SubscribersNotesListComponent implements OnInit, OnDestroy {

  sessionNotes = [];
  loader: boolean = false;
  courseTitle: string;

  @Output() sub_notes = new EventEmitter();
  @Input() courseId;
  @Input() sessionId;
  @Input() sessionTitle;
  @Input() subscriberId;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private activatedRoute: ActivatedRoute,
    private courseService: CourseService,
    private _monitoringShareService: MonitoringShareService
  ) { }


  ngOnInit(): void {
    window.scroll(0, 0);
    this.courseTitle = this._monitoringShareService.getCourseName();
    if(!this.courseTitle){
      this.courseTitle = localStorage.getItem('courseName');
    } 

    if (this.courseId && this.sessionId) {
      this.getNotes();
      this.renderer.addClass(this.document.body, 'notes');
    }
  }

  getNotes() {
    this.loader = true;
    this.courseService.courseSessionNotes(this.courseId, this.sessionId)
      .pipe(finalize(() => this.loader = false)).subscribe(data => {
        this.sessionNotes = data.response;
        // this.getScroll();

      });
  }

  getScroll() {
    let idx: number = this.sessionNotes.findIndex(data => data._id == this.subscriberId);
    if (idx >= 0) {
      setTimeout(() => {
        let rows = document.querySelectorAll('#tableid tr');
        rows[idx].scrollIntoView();
      }, 100);
    }
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(this.document.body, 'notes');
  }

  redirect() {
    this.sub_notes.emit(false);
  }

  getActiveTr(id) {
    if (id === this.subscriberId) {
      return true;
    } else {
      return false;
    }
  }

  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: false,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['<i class="fas fa-chevron-left"></i>', '<i class="fas fa-chevron-right"></i>'],
    responsive: {
      0: {
        items: 1
      },
      748: {
        items: 2
      }
    },
    nav: true
  }

}
