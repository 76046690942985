<!-- New Code -->
<div class="new_UIstyle">
    <div class="pageBodyWrap">
        <!-- banner section -->
     <div class="banner banner-gradient banner-C-assessment">
         <div class="container">
              <div class="banner-content">
                  <div class="banner-content-content">
                      <h2 class="white-text avenirBlack">{{page_name}}</h2>
                    </div>
                  <div class="banner-content-action btn-groups">
                       <button class="btn btn-primary" routerLink="/resource-bank">Back</button>
                  </div>
              </div>
         </div> 
      </div>
      <!-- banner section -->
    
     <section class="section add-resource-wrap">
          <div class="container">
               <form class="typo-16" [ngClass]="{submitting : loader}" [formGroup]="addResourceFrom" (ngSubmit)="formSubmit('unpublish')">
                   <div class="row">
                       <div class="col-md-12 mb-4">
                            <label class="avenirBlack mb-1">Resource Title</label>
                           
                            <input type="text" class="form-control form-control-lg form-control-style-2" [ngClass]="{ 'is-invalid': submitResource && addResourceFrom.controls.resource_title.errors }" formControlName="resource_title" placeholder="Enter a title" />
                
                            <div *ngIf="submitResource && addResourceFrom.controls.resource_title.errors" class="invalid-feedback">
                                <div *ngIf="addResourceFrom.controls.resource_title.errors.required"> Resource Title is
                                    required
                                </div>
                            </div>
                            </div>
                       <div class="col-md-12 mb-4">
                            <label class="avenirBlack mb-1">Resource Description</label>
                            <textarea class="form-control form-control-textarea form-control-style-2"  [ngClass]="{ 'is-invalid': submitResource && addResourceFrom.controls.description.errors }"  formControlName="description" placeholder="Enter a little Description" ></textarea>
                            <div *ngIf="submitResource && addResourceFrom.controls.description.errors" class="invalid-feedback">
                                <div *ngIf="addResourceFrom.controls.description.errors.required"> Resource Description is
                                    required
                                </div>
                            </div> 
                        </div>
    
                        <div class="col-md-12 mb-4">
                            <label class="avenirBlack mb-1">Resource Tags</label>
                        <tag-input 
                        [ngClass]="{ 'is-invalid': submitResource && addResourceFrom.controls.tags.errors }"
                        [formControlName]="'tags'"  [addOnBlur]="true" [clearOnBlur]="true"
                            [secondaryPlaceholder]="'Enter a tag'" class="form-control form-control-lg form-control-style-2" [displayBy]="'tag'" [identifyBy]="'_id'"
                            [ngClass]="{ 'is-invalid': submitCoursework && addCourseWork.controls.tag.errors }">
                            <tag-input-dropdown [autocompleteItems]="submissionTags" [displayBy]="'tag'"
                                [identifyBy]="'_id'">
                            </tag-input-dropdown>
                        </tag-input>
                        <div *ngIf="submitResource && addResourceFrom.controls.tags.errors" class="invalid-feedback">
                            <div *ngIf="addResourceFrom.controls.tags.errors.required"> Tags are required</div>
                        </div>
                        </div>
    
                     <div class="col-md-12 mt-4">
                        <div class="dragDropWrap" [ngClass]="{submitting : !(uploadArray.length == browseFileLen)}">
                            <div class="spinner-border text-warning outer_loader_over" role="status"  *ngIf="!(uploadArray.length == browseFileLen)">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div>
                                <div class="dragDrop dragDropOr  d-none d-md-flex">
                                    <figure class="dragDropIcon"><i class="fas fa-upload"></i></figure>
                                    <div class="dragDropContent">
                                        <input type="file" title=" " id="fileU1"  class="file-upload-input "
                                            accept="image/*,video/*,audio/*,doc/*,application/*,text/*"
                                            (change)="onFileBrowse($event)"  #fileU1 />
                                        <p><strong class="blue-text d-block">Drag & Drop your file here
                                            </strong>Supports image, video, audio, pdf & text files
                                        </p>

                                        <div class="uploadedFiles">
                                            <p *ngIf="uploadedMedia.length > 0">
                                                <small *ngIf="uploadedMedia.length == 1"><b>{{uploadedMedia.length}} file selected</b></small>
                                                <small *ngIf="uploadedMedia.length > 1"><b>{{uploadedMedia.length}}  files selected</b></small>
                                            </p>
                                            <p *ngIf="uploadedMedia.length == 0"><small><b>No File
                                                        Selected</b></small></p>
                                        </div>
                                    </div>
                                    <span class="divideOr"></span>
                                </div>

                                <div class="dragDropUpload btn-groups" *ngIf="!resource_id">
                                    <button type="button" (click)="fileU1.click()"
                                        class="custom-upload-btn btn btn-blue btn-shadow btn-icon-text">
                                        <span class=""><i class="fas fa-download"></i> Browse
                                            Files</span>
                                    </button>
                                </div>

                            </div>

                        </div>
                        <div class="col-md-12 mt-4 uploadedItemWrap">
                            <div class="uploadedItem">
                                <div class=" mb-3" *ngFor="let media of uploadedMedia; let i = index">
                                    <div class="uploadedItemTop">
                                        <div class="uploadedItemDetail">
                                            <p class="mb-1"><strong class="blue-text"> {{ media.FileName
                                                    }}</strong></p>
                                            <p class="uploadedItemStatus">
                                                <small>{{media.FileProgress}}%
                                                    Complete</small>
                                                <small class="dark-green-text"
                                                    *ngIf="media.FileProgress === 100">Uploaded
                                                    Successful</small>
                                            </p>
                                        </div>
                                        <div class="uploadedItemAction">
                                        <a href="javascript:void(0);"
                                        *ngIf="media.FileProgress === 100 && (uploadArray.length == browseFileLen) && !resource_id" 
                                        (click)="openModelDeleteRes(deleteRes)" class="uploadedActionLink"><i
                                                class="far fa-times-circle"></i></a>

                                                   <!-- new ui delete question -->
     <ng-template #deleteRes let-c="close" let-d="dismiss">
        <div class="new_UIstyle">
            <div class="modal-header justify-content-between align-items-center">
                <h5 class="avenirBook modal-title text-danger mb-0">Delete Resourse</h5>
                <button type="button" class="close ml-0" aria-label="Close"
                    (click)="closeResPopup()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>Are you sure you want to delete Resource<strong class="text-danger">?</strong></p>
            </div>

            <div class="modal-footer">
                <div class="text-left">
                    <button type="submit" class="btn btn-primary btn-sm"
                        (click)="closeResPopup()">NO</button>
                </div>
                <div class="text-right">
                    <button type="submit" class="btn btn-primary btn-sm"
                    (click)="deleteResouce(media,i)">YES</button>
                </div>
            </div>
        </div>
    </ng-template>
                                    </div>
                                    </div>
                                    <div class="progress uploadedProgress">
                                        <div class="progress-bar uploadedProgressBlue"
                                            role="progressbar"
                                            [ngStyle]="{ 'width.%': media.FileProgress }"></div>
                                    </div>
                                    <!-- <p>
                                        <small>{{ media.FileProgessSize }} of {{ media.FileSize
                                            }}</small>
                                    </p> -->
                                </div>
                            </div>
                        </div>
                     </div>
    
                       <div class="col-md-12">
                           <hr />
                       </div>
                       <div class="btn-groups justify-content-end col-12">
                           <button class="btn btn-primary-border btn-w-100" routerLink="/resource-bank">Cancel</button>
                           <button class="btn btn-primary btn-w-100">Save</button>
                       </div>
                   </div> 
               </form>
          </div>
     </section>
    
      </div>
    
</div>
