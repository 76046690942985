import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Resource } from 'src/app/model/resource';

@Injectable({
  providedIn: 'root'
})
export class SharedResourceService {
  private editResouce = new BehaviorSubject<object>({});
  getResouce  = this.editResouce.asObservable();

  constructor() { }

  saveResouce(resource: Resource) {
    console.log(resource);
    this.editResouce.next(resource);
  }
}
